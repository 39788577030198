import NotificationMasterList from '../../__Demo_Data__/Notifications/notificationMasterList';

export default [
	{
		id: 131,
		group: 'High Risk Merchants',
		assignedUserId: null,
		isDisabled: 0,
		label: NotificationMasterList.notifications.one.text,
		tags: NotificationMasterList.notifications.one.category,
		type: NotificationMasterList.notifications.one.severity,
		createdByUserId: 4,
		updatedByUserId: null,
		createdOn: '05/20/2022 08:12',
		updatedOn: '05/20/2022 08:12',
		actionItems: [],
		sourceId: null
	},
	{
		id: 132,
		group: 'High Risk Merchants',
		assignedUserId: null,
		isDisabled: 0,
		label: NotificationMasterList.notifications.two.text,
		tags: NotificationMasterList.notifications.two.category,
		type: NotificationMasterList.notifications.two.severity,
		createdByUserId: 4,
		updatedByUserId: null,
		createdOn: '05/18/2022 11:28',
		updatedOn: '05/18/2022 11:28',
		actionItems: [],
		sourceId: null
	},
	{
		id: 133,
		group: 'High Risk Merchants',
		assignedUserId: null,
		isDisabled: 0,
		label: NotificationMasterList.notifications.three.text,
		tags: NotificationMasterList.notifications.three.category,
		type: NotificationMasterList.notifications.three.severity,
		createdByUserId: 4,
		updatedByUserId: null,
		createdOn: '05/15/2022 14:01',
		updatedOn: '05/15/2022 14:01',
		actionItems: [],
		sourceId: null
	},
	{
		id: 134,
		group: 'High Risk Merchants',
		assignedUserId: null,
		isDisabled: 0,
		label: NotificationMasterList.notifications.four.text,
		tags: NotificationMasterList.notifications.four.category,
		type: NotificationMasterList.notifications.four.severity,
		createdByUserId: 4,
		updatedByUserId: null,
		createdOn: '05/09/2022 20:13',
		updatedOn: '05/09/2022 20:13',
		actionItems: [],
		sourceId: null
	},
	{
		id: 141,
		group: 'Merchants with Current Violations',
		assignedUserId: null,
		isDisabled: 0,
		label: NotificationMasterList.notifications.five.text,
		tags: NotificationMasterList.notifications.five.category,
		type: NotificationMasterList.notifications.five.severity,
		createdByUserId: 4,
		updatedByUserId: null,
		createdOn: '05/20/2022 13:34',
		updatedOn: '05/20/2022 13:34',
		actionItems: [],
		sourceId: null
	},
	{
		id: 142,
		group: 'Merchants with Current Violations',
		assignedUserId: null,
		isDisabled: 0,
		label: NotificationMasterList.notifications.six.text,
		tags: NotificationMasterList.notifications.six.category,
		type: NotificationMasterList.notifications.six.severity,
		createdByUserId: 4,
		updatedByUserId: null,
		createdOn: '05/17/2022 06:04',
		updatedOn: '05/17/2022 06:04',
		actionItems: [],
		sourceId: null
	},
	{
		id: 143,
		group: 'Merchants with Current Violations',
		assignedUserId: null,
		isDisabled: 0,
		label: NotificationMasterList.notifications.seven.text,
		tags: NotificationMasterList.notifications.seven.category,
		type: NotificationMasterList.notifications.seven.severity,
		createdByUserId: 4,
		updatedByUserId: null,
		createdOn: '05/15/2022 12:50',
		updatedOn: '05/15/2022 12:50',
		actionItems: [],
		sourceId: null
	},
	{
		id: 144,
		group: 'Merchants with Current Violations',
		assignedUserId: null,
		isDisabled: 0,
		label: NotificationMasterList.notifications.eight.text,
		tags: NotificationMasterList.notifications.eight.category,
		type: NotificationMasterList.notifications.eight.severity,
		createdByUserId: 4,
		updatedByUserId: null,
		createdOn: '05/13/2022 05:01',
		updatedOn: '05/13/2022 05:01',
		actionItems: [],
		sourceId: null
	},
	{
		id: 121,
		group: 'Newly Onboarded Merchants',
		assignedUserId: 2,
		isDisabled: 0,
		label: NotificationMasterList.notifications.nine.text,
		tags: NotificationMasterList.notifications.nine.category,
		type: NotificationMasterList.notifications.nine.severity,
		createdByUserId: 4,
		updatedByUserId: null,
		createdOn: '05/20/2022 11:37',
		updatedOn: '05/20/2022 11:37',
		actionItems: [
			{
				title: 'View Fraud Report',
				link: '',
				data: {}
			}
		],
		sourceId: null
	},
	{
		id: 123,
		group: 'Newly Onboarded Merchants',
		assignedUserId: null,
		isDisabled: 0,
		label: NotificationMasterList.notifications.ten.text,
		tags: NotificationMasterList.notifications.ten.category,
		type: NotificationMasterList.notifications.ten.severity,
		createdByUserId: 4,
		updatedByUserId: null,
		createdOn: '05/17/2022 19:21',
		updatedOn: '05/17/2022 19:21',
		actionItems: [],
		sourceId: null
	},
	{
		id: 124,
		group: 'Newly Onboarded Merchants',
		assignedUserId: null,
		isDisabled: 0,
		label: NotificationMasterList.notifications.eleven.text,
		tags: NotificationMasterList.notifications.eleven.category,
		type: NotificationMasterList.notifications.eleven.severity,
		createdByUserId: 4,
		updatedByUserId: null,
		createdOn: '05/15/2022 04:17',
		updatedOn: '05/15/2022 04:17',
		actionItems: [],
		sourceId: null
	},
	{
		id: 125,
		group: 'Newly Onboarded Merchants',
		assignedUserId: null,
		isDisabled: 0,
		label: NotificationMasterList.notifications.twelve.text,
		tags: NotificationMasterList.notifications.twelve.category,
		type: NotificationMasterList.notifications.twelve.severity,
		createdByUserId: 4,
		updatedByUserId: null,
		createdOn: '05/14/2022 13:07',
		updatedOn: '05/14/2022 13:07',
		actionItems: [],
		sourceId: null
	},
	{
		id: 151,
		group: 'Merchants with Former Violations',
		assignedUserId: null,
		isDisabled: 0,
		label: NotificationMasterList.notifications.thirteen.text,
		tags: NotificationMasterList.notifications.thirteen.category,
		type: NotificationMasterList.notifications.thirteen.severity,
		createdByUserId: 4,
		updatedByUserId: null,
		createdOn: '05/20/2022 10:19',
		updatedOn: '05/20/2022 10:19',
		actionItems: [],
		sourceId: null
	},
	{
		id: 152,
		group: 'Merchants with Former Violations',
		assignedUserId: null,
		isDisabled: 0,
		label: NotificationMasterList.notifications.fourteen.text,
		tags: NotificationMasterList.notifications.fourteen.category,
		type: NotificationMasterList.notifications.fourteen.severity,
		createdByUserId: 4,
		updatedByUserId: null,
		createdOn: '05/17/2022 06:58',
		updatedOn: '05/17/2022 06:58',
		actionItems: [],
		sourceId: null
	}
];
