import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import findReportItemInList from '../../../../helpers/reporter/findReportItemInList';
import { reporterActions } from '../../../../containers/Reporter';

import Stack from '@mui/material/Stack';

const styles = (theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		flex: 1
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
		alignSelf: 'stretch'
	},
	headerRow: {
		fontSize: '.8rem'
	},
	splitColumn: {
		display: 'flex',
		flexDirection: 'row',
		flex: 1
	},
	leftAlign: {
		justifyContent: 'flex-start'
	},
	rightAlign: {
		justifyContent: 'flex-end'
	},
	itemRow: {
		fontSize: '1.2rem',
		flex: 1
	},
	severeText: {
		fontWeight: 600,
		color: '#f5989d'
	},
	warningText: {
		fontWeight: 600,
		color: '#c6b55a'
	}
});

const _renderChargebackValue = (value, classes) => {
	const { severeText, warningText } = classes;

	let rendered = <></>;

	if (value > 11) {
		rendered = <span className={severeText}>{value}</span>;
	} else {
		rendered = <span className={warningText}>{value}</span>;
	}

	return rendered;
};

// DANGER ZONE 5 - 10

const StaticMetricList = (props) => {
	const { classes, height, tileProperties, reportData, getTileData } = props;
	const {
		container,
		row,
		headerRow,
		splitColumn,
		leftAlign,
		rightAlign,
		itemRow
	} = classes;

	let tileData;

	if (reportData && tileProperties) {
		tileData = findReportItemInList(
			reportData,
			tileProperties.universalUniqueIdentifier
		);
	}

	React.useEffect(() => {
		if (
			tileProperties &&
			(!tileData || (!tileData.hasBeenLoaded && !tileData.isLoading))
		) {
			getTileData({
				id: tileProperties.reportId,
				uuid: tileProperties.universalUniqueIdentifier
			});
		}
	}, [tileProperties, tileData, getTileData]);

	return (
		<div className={container}>
			<div className={clsx(row, headerRow)}>
				<div className={clsx(splitColumn, leftAlign)}>MID</div>
				<div className={clsx(splitColumn, rightAlign)}>Count</div>
			</div>
			<Stack
				direction="column"
				spacing={2}
				mt={2}
				height={height * 0.8}
				alignItems="center"
				justifyContent="center"
			>
				{tileData &&
					tileData.hasBeenLoaded &&
					tileData.data.map((rowData, index) => {
						return (
							<div key={index} className={clsx(row, itemRow)}>
								<div className={clsx(splitColumn, leftAlign)}>
									{rowData.name}
								</div>
								<div className={clsx(splitColumn, rightAlign)}>
									{_renderChargebackValue(rowData.chargebacks, classes)}
								</div>
							</div>
						);
					})}
			</Stack>
		</div>
	);
};

StaticMetricList.propTypes = {
	tileProperties: PropTypes.object,
	classes: PropTypes.object,
	getTileData: PropTypes.func,
	t: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		reportData: state.reporterReducer.get('reportData')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getTileData: (payload) => {
			dispatch(reporterActions.executeReport(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(withTranslation()(StaticMetricList)));
