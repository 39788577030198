import axios from '../../utilities/axios';
import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { refreshToken } from '../../utilities/refreshToken';
import { failureMessage } from '../ToastNotifications/actions';
import Logger from '../../utilities/logger';
import { primaryRestGateway } from '../../utilities/apiEndpointUtility';

import * as actions from './actions.js';
import * as actionTypes from './actionTypes.js';

import demoGetCardOverview from '../../__Demo_Data__/CurrentInventory/getCardOverview';
import demoGetCurrentInventory from '../../__Demo_Data__/CurrentInventory/getCurrentInventory';
import { demoWait } from '../../__Demo_Data__/mockRandomLoad';

export const getCardOverview = (action$) =>
	action$.pipe(
		ofType(actionTypes.GET_CARD_OVERVIEW),
		mergeMap(async (action) => {
			await demoWait();
			// await refreshToken();
			// const dateRange = action.payload.dateRange;

			// const response = await axios.get(
			// 	`${primaryRestGateway()}/api/representments/currentInventory/cardOverview?dateRange=${dateRange}`
			// );

			const response = {
				data: demoGetCardOverview
			};

			return response.data;
		}),
		switchMap((res) => [actions.getCardOverviewCompleted(res)]),
		catchError((error, source) => {
			Logger.error('Get Card Overview Failure', error);

			return merge(
				of(
					actions.getCardOverviewFailed(error.message),
					failureMessage(error.message)
				),
				source
			);
		})
	);

export const getCurrentInventory = (action$) =>
	action$.pipe(
		ofType(actionTypes.GET_CURRENT_INVENTORY),
		mergeMap(async (action) => {
			await demoWait();
			// await refreshToken();

			// const dateRange = action.payload.dateRange;
			const groupBy = action.payload.groupBy;

			// const response = await axios.get(
			// 	`${primaryRestGateway()}/api/representments/currentInventory?dateRange=${dateRange}&groupBy=${groupBy}`
			// );

			const groupedResponse = demoGetCurrentInventory(groupBy);

			const response = {
				data: groupedResponse
			};

			return response.data;
		}),
		switchMap((res) => [actions.getCurrentInventoryCompleted(res)]),
		catchError((error, source) => {
			Logger.error('Get Current Inventory Failure', error);

			return merge(
				of(
					actions.getCurrentInventoryFailed(error.message),
					failureMessage(error.message)
				),
				source
			);
		})
	);
