export const AUTH_USER = 'auth/AUTH_USER';
export const AUTH_USER_COMPLETED = 'auth/AUTH_USER_COMPLETED';
export const AUTH_USER_FAILED = 'auth/AUTH_USER_FAILED';

export const AUTH_USER_SESSION = 'auth/AUTH_USER_SESSION';
export const AUTH_USER_SESSION_COMPLETED = 'auth/AUTH_USER_SESSION_COMPLETED';
export const AUTH_USER_SESSION_FAILED = 'auth/AUTH_USER_SESSION_FAILED';

export const DE_AUTH_USER = 'auth/DE_AUTH_USER';

export const COMPLETE_NEW_PASSWORD = 'auth/COMPLETE_NEW_PASSWORD';
export const COMPLETE_NEW_PASSWORD_COMPLETED =
	'auth/COMPLETE_NEW_PASSWORD_COMPLETED';
export const COMPLETE_NEW_PASSWORD_FAILED = 'auth/COMPLETE_NEW_PASSWORD_FAILED';
