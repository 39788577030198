import React, { Fragment, useCallback, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { systemActions } from '../../../containers/System';
import Modal from './ScreenshotModal';
import EmailInputs from './EmailInputs';

import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

const styles = (theme) => ({
	emailForm: {
		display: 'flex',
		flexDirection: 'column',
		minWidth: '650px'
	},
	errorCaption: {
		marginBottom: '10px',
		color: theme.palette.error.main
	}
});

const EmailScreenshot = (props) => {
	const {
		classes,
		error,
		hasBeenSent,
		getScreenshot,
		isOpen,
		isSending,
		resetEmailValues,
		screenshotLabel,
		sendEmail,
		onClose,
		sendTo,
		title
	} = props;
	const { emailForm, errorCaption } = classes;

	const [emailIsValidToSend, setEmailIsValidToSend] = useState(false);
	const [inputValues, setInputValues] = useState({
		to: [sendTo],
		cc: [],
		bcc: [],
		subject: '',
		message: ''
	});

	const handleClose = useCallback(() => {
		resetEmailValues();
		onClose();
	}, [onClose, resetEmailValues]);

	useEffect(() => {
		if (hasBeenSent && !isSending) {
			handleClose();
		}
	}, [handleClose, hasBeenSent, isSending]);

	const handleInputChange = useCallback(
		(key, value) => {
			const inputs = Object.assign({}, inputValues);
			inputs[key] = value;

			if (inputs.to !== [] && inputs.subject !== '' && inputs.message !== '') {
				setEmailIsValidToSend(true);
			} else {
				setEmailIsValidToSend(false);
			}
			setInputValues(inputs);
		},
		[inputValues, setInputValues]
	);

	const handleSendEmail = async () => {
		const { to, cc, bcc, subject, message } = inputValues;
		const mailer = {
			to: to || sendTo,
			cc: cc || [],
			bcc: bcc || [],
			subject,
			message
		};

		if (getScreenshot) {
			const timestamp = new Date(Date.now())
				.toLocaleString()
				.split(',')[0]
				.replace(/\//g, '-');

			const screenshotEncoded = await getScreenshot();
			mailer.attachments = [
				{
					name: `slyce360-${screenshotLabel}-${timestamp}.png`,
					file: screenshotEncoded
				}
			];
		}
		sendEmail(mailer);
	};

	const _emailActions = () => {
		return (
			<Fragment>
				<Button autoFocus onClick={handleClose} color="primary">
					Cancel
				</Button>
				<Button
					autoFocus
					onClick={handleSendEmail}
					color="primary"
					disabled={!emailIsValidToSend}
				>
					Send
				</Button>
			</Fragment>
		);
	};

	const _emailScreenshotForm = () => {
		return (
			<Container className={emailForm}>
				{error && (
					<Typography variant="subtitle2" className={errorCaption}>
						Email Is Currently Unavailable - Please Try Again Later
					</Typography>
				)}
				<EmailInputs
					label={'To*'}
					defaultValue={sendTo}
					id="email-to"
					onSelect={(values) => handleInputChange('to', values)}
				/>
				<EmailInputs
					id="email-cc"
					label={'CC'}
					onSelect={(values) => handleInputChange('cc', values)}
				/>
				<EmailInputs
					id="email-bcc"
					label={'BCC'}
					onSelect={(values) => handleInputChange('bcc', values)}
				/>
				<TextField
					id="email-subject"
					label="Subject*"
					style={{ margin: 8 }}
					margin="normal"
					onChange={(e) => handleInputChange('subject', e.target.value)}
					InputLabelProps={{
						shrink: true
					}}
				/>
				<TextareaAutosize
					id="email-body"
					placeholder="Message body (required)"
					rowsMin={12}
					onChange={(e) => handleInputChange('message', e.target.value)}
				/>
			</Container>
		);
	};

	return (
		<Fragment>
			<Modal
				actions={_emailActions()}
				isOpen={isOpen}
				title={title || 'Email'}
				handleClose={handleClose}
			>
				{_emailScreenshotForm()}
			</Modal>
		</Fragment>
	);
};

EmailScreenshot.propTypes = {
	classes: PropTypes.object,
	error: PropTypes.string,
	hasBeenSent: PropTypes.bool,
	getScreenshot: PropTypes.func,
	isOpen: PropTypes.bool,
	isSending: PropTypes.bool,
	onClose: PropTypes.func,
	resetEmailValues: PropTypes.func,
	screenshotLabel: PropTypes.string,
	sendEmail: PropTypes.func,
	sendTo: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	t: PropTypes.func,
	title: PropTypes.string
};

const mapStateToProps = (state) => {
	return {
		error: state.systemReducer.get('email').get('error'),
		hasBeenSent: state.systemReducer.get('email').get('hasBeenSent'),
		isSending: state.systemReducer.get('email').get('isSending')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		resetEmailValues: () => {
			dispatch(systemActions.resetEmailValues());
		},
		sendEmail: (message) => {
			dispatch(systemActions.sendEmail(message));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(EmailScreenshot));
