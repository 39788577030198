import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { layoutsActions } from '../../../containers/Layouts';
import HeaderRow from '../shared/HeaderRow';
import { tilesActions } from '../../../containers/Tiles';
import moment from 'moment';
import shortUuid from 'short-uuid';
import clsx from 'clsx';
import querier from '../../../utilities/querier';
import AddTileForm from './AddTileForm';
import getTileListForLayout from '../../../helpers/tiles/getTileListForLayout';

import { reportViewsActions } from '../../../containers/ReportViews';

import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const styles = (theme) => ({
	modal: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	paper: {
		display: 'flex',
		flexFlow: 'column',
		padding: '20px'
	},
	paperRow: {
		display: 'flex',
		alignSelf: 'stretch',
		marginTop: '15px',
		flexflow: 'row'
	},
	loadingContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		marginTop: '5%'
	},
	successContainer: {
		display: 'flex',
		marginTop: '5%',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center'
	},
	buttonContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginTop: '20px',
		marginBottom: '10px',
		marginRight: '10px'
	},
	inputButton: {
		borderRadius: '4px',
		width: '100px',
		border: 'none',
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.secondary.alt,
		padding: '5px',
		fontSize: '16px',
		fontWeight: '300',
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
			cursor: 'pointer'
		}
	},
	icon: {
		color: theme.palette.createLayoutModal.successIcon,
		fontSize: '3rem',
		marginBottom: '10px'
	}
});

const _render = (
	selectedLayout,
	setSelectedLayout,
	tileLabel,
	setTileLabel,
	tileSubmitted,
	currentLayoutTiles,
	onClickAdd,
	onCloseModal,
	layouts,
	classes,
	t
) => {
	const {
		loadingContainer,
		icon,
		paperRow,
		buttonContainer,
		inputButton,
		successContainer
	} = classes;
	let rendered = <></>;

	if (tileSubmitted && currentLayoutTiles && currentLayoutTiles.isLoading) {
		rendered = (
			<div className={loadingContainer}>
				<CircularProgress />
			</div>
		);
	} else if (
		tileSubmitted &&
		currentLayoutTiles &&
		!currentLayoutTiles.isLoading
	) {
		rendered = (
			<div className={successContainer}>
				<CheckCircleIcon className={icon} />
				{t('components.modals.AddReportToDashboard.successfullyAdded')}
				<div className={clsx(paperRow, buttonContainer)}>
					<Button
						className={inputButton}
						variant="outlined"
						color="secondary"
						onClick={onCloseModal}
					>
						{t('generic.buttons.close')}
					</Button>
				</div>
			</div>
		);
	} else {
		rendered = (
			<Fragment>
				<AddTileForm
					tileLabel={tileLabel}
					setTileLabel={setTileLabel}
					layouts={layouts}
					selectedLayout={selectedLayout}
					setSelectedLayout={setSelectedLayout}
				/>
				<div className={clsx(paperRow, buttonContainer)}>
					<Button
						variant="contained"
						size="small"
						className={inputButton}
						type="submit"
						onClick={onClickAdd}
						disabled={
							(selectedLayout && false) ||
							(tileSubmitted &&
								currentLayoutTiles &&
								currentLayoutTiles.isLoading)
						}
					>
						{t('generic.buttons.add')}
					</Button>
				</div>
			</Fragment>
		);
	}

	return rendered;
};

export const AddReportToDashboardModal = (props) => {
	const {
		classes,
		t,
		user,
		reportTileBeingCreated,
		closeModal,
		layouts,
		layoutsAreLoading,
		layoutsHaveBeenLoaded,
		layoutTiles,
		getLayouts
	} = props;
	const { modal, paper } = classes;

	const [selectedLayout, setSelectedLayout] = React.useState();
	const [tileLabel, setTileLabel] = React.useState();
	const [tileSubmitted, setTileSubmitted] = React.useState(false);

	const currentLayoutTiles = getTileListForLayout(
		layoutTiles,
		querier(selectedLayout, 'universalUniqueIdentifier')
	);

	React.useEffect(() => {
		if (!layoutsAreLoading && !layoutsHaveBeenLoaded) {
			getLayouts({
				userId: user.id
			});
		}
	}, [user, layoutsAreLoading, layoutsHaveBeenLoaded, getLayouts]);

	React.useEffect(() => {
		if (layouts && layouts.length > 0 && !selectedLayout) {
			setSelectedLayout(layouts[0]);
		}
	}, [layouts]);

	React.useEffect(() => {
		if (!tileLabel && reportTileBeingCreated) {
			setTileLabel(reportTileBeingCreated.label);
		}
	}, [tileLabel, reportTileBeingCreated]);

	const onCloseModal = () => {
		const { closeModal } = props;

		setTileLabel();
		setTileSubmitted(false);
		closeModal();
	};

	const onClickAdd = () => {
		// DISABLED FOR DEMO
		// const { addTileToLayout } = props;
		// const currentMoment = moment().format('YYYY-MM-DD');
		// const tileUniversalUniqueIdentifier = shortUuid.uuid();
		// const newTileConfiguration = {
		// 	universalUniqueIdentifier: tileUniversalUniqueIdentifier,
		// 	layoutUuid: selectedLayout.universalUniqueIdentifier,
		// 	label: tileLabel,
		// 	userInterfaceDefinition: 'dashboardWidget',
		// 	// HACK UNTIL WE DECIDE HOW TO HANDLE MULTI REPORT VIEWS
		// 	reportId: querier(reportTileBeingCreated, 'reportIds[0].id'),
		// 	dataViewStyle: 'line',
		// 	minWidth: 700,
		// 	maxWidth: 800,
		// 	createdOn: currentMoment,
		// 	createdByUserId: selectedLayout.userId,
		// 	updatedOn: currentMoment
		// };
		// setTileSubmitted(true);
		// addTileToLayout({
		// 	layoutUniversalUniqueIdentifier: selectedLayout.universalUniqueIdentifier,
		// 	tiles: [newTileConfiguration]
		// });
	};

	return (
		<Modal
			open={reportTileBeingCreated && reportTileBeingCreated.id ? true : false}
			onClose={closeModal}
			className={modal}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Paper className={paper} elevation={1}>
				<HeaderRow
					translationPath={
						'components.modals.AddReportToDashboard.addReportToDashboard'
					}
					onClickCancel={onCloseModal}
				/>
				{_render(
					selectedLayout,
					setSelectedLayout,
					tileLabel,
					setTileLabel,
					tileSubmitted,
					currentLayoutTiles,
					onClickAdd,
					onCloseModal,
					layouts,
					classes,
					t
				)}
			</Paper>
		</Modal>
	);
};

AddReportToDashboardModal.propTypes = {
	user: PropTypes.object,
	reportTileBeingCreated: PropTypes.object,
	layouts: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	getLayouts: PropTypes.func,
	closeModal: PropTypes.func,
	layoutTiles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	classes: PropTypes.object,
	t: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		user: state.userReducer.get('user'),
		layouts: state.layoutsReducer.get('data'),
		layoutsAreLoading: state.layoutsReducer.get('isLoading'),
		layoutsHaveBeenLoaded: state.layoutsReducer.get('hasBeenLoaded'),
		layoutTiles: state.tilesReducer.get('layoutTiles'),
		reportTileBeingCreated: state.reportViewsReducer.get(
			'reportTileBeingCreated'
		)
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		closeModal: (payload) => {
			dispatch(reportViewsActions.setReportTileBeingCreated(payload));
		},
		getLayouts: (payload) => {
			dispatch(layoutsActions.getLayouts(payload));
		},
		addTileToLayout: (payload) => {
			dispatch(tilesActions.addTilesToLayout(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(withTranslation()(AddReportToDashboardModal)));
