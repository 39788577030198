import * as actionTypes from './actionTypes';

export const getTilesForLayout = (payload) => ({
	type: actionTypes.GET_TILES_FOR_LAYOUT,
	payload
});

export const getTilesForLayoutCompleted = (payload) => ({
	type: actionTypes.GET_TILES_FOR_LAYOUT_COMPLETED,
	payload
});

export const getTilesForLayoutFailed = (payload) => ({
	type: actionTypes.GET_TILES_FOR_LAYOUT_FAILED,
	payload
});

export const setTileBeingModified = (payload) => ({
	type: actionTypes.SET_TILE_BEING_MODIFIED,
	payload
});

export const updateLayoutTile = (payload) => ({
	type: actionTypes.UPDATE_LAYOUT_TILE,
	payload
});

export const updateLayoutTileCompleted = (payload) => ({
	type: actionTypes.UPDATE_LAYOUT_TILE_COMPLETED,
	payload
});

export const updateLayoutTileFailed = (payload) => ({
	type: actionTypes.UPDATE_LAYOUT_TILE_FAILED,
	payload
});

export const getAvailableTiles = (payload) => ({
	type: actionTypes.GET_AVAILABLE_TILES,
	payload
});

export const getAvailableTilesCompleted = (payload) => ({
	type: actionTypes.GET_AVAILABLE_TILES_COMPLETED,
	payload
});

export const getAvailableTilesFailed = (payload) => ({
	type: actionTypes.GET_AVAILABLE_TILES_FAILED,
	payload
});

export const addTilesToLayout = (payload) => ({
	type: actionTypes.ADD_TILES_TO_LAYOUT,
	payload
});

export const addTilesToLayoutCompleted = (payload) => ({
	type: actionTypes.ADD_TILES_TO_LAYOUT_COMPLETED,
	payload
});

export const addTilesToLayoutFailed = (payload) => ({
	type: actionTypes.ADD_TILES_TO_LAYOUT_FAILED,
	payload
});

export const deleteTilesByLayout = (payload) => ({
	type: actionTypes.DELETE_TILES_BY_LAYOUT,
	payload
});

export const deleteTilesByLayoutCompleted = (payload) => ({
	type: actionTypes.DELETE_TILES_BY_LAYOUT_COMPLETED,
	payload
});

export const deleteTilesByLayoutFailed = (payload) => ({
	type: actionTypes.DELETE_TILES_BY_LAYOUT_FAILED,
	payload
});

export const deleteTileFromLayout = (payload) => ({
	type: actionTypes.DELETE_TILE_FROM_LAYOUT,
	payload
});

export const deleteTileFromLayoutCompleted = (payload) => ({
	type: actionTypes.DELETE_TILE_FROM_LAYOUT_COMPLETED,
	payload
});

export const deleteTileFromLayoutFailed = (payload) => ({
	type: actionTypes.DELETE_TILE_FROM_LAYOUT_FAILED,
	payload
});
