import React from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import DashboardControl from '../../components/Dashboard/DashboardControl';
import DashboardTileManager from '../../components/Dashboard/DashboardTileManager';
import ManageDashboardModal from '../../components/modals/ManageDashboardModal';
import CreateLayoutModal from '../../components/modals/CreateLayoutModal';
import EditTileModal from '../../components/modals/EditTileModal';

const styles = (theme) => ({
	root: {
		display: 'flex',
		flexGrow: '1',
		flexDirection: 'column',
		padding: '0px 2rem 2rem 2rem',
		margin: 0,
		height: '100%',
		overflow: 'auto'
	}
});

const Dashboard = (props) => {
	const { classes } = props;
	const { root } = classes;

	return (
		<div className={root}>
			<DashboardControl />
			<DashboardTileManager />
			<ManageDashboardModal />
			<CreateLayoutModal />
			<EditTileModal />
		</div>
	);
};

Dashboard.propTypes = {
	classes: PropTypes.object
};

export default withStyles(styles)(Dashboard);
