import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import { reportViewsActions } from '../../containers/ReportViews';
import Loader from '../../components/Loader';

import Container from '@mui/material/Container';
import determineReportViewConfiguration from '../../helpers/reporter/determineReportViewConfiguration';

const styles = (theme) => ({
	root: {
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		padding: '0px'
	}
});

const _renderReportView = (selectedReportViewId, SelectedReportComponent) => {
	if (SelectedReportComponent) {
		return <SelectedReportComponent reportViewId={selectedReportViewId} />;
	} else {
		return <Loader />;
	}
};

const Report = (props) => {
	const {
		classes,
		isLoadingReports,
		reportsHaveBeenLoaded,
		getReportViews
	} = props;
	const { root } = classes;

	const [selectedReportViewId, setSelectedReportViewId] = React.useState(
		undefined
	);
	const [SelectedReportComponent, setSelectedReportComponent] = React.useState(
		undefined
	);

	const location = useLocation();

	React.useEffect(() => {
		if (!isLoadingReports && !reportsHaveBeenLoaded) {
			getReportViews();
		}
	}, [isLoadingReports, reportsHaveBeenLoaded, getReportViews]);

	React.useEffect(() => {
		if (reportsHaveBeenLoaded) {
			const incomingReportViewId = location.pathname.split('/')[2];

			if (incomingReportViewId !== selectedReportViewId) {
				setSelectedReportViewId(incomingReportViewId);
				const ReportComponent = determineReportViewConfiguration(
					incomingReportViewId
				);
				setSelectedReportComponent(ReportComponent);
			}
		}
	}, [
		location,
		reportsHaveBeenLoaded,
		selectedReportViewId,
		setSelectedReportViewId,
		setSelectedReportComponent
	]);

	return (
		<Container maxWidth={false} className={root}>
			{_renderReportView(selectedReportViewId, SelectedReportComponent)}
		</Container>
	);
};

Report.propTypes = {
	isLoadingReports: PropTypes.bool,
	reportsHaveBeenLoaded: PropTypes.bool,
	getReportViews: PropTypes.func,
	classes: PropTypes.object
};

const mapStateToProps = (state) => {
	return {
		reportsList: state.reportViewsReducer.get('reportViews'),
		isLoadingReports: state.reportViewsReducer.get('isLoading'),
		reportsHaveBeenLoaded: state.reportViewsReducer.get('hasBeenLoaded'),
		reportsError: state.reportViewsReducer.get('error')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getReportViews: (payload) => {
			dispatch(reportViewsActions.getReportViews(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(Report));
