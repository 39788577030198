import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import MaterialTable from './MaterialTable';

import Button from '@mui/material/Button';

const styles = (theme) => ({
	root: {
		overflowX: 'auto',
		maxWidth: '100vw'
	}
});

const _displayRecordingStatus = (status) => {
	switch (status) {
		case 'unavailable':
			return 'Unavailable';
		case 'ready':
			return 'Play';
		default:
			return 'None';
	}
};

const _getColumnOrder = (columnName) => {
	switch (columnName) {
		case 'recordingStatus':
			return { columnOrder: 0 };
		case 'recording':
			return { columnOrder: 0 };
		case 'customerServiceRecordingStatus':
			return { columnOrder: 0 };
		case 'customerServiceRecording':
			return { columnOrder: 0 };
		case 'customerSalesRecordingStatus':
			return { columnOrder: 0 };
		case 'customerSalesRecording':
			return { columnOrder: 0 };
		default:
			return null;
	}
};

const generateColumnsForRender = (columnList, openRecordingsModal) => {
	return columnList.map((column) => {
		return {
			...column,
			tableData: _getColumnOrder(column.name),
			render: (rowData) => {
				if (rowData.recordingStatus && column.name === 'recording') {
					return (
						<Button
							variant="outlined"
							size="small"
							style={{ textTransform: 'none' }}
							onClick={() => openRecordingsModal(rowData.orderId)}
							disabled={rowData.recordingStatus === 'Unavailable'}
						>
							{_displayRecordingStatus(rowData.recordingStatus.toLowerCase())}
						</Button>
					);
				} else if (
					rowData.customerServiceRecordingStatus &&
					column.name === 'customerServiceRecording'
				) {
					return (
						<Button
							variant="outlined"
							size="small"
							style={{ textTransform: 'none' }}
							onClick={() =>
								openRecordingsModal(rowData.customerServiceRecording.filename)
							}
							disabled={
								rowData.customerServiceRecordingStatus === 'Unavailable'
							}
						>
							{_displayRecordingStatus(
								rowData.customerServiceRecordingStatus.toLowerCase()
							)}
						</Button>
					);
				} else if (
					rowData.customerSalesRecordingStatus &&
					column.name === 'customerSalesRecording'
				) {
					return (
						<Button
							variant="outlined"
							size="small"
							style={{ textTransform: 'none' }}
							onClick={() =>
								openRecordingsModal(rowData.customerSalesRecording.filename)
							}
							disabled={rowData.customerSalesRecordingStatus === 'Unavailable'}
						>
							{_displayRecordingStatus(
								rowData.customerSalesRecordingStatus.toLowerCase()
							)}
						</Button>
					);
				} else {
					return <span>{rowData[column.name]}</span>;
				}
			}
		};
	});
};

const TableView = (props) => {
	const { classes, columns, data, openRecordingsModal, pageSize } = props;
	const { root } = classes;

	return (
		<div className={root}>
			<MaterialTable
				columns={generateColumnsForRender(columns, openRecordingsModal)}
				data={data}
				options={{
					padding: 'dense',
					rowStyle: { fontSize: '12px' },
					headerStyle: { fontSize: '12px', fontWeight: 550 },
					showTitle: false,
					toolbar: true,
					pageSize: pageSize || 10
				}}
			/>
		</div>
	);
};

TableView.propTypes = {
	classes: PropTypes.object,
	columns: PropTypes.array,
	openRecordingsModal: PropTypes.func,
	pageSize: PropTypes.number,
	data: PropTypes.array
};

export default withStyles(styles)(TableView);
