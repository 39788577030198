import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';

import TableChartSharpIcon from '@mui/icons-material/TableChartSharp';
import BarChartIcon from '@mui/icons-material/BarChart';
import PieChartIcon from '@mui/icons-material/PieChart';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import PivotTableChartIcon from '@mui/icons-material/PivotTableChart';
import Tooltip from '@mui/material/Tooltip';

const styles = (theme) => ({
	suggestedIcon: {
		color: theme.palette.reportViews.icons.actionIcon
	}
});

const _chartTypes = {
	bar: BarChartIcon,
	pie: PieChartIcon,
	table: TableChartSharpIcon,
	line: ShowChartIcon,
	pivot: PivotTableChartIcon
};

export const SuggestedDisplayTypeItem = (props) => {
	const { classes, t, suggestedDisplayType } = props;
	const { suggestedIcon } = classes;

	const SelectedIcon = _chartTypes[suggestedDisplayType];

	return (
		<Fragment>
			{SelectedIcon && (
				<Tooltip
					title={t(
						`components.ReportViews.ReportCard.SuggestedViewTypeItem.${suggestedDisplayType}`
					)}
				>
					<SelectedIcon className={suggestedIcon} />
				</Tooltip>
			)}
		</Fragment>
	);
};

SuggestedDisplayTypeItem.propTypes = {
	suggestedDisplayType: PropTypes.string,
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(SuggestedDisplayTypeItem));
