import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import logos from './logos';

import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const styles = (theme) => ({
	associationContainer: {
		display: 'flex',
		alignSelf: 'stretch',
		flexDirection: 'row',
		borderRadius: '10px'
	},
	logoContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		margin: '0px',
		overflow: 'hidden',
		borderRadius: '10px'
	},
	logo: {
		marginTop: '-5px',
		objectFit: 'contain'
	},
	statsContainer: {
		display: 'flex',
		flexDirection: 'column',
		marginLeft: '-25px',
		flex: 3,
		zIndex: '1000',
		backgroundColor: theme.palette.common.white,
		borderBottomRightRadius: '10px',
		borderTopRightRadius: '10px',
		paddingLeft: '10px',
		boxShadow: `-3px 0px 3px -3px ${theme.palette.common.black}`
	},
	amexBorder: {
		borderTop: `1px solid ${theme.palette.creditCards.amex.base}`,
		borderBottom: `1px solid ${theme.palette.creditCards.amex.base}`,
		borderRight: `1px solid ${theme.palette.creditCards.amex.base}`
	},
	visaBorder: {
		borderTop: `1px solid ${theme.palette.creditCards.visa.base}`,
		borderBottom: `1px solid ${theme.palette.creditCards.visa.base}`,
		borderRight: `1px solid ${theme.palette.creditCards.visa.base}`
	},
	discoverBorder: {
		borderTop: `1px solid ${theme.palette.creditCards.discover.base}`,
		borderBottom: `1px solid ${theme.palette.creditCards.discover.base}`,
		borderRight: `1px solid ${theme.palette.creditCards.discover.base}`
	},
	mastercardBorder: {
		borderTop: `1px solid ${theme.palette.creditCards.masterCard.base}`,
		borderBottom: `1px solid ${theme.palette.creditCards.masterCard.base}`,
		borderRight: `1px solid ${theme.palette.creditCards.masterCard.base}`
	},
	percentageContainer: {
		display: 'flex',
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		fontSize: '2rem'
	},
	infoIcon: {
		color: theme.palette.dashboard.tiles.chargebackKpi.infoIcon,
		margin: '2px',
		fontSize: '1.2rem'
	},
	coloredTextAmex: {
		color: theme.palette.creditCards.amex.base
	},
	coloredTextDiscover: {
		color: theme.palette.creditCards.discover.base
	},
	coloredTextVisa: {
		color: theme.palette.creditCards.visa.base
	},
	coloredTextMastercard: {
		color: theme.palette.creditCards.masterCard.base
	}
});

const _generateCardClasses = (classes) => {
	return {
		amex: {
			border: classes.amexBorder,
			coloredText: classes.coloredTextAmex
		},
		visa: {
			border: classes.visaBorder,
			coloredText: classes.coloredTextVisa
		},
		discover: {
			border: classes.discoverBorder,
			coloredText: classes.coloredTextDiscover
		},
		mastercard: {
			border: classes.mastercardBorder,
			coloredText: classes.coloredTextMastercard
		}
	};
};

const ChargebackItem = (props) => {
	const { classes, association, t } = props;
	const {
		associationContainer,
		logoContainer,
		logo,
		statsContainer,
		percentageContainer,
		infoIcon
	} = classes;

	const cardClasses = _generateCardClasses(classes);

	return (
		<div key={association.uuid} className={associationContainer}>
			<div className={logoContainer}>
				{' '}
				<img src={logos[association.name]} height={90} className={logo} />
			</div>
			<div
				className={clsx(statsContainer, cardClasses[association.name].border)}
			>
				<div className={percentageContainer}>
					<span className={clsx(cardClasses[association.name].coloredText)}>
						{(association.chargebacks / association.transactions).toFixed(2)}%
					</span>
					<Tooltip
						title={
							<React.Fragment>
								<Typography color="inherit">{`${association.transactions} ${t(
									'components.dashboardTiles.chargebackKPIsTile.transactions'
								)}`}</Typography>
								<Typography color="inherit">{`${association.chargebacks} ${t(
									'components.dashboardTiles.chargebackKPIsTile.chargebacks'
								)}`}</Typography>
							</React.Fragment>
						}
					>
						<InfoOutlinedIcon className={infoIcon} />
					</Tooltip>
				</div>
			</div>
		</div>
	);
};

ChargebackItem.propTypes = {
	association: PropTypes.object,
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(ChargebackItem));
