import React, { useState } from 'react';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// import Tooltip from '@mui/material/Tooltip';

import AnnotatorModal from './AnnotatorModal_OLD';
import ScreenshotterIcon from './partials/screenshotterIcon';

const styles = (theme) => ({
	actionButton: {
		display: 'flex',
		borderRadius: '10px',
		margin: '10px',
		backgroundColor: theme.palette.navigationActionButton.background
	}
});

const Screenshotter = (props) => {
	const { classes, t, elementRef, label } = props;
	const { actionButton } = classes;

	const [annotatorIsOpen, setAnnotatorIsOpen] = useState(false);

	const handleOpenScreenshotterModal = () => {
		setAnnotatorIsOpen(!annotatorIsOpen);
	};

	return (
		// <Tooltip title={t('components.Layout.Navbar.actionButtons.screenshot')}> // Disabling because it stays present when you click it.
		<div className={actionButton}>
			<ScreenshotterIcon onClick={handleOpenScreenshotterModal} />
			<AnnotatorModal
				elementRef={elementRef}
				label={label}
				isOpen={annotatorIsOpen}
				onClose={() => setAnnotatorIsOpen(false)}
			/>
		</div>
		// </Tooltip>
	);
};

Screenshotter.propTypes = {
	classes: PropTypes.object,
	elementRef: PropTypes.object,
	label: PropTypes.string,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(Screenshotter));
