import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { reportViewsActions } from '../../containers/ReportViews';
import ReportsList from './ReportsList';
import Loader from '../Loader';

const styles = (theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		flex: 1
	}
});

export const ReportViewManager = (props) => {
	const {
		classes,
		reportsList,
		isLoadingReports,
		reportsHaveBeenLoaded,
		getReportViews,
		setReportTileBeingCreated
	} = props;
	const { container } = classes;

	React.useEffect(() => {
		if (!isLoadingReports && !reportsHaveBeenLoaded) {
			getReportViews();
		}
	}, [reportsList, isLoadingReports, reportsHaveBeenLoaded, getReportViews]);

	return (
		<div className={container}>
			{isLoadingReports ? (
				<Loader />
			) : (
				<ReportsList
					reportsList={reportsList}
					isLoading={isLoadingReports}
					setReportTileBeingCreated={setReportTileBeingCreated}
				/>
			)}
		</div>
	);
};

ReportViewManager.propTypes = {
	reportsList: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	isLoadingReports: PropTypes.bool,
	reportsHaveBeenLoaded: PropTypes.bool,
	getReportViews: PropTypes.func,
	getUserFavoritedReports: PropTypes.func,
	addUserFavoritedReport: PropTypes.func,
	removeUserFavoriteReport: PropTypes.func,
	setReportTileBeingCreated: PropTypes.func,
	classes: PropTypes.object
};

const mapStateToProps = (state) => {
	return {
		reportsList: state.reportViewsReducer.get('reportViews'),
		isLoadingReports: state.reportViewsReducer.get('isLoading'),
		reportsHaveBeenLoaded: state.reportViewsReducer.get('hasBeenLoaded'),
		reportsError: state.reportViewsReducer.get('error')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getReportViews: (payload) => {
			dispatch(reportViewsActions.getReportViews(payload));
		},
		setReportTileBeingCreated: (payload) => {
			dispatch(reportViewsActions.setReportTileBeingCreated(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(ReportViewManager));
