import React, { Fragment } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { sanitizeForecastDateRange } from '../../../../helpers/sanitizeKeys';
import SubHeaderRow from './components/SubHeaderRow';
import ChargebackPercentageRow from './components/ChargebackPercentageRow';
import ChargebacksRow from './components/ChargebacksRow';
import ChargebackUnitsOverRow from './components/ChargebackUnitsOverRow';
import TransactionsRow from './components/TransactionsRow';
import TransactionUnitsOverRow from './components/TransactionUnitsOverRow';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const styles = (theme) => ({
	header: {
		borderLeft: '1px solid black',
		fontWeight: '600'
	},
	root: {
		display: 'flex',
		maxWidth: '84vw',
		margin: '20px 0px 10px 0px'
	},
	row: {
		backgroundColor: theme.palette.navigation.background
	},
	rowStyle: {
		fontWeight: '600'
	},
	subHeader: {
		borderLeft: '1px solid black'
	}
});

const MonthlySummaryByMidTable = (props) => {
	const { classes, cardData } = props;
	const { header, root, row, rowStyle, subHeader } = classes;

	const _renderEmptyRow = () => {
		return (
			<Fragment>
				<TableCell colSpan={2}>&nbsp;</TableCell>
				<TableCell colSpan={4} className={subHeader}></TableCell>
				<TableCell colSpan={4} className={subHeader}></TableCell>
				<TableCell colSpan={4} className={subHeader}></TableCell>
			</Fragment>
		);
	};

	return (
		<TableContainer component={Paper} className={root}>
			<Table size="small">
				<TableHead>
					<TableRow>
						<TableCell colSpan={2} />
						{['daily', 'weekToDate', 'monthToDate'].map((column, idx) => {
							return (
								<Fragment key={idx}>
									<TableCell colSpan={2} className={header}>
										{sanitizeForecastDateRange(column)}
									</TableCell>
									<TableCell align="right" colSpan={2} className={rowStyle}>
										{cardData.dateRangeStart}
									</TableCell>
								</Fragment>
							);
						})}
					</TableRow>
					<TableRow>
						<SubHeaderRow cardData={cardData} />
					</TableRow>
				</TableHead>
				<TableBody>
					<TableRow className={row}>
						<ChargebacksRow cardData={cardData.data} />
					</TableRow>
					<TableRow>
						<TransactionsRow cardData={cardData.data} />
					</TableRow>
					<TableRow className={row}>{_renderEmptyRow()}</TableRow>
					<TableRow>
						<ChargebackPercentageRow cardData={cardData.data} />
					</TableRow>
				</TableBody>
			</Table>
		</TableContainer>
	);
};

MonthlySummaryByMidTable.propTypes = {
	cardData: PropTypes.object,
	classes: PropTypes.object
};

export default withStyles(styles)(MonthlySummaryByMidTable);

// HACK THIS DATA DOESN'T EXIST YET
/*
					<TableRow className={row}>
						<ChargebackUnitsOverRow cardData={cardData.data} />
					</TableRow>
					<TableRow>
						<TransactionUnitsOverRow cardData={cardData.data} />
					</TableRow>
					*/
