import { fromJS } from 'immutable';
import * as actionTypes from './actionTypes';

export const initialState = fromJS({
	cardOverview: {
		isLoading: false,
		hasBeenLoaded: false,
		error: undefined,
		data: {
			visa: {},
			mastercard: {},
			discover: {},
			amex: {}
		}
	},
	currentInventory: {
		isLoading: false,
		hasBeenLoaded: false,
		error: undefined,
		data: []
	}
});

export const currentInventoryReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_CARD_OVERVIEW:
			return state.setIn(['cardOverview', 'isLoading'], true);

		case actionTypes.GET_CARD_OVERVIEW_COMPLETED:
			return state.setIn(['cardOverview', 'isLoading'], false)
				.setIn(['cardOverview', 'hasBeenLoaded'], true)
				.setIn(['cardOverview', 'data'], action.payload);

		case actionTypes.GET_CARD_OVERVIEW_FAILED:
			return state.setIn(['cardOverview', 'isLoading'], false)
				.setIn(['cardOverview', 'error'], action.payload);

		case actionTypes.GET_CURRENT_INVENTORY:
			return state.setIn(['currentInventory', 'isLoading'], true);

		case actionTypes.GET_CURRENT_INVENTORY_COMPLETED:
			return state.setIn(['currentInventory', 'isLoading'], false)
				.setIn(['currentInventory', 'hasBeenLoaded'], true)
				.setIn(['currentInventory', 'data'], action.payload);

		case actionTypes.GET_CURRENT_INVENTORY_FAILED:
			return state.setIn(['currentInventory', 'isLoading'], false)
				.setIn(['currentInventory', 'error'], action.payload);

		default:
			return state;
	}
};
