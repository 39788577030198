import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@mui/styles';
import { withSize } from 'react-sizeme';
import DataViewer from '../../../DataViewer/DataViewer';

const styles = (theme) => ({
	container: {
		display: 'flex',
		flex: 1,
		flexDirection: 'row',
		alignSelf: 'stretch',
		marginTop: '2px'
	}
});

const _mockChartData = {
	lineKeys: ['Amex', 'Discover', 'MasterCard', 'Visa'],
	results: [
		{
			Amex: 4.74,
			Discover: 2.7,
			MasterCard: 0.44,
			Visa: 0.22,
			date: '12-31-2022'
		},
		{
			Amex: 4.99,
			Discover: 4.12,
			MasterCard: 0.8,
			Visa: 0.4,
			date: '01-31-2022'
		},
		{
			Amex: 6.59,
			Discover: 2.55,
			MasterCard: 0.62,
			Visa: 0.31,
			date: '02-28-2022'
		},
		{
			Amex: 4.02,
			Discover: 2.66,
			MasterCard: 0.502,
			Visa: 0.251,
			date: '03-31-2022'
		},
		{
			Amex: 4.55,
			Discover: 3.25,
			MasterCard: 0.7,
			Visa: 0.331,
			date: '04-30-2022'
		},
		{
			Amex: 3.75,
			Discover: 2.1,
			MasterCard: 0.88,
			Visa: 0.44,
			date: '05-31-2022'
		}
	]
};

const QuickView = (props) => {
	const { classes, size } = props;
	const { container, row } = classes;

	return (
		<div className={container}>
			<DataViewer
				reportData={_mockChartData}
				dataViewType={'line'}
				height={size.height * 0.95}
				width={size.width * 0.95}
			/>
		</div>
	);
};

QuickView.propTypes = {
	tileProperties: PropTypes.object,
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(
	withTranslation()(withSize({ monitorHeight: true })(QuickView))
);
