import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import { usersActions } from '../../containers/Users';
import MaterialTable from '../../components/Table/MaterialTable';
import Loader from '../../components/Loader';
import config from '../../config.json';
import { columns } from './partials/columns';

import Avatar from '@mui/material/Avatar';

const styles = (theme) => ({
	root: {
		display: 'flex',
		flexFlow: 'column',
		padding: 0,
		margin: '5vh 0px 0px 0px',
		alignItems: 'center'
	},
	table: {
		display: 'flex',
		justifyContent: 'center',
		[theme.breakpoints.up('tablet')]: {
			width: '80%'
		},
		[theme.breakpoints.down('tablet')]: {
			width: '100%'
		}
	}
});

const Users = (props) => {
	const { classes, getUsers, hasBeenLoaded, isLoading, users, t } = props;
	const { root, table } = classes;

	useEffect(() => {
		if (!hasBeenLoaded && !isLoading) {
			getUsers();
		}
	}, [getUsers, hasBeenLoaded, isLoading]);

	const columnTranslator = () => {
		const newColumns = [];
		columns.forEach((column) => {
			newColumns.push({
				title: t(column.title),
				field: column.field
			});
		});
		return newColumns;
	};

	const formatData = () => {
		const returnTableData = (user) => {
			const { avatar, email, firstName, lastName, phone, username } = user;
			return {
				id: email,
				avatar: (
					<Avatar
						alt={firstName[0]}
						src={
							avatar
								? `${config[process.env.REACT_APP_STAGE].assetsURI}${avatar}`
								: null
						}
					/>
				),
				email,
				name: `${firstName} ${lastName}`,
				phone,
				username
			};
		};
		return users.map(returnTableData);
	};

	return (
		<Container className={root} maxWidth={false}>
			{!hasBeenLoaded && !isLoading ? (
				<p>{t('generic.error.nodata')}</p>
			) : !hasBeenLoaded && isLoading ? (
				<Loader />
			) : (
				<div className={table}>
					<MaterialTable
						columns={columnTranslator()}
						data={formatData()}
						options={{
							padding: 'dense',
							paging: false,
							showTitle: false,
							toolbar: false
						}}
						style={{ fontSize: '.9rem', width: '90%' }}
					/>
				</div>
			)}
		</Container>
	);
};

Users.propTypes = {
	classes: PropTypes.object,
	getUsers: PropTypes.func,
	hasBeenLoaded: PropTypes.bool,
	isLoading: PropTypes.bool,
	users: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	t: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		hasBeenLoaded: state.usersReducer.get('hasBeenLoaded'),
		isLoading: state.usersReducer.get('isLoading'),
		users: state.usersReducer.get('users')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getUsers: () => {
			dispatch(usersActions.getUsers());
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(withTranslation()(Users)));
