import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import update from 'immutability-helper';
import deepCopy from '../../../../../../utilities/deepCopy';

import OrderTileListItem from './OrderTileListItem';

import Input from '@mui/material/Input';

const styles = (theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 6,
		padding: '20px'
	},
	instructionsRow: {
		display: 'flex',
		flexDirection: 'row',
		color: theme.palette.createLayoutModal.instructionsText,
		fontSize: '1.4rem',
		padding: '5px 10px 5px 10px'
	},
	namingRow: {
		display: 'flex',
		alignSelf: 'stretch',
		flexDirection: 'row',
		margin: '15px 10px 5px 10px',
		justifyContent: 'center'
	},
	namingInput: {
		flex: 0.5
	},
	tileListCenteredWrapper: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: '10px'
	},
	tileListContainer: {
		display: 'flex',
		flexFlow: 'column',
		margin: '10px',
		padding: '2px'
	}
});

export const NameAndOrderTiles = (props) => {
	const {
		classes,
		t,
		createdLayoutConfiguration,
		updateCreatedLayoutConfiguration,
		setCurrentStepCompleted
	} = props;
	const {
		container,
		instructionsRow,
		namingRow,
		namingInput,
		tileListCenteredWrapper,
		tileListContainer
	} = classes;

	const moveLayoutItem = React.useCallback(
		(dragIndex, hoverIndex) => {
			const tiles = deepCopy(createdLayoutConfiguration.tiles);
			const dragLayout = tiles[dragIndex];
			const updatedOrder = update(tiles, {
				$splice: [
					[dragIndex, 1],
					[hoverIndex, 0, dragLayout]
				]
			});

			updateCreatedLayoutConfiguration({
				...createdLayoutConfiguration,
				tiles: updatedOrder
			});
		},
		[createdLayoutConfiguration, updateCreatedLayoutConfiguration]
	);

	const onLayoutNameChange = (e) => {
		updateCreatedLayoutConfiguration({
			...createdLayoutConfiguration,
			name: e.target.value
		});

		if (e.target.value && e.target.value.length > 3) {
			setCurrentStepCompleted(true);
		} else {
			setCurrentStepCompleted(false);
		}
	};

	const renderTileListItem = (
		{ universalUniqueIdentifier, label, type, userGivenName },
		index
	) => {
		return (
			<OrderTileListItem
				key={universalUniqueIdentifier}
				index={index}
				universalUniqueIdentifier={universalUniqueIdentifier}
				label={label || userGivenName}
				type={type}
				moveLayoutItem={moveLayoutItem}
			/>
		);
	};

	return (
		<div className={container}>
			<div className={instructionsRow}>
				{t('components.modals.createLayout.instructions.nameAndOrder')}
			</div>
			<div className={namingRow}>
				<Input
					className={namingInput}
					placeholder="Layout Name"
					value={createdLayoutConfiguration.name}
					onChange={onLayoutNameChange}
				/>
			</div>
			<div className={tileListCenteredWrapper}>
				<div className={tileListContainer}>
					{createdLayoutConfiguration.tiles.map((tileListItem, index) => {
						return renderTileListItem(tileListItem, index);
					})}
				</div>
			</div>
		</div>
	);
};

NameAndOrderTiles.propTypes = {
	createdLayoutConfiguration: PropTypes.object,
	updateCreatedLayoutConfiguration: PropTypes.func,
	setCurrentStepCompleted: PropTypes.func,
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(NameAndOrderTiles));
