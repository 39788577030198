import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@mui/styles';
import ChargebackItem from './ChargebackItem';

import Stack from '@mui/material/Stack';

// HACK UNTIL WE SETUP THE REDUCER AND EPICS FOR THIS DATA
const associationData = [
	{
		name: 'mastercard',
		uuid: '497cd2c8-bbef-45d8-b9ed-e8a1606fd53c',
		transactions: 712,
		chargebacks: 1
	},
	{
		name: 'discover',
		uuid: 'c6eaf22e-d9c0-4cbe-9def-dc1468d8eea2',
		transactions: 54,
		chargebacks: 4
	},
	{
		name: 'visa',
		uuid: '3eb04cf1-3f47-44c9-864d-da586498180a',
		transactions: 257,
		chargebacks: 2
	},
	{
		name: 'amex',
		uuid: 'b768330a-6382-4f3e-886e-209e57d9f1bb',
		transactions: 123,
		chargebacks: 6
	}
];

const styles = (theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		flex: '1'
	},
	headerContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		padding: '3px'
	},
	headerText: {
		color: theme.palette.primary.main,
		fontSize: '1.2rem'
	},
	stack: {
		flex: 1,
		alignSelf: 'stretch'
	}
});

const ChargebackKpiTile = (props) => {
	const { classes } = props;
	const { stack } = classes;

	return (
		<Stack
			direction="column"
			spacing={2}
			mt={2}
			className={stack}
			justifyContent="space-between"
			alignItems="center"
		>
			{associationData.map((association) => {
				return (
					<ChargebackItem key={association.uuid} association={association} />
				);
			})}
		</Stack>
	);
};

ChargebackKpiTile.propTypes = {
	tileProperties: PropTypes.object,
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(ChargebackKpiTile));
