import React from 'react';

import {
	DateInput,
	DynamicInput,
	MultiSelectInput,
	SimpleSelectInput
} from '../../components/Widget/WidgetController/filterInputs';

// HACK FOR NOW - WE SHOULD FORMAT FILTERS / OPTIONS IN LAMBDA
const _formatMultiSelect = (value) => {
	let formatted = value;

	if (value && !Array.isArray(value)) {
		formatted = value.split(',');
	}

	return formatted;
};

export default function (options, filters, handleFilterUpdate) {
	const optionValues = [];

	if (options && Object.entries(options).length) {
		Object.entries(options).map(([label, option]) => {
			if (option.isDate) {
				optionValues.push(
					<DateInput
						key={label}
						onUpdate={handleFilterUpdate}
						label={label}
						option={option}
						value={filters[label]}
					/>
				);
			} else if (option.isMultiSelect) {
				const formattedFilters = _formatMultiSelect(filters[label]);
				optionValues.push(
					<MultiSelectInput
						key={label}
						onUpdate={handleFilterUpdate}
						label={label}
						option={option}
						value={formattedFilters}
					/>
				);
			} else if (option.isDynamicInput) {
				optionValues.push(
					<DynamicInput
						key={label}
						onUpdate={handleFilterUpdate}
						label={label}
						option={option}
						value={filters[label]}
					/>
				);
			} else {
				optionValues.push(
					<SimpleSelectInput
						key={label}
						onUpdate={handleFilterUpdate}
						label={label}
						option={option}
						value={filters[label]}
					/>
				);
			}
		});
	}

	return optionValues;
}
