import React, { Fragment } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
	getMidsByProcessor,
	handleProcessorUpdate,
	getMerchantIdsByLabel
} from '../../../../../helpers/dailyChargebackReport/twelveMonthLook/filterHelper';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { CircularProgress } from '@mui/material';

const styles = (theme) => ({
	valueSelect: {
		display: 'flex',
		flexFlow: 'row wrap',
		minWidth: '160px',
		fontSize: '1rem',
		margin: '0px 3px',
		backgroundColor: theme.palette.common.white
	},
	dateSelect: {
		fontSize: '.8rem',
		margin: '0px 3px',
		backgroundColor: theme.palette.common.white
	},
	submitButton: {
		marginLeft: '.8rem',
		cursor: 'pointer',
		'&hover': {
			backgroundColor: theme.palette.common.white
		}
	}
});

const OptionsForm = (props) => {
	const {
		classes,
		t,
		options,
		initialFilters,
		reportDataIsLoading,
		onSubmitFilters
	} = props;
	const { valueSelect, dateSelect, submitButton } = classes;

	const [lookupDate, setLookupDate] = React.useState(initialFilters.lookupDate);
	const [processor, setProcessor] = React.useState(initialFilters.processor);

	// const [selectedMids, setSelectedMids] = React.useState(
	// 	initialFilters.mids ? initialFilters.mids.map((mid) => mid.label) : []
	// );

	// @TODO this is hardcoding a single mid at the start. Use above for all selected
	const [selectedMids, setSelectedMids] = React.useState([
		'Fiserv - Saving Central'
	]);

	const [availableMids, setAvailableMids] = React.useState(initialFilters.mids);

	React.useEffect(() => {
		if (initialFilters.mids && selectedMids.legnth === 0) {
			const initialSelectedMids = initialFilters.mids.map((mid) => mid.label);
			setSelectedMids(initialSelectedMids || []);
		}
	}, [initialFilters, selectedMids, setSelectedMids]);

	const onProcessorUpdate = (event) => {
		const {
			target: { value }
		} = event;

		const updatedProcessor = handleProcessorUpdate(value, options);

		const updatedMids = getMidsByProcessor(updatedProcessor, options);

		setProcessor(updatedProcessor);
		setAvailableMids(updatedMids);
		setSelectedMids(updatedMids.map((mid) => mid.label));
	};

	const onMidsUpdate = (event) => {
		const {
			target: { value }
		} = event;

		setSelectedMids(value);
	};

	const onSubmit = (processor, mids, lookupDate) => {
		const processorLabel = processor.label;
		const formattedMids = getMerchantIdsByLabel(mids, options);

		onSubmitFilters(processorLabel, formattedMids, lookupDate);
	};

	return (
		<Fragment>
			<FormControl>
				<LocalizationProvider dateAdapter={AdapterDateFns}>
					<DatePicker
						label={t(
							'components.staticReportViews.DailyChargebackReport.MonthlySummaryByMid.OptionsSelector.lookupDate'
						)}
						value={lookupDate}
						onChange={(newValue) => {
							setLookupDate(newValue);
						}}
						renderInput={(params) => (
							<TextField {...params} size="small" className={dateSelect} />
						)}
					/>
				</LocalizationProvider>
			</FormControl>
			<FormControl>
				<InputLabel id="processor-label">
					{t(
						'components.staticReportViews.DailyChargebackReport.MonthlySummaryByMid.OptionsSelector.processor'
					)}
				</InputLabel>
				<Select
					labelId="processor-label"
					id="processor-select"
					size="small"
					label={t(
						'components.staticReportViews.DailyChargebackReport.MonthlySummaryByMid.OptionsSelector.processor'
					)}
					className={valueSelect}
					value={processor.label}
					onChange={onProcessorUpdate}
					renderValue={(selected) => {
						return <span>{selected}</span>;
					}}
				>
					{options.PROCESSOR.values.map((option) => (
						<MenuItem key={option.id} value={option.label}>
							{option.label}
						</MenuItem>
					))}
				</Select>
			</FormControl>
			<FormControl>
				<InputLabel id="mid-label">
					{t(
						'components.staticReportViews.DailyChargebackReport.MonthlySummaryByMid.OptionsSelector.mids'
					)}
				</InputLabel>
				<Select
					labelId="processor-label"
					id="processor-select"
					size="small"
					multiple
					label={t(
						'components.staticReportViews.DailyChargebackReport.MonthlySummaryByMid.OptionsSelector.mids'
					)}
					className={valueSelect}
					value={selectedMids}
					onChange={onMidsUpdate}
					renderValue={(selected) => {
						if (selected.length > 2) {
							return `${selected.length} Selected`;
						} else {
							return selected;
						}
					}}
				>
					{availableMids.map((option) => (
						<MenuItem key={option.label} value={option.label}>
							{option.label}
						</MenuItem>
					))}
				</Select>
			</FormControl>
			<Button
				className={submitButton}
				variant="outlined"
				size="small"
				onClick={() => {
					onSubmit(processor, selectedMids, lookupDate);
				}}
			>
				{reportDataIsLoading ? (
					<CircularProgress size="1rem" />
				) : (
					<span>{t('generic.buttons.submit')}</span>
				)}
			</Button>
		</Fragment>
	);
};

OptionsForm.propTypes = {
	options: PropTypes.object,
	initialFilters: PropTypes.object,
	reportDataIsLoading: PropTypes.bool,
	onSubmitFilters: PropTypes.func,
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(OptionsForm));
