import React from 'react';
import PropTypes from 'prop-types';
import { Sector } from 'recharts';

const RADIAN = Math.PI / 180;

const renderActiveShape = (props) => {
	const {
		cx,
		cy,
		innerRadius,
		outerRadius,
		midAngle,
		startAngle,
		endAngle,
		payload
	} = props;

	const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
	const x = cx + radius * Math.cos(-midAngle * RADIAN);
	const y = cy + radius * Math.sin(-midAngle * RADIAN);

	return (
		<g>
			<Sector
				cx={cx}
				cy={cy}
				innerRadius={innerRadius}
				outerRadius={outerRadius}
				startAngle={startAngle}
				endAngle={endAngle}
				fill={payload.fillColor}
			/>
			<Sector
				cx={cx}
				cy={cy}
				startAngle={startAngle}
				endAngle={endAngle}
				innerRadius={outerRadius + 6}
				outerRadius={outerRadius + 10}
				fill={payload.fillColor}
			/>
			<text
				x={x}
				y={y}
				fill="white"
				textAnchor="middle"
				dominantBaseline="central"
			>
				{`${payload.value.toFixed(0)}%`}
			</text>
		</g>
	);
};

renderActiveShape.propTypes = {
	cx: PropTypes.number,
	cy: PropTypes.number,
	innerRadius: PropTypes.number,
	outerRadius: PropTypes.number,
	startAngle: PropTypes.number,
	endAngle: PropTypes.number,
	payload: PropTypes.object
};

export default renderActiveShape;
