import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import Filters from './Filters';
import BreakdownByWinsMisses from './BreakdownByWinsMisses';
import BreakDownByCard from './BreakdownByCard/BreakdownByCard';
import BreakdownByChargebackReason from './BreakdownByChargebackReason/BreakdownByChargebackReason';

const styles = (theme) => ({
	container: {
		display: 'flex',
		flexFlow: 'column'
	}
});

export const RecoveryHistoryManager = (props) => {
	const { classes } = props;
	const { container } = classes;

	const [dateRange, setDateRange] = React.useState('30d');
	const [selectedCards, setSelectedCards] = React.useState([
		'visa',
		'mastercard',
		'discover',
		'amex'
	]);

	return (
		<div className={container}>
			<Filters
				dateRange={dateRange}
				setDateRange={setDateRange}
				selectedCards={selectedCards}
				setSelectedCards={setSelectedCards}
			/>
			<BreakdownByWinsMisses dateRange={dateRange} />
			<BreakDownByCard dateRange={dateRange} />
			<BreakdownByChargebackReason dateRange={dateRange} />
		</div>
	);
};

RecoveryHistoryManager.propTypes = {
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(RecoveryHistoryManager);
