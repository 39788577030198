import moment from 'moment';
import querier from '../../../utilities/querier';

const _replaceableFields = [
	'{membershipStartDate}',
	'{customerServicePhone}',
	'{cancelRequestedOn}',
	'{refundRequestLetterSentOn}',
	'{billingDescriptor}',
	'{refundDate}',
	'{membershipFee}',
	'{welcomeLetterMailedOn}'
];

const _dateFields = [
	'{membershipStartDate}',
	'{cancelRequestedOn}',
	'{refundRequestLetterSentOn}',
	'{refundDate}',
	'welcomeLetterMailedOn'
];

const _fieldMap = {
	'{membershipStartDate}': 'chargebackReversalSupplementalData.membershipStartDate',
	'{customerServicePhone}': 'overview.customerServicePhone',
	'{cancelRequestedOn}': 'chargebackReversalSupplementalData.cancelRequestedOn',
	'{refundRequestLetterSentOn}': 'chargebackReversalSupplementalData.refundRequestLetterSentOn',
	'{billingDescriptor}': 'overview.billingDescriptor',
	'{refundDate}': '[$.creditTransactions.settledOn][0]',
	'{membershipFee}': 'chargebackReversalSupplementalData.membershipFee',
	'{welcomeLetterMailedOn}': 'chargebackReversalSupplementalData.welcomeLetterMailedOn'
};

export default function (reason, representmentData) {
	const fieldsToReplace = [];

	_replaceableFields.forEach((field) => {
		if (reason.indexOf(field) > -1) {
			fieldsToReplace.push(field);
		}
	});

	let replacedText = reason;

	fieldsToReplace.forEach((field) => {
		const query = _fieldMap[field];

		let dataToInject = querier(representmentData, query);

		if (_dateFields.includes(field)) {
			dataToInject = moment(dataToInject).format('YYYY-MM-DD');
		}

		replacedText = replacedText.replace(field, dataToInject);
	});

	return replacedText;
}
