import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import PageTitle from '../../components/CurrentInventory/PageTitle';
import CurrentInventoryManager from '../../components/CurrentInventory/CurrentInventoryManager';

import Container from '@mui/material/Container';

const styles = (theme) => ({
	root: {
		display: 'flex',
		flexFlow: 'column',
		padding: '40px',
		[theme.breakpoints.up('tablet')]: {
			width: '70%'
		},
		[theme.breakpoints.down('tablet')]: {
			width: '100%'
		}
	}
});

export const CurrentInventory = (props) => {
	const { classes } = props;
	const { root } = classes;

	return (
		<Container className={root} maxWidth={false}>
			<PageTitle />
			<CurrentInventoryManager />
		</Container>
	);
};

CurrentInventory.propTypes = {
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(CurrentInventory);
