import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';

import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';

import WarningAmberIcon from '@mui/icons-material/WarningAmber';

const styles = (theme) => ({
	container: {
		display: 'flex',
		flexFlow: 'column',
		padding: '5px',
		justifyContent: 'center'
	},
	errorIcon: {
		color: theme.palette.warning.main,
		fontSize: '2rem'
	},
	errorText: {
		color: theme.palette.common.black
	}
});

export const NoData = (props) => {
	const { classes, t, height, width } = props;
	const { container, errorIcon, errorText } = classes;

	return (
		<Container className={container} style={{ height, width }}>
			<Stack
				direction="column"
				spacing={2}
				pt={2}
				pb={2}
				alignItems="center"
				justifyContent="center"
			>
				<WarningAmberIcon className={errorIcon} />
				<span className={errorText}>
					{t('components.dashboardTiles.DashboardWidgetTile.noData')}
				</span>
			</Stack>
		</Container>
	);
};

NoData.propTypes = {
	height: PropTypes.number,
	width: PropTypes.number,
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(NoData));
