export default (groupBy) => {
	if (groupBy === 'date') {
		return [
			{
				key: '2022-05-13',
				total: '119.80',
				data: [
					{
						caseId: '721313681201',
						billingLastName: 'GREENE',
						billingFirstName: 'ALLAN',
						receivedByMerchantOn: '2022-05-13T07:30:42.000Z',
						amount: 29.95,
						merchantId: '345045106886',
						code: '37',
						dueDate: '2022-05-28T07:30:42.000Z',
						paymentEnablerAcquiringBanksLabel: 'FISERV',
						paymentCardBrand: 'M'
					},
					{
						caseId: '721313678801',
						billingLastName: 'GREENE',
						billingFirstName: 'ALLAN',
						receivedByMerchantOn: '2022-05-13T07:30:42.000Z',
						amount: 29.95,
						merchantId: '345045106886',
						code: '37',
						dueDate: '2022-05-28T07:30:42.000Z',
						paymentEnablerAcquiringBanksLabel: 'FISERV',
						paymentCardBrand: 'M'
					},
					{
						caseId: '721313681101',
						billingLastName: 'GREENE',
						billingFirstName: 'ALLAN',
						receivedByMerchantOn: '2022-05-13T07:30:42.000Z',
						amount: 29.95,
						merchantId: '345045106886',
						code: '37',
						dueDate: '2022-05-28T07:30:42.000Z',
						paymentEnablerAcquiringBanksLabel: 'FISERV',
						paymentCardBrand: 'M'
					},
					{
						caseId: '721313678901',
						billingLastName: 'FUNAKI',
						billingFirstName: 'SELVIAVI',
						receivedByMerchantOn: '2022-05-13T07:30:42.000Z',
						amount: 29.95,
						merchantId: '345045106886',
						code: '37',
						dueDate: '2022-05-28T07:30:42.000Z',
						paymentEnablerAcquiringBanksLabel: 'FISERV',
						paymentCardBrand: 'M'
					}
				]
			},
			{
				key: '2022-05-14',
				total: '201.65',
				data: [
					{
						caseId: '813034933702',
						billingLastName: 'ANDERSON',
						billingFirstName: 'JOANNE',
						receivedByMerchantOn: '2022-05-14T07:30:34.000Z',
						amount: 29.95,
						merchantId: '345045106886',
						code: '1040',
						dueDate: '2022-05-29T07:30:34.000Z',
						paymentEnablerAcquiringBanksLabel: 'FISERV',
						paymentCardBrand: 'V'
					},
					{
						caseId: '721323001001',
						billingLastName: 'MCINTOSH',
						billingFirstName: 'VERGON',
						receivedByMerchantOn: '2022-05-14T07:30:34.000Z',
						amount: 27.95,
						merchantId: '345045106886',
						code: '37',
						dueDate: '2022-05-29T07:30:34.000Z',
						paymentEnablerAcquiringBanksLabel: 'FISERV',
						paymentCardBrand: 'M'
					},
					{
						caseId: '813034933902',
						billingLastName: 'ANDERSON',
						billingFirstName: 'JOANNE',
						receivedByMerchantOn: '2022-05-14T07:30:34.000Z',
						amount: 29.95,
						merchantId: '345045106886',
						code: '1040',
						dueDate: '2022-05-29T07:30:34.000Z',
						paymentEnablerAcquiringBanksLabel: 'FISERV',
						paymentCardBrand: 'V'
					},
					{
						caseId: '813034933802',
						billingLastName: 'ANDERSON',
						billingFirstName: 'JOANNE',
						receivedByMerchantOn: '2022-05-14T07:30:34.000Z',
						amount: 29.95,
						merchantId: '345045106886',
						code: '1040',
						dueDate: '2022-05-29T07:30:34.000Z',
						paymentEnablerAcquiringBanksLabel: 'FISERV',
						paymentCardBrand: 'V'
					},
					{
						caseId: '721323001201',
						billingLastName: 'MCINTOSH',
						billingFirstName: 'VERGON',
						receivedByMerchantOn: '2022-05-14T07:30:34.000Z',
						amount: 27.95,
						merchantId: '345045106886',
						code: '37',
						dueDate: '2022-05-29T07:30:34.000Z',
						paymentEnablerAcquiringBanksLabel: 'FISERV',
						paymentCardBrand: 'M'
					},
					{
						caseId: '812953005502',
						billingLastName: 'LINDO',
						billingFirstName: 'EDGAR',
						receivedByMerchantOn: '2022-05-14T07:30:34.000Z',
						amount: 27.95,
						merchantId: '345045110888',
						code: '1040',
						dueDate: '2022-05-29T07:30:34.000Z',
						paymentEnablerAcquiringBanksLabel: 'FISERV',
						paymentCardBrand: 'V'
					},
					{
						caseId: '812953005402',
						billingLastName: 'LINDO',
						billingFirstName: 'EDGAR',
						receivedByMerchantOn: '2022-05-14T07:30:34.000Z',
						amount: 27.95,
						merchantId: '345045110888',
						code: '1040',
						dueDate: '2022-05-29T07:30:34.000Z',
						paymentEnablerAcquiringBanksLabel: 'FISERV',
						paymentCardBrand: 'V'
					}
				]
			},
			{
				key: '2022-05-15',
				total: '34.95',
				data: [
					{
						caseId: '721333004001',
						billingLastName: 'KJENSLEE',
						billingFirstName: 'ALICIA',
						receivedByMerchantOn: '2022-05-15T07:35:14.000Z',
						amount: 34.95,
						merchantId: '345045106886',
						code: '37',
						dueDate: '2022-05-30T07:35:14.000Z',
						paymentEnablerAcquiringBanksLabel: 'FISERV',
						paymentCardBrand: 'M'
					}
				]
			},
			{
				key: '2022-05-16',
				total: '34.95',
				data: [
					{
						caseId: '821334271401',
						billingLastName: 'RESNICK',
						billingFirstName: 'LEONARD',
						receivedByMerchantOn: '2022-05-16T07:30:20.000Z',
						amount: 34.95,
						merchantId: '345045106886',
						code: '1040',
						dueDate: '2022-05-31T07:30:20.000Z',
						paymentEnablerAcquiringBanksLabel: 'FISERV',
						paymentCardBrand: 'V'
					}
				]
			},
			{
				key: '2022-05-17',
				total: '136.75',
				data: [
					{
						caseId: '521360015601',
						billingLastName: 'SAMPLE',
						billingFirstName: 'ROHLAND',
						receivedByMerchantOn: '2022-05-17T07:30:17.000Z',
						amount: 24.95,
						merchantId: '345045109880',
						code: '7030',
						dueDate: '2022-06-01T07:30:17.000Z',
						paymentEnablerAcquiringBanksLabel: 'FISERV',
						paymentCardBrand: 'D'
					},
					{
						caseId: '521360025701',
						billingLastName: 'CLARK',
						billingFirstName: 'RICHARD',
						receivedByMerchantOn: '2022-05-17T07:30:17.000Z',
						amount: 34.95,
						merchantId: '345045109880',
						code: '7030',
						dueDate: '2022-06-01T07:30:17.000Z',
						paymentEnablerAcquiringBanksLabel: 'FISERV',
						paymentCardBrand: 'D'
					},
					{
						caseId: '521360015501',
						billingLastName: 'SAMPLE',
						billingFirstName: 'ROHLAND',
						receivedByMerchantOn: '2022-05-17T07:30:17.000Z',
						amount: 24.95,
						merchantId: '345045109880',
						code: '7030',
						dueDate: '2022-06-01T07:30:17.000Z',
						paymentEnablerAcquiringBanksLabel: 'FISERV',
						paymentCardBrand: 'D'
					},
					{
						caseId: '521360015401',
						billingLastName: 'SAMPLE',
						billingFirstName: 'ROHLAND',
						receivedByMerchantOn: '2022-05-17T07:30:17.000Z',
						amount: 24.95,
						merchantId: '345045109880',
						code: '7030',
						dueDate: '2022-06-01T07:30:17.000Z',
						paymentEnablerAcquiringBanksLabel: 'FISERV',
						paymentCardBrand: 'D'
					},
					{
						caseId: '521360026601',
						billingLastName: 'FONSECA',
						billingFirstName: 'LUIS',
						receivedByMerchantOn: '2022-05-17T07:30:17.000Z',
						amount: 26.95,
						merchantId: '345045110888',
						code: '7030',
						dueDate: '2022-06-01T07:30:17.000Z',
						paymentEnablerAcquiringBanksLabel: 'FISERV',
						paymentCardBrand: 'D'
					}
				]
			},
			{
				key: '2022-05-18',
				total: '110.80',
				data: [
					{
						caseId: '721363479901',
						billingLastName: 'THOMAS',
						billingFirstName: 'MARGARET',
						receivedByMerchantOn: '2022-05-18T07:30:16.000Z',
						amount: 27.95,
						merchantId: '345045106886',
						code: '37',
						dueDate: '2022-06-02T07:30:16.000Z',
						paymentEnablerAcquiringBanksLabel: 'FISERV',
						paymentCardBrand: 'M'
					},
					{
						caseId: '721363487601',
						billingLastName: 'LABOUNTY',
						billingFirstName: 'GLENN',
						receivedByMerchantOn: '2022-05-18T07:30:16.000Z',
						amount: 27.95,
						merchantId: '345045106886',
						code: '37',
						dueDate: '2022-06-02T07:30:16.000Z',
						paymentEnablerAcquiringBanksLabel: 'FISERV',
						paymentCardBrand: 'M'
					},
					{
						caseId: '721363502401',
						billingLastName: 'BESSIERES',
						billingFirstName: 'GRACE',
						receivedByMerchantOn: '2022-05-18T07:30:16.000Z',
						amount: 24.95,
						merchantId: '345045106886',
						code: '37',
						dueDate: '2022-06-02T07:30:16.000Z',
						paymentEnablerAcquiringBanksLabel: 'FISERV',
						paymentCardBrand: 'M'
					},
					{
						caseId: '821344289801',
						billingLastName: 'KILGORE',
						billingFirstName: 'JACK',
						receivedByMerchantOn: '2022-05-18T07:30:16.000Z',
						amount: 29.95,
						merchantId: '345045106886',
						code: '1320',
						dueDate: '2022-06-02T07:30:16.000Z',
						paymentEnablerAcquiringBanksLabel: 'FISERV',
						paymentCardBrand: 'V'
					}
				]
			}
		];
	}

	if (groupBy === 'cardType') {
		return [
			{ key: 'amex', total: 0, data: [] },
			{
				key: 'discover',
				total: '136.75',
				data: [
					{
						caseId: '521360015601',
						billingLastName: 'ACANTILADO',
						billingFirstName: 'ROLANDO',
						receivedByMerchantOn: '2022-05-17T07:30:17.000Z',
						amount: 24.95,
						merchantId: '345045109880',
						code: '7030',
						dueDate: '2022-05-24T07:30:17.000Z',
						paymentEnablerAcquiringBanksLabel: 'FISERV',
						paymentCardBrand: 'D'
					},
					{
						caseId: '521360025701',
						billingLastName: 'CLARK',
						billingFirstName: 'RICHARD',
						receivedByMerchantOn: '2022-05-17T07:30:17.000Z',
						amount: 34.95,
						merchantId: '345045109880',
						code: '7030',
						dueDate: '2022-05-24T07:30:17.000Z',
						paymentEnablerAcquiringBanksLabel: 'FISERV',
						paymentCardBrand: 'D'
					},
					{
						caseId: '521360015501',
						billingLastName: 'ACANTILADO',
						billingFirstName: 'ROLANDO',
						receivedByMerchantOn: '2022-05-17T07:30:17.000Z',
						amount: 24.95,
						merchantId: '345045109880',
						code: '7030',
						dueDate: '2022-05-24T07:30:17.000Z',
						paymentEnablerAcquiringBanksLabel: 'FISERV',
						paymentCardBrand: 'D'
					},
					{
						caseId: '521360015401',
						billingLastName: 'ACANTILADO',
						billingFirstName: 'ROLANDO',
						receivedByMerchantOn: '2022-05-17T07:30:17.000Z',
						amount: 24.95,
						merchantId: '345045109880',
						code: '7030',
						dueDate: '2022-05-24T07:30:17.000Z',
						paymentEnablerAcquiringBanksLabel: 'FISERV',
						paymentCardBrand: 'D'
					},
					{
						caseId: '521360026601',
						billingLastName: 'FONSECA',
						billingFirstName: 'LUIS',
						receivedByMerchantOn: '2022-05-17T07:30:17.000Z',
						amount: 26.95,
						merchantId: '345045110888',
						code: '7030',
						dueDate: '2022-05-24T07:30:17.000Z',
						paymentEnablerAcquiringBanksLabel: 'FISERV',
						paymentCardBrand: 'D'
					}
				]
			},
			{
				key: 'mastercard',
				total: '291.50',
				data: [
					{
						caseId: '721313681201',
						billingLastName: 'GREENE',
						billingFirstName: 'ALLAN',
						receivedByMerchantOn: '2022-05-13T07:30:42.000Z',
						amount: 29.95,
						merchantId: '345045106886',
						code: '37',
						dueDate: '2022-05-20T07:30:42.000Z',
						paymentEnablerAcquiringBanksLabel: 'FISERV',
						paymentCardBrand: 'M'
					},
					{
						caseId: '721313678801',
						billingLastName: 'GREENE',
						billingFirstName: 'ALLAN',
						receivedByMerchantOn: '2022-05-13T07:30:42.000Z',
						amount: 29.95,
						merchantId: '345045106886',
						code: '37',
						dueDate: '2022-05-20T07:30:42.000Z',
						paymentEnablerAcquiringBanksLabel: 'FISERV',
						paymentCardBrand: 'M'
					},
					{
						caseId: '721313681101',
						billingLastName: 'GREENE',
						billingFirstName: 'ALLAN',
						receivedByMerchantOn: '2022-05-13T07:30:42.000Z',
						amount: 29.95,
						merchantId: '345045106886',
						code: '37',
						dueDate: '2022-05-20T07:30:42.000Z',
						paymentEnablerAcquiringBanksLabel: 'FISERV',
						paymentCardBrand: 'M'
					},
					{
						caseId: '721313678901',
						billingLastName: 'FUNAKI',
						billingFirstName: 'SELVIAVI',
						receivedByMerchantOn: '2022-05-13T07:30:42.000Z',
						amount: 29.95,
						merchantId: '345045106886',
						code: '37',
						dueDate: '2022-05-20T07:30:42.000Z',
						paymentEnablerAcquiringBanksLabel: 'FISERV',
						paymentCardBrand: 'M'
					},
					{
						caseId: '721323001001',
						billingLastName: 'MCINTOSH',
						billingFirstName: 'VERGON',
						receivedByMerchantOn: '2022-05-14T07:30:34.000Z',
						amount: 27.95,
						merchantId: '345045106886',
						code: '37',
						dueDate: '2022-05-21T07:30:34.000Z',
						paymentEnablerAcquiringBanksLabel: 'FISERV',
						paymentCardBrand: 'M'
					},
					{
						caseId: '721323001201',
						billingLastName: 'MCINTOSH',
						billingFirstName: 'VERGON',
						receivedByMerchantOn: '2022-05-14T07:30:34.000Z',
						amount: 27.95,
						merchantId: '345045106886',
						code: '37',
						dueDate: '2022-05-21T07:30:34.000Z',
						paymentEnablerAcquiringBanksLabel: 'FISERV',
						paymentCardBrand: 'M'
					},
					{
						caseId: '721333004001',
						billingLastName: 'KJENSLEE',
						billingFirstName: 'ALICIA',
						receivedByMerchantOn: '2022-05-15T07:35:14.000Z',
						amount: 34.95,
						merchantId: '345045106886',
						code: '37',
						dueDate: '2022-05-22T07:35:14.000Z',
						paymentEnablerAcquiringBanksLabel: 'FISERV',
						paymentCardBrand: 'M'
					},
					{
						caseId: '721363479901',
						billingLastName: 'THOMAS',
						billingFirstName: 'MARGARET',
						receivedByMerchantOn: '2022-05-18T07:30:16.000Z',
						amount: 27.95,
						merchantId: '345045106886',
						code: '37',
						dueDate: '2022-05-25T07:30:16.000Z',
						paymentEnablerAcquiringBanksLabel: 'FISERV',
						paymentCardBrand: 'M'
					},
					{
						caseId: '721363487601',
						billingLastName: 'LABOUNTY',
						billingFirstName: 'GLENN',
						receivedByMerchantOn: '2022-05-18T07:30:16.000Z',
						amount: 27.95,
						merchantId: '345045106886',
						code: '37',
						dueDate: '2022-05-25T07:30:16.000Z',
						paymentEnablerAcquiringBanksLabel: 'FISERV',
						paymentCardBrand: 'M'
					},
					{
						caseId: '721363502401',
						billingLastName: 'BESSIERES',
						billingFirstName: 'GRACE',
						receivedByMerchantOn: '2022-05-18T07:30:16.000Z',
						amount: 24.95,
						merchantId: '345045106886',
						code: '37',
						dueDate: '2022-05-25T07:30:16.000Z',
						paymentEnablerAcquiringBanksLabel: 'FISERV',
						paymentCardBrand: 'M'
					}
				]
			},
			{
				key: 'visa',
				total: '210.65',
				data: [
					{
						caseId: '813034933702',
						billingLastName: 'ANDERSON',
						billingFirstName: 'JOANNE',
						receivedByMerchantOn: '2022-05-14T07:30:34.000Z',
						amount: 29.95,
						merchantId: '345045106886',
						code: '1040',
						dueDate: '2022-05-21T07:30:34.000Z',
						paymentEnablerAcquiringBanksLabel: 'FISERV',
						paymentCardBrand: 'V'
					},
					{
						caseId: '813034933902',
						billingLastName: 'ANDERSON',
						billingFirstName: 'JOANNE',
						receivedByMerchantOn: '2022-05-14T07:30:34.000Z',
						amount: 29.95,
						merchantId: '345045106886',
						code: '1040',
						dueDate: '2022-05-21T07:30:34.000Z',
						paymentEnablerAcquiringBanksLabel: 'FISERV',
						paymentCardBrand: 'V'
					},
					{
						caseId: '813034933802',
						billingLastName: 'ANDERSON',
						billingFirstName: 'JOANNE',
						receivedByMerchantOn: '2022-05-14T07:30:34.000Z',
						amount: 29.95,
						merchantId: '345045106886',
						code: '1040',
						dueDate: '2022-05-21T07:30:34.000Z',
						paymentEnablerAcquiringBanksLabel: 'FISERV',
						paymentCardBrand: 'V'
					},
					{
						caseId: '812953005502',
						billingLastName: 'LINDO',
						billingFirstName: 'EDGAR',
						receivedByMerchantOn: '2022-05-14T07:30:34.000Z',
						amount: 27.95,
						merchantId: '345045110888',
						code: '1040',
						dueDate: '2022-05-21T07:30:34.000Z',
						paymentEnablerAcquiringBanksLabel: 'FISERV',
						paymentCardBrand: 'V'
					},
					{
						caseId: '812953005402',
						billingLastName: 'LINDO',
						billingFirstName: 'EDGAR',
						receivedByMerchantOn: '2022-05-14T07:30:34.000Z',
						amount: 27.95,
						merchantId: '345045110888',
						code: '1040',
						dueDate: '2022-05-21T07:30:34.000Z',
						paymentEnablerAcquiringBanksLabel: 'FISERV',
						paymentCardBrand: 'V'
					},
					{
						caseId: '821334271401',
						billingLastName: 'RESNICK',
						billingFirstName: 'LEONARD',
						receivedByMerchantOn: '2022-05-16T07:30:20.000Z',
						amount: 34.95,
						merchantId: '345045106886',
						code: '1040',
						dueDate: '2022-05-23T07:30:20.000Z',
						paymentEnablerAcquiringBanksLabel: 'FISERV',
						paymentCardBrand: 'V'
					},
					{
						caseId: '821344289801',
						billingLastName: 'KILGORE',
						billingFirstName: 'JACK',
						receivedByMerchantOn: '2022-05-18T07:30:16.000Z',
						amount: 29.95,
						merchantId: '345045106886',
						code: '1320',
						dueDate: '2022-05-25T07:30:16.000Z',
						paymentEnablerAcquiringBanksLabel: 'FISERV',
						paymentCardBrand: 'V'
					}
				]
			}
		];
	}
};
