import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { withStyles } from '@mui/styles';
import deepCopy from '../../../utilities/deepCopy';
import { tilesActions } from '../../../containers/Tiles';
import HeaderRow from '../shared/HeaderRow';
import safeGenerateInitialFilters from '../../../helpers/tiles/safeGenerateInitialFilters';
import { noCase } from 'change-case';

import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';

import Button from '@mui/material/Button';

import DeleteConfirmation from './DeleteConfirmation';
import TileSettingsList from './TileSettingsList';

const styles = (theme) => ({
	modal: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center'
	},
	paper: {
		display: 'flex',
		flexFlow: 'column',
		padding: '1rem',
		[theme.breakpoints.up('smallLaptop')]: {
			width: '50%'
		},
		[theme.breakpoints.down('smallLaptop')]: {
			width: '80%'
		}
	},
	actionSection: {
		display: 'flex',
		flex: 1,
		flexDirection: 'row-reverse'
	},
	deleteButtonContainer: {
		display: 'flex',
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'flex-start'
	},
	inputButton: {
		borderRadius: '4px',
		width: '100px',
		border: 'none',
		marginLeft: '4px',
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.secondary.alt,
		padding: '5px',
		fontSize: '16px',
		fontWeight: '300',
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
			cursor: 'pointer'
		}
	}
});

export const EditTileModal = (props) => {
	const {
		classes,
		t,
		tileBeingModified,
		setTileBeingModified,
		layoutTiles
	} = props;
	const {
		modal,
		paper,
		actionSection,
		deleteButtonContainer,
		inputButton
	} = classes;

	const tile = tileBeingModified || {};

	const [filters, setFilters] = React.useState({});
	const [dataViewStyle, setDataViewStyle] = React.useState();
	const [
		displayDeleteConfirmation,
		setDisplayDeleteConfirmation
	] = React.useState(false);

	React.useEffect(() => {
		if (
			tileBeingModified &&
			Object.keys(filters).length === 0 &&
			Object.keys(tileBeingModified.options).length > 0
		) {
			setFilters(safeGenerateInitialFilters(tileBeingModified));
			setDataViewStyle(tileBeingModified.dataViewStyle);
		}
	}, [tileBeingModified, setTileBeingModified]);

	const onCloseModal = () => {
		const { setTileBeingModified } = props;

		setFilters({});
		setDisplayDeleteConfirmation(false);
		setTileBeingModified(undefined);
	};

	const onFilterUpdate = (key, value) => {
		const updatedFilters = deepCopy(filters);
		updatedFilters[key] = value;

		setFilters(updatedFilters);
	};

	const onTitleUpdate = (value) => {
		const { tileBeingModified } = props;

		const updatedTile = deepCopy(tileBeingModified);
		updatedTile.label = value;

		setTileBeingModified(updatedTile);
	};

	const onSubmit = () => {
		const { tileBeingModified, updateLayoutTile } = props;

		const updateData = {
			layoutUniversalUniqueIdentifier:
				tileBeingModified.layoutUniversalUniqueIdentifier,
			universalUniqueIdentifier: tileBeingModified.universalUniqueIdentifier,
			data: {
				label: tileBeingModified.label,
				reportId: tileBeingModified.reportId,
				dataViewStyle: noCase(dataViewStyle),
				updatedOn: moment.utc().format('YYYY-MM-DD'),
				filters
			}
		};

		updateLayoutTile(updateData);
		onCloseModal();
	};

	const onDelete = () => {
		setDisplayDeleteConfirmation(true);
	};

	const onCancelDelete = () => {
		setDisplayDeleteConfirmation(false);
	};

	const onConfirmDelete = () => {
		const { deleteLayoutTile } = props;

		deleteLayoutTile({
			layoutUniversalUniqueIdentifier:
				tileBeingModified.layoutUniversalUniqueIdentifier,
			tileUniversalUniqueIdentifier: tileBeingModified.universalUniqueIdentifier
		});
	};

	return (
		<Modal
			open={tileBeingModified ? true : false}
			onClose={onCloseModal}
			className={modal}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Paper className={paper} elevation={1}>
				<HeaderRow
					translationPath={'components.modals.EditTile.header'}
					onClickCancel={onCloseModal}
				/>
				{displayDeleteConfirmation ? (
					<DeleteConfirmation
						onConfirmDelete={onConfirmDelete}
						onCancelDelete={onCancelDelete}
						layoutTiles={layoutTiles}
						tile={tile}
						onCloseModal={onCloseModal}
					/>
				) : (
					<TileSettingsList
						tile={tile}
						onTitleUpdate={onTitleUpdate}
						filters={filters}
						onFilterUpdate={onFilterUpdate}
						dataViewStyle={dataViewStyle}
						setDataViewStyle={setDataViewStyle}
					/>
				)}
				{!displayDeleteConfirmation && (
					<div className={actionSection}>
						<Button
							variant="contained"
							size="small"
							className={inputButton}
							type="submit"
							onClick={onSubmit}
						>
							{t('generic.buttons.submit')}
						</Button>
						<Button
							variant="contained"
							size="small"
							className={inputButton}
							type="submit"
							onClick={onCloseModal}
						>
							{t('generic.buttons.cancel')}
						</Button>
						<div className={deleteButtonContainer}>
							<Button
								variant="contained"
								size="small"
								className={inputButton}
								type="submit"
								onClick={onDelete}
							>
								{t('generic.buttons.delete')}
							</Button>
						</div>
					</div>
				)}
			</Paper>
		</Modal>
	);
};

EditTileModal.propTypes = {
	closeModal: PropTypes.func,
	updateLayoutTile: PropTypes.func,
	layoutTiles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	classes: PropTypes.object,
	t: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		tileBeingModified: state.tilesReducer.get('tileBeingModified'),
		layoutTiles: state.tilesReducer.get('layoutTiles')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setTileBeingModified: (payload) => {
			dispatch(tilesActions.setTileBeingModified(payload));
		},
		updateLayoutTile: (payload) => {
			dispatch(tilesActions.updateLayoutTile(payload));
		},
		deleteLayoutTile: (payload) => {
			dispatch(tilesActions.deleteTileFromLayout(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(withTranslation()(EditTileModal)));
