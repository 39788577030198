import * as actionTypes from './actionTypes';

export const getUsers = (payload) => ({
	type: actionTypes.GET_USERS,
	payload
});

export const getUsersCompleted = (payload) => ({
	type: actionTypes.GET_USERS_COMPLETED,
	payload
});

export const getUsersFailed = (payload) => ({
	type: actionTypes.GET_USERS_FAILED,
	payload
});
