import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import Branding from './partials/Branding';
import Navigation from './partials/Navigation';
import SearchBar from './partials/SearchBar';
import ActionSection from './partials/ActionSection';
import Profile from './partials/Profile';

const styles = (theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'row',
		margin: '0px',
		position: 'fixed',
		width: '100vw',
		height: theme.panel.height,
		backgroundColor: theme.palette.navigation.background,
		boxShadow: `0px 1px 3px ${theme.palette.navigation.shadow}`,
		zIndex: 999
	}
});

const Header = (props) => {
	const { classes, dashboardRef } = props;
	const { root } = classes;

	return (
		<div className={root}>
			<Branding />
			<Navigation />
			<SearchBar />
			<ActionSection dashboardRef={dashboardRef} />
			<Profile />
		</div>
	);
};

Header.propTypes = {
	classes: PropTypes.object,
	dashboardRef: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({ current: PropTypes.Element })
	])
};

export default withStyles(styles)(Header);
