import React from 'react';
import { capitalCase } from 'change-case';

export default function (
	data,
	ActionComponent,
	handleDailyRedirect,
	handleFourteenDayRedirect
) {
	return data.map((entry) => {
		return {
			mid: capitalCase(entry.forecast_name),
			actualsByDay: entry.a_dly_chb,
			actualsByMonth: entry.a_mth_chb,
			forecastChargebacks: entry.f_chb || '-',
			chargebackMargin: entry.actual_chb_margin,
			chargebackPctByMonth: entry.chb_pct,
			actions: (
				<ActionComponent
					forecastName={entry.forecast_name}
					handleDailyRedirect={handleDailyRedirect}
					handleFourteenDayRedirect={handleFourteenDayRedirect}
				/>
			)
		};
	});
}
