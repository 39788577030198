import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Slyce360LogoOriginal from './Slyce360Logo_Original';
import Slyce360LogoDark from './Slyce360Logo_Dark';

const Slyce360Icon = (props) => {
	const { theme } = props;
	if (theme === 'light') {
		return <Slyce360LogoOriginal />;
	} else {
		return <Slyce360LogoDark />;
	}
};

Slyce360Icon.propTypes = {
	theme: PropTypes.string
};

const mapStateToProps = (state) => {
	return {
		theme: state.systemReducer.get('theme')
	};
};

export default connect(mapStateToProps, null)(Slyce360Icon);
