import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

const styles = (theme) => ({
	cellStyle: {
		textIndent: '3em'
	},
	currentMonth: {
		borderLeft: '2px solid black',
		borderRight: '2px solid black'
	},
	remediation: {
		cursor: 'pointer'
	},
	row: {
		backgroundColor: theme.palette.navigation.background
	},
	rowStyle: {
		fontWeight: '600'
	},
	title: {
		display: 'flex'
	}
});

const RemediationTable = (props) => {
	const {
		cardType,
		classes,
		renderRow,
		remediationOpen,
		setRemediationOpen,
		t
	} = props;
	const {
		cellStyle,
		currentMonth,
		row,
		rowStyle,
		remediation,
		title
	} = classes;

	return (
		<Fragment>
			<TableRow
				className={clsx(row, remediation)}
				onClick={() => setRemediationOpen(!remediationOpen)}
			>
				<TableCell className={clsx(rowStyle, title)}>
					{remediationOpen ? (
						<ExpandMoreIcon fontSize="small" />
					) : (
						<ChevronRightIcon fontSize="small" />
					)}
					{t('pages.reports.chargebacks.daily-chargeback-report.mitigation')}
				</TableCell>
				<TableCell colSpan={5} />
				<TableCell className={currentMonth} />
				<TableCell colSpan={6} />
			</TableRow>
			{remediationOpen && (
				<Fragment>
					<TableRow>
						<TableCell className={cellStyle}>
							{cardType === 'uo_visa'
								? t('pages.reports.chargebacks.daily-chargeback-report.rdr')
								: t('pages.reports.chargebacks.daily-chargeback-report.precb')}
						</TableCell>
						{cardType === 'uo_visa'
							? renderRow('rdr')
							: renderRow('preChargeback')}
					</TableRow>
					<TableRow className={row}>
						<TableCell className={cellStyle}>
							{t('pages.reports.chargebacks.daily-chargeback-report.cdrn')}
						</TableCell>
						{renderRow('cdrn')}
					</TableRow>
					<TableRow>
						<TableCell className={cellStyle}>
							{t('pages.reports.chargebacks.daily-chargeback-report.ethoca')}
						</TableCell>
						{renderRow('ethoca')}
					</TableRow>
					<TableRow className={row}>
						<TableCell className={cellStyle}>
							{t('pages.reports.chargebacks.daily-chargeback-report.total')}
						</TableCell>
						{renderRow('total')}
					</TableRow>
					<TableRow>
						<TableCell className={cellStyle}>
							{t(
								'pages.reports.chargebacks.daily-chargeback-report.cbmitigation'
							)}
						</TableCell>
						{renderRow('disputePercent')}
					</TableRow>
					<TableRow className={row}>
						<TableCell className={cellStyle}>
							{t(
								'pages.reports.chargebacks.daily-chargeback-report.cbreduction'
							)}
						</TableCell>
						{renderRow('deflectionPercent')}
					</TableRow>
				</Fragment>
			)}
		</Fragment>
	);
};

RemediationTable.propTypes = {
	cardType: PropTypes.string,
	classes: PropTypes.object,
	renderRow: PropTypes.func,
	remediationOpen: PropTypes.bool,
	setRemediationOpen: PropTypes.func,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(RemediationTable));
