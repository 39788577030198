import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { userActions } from '../../../containers/Users';

import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';

const styles = (theme) => ({
	root: {
		display: 'flex',
		minWidth: '600px',
		margin: '6px'
	}
});

const EmailInputs = (props) => {
	const {
		classes,
		defaultValue,
		getUsersList,
		hasLoadedListOfUsers,
		label,
		listOfUsers,
		onSelect
	} = props;
	const { root } = classes;

	useEffect(() => {
		if (!hasLoadedListOfUsers) {
			getUsersList();
		}
	}, [getUsersList, hasLoadedListOfUsers, listOfUsers]);

	const renderTags = (values, getTagProps) => {
		return values.map((option, index) => (
			<Chip
				variant="outlined"
				label={option}
				key={index}
				{...getTagProps({ index })}
			/>
		));
	};

	const optionsList = () => {
		if (hasLoadedListOfUsers) {
			const formattedUsers = listOfUsers.map(
				(user) => `${user.firstName} - ${user.email}`
			);
			return formattedUsers;
		} else {
			return [];
		}
	};

	return (
		<Autocomplete
			id={`${label}-input-values`}
			className={root}
			defaultValue={defaultValue ? [defaultValue] : []}
			multiple
			options={optionsList()}
			freeSolo
			onChange={(event, values) => onSelect(values)}
			filterSelectedOptions={true}
			renderTags={renderTags}
			renderInput={(params) => (
				<TextField
					{...params}
					variant="outlined"
					label={label}
					placeholder="Add Email"
				/>
			)}
		/>
	);
};

EmailInputs.propTypes = {
	classes: PropTypes.object,
	defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	getUsersList: PropTypes.func,
	hasLoadedListOfUsers: PropTypes.bool,
	label: PropTypes.string,
	listOfUsers: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	onSelect: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		hasLoadedListOfUsers: state.usersReducer.get('hasBeenLoaded'),
		listOfUsers: state.usersReducer.get('users')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getUsersList: () => {
			dispatch(usersActions.getUsers());
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(EmailInputs));
