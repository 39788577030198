export default function (value) {
	let menuItem;

	if (value.label) {
		menuItem = value.label;
	} else if (value.merchantId) {
		menuItem = value.merchantId;
	} else {
		menuItem = value;
	}

	return menuItem;
}
