import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';
import { capitalCase } from 'change-case';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';

import CheckBoxIcon from '@mui/icons-material/CheckCircle';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckCircleOutline';

const styles = (theme) => ({
	container: {
		display: 'flex',
		flex: 4,
		flexDirection: 'column',
		margin: '10px'
	},
	centered: {
		display: 'flex',
		padding: '20px',
		justifyContent: 'center',
		alignItems: 'center'
	},
	ruleItemContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignSelf: 'stretch',
		border: `1px solid ${theme.palette.groupManagement.divider}`,
		borderRadius: '5px',
		marginTop: '10px',
		padding: '5px',
		flexDirection: 'row'
	},
	mainContainer: {
		display: 'flex',
		flexDirection: 'column',
		marginLeft: '10px',
		flex: 5
	},
	categoryContainer: {
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		justifyContent: 'flex-start',
		alignItems: 'center'
		// padding: '5px'
	},
	categoryHeader: {
		fontSize: '.8rem',
		color: theme.palette.secondary.main
	},
	categoryValue: {
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		justifyContent: 'center',
		flex: 1
	},
	activeStatusContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		alignItems: 'center',
		marginLeft: '5px'
	},
	active: {
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		justifyContent: 'center',
		fontSize: '2rem',
		color: theme.palette.primary.main
	},
	inactive: {
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		justifyContent: 'center',
		fontSize: '2rem',
		opacity: '0.2',
		color: theme.palette.secondary.main
	},
	levelInfo: {
		color: theme.palette.info.main,
		marginLeft: '5px'
	},
	levelWarning: {
		color: theme.palette.warning.main,
		marginLeft: '5px'
	},
	levelSevere: {
		color: theme.palette.error.light,
		marginLeft: '5px'
	},
	ruleText: {
		fontSize: '1.2rem',
		padding: '.5rem'
	},
	detailText: {
		display: 'flex',
		paddingLeft: '.5rem'
	}
});

export const NotificationRulesList = (props) => {
	const {
		classes,
		notificationRulesList,
		setGroupNotificationRuleBeingEdited
	} = props;
	const {
		activeStatusContainer,
		container,
		ruleItemContainer,
		mainContainer,
		active,
		inactive,
		levelInfo,
		levelWarning,
		levelSevere,
		ruleText,
		detailText,
		categoryContainer,
		categoryHeader,
		categoryValue
	} = classes;

	return (
		<div className={container}>
			{notificationRulesList.map((rule) => {
				return (
					<Container className={ruleItemContainer} key={rule.id}>
						<div className={activeStatusContainer}>
							<span className={categoryHeader}>active status</span>
							{rule.active ? (
								<CheckBoxIcon className={active} />
							) : (
								<CheckBoxOutlineBlankIcon className={inactive} />
							)}
						</div>
						<div className={categoryContainer}>
							<span className={categoryHeader}>type</span>
							<span className={categoryValue}>{rule.category}</span>
						</div>
						<div className={mainContainer}>
							<span className={ruleText}>{rule.rule}</span>
							<div className={detailText}>
								Level:{' '}
								<span
									className={clsx(
										rule.alertLevel === 'severe' && levelSevere,
										rule.alertLevel === 'warning' && levelWarning,
										rule.alertLevel === 'info' && levelInfo
									)}
								>
									{capitalCase(rule.alertLevel)}
								</span>
							</div>
						</div>
						<Stack spacing={2} padding={1} direction={'row-reverse'}>
							<Button onClick={() => {}} variant="outlined">
								Delete
							</Button>
							<Button
								onClick={() => {
									setGroupNotificationRuleBeingEdited(rule);
								}}
								variant="outlined"
							>
								Edit
							</Button>
						</Stack>
					</Container>
				);
			})}
		</div>
	);
};

NotificationRulesList.propTypes = {
	notificationRulesList: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.array
	]),
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(NotificationRulesList));
