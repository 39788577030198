import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import getTileListForLayout from '../../../helpers/tiles/getTileListForLayout';
import querier from '../../../utilities/querier';

import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const styles = (theme) => ({
	container: {
		display: 'flex',
		flexFlow: 'column',
		padding: '5px',
		justifyContent: 'center',
		alignItems: 'center',
		padding: '5px'
	},
	warningIcon: {
		color: theme.palette.warning.main,
		fontSize: '2rem'
	},
	successIcon: {
		color: theme.palette.success.main,
		fontSize: '2rem'
	},
	detailText: {
		color: theme.palette.common.black
	},
	buttonRow: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between'
	},
	inputButton: {
		borderRadius: '4px',
		width: '100px',
		border: 'none',
		marginLeft: '4px',
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.secondary.alt,
		padding: '5px',
		fontSize: '16px',
		fontWeight: '300',
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
			cursor: 'pointer'
		}
	}
});

const _render = (
	tileExists,
	currentLayoutTiles,
	onConfirmDelete,
	onCancelDelete,
	onCloseModal,
	t,
	classes
) => {
	const {
		warningIcon,
		successIcon,
		detailText,
		buttonRow,
		inputButton
	} = classes;

	let rendered = <CircularProgress />;

	// TODO - Clean this up so there isnt so much duplicated code.
	if (tileExists && currentLayoutTiles && !currentLayoutTiles.isLoading) {
		rendered = (
			<Stack
				direction="column"
				spacing={2}
				pt={2}
				pb={2}
				alignItems="center"
				justifyContent="center"
			>
				<WarningAmberIcon className={warningIcon} />
				<span className={detailText}>
					{t('components.dashboardTiles.DashboardWidgetTile.confirmDeletion')}
				</span>
				<div className={buttonRow}>
					<Button
						variant="contained"
						size="small"
						className={inputButton}
						type="submit"
						onClick={onCancelDelete}
					>
						{t('generic.buttons.cancel')}
					</Button>
					<Button
						variant="contained"
						size="small"
						className={inputButton}
						type="submit"
						onClick={onConfirmDelete}
					>
						{t('generic.buttons.delete')}
					</Button>
				</div>
			</Stack>
		);
	} else if (
		!tileExists &&
		currentLayoutTiles &&
		!currentLayoutTiles.isLoading
	) {
		rendered = (
			<Stack
				direction="column"
				spacing={2}
				pt={2}
				pb={2}
				alignItems="center"
				justifyContent="center"
			>
				<CheckCircleIcon className={successIcon} />
				<span className={detailText}>
					{t('components.dashboardTiles.DashboardWidgetTile.successMessage')}
				</span>
				<div className={buttonRow}>
					<Button
						variant="contained"
						size="small"
						className={inputButton}
						type="submit"
						onClick={onCloseModal}
					>
						{t('generic.buttons.close')}
					</Button>
				</div>
			</Stack>
		);
	}

	return rendered;
};

export const DeleteConfirmation = (props) => {
	const {
		classes,
		t,
		onConfirmDelete,
		onCancelDelete,
		onCloseModal,
		layoutTiles,
		tile,
		height,
		width
	} = props;
	const { container } = classes;

	const currentLayoutTiles = getTileListForLayout(
		layoutTiles,
		querier(tile, 'layoutUniversalUniqueIdentifier')
	);

	const tileExists = querier(
		currentLayoutTiles,
		`data[universalUniqueIdentifier = "${tile.universalUniqueIdentifier}"]`
	);

	return (
		<Container className={container} style={{ height, width }}>
			{_render(
				tileExists,
				currentLayoutTiles,
				onConfirmDelete,
				onCancelDelete,
				onCloseModal,
				t,
				classes
			)}
		</Container>
	);
};

DeleteConfirmation.propTypes = {
	onConfirmDelete: PropTypes.func,
	onCancelDelete: PropTypes.func,
	onClose: PropTypes.func,
	layoutTiles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	tile: PropTypes.object,
	height: PropTypes.number,
	width: PropTypes.number,
	classes: PropTypes.object
};

export default withStyles(styles)(withTranslation()(DeleteConfirmation));
