import deepCopy from '../../utilities/deepCopy';
import groupMidList from './groupMidList';

const _optionStandardizers = {
	MID: groupMidList
};

export default function (options) {
	let standardizedOptions = {};

	if (options) {
		standardizedOptions = deepCopy(options);

		Object.entries(options).forEach(([key, value]) => {
			if (_optionStandardizers[key]) {
				standardizedOptions[key].values = _optionStandardizers[key](
					value.values
				);
			}
		});
	}

	return standardizedOptions;
}
