import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles, useTheme } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { MTableBody } from '@material-table/core';
import MaterialTable from '../../../Table/MaterialTable';
import { sanitizeKeys } from '../../../../helpers/sanitizeKeys';
import Footer from './Footer';
import ActionColumn from './ActionColumn';
import { getColumnNames } from '../../../../helpers/chargebackSummary/getColumnNames';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Typography from '@mui/material/Typography';
import CollapsedFooter from './CollapsedTotals';
import formatTableData from '../../../../helpers/chargebackSummary/formatTableData';

const styles = (theme) => ({
	accordion: {
		marginBottom: '16px',
		width: '90vw'
	},
	summary: {
		display: 'flex',
		flexDirection: 'row'
	},
	accordionTitleContainer: {
		display: 'flex',
		flex: 1,
		flexDirection: 'row',
		alignSelf: 'stretch',
		justifyContent: 'space-between'
	},
	sectionTitle: {
		flexBasis: '10%'
	},
	collapse: {
		display: 'flex',
		alignItems: 'center'
	}
});

const SummaryTableByCardType = (props) => {
	const { classes, cardType, data, processor, t } = props;
	const {
		accordion,
		summary,
		accordionTitleContainer,
		sectionTitle,
		collapse
	} = classes;

	const history = useHistory();
	const theme = useTheme();

	const [open, setOpen] = useState(true);

	const handleDailyRedirect = (forecastname) => {
		// TODO: pathing is deprecated, but the button assoc. with this is also disabled
		const selectedProcessor = processor;
		const sanitizedMid = encodeURIComponent(forecastname);
		const sanitizedProcessor = encodeURIComponent(selectedProcessor);

		history.push(
			`/chargebacks/daily-chargeback-report?processor=${sanitizedProcessor}&mids=${sanitizedMid}&autoApply=true`
		);
	};

	const handleFourteenDayRedirect = (forecastName) => {
		// TODO: pathing is deprecated, but the button assoc. with this is also disabled
		const selectedProcessor = processor;
		const sanitizedProcessor = encodeURIComponent(selectedProcessor);
		const sanitizedMid = encodeURIComponent(forecastName);

		history.push(
			`/chargebacks/fourteen-day-chargebacks?card_types=${cardType}&processor=${sanitizedProcessor}&mid=${sanitizedMid}&autoApply=true`
		);
	};

	const formattedDate = formatTableData(
		data,
		ActionColumn,
		handleDailyRedirect,
		handleFourteenDayRedirect
	);

	return (
		<Accordion
			className={accordion}
			expanded={open}
			onChange={() => setOpen(!open)}
		>
			<AccordionSummary className={summary}>
				<div className={accordionTitleContainer}>
					<Typography variant="h6" className={sectionTitle}>
						{sanitizeKeys(cardType)}
					</Typography>
					{!open && <CollapsedFooter cardType={cardType} data={data} />}
					{open ? (
						<Typography variant="caption" className={collapse}>
							{t('pages.reports.chargebacks.summary.collapse')}&nbsp;
							<ArrowUpwardIcon fontSize="small" />
						</Typography>
					) : (
						<Typography variant="caption" className={collapse}>
							{t('pages.reports.chargebacks.summary.expand')}&nbsp;
							<ArrowDownwardIcon fontSize="small" />
						</Typography>
					)}
				</div>
			</AccordionSummary>

			<AccordionDetails>
				<MaterialTable
					columns={getColumnNames(t)}
					data={formattedDate}
					style={{ fontSize: '.8rem' }}
					options={{
						padding: 'dense',
						paging: false,
						rowStyle: (data, index) => {
							if (index % 2) {
								return { backgroundColor: theme.palette.navigation.background };
							}
						},
						search: false,
						showTitle: false,
						toolbar: false
					}}
					components={{
						Body: (props) => (
							<Fragment>
								<MTableBody {...props} />
								<Footer data={data} processor={processor} />
							</Fragment>
						)
					}}
				/>
			</AccordionDetails>
		</Accordion>
	);
};

SummaryTableByCardType.propTypes = {
	classes: PropTypes.object,
	cardType: PropTypes.string,
	data: PropTypes.array,
	midList: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	processor: PropTypes.string,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(SummaryTableByCardType));
