import axios from '../../utilities/axios';
import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { refreshToken } from '../../utilities/refreshToken';
import { failureMessage } from '../ToastNotifications/actions';
import { primaryRestGateway } from '../../utilities/apiEndpointUtility';

import * as actions from './actions';
import * as actionTypes from './actionTypes';

import mockGetRepresentmentReversalReasonEpic from '../../__Demo_Data__/ReversalReasonBuilder/getRepresentmentReversalReasonEpic';
import { demoWait } from '../../__Demo_Data__/mockRandomLoad';

const _handleEmptyReasons = (data) => {
	let reasons = [];

	if (Array.isArray(data)) {
		reasons = data;
	}

	return reasons;
};

export const getRepresentmentReversalReasonEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.GET_REPRESENTMENT_REVERSAL_REASON),
		mergeMap(async (action) => {
			await demoWait();
			//await refreshToken();

			const caseNumber = action.payload.caseNumber;

			// const response = await axios.get(
			// 	`${primaryRestGateway()}/api/representments/${caseNumber}/reversalReason`
			// );

			const response = {
				data: mockGetRepresentmentReversalReasonEpic
			};

			return _handleEmptyReasons(response.data);
		}),
		switchMap((res) => [actions.getRepresentmentReversalReasonCompleted(res)]),
		catchError((error, source) =>
			merge(
				of(
					actions.getRepresentmentReversalReasonFailed(error.message),
					failureMessage(error.message)
				),
				source
			)
		)
	);

export const insertRepresentmentReversalReasonEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.INSERT_REPRESENTMENT_REVERSAL_REASON),
		mergeMap(async (action) => {
			await refreshToken();
			const caseNumber = action.payload.caseNumber;

			const response = await axios.post(
				`${primaryRestGateway()}/api/representments/${caseNumber}/reversalReason`,
				{ ...action.payload.data }
			);

			return _handleEmptyReasons(response.data);
		}),
		switchMap((res) => [
			actions.insertRepresentmentReversalReasonCompleted(res)
		]),
		catchError((error, source) =>
			merge(
				of(
					actions.insertRepresentmentReversalReasonFailed(error.message),
					failureMessage(error.message)
				),
				source
			)
		)
	);

export const updateRepresentmentReversalReasonEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.UPDATE_REPRESENTMENT_REVERSAL_REASON),
		mergeMap(async (action) => {
			await refreshToken();
			const caseNumber = action.payload.caseNumber;

			const response = await axios.put(
				`${primaryRestGateway()}/api/representments/${caseNumber}/reversalReason`,
				{ ...action.payload.data }
			);

			return _handleEmptyReasons(response.data);
		}),
		switchMap((res) => [
			actions.updateRepresentmentReversalReasonCompleted(res)
		]),
		catchError((error, source) =>
			merge(
				of(
					actions.updateRepresentmentReversalReasonFailed(error.message),
					failureMessage(error.message)
				),
				source
			)
		)
	);
