const Slyce360Logger = require('@sempris/slyce360-logger');

const Logger = new Slyce360Logger('slyce360-dashboard', [
	process.env.REACT_APP_STAGE
]);

if (process.env.REACT_APP_STAGE === 'development') {
	Logger.DEBUG_MODE = true;
}

export default Logger;
