import React, { Fragment } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { sentenceCase, capitalCase } from 'change-case';

import generateInitialFilters from '../../../helpers/widget/generateInitialFilters';

import FilterSelect from './partials/FilterSelect';
import StandardAppBarSkeleton from './skeletons/StandardAppBarSkeleton';

import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

const styles = (theme) => ({
	toolbar: {
		display: 'flex',
		alignItems: 'flex-start',
		flexDirection: 'column',
		padding: '4px'
	},
	labelText: {
		marginLeft: '5px',
		fontSize: '1.2rem',
		color: theme.palette.widget.appBar.label
	}
});

const StandardAppBar = (props) => {
	const {
		classes,
		data,
		isLoading,
		hasBeenLoaded,
		error,
		onSubmit,
		isLoadingReportData
	} = props;
	const { toolbar, labelText } = classes;

	const [filters, setFilters] = React.useState(undefined);

	React.useEffect(() => {
		if (hasBeenLoaded) {
			const initialFilters = generateInitialFilters(data.options);
			setFilters(initialFilters);
		}
	}, [hasBeenLoaded, data]);

	const onFilterUpdate = (updatedFilters) => {
		setFilters(updatedFilters);
	};

	const onSubmitFilters = () => {
		onSubmit(filters);
	};

	return (
		<Toolbar className={toolbar}>
			{!hasBeenLoaded || !filters || isLoading || error ? (
				<StandardAppBarSkeleton />
			) : (
				<Fragment>
					<Typography variant="subtitle2" noWrap className={labelText}>
						{capitalCase(sentenceCase(data.label))}
					</Typography>
					<FilterSelect
						options={data.options}
						filters={filters}
						onFilterUpdate={onFilterUpdate}
						onSubmit={onSubmitFilters}
						isLoadingReportData={isLoadingReportData}
					/>
				</Fragment>
			)}
		</Toolbar>
	);
};

StandardAppBar.propTypes = {
	data: PropTypes.object,
	isLoading: PropTypes.bool,
	hasBeenLoaded: PropTypes.bool,
	error: PropTypes.string,
	filters: PropTypes.object,
	onFilterUpdate: PropTypes.func,
	onSubmit: PropTypes.func,
	isLoadingReportData: PropTypes.bool,
	classes: PropTypes.object
};

export default withStyles(styles)(StandardAppBar);
