import React, { useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { withStyles } from '@mui/styles';

import Loader from '../../Loader';
import Header from './Header/';
import LeftDrawer from './LeftDrawer';
import RightDrawer from './RightDrawer';
import Footer from './Footer';

const styles = (theme) => ({
	pageContentContainer: {
		display: 'flex',
		minHeight: '100vh',
		overflow: 'hidden',
		backgroundColor: theme.palette.common.offWhite
	},
	dashboardContentWrapper: {
		display: 'flex',
		flexDirection: 'column',
		width: '100vw'
	},
	screenshotterRoot: {
		display: 'flex',
		flexDirection: 'row',
		flex: 1,
		padding: '0px',
		marginTop: '3.25rem',
		zIndex: '100'
	},
	splashContentWrapper: {
		display: 'flex',
		flexDirection: 'column',
		height: '100vh'
	},
	splashPageContent: {
		position: 'relative',
		minHeight: '100vh'
	}
});

const Layout = (props) => {
	const { children, classes, isAuthed, userHasBeenLoaded } = props;
	const {
		pageContentContainer,
		dashboardContentWrapper,
		screenshotterRoot,
		splashPageContent,
		splashContentWrapper
	} = classes;

	const dashboardRef = useRef(null);

	const _renderDashboardLayout = () => {
		if (!userHasBeenLoaded) {
			return <Loader />;
		}

		return (
			<div className={pageContentContainer}>
				<div className={dashboardContentWrapper}>
					<Header dashboardRef={dashboardRef} />
					<div className={screenshotterRoot} ref={dashboardRef}>
						{/* <LeftDrawer /> */}
						{children}
						<RightDrawer />
					</div>
				</div>
			</div>
		);
	};

	const _renderSplashLayout = () => {
		return (
			<div className={splashPageContent}>
				<div className={splashContentWrapper}>
					{children}
					<Footer />
				</div>
			</div>
		);
	};

	return <>{isAuthed ? _renderDashboardLayout() : _renderSplashLayout()}</>;
};

Layout.propTypes = {
	authUserSession: PropTypes.func,
	children: PropTypes.element,
	classes: PropTypes.object,
	location: PropTypes.object,
	isAuthed: PropTypes.bool,
	t: PropTypes.func,
	userHasBeenLoaded: PropTypes.bool
};

const mapStateToProps = (state) => {
	return {
		isAuthed: state.authReducer.get('isAuthed'),
		userHasBeenLoaded: state.userReducer.get('userHasBeenLoaded')
	};
};

export default connect(mapStateToProps, null)(withStyles(styles)(Layout));
