import { fromJS } from 'immutable';
import * as actionTypes from './actionTypes';

export const initialState = fromJS({
	users: {
		data: [],
		isLoading: false,
		hasBeenLoaded: false,
		error: undefined
	},
	userLayouts: {
		data: [],
		isLoading: false,
		hasBeenLoaded: false,
		error: undefined
	}
});

export const cloneLayoutReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_USERS:
			return state.setIn(['users', 'isLoading'], true);

		case actionTypes.GET_USERS_COMPLETED:
			return state
				.setIn(['users', 'isLoading'], false)
				.setIn(['users', 'hasBeenLoaded'], true)
				.setIn(['users', 'data'], action.payload);

		case actionTypes.GET_USERS_FAILED:
			return state
				.setIn(['users', 'isLoading'], false)
				.setIn(['users', 'error'], action.payload);

		case actionTypes.GET_USER_LAYOUTS:
			return state.setIn(['userLayouts', 'isLoading'], true);

		case actionTypes.GET_USER_LAYOUTS_COMPLETED:
			return state
				.setIn(['userLayouts', 'isLoading'], false)
				.setIn(['userLayouts', 'hasBeenLoaded'], true)
				.setIn(['userLayouts', 'data'], action.payload);

		case actionTypes.GET_USER_LAYOUTS_FAILED:
			return state
				.setIn(['userLayouts', 'isLoading'], false)
				.setIn(['userLayouts', 'error'], action.payload);

		case actionTypes.CLEAR_USER_LAYOUTS:
			return state
				.setIn(['userLayouts', 'isLoading'], false)
				.setIn(['userLayouts', 'data'], [])
				.setIn(['userLayouts', 'hasBeenLoaded'], false)
				.setIn(['userLayouts', 'error'], undefined);

		default:
			return state;
	}
};
