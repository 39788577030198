import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import deepCopy from '../../../utilities/deepCopy';
import { Switch, Case, Default } from 'react-if';
import moment from 'moment';
import { reversalReasonBuilderActions } from '../../../containers/ReversalReasonBuilder';
import builderDataInjector from '../../../containers/ReversalReasonBuilder/helpers/builderDataInjector';

import Button from '@mui/material/Button';

import AddChargebackReason from './AddChargebackReason';
import ReversalReasonItem from './ReversalReasonItem';

const styles = (theme) => ({
	container: {
		display: 'flex',
		flexFlow: 'column',
		width: '80%',
		padding: '10px',
		margin: '10px',
		borderRadius: '10px',
		border: '1px solid #c6c6c6',
		background: theme.palette.common.white
	},
	headerContainer: {
		display: 'flex',
		flexDirection: 'row',
		padding: '5px 10px 5px 10px'
	},
	headerTextContainer: {
		display: 'flex',
		flex: 1,
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'flex-start',
		fontWeight: '600',
		fontSize: '1.2rem'
	},
	updateContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end'
	},
	addReasonContainer: {
		display: 'flex',
		flexDirection: 'row',
		flex: 1
	},
	reversalReasonList: {
		display: 'flex',
		flexDirection: 'column',
		margin: '10px 20px',
		border: '1px solid #c6c6c6'
	},
	button: {
		margin: '5px 5px 3px 5px',
		backgroundColor: theme.palette.common.white,
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: theme.palette.common.white,
			borderColor: theme.palette.representments.buttons.hover,
			color: theme.palette.representments.buttons.hover
		}
	}
});

export const ReversalReasonBuilder = (props) => {
	const {
		classes,
		t,
		buildType,
		getChargebackReversalReason,
		setChargebackReversalReason,
		reversalReason,
		reversalReasonIsLoading,
		reversalReasonHasBeenLoaded,
		reversalReasonError
	} = props;
	const {
		container,
		headerContainer,
		headerTextContainer,
		updateContainer,
		button,
		addReasonContainer,
		reversalReasonList
	} = classes;

	const location = useLocation();

	React.useEffect(() => {
		const caseNumber = location.pathname.split('/')[2];

		getChargebackReversalReason({
			caseNumber
		});
	}, [location, getChargebackReversalReason]);

	const onAddChargebackReason = (newReason) => {
		const { representment, reversalReason } = props;

		const updatedReason = deepCopy(reversalReason);

		const result = builderDataInjector(newReason, representment);

		updatedReason.push(result);

		setChargebackReversalReason(updatedReason);
	};

	const onRemoveChargebackReason = (removeIndex) => {
		let updatedReason = deepCopy(reversalReason);

		updatedReason = updatedReason.filter((reason, index) => {
			return index !== removeIndex;
		});

		setChargebackReversalReason(updatedReason);
	};

	const onReorderReason = (oldIndex, newIndex) => {
		const updatedReason = deepCopy(reversalReason);

		const [item] = updatedReason.splice(oldIndex, 1);

		updatedReason.splice(newIndex, 0, item);

		setChargebackReversalReason(updatedReason);
	};

	const onUpdateClicked = () => {
		const {
			buildType,
			user,
			reversalReason,
			insertChargebackReversalReason,
			updateChargebackReversalReason
		} = props;
		const caseNumber = location.pathname.split('/')[2];

		// TODO SEND PAYLOAD TO LAMDA TO STORE (LAMBDA CURRENTLY NOT ABLE TO STORE TO DB)

		if (buildType === 'new') {
			insertChargebackReversalReason({
				caseNumber: caseNumber,
				data: {
					sentToProcessorOn: null,
					createdOn: moment.utc().format('YYYY-MM-DD'),
					lastUpdatedOn: moment.utc().format('YYYY-MM-DD'),
					createdByUserId: user.id,
					updatedByUserId: user.id,
					chargebackReversalReason: JSON.stringify(reversalReason)
				}
			});
		} else {
			updateChargebackReversalReason({
				caseNumber: caseNumber,
				data: {
					sentToProcessorOn: null,
					updatedOn: moment.utc().format('YYYY-MM-DD'),
					updatedByUserId: user.id,
					chargebackReversalReason: JSON.stringify(reversalReason)
				}
			});
		}
	};

	return (
		<Switch>
			<Case condition={!reversalReasonIsLoading && reversalReasonError}>
				<>Error State</>
			</Case>
			<Case condition={!reversalReasonIsLoading && reversalReasonHasBeenLoaded}>
				<div className={container}>
					<div className={headerContainer}>
						<div className={headerTextContainer}>
							{t(
								'components.Representment.ChargebackReversalReasons.chargebackReversalReasons'
							)}
						</div>
						<div className={updateContainer}>
							<Button
								className={button}
								variant="outlined"
								color="secondary"
								onClick={null}
							>
								{t('generic.buttons.update')}
							</Button>
						</div>
					</div>
					<div className={addReasonContainer}>
						<AddChargebackReason
							onAddChargebackReason={onAddChargebackReason}
						/>
					</div>
					<div className={reversalReasonList}>
						{' '}
						{reversalReason.map((reason, index) => {
							return (
								<ReversalReasonItem
									key={index}
									data={reason}
									onRemoveItem={onRemoveChargebackReason}
									itemIndex={index}
									totalItems={reversalReason.length}
									onReorderReason={onReorderReason}
								/>
							);
						})}
					</div>
				</div>
			</Case>
			<Default></Default>
		</Switch>
	);
};

ReversalReasonBuilder.propTypes = {
	representment: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	reversalReason: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	reversalReasonIsLoading: PropTypes.bool,
	reversalReasonHasBeenLoaded: PropTypes.bool,
	reversalReasonError: PropTypes.string,
	buildType: PropTypes.string,
	user: PropTypes.object,
	getChargebackReversalReason: PropTypes.func,
	setChargebackReversalReason: PropTypes.func,
	insertChargebackReversalReason: PropTypes.func,
	updateChargebackReversalReason: PropTypes.func,
	classes: PropTypes.object,
	t: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		buildType: state.reversalReasonBuilderReducer.get('buildType'),
		representment: state.representmentReducer.getIn(['representment', 'data']),
		reversalReason: state.reversalReasonBuilderReducer.getIn([
			'reversalReason',
			'data'
		]),
		reversalReasonIsLoading: state.reversalReasonBuilderReducer.getIn([
			'reversalReason',
			'isLoading'
		]),
		reversalReasonHasBeenLoaded: state.reversalReasonBuilderReducer.getIn([
			'reversalReason',
			'hasBeenLoaded'
		]),
		reversalReasonError: state.reversalReasonBuilderReducer.getIn([
			'reversalReason',
			'error'
		]),
		user: state.userReducer.get('user')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getChargebackReversalReason: (payload) => {
			dispatch(
				reversalReasonBuilderActions.getRepresentmentReversalReason(payload)
			);
		},
		setChargebackReversalReason: (payload) => {
			dispatch(
				reversalReasonBuilderActions.setChargebackReversalReason(payload)
			);
		},
		insertChargebackReversalReason: (payload) => {
			dispatch(
				reversalReasonBuilderActions.insertRepresentmentReversalReason(payload)
			);
		},
		updateChargebackReversalReason: (payload) => {
			dispatch(
				reversalReasonBuilderActions.updateRepresentmentReversalReason(payload)
			);
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(withTranslation()(ReversalReasonBuilder)));
