import React from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';

const styles = (theme) => ({
	linear: {
		width: '50%'
	},
	root: {
		display: 'flex!important',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		width: '98vw!important',
		height: '98vh',
		padding: 0,
		margin: 0
	}
});

const Loader = (props) => {
	const { label, classes, type } = props;
	const { linear, root } = classes;

	const _renderProgressType = () => {
		switch (type) {
			case 'linear':
				return <LinearProgress className={linear} color="secondary" />;
			default:
				return <CircularProgress color="secondary" />;
		}
	};

	return (
		<div className={root}>
			{label ? <p>{label}</p> : null}
			{_renderProgressType()}
		</div>
	);
};

Loader.propTypes = {
	classes: PropTypes.object,
	label: PropTypes.string,
	type: PropTypes.string
};

export default withStyles(styles)(Loader);
