import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import Reporter from '../../Reporter';
import OptionSelector from './OptionsSelector';
import DynamicItemizationTable from './DynamicItemizationTable';
import RecordingsModal from '../../modals/RecordingsModal';

import Container from '@mui/material/Container';

const styles = (theme) => ({
	root: {
		display: 'flex',
		marginTop: '20px',
		width: '100%',
		flexDirection: 'column'
	}
});

const ChargebackItemizationView = (props) => {
	const { classes, reportViewId } = props;
	const { root } = classes;

	return (
		<Container maxWidth={false} className={root}>
			<Reporter
				reportViewId={reportViewId}
				OverrideFilterComponent={OptionSelector}
				OverrideReportComponent={DynamicItemizationTable}
			/>
			<RecordingsModal reportViewId={reportViewId} />
		</Container>
	);
};

ChargebackItemizationView.propTypes = {
	reportViewId: PropTypes.string,
	classes: PropTypes.object
};

export default withStyles(styles)(ChargebackItemizationView);
