import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@mui/styles';

import Button from '@mui/material/Button';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const styles = (theme) => ({
	row: {
		display: 'flex',
		flexDirection: 'row',
		padding: '5px',
		justifyContent: 'center'
	},
	successIcon: {
		margin: '20px',
		color: theme.palette.success.main
	},
	actionRow: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end'
	},
	inputButton: {
		borderRadius: '4px',
		width: '150px',
		border: 'none',
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.secondary.alt,
		padding: '5px',
		fontSize: '16px',
		fontWeight: '300',
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
			cursor: 'pointer'
		}
	}
});

export const SuccessPage = (props) => {
	const { classes, t, onCloseModal } = props;
	const { row, successIcon, actionRow, inputButton } = classes;

	const onClose = () => {
		onCloseModal();
	};

	return (
		<Fragment >
			<div className={row}>
				{t('components.modals.MultiFactorAuth.allSetUp')}
			</div>
			<div className={row}>
				<CheckCircleIcon className={successIcon} fontSize='large'/>
			</div>
			<div className={actionRow}>
				<Button
					variant="contained"
					size="small"
					className={inputButton}
					onClick={onClose}
				>
					{t('generic.buttons.close')}
				</Button>
			</div>
		</Fragment>
	);
};

SuccessPage.propTypes = {
	onCloseModal: PropTypes.func,
	authUserSession: PropTypes.func,
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(SuccessPage));
