import * as actionTypes from './actionTypes';

export const getReportOptions = (payload) => ({
	type: actionTypes.GET_REPORT_OPTIONS,
	payload
});

export const getReportOptionsCompleted = (payload) => ({
	type: actionTypes.GET_REPORT_OPTIONS_COMPLETED,
	payload
});

export const getReportOptionsFailed = (payload) => ({
	type: actionTypes.GET_REPORT_OPTIONS_FAILED,
	payload
});

export const executeReport = (payload) => ({
	type: actionTypes.EXECUTE_REPORT,
	payload
});

export const executeReportCompleted = (payload) => ({
	type: actionTypes.EXECUTE_REPORT_COMPLETED,
	payload
});

export const executeReportFailed = (payload) => ({
	type: actionTypes.EXECUTE_REPORT_FAILED,
	payload
});
