import roleConstants from './constants/roleConstants';

export const userIsAdmin = (user) => {
	try {
		const userRoles = user.roles;
		const adminRoles = Object.values(roleConstants);

		return userRoles.some((r) => adminRoles.includes(r));
	} catch (e) {
		return false;
	}
};
