export default {
	data: {
		request: 'api/v1/getLayouts',
		pathParameters: {
			userId: '2'
		},
		results: [
			{
				universalUniqueIdentifier: '3fdc2d68-f431-4fcd-9c1d-f1252361c9e7',
				userId: 2,
				label: 'Notification Layout',
				isShareable: 1
			},
			{
				universalUniqueIdentifier: 'a363d072-d387-4eb1-a472-6b5891875003',
				userId: 2,
				label: 'Executive Snapshot',
				isShareable: 1
			}
		]
	}
};
