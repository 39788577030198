import React, { Fragment, useCallback, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { systemActions } from '../../../containers/System';
import Modal from './ScreenshotModal';
import EmailInputs from './EmailInputs';

import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import AddNewDetailTemplate from './addNewDetailTemplateScreenshotter';
import { notificationActions } from '../../../containers/Notifications';

import { detailTypeOptions } from '../../Notifications/NotificationTimeline/mocks/mockTypes';

const styles = (theme) => ({});

const AttachToNotificationModal = (props) => {
	const {
		addNotificationDetail,
		getScreenshot,
		hasBeenSent,
		isOpen,
		isSending,
		listOfUsers,
		notificationId,
		onClose,
		title
	} = props;

	const handleClose = useCallback(() => {
		onClose();
	}, [onClose]);

	// const [screenshot, set] = useState(null);

	useEffect(async () => {
		// if (!screenshot && isOpen) {
		// const getScreenshot = await getScreenshot();
		// setScreenshot(getScreenshot);
		// }
		// if (hasBeenSent && !isSending) {
		// 	handleClose();
		// }
	}, [handleClose, hasBeenSent, isSending]);

	const _handleSubmit = async (values) => {
		const screenshot = await getScreenshot();

		values.attachments = [];
		values.attachments.push({
			type: 'base64',
			title: 'screenshot',
			value: screenshot
		});

		addNotificationDetail(values);
	};

	return (
		<Fragment>
			<Modal isOpen={isOpen} title={title} handleClose={handleClose}>
				<AddNewDetailTemplate
					handleClose={handleClose}
					listOfUsers={listOfUsers}
					selected={{}}
					typeOptions={detailTypeOptions}
					parentNotificationId={notificationId}
					onSubmit={_handleSubmit}
				/>
			</Modal>
		</Fragment>
	);
};

AttachToNotificationModal.propTypes = {
	addNotificationDetail: PropTypes.func,
	classes: PropTypes.object,
	error: PropTypes.string,
	hasBeenSent: PropTypes.bool,
	getScreenshot: PropTypes.func,
	isOpen: PropTypes.bool,
	isSending: PropTypes.bool,
	listOfUsers: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	notificationId: PropTypes.string,
	onClose: PropTypes.func,
	resetEmailValues: PropTypes.func,
	screenshotLabel: PropTypes.string,
	sendEmail: PropTypes.func,
	sendTo: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	t: PropTypes.func,
	title: PropTypes.string,
	user: PropTypes.object
};

const mapStateToProps = (state) => {
	return {
		user: state.userReducer.get('user'),
		listOfUsers: state.usersReducer.get('users')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		addNotificationDetail: (payload) => {
			dispatch(notificationActions.addNotificationDetail(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(AttachToNotificationModal));
