import { fromJS } from 'immutable';
import * as actionTypes from './actionTypes';
import { handleDynamicReport } from './handlers/handleDynamicReport';

export const initialState = fromJS({
	reportOptions: {},
	reportData: {}
});

export const reporterReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_REPORT_OPTIONS:
			return handleDynamicReport(state, action, 'reportOptions');

		case actionTypes.GET_REPORT_OPTIONS_COMPLETED:
			return handleDynamicReport(state, action, 'reportOptions');

		case actionTypes.GET_REPORT_OPTIONS_FAILED:
			return handleDynamicReport(state, action, 'reportOptions');

		case actionTypes.EXECUTE_REPORT:
			return handleDynamicReport(state, action, 'reportData');

		case actionTypes.EXECUTE_REPORT_COMPLETED:
			return handleDynamicReport(state, action, 'reportData');

		case actionTypes.EXECUTE_REPORT_FAILED:
			return handleDynamicReport(state, action, 'reportData');

		default:
			return state;
	}
};
