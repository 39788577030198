import querier from '../../../utilities/querier';

export default function (currentLayoutTiles) {
	let result = true;

	if (querier(currentLayoutTiles, 'hasBeenLoaded')) {
		return false;
	}

	return result;
}
