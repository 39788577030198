export const GET_USER = 'user/GET_USER';
export const GET_USER_COMPLETED = 'user/GET_USER_COMPLETED';
export const GET_USER_FAILED = 'user/GET_USER_FAILED';

export const UPDATE_USER = 'user/UPDATE_USER';
export const UPDATE_USER_COMPLETED = 'user/UPDATE_USER_COMPLETED';
export const UPDATE_USER_FAILED = 'user/UPDATE_USER_FAILED';

export const UPDATE_USER_AVATAR = 'user/UPDATE_USER_AVATAR';
export const UPDATE_USER_AVATAR_COMPLETED = 'user/UPDATE_USER_AVATAR_COMPLETED';
export const UPDATE_USER_AVATAR_FAILED = 'user/UPDATE_USER_AVATAR_FAILED';

export const RESET_USER_AVATAR_UPLOAD = 'user/RESET_USER_AVATAR_UPLOAD';

export const GET_USER_FAVORITE_REPORTS = 'user/GET_USER_FAVORITE_REPORTS';
export const GET_USER_FAVORITE_REPORTS_COMPLETED =
	'user/GET_USER_FAVORITE_REPORTS_COMPLETED';
export const GET_USER_FAVORITE_REPORTS_FAILED =
	'user/GET_USER_FAVORITE_REPORTS_FAILED';

export const ADD_USER_FAVORITE_REPORT = 'user/ADD_USER_FAVORITE_REPORT';
export const ADD_USER_FAVORITE_REPORT_COMPLETED =
	'user/ADD_USER_FAVORITE_REPORT_COMPLETED';
export const ADD_USER_FAVORITE_REPORT_FAILED =
	'user/ADD_USER_FAVORITE_REPORT_FAILED';

export const REMOVE_USER_FAVORITE_REPORT = 'user/REMOVE_USER_FAVORITE_REPORT';
export const REMOVE_USER_FAVORITE_REPORT_COMPLETED =
	'user/REMOVE_USER_FAVORITE_REPORT_COMPLETED';
export const REMOVE_USER_FAVORITE_REPORT_FAILED =
	'user/REMOVE_USER_FAVORITE_REPORT_FAILED';

export const LOGOUT = 'user/LOGOUT';
