import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import moment from 'moment';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';
import { getFullCardByBrandName } from '../../../../helpers/shared/cardBrandHelper';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';

import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ArticleIcon from '@mui/icons-material/Article';
import BuildIcon from '@mui/icons-material/Build';

const styles = (theme) => ({
	tableCell: {
		fontWeight: 600,
		paddingTop: '10px',
		paddingBottom: '10px',
		color: theme.palette.currentInventory.listGroup.headerTextColor,
		border: 'none'
	},
	setWidth: {
		width: '175px',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis'
	},
	highlightedCell: {
		fontWeight: 600,
		color: theme.palette.currentInventory.listGroup.highlightedCell,
		border: 'none'
	},
	inactiveIcon: {
		visibility: 'hidden'
	},
	warningActive: {
		color: theme.palette.currentInventory.listGroup.warningActive
	},
	buildIcon: {
		color: theme.palette.currentInventory.listGroup.buildIcon
	}
});

export const GroupRow = (props) => {
	const { classes, t, groupBy, row, onClickGenerate } = props;
	const {
		tableRow,
		tableCell,
		setWidth,
		highlightedCell,
		inactiveIcon,
		warningActive,
		buildIcon
	} = classes;

	const expiringSoon = moment(row.dueDate).isBefore('2022-06-01T07:30:16.000Z');

	const isGroupByCard = groupBy === 'card';

	return (
		<TableRow key={row.merchantTransactionId} className={tableRow}>
			<TableCell className={tableCell}>{row.caseId}</TableCell>
			<TableCell
				className={clsx(tableCell, setWidth)}
			>{`${row.billingFirstName} ${row.billingLastName}`}</TableCell>
			<TableCell className={highlightedCell}>{row.amount}</TableCell>
			{!isGroupByCard && (
				<TableCell className={tableCell}>
					<img
						src={getFullCardByBrandName(row.paymentCardBrand)}
						height={25}
						width={45}
					/>
				</TableCell>
			)}
			<TableCell className={tableCell}>{row.merchantId}</TableCell>
			<TableCell className={tableCell} component="th" scope="row">
				{moment(row.receivedByMerchantOn).format('YYYY-MM-DD')}
			</TableCell>
			<TableCell className={tableCell}>{row.code}</TableCell>
			<TableCell className={tableCell} component="th" scope="row">
				{moment(row.dueDate).format('YYYY-MM-DD')}
			</TableCell>
			<TableCell className={tableCell}>
				<Tooltip title="Expiring Soon">
					<WarningAmberIcon
						className={expiringSoon ? warningActive : inactiveIcon}
					/>
				</Tooltip>
			</TableCell>
			<TableCell className={tableCell}>
				<Link to={`/representments/build/${row.caseId}`}>
					<Tooltip
						title={t(
							'components.CurrentInventory.CurrentInventoryList.representmentBuilder'
						)}
					>
						<BuildIcon className={buildIcon} />
					</Tooltip>
				</Link>
			</TableCell>
			<TableCell className={tableCell} style={{ cursor: 'pointer' }}>
				<Tooltip
					title={t(
						'components.CurrentInventory.CurrentInventoryList.generateDocument'
					)}
				>
					<ArticleIcon
						className={buildIcon}
						onClick={() => {
							onClickGenerate(row.caseId);
						}}
					/>
				</Tooltip>
			</TableCell>
		</TableRow>
	);
};

GroupRow.propTypes = {
	groupBy: PropTypes.string,
	row: PropTypes.object,
	onClickGenerate: PropTypes.func,
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(GroupRow));
