import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import OptionsSelector from './OptionsSelector';
import ChargebackSummary from './ChargebackSummary';
import Reporter from '../../Reporter';

import Container from '@mui/material/Container';

const styles = (theme) => ({
	root: {
		display: 'flex',
		marginTop: '20px',
		width: '100%',
		flexDirection: 'column'
	}
});

const ChargebackSummaryView = (props) => {
	const { classes, reportViewId } = props;
	const { root } = classes;

	return (
		<Container className={root} maxWidth={false}>
			<Reporter
				reportViewId={reportViewId}
				OverrideFilterComponent={OptionsSelector}
				OverrideReportComponent={ChargebackSummary}
			/>
		</Container>
	);
};

ChargebackSummaryView.propTypes = {
	reportViewId: PropTypes.string,
	classes: PropTypes.object
};

export default withStyles(styles)(ChargebackSummaryView);
