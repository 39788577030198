import React from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import Skeleton from '@mui/material/Skeleton';

const styles = (theme) => ({
	skeletonMargin: {
		margin: '0 10px 0 10px'
	}
});

const TileSkeleton = (props) => {
	const { classes, size } = props;
	const { skeletonMargin } = classes;

	return (
		<Skeleton
			className={skeletonMargin}
			variant="square"
			width={size.width}
			height={size.height}
		/>
	);
};

TileSkeleton.propTypes = {
	size: PropTypes.object,
	classes: PropTypes.object
};

export default withStyles(styles)(TileSkeleton);
