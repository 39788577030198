import shortUuid from 'short-uuid';

export default function (tiles) {
	return tiles.map((tile) => {
		const generatedUuid = shortUuid.uuid();

		const tileConfiguration = {
			label: tile.label,
			universalUniqueIdentifier: generatedUuid
		};

		if (tile.reportId) {
			(tileConfiguration.reportId = tile.reportId),
				(tileConfiguration.userIntefaceDefinition = 'dashboardWidget');
		} else {
			tileConfiguration.tileName = tile.tileName;
		}

		if (tile.filters) {
			tileConfiguration.filters = tile.filters;
		}

		return tileConfiguration;
	});
}
