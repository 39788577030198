export const sumOfActualsAndForecast = (data) => {
	const monthlyChb = data.reduce((total, element) => {
		return total + element.a_mth_chb;
	}, 0);
	const dailyChb = data.reduce((total, element) => {
		return total + element.a_dly_chb;
	}, 0);
	const forecastChb = data.reduce((total, element) => {
		return total + element.f_chb;
	}, 0);
	return { monthlyChb, dailyChb, forecastChb };
};
