import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import getTileListForlayout from '../../../../../../helpers/tiles/getTileListForLayout';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const styles = (theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		padding: '20px',
		justifyContent: 'center',
		alignItems: 'center'
	},
	row: {
		display: 'flex',
		justifyContent: 'center'
	},
	icon: {
		color: theme.palette.createLayoutModal.successIcon,
		fontSize: '8rem'
	},
	button: {
		marginTop: '5px',
		marginRight: '10px',
		cursor: 'pointer'
	}
});

export const CloneOrNew = (props) => {
	const {
		classes,
		t,
		closeModal,
		backToManage,
		createdLayoutConfiguration,
		layoutTiles,
		isLoadingLayouts
	} = props;
	const { container, row, icon, button } = classes;

	const tileList = getTileListForlayout(
		layoutTiles,
		createdLayoutConfiguration.layoutUniversalUniqueIdentifier
	);

	return (
		<>
			{!tileList ||
			isLoadingLayouts ||
			tileList.isLoading ||
			!tileList.hasBeenLoaded ? (
				<div className={container}>
					<CircularProgress />
				</div>
			) : (
				<div className={container}>
					<CheckCircleIcon className={icon} />
					<div className={row}>
						<Button
							className={button}
							variant="outlined"
							color="primary"
							onClick={backToManage}
						>
							{t('components.modals.createLayout.navigation.backToManage')}
						</Button>
						<Button
							className={button}
							variant="outlined"
							color="secondary"
							onClick={closeModal}
						>
							{t('components.modals.createLayout.navigation.close')}
						</Button>
					</div>
				</div>
			)}
		</>
	);
};

CloneOrNew.propTypes = {
	createdLayoutConfiguration: PropTypes.object,
	isLoadingLayouts: PropTypes.bool,
	layoutTiles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	closeModal: PropTypes.func,
	backToManage: PropTypes.func,
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(CloneOrNew));
