import React from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import Skeleton from '@mui/material/Skeleton';
import Toolbar from '@mui/material/Toolbar';

const styles = (theme) => ({
	toolbar: {
		display: 'flex',
		width: '100%',
		alignItems: 'flex-start',
		flexDirection: 'column',
		padding: '4px'
	},
	filterRowContainer: {
		display: 'flex',
		width: '100%',
		justifyContent: 'flex-end',
		flexDirection: 'row'
	},
	skeletonMargin: {
		margin: '0 10px 0 10px'
	}
});

const StandardAppBarSkeleton = (props) => {
	const { classes } = props;
	const { toolbar, filterRowContainer, skeletonMargin } = classes;

	return (
		<Toolbar className={toolbar}>
			<Skeleton variant="text" width={150} height={40} />
			<div className={filterRowContainer}>
				<Skeleton
					className={skeletonMargin}
					variant="text"
					width={'60%'}
					height={40}
				/>
				<Skeleton
					className={skeletonMargin}
					variant="rounded"
					width={100}
					height={40}
				/>
			</div>
		</Toolbar>
	);
};

StandardAppBarSkeleton.propTypes = {
	classes: PropTypes.object
};

export default withStyles(styles)(StandardAppBarSkeleton);
