import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withSize } from 'react-sizeme';
import RepresentmentViewer from '../../components/RepresentmentViewer';

import Container from '@mui/material/Container';

const styles = (theme) => ({
	root: {
		display: 'flex',
		flexFlow: 'column',
		padding: '0px 40px',
		alignItems: 'center',
		alignContent: 'center'
	}
});

export const RepresentmentViewerPage = (props) => {
	const { classes, size } = props;
	const { root } = classes;

	return (
		<Container className={root} maxWidth={false}>
			<RepresentmentViewer size={size} />
		</Container>
	);
};

RepresentmentViewerPage.propTypes = {
	size: PropTypes.object,
	classes: PropTypes.object
};

export default withStyles(styles)(
	withSize({ monitorHeight: true })(RepresentmentViewerPage)
);
