import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import RepresentmentWraper from '../../components/Representment/RepresentmentWrapper/';
import ReversalReasonBuilder from '../../components/Representment/ReversalReasonBuilder/';

import Container from '@mui/material/Container';

const styles = (theme) => ({
	root: {
		display: 'flex',
		flexFlow: 'column',
		padding: '0px 40px',
		alignItems: 'center',
		alignContent: 'center'
	}
});

export const Representment = (props) => {
	const { classes } = props;
	const { root } = classes;

	return (
		<Container className={root} maxWidth={false}>
			<RepresentmentWraper />
			<ReversalReasonBuilder />
		</Container>
	);
};

Representment.propTypes = {
	classes: PropTypes.object
};

export default withStyles(styles)(Representment);
