export default {
	overview: {
		mid: '123456789',
		billingDescriptor: 'SomeBillingDesc*',
		customerServicePhone: '763-555-1234',
		programDesctription:
			"Here we have a program description of what the program is about. It could be a very long description or it could be relatively short. It all depends on the program and what the description is for that program. Here we have a medium length description because this box is fairly large and it would look silly if there wasn't at least a medium length description here."
	},
	chargebackInformation: {
		code: '1040',
		amount: 27.95,
		postedOn: '2022-05-09',
		caseId: '8022224129'
	},
	customerInformation: {
		billingFirstName: 'John',
		billingLastName: 'Doe',
		billingAddress: '1234 Street Drive NW',
		billingCity: 'City',
		billingStateProvince: 'ST',
		billingPostalCode: '123456',
		phoneNumber: '763-555-1234',
		customerId: 1223344,
		orderId: 11223344
	},
	debitTransaction: {
		maskedCardNumber: '123*********6789',
		paymentCardBrand: 'mastercard',
		settledOn: '01-22-2022',
		settledAmount: '27.95',
		authorizationCode: 'A12345',
		authorizedOn: '01-22-2022',
		acquirerReferenceNumber: '49237437391234234',
		paymentCardAssociationTransactionId: '234DD5533',
		merchantTransactionId: '49237437391',
		paymentCardVerificationValueResponse: null,
		addressVerificationServiceResponse: '55'
	},
	chargebackReversalSupplementalData: {
		membershipStartDate: '01-22-2022',
		membershipFee: 27.95,
		customerServicePhone: '763-555-1234',
		canceledOn: null
	},
	cdrns: [
		{
			caseId: null,
			issuerCaseId: null
		}
	],
	ethocaAlerts: [
		{
			id: null
		}
	],
	creditTransactions: {},
	previouslyBilledUndisputedTransactions: [
		{
			maskedCardNumber: '123*********6789',
			settledOn: '2021-07-31',
			settledAmount: '27.95',
			authorizationCode: 'A1234',
			authorizedOn: '2021-07-31',
			acquirerReferenceNumber: '234537257868568',
			paymentCardAssociationTransactionId: '2352436324562346346',
			merchantTransactionId: '6856675757',
			paymentCardVerificationValueResponse: null
		},
		{
			maskedCardNumber: '123*********6789',
			settledOn: '2021-08-30',
			settledAmount: '27.95',
			authorizationCode: 'A24433',
			authorizedOn: '2021-08-30',
			acquirerReferenceNumber: '12341245326436737',
			paymentCardAssociationTransactionId: '34628856856856858',
			merchantTransactionId: '2453245345',
			paymentCardVerificationValueResponse: null
		}
	]
};
