import * as NotificationEpics from './epic';
import * as notificationActions from './actions';
import * as notificationActionTypes from './actionTypes';
import { notificationReducer } from './reducer';

const notificationEpics = Object.values({ ...NotificationEpics });

export {
	notificationActions,
	notificationActionTypes,
	notificationEpics,
	notificationReducer
};
