export const GET_RECOVERY_HISTORY_BY_OUTCOME =
	'recoveryHistory/GET_RECOVERY_HISTORY_BY_OUTCOME';
export const GET_RECOVERY_HISTORY_BY_OUTCOME_COMPLETED =
	'recoveryHistory/GET_RECOVERY_HISTORY_BY_OUTCOME_COMPLETED';
export const GET_RECOVERY_HISTORY_BY_OUTCOME_FAILED =
	'recoveryHistory/GET_RECOVERY_HISTORY_BY_OUTCOME_FAILED';

export const GET_RECOVERY_HISTORY_BY_CARD =
	'recoveryHistory/GET_RECOVERY_HISTORY_BY_CARD';
export const GET_RECOVERY_HISTORY_BY_CARD_COMPLETED =
	'recoveryHistory/GET_RECOVERY_HISTORY_BY_CARD_COMPLETED';
export const GET_RECOVERY_HISTORY_BY_CARD_FAILED =
	'recoveryHistory/GET_RECOVERY_HISTORY_BY_CARD_FAILED';

export const GET_RECOVERY_HISTORY_BY_REASON =
	'recoveryHistory/GET_RECOVERY_HISTORY_BY_REASON';
export const GET_RECOVERY_HISTORY_BY_REASON_COMPLETED =
	'recoveryHistory/GET_RECOVERY_HISTORY_BY_REASON_COMPLETED';
export const GET_RECOVERY_HISTORY_BY_REASON_FAILED =
	'recoveryHistory/GET_RECOVERY_HISTORY_BY_REASON_FAILED';
