import generateInitialFilters from '../widget/generateInitialFilters';

export default function (tileBeingModified) {
	let filters = {};

	if (tileBeingModified) {
		filters = generateInitialFilters(
			tileBeingModified.options,
			tileBeingModified.filters
		);
	}

	return filters;
}
