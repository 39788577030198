import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';

const styles = (theme) => ({
	paper: {
		backgroundColor: '#ffffff',
		cursor: 'pointer',
		marginRight: '-20px',
		marginBottom: '-20px'
	},
	icon: {
		color: theme.palette.primary.main
	}
});

const EditAvatar = (props) => {
	const { classes, onClick } = props;
	const { paper, icon } = classes;

	return (
		<Paper className={paper} onClick={onClick} elevation={1}>
			<EditIcon className={icon} />
		</Paper>
	);
};

EditAvatar.propTypes = {
	classes: PropTypes.object,
	onClick: PropTypes.func
};

export default withStyles(styles)(EditAvatar);
