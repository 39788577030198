import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';

import Chip from '@mui/material/Chip';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

const styles = (theme) => ({
	flexColumn: {
		display: 'flex',
		flexDirection: 'column'
	},
	flex: {
		flex: 1
	},
	flexStart: {
		alignItems: 'flex-start'
	},
	flexEnd: {
		alignItems: 'flex-end'
	},
	flexRow: {
		display: 'flex',
		flexDirection: 'row',
		alignSelf: 'stretch'
	},
	additionalContentList: {
		display: 'flex',
		flexDirection: 'row',
		alignSelf: 'stretch',
		margin: '5px'
	},
	timeStampRow: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end'
	},
	additionalContentContainer: {
		display: 'flex',
		flexDirection: 'column',
		paddingTop: '5px'
	},
	actionItem: {
		margin: '0px 3px',
		cursor: 'pointer'
	}
});

const InitialNotificationDisplay = (props) => {
	const {
		classes,
		handleSubmit,
		renderAssigned,
		entry,
		setDeleteData,
		setIsEditable,
		t
	} = props;
	const {
		flexColumn,
		flexStart,
		flexEnd,
		flexRow,
		flex,
		additionalContentList,
		additionalContentContainer,
		timeStampRow,
		actionItem
	} = classes;

	const history = useHistory();

	const handleOnSuggestedActionClick = (link) => {
		history.push(link);
	};

	return (
		<Fragment>
			<div className={clsx(flexColumn, flex)}>
				<div className={flexRow}>
					<div className={clsx(flexColumn, flex)}>
						<div className={flexRow}>
							<Typography variant="h6">{entry.message}</Typography>
						</div>
						<div className={flexRow}>
							<Typography variant="caption" sx={{ m: 1 }}>
								{t('pages.notifications.timeline.assigned')}
								{entry.assignedTo ? entry.assignedTo : 'Not Assigned'}
							</Typography>
							<Typography variant="caption" sx={{ m: 1 }}>
								{t('pages.notifications.timeline.createdBy')}
								{entry.createdBy ? entry.createdBy : 'System'}
							</Typography>
							{entry.tags.length && (
								<Typography variant="caption" sx={{ m: 1 }}>
									{t('pages.notifications.timeline.tags')}
									{entry.tags.split(',').map((tag) => (
										<Chip
											size="small"
											variant="outlined"
											key={tag}
											label={tag}
										/>
									))}
								</Typography>
							)}
						</div>
					</div>
				</div>
				{entry && entry.actionItems.length > 0 && (
					<div className={additionalContentContainer}>
						<Divider width="40%" />
						<span>Suggested Actions</span>
						<div className={additionalContentList}>
							{entry.actionItems.map((entry, index) => {
								return (
									<Chip
										size="medium"
										variant="primary"
										className={actionItem}
										onClick={() => {
											handleOnSuggestedActionClick(entry.link);
										}}
										key={`${entry.title}-${index}`}
										label={entry.title}
									/>
								);
							})}
						</div>
					</div>
				)}
				<div className={timeStampRow}>
					<Typography variant="caption">
						{t('pages.notifications.timeline.updatedOn')}
					</Typography>
					<Typography variant="caption">
						{entry.updatedOn ? entry.updatedOn : entry.createdOn}
					</Typography>
				</div>
			</div>
		</Fragment>
	);
};

InitialNotificationDisplay.propTypes = {
	classes: PropTypes.object,
	handleSubmit: PropTypes.func,
	renderAssigned: PropTypes.func,
	entry: PropTypes.object,
	setDeleteData: PropTypes.func,
	setIsEditable: PropTypes.func,
	t: PropTypes.func
};

export default withStyles(styles)(
	withTranslation()(InitialNotificationDisplay)
);
