export default {
	groups: {
		one: 'High Risk Merchants',
		two: 'Newly Onboarded Merchants',
		three: 'Merchants With Current Violations',
		four: 'Merchants With Former Violations'
	},
	notifications: {
		one: {
			severity: 'severe',
			category: 'Disputes',
			text:
				'Weekly Fisherman disputes accelerating at 2x the 30-day rolling average'
		},
		two: {
			severity: 'severe',
			category: 'Mitigation',
			text:
				'Global Gift Co has over 30% of mitigated refunds resulting in a chargeback'
		},
		three: {
			severity: 'warning',
			category: 'Refunds',
			text:
				'Beautiful Memories refund rate on new product SKU 897381 at 2x company average over last 14 days'
		},
		four: {
			severity: 'severe',
			category: 'Fraud',
			text:
				'Trinkets monthly fraud % at issuing banks Minnesota State Bank & Iowa Farmers Credit increased 3x over last 4 weeks'
		},
		five: {
			severity: 'severe',
			category: 'Authorizations',
			text:
				'ABC Vitamins Subscription authorization rates on Visa have decreased by a compounded rate of 30% over the last 6 months'
		},
		six: {
			severity: 'warning',
			category: 'Disputes',
			text:
				'The Weekly Chemist affiliate Alchemy Online dispute % has increased by 20% on first installment over the last 14 days'
		},
		seven: {
			severity: 'warning',
			category: 'Customer Service',
			text:
				'Wiz Kid Subscriber had over 60% of contacts to customer service resulting in a dispute over the past 30 days'
		},
		eight: {
			severity: 'info',
			category: 'List Source',
			text:
				'Pen of the Month list source C23519 submitting transactions after 30 days of zero'
		},
		nine: {
			severity: 'warning',
			category: 'Fraud',
			text:
				'Flowers by Friday had greater than 2X increase in fraud index score compared to prior week'
		},
		ten: {
			severity: 'severe',
			category: 'Card Distribution',
			text: 'Baby Box has marginal bin sales greater than 20% of overall sales'
		},
		eleven: {
			severity: 'warning',
			category: 'Mitigation',
			text:
				'Cigar Central Visa Mitigation leakage has exceeded 20% at least once in the past 3 months'
		},
		twelve: {
			severity: 'info',
			category: 'Product',
			text:
				'Coffee Club received first transactions on 10 new product SKUs in the last 7 days'
		},
		thirteen: {
			severity: 'severe',
			category: 'Authorizations',
			text:
				'Protein Powder Now first attempt authorization rates on issuing banks Northland Bank & Unity Trust & Bank down 15% over the last 21 days along with a 20% increase in fraud dollars 30 days prior'
		},
		fourteen: {
			severity: 'severe',
			category: 'Disputes',
			text:
				'Horoscopes by Lenny Visa disputes up 30% over the last 3 months with chargeback to RDR ratio shifting by 50%'
		}
	}
};
