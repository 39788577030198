import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import SuggestedDisplayTypeItem from './SuggestedDisplayTypeItem';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const styles = (theme) => ({
	suggestedContainer: {
		display: 'flex',
		flexDirection: 'column',
		padding: '10px'
	},
	label: {
		fontSize: '.8rem',
		color: theme.palette.reportViews.suggestedDisplayLabel
	},
	noSuggestedTypes: {
		fontSize: '.8'
	}
});

export const SuggestedDisplayTypeList = (props) => {
	const { classes, t, suggestedDisplayTypes } = props;
	const { suggestedContainer, label } = classes;

	return (
		<div className={suggestedContainer}>
			<Typography className={label}>
				{t('components.ReportViews.ReportCard.suggestedDisplayStyle')}
			</Typography>
			<Stack
				direction="row"
				spacing={2}
				alignItems="center"
				justifyContent="left"
			>
				{suggestedDisplayTypes.length > 0 ? (
					<Fragment>
						{suggestedDisplayTypes.map((suggestedDisplayType, index) => {
							return (
								<SuggestedDisplayTypeItem
									key={index}
									suggestedDisplayType={suggestedDisplayType}
								/>
							);
						})}
					</Fragment>
				) : (
					<span>-</span>
				)}
			</Stack>
		</div>
	);
};

SuggestedDisplayTypeList.propTypes = {
	suggestedDisplayTypes: PropTypes.array,
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(SuggestedDisplayTypeList));
