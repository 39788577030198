import { noCase } from 'change-case';

export default function (notifications, type) {
	let filteredNotifcations = [];

	if (notifications && notifications.length > 0) {
		filteredNotifcations = notifications.filter(
			(notification) => noCase(notification.type) === noCase(type)
		);
	}

	return filteredNotifcations;
}
