import CONSTANTS from './constants';
import { getLabel } from './shared/cardBrandHelper';

export const sanitizeKeys = (key, label) => {
	let sanitzedValue = key;

	if (label === 'CARD_TYPES') {
		sanitzedValue = getLabel(key);
	}
	return sanitzedValue;
};

export const sanitizeForecastDateRange = (key) => {
	// TODO: this might need to just be moved to the translation file since it's text
	const constants = {
		daily: 'Daily',
		monthToDate: 'Month-to-Date',
		weekToDate: 'Week-to-Date'
	};

	return constants[key] ? constants[key] : null;
};

export const sanitizeForecastDates = (key) => {
	const constants = {
		'01': 'Jan',
		'02': 'Feb',
		'03': 'Mar',
		'04': 'Apr',
		'05': 'May',
		'06': 'Jun',
		'07': 'Jul',
		'08': 'Aug',
		'09': 'Sep',
		10: 'Oct',
		11: 'Nov',
		12: 'Dec'
	};
	if (key.includes('-')) {
		const month = key.split('-')[1];
		return constants[month] ? constants[month] : null;
	}

	return constants[key] ? constants[key] : null;
};

export const sanitizeProcessors = (key) => {
	return CONSTANTS.PROCESSORS[key] ? CONSTANTS.PROCESSORS[key] : key;
};
