import React from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import generateFilterSelect from '../../../../helpers/widget/generateFilterSelect';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import { withTranslation } from 'react-i18next';

const styles = (theme) => ({
	stack: {
		display: 'flex',
		flexFlow: 'row wrap'
	}
});

const FilterSelectDialog = (props) => {
	const {
		classes,
		t,
		options,
		filters,
		isOpen,
		onClose,
		onFilterUpdate,
		onSubmitFilters
	} = props;
	const { stack } = classes;

	const handleFilterUpdate = (option, value) => {
		const newFilter = {};
		newFilter[option] = `${value}`;

		onFilterUpdate(Object.assign({}, filters, newFilter));
	};

	const handleFilterSubmit = (filters) => {
		onSubmitFilters(filters);
		onClose();
	};

	return (
		<Dialog
			open={isOpen}
			onClose={onClose}
			aria-labelledby="max-width-dialog-title"
			fullWidth={true}
			maxWidth={'md'}
		>
			<DialogTitle id="max-width-dialog-title">
				{t('components.Widget.WidgetController.selectFilters')}
			</DialogTitle>
			<DialogContent>
				<Stack
					className={stack}
					direction="column"
					spacing={2}
					mt={1}
					alignItems="center"
					justifyContent="right"
				>
					{generateFilterSelect(options, filters, handleFilterUpdate)}
				</Stack>
			</DialogContent>
			<DialogActions>
				<div>
					<Button onClick={onClose} color="primary">
						Close
					</Button>
					<Button onClick={handleFilterSubmit} color="primary">
						Submit
					</Button>
				</div>
			</DialogActions>
		</Dialog>
	);
};

FilterSelectDialog.propTypes = {
	isOpen: PropTypes.bool,
	onClose: PropTypes.func,
	options: PropTypes.object,
	filters: PropTypes.object,
	onFilterUpdate: PropTypes.func,
	onSubmitFilters: PropTypes.func,
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(FilterSelectDialog));
