import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import {
	ComposedChart,
	CartesianGrid,
	Label,
	XAxis,
	YAxis,
	Bar
} from 'recharts';

const styles = () => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		width: '23vw',
		height: '20vh'
	}
});

const FlagGraph = (props) => {
	const { classes, countType } = props;
	const { root } = classes;

	const formatData = (countType) => {
		const topFive = [];
		topFive.sort((a, b) => b.count - a.count);
		return topFive.slice(0, 5);
	};

	const tableData = formatData(countType.selectCount);

	return (
		<div className={root}>
			<ComposedChart
				layout="vertical"
				width={500}
				height={250}
				margin={{
					top: 20,
					right: 20,
					bottom: 20,
					left: 20
				}}
				data={tableData}
			>
				<CartesianGrid stroke="#f5f5f5" />
				<XAxis
					dataKey="count"
					type="number"
					style={{ fontSize: '9px' }}
					tick={false}
				>
					<Label
						position="insideBottom"
						value={`# of ${countType.selectCount}`}
						offset={-3}
					/>
				</XAxis>
				<YAxis dataKey="name" type="category" style={{ fontSize: '11px' }} />
				<Bar
					isAnimationActive={false}
					barSize={30}
					dataKey="count"
					label={{ fill: 'white', fontSize: '11px' }}
					fill="#82ca9d"
				/>
			</ComposedChart>
		</div>
	);
};

FlagGraph.propTypes = {
	classes: PropTypes.object,
	countType: PropTypes.object
};

export default withStyles(styles)(FlagGraph);
