export const TOGGLE_RIGHT_DRAWER = 'system/TOGGLE_RIGHT_DRAWER';
export const TOGGLE_LEFT_DRAWER = 'system/TOGGLE_LEFT_DRAWER';
export const TOGGLE_NAVIGATION_DRAWER = 'system/TOGGLE_NAVIGATION_DRAWER';

export const TOGGLE_THEME = 'system/TOGGLE_THEME';

// BELEOW ARE OLD REQUIREMENTS BY SCREENSHOTTER

export const SEND_EMAIL = 'system/SEND_EMAIL';
export const SEND_EMAIL_COMPLETED = 'system/SEND_EMAIL_COMPLETED';
export const SEND_EMAIL_FAILED = 'system/SEND_EMAIL_FAILED';

export const SEND_TEXT = 'system/SEND_TEXT';
export const SEND_TEXT_COMPLETED = 'system/SEND_TEXT_COMPLETED';
export const SEND_TEXT_FAILED = 'system/SEND_TEXT_FAILED';

export const RESET_EMAIL_VALUES = 'system/RESET_EMAIL_VALUES';
export const RESET_TEXT_VALUES = 'system/RESET_TEXT_VALUES';
