import React, { Fragment, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import Draggable from 'react-draggable';
// import { ColorPicker } from 'material-ui-color';
import Xarrow from 'react-xarrows';

import IconButton from '@mui/material/IconButton';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import TextField from '@mui/material/TextField';

import CloseIcon from '@mui/icons-material/Close';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

const labelInputHeight = '24px';

const styles = (theme) => ({
	arrowPath: {
		'& svg': {
			zIndex: 1
		}
	},
	commentArrowEndAnchor: {
		position: 'absolute',
		top: '100px',
		left: '30%',
		height: '16px',
		width: '16px',
		borderRadius: '10px',
		zIndex: 16,
		opacity: 0.2,
		cursor: 'pointer',
		'&:hover': {
			opacity: 0.8
		}
	},
	commentBox: {
		display: 'flex',
		position: 'absolute',
		top: '100px',
		flexDirection: 'column',
		zIndex: 16,
		backgroundColor: 'lightgray',
		minWidth: '180px',
		borderRadius: '5px'
	},
	commentBoxHeader: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-between',
		'& .commentBoxHeaderActions': {
			display: 'flex',
			margin: '5px'
		},
		'& p': {
			marginLeft: '10px'
		},
		'& span': {
			display: 'flex',
			alignItems: 'center',
			'& button': {
				padding: '6px'
			}
		}
	},
	commentBoxHeaderDrag: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		margin: '0px!important',
		width: '100%',
		height: '20px',
		borderRadius: '5px 5px 0px 0px',
		'& span': {
			'& div': {
				'& button': {
					width: '8px',
					height: '8px',
					margin: '0px',
					'& span': {
						width: '8px',
						height: '8px',
						'& div': {
							width: '8px',
							height: '14px',
							border: '1px solid rgba(0,0,0,.40)',
							borderRadius: '50px'
						}
					}
				}
			}
		}
	},
	commentBoxText: {
		margin: '4px',
		fontFamily: 'inherit'
	},
	controllerElement: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'baseline'
	}
});

const Commenter = (props) => {
	const { comment, color, classes, onRemove } = props;
	const {
		arrowPath,
		commentArrowEndAnchor,
		commentBox,
		commentBoxHeader,
		commentBoxHeaderDrag,
		commentBoxText,
		headerLabelInput
	} = classes;

	const [
		dragIsDisabled
		// setDragIsDisabled
	] = useState(false);
	const [usePointer, setUsePointer] = useState(false);
	const [
		colorPickerData
		// setColorPickerData
	] = useState(color || '#51284f');

	const [arrowStartPosition, setArrowStartPosition] = useState({ x: 0, y: 0 });
	const [arrowEndPosition, setArrowEndPosition] = useState({ x: 0, y: 0 });

	const commentBoxStart = useRef(null);

	useEffect(() => {}, [arrowEndPosition, arrowStartPosition, commentBoxStart]);

	return (
		<Fragment>
			{usePointer && (
				<Fragment>
					<Draggable
						disabled={dragIsDisabled}
						cancel={'.comment-text-area, .MuiBox-root'}
						onDrag={(e) => setArrowEndPosition({ x: e.screenX, y: e.screenY })}
					>
						<div
							className={commentArrowEndAnchor}
							style={{ backgroundColor: colorPickerData }}
							id={`comment-arrow-${comment.idx}`}
						/>
					</Draggable>

					<Xarrow
						start={commentBoxStart}
						end={`comment-arrow-${comment.idx}`}
						className={arrowPath}
						color={colorPickerData}
						zIndex={1}
					/>
				</Fragment>
			)}
			<Draggable
				disabled={dragIsDisabled}
				key={comment.idx}
				cancel={'.comment-text-area, .MuiBox-root'}
				onDrag={(e) => setArrowStartPosition({ x: e.screenX, y: e.screenY })}
			>
				<div className={commentBox}>
					<div className={commentBoxHeader}>
						<div
							className={commentBoxHeaderDrag}
							style={{ backgroundColor: colorPickerData }}
						>
							<IconButton onClick={() => setUsePointer(!usePointer)}>
								<ArrowRightAltIcon
									ref={commentBoxStart}
									style={{
										fontSize: '30px',
										transform: 'rotate(180deg)',
										color: usePointer && 'white'
									}}
								/>
							</IconButton>
							<span>
								{/* <ColorPicker
									value={colorPickerData}
									disableTextfield={true}
									hideTextfield={true}
									disableAlpha={true}
									onOpen={() => setDragIsDisabled(!dragIsDisabled)}
									onChange={(color) => {
										setColorPickerData(`#${color.hex}`);
										console.log(color);
									}}
								/> */}
							</span>
							<IconButton onClick={() => onRemove(comment.idx)}>
								<CloseIcon style={{ fontSize: '16px' }} />
							</IconButton>
						</div>
						<div className="commentBoxHeaderActions">
							<TextField
								size="small"
								className={headerLabelInput}
								defaultValue={`#${comment.idx}`}
								type="text"
								InputLabelProps={{
									shrink: true,
									style: {
										height: labelInputHeight
									}
								}}
								InputProps={{
									style: {
										height: labelInputHeight
									}
								}}
								variant="outlined"
							/>
						</div>
					</div>
					<TextareaAutosize
						defaultValue={comment.value}
						className={`${commentBoxText} comment-text-area`}
						rowsMin={3}
					/>
				</div>
			</Draggable>
		</Fragment>
	);
};

Commenter.propTypes = {
	classes: PropTypes.object,
	comment: PropTypes.object,
	color: PropTypes.string,
	onRemove: PropTypes.func
};

export default withStyles(styles)(Commenter);
