import { getLowercase } from '../shared/cardBrandHelper';

export default function (data, groupBy, selectedCards) {
	let filteredGroups = [];

	if (groupBy === 'cardType') {
		filteredGroups = data.filter((group) => {
			return selectedCards.includes(group.key);
		});
	} else {
		filteredGroups = data.map((dateGroup) => {
			const filteredDateGroupData = dateGroup.data.filter((rowItem) => {
				return selectedCards.includes(getLowercase(rowItem.paymentCardBrand));
			});

			let newTotal = 0;

			filteredDateGroupData.forEach((rowData) => {
				newTotal += rowData.amount;
			});

			return {
				key: dateGroup.key,
				total: newTotal.toFixed(2),
				data: filteredDateGroupData
			};
		});

		filteredGroups = filteredGroups.filter((group) => {
			return group.data.length > 0;
		});
	}

	return filteredGroups;
}
