import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { UserInformationForm } from './Forms';
import EditAvatar from './buttons/EditAvatar';

import { userActions } from '../../containers/User';
import { modalActions } from '../../containers/Modal';

import { withStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';

const styles = (theme) => ({
	informationContainer: {
		display: 'flex',
		flexFlow: 'row',
		width: '500px',
		margin: '30px',
		border: theme.palette.primary.main
	},
	avatarContainer: {
		display: 'flex',
		flex: '1',
		justifyContent: 'flex-end',
		alignItems: 'center'
	},
	detailsContainer: {
		display: 'flex',
		flex: '3',
		justifyContent: 'center',
		alignItems: 'center'
	}
});

export const UserInformationSection = (props) => {
	const { classes, user, userAvatar } = props;
	const { informationContainer, avatarContainer, detailsContainer } = classes;

	const onUpdateUserInformation = (updatedUser) => {
		const { updateUser, user } = props;

		updateUser({
			userId: user.id,
			username: user.username,
			userData: updatedUser
		});
	};

	const onEditAvatar = () => {
		const { editAvatar, resetUserAvatarUpload } = props;

		resetUserAvatarUpload();
		editAvatar();
	};

	return (
		<Paper elevation={2} className={informationContainer} square>
			<div className={avatarContainer}>
				<Badge
					overlap="circular"
					anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
					badgeContent={<EditAvatar onClick={onEditAvatar} />}
				>
					<Avatar
						alt={`${user.firstName} ${user.lastName}`}
						src={`https://dev.assets.slyce360.com${userAvatar}`}
						variant="rounded"
						sx={{ width: 100, height: 100 }}
					/>
				</Badge>
			</div>
			<div className={detailsContainer}>
				<UserInformationForm onUpdate={onUpdateUserInformation} user={user} />
			</div>
		</Paper>
	);
};

UserInformationSection.propTypes = {
	user: PropTypes.object,
	userAvatar: PropTypes.string,
	isUpdateAvatarModalOpen: PropTypes.bool,
	updateUser: PropTypes.func,
	editAvatar: PropTypes.func,
	resetUserAvatarUpload: PropTypes.func,
	classes: PropTypes.object
};

const mapStateToProps = (state) => {
	return {
		user: state.userReducer.get('user'),
		userAvatar: state.userReducer.get('userAvatar'),
		isUpdateAvatarModalOpen: state.modalReducer.get('isUpdateAvatarModalOpen')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateUser: (payload) => {
			dispatch(userActions.updateUser(payload));
		},
		editAvatar: () => {
			dispatch(modalActions.toggleUpdateAvatarModal());
		},
		resetUserAvatarUpload: () => {
			dispatch(userActions.resetUserAvatarUpload());
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(UserInformationSection));
