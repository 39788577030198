import axios from '../../utilities/axios';
import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { refreshToken } from '../../utilities/refreshToken';
import { failureMessage } from '../ToastNotifications/actions';
import { primaryRestGateway } from '../../utilities/apiEndpointUtility';

import * as actions from './actions.js';
import * as actionTypes from './actionTypes.js';

export const getLayoutsEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.GET_USERS),
		mergeMap(async (action) => {
			await refreshToken();

			const response = await axios.get(
				`${primaryRestGateway()}/api/users?getLayoutCounts=true`
			);

			return response.data;
		}),
		switchMap((res) => [actions.getUsersCompleted(res)]),
		catchError((error, source) =>
			merge(
				of(
					actions.getUsersFailed(error.message),
					failureMessage(error.message)
				),
				source
			)
		)
	);

export const getUserLayouts = (action$) =>
	action$.pipe(
		ofType(actionTypes.GET_USER_LAYOUTS),
		mergeMap(async (action) => {
			await refreshToken();

			const { userId } = action.payload;

			const response = await axios.get(
				`${primaryRestGateway()}/api/layouts/${userId}?getTileConfiguration=true`
			);

			return response.data.results;
		}),
		switchMap((res) => [actions.getUserLayoutsCompleted(res)]),
		catchError((error, source) =>
			merge(
				of(
					actions.getUserLayoutsFailed(error.message),
					failureMessage(error.message)
				),
				source
			)
		)
	);
