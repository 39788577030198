import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import ReportCard from './ReportCard';
import { useHistory } from 'react-router-dom';

const styles = (theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		margin: '20px',
		padding: 0,
		width: '95%'
	},
	reportCardContainer: {
		display: 'flex',
		flexFlow: 'row wrap'
	}
});

export const ReportsList = (props) => {
	const {
		classes,
		reportsList,
		onAddFavoriteReport,
		setReportTileBeingCreated
	} = props;
	const { container, reportCardContainer } = classes;

	const history = useHistory();

	const handleReportClicked = (reportName) => {
		if (reportName === 'b8b804d8-e685-410c-bb7f-f0d03e531459') {
			history.push(`/reports/${reportName}`);
		}
	};

	return (
		<div className={container}>
			{reportsList && (
				<div className={reportCardContainer}>
					{reportsList.map((reportView) => {
						if (reportView.label !== 'Pivot Table') {
							return (
								<ReportCard
									key={reportView.id}
									reportView={reportView}
									isConfigurable={reportView.isConfigurable}
									setFavoriteStatus={onAddFavoriteReport}
									onClick={handleReportClicked}
									setReportTileBeingCreated={setReportTileBeingCreated}
								/>
							);
						}
					})}
				</div>
			)}
		</div>
	);
};

ReportsList.propTypes = {
	reportsList: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	isLoading: PropTypes.bool,
	hasBeenLoaded: PropTypes.bool,
	onAddFavoriteReport: PropTypes.func,
	setReportTileBeingCreated: PropTypes.func,
	classes: PropTypes.object
};

export default withStyles(styles)(ReportsList);
