export const handleDynamicReport = (state, action, key) => {
	const reportId = action.payload.uuid;
	const existingReport = state.get(key).get(reportId);

	if (existingReport === undefined) {
		return state.setIn(`${key}.${reportId}`.split('.'), {
			isLoading: true,
			hasBeenLoaded: false,
			error: '',
			data: {}
		});
	}

	// TODO: Make an error state.
	// 	   : Consider checking the action.type for _FAILED

	if (action.payload.data) {
		return state
			.setIn(`${key}.${reportId}.isLoading`.split('.'), false)
			.setIn(`${key}.${reportId}.hasBeenLoaded`.split('.'), true)
			.setIn(`${key}.${reportId}.error`.split('.'), '')
			.setIn(`${key}.${reportId}.data`.split('.'), action.payload.data);
	}

	if (action.payload.data === undefined) {
		return state.setIn(`${key}.${reportId}.isLoading`.split('.'), true);
	}

	return state;
};
