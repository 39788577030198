import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import GroupByControl from './GroupByControl';

const styles = (theme) => ({
	containerRow: {
		display: 'flex',
		alignSelf: 'stretch',
		margin: '1rem 0 1rem 0'
	}
});

export const NotificationSettingsActionRow = (props) => {
	const { classes, t, sortBy, setSortBy } = props;
	const { containerRow } = classes;

	const onClickButton = () => {};

	return (
		<Stack spacing={2} direction={'row-reverse'} className={containerRow}>
			<Button onClick={onClickButton} variant="outlined">
				Disable All
			</Button>
			<Button onClick={onClickButton} variant="outlined">
				Enable All
			</Button>
			<Button onClick={onClickButton} variant="outlined">
				Add Rule
			</Button>
			<GroupByControl sortBy={sortBy} setSortBy={setSortBy} />
		</Stack>
	);
};

NotificationSettingsActionRow.propTypes = {
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(
	withTranslation()(NotificationSettingsActionRow)
);
