import React, { Fragment } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import StatBarFragment from './StatBarFragment';
import statBarGenerator from '../../../helpers/recoveryHistory/statBarGenerator';
import querier from '../../../utilities/querier';

const styles = (theme) => ({
	statusbar: {
		alignSelf: 'center',
		marginTop: '10px',
		marginBottom: '20px',
		borderRadius: '10px',
		height: '30px',
		width: '80%'
	},
	hidden: {
		display: 'none'
	},
	skeleton: {
		width: '100%',
		height: '100%'
	}
});

export const StatBar = (props) => {
	const { classes, data, isLoading, hasBeenLoaded } = props;
	const { statusbar, skeleton, hidden } = classes;

	return (
		<Stack
			direction="row"
			spacing={0}
			alignItems="center"
			className={clsx(statusbar, !querier(data, 'total.value') && hidden)}
		>
			{!hasBeenLoaded || isLoading ? (
				<Fragment>
					<Skeleton variant="rect" className={skeleton} />
				</Fragment>
			) : (
				statBarGenerator(data, StatBarFragment)
			)}
		</Stack>
	);
};

StatBar.propTypes = {
	data: PropTypes.object,
	isLoading: PropTypes.bool,
	hasBeenLoaded: PropTypes.bool,
	classes: PropTypes.object,
	total: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(StatBar);
