import React from 'react';
import { withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import { withTranslation } from 'react-i18next';
import { authActions } from '../../../../containers/Auth';
import { multiFactorAuthActions } from '../../../../containers/MultiFactorAuth';
import { userActions } from '../../../../containers/User';

// import Button from '@mui/material/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import Logout from '@mui/icons-material/Logout';
import MenuItem from '@mui/material/MenuItem';

const styles = (theme) => ({
	root: {
		textTransform: 'none'
	}
});

const LogOut = (props) => {
	const handleLogOut = async (event) => {
		event.preventDefault();
		const { deAuthUser, mfaLogout, clearUser } = props;
		try {
			// window.location.href = '/';
			Auth.signOut();
			mfaLogout();
			deAuthUser();
			clearUser();
		} catch (error) {
			console.log(error.message);
		}
	};

	return (
		<MenuItem onClick={handleLogOut}>
			<ListItemIcon>
				<Logout fontSize="small" />
			</ListItemIcon>
			Logout
		</MenuItem>
	);
};

LogOut.propTypes = {
	classes: PropTypes.object,
	deAuthUser: PropTypes.func,
	mfaLogout: PropTypes.func,
	clearUser: PropTypes.func,
	t: PropTypes.func
};

const mapDispatchToProps = (dispatch) => {
	return {
		deAuthUser: () => {
			dispatch(authActions.deAuthUser());
		},
		mfaLogout: () => {
			dispatch(multiFactorAuthActions.logout());
		},
		clearUser: () => {
			dispatch(userActions.logout());
		}
	};
};

export default connect(
	null,
	mapDispatchToProps
)(withStyles(styles)(withTranslation()(LogOut)));
