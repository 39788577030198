import React, { Fragment } from 'react';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import TwelveMonthLookTable from './TwelveMonthLookTable';
// import { reorder } from '../../../../helpers/dailyChargebackReport/reorder';
// import mapTwelveMonthSummary from '../../../../helpers/dailyChargebackReport/twelveMonthLook/mapTwelveMonthSummary';
import Loader from '../../../Loader';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

const styles = (theme) => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column'
	},
	noData: {
		fontSize: '1rem'
	}
});

const _renderTwelveMonthLook = (data, hasBeenLoaded, isLoading, classes, t) => {
	const { noData } = classes;

	let rendered;

	if (!hasBeenLoaded && isLoading) {
		rendered = <Loader />;
	} else if (!hasBeenLoaded && !isLoading) {
		rendered = (
			<Typography className={noData}>
				{t(
					'components.staticReportViews.DailyChargebackReport.TwelveMonthLook.submitToLoad'
				)}
			</Typography>
		);
	} else if (hasBeenLoaded) {
		if (Object.keys(data).length === 0) {
			rendered = (
				<Typography className={noData}>
					{t(
						'components.staticReportViews.DailyChargebackReport.TwelveMonthLook.noData'
					)}
				</Typography>
			);
		} else {
			return (
				<Fragment>
					{Object.entries(data).map(([key, cardData]) => {
						if (cardData.data.length > 0) {
							return (
								<TwelveMonthLookTable
									key={key}
									data={cardData.data}
									cardType={cardData.cardType}
								/>
							);
						}
					})}
				</Fragment>
			);
		}
	}

	return rendered;
};

const TwelveMonthLook = (props) => {
	const { classes, t, data, hasBeenLoaded, isLoading } = props;
	const { root } = classes;

	return (
		<Container className={root}>
			{_renderTwelveMonthLook(example, hasBeenLoaded, isLoading, classes, t)}
			{/* TODO: "example" should be "data" */}
		</Container>
	);
};

TwelveMonthLook.propTypes = {
	classes: PropTypes.object,
	data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	error: PropTypes.string,
	filters: PropTypes.object,
	handleFilterUpdate: PropTypes.func,
	handleSubmit: PropTypes.func,
	hasBeenLoaded: PropTypes.bool,
	isLoading: PropTypes.bool,
	report: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(TwelveMonthLook));

// @TODO: this is demo data - Remove when we have the call wired up
const example = [
	{
		cardType: 'visa',
		dateRangeStart: '2022-04-14',
		dateRangeEnd: '2022-04-14',
		mid: ['345045106886', '345045117883'],
		processor: 'FISERV',
		data: [
			{
				date: '2022-04',
				month: 'Nov',
				chargebacks: 24,
				transactions: 22648,
				chargebackPercent: 0.106,
				cbUnitsOver: 76,
				transactionsOver: 19981,
				rdr: 0,
				cdrn: 12,
				ethoca: 18,
				total: 36,
				disputePercent: 0.645,
				deflectionPercent: 12.327
			},
			{
				date: '2022-03',
				month: 'Dec',
				chargebacks: 50,
				transactions: 22799,
				chargebackPercent: 0.219,
				cbUnitsOver: 50,
				transactionsOver: 17243,
				rdr: 4,
				cdrn: 5,
				ethoca: 6,
				total: 21,
				disputePercent: 0.217,
				deflectionPercent: 15.674
			},
			{
				date: '2022-02',
				month: 'Jan',
				chargebacks: 16,
				transactions: 14398,
				chargebackPercent: 0.111,
				cbUnitsOver: 84,
				transactionsOver: 12620,
				rdr: 8,
				cdrn: 14,
				ethoca: 10,
				total: 214,
				disputePercent: 0.178,
				deflectionPercent: 36.276
			},
			{
				date: '2022-01',
				month: 'Feb',
				chargebacks: 22,
				transactions: 18874,
				chargebackPercent: 0.117,
				cbUnitsOver: 78,
				transactionsOver: 16430,
				rdr: 9,
				cdrn: 9,
				ethoca: 0,
				total: 54,
				disputePercent: 0.845,
				deflectionPercent: 54.365
			},
			{
				date: '2021-12',
				month: 'Mar',
				chargebacks: 22,
				transactions: 20340,
				chargebackPercent: 0.108,
				cbUnitsOver: 78,
				transactionsOver: 17896,
				rdr: 0,
				cdrn: 8,
				ethoca: 15,
				total: 301,
				disputePercent: 0.514,
				deflectionPercent: 42.345
			},
			{
				date: '2021-11',
				month: 'Apr',
				currentMonth: true,
				chargebacks: 100,
				transactions: 18707,
				chargebackPercent: 0.537,
				cbUnitsOver: 68,
				transactionsOver: 7549,
				rdr: 7,
				cdrn: 9,
				ethoca: 12,
				total: 141,
				disputePercent: 0.747,
				deflectionPercent: 34.114
			},
			{
				date: '2022-05',
				month: 'May',
				chargebacks: 131,
				transactions: 19449,
				chargebackPercent: 0.671,
				cbUnitsOver: 44,
				transactionsOver: 4939
			},
			{
				date: '2022-06',
				month: 'Jun',
				chargebacks: 124,
				transactions: 18566,
				chargebackPercent: 0.668,
				cbUnitsOver: 43,
				transactionsOver: 4784
			},
			{
				date: '2022-07',
				month: 'Jul',
				chargebacks: 127,
				transactions: 18530,
				chargebackPercent: 0.683,
				cbUnitsOver: 40,
				transactionsOver: 4458
			},
			{
				date: '2022-08',
				month: 'Aug',
				chargebacks: 118,
				transactions: 18125,
				chargebackPercent: 0.652,
				cbUnitsOver: 45,
				transactionsOver: 5001
			},
			{
				date: '2022-09',
				month: 'Sep',
				chargebacks: 120,
				transactions: 17064,
				chargebackPercent: 0.703,
				cbUnitsOver: 34,
				transactionsOver: 3734
			},
			{
				date: '2022-10',
				month: 'Oct',
				chargebacks: 120,
				transactions: 17059,
				chargebackPercent: 0.702,
				cbUnitsOver: 34,
				transactionsOver: 3760
			}
		]
	},
	{
		cardType: 'mastercard',
		dateRangeStart: '2022-04-14',
		dateRangeEnd: '2022-04-14',
		mid: ['345045106886', '345045117883'],
		processor: 'FISERV',
		data: [
			{
				chargebacks: 11,
				transactions: 5861,
				date: '2021-08',
				month: 'Nov',
				chargebackPercent: 0.231,
				cbUnitsOver: 89,
				transactionsOver: 4021,
				preChargeback: 11,
				cdrn: 10,
				ethoca: 15,
				total: 47,
				disputePercent: 0.714,
				deflectionPercent: 27.831
			},
			{
				chargebacks: 18,
				transactions: 5564,
				date: '2021-09',
				month: 'Dec',
				chargebackPercent: 0.307,
				cbUnitsOver: 82,
				transactionsOver: 4661,
				preChargeback: 14,
				cdrn: 9,
				ethoca: 10,
				total: 61,
				disputePercent: 0.841,
				deflectionPercent: 31.951
			},
			{
				chargebacks: 15,
				transactions: 5537,
				date: '2021-10',
				month: 'Jan',
				chargebackPercent: 0.27,
				cbUnitsOver: 85,
				transactionsOver: 4564,
				preChargeback: 7,
				cdrn: 16,
				ethoca: 6,
				total: 44,
				disputePercent: 0.621,
				deflectionPercent: 22.348
			},
			{
				chargebacks: 10,
				transactions: 5147,
				date: '2021-11',
				month: 'Feb',
				chargebackPercent: 0.181,
				cbUnitsOver: 90,
				transactionsOver: 4870,
				preChargeback: 5,
				cdrn: 4,
				ethoca: 10,
				total: 29,
				disputePercent: 0.554,
				deflectionPercent: 24.157
			},
			{
				chargebacks: 18,
				transactions: 5528,
				date: '2021-12',
				month: 'Mar',
				chargebackPercent: 0.35,
				cbUnitsOver: 82,
				transactionsOver: 3947,
				preChargeback: 6,
				cdrn: 18,
				ethoca: 4,
				total: 44,
				disputePercent: 0.624,
				deflectionPercent: 37.314
			},
			{
				chargebacks: 13,
				transactions: 780,
				currentMonth: true,
				date: '2022-01',
				month: 'Apr',
				chargebackPercent: 0.238,
				cbUnitsOver: 87,
				transactionsOver: 4651,
				preChargeback: 13,
				cdrn: 14,
				ethoca: 9,
				total: 101,
				disputePercent: 0.368,
				deflectionPercent: 41.254
			},
			{
				chargebacks: 27,
				transactions: 4130,
				date: '2022-02',
				month: 'May',
				chargebackPercent: 3.481,
				cbUnitsOver: 73,
				transactionsOver: -1031
			},
			{
				chargebacks: 30,
				transactions: 4422,
				date: '2022-03',
				month: 'Jun',
				chargebackPercent: 0.731,
				cbUnitsOver: 70,
				transactionsOver: 2117
			},
			{
				chargebacks: 28,
				transactions: 4058,
				date: '2022-04',
				month: 'Jul',
				chargebackPercent: 0.651,
				cbUnitsOver: 71,
				transactionsOver: 2505
			},
			{
				chargebacks: 28,
				transactions: 4010,
				date: '2022-05',
				month: 'Aug',
				chargebackPercent: 0.694,
				cbUnitsOver: 72,
				transactionsOver: 2182
			},
			{
				chargebacks: 27,
				transactions: 3728,
				date: '2022-06',
				month: 'Sep',
				chargebackPercent: 0.692,
				cbUnitsOver: 72,
				transactionsOver: 2161
			},
			{
				chargebacks: 28,
				transactions: 3670,
				date: '2022-07',
				month: 'Oct',
				chargebackPercent: 0.753,
				cbUnitsOver: 72,
				transactionsOver: 1856
			}
		]
	},
	{
		cardType: 'discover',
		dateRangeStart: '2022-04-14',
		dateRangeEnd: '2022-04-14',
		mid: ['345045106886', '345045117883'],
		processor: 'FISERV',
		data: [
			{
				chargebacks: 8,
				transactions: 448,
				date: '2021-08',
				month: 'Nov',
				chargebackPercent: 1.786,
				cbUnitsOver: 92,
				transactionsOver: 352
			},
			{
				chargebacks: 6,
				transactions: 419,
				date: '2021-09',
				month: 'Dec',
				chargebackPercent: 1.432,
				cbUnitsOver: 94,
				transactionsOver: 181
			},
			{
				chargebacks: 15,
				transactions: 443,
				date: '2021-10',
				month: 'Jan',
				chargebackPercent: 3.386,
				cbUnitsOver: 85,
				transactionsOver: 1057
			},
			{
				chargebacks: 12,
				transactions: 482,
				date: '2021-11',
				month: 'Feb',
				chargebackPercent: 2.49,
				cbUnitsOver: 88,
				transactionsOver: 718
			},
			{
				chargebacks: 25,
				transactions: 753,
				date: '2021-12',
				month: 'Mar',
				chargebackPercent: 3.32,
				cbUnitsOver: 75,
				transactionsOver: -1747
			},
			{
				chargebacks: 9,
				transactions: 780,
				currentMonth: true,
				date: '2022-01',
				month: 'Apr',
				chargebackPercent: 1.174,
				cbUnitsOver: 91,
				transactionsOver: 136
			},
			{
				chargebacks: 8,
				transactions: 708,
				date: '2022-02',
				month: 'May',
				chargebackPercent: 1.213,
				cbUnitsOver: 91,
				transactionsOver: -151
			},
			{
				chargebacks: 9,
				transactions: 815,
				date: '2022-03',
				month: 'Jun',
				chargebackPercent: 1.164,
				cbUnitsOver: 91,
				transactionsOver: -134
			},
			{
				chargebacks: 8,
				transactions: 794,
				date: '2022-04',
				month: 'Jul',
				chargebackPercent: 1.086,
				cbUnitsOver: 91,
				transactionsOver: -68
			},
			{
				chargebacks: 7,
				transactions: 824,
				date: '2022-05',
				month: 'Aug',
				chargebackPercent: 0.963,
				cbUnitsOver: 92,
				transactionsOver: 30
			},
			{
				chargebacks: 7,
				transactions: 809,
				date: '2022-06',
				month: 'Sep',
				chargebackPercent: 0.896,
				cbUnitsOver: 93,
				transactionsOver: 84
			},
			{
				chargebacks: 6,
				transactions: 840,
				date: '2022-07',
				month: 'Oct',
				chargebackPercent: 0.807,
				cbUnitsOver: 93,
				transactionsOver: 162
			}
		]
	}
];

// HACK - KEEPING CODE HERE FOR NOW UNTIL THE FINAL FORM IS REALIZED
/*
	return !hasBeenLoaded && !isLoading ? null : isLoading ? (
		<Loader />
	) : orderedData ? (
		<Container maxWidth={false} className={root}></Container>
	) : (
		<p>{t('pages.notifications.general.loading')}</p>
	);
	*/

/*
if (!data.cardData.length) {
					return <p key={index}>Failed to load card data</p>; // TODO: Add a proper Failure to load;
				}

				const { cardType, cardData } = data;

			{orderedData.map((data, index) => {
				if (!data.cardData.length) {
					return <p key={index}>Failed to load card data</p>; // TODO: Add a proper Failure to load;
				}

				const { cardType, cardData } = data;

				);
			})}

*/

/*
useEffect(() => {
	if (hasBeenLoaded && !orderedData) {
		const tableData = getTwelveMonthSummary().map((cardData, index) => {
			const cardType = cardData.map((entry) => entry.cardType)[0];

			return { cardType, cardData };
		});
		const reorderedData = reorder(
			tableData,
			['uo_visa', 'uo_mastercard', 'uo_discover'],
			'cardType'
		);
		setOrderedData(reorderedData);
	}
}, [hasBeenLoaded, isLoading]); */
