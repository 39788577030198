import * as DashboardEpics from './epics';
import * as dashboardActions from './actions';
import * as dashboardActiontypes from './actionTypes';
import { dashboardReducer } from './reducer';

const dashboardEpics = Object.values({ ...DashboardEpics });

export {
	dashboardActions,
	dashboardActiontypes,
	dashboardReducer,
	dashboardEpics
};
