import React from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import ListItem from '@mui/material/ListItem';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';

const styles = (theme) => ({
	button: {
		textTransform: 'none',
		margin: '8px'
	},
	menuItem: {
		fontSize: '14px'
	},
	root: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		width: '23vw',
		height: '20vh'
	},
	select: {
		minWidth: '5vw',
		fontSize: '.8em',
		'& div': {
			display: 'flex',
			justifyContent: 'center'
		}
	}
});

const FlagGraphSettings = (props) => {
	const {
		classes,
		determineSelectOptions,
		handleClickSelect,
		settings,
		t,
		toggleSettings
	} = props;
	const { button, menuItem, root, select } = classes;

	const handleClick = () => {
		toggleSettings((prevState) => !prevState);
	};

	return (
		<div className={root}>
			<div>
				<ListItem>
					<FormControl>
						<InputLabel shrink>
							{t('pages.widgets.settings.countby')}
						</InputLabel>
						<Select
							className={select}
							value={settings.selectCount}
							variant="standard"
							onChange={(e) => handleClickSelect('selectCount', e.target.value)}
						>
							{determineSelectOptions().map((countOption) => {
								return (
									<MenuItem
										key={countOption}
										value={countOption}
										className={menuItem}
									>
										{countOption}
									</MenuItem>
								);
							})}
						</Select>
					</FormControl>
				</ListItem>
			</div>
			<div>
				<Button
					className={button}
					size="small"
					variant="outlined"
					onClick={() => handleClick()}
				>
					{t('pages.widgets.settings.save')}
				</Button>
			</div>
		</div>
	);
};

FlagGraphSettings.propTypes = {
	classes: PropTypes.object,
	determineSelectOptions: PropTypes.func,
	handleClickSelect: PropTypes.func,
	settings: PropTypes.object,
	t: PropTypes.func,
	toggleSettings: PropTypes.func
};

export default withStyles(styles)(FlagGraphSettings);
