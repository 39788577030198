import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import NotificationDetail from './NotificationDetail';
import { modalActions } from '../../../../containers/Modal';
import isDataEmpty from '../../../../helpers/shared/isDataEmpty';

import Typography from '@mui/material/Typography';
import AttachmentViewer from '../../../modals/AttachmentViewer';

const styles = (theme) => ({
	noNotificationDetail: {
		display: 'flex',
		justifyContent: 'center',
		margin: '1vh 0 2vh 0'
	}
});

const TimelineNotificationDetails = (props) => {
	const {
		classes,
		deleteNotificationDetail,
		notificationDetails,
		renderAssigned,
		selected,
		t,
		updateNotificationDetail
	} = props;
	const { noNotificationDetail } = classes;

	const onOpenAttachmentModal = (attachment) => {
		const { setAttachmentModalData } = props;

		setAttachmentModalData(attachment);
	};

	return (
		<div>
			{isDataEmpty(notificationDetails) ? (
				<Typography variant="h6" className={noNotificationDetail}>
					{t('pages.notifications.general.noDetails')}
				</Typography>
			) : (
				notificationDetails
					.sort(function (a, b) {
						return b.id - a.id;
					})
					.map((entry) => {
						return (
							<NotificationDetail
								entry={entry}
								deleteNotificationDetail={deleteNotificationDetail}
								key={entry.id}
								renderAssigned={renderAssigned}
								selected={selected}
								updateNotificationDetail={updateNotificationDetail}
								onOpenAttachmentModal={onOpenAttachmentModal}
							/>
						);
					})
			)}
			<AttachmentViewer />
		</div>
	);
};

TimelineNotificationDetails.propTypes = {
	classes: PropTypes.object,
	deleteNotificationDetail: PropTypes.func,
	notificationDetails: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	renderAssigned: PropTypes.func,
	selected: PropTypes.object,
	t: PropTypes.func,
	attachmentModalData: PropTypes.object,
	updateNotificationDetail: PropTypes.func
};

const mapDispatchToProps = (dispatch) => {
	return {
		setAttachmentModalData: (payload) => {
			dispatch(modalActions.setAttachmentModalData(payload));
		}
	};
};

export default connect(
	null,
	mapDispatchToProps
)(withStyles(styles)(withTranslation()(TimelineNotificationDetails)));
