import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import download from 'downloadjs';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { servicePdfActions } from '../../containers/ServicePdf';
import { useLocation } from 'react-router-dom';

import Button from '@mui/material/Button';

import DownloadIcon from '@mui/icons-material/Download';
import CircularProgress from '@mui/material/CircularProgress';

const styles = (theme) => ({
	pdfContainer: {
		display: 'flex',
		flex: 1,
		width: '100%',
		flexDirection: 'column',
		margin: '2%',
		alignItems: 'center'
	},
	actionRowContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		flexDirection: 'row',
		marginBottom: '5px'
	},
	pdfPage: {
		marginBottom: '10px'
	},
	inputButton: {
		borderRadius: '4px',
		border: 'none',
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.secondary.alt,
		padding: '5px',
		fontSize: '16px',
		fontWeight: '300',
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
			cursor: 'pointer'
		}
	}
});

export const RepresentmentViewer = (props) => {
	const {
		classes,
		t,
		size,
		generateChargebackReversalDocument,
		pdfUrl,
		pdfIsLoading,
		pdfHasBeenLoaded
	} = props;
	const { pdfContainer, actionRowContainer, pdfPage, inputButton } = classes;

	const [numPages, setNumPages] = React.useState(null);
	const [pageNumber, setPageNumber] = React.useState(1);

	const location = useLocation();

	React.useEffect(() => {
		const caseId = location.pathname.split('/')[3];

		if (pdfUrl === undefined) {
			generateChargebackReversalDocument({
				caseId
			});
		}
	}, [location, generateChargebackReversalDocument]);

	const onDocumentLoadSuccess = ({ numPages }) => {
		setNumPages(numPages);
	};

	const onDocumentLoadError = (error) => {
		console.log('error', error);
	};

	const onClickDownload = () => {
		// TODO - Handle download
		// GET PDF LINK AND SEND TO DOWNLOAD HANDLER
	};

	return (
		<div className={pdfContainer}>
			<div
				className={actionRowContainer}
				style={{ width: size.width * 0.9 > 1000 ? 1000 : size.width * 0.9 }}
			>
				<Button
					variant="text"
					size="small"
					className={inputButton}
					onClick={onClickDownload}
				>
					<DownloadIcon />
				</Button>
			</div>
			{pdfUrl ? (
				<Document
					// TO REPLACE WITH PDF LINK PULLED FROM PDF SERVICE
					file={pdfUrl}
					onLoadSuccess={onDocumentLoadSuccess}
					onLoadError={onDocumentLoadError}
				>
					{numPages > 0 &&
						Array.from(Array(numPages)).map((x, index) => {
							return (
								<Page
									className={pdfPage}
									key={index}
									width={size.width * 0.9 > 1000 ? 1000 : size.width * 0.9}
									pageNumber={index + 1}
								/>
							);
						})}
				</Document>
			) : (
				<CircularProgress />
			)}
		</div>
	);
};

RepresentmentViewer.propTypes = {
	pdfUrl: PropTypes.string,
	pdfIsLoading: PropTypes.bool,
	pdfHasBeenLoaded: PropTypes.bool,
	generateChargebackReversalDocument: PropTypes.func,
	classes: PropTypes.object,
	t: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		pdfUrl: state.servicePdfReducer.get('pdfUrl'),
		pdfIsLoading: state.servicePdfReducer.get('isLoading'),
		pdfHasBeenLoaded: state.servicePdfReducer.get('hasBeenLoaded')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		generateChargebackReversalDocument: (payload) => {
			dispatch(servicePdfActions.generatePdf(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(withTranslation()(RepresentmentViewer)));
