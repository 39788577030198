import * as cloneLayoutActionTypes from './actionTypes';
import * as cloneLayoutActions from './actions';
import * as CloneLayoutEpics from './epics';
import { cloneLayoutReducer } from './reducer';

const cloneLayoutEpics = Object.values({ ...CloneLayoutEpics });

export {
	cloneLayoutActions,
	cloneLayoutActionTypes,
	cloneLayoutEpics,
	cloneLayoutReducer
};
