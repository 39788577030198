import axios from '../../utilities/axios';
import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { refreshToken } from '../../utilities/refreshToken';
import { successMessage, failureMessage } from '../ToastNotifications/actions';
import { primaryRestGateway } from '../../utilities/apiEndpointUtility';

import * as actionTypes from './actionTypes';
import * as actions from './actions';

import demoGetUsersEpic from '../../__Demo_Data__/Users/getUsersEpic';
import { demoWait } from '../../__Demo_Data__/mockRandomLoad';

export const getUsersEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.GET_USERS),
		mergeMap(async (action) => {
			await demoWait();
			// await refreshToken();

			// const users = await axios.get(`${primaryRestGateway()}/api/users`);

			const users = {
				data: demoGetUsersEpic
			};

			return users;
		}),
		switchMap((res) => [
			actions.getUsersCompleted(res.data),
			successMessage('Successfully Fetched List Of Users')
		]),
		catchError((error, source) =>
			merge(
				of(
					actions.getUsersFailed(error.message),
					failureMessage(error.message)
				),
				source
			)
		)
	);
