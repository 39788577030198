import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom';

import { modalActions } from '../../containers/Modal';
import { multiFactorAuthConstants } from '../../containers/MultiFactorAuth';

import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';

const styles = (theme) => ({
	paper: {
		display: 'flex',
		flex: 1,
		flexFlow: 'column',
		maxWidth: '500px',
		margin: '30px',
		padding: '5px 20px',
		borderColor: theme.palette.primary.main,
		[theme.breakpoints.up('smallLaptop')]: {
			flexBasis: '50%'
		},
		[theme.breakpoints.down('smallLaptop')]: {
			flexBasis: '100%'
		}
	},
	row: {
		display: 'flex',
		flex: 1,
		flexFlow: 'row',
		marginTop: '10px'
	},
	labelContainer: {
		flex: 1,
		padding: '5px'
	},
	statusContainer: {
		padding: '5px',
		color: theme.palette.primary.main
	},
	inputButtonContainer: {
		display: 'flex',
		marginBottom: '10px',
		justifyContent: 'flex-end'
	},
	inputButton: {
		borderRadius: '4px',
		width: '100px',
		border: 'none',
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.secondary.alt,
		padding: '5px',
		fontSize: '16px',
		fontWeight: '300',
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
			cursor: 'pointer'
		}
	}
});

const _renderStatus = (preferredMFA) => {
	return preferredMFA === multiFactorAuthConstants.softwareMfa
		? 'components.Accounts.MultiFactorAuthenticationSection.enabled'
		: 'components.Accounts.MultiFactorAuthenticationSection.notConfigured';
};

export const MultiFactorAuthenticationSection = (props) => {
	const {
		classes,
		t,
		userPreferredMFA,
		isMultiFactorAuthSetupModalOpen
	} = props;
	const {
		paper,
		row,
		labelContainer,
		inputButtonContainer,
		statusContainer,
		inputButton
	} = classes;

	const history = useHistory();

	const onOpenModal = (modalFunction) => {
		const { openModal } = props;

		history.push(`/account/${modalFunction}`);
		openModal();
	};

	return (
		<Paper elevation={1} className={paper} square>
			<div className={row}>
				<div className={labelContainer}>
					{t('components.Accounts.MultiFactorAuthenticationSection.mfa')}
				</div>
				<div className={statusContainer}>
					{isMultiFactorAuthSetupModalOpen ? (
						<CircularProgress />
					) : (
						<>{t(_renderStatus(userPreferredMFA))}</>
					)}
				</div>
			</div>
			<div className={inputButtonContainer}>
				<Button
					variant="contained"
					size="small"
					className={inputButton}
					disabled={true}
					onClick={() =>
						onOpenModal(
							userPreferredMFA === multiFactorAuthConstants.softwareMfa
								? 'deactivate-mfa'
								: 'activate-mfa'
						)
					}
				>
					{userPreferredMFA === multiFactorAuthConstants.softwareMfa
						? t('generic.buttons.disable')
						: t('generic.buttons.setup')}
				</Button>
			</div>
		</Paper>
	);
};

MultiFactorAuthenticationSection.propTypes = {
	openModal: PropTypes.func,
	userPreferredMFA: PropTypes.string,
	isMultiFactorAuthSetupModalOpen: PropTypes.bool,
	user: PropTypes.object,
	classes: PropTypes.object,
	t: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		user: state.authReducer.get('user'),
		userPreferredMFA: state.multiFactorAuthReducer.get('preferredMFA'),
		isMultiFactorAuthSetupModalOpen: state.modalReducer.get(
			'isMultiFactorAuthSetupModalOpen'
		)
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		openModal: () => {
			dispatch(modalActions.toggleMultiFactorAuthSetupModal());
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(withTranslation()(MultiFactorAuthenticationSection)));
