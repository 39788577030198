export const columns = [
	{
		title: 'pages.users.columns.avatar',
		field: 'avatar'
	},
	{
		title: 'pages.users.columns.username',
		field: 'username'
	},
	{
		title: 'pages.users.columns.name',
		field: 'name'
	},
	{
		title: 'pages.users.columns.email',
		field: 'email'
	},
	{
		title: 'pages.users.columns.phone',
		field: 'phone'
	}
];
