import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NotificationSettingsForm } from './Forms';
import { userActions } from '../../containers/User';

import { withStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';

const styles = (theme) => ({
	informationContainer: {
		display: 'flex',
		flexFlow: 'row',
		flex: 1,
		maxWidth: '500px',
		margin: '30px',
		padding: '5px 20px',
		borderColor: theme.palette.primary.main,
		[theme.breakpoints.up('smallLaptop')]: {
			flexBasis: '50%'
		},
		[theme.breakpoints.down('smallLaptop')]: {
			flexBasis: '100%'
		}
	}
});

export const NotificationSettingsSection = (props) => {
	const { classes, user } = props;
	const { informationContainer } = classes;

	const onUpdateNotificationSettings = (updatedNotificationSettings) => {
		// TODO implement when we setup how we will store/retreive/update
	};

	return (
		<Paper elevation={1} className={informationContainer} square>
			<NotificationSettingsForm
				currentToastSetting={
					user && user.preferences // Hack for now until structure figured out
						? user.preferences.showToastNotifications === 1
						: true
				}
				onUpdate={onUpdateNotificationSettings}
			/>
		</Paper>
	);
};

NotificationSettingsSection.propTypes = {
	user: PropTypes.object,
	classes: PropTypes.object
};

const mapStateToProps = (state) => {
	return {
		user: state.userReducer.get('user')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateUser: (payload) => {
			dispatch(userActions.updateUser(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(NotificationSettingsSection));
