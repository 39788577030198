import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@mui/styles';
import { PieChart, Pie, Cell } from 'recharts';
import renderActiveShape from './renderActiveShape';

const _sliceColors = {
	severe: '#f5989d',
	warning: '#c6b55a',
	info: '#71a6ec',
	base: '#ffffff'
};

const _mockData = [
	{
		fillColor: _sliceColors.info,
		value: 18,
		name: 'Info'
	},
	{
		fillColor: _sliceColors.warning,
		value: 22,
		name: 'Warning'
	},
	{
		fillColor: _sliceColors.severe,
		value: 60,
		name: 'Severe'
	}
];

const styles = (theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	legend: {
		display: 'flex',
		flexDirection: 'row'
	},
	legendItem: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		marginLeft: '2px',
		marginRight: '2px'
	}
});

const _calculatePieDimensions = (height, width) => {
	const smallestDimension = height < width ? height : width;
	return {
		height: smallestDimension * 0.8,
		width: smallestDimension * 0.8,
		outerRadius: smallestDimension * 0.3
	};
};

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
	cx,
	cy,
	midAngle,
	innerRadius,
	outerRadius,
	percent,
	index
}) => {
	const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
	const x = cx + radius * Math.cos(-midAngle * RADIAN);
	const y = cy + radius * Math.sin(-midAngle * RADIAN);

	return (
		<text
			x={x}
			y={y}
			fill="white"
			textAnchor={x > cx ? 'start' : 'end'}
			dominantBaseline="central"
		>
			{`${(percent * 100).toFixed(0)}%`}
		</text>
	);
};

const BreakdownBySeverity = (props) => {
	const { classes, height, width } = props;
	const { container, legend, legendItem } = classes;

	const [activeIndex, setActiveIndex] = React.useState(1);

	const onPieEnter = (_, index) => {
		setActiveIndex(index);
	};

	const pieDimensions = _calculatePieDimensions(height, width);

	return (
		<div className={container}>
			<PieChart width={pieDimensions.width} height={pieDimensions.height}>
				<Pie
					data={_mockData}
					cx="50%"
					cy="50%"
					startAngle={90}
					endAngle={480}
					fill={_sliceColors.base}
					dataKey="value"
					labelLine={false}
					label={renderCustomizedLabel}
				>
					{_mockData.map((entry, index) => {
						return <Cell key={`cell-${index}`} fill={entry.fillColor} />;
					})}
				</Pie>
			</PieChart>
			<div className={legend}>
				{_mockData.map((entry, index) => {
					return (
						<div key={index} className={legendItem}>
							<div
								style={{
									height: '20px',
									width: '20px',
									backgroundColor: entry.fillColor,
									margin: '5px'
								}}
							/>
							<span>{entry.name}</span>
						</div>
					);
				})}
			</div>
		</div>
	);
};

BreakdownBySeverity.propTypes = {
	tileProperties: PropTypes.object,
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(BreakdownBySeverity));
