import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import {
	groupByCardColumnDefinition,
	groupByDateColumnDefinition
} from '../../constants/columnDefinitions';
import { getLogoByBrandName } from '../../../../helpers/shared/cardBrandHelper';
import GroupByRow from './GroupByRow';

import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const styles = (theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		borderRadius: '5px',
		backgroundColor: theme.palette.common.white,
		border: `1px solid ${theme.palette.currentInventory.listGroup.border}`,
		margin: '10px 0'
	},
	headerRow: {
		display: 'flex',
		flexDirection: 'row',
		padding: '5px'
	},
	totalContainer: {
		display: 'flex',
		flex: 1,
		paddingRight: '10px',
		justifyContent: 'flex-end',
		fontSize: '2rem'
	},
	groupText: {
		marginLeft: '10px',
		fontSize: '1.8rem'
	},
	headerCell: {
		border: 'none',
		fontSize: '.8rem',
		color: theme.palette.currentInventory.listGroup.headerTextColor
	}
});

const _formatHeaderText = (groupBy, key, isLoading) => {
	if (groupBy === 'date' && key) {
		return moment(key).format('YYYY-MM-DD');
	} else if (groupBy === 'cardType' && key) {
		return <img src={getLogoByBrandName(key)} height={60} width={100} />;
	}
};

export const Group = (props) => {
	const { classes, t, group, groupBy, onGenerateDocument } = props;
	const {
		container,
		headerRow,
		headerCell,
		totalContainer,
		groupText
	} = classes;

	const [collapsed, setCollapsed] = React.useState(true);

	const columnList =
		groupBy === 'date'
			? groupByDateColumnDefinition
			: groupByCardColumnDefinition;

	return (
		<div className={container}>
			<div
				className={headerRow}
				onClick={() => {
					setCollapsed(!collapsed);
				}}
			>
				<span className={groupText}>
					{_formatHeaderText(groupBy, group.key)}
				</span>
				<div className={totalContainer}>{`$${group.total}`}</div>
			</div>
			<Collapse in={collapsed} timeout="auto" unmountOnExit>
				<TableContainer>
					<Table sx={{ minWidth: 650 }} size="small">
						<TableHead>
							<TableRow>
								{columnList.map((label, index) => {
									return (
										<TableCell className={headerCell} key={index}>
											{t(label)}
										</TableCell>
									);
								})}
							</TableRow>
						</TableHead>
						<TableBody>
							{group.data.length === 0 ? (
								<TableRow
									key={'no-data'}
									sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
								>
									<TableCell>
										{t('components.Representment.TransactionsList.noData')}
									</TableCell>
								</TableRow>
							) : (
								group.data.map((row, index) => {
									return (
										<GroupByRow
											key={index}
											row={row}
											onClickGenerate={onGenerateDocument}
											groupBy={groupBy}
										/>
									);
								})
							)}
						</TableBody>
					</Table>
				</TableContainer>
			</Collapse>
		</div>
	);
};

Group.propTypes = {
	group: PropTypes.object,
	groupBy: PropTypes.string,
	onGenerateDocument: PropTypes.func,
	selectedCards: PropTypes.array,
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(Group));
