import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Link } from 'react-router-dom';

import IconButton from '@mui/material/IconButton';
import PeopleIcon from '@mui/icons-material/People';
import Tooltip from '@mui/material/Tooltip';

const styles = (theme) => ({
	actionButton: {
		display: 'flex',
		borderRadius: '10px',
		margin: '10px',
		backgroundColor: theme.palette.navigationActionButton.background
	},
	icon: {
		color: theme.palette.secondary.main,
		padding: '5px',
		fontSize: '22px'
	}
});

const Users = (props) => {
	const { classes } = props;
	const { actionButton, icon } = classes;

	return (
		<Tooltip title="Users">
			<Link to={'/users'}>
				<div className={actionButton}>
					<IconButton className={icon}>
						<PeopleIcon />
					</IconButton>
				</div>
			</Link>
		</Tooltip>
	);
};

Users.propTypes = {
	classes: PropTypes.object
};

export default withStyles(styles)(Users);
