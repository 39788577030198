const _sortByActiveStatus = (list) => {
	const active = [];
	const innactive = [];

	list.forEach((item) => {
		if (item.active) {
			active.push(item);
		} else {
			innactive.push(item);
		}
	});

	return active.concat(innactive);
};

const _sortBySeverity = (list) => {
	const severe = [];
	const warning = [];
	const info = [];

	list.forEach((item) => {
		if (item.alertLevel === 'severe') {
			severe.push(item);
		} else if (item.alertLevel === 'warning') {
			warning.push(item);
		} else {
			info.push(item);
		}
	});

	return _sortByActiveStatus(severe)
		.concat(_sortByActiveStatus(warning))
		.concat(_sortByActiveStatus(info));
};

export default function (list, key) {
	let sortedList = [];

	if (list && list.length > 0) {
		if (key === 'activeStatus') {
			sortedList = _sortByActiveStatus(list);
		} else if (key === 'alertLevel') {
			sortedList = _sortBySeverity(list);
		}
	}

	return sortedList;
}
