export default function (queryParameters) {
	const formattedParameters = {};

	Object.keys(queryParameters).forEach((key) => {
		if (Array.isArray(queryParameters[key])) {
			formattedParameters[key] = queryParameters[key].join(',');
		} else {
			formattedParameters[key] = queryParameters[key];
		}
	});

	return formattedParameters;
}
