import React from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { sanitizeLabels } from '../../helpers/sanitizeLabels';
import clsx from 'clsx';

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

const styles = (theme) => ({
	genericInput: {
		fontSize: '.8rem',
		flex: 1,
		margin: '5px 3px',
		height: '55px',
		backgroundColor: theme.palette.common.white
	}
});

const GenericTextInput = (props) => {
	const { classes, label, onUpdate, value, overrideClass } = props;
	const { genericInput } = classes;

	return (
		<FormControl>
			<TextField
				id={`generic-text-${label}`}
				label={sanitizeLabels(label)}
				className={clsx(overrideClass || genericInput)}
				defaultValue={value}
				onChange={onUpdate}
			/>
		</FormControl>
	);
};

GenericTextInput.propTypes = {
	classes: PropTypes.object,
	label: PropTypes.string,
	onUpdate: PropTypes.func,
	value: PropTypes.string
};

export default withStyles(styles)(GenericTextInput);
