import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import NotificationSettingsActionRow from './NotificationSettingsActionRow';
import sortNotificationSettingsList from '../../../helpers/groupManagement/sortNotificationSettingsList';

import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import NotificationRulesList from './NotificationRulesList';

const styles = (theme) => ({
	container: {
		display: 'flex',
		flex: 4,
		flexDirection: 'column',
		margin: '10px'
	},
	centered: {
		display: 'flex',
		padding: '20px',
		justifyContent: 'center',
		alignItems: 'center'
	}
});

export const ManagementArea = (props) => {
	const {
		classes,
		t,
		selectedGroup,
		notificationRulesList,
		setGroupNotificationRuleBeingEdited
	} = props;
	const { container } = classes;

	const [sortBy, setSortBy] = React.useState('activeStatus');

	const sortedList = sortNotificationSettingsList(
		notificationRulesList.data,
		sortBy
	);

	return (
		<div className={container}>
			<NotificationSettingsActionRow sortBy={sortBy} setSortBy={setSortBy} />
			<NotificationRulesList
				notificationRulesList={sortedList}
				setGroupNotificationRuleBeingEdited={
					setGroupNotificationRuleBeingEdited
				}
			/>
		</div>
	);
};

ManagementArea.propTypes = {
	selectedGroup: PropTypes.object,
	notificationRulesList: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.array
	]),
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(ManagementArea));
