import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import { Switch, Case, Default } from 'react-if';
import { representmentActions } from '../../../containers/Representment';
import Overview from './representmentSections/Overview';
import ChargebackInformation from './representmentSections/ChargebackInformation';
import CustomerInformation from './representmentSections/CustomerInformation';
import TransactionDetails from './representmentSections/TransactionDetails';
import TransactionList from './representmentSections/TransactionList';
import RefundInformation from './representmentSections/RefundInformation';
import DisplayControls from './DisplayControls';
import displaySections from './constants/displaySections';
import NotFound from './NotFound';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

const styles = (theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		margin: '20px',
		justifyContent: 'center'
	},
	row: {
		display: 'flex',
		flexFlow: 'row wrap',
		justifyContent: 'center'
	},
	mainContent: {
		display: 'flex',
		flexDirection: 'column',
		width: '80%'
	},
	controlBox: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		margin: '15px',
		width: '80%'
	},
	actionControls: {
		display: 'flex',
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'flex-end'
	},
	button: {
		margin: '5px 5px 3px 5px',
		backgroundColor: theme.palette.common.white,
		cursor: 'pointer',
		maxHeight: '40px',
		'&:hover': {
			backgroundColor: theme.palette.common.white,
			borderColor: theme.palette.representments.buttons.hover,
			color: theme.palette.representments.buttons.hover
		}
	}
});

export const RepresentmentWrapper = (props) => {
	const {
		classes,
		representment,
		representmentIsLoading,
		buildType,
		representmentHasBeenLoaded,
		getRepresentment,
		representmentError
	} = props;
	const { container, mainContent, row, controlBox } = classes;

	const location = useLocation();

	const caseId = location.pathname.split('/')[2];

	const [displayedSections, setDisplayedSections] = React.useState(
		displaySections
	);

	React.useEffect(() => {
		getRepresentment({ caseId, buildType });
	}, [caseId, buildType, getRepresentment]);

	let refundTransaction;

	if (
		representment.creditTransactions &&
		representment.creditTransactions.length > 0
	) {
		refundTransaction = representment.creditTransactions.find((transaction) => {
			return (
				transaction.merchantTransactionId ===
				representment.debitTransaction.merchantTransactionId
			);
		});
	}

	const hasRefundSection =
		displayedSections.includes('refundInformation') && refundTransaction;

	return (
		<Switch>
			<Case condition={!representmentIsLoading && representmentError}>
				<NotFound />
			</Case>
			<Case
				condition={
					!representmentIsLoading && representmentHasBeenLoaded && representment
				}
			>
				<Box className={controlBox}>
					<DisplayControls
						displayedSections={displayedSections}
						setDisplayedSections={setDisplayedSections}
					/>
				</Box>
				<Box className={mainContent}>
					<div className={row}>
						{displayedSections.includes('overview') && (
							<Overview data={representment.overview} />
						)}
						{displayedSections.includes('customerInformation') && (
							<CustomerInformation data={representment.customerInformation} />
						)}
						{displayedSections.includes('chargebackInformation') && (
							<ChargebackInformation
								data={representment.chargebackInformation}
							/>
						)}
					</div>
					<div className={row}>
						{displayedSections.includes('transactionDetails') && (
							<TransactionDetails data={representment.debitTransaction} />
						)}
						{hasRefundSection && (
							<RefundInformation
								data={refundTransaction}
								saleData={representment.debitTransaction}
							/>
						)}
					</div>
					<div className={row}>
						{displayedSections.includes('transactionsList') && (
							<TransactionList
								data={representment.previouslyBilledUndisputedTransactions}
							/>
						)}
					</div>
				</Box>
			</Case>
			<Default>
				<div className={container}>
					<CircularProgress />
				</div>
			</Default>
		</Switch>
	);
};

RepresentmentWrapper.propTypes = {
	representment: PropTypes.object,
	representmentIsLoading: PropTypes.bool,
	representmentHasBeenLoaded: PropTypes.bool,
	getRepresentment: PropTypes.func,
	representmentError: PropTypes.string,
	user: PropTypes.object,
	buildType: PropTypes.string,
	classes: PropTypes.object,
	t: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		representment: state.representmentReducer.getIn(['representment', 'data']),
		representmentIsLoading: state.representmentReducer.getIn([
			'representment',
			'isLoading'
		]),
		representmentHasBeenLoaded: state.representmentReducer.getIn([
			'representment',
			'hasBeenLoaded'
		]),
		representmentError: state.representmentReducer.getIn([
			'representment',
			'error'
		]),
		buildType: state.reversalReasonBuilderReducer.get('buildType'),
		user: state.userReducer.get('user')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getRepresentment: (payload) => {
			dispatch(representmentActions.getRepresentment(payload));
		},
		updateChargebackReversalReasons: (payload) => {
			dispatch(
				representmentActions.updateRepresentmentReversalReasons(payload)
			);
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(RepresentmentWrapper));
