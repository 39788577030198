import { capitalCase } from 'change-case';

export default function (results) {
	const columns = [];

	if (results && results.length > 0) {
		const firstDataSet = results[0];

		Object.keys(firstDataSet).forEach((key) => {
			columns.push({
				name: key,
				title: capitalCase(key),
				field: key,
				isDisplayed: true
			});
		});
	}

	return columns;
}
