import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';

const styles = (theme) => ({
	container: {
		display: 'flex',
		flexFlow: 'row',
		justifyContent: 'flex-start'
	},
	titleText: {
		fontSize: '1.6rem'
	}
});

export const PageTitle = (props) => {
	const { classes, t } = props;
	const { container, titleText } = classes;

	return (
		<div className={container}>
			<span className={titleText}>
				{/* {t('components.CurrentInventory.pageTitle')} */}
				Representment Inventory
			</span>
		</div>
	);
};

PageTitle.propTypes = {
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(PageTitle));
