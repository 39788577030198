import { fromJS } from 'immutable';
import * as actionTypes from './actionTypes';

export const initialState = fromJS({
	representment: {
		data: {},
		isLoading: false,
		hasBeenLoaded: false,
		error: undefined
	}
});

export const representmentReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_REPRESENTMENT:
			return state.setIn(['representment', 'isLoading'], true);

		case actionTypes.GET_REPRESENTMENT_COMPLETED:
			return state
				.setIn(['representment', 'data'], action.payload)
				.setIn(['representment', 'isLoading'], false)
				.setIn(['representment', 'hasBeenLoaded'], true);

		case actionTypes.GET_REPRESENTMENT_FAILED:
			return state
				.setIn(['representment', 'error'], action.payload)
				.setIn(['representment', 'isLoading'], false);

		default:
			return state;
	}
};
