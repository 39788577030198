import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import { numberWithCommas } from '../../../../utilities/formatNumber';

import {
	CartesianGrid,
	BarChart,
	Bar,
	Tooltip,
	YAxis,
	XAxis,
	LabelList
} from 'recharts';

const styles = (theme) => ({});

const BarChartView = (props) => {
	const { classes, t, height, width, reportData, layout } = props;
	const {} = classes;

	const layoutType = layout ? layout : 'horizontal';

	const CustomizedLabel = (props) => {
		const { x, y, width, height, value } = props;

		return (
			<text
				x={x + width / 2}
				y={y + height / 1.75}
				fontSize="16"
				fontFamily="sans-serif"
				fill="#ffffff"
				position="inside"
			>
				{`$${numberWithCommas(value)}`}
			</text>
		);
	};

	return (
		<Fragment>
			{layoutType === 'vertical' ? (
				<BarChart width={width} height={height} data={reportData}>
					<CartesianGrid />
					<XAxis dataKey="key" />
					<YAxis />
					<Bar dataKey="value" fill={'#617289'} />
				</BarChart>
			) : (
				<BarChart
					data={reportData}
					layout="vertical"
					width={width}
					height={height}
				>
					<XAxis type="number" hide />
					<YAxis type="category" width={150} dataKey="key" />
					<Bar dataKey="value" fill={'#617289'}>
						<LabelList dataKey="value" content={<CustomizedLabel />} />
					</Bar>
				</BarChart>
			)}
		</Fragment>
	);
};

BarChartView.propTypes = {
	reportData: PropTypes.array,
	classes: PropTypes.object,
	size: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(BarChartView));
