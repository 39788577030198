import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

export const LegendItem = (props) => {
	const { t, titlePath, legendClass } = props;

	return (
		<Stack direction="row" spacing={2} alignItems="center">
			<Box className={legendClass} />
			<Typography>{t(titlePath)}</Typography>
		</Stack>
	);
};

LegendItem.propTypes = {
	titlePath: PropTypes.string,
	legendClass: PropTypes.string,
	t: PropTypes.func
};

export default withTranslation()(LegendItem);
