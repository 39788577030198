import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';

import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';

import WarningAmberIcon from '@mui/icons-material/WarningAmber';

const styles = (theme) => ({
	container: {
		display: 'flex',
		flexSrhink: 1,
		flexFlow: 'column',
		padding: '5px',
		maxWidth: '500px'
	},
	errorIcon: {
		color: theme.palette.warning.main,
		fontSize: '2rem'
	},
	errorText: {
		color: theme.palette.common.black
	}
});

export const NoData = (props) => {
	const { classes, t, data, isLoading, hasBeenLoaded } = props;
	const { container, errorIcon, errorText } = classes;

	return (
		<Container className={container}>
			{!isLoading && hasBeenLoaded && data && Object.keys(data).length === 0 && (
				<Stack
					direction="column"
					spacing={2}
					pt={2}
					pb={2}
					alignItems="center"
					justifyContent="center"
				>
					<WarningAmberIcon className={errorIcon} />
					<span className={errorText}>
						{t('components.RecoveryHistory.RecoveryHistoryManager.noData')}
					</span>
				</Stack>
			)}
		</Container>
	);
};

NoData.propTypes = {
	data: PropTypes.object,
	isLoading: PropTypes.bool,
	hasBeenLoaded: PropTypes.bool,
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(NoData));
