export const GET_TILES_FOR_LAYOUT = 'tiles/GET_TILES_FOR_LAYOUT';
export const GET_TILES_FOR_LAYOUT_COMPLETED =
	'tiles/GET_TILES_FOR_LAYOUT_COMPLETED';
export const GET_TILES_FOR_LAYOUT_FAILED = 'tiles/GET_TILES_FOR_LAYOUT_FAILED';

export const SET_TILE_BEING_MODIFIED = 'tiles/SET_TILE_BEING_MODIFIED';

export const UPDATE_LAYOUT_TILE = 'tiles/UPDATE_LAYOUT_TILE';
export const UPDATE_LAYOUT_TILE_COMPLETED =
	'tiles/UPDATE_LAYOUT_TILE_COMPLETED';
export const UPDATE_LAYOUT_TILE_FAILED = 'tiles/UPDATE_LAYOUT_TILE_FAILED';

export const GET_AVAILABLE_TILES = 'tiles/GET_AVAILABLE_TILES';
export const GET_AVAILABLE_TILES_COMPLETED =
	'tiles/GET_AVAILABLE_TILES_COMPLETED';
export const GET_AVAILABLE_TILES_FAILED = 'tiles/GET_AVAILABLE_TILES_FAILED';

export const ADD_TILES_TO_LAYOUT = 'tiles/ADD_TILES_TO_LAYOUT';
export const ADD_TILES_TO_LAYOUT_COMPLETED =
	'tiles/ADD_TILES_TO_LAYOUT_COMPLETED';
export const ADD_TILES_TO_LAYOUT_FAILED = 'tiles/ADD_TILES_TO_LAYOUT_FAILED';

export const DELETE_TILES_BY_LAYOUT = 'tiles/DELETE_TILES_BY_LAYOUT';
export const DELETE_TILES_BY_LAYOUT_COMPLETED =
	'tiles/DELETE_TILES_BY_LAYOUT_COMPLETED';
export const DELETE_TILES_BY_LAYOUT_FAILED =
	'tiles/DELETE_TILES_BY_LAYOUT_FAILED';

export const DELETE_TILE_FROM_LAYOUT = 'tiles/DELETE_TILE_FROM_LAYOUT';
export const DELETE_TILE_FROM_LAYOUT_COMPLETED =
	'tiles/DELETE_TILE_FROM_LAYOUT_COMPLETED';
export const DELETE_TILE_FROM_LAYOUT_FAILED =
	'tiles/DELETE_TILE_FROM_LAYOUT_FAILED';
