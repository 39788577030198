import axios from 'axios';
import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { refreshToken } from '../../utilities/refreshToken';
import { failureMessage } from '../ToastNotifications/actions';
import Logger from '../../utilities/logger';

import * as actions from './actions';
import * as actionTypes from './actionTypes';

export const getTemporaryRecordingDirectoryEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.GET_TEMPORARY_RECORDING_DIRECTORY),
		mergeMap(async (action) => {
			// await refreshToken();

			// const instance = axios.create();
			// const filename = action.payload;

			// const result = await instance.get(
			// `https://recordings.sempris.com/recordings/get_sales-recording_S3_2_FS.php?name=${filename}`
			// );

			// const audioRecording = result.data.split('~')[1];

			// return audioRecording.replace(
			// 'http://recordings.provell.com',
			// 'https://recordings.sempris.com'
			// );

			if (action.payload === 'CustomerSales') {
				return 'https://dev.assets.slyce360.com/sempris/CustomerSales.mp3';
			} else if (action.payload === 'CustomerService') {
				return 'https://dev.assets.slyce360.com/sempris/CustomerService.mp3';
			} else {
				return null;
			}
		}),
		switchMap((res) => [actions.getTemporaryRecordingDirectoryCompleted(res)]),
		catchError((error, source) => {
			Logger.error('Get Temporary Recording Directory Failure', error);
			return merge(
				of(
					actions.getTemporaryRecordingDirectoryFailed(error.message),
					failureMessage(error.message)
				),
				source
			);
		})
	);
