import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import deepCopy from '../../utilities/deepCopy';
import generateDateRange from '../../helpers/shared/generateDateRange';
import { currentInventoryActions } from '../../containers/CurrentInventory';
import {
	getCardList,
	getLogoByBrandName
} from '../../helpers/shared/cardBrandHelper';

import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const styles = (theme) => ({
	container: {
		display: 'flex',
		flex: 1,
		flexFlow: 'row wrap',
		justifyContent: 'center',
		margin: '10px 0'
	},
	paper: {
		display: 'flex',
		flexDirection: 'column',
		flex: '0 1 275px',
		margin: '10px 10px',
		height: '175px',
		background: `linear-gradient(to right top, ${theme.palette.currentInventory.cardOverview.cardGradientDark},
			${theme.palette.currentInventory.cardOverview.cardGradientLight}
			)`
	},
	cardContainer: {
		display: 'flex',
		flex: 1,
		borderTopLeftRadius: '5px',
		borderTopRightRadius: '5px',
		flexDirection: 'column'
	},
	deselected: {
		opacity: '.3'
	},
	visibilityToggleOff: {
		color: theme.palette.currentInventory.cardOverview.visibilityToggleActive
	},
	visibilityToggleOn: {
		color: theme.palette.currentInventory.cardOverview.visibilityToggleInactive
	},
	cardLogoContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		padding: '0px'
	},
	dataContainer: {
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		justifyContent: 'flex-end'
	},
	chargebackContainer: {
		display: 'flex',
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
		paddingLeft: '10px'
	},
	chargebackAmountContainer: {
		display: 'flex',
		flex: 1,
		justifyContent: 'flex-start',
		paddingLeft: '10px',
		fontSize: '2rem'
	},
	visibilityContainer: {
		display: 'flex',
		flex: 1,
		paddingLeft: '5px',
		paddingTop: '3px',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		cursor: 'pointer'
	}
});

const _renderChargebackData = (
	hasBeenLoaded,
	isLoading,
	data,
	error,
	card,
	selectedCards
) => {
	if (selectedCards.includes(card)) {
		if (isLoading) {
			return <Skeleton variant="text" width={150} height={40} />;
		} else if (hasBeenLoaded && !error) {
			return <span>{`${data[card].chargebacks} Chargebacks`}</span>;
		}
	}
};

const _renderChargebackAmountData = (
	hasBeenLoaded,
	isLoading,
	data,
	error,
	card,
	classes,
	selectedCards
) => {
	const { errorIcon } = classes;

	if (selectedCards.includes(card)) {
		if (isLoading) {
			return <Skeleton variant="text" width={50} height={40} />;
		} else if (hasBeenLoaded && !error) {
			return <span>{`$${data[card].value}`}</span>;
		} else {
			return <ErrorOutlineIcon className={errorIcon} />;
		}
	}
};

export const CardOverview = (props) => {
	const {
		classes,
		selectedCards,
		setSelectedCards,
		dateRange,
		isLoading,
		hasBeenLoaded,
		error,
		data,
		getCardOverviewData
	} = props;
	const {
		container,
		paper,
		cardContainer,
		deselected,
		cardLogoContainer,
		dataContainer,
		chargebackContainer,
		chargebackAmountContainer,
		visibilityToggleOff,
		visibilityToggleOn,
		visibilityContainer
	} = classes;

	React.useEffect(() => {
		getCardOverviewData({
			dateRange: generateDateRange(dateRange)
		});
	}, [dateRange, getCardOverviewData]);

	const onToggleCardVisibility = (selectedCards, card) => {
		const updatedCards = deepCopy(selectedCards);

		const index = selectedCards.indexOf(card);

		if (index > -1) {
			updatedCards.splice(index, 1);
		} else {
			updatedCards.push(card);
		}

		setSelectedCards(updatedCards);
	};

	return (
		<Container maxWidth={false} className={container}>
			{getCardList().map((card) => {
				return (
					<Paper
						key={card}
						className={clsx(paper, !selectedCards.includes(card) && deselected)}
					>
						<div className={cardContainer}>
							<div className={cardLogoContainer}>
								<div
									className={visibilityContainer}
									onClick={() => {
										onToggleCardVisibility(selectedCards, card);
									}}
								>
									{selectedCards.includes(card) ? (
										<VisibilityIcon className={visibilityToggleOff} />
									) : (
										<VisibilityOffIcon className={visibilityToggleOn} />
									)}
								</div>
								<img src={getLogoByBrandName(card)} height={60} width={100} />
							</div>
							<div className={dataContainer}>
								<div className={chargebackContainer}>
									{_renderChargebackData(
										hasBeenLoaded,
										isLoading,
										data,
										error,
										card,
										selectedCards
									)}
								</div>
								<div className={chargebackAmountContainer}>
									{_renderChargebackAmountData(
										hasBeenLoaded,
										isLoading,
										data,
										error,
										card,
										classes,
										selectedCards
									)}
								</div>
							</div>
						</div>
					</Paper>
				);
			})}
		</Container>
	);
};

CardOverview.propTypes = {
	selectedCards: PropTypes.array,
	setSelectedCards: PropTypes.func,
	dateRange: PropTypes.string,
	isLoading: PropTypes.bool,
	hasBeenLoaded: PropTypes.bool,
	error: PropTypes.string,
	data: PropTypes.object,
	getCardOverviewData: PropTypes.func,
	classes: PropTypes.object,
	t: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		isLoading: state.currentInventoryReducer.getIn([
			'cardOverview',
			'isLoading'
		]),
		hasBeenLoaded: state.currentInventoryReducer.getIn([
			'cardOverview',
			'hasBeenLoaded'
		]),
		error: state.currentInventoryReducer.getIn(['cardOverview', 'error']),
		data: state.currentInventoryReducer.getIn(['cardOverview', 'data'])
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getCardOverviewData: (payload) => {
			dispatch(currentInventoryActions.getCardOverview(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(CardOverview));
