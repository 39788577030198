import React, { Fragment, useCallback, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Modal from './ScreenshotModal';
import { systemActions } from '../../../containers/System';
import { usersActions } from '../../../containers/Users';

import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

const styles = (theme) => ({
	errorCaption: {
		marginBottom: '10px',
		color: theme.palette.error.main
	},
	input: {
		margin: '6px 0px 8px 0px'
	},
	sendTextForm: {
		display: 'flex',
		flexDirection: 'column',
		minWidth: '550px'
	}
});

const SendTextScreenshotModal = (props) => {
	const {
		classes,
		error,
		getScreenshot,
		getUsersList,
		hasLoadedListOfUsers,
		isOpen,
		isSending,
		listOfUsers,
		onClose,
		resetTextValues,
		sendText,
		t,
		textHasBeenSent,
		title
	} = props;
	const { errorCaption, input, sendTextForm } = classes;

	const [textIsValidToSend, setTextIsValidToSend] = useState(false);
	const [toValues, setToValues] = useState([]);
	const [messageValue, setMessageValue] = useState('');

	const handleClose = useCallback(() => {
		resetTextValues();
		onClose();
	}, [onClose, resetTextValues]);

	useEffect(() => {
		if (toValues.length && messageValue !== '') {
			setTextIsValidToSend(true);
		} else {
			setTextIsValidToSend(false);
		}
		if (!hasLoadedListOfUsers) {
			getUsersList();
		}
		if (!isSending && textHasBeenSent) {
			handleClose();
		}
	}, [
		getUsersList,
		hasLoadedListOfUsers,
		handleClose,
		isSending,
		listOfUsers,
		messageValue,
		textHasBeenSent,
		toValues,
		setTextIsValidToSend
	]);

	const handleSendText = async () => {
		const prepareInput = toValues.map((entry) => {
			const splitEntry = entry.split('- ')[1];
			return splitEntry || entry;
		});
		const text = {
			to: prepareInput,
			message: messageValue
		};
		const screenshotEncoded = await getScreenshot();
		text.image = screenshotEncoded;
		sendText(text);
		setToValues(toValues);
	};

	const optionsList = () => {
		if (hasLoadedListOfUsers) {
			const formattedUsers = listOfUsers
				.filter((user) => user.phone)
				.map((user) => `${user.firstName} - ${user.phone}`);
			return formattedUsers;
		} else {
			return [];
		}
	};

	const renderTags = (values, getTagProps) => {
		return values.map((option, index) => (
			<Chip
				variant="outlined"
				label={option}
				key={index}
				{...getTagProps({ index })}
			/>
		));
	};

	const _sendTextActions = () => {
		return (
			<Fragment>
				<Button autoFocus onClick={handleClose} color="primary">
					Cancel
				</Button>
				<Button
					autoFocus
					onClick={handleSendText}
					color="primary"
					disabled={!textIsValidToSend}
				>
					Send
				</Button>
			</Fragment>
		);
	};

	const _sendTextScreenshotForm = () => {
		return (
			<Container className={sendTextForm}>
				<Autocomplete
					className={input}
					multiple
					options={optionsList()}
					freeSolo
					onChange={(event, values) => setToValues(values)}
					filterSelectedOptions={true}
					renderTags={renderTags}
					renderInput={(params) => (
						<Fragment>
							{error.length > 0 && (
								<Typography variant="subtitle2" className={errorCaption}>
									Texting Is Currently Unavailable - Please Try Again Later
								</Typography>
							)}
							<TextField
								{...params}
								// helperText={t('pages.register.helpertext.phonenumber')}
								helperText="Phone Number"
								variant="outlined"
								label="To"
								placeholder="Add Phone Number"
							/>
						</Fragment>
					)}
				/>
				<TextareaAutosize
					placeholder="Message body (required)"
					rowsMin={8}
					onChange={(e) => setMessageValue(e.target.value)}
				/>
			</Container>
		);
	};

	return (
		<Fragment>
			<Modal
				isOpen={isOpen}
				handleClose={handleClose}
				title={title || 'Text A Screenshot'}
				actions={_sendTextActions()}
			>
				{_sendTextScreenshotForm()}
			</Modal>
		</Fragment>
	);
};

SendTextScreenshotModal.propTypes = {
	classes: PropTypes.object,
	error: PropTypes.string,
	getUsersList: PropTypes.func,
	getScreenshot: PropTypes.func,
	hasLoadedListOfUsers: PropTypes.bool,
	isOpen: PropTypes.bool,
	isSending: PropTypes.bool,
	listOfUsers: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	onClose: PropTypes.func,
	resetTextValues: PropTypes.func,
	sendText: PropTypes.func,
	t: PropTypes.func,
	textHasBeenSent: PropTypes.bool,
	title: PropTypes.string
};

const mapStateToProps = (state) => {
	return {
		error: state.systemReducer.get('text').get('error'),
		hasLoadedListOfUsers: state.usersReducer.get('hasBeenLoaded'),
		isSending: state.systemReducer.get('text').get('isSending'),
		listOfUsers: state.usersReducer.get('users'),
		textHasBeenSent: state.systemReducer.get('text').get('hasBeenSent')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getUsersList: () => {
			dispatch(usersActions.getUsers());
		},
		resetTextValues: () => {
			dispatch(systemActions.resetTextValues());
		},
		sendText: (payload) => {
			dispatch(systemActions.sendText(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(withTranslation()(SendTextScreenshotModal)));
