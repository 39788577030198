import * as actionTypes from './actionTypes';

export const getReportViews = (payload) => ({
	type: actionTypes.GET_REPORT_VIEWS,
	payload
});

export const getReportViewsCompleted = (payload) => ({
	type: actionTypes.GET_REPORT_VIEWS_COMPLETED,
	payload
});

export const getReportViewsFailed = (payload) => ({
	type: actionTypes.GET_REPORT_VIEWS_FAILED,
	payload
});

export const setReportTileBeingCreated = (payload) => ({
	type: actionTypes.SET_REPORT_TILE_BEING_CREATED,
	payload
});
