import * as actionTypes from './actionTypes';

export const getRepresentment = (payload) => ({
	type: actionTypes.GET_REPRESENTMENT,
	payload
});

export const getRepresentmentCompleted = (payload) => ({
	type: actionTypes.GET_REPRESENTMENT_COMPLETED,
	payload
});

export const getRepresentmentFailed = (payload) => ({
	type: actionTypes.GET_REPRESENTMENT_FAILED,
	payload
});
