import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom/cjs/react-router-dom.min';

const PrivateRoute = (props) => {
	const { children, isAuthed, ...otherProps } = props;

	return (
		<Route
			{...otherProps}
			render={({ location }) => {
				return isAuthed
					? children
					: (
						<Redirect to={{
							pathname: '/login',
							state: { from: location }
						}}
						/>
					);
			}}
		/>
	);
};

PrivateRoute.propTypes = {
	children: PropTypes.object,
	isAuthed: PropTypes.bool,
	Render: PropTypes.func
};

export default PrivateRoute;
