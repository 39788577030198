export const GET_REPRESENTMENT_REVERSAL_REASON =
	'reversalReasonBuilder/GET_REPRESENTMENT_REVERSAL_REASON';
export const GET_REPRESENTMENT_REVERSAL_REASON_COMPLETED =
	'reversalReasonBuilder/GET_REPRESENTMENT_REVERSAL_REASON_COMPLETED';
export const GET_REPRESENTMENT_REVERSAL_REASON_FAILED =
	'reversalReasonBuilder/GET_REPRESENTMENT_REVERSAL_REASON_FAILED';

export const SET_CHARGEBACK_REVERSAL_REASON =
	'reversalReasonBuilder/SET_CHARGEBACK_REVERSAL_REASON';

export const INSERT_REPRESENTMENT_REVERSAL_REASON =
	'reversalReasonBuilder/INSERT_REPRESENTMENT_REVERSAL_REASON';
export const INSERT_REPRESENTMENT_REVERSAL_REASON_COMPLETED =
	'reversalReasonBuilder/INSERT_REPRESENTMENT_REVERSAL_REASON_COMPLETED';
export const INSERT_REPRESENTMENT_REVERSAL_REASON_FAILED =
	'reversalReasonBuilder/INSERT_REPRESENTMENT_REVERSAL_REASON_FAILED';

export const UPDATE_REPRESENTMENT_REVERSAL_REASON =
	'reversalReasonBuilder/UPDATE_REPRESENTMENT_REVERSAL_REASON';
export const UPDATE_REPRESENTMENT_REVERSAL_REASON_COMPLETED =
	'reversalReasonBuilder/UPDATE_REPRESENTMENT_REVERSAL_REASON_COMPLETED';
export const UPDATE_REPRESENTMENT_REVERSAL_REASON_FAILED =
	'reversalReasonBuilder/UPDATE_REPRESENTMENT_REVERSAL_REASON_FAILED';

export const SET_BUILDER_TYPE = 'reversalReasonBuilder/SET_BUILDER_TYPE';
