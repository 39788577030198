import React, { Fragment, useRef, useState } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { sentenceCase } from 'change-case';
import { withSize } from 'react-sizeme';
import { reporterActions } from '../../../containers/Reporter';
import formatQueryParameters from '../../../helpers/reporter/formatQueryParameters';
// TODO: deleted file, make new updateUserPreference file
// import {
// 	addReportToDashboard,
// 	removeReportFromDashboard,
// 	updateReportOnDashboard
// } from '../../utilities/updateUserPreference';

import StandardAppBar from './StandardAppBar';
import CompressedAppBar from './CompressedAppBar';

import ActiveOnDashboard from '../actions/ActiveOnDashboard';

import AppBar from '@mui/material/AppBar';

const styles = (theme) => ({
	container: {
		display: 'flex',
		backgroundColor: theme.palette.common.white,
		borderRadius: '3px',
		border: `1px solid ${theme.palette.secondary.alt}`
	}
});

const WidgetController = (props) => {
	const { classes, size, reportViewId, options, isLoadingReportData } = props;
	const { container } = classes;

	const useCompressed = size.width < 870;
	const toolbarRef = useRef();

	const onExecute = (filters) => {
		const { executeReport } = props;

		executeReport({
			id: options.data.id,
			queryParameters: formatQueryParameters(filters),
			uuid: reportViewId
		});
	};

	return (
		<AppBar
			position="static"
			className={container}
			elevation={0}
			ref={toolbarRef}
		>
			{useCompressed ? (
				<CompressedAppBar
					data={options.data}
					isLoading={options.isLoading}
					hasBeenLoaded={options.hasBeenLoaded}
					error={options.error}
					onSubmit={onExecute}
					isLoadingReportData={isLoadingReportData}
				/>
			) : (
				<StandardAppBar
					data={options.data}
					isLoading={options.isLoading}
					hasBeenLoaded={options.hasBeenLoaded}
					error={options.error}
					onSubmit={onExecute}
					isLoadingReportData={isLoadingReportData}
				/>
			)}
		</AppBar>
	);
};

WidgetController.propTypes = {
	reportViewId: PropTypes.string,
	options: PropTypes.object,
	isLoadingReportData: PropTypes.bool,
	executeReport: PropTypes.func,
	size: PropTypes.object,
	classes: PropTypes.object
};

const mapStateToProps = (state) => {
	return {
		optionsList: state.reporterReducer.get('reportOptions'),
		reportDataList: state.reporterReducer.get('reportData')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getReportOptions: (payload) => {
			dispatch(reporterActions.getReportOptions(payload));
		},
		executeReport: (payload) => {
			dispatch(reporterActions.executeReport(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(withSize()(WidgetController)));

// DASHBOARD ITEMS
/*
	const [showOnDashboard, setShowOnDashboard] = useState(
		preferences.dashboardActive || false
	);

		const handleRemoveFromDeskTopDialog = (value) => {
		if (value) {
			// removeReportFromDashboard(preferences);
			setShowOnDashboard(false);
		}
		setPromptShowOnDesktopRemoval(false);
	};

	const handleShowOnDashboard = (e) => {
		const isChecked = e.target.checked || false;

		if (!isChecked) {
			setPromptShowOnDesktopRemoval(true);
		} else {
			// addReportToDashboard(report, preferences, filters, displayLabel, view);
			setShowOnDashboard(isChecked);
		}
	};

	const _renderActiveOnDashboardAction = () => (
		<ActiveOnDashboard
			isDisabled={hasNoData()}
			handleShowOnDashboard={handleShowOnDashboard}
			showOnDashboard={showOnDashboard}
		/>
	);

				<RemoveFromDesktopDialog
				isOpen={promptShowOnDesktopRemoval}
				onConfirm={handleRemoveFromDeskTopDialog}
			/>

	{!preferences.dashboardActive && _renderActiveOnDashboardAction()}
	{preferences.dashboardActive && _renderActiveOnDashboardAction()}
*/

/*
	const [displayLabel, setDisplayLabel] = useState(
		preferences && preferences.label ? preferences.label : report.id
	);

		const _handleDisplayLabelChange = (e) => {
		setDisplayLabel(e.target.value);
	};
*/

/*
			<Toolbar className={toolbar} variant="dense">
				<Typography variant="subtitle2" noWrap>
					{getDisplayLabel()}
				</Typography>
				<div className={actionGroup}>
					<DisplayList
						isDisabled={hasNoData()}
						view={view}
						onViewSelect={(newView) => {
							handleUpdateDashboardWidget('displayType', newView);
							onViewSelect(newView);
						}}
					/>
					<SubmitFilters onSubmit={handleFilterSubmit} />
					<ActionsList>
						<Fragment>
							{preferences.Screenshotter}
							<Tooltip title={handleTooltipTitle()}>
								<span>
									<IconButton
										aria-label="close"
										className={icon}
										disabled={!report.options}
										onClick={handleShowFilterSelectDialog}
									>
										<FilterListIcon />
									</IconButton>
								</span>
							</Tooltip>
							<Tooltip title="Download">
								<span>
									<DownloadToCsv
										isDisabled={hasNoData()}
										label={displayLabel}
										data={data}
									/>
								</span>
							</Tooltip>
							<InformationPopover content="Here is some example help-text" />
						</Fragment>
					</ActionsList>
				</div>
			</Toolbar>

			{report && report.options && (
				<FilterSelectDialog
					isOpen={showFilterSelect}
					onClose={handleShowFilterSelectDialog}
					onUpdate={onFilterUpdate}
					preferences={preferences}
					reportOptions={report.options}
					filters={filters}
				/>
			)}
			*/
