import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { getFormFields } from '../../../../utilities/formStructureParser';

import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

const styles = (theme) => ({
	form: {
		marginLeft: '0px',
		marginRight: '0px',
		marginBottom: '20px'
	},
	formFieldContainer: {
		margin: '0px',
		padding: '0px'
	},
	label: {
		lineHeight: '2',
		textAlign: 'left',
		display: 'block',
		marginBottom: '3px',
		marginTop: '5px',
		color: theme.palette.secondary.main,
		fontSize: '14px',
		fontWeight: '400'
	},
	inputField: {
		width: '100%',
		marginBottom: '3px',
		fontSize: '14px',
		minWidth: '300px',
		'&:focus': {
			outline: 'none !important'
		}
	},
	inputButtonContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginTop: '10px'
	},
	inputButton: {
		borderRadius: '4px',
		width: '100px',
		border: 'none',
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.secondary.alt,
		padding: '5px',
		fontSize: '16px',
		fontWeight: '300',
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
			cursor: 'pointer'
		}
	}
});

const TextInputForm = (props) => {
	const {
		classes,
		formStructure,
		defaultValues,
		disableEdit,
		onSubmit,
		t
	} = props;
	const {
		form,
		formFieldContainer,
		label,
		inputField,
		inputButtonContainer,
		inputButton
	} = classes;
	const inputFields = getFormFields(formStructure, defaultValues);
	const { register, handleSubmit } = useForm(inputFields);
	const [formEdited, setFormEdited] = useState(false);

	return (
		<form className={form} onSubmit={handleSubmit(onSubmit)}>
			{formStructure.map((inputItem) => {
				return (
					<div className={formFieldContainer} key={inputItem.fieldName}>
						<label className={label} htmlFor={inputItem.fieldName}>
							{t(inputItem.translationPath)}
						</label>
						<TextField
							className={inputField}
							defaultValue={defaultValues[inputItem.fieldName]}
							{...register(inputItem.fieldName, {
								onChange: () => setFormEdited(true),
								disabled: disableEdit
							})}
						/>
					</div>
				);
			})}

			<div className={inputButtonContainer}>
				{!disableEdit && (
					<Button
						variant="contained"
						size="small"
						className={inputButton}
						type="submit"
						value="Update"
						disabled={!formEdited}
					>
						{t('generic.buttons.update')}
					</Button>
				)}
			</div>
		</form>
	);
};

TextInputForm.propTypes = {
	formStructure: PropTypes.array,
	defaultValues: PropTypes.object,
	onSubmit: PropTypes.func,
	disableEdit: PropTypes.bool,
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(TextInputForm));
