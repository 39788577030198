import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import PageTitle from '../../components/GroupManagement/PageTitle';
import ActionRow from '../../components/GroupManagement/ActionRow';
import GroupManager from '../../components/GroupManagement/GroupManager';

import Container from '@mui/material/Container';

const styles = (theme) => ({
	root: {
		display: 'flex',
		flexFlow: 'column',
		padding: '5px 40px',
		alignItems: 'center',
		alignContent: 'center'
	}
});

export const GroupManagementPage = (props) => {
	const { classes } = props;
	const { root } = classes;

	return (
		<Container className={root} maxWidth={false}>
			<PageTitle />
			<ActionRow />
			<GroupManager />
		</Container>
	);
};

GroupManagementPage.propTypes = {
	classes: PropTypes.object
};

export default withStyles(styles)(GroupManagementPage);
