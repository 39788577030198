import { fromJS } from 'immutable';
import * as actionTypes from './actionTypes';

export const initialState = fromJS({
	activeDashboardLayout: undefined
});

export const dashboardReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_ACTIVE_DASHBOARD_LAYOUT:
			return state.set('activeDashboardLayout', action.payload);

		default:
			return state;
	}
};
