import React from 'react';
import PropTypes from 'prop-types';

import { sanitizeKeys } from '../../../../../../helpers/sanitizeKeys';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

const KpiSmallView = (props) => {
	const { data, preferences } = props;

	const randPercent = (Math.floor(Math.random() * 3) + 1).toFixed(2) + '%';

	const renderCardType = () => {
		if (
			data &&
			data.filters &&
			data.filters.CARD_TYPES &&
			data.filters.CARD_TYPES.length
		) {
			return data.filters.CARD_TYPES[0];
		}

		if (
			preferences &&
			preferences.filters &&
			preferences.filters.CARD_TYPES &&
			preferences.filters.CARD_TYPES.length
		) {
			return preferences.filters.CARD_TYPES[0];
		}

		return 'KPI';
	};

	return (
		<Container maxWidth={false}>
			<Typography variant="h4" gutterBottom>
				{sanitizeKeys(renderCardType())}
			</Typography>
			<Typography variant="h2" gutterBottom>
				{randPercent}
			</Typography>
		</Container>
	);
};

KpiSmallView.propTypes = {
	data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	filters: PropTypes.object,
	preferences: PropTypes.object
};

export default KpiSmallView;
