import React from 'react';
import PropTypes from 'prop-types';
import deepCopy from '../../../../../../utilities/deepCopy';
import cloneTileConfiguration from '../../../../../../helpers/tiles/cloneTileConfiguration';

import SelectUser from './SelectUser';
import SelectLayout from './SelectLayout';

export const CloneLayout = (props) => {
	const {
		createdLayoutConfiguration,
		updateCreatedLayoutConfiguration,
		setActiveStepCompleted
	} = props;

	const [selectedUser, setSelectedUser] = React.useState();
	const [selectedLayout, setSelectedLayout] = React.useState(undefined);

	const updatedSelectedLayout = (selectedLayout) => {
		const updatedCreatedLayoutConfiguration = deepCopy(
			createdLayoutConfiguration
		);

		if (selectedLayout) {
			updatedCreatedLayoutConfiguration.tiles = cloneTileConfiguration(
				selectedLayout.tiles
			);

			updateCreatedLayoutConfiguration(updatedCreatedLayoutConfiguration);
			setActiveStepCompleted(true);
			setSelectedLayout(selectedLayout);
		} else {
			setActiveStepCompleted(false);
			setSelectedLayout(selectedLayout);
		}
	};

	return (
		<>
			{!selectedUser ? (
				<SelectUser onUserSelect={setSelectedUser} />
			) : (
				<SelectLayout
					selectedUser={selectedUser}
					onClearUser={setSelectedUser}
					selectedLayout={selectedLayout}
					setSelectedLayout={updatedSelectedLayout}
					createdLayoutConfiguration={createdLayoutConfiguration}
					updateCreatedLayoutConfiguration={updateCreatedLayoutConfiguration}
					setActiveStepCompleted={setActiveStepCompleted}
				/>
			)}
		</>
	);
};

CloneLayout.propTypes = {
	createdLayoutConfiguration: PropTypes.object,
	updateCreatedLayoutConfiguration: PropTypes.func,
	setCurrentStepCompleted: PropTypes.func,
	setActiveStepCompleted: PropTypes.func,
	getSelectedUserLayouts: PropTypes.func,
	classes: PropTypes.object,
	t: PropTypes.func
};

export default CloneLayout;
