import querier from '../../utilities/querier';

export default function (data, groupBy) {
	let noData = true;

	if (groupBy === 'cardType') {
		const sumCheck = querier(data, '$sum($.$number(total))');

		noData = sumCheck === 0;
	} else {
		noData = !data || (data && data.length === 0);
	}

	return noData;
}
