export const mockTags = [
	'Affiliate',
	'Amex',
	'Authorization',
	'Bin',
	'CS',
	'Chargeback',
	'Daily',
	'Discover',
	'Dispute',
	'Ethoca',
	'Fraud',
	'Info',
	'Issuer',
	'MID',
	'MasterCard',
	'Merchant',
	'Mitigation',
	'Monthly',
	'Price Point',
	'Product',
	'RDR',
	'Refund',
	'Representment',
	'Research',
	'Sales',
	'System',
	'Transaction',
	'Visa',
	'Weekly',
	'YTD'
];
