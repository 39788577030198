import NotificationMasterList from '../../__Demo_Data__/Notifications/notificationMasterList';

export default {
	'de7af4c2-cc6f-4fd4-ab90-f4a234a6e5b8': {
		results: [
			{
				name: '04938234',
				chargebacks: 22
			},
			{
				name: '583737292',
				chargebacks: 14
			},
			{
				name: '943598723953',
				chargebacks: 11
			},
			{
				name: '38327755',
				chargebacks: 10
			},
			{
				name: '2343234',
				chargebacks: 7
			}
		]
	},
	'e5f94118-640a-402c-a77c-e6f018814620': {
		results: [
			{
				name: '5451325',
				chargebacks: 11
			},
			{
				name: '5844488',
				chargebacks: 9
			},
			{
				name: '1250046988',
				chargebacks: 8
			},
			{
				name: '446316384',
				chargebacks: 6
			},
			{
				name: '2343234',
				chargebacks: 5
			}
		]
	},
	9: {
		results: [
			// {
			// 	settledOn: '2021-11-04',
			// 	orderId: '63100396',
			// 	customerId: '99999999',
			// 	billingFirstName: 'Bradley',
			// 	billingLastName: 'Service',
			// 	recordingStatus: 'READY',
			// 	recording: {
			// 		vendor_id: 'gtfm',
			// 		filename: 'gtfm.semp.42386.63100396.20210629120839.oh.4375204.mp3',
			// 		s3_name: 'gtfm.semp.42386.63100396.20210629120839.oh.4375204.mp3.zip',
			// 		STATUS: 'READY',
			// 		ACTION: 'S3',
			// 		source: 42386,
			// 		client_order_number: '63100396',
			// 		aws_restore_expire: '0000-00-00 00:00:00',
			// 		aws_restore_request: '0000-00-00 00:00:00'
			// 	}
			// },
			{
				settledOn: '2021-03-21T00:00:00.000Z',
				orderId: 128242528,
				billingSequenceNumber: 1,
				customerId: 1142845744,
				authAttemptNumber: 1,
				installmentNumber: 1,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'LARRY',
				billingLastName: 'PALMER',
				billingAddress: '147 CHIANTI PL',
				billingCity: 'PLEASANT HILL',
				billingStateProvince: 'CA',
				billingPostalCode: '94523',
				settledAmount: 1.19,
				paymentCardBrand: 'M',
				startedOn: '2021-03-21T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 379,
				flexibleBillingId: null,
				levelId: 1282,
				paymentCardBankIdentificationNumber: '552322',
				merchantId: '345045125886',
				paymentCardLast4: '7365',
				issuingBankLabel: 'BARCLAYS BANK',
				clientId: 1,
				status: 'CANCEL',
				productId: 100928,
				sourceId: 37340,
				categoryId: 65,
				membershipType: 'FTO',
				billingDistributionGroupId: null,
				promotionPeriodInDays: 14,
				membershipFee: 1.19,
				chargeback: 'chargeback',
				postedOn: '2021-05-25T00:00:00.000Z',
				cycle: 1,
				caseId: '711453675801',
				amount: 1.19,
				authorizationCode: '07667W',
				code: '37',
				receivedByMerchantOn: '2021-05-28T07:30:12.000Z',
				reconciledOn: '2021-05-27T00:00:00.000Z',
				debitCreditCode: 'CREDIT',
				customerServiceRecordingStatus: 'READY',
				customerServiceRecording: {
					vendor_id: 'gtfm',
					filename: 'CustomerService',
					s3_name: 'gtfm.semp.42386.63100396.20210629120839.oh.4375204.mp3.zip',
					STATUS: 'READY',
					ACTION: 'S3',
					source: 42386,
					client_order_number: '63100396',
					aws_restore_expire: '0000-00-00 00:00:00',
					aws_restore_request: '0000-00-00 00:00:00'
				},
				customerSalesRecordingStatus: 'READY',
				customerSalesRecording: {
					vendor_id: 'gtfm',
					filename: 'CustomerSales',
					s3_name: 'gtfm.semp.42386.63100396.20210629120839.oh.4375204.mp3.zip',
					STATUS: 'READY',
					ACTION: 'S3',
					source: 42386,
					client_order_number: '63100396',
					aws_restore_expire: '0000-00-00 00:00:00',
					aws_restore_request: '0000-00-00 00:00:00'
				}
				// recordingStatus: 'READY',
				// recording: {
				// 	vendor_id: 'gtfm',
				// 	filename: 'CustomerService',
				// 	s3_name: 'gtfm.semp.42386.63100396.20210629120839.oh.4375204.mp3.zip',
				// 	STATUS: 'READY',
				// 	ACTION: 'S3',
				// 	source: 42386,
				// 	client_order_number: '63100396',
				// 	aws_restore_expire: '0000-00-00 00:00:00',
				// 	aws_restore_request: '0000-00-00 00:00:00'
				// }
			},
			{
				settledOn: '2021-05-18T00:00:00.000Z',
				orderId: 128406788,
				billingSequenceNumber: 1,
				customerId: 1142939750,
				authAttemptNumber: 1,
				installmentNumber: 1,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'GILDA',
				billingLastName: 'HAGOOD',
				billingAddress: '621 DANIEL FREEMAN CIR',
				billingCity: 'INGLEWOOD',
				billingStateProvince: 'CA',
				billingPostalCode: '90301',
				settledAmount: 1.95,
				paymentCardBrand: 'V',
				startedOn: '2021-05-18T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 371,
				flexibleBillingId: null,
				levelId: 1341,
				paymentCardBankIdentificationNumber: '483316',
				merchantId: '345045125886',
				paymentCardLast4: '0427',
				issuingBankLabel: 'JPMORGAN CHASE BANK',
				clientId: 218,
				status: 'CANCEL',
				productId: 102350,
				sourceId: 42411,
				categoryId: 65,
				membershipType: 'FTO',
				billingDistributionGroupId: null,
				promotionPeriodInDays: 21,
				membershipFee: 1.95,
				chargeback: 'chargeback',
				postedOn: '2021-05-27T00:00:00.000Z',
				cycle: 1,
				caseId: '811475014301',
				amount: 1.95,
				authorizationCode: '070114',
				code: '1040',
				receivedByMerchantOn: '2021-05-31T07:30:19.000Z',
				reconciledOn: '2021-05-30T00:00:00.000Z',
				debitCreditCode: 'DEBIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-01-28T00:00:00.000Z',
				orderId: 127837645,
				billingSequenceNumber: 3,
				customerId: 1142564590,
				authAttemptNumber: 1,
				installmentNumber: 3,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'GUADALUPE',
				billingLastName: 'EREBIA',
				billingAddress: '939 DAVIS',
				billingCity: 'ROSHARON',
				billingStateProvince: 'TX',
				billingPostalCode: '77583',
				settledAmount: 29.95,
				paymentCardBrand: 'M',
				startedOn: '2020-11-29T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 198,
				flexibleBillingId: null,
				levelId: 1236,
				paymentCardBankIdentificationNumber: '512107',
				merchantId: '345045125886',
				paymentCardLast4: '5730',
				issuingBankLabel: 'CITIBANK',
				clientId: 144,
				status: 'CANCEL',
				productId: 102064,
				sourceId: 40481,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 29.95,
				chargeback: 'chargeback',
				postedOn: '2021-05-29T00:00:00.000Z',
				cycle: 1,
				caseId: '711493062101',
				amount: 29.95,
				authorizationCode: '02875B',
				code: '37',
				receivedByMerchantOn: '2021-06-02T07:30:30.000Z',
				reconciledOn: '2021-06-01T00:00:00.000Z',
				debitCreditCode: 'CREDIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-05-26T00:00:00.000Z',
				orderId: 128424288,
				billingSequenceNumber: 1,
				customerId: 1142920262,
				authAttemptNumber: 1,
				installmentNumber: 1,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'JEFF',
				billingLastName: 'LONG',
				billingAddress: '2679 CHANT CT',
				billingCity: 'LEXINGTON',
				billingStateProvince: 'KY',
				billingPostalCode: '40509',
				settledAmount: 27.95,
				paymentCardBrand: 'V',
				startedOn: '2021-05-26T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 371,
				flexibleBillingId: null,
				levelId: 1341,
				paymentCardBankIdentificationNumber: '434769',
				merchantId: '345045125886',
				paymentCardLast4: '3969',
				issuingBankLabel: 'JPMORGAN CHASE BANK',
				clientId: 218,
				status: 'CANCEL',
				productId: 102349,
				sourceId: 42411,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 27.95,
				chargeback: 'chargeback',
				postedOn: '2021-06-03T00:00:00.000Z',
				cycle: 1,
				caseId: '811544016701',
				amount: 27.95,
				authorizationCode: '023210',
				code: '1310',
				receivedByMerchantOn: '2021-06-06T14:40:29.000Z',
				reconciledOn: '2021-06-05T00:00:00.000Z',
				debitCreditCode: 'DEBIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-02-20T00:00:00.000Z',
				orderId: 128124556,
				billingSequenceNumber: 1,
				customerId: 1142728735,
				authAttemptNumber: 1,
				installmentNumber: 1,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'THAD',
				billingLastName: 'ROGERS',
				billingAddress: '1408 CARROLLTON AVE',
				billingCity: 'KILLEEN',
				billingStateProvince: 'TX',
				billingPostalCode: '76541',
				settledAmount: 29.95,
				paymentCardBrand: 'M',
				startedOn: '2021-02-20T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 198,
				flexibleBillingId: null,
				levelId: 1236,
				paymentCardBankIdentificationNumber: '517805',
				merchantId: '345045125886',
				paymentCardLast4: '5912',
				issuingBankLabel: 'CAPITAL ONE',
				clientId: 144,
				status: 'CANCEL',
				productId: 102064,
				sourceId: 40481,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 29.95,
				chargeback: 'chargeback',
				postedOn: '2021-06-05T00:00:00.000Z',
				cycle: 1,
				caseId: '711563162301',
				amount: 29.95,
				authorizationCode: '02327P',
				code: '37',
				receivedByMerchantOn: '2021-06-07T07:30:36.000Z',
				reconciledOn: '2021-06-06T00:00:00.000Z',
				debitCreditCode: 'CREDIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-05-24T00:00:00.000Z',
				orderId: 128327480,
				billingSequenceNumber: 3,
				customerId: 1142838525,
				authAttemptNumber: 1,
				installmentNumber: 2,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'GEORGE',
				billingLastName: 'FREYDER',
				billingAddress: '20331 NE 14TH AVE',
				billingCity: 'MIAMI',
				billingStateProvince: 'FL',
				billingPostalCode: '33179',
				settledAmount: 27.95,
				paymentCardBrand: 'M',
				startedOn: '2021-04-17T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 371,
				flexibleBillingId: null,
				levelId: 1341,
				paymentCardBankIdentificationNumber: '525475',
				merchantId: '345045125886',
				paymentCardLast4: '7466',
				issuingBankLabel: 'CITIBANK',
				clientId: 218,
				status: 'CANCEL',
				productId: 101955,
				sourceId: 37220,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 27.95,
				chargeback: 'chargeback',
				postedOn: '2021-06-07T00:00:00.000Z',
				cycle: 1,
				caseId: '711583322001',
				amount: 27.95,
				authorizationCode: '01484Z',
				code: '60',
				receivedByMerchantOn: '2021-06-10T07:30:40.000Z',
				reconciledOn: '2021-06-09T00:00:00.000Z',
				debitCreditCode: 'CREDIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-02-19T00:00:00.000Z',
				orderId: 128018060,
				billingSequenceNumber: 2,
				customerId: 1142662043,
				authAttemptNumber: 1,
				installmentNumber: 2,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'KURT',
				billingLastName: 'SPIESSERT',
				billingAddress: '2500 ASHEN LIGHT DR',
				billingCity: 'HENDERSON',
				billingStateProvince: 'NV',
				billingPostalCode: '89044',
				settledAmount: 29.95,
				paymentCardBrand: 'M',
				startedOn: '2021-01-20T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 198,
				flexibleBillingId: null,
				levelId: 1236,
				paymentCardBankIdentificationNumber: '542418',
				merchantId: '345045125886',
				paymentCardLast4: '7758',
				issuingBankLabel: 'CITIBANK',
				clientId: 144,
				status: 'CANCEL',
				productId: 102064,
				sourceId: 40481,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 29.95,
				chargeback: 'chargeback',
				postedOn: '2021-06-11T00:00:00.000Z',
				cycle: 1,
				caseId: '711623549201',
				amount: 29.95,
				authorizationCode: '21134P',
				code: '37',
				receivedByMerchantOn: '2021-06-13T08:20:18.000Z',
				reconciledOn: '2021-06-12T00:00:00.000Z',
				debitCreditCode: 'CREDIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-06-06T00:00:00.000Z',
				orderId: 128452304,
				billingSequenceNumber: 1,
				customerId: 1142913197,
				authAttemptNumber: 1,
				installmentNumber: 1,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'GLENN',
				billingLastName: 'RILEY',
				billingAddress: '3685 VISTA CAMPANA N UNIT 21',
				billingCity: 'OCEANSIDE',
				billingStateProvince: 'CA',
				billingPostalCode: '92057',
				settledAmount: 27.95,
				paymentCardBrand: 'V',
				startedOn: '2021-06-06T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 371,
				flexibleBillingId: null,
				levelId: 1341,
				paymentCardBankIdentificationNumber: '483316',
				merchantId: '345045125886',
				paymentCardLast4: '6480',
				issuingBankLabel: 'JPMORGAN CHASE BANK',
				clientId: 218,
				status: 'CANCEL',
				productId: 101955,
				sourceId: 37220,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 27.95,
				chargeback: 'chargeback',
				postedOn: '2021-06-12T00:00:00.000Z',
				cycle: 1,
				caseId: '811635006901',
				amount: 27.95,
				authorizationCode: '013310',
				code: '1040',
				receivedByMerchantOn: '2021-06-15T07:46:18.000Z',
				reconciledOn: '2021-06-14T00:00:00.000Z',
				debitCreditCode: 'DEBIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-05-06T00:00:00.000Z',
				orderId: 128358211,
				billingSequenceNumber: 1,
				customerId: 1142913197,
				authAttemptNumber: 2,
				installmentNumber: 1,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'GLENN',
				billingLastName: 'RILEY',
				billingAddress: '3685 VISTA CAMPANA N UNIT 21',
				billingCity: 'OCEANSIDE',
				billingStateProvince: 'CA',
				billingPostalCode: '92057',
				settledAmount: 1.95,
				paymentCardBrand: 'V',
				startedOn: '2021-04-29T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 371,
				flexibleBillingId: null,
				levelId: 1341,
				paymentCardBankIdentificationNumber: '483316',
				merchantId: '345045125886',
				paymentCardLast4: '6480',
				issuingBankLabel: 'JPMORGAN CHASE BANK',
				clientId: 218,
				status: 'CANCEL',
				productId: 101958,
				sourceId: 37220,
				categoryId: 65,
				membershipType: 'FTO',
				billingDistributionGroupId: null,
				promotionPeriodInDays: 30,
				membershipFee: 1.95,
				chargeback: 'chargeback',
				postedOn: '2021-06-12T00:00:00.000Z',
				cycle: 1,
				caseId: '811635006801',
				amount: 1.95,
				authorizationCode: '053310',
				code: '1040',
				receivedByMerchantOn: '2021-06-15T07:46:18.000Z',
				reconciledOn: '2021-06-14T00:00:00.000Z',
				debitCreditCode: 'DEBIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-06-08T00:00:00.000Z',
				orderId: 128457930,
				billingSequenceNumber: 1,
				customerId: 1142967708,
				authAttemptNumber: 1,
				installmentNumber: 1,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'RONALD',
				billingLastName: 'GREINER',
				billingAddress: '210 PEBBLEBROOK ST',
				billingCity: 'ARLINGTON',
				billingStateProvince: 'TX',
				billingPostalCode: '76014',
				settledAmount: 1.95,
				paymentCardBrand: 'V',
				startedOn: '2021-06-08T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 303,
				flexibleBillingId: null,
				levelId: 1032,
				paymentCardBankIdentificationNumber: '441105',
				merchantId: '345045125886',
				paymentCardLast4: '9561',
				issuingBankLabel: 'JPMORGAN CHASE BANK',
				clientId: 190,
				status: 'CANCEL',
				productId: 102156,
				sourceId: 42220,
				categoryId: 65,
				membershipType: 'FTO',
				billingDistributionGroupId: null,
				promotionPeriodInDays: 21,
				membershipFee: 1.95,
				chargeback: 'chargeback',
				postedOn: '2021-06-14T00:00:00.000Z',
				cycle: 1,
				caseId: '811653537501',
				amount: 1.95,
				authorizationCode: '003410',
				code: '1040',
				receivedByMerchantOn: '2021-06-16T07:30:41.000Z',
				reconciledOn: '2021-06-15T00:00:00.000Z',
				debitCreditCode: 'DEBIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-02-20T00:00:00.000Z',
				orderId: 127914471,
				billingSequenceNumber: 3,
				customerId: 1142602929,
				authAttemptNumber: 1,
				installmentNumber: 3,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'ROSS',
				billingLastName: 'ROCCO',
				billingAddress: '4929 144TH ST',
				billingCity: 'MIDLOTHIAN',
				billingStateProvince: 'IL',
				billingPostalCode: '60445',
				settledAmount: 29.95,
				paymentCardBrand: 'M',
				startedOn: '2020-12-22T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 198,
				flexibleBillingId: null,
				levelId: 1236,
				paymentCardBankIdentificationNumber: '546616',
				merchantId: '345045125886',
				paymentCardLast4: '1213',
				issuingBankLabel: 'CITIBANK',
				clientId: 144,
				status: 'CANCEL',
				productId: 102064,
				sourceId: 40481,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 29.95,
				chargeback: 'chargeback',
				postedOn: '2021-06-05T00:00:00.000Z',
				cycle: 1,
				caseId: '711563568101',
				amount: 29.95,
				authorizationCode: '18279S',
				code: '37',
				receivedByMerchantOn: '2021-06-17T07:30:38.000Z',
				reconciledOn: '2021-06-16T00:00:00.000Z',
				debitCreditCode: 'CREDIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-05-21T00:00:00.000Z',
				orderId: 127914471,
				billingSequenceNumber: 6,
				customerId: 1142602929,
				authAttemptNumber: 1,
				installmentNumber: 6,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'ROSS',
				billingLastName: 'ROCCO',
				billingAddress: '4929 144TH ST',
				billingCity: 'MIDLOTHIAN',
				billingStateProvince: 'IL',
				billingPostalCode: '60445',
				settledAmount: 29.95,
				paymentCardBrand: 'M',
				startedOn: '2020-12-22T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 198,
				flexibleBillingId: null,
				levelId: 1236,
				paymentCardBankIdentificationNumber: '546616',
				merchantId: '345045125886',
				paymentCardLast4: '1213',
				issuingBankLabel: 'CITIBANK',
				clientId: 144,
				status: 'CANCEL',
				productId: 102064,
				sourceId: 40481,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 29.95,
				chargeback: 'chargeback',
				postedOn: '2021-06-05T00:00:00.000Z',
				cycle: 1,
				caseId: '711563568401',
				amount: 29.95,
				authorizationCode: '57790S',
				code: '37',
				receivedByMerchantOn: '2021-06-17T07:30:38.000Z',
				reconciledOn: '2021-06-16T00:00:00.000Z',
				debitCreditCode: 'CREDIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-03-22T00:00:00.000Z',
				orderId: 127914471,
				billingSequenceNumber: 4,
				customerId: 1142602929,
				authAttemptNumber: 1,
				installmentNumber: 4,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'ROSS',
				billingLastName: 'ROCCO',
				billingAddress: '4929 144TH ST',
				billingCity: 'MIDLOTHIAN',
				billingStateProvince: 'IL',
				billingPostalCode: '60445',
				settledAmount: 29.95,
				paymentCardBrand: 'M',
				startedOn: '2020-12-22T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 198,
				flexibleBillingId: null,
				levelId: 1236,
				paymentCardBankIdentificationNumber: '546616',
				merchantId: '345045125886',
				paymentCardLast4: '1213',
				issuingBankLabel: 'CITIBANK',
				clientId: 144,
				status: 'CANCEL',
				productId: 102064,
				sourceId: 40481,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 29.95,
				chargeback: 'chargeback',
				postedOn: '2021-06-05T00:00:00.000Z',
				cycle: 1,
				caseId: '711563568201',
				amount: 29.95,
				authorizationCode: '75924S',
				code: '37',
				receivedByMerchantOn: '2021-06-17T07:30:38.000Z',
				reconciledOn: '2021-06-16T00:00:00.000Z',
				debitCreditCode: 'CREDIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-04-21T00:00:00.000Z',
				orderId: 127914471,
				billingSequenceNumber: 5,
				customerId: 1142602929,
				authAttemptNumber: 1,
				installmentNumber: 5,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'ROSS',
				billingLastName: 'ROCCO',
				billingAddress: '4929 144TH ST',
				billingCity: 'MIDLOTHIAN',
				billingStateProvince: 'IL',
				billingPostalCode: '60445',
				settledAmount: 29.95,
				paymentCardBrand: 'M',
				startedOn: '2020-12-22T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 198,
				flexibleBillingId: null,
				levelId: 1236,
				paymentCardBankIdentificationNumber: '546616',
				merchantId: '345045125886',
				paymentCardLast4: '1213',
				issuingBankLabel: 'CITIBANK',
				clientId: 144,
				status: 'CANCEL',
				productId: 102064,
				sourceId: 40481,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 29.95,
				chargeback: 'chargeback',
				postedOn: '2021-06-05T00:00:00.000Z',
				cycle: 1,
				caseId: '711563568301',
				amount: 29.95,
				authorizationCode: '07413S',
				code: '37',
				receivedByMerchantOn: '2021-06-17T07:30:38.000Z',
				reconciledOn: '2021-06-16T00:00:00.000Z',
				debitCreditCode: 'CREDIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-06-14T00:00:00.000Z',
				orderId: 128473650,
				billingSequenceNumber: 1,
				customerId: 1142934906,
				authAttemptNumber: 1,
				installmentNumber: 1,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'TIWANNA',
				billingLastName: 'JOHNSON',
				billingAddress: '10439 S STATE ST',
				billingCity: 'CHICAGO',
				billingStateProvince: 'IL',
				billingPostalCode: '60628',
				settledAmount: 27.95,
				paymentCardBrand: 'V',
				startedOn: '2021-06-14T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 371,
				flexibleBillingId: null,
				levelId: 1341,
				paymentCardBankIdentificationNumber: '442755',
				merchantId: '345045125886',
				paymentCardLast4: '4539',
				issuingBankLabel: 'JPMORGAN CHASE BANK',
				clientId: 218,
				status: 'CANCEL',
				productId: 101955,
				sourceId: 37220,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 27.95,
				chargeback: 'chargeback',
				postedOn: '2021-06-21T00:00:00.000Z',
				cycle: 1,
				caseId: '811723643401',
				amount: 27.95,
				authorizationCode: '075912',
				code: '1040',
				receivedByMerchantOn: '2021-06-23T07:30:30.000Z',
				reconciledOn: '2021-06-22T00:00:00.000Z',
				debitCreditCode: 'DEBIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-04-20T00:00:00.000Z',
				orderId: 128018060,
				billingSequenceNumber: 4,
				customerId: 1142662043,
				authAttemptNumber: 1,
				installmentNumber: 4,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'KURT',
				billingLastName: 'SPIESSERT',
				billingAddress: '2500 ASHEN LIGHT DR',
				billingCity: 'HENDERSON',
				billingStateProvince: 'NV',
				billingPostalCode: '89044',
				settledAmount: 29.95,
				paymentCardBrand: 'M',
				startedOn: '2021-01-20T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 198,
				flexibleBillingId: null,
				levelId: 1236,
				paymentCardBankIdentificationNumber: '542418',
				merchantId: '345045125886',
				paymentCardLast4: '7758',
				issuingBankLabel: 'CITIBANK',
				clientId: 144,
				status: 'CANCEL',
				productId: 102064,
				sourceId: 40481,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 29.95,
				chargeback: 'chargeback',
				postedOn: '2021-06-21T00:00:00.000Z',
				cycle: 1,
				caseId: '711723446801',
				amount: 29.95,
				authorizationCode: '58409P',
				code: '37',
				receivedByMerchantOn: '2021-06-23T07:30:30.000Z',
				reconciledOn: '2021-06-22T00:00:00.000Z',
				debitCreditCode: 'CREDIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-03-21T00:00:00.000Z',
				orderId: 128018060,
				billingSequenceNumber: 3,
				customerId: 1142662043,
				authAttemptNumber: 1,
				installmentNumber: 3,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'KURT',
				billingLastName: 'SPIESSERT',
				billingAddress: '2500 ASHEN LIGHT DR',
				billingCity: 'HENDERSON',
				billingStateProvince: 'NV',
				billingPostalCode: '89044',
				settledAmount: 29.95,
				paymentCardBrand: 'M',
				startedOn: '2021-01-20T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 198,
				flexibleBillingId: null,
				levelId: 1236,
				paymentCardBankIdentificationNumber: '542418',
				merchantId: '345045125886',
				paymentCardLast4: '7758',
				issuingBankLabel: 'CITIBANK',
				clientId: 144,
				status: 'CANCEL',
				productId: 102064,
				sourceId: 40481,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 29.95,
				chargeback: 'chargeback',
				postedOn: '2021-06-21T00:00:00.000Z',
				cycle: 1,
				caseId: '711723446701',
				amount: 29.95,
				authorizationCode: '96423P',
				code: '37',
				receivedByMerchantOn: '2021-06-23T07:30:30.000Z',
				reconciledOn: '2021-06-22T00:00:00.000Z',
				debitCreditCode: 'CREDIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-05-20T00:00:00.000Z',
				orderId: 128018060,
				billingSequenceNumber: 5,
				customerId: 1142662043,
				authAttemptNumber: 1,
				installmentNumber: 5,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'KURT',
				billingLastName: 'SPIESSERT',
				billingAddress: '2500 ASHEN LIGHT DR',
				billingCity: 'HENDERSON',
				billingStateProvince: 'NV',
				billingPostalCode: '89044',
				settledAmount: 29.95,
				paymentCardBrand: 'M',
				startedOn: '2021-01-20T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 198,
				flexibleBillingId: null,
				levelId: 1236,
				paymentCardBankIdentificationNumber: '542418',
				merchantId: '345045125886',
				paymentCardLast4: '7758',
				issuingBankLabel: 'CITIBANK',
				clientId: 144,
				status: 'CANCEL',
				productId: 102064,
				sourceId: 40481,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 29.95,
				chargeback: 'chargeback',
				postedOn: '2021-06-21T00:00:00.000Z',
				cycle: 1,
				caseId: '711723446901',
				amount: 29.95,
				authorizationCode: '12757P',
				code: '37',
				receivedByMerchantOn: '2021-06-23T07:30:30.000Z',
				reconciledOn: '2021-06-22T00:00:00.000Z',
				debitCreditCode: 'CREDIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-06-08T00:00:00.000Z',
				orderId: 128457973,
				billingSequenceNumber: 1,
				customerId: 1142967736,
				authAttemptNumber: 1,
				installmentNumber: 1,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'UD',
				billingLastName: 'SHEARIN',
				billingAddress: 'PO BOX 177',
				billingCity: 'CALL',
				billingStateProvince: 'TX',
				billingPostalCode: '75933',
				settledAmount: 1.95,
				paymentCardBrand: 'V',
				startedOn: '2021-06-08T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 303,
				flexibleBillingId: null,
				levelId: 1032,
				paymentCardBankIdentificationNumber: '461046',
				merchantId: '345045125886',
				paymentCardLast4: '3416',
				issuingBankLabel: 'JPMORGAN CHASE BANK',
				clientId: 190,
				status: 'CANCEL',
				productId: 102156,
				sourceId: 42220,
				categoryId: 65,
				membershipType: 'FTO',
				billingDistributionGroupId: null,
				promotionPeriodInDays: 21,
				membershipFee: 1.95,
				chargeback: 'chargeback',
				postedOn: '2021-06-24T00:00:00.000Z',
				cycle: 1,
				caseId: '811754698001',
				amount: 1.95,
				authorizationCode: '003410',
				code: '1040',
				receivedByMerchantOn: '2021-06-26T07:30:35.000Z',
				reconciledOn: '2021-06-25T00:00:00.000Z',
				debitCreditCode: 'DEBIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-06-15T00:00:00.000Z',
				orderId: 128477654,
				billingSequenceNumber: 1,
				customerId: 1142978849,
				authAttemptNumber: 1,
				installmentNumber: 1,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'RAMON',
				billingLastName: 'RAMIREZ',
				billingAddress: '9409 S CALLE BELLA VIS',
				billingCity: 'GUADALUPE',
				billingStateProvince: 'AZ',
				billingPostalCode: '85283',
				settledAmount: 1.95,
				paymentCardBrand: 'V',
				startedOn: '2021-06-15T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 371,
				flexibleBillingId: null,
				levelId: 1341,
				paymentCardBankIdentificationNumber: '475055',
				merchantId: '345045125886',
				paymentCardLast4: '3769',
				issuingBankLabel: 'JPMORGAN CHASE BANK',
				clientId: 218,
				status: 'CANCEL',
				productId: 101958,
				sourceId: 37220,
				categoryId: 65,
				membershipType: 'FTO',
				billingDistributionGroupId: null,
				promotionPeriodInDays: 30,
				membershipFee: 1.95,
				chargeback: 'chargeback',
				postedOn: '2021-06-24T00:00:00.000Z',
				cycle: 1,
				caseId: '811755060001',
				amount: 1.95,
				authorizationCode: '070114',
				code: '1040',
				receivedByMerchantOn: '2021-06-26T07:30:35.000Z',
				reconciledOn: '2021-06-25T00:00:00.000Z',
				debitCreditCode: 'DEBIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-06-20T00:00:00.000Z',
				orderId: 128490744,
				billingSequenceNumber: 1,
				customerId: 1142942883,
				authAttemptNumber: 1,
				installmentNumber: 1,
				addressVerificationServiceResponse: 'I4',
				billingFirstName: 'SHARON',
				billingLastName: 'KAHLE',
				billingAddress: 'H1801 S FLAGLER DR APT 101',
				billingCity: 'WEST PALM BEACH',
				billingStateProvince: 'FL',
				billingPostalCode: '33401',
				settledAmount: 27.95,
				paymentCardBrand: 'V',
				startedOn: '2021-06-20T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 371,
				flexibleBillingId: null,
				levelId: 1341,
				paymentCardBankIdentificationNumber: '483312',
				merchantId: '345045125886',
				paymentCardLast4: '6934',
				issuingBankLabel: 'JPMORGAN CHASE BANK',
				clientId: 218,
				status: 'CANCEL',
				productId: 101955,
				sourceId: 37220,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 27.95,
				chargeback: 'chargeback',
				postedOn: '2021-06-26T00:00:00.000Z',
				cycle: 1,
				caseId: '811774926301',
				amount: 27.95,
				authorizationCode: '093310',
				code: '1040',
				receivedByMerchantOn: '2021-06-29T07:30:33.000Z',
				reconciledOn: '2021-06-28T00:00:00.000Z',
				debitCreditCode: 'DEBIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-05-29T00:00:00.000Z',
				orderId: 128433282,
				billingSequenceNumber: 1,
				customerId: 1142911514,
				authAttemptNumber: 1,
				installmentNumber: 1,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'ROBERT',
				billingLastName: 'SWINIGAN',
				billingAddress: '125 WILLCOM BLVD APT311B',
				billingCity: 'INDIANAPOLIS',
				billingStateProvince: 'IN',
				billingPostalCode: '46214',
				settledAmount: 27.95,
				paymentCardBrand: 'V',
				startedOn: '2021-05-29T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 371,
				flexibleBillingId: null,
				levelId: 1341,
				paymentCardBankIdentificationNumber: '441104',
				merchantId: '345045125886',
				paymentCardLast4: '4361',
				issuingBankLabel: 'JPMORGAN CHASE BANK',
				clientId: 218,
				status: 'CANCEL',
				productId: 101955,
				sourceId: 37220,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 27.95,
				chargeback: 'chargeback',
				postedOn: '2021-06-26T00:00:00.000Z',
				cycle: 1,
				caseId: '811774142701',
				amount: 27.95,
				authorizationCode: '093410',
				code: '1040',
				receivedByMerchantOn: '2021-06-29T07:30:33.000Z',
				reconciledOn: '2021-06-28T00:00:00.000Z',
				debitCreditCode: 'DEBIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-03-08T00:00:00.000Z',
				orderId: 128076358,
				billingSequenceNumber: 2,
				customerId: 1142681154,
				authAttemptNumber: 1,
				installmentNumber: 2,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'CHARLES',
				billingLastName: 'LATROBE',
				billingAddress: '16800 OLD YORK RD',
				billingCity: 'MONKTON',
				billingStateProvince: 'MD',
				billingPostalCode: '21111',
				settledAmount: 27.95,
				paymentCardBrand: 'M',
				startedOn: '2021-02-06T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 371,
				flexibleBillingId: null,
				levelId: 1341,
				paymentCardBankIdentificationNumber: '512107',
				merchantId: '345045125886',
				paymentCardLast4: '5630',
				issuingBankLabel: 'CITIBANK',
				clientId: 218,
				status: 'CANCEL',
				productId: 101955,
				sourceId: 37220,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 27.95,
				chargeback: 'chargeback',
				postedOn: '2021-06-29T00:00:00.000Z',
				cycle: 1,
				caseId: '711803052301',
				amount: 27.95,
				authorizationCode: '20893B',
				code: '37',
				receivedByMerchantOn: '2021-07-02T07:50:12.000Z',
				reconciledOn: '2021-07-01T00:00:00.000Z',
				debitCreditCode: 'CREDIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-03-13T00:00:00.000Z',
				orderId: 127983156,
				billingSequenceNumber: 3,
				customerId: 1142643592,
				authAttemptNumber: 1,
				installmentNumber: 3,
				addressVerificationServiceResponse: 'I4',
				billingFirstName: 'RAYMOND',
				billingLastName: 'DAVIS',
				billingAddress: '2140 BROOKS DR APT 205',
				billingCity: 'DISTRICT HEIGHTS',
				billingStateProvince: 'MD',
				billingPostalCode: '20747',
				settledAmount: 29.95,
				paymentCardBrand: 'M',
				startedOn: '2021-01-10T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 198,
				flexibleBillingId: null,
				levelId: 1236,
				paymentCardBankIdentificationNumber: '517805',
				merchantId: '345045125886',
				paymentCardLast4: '4715',
				issuingBankLabel: 'CAPITAL ONE',
				clientId: 144,
				status: 'CANCEL',
				productId: 102064,
				sourceId: 40481,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 29.95,
				chargeback: 'chargeback',
				postedOn: '2021-06-30T00:00:00.000Z',
				cycle: 1,
				caseId: '711813175001',
				amount: 29.95,
				authorizationCode: '02077Z',
				code: '37',
				receivedByMerchantOn: '2021-07-02T07:50:12.000Z',
				reconciledOn: '2021-07-01T00:00:00.000Z',
				debitCreditCode: 'CREDIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-07-03T00:00:00.000Z',
				orderId: 128524429,
				billingSequenceNumber: 1,
				customerId: 1142972622,
				authAttemptNumber: 1,
				installmentNumber: 1,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'VINCENT J',
				billingLastName: 'DELFINO',
				billingAddress: '225 DORSET F',
				billingCity: 'BOCA RATON',
				billingStateProvince: 'FL',
				billingPostalCode: '33434',
				settledAmount: 29.95,
				paymentCardBrand: 'V',
				startedOn: '2021-07-03T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 303,
				flexibleBillingId: null,
				levelId: 1032,
				paymentCardBankIdentificationNumber: '441104',
				merchantId: '345045125886',
				paymentCardLast4: '1951',
				issuingBankLabel: 'JPMORGAN CHASE BANK',
				clientId: 190,
				status: 'CANCEL',
				productId: 101520,
				sourceId: 42220,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 29.95,
				chargeback: 'chargeback',
				postedOn: '2021-07-10T00:00:00.000Z',
				cycle: 1,
				caseId: '811914240101',
				amount: 29.95,
				authorizationCode: '033310',
				code: '1370',
				receivedByMerchantOn: '2021-07-13T07:30:34.000Z',
				reconciledOn: '2021-07-12T00:00:00.000Z',
				debitCreditCode: 'DEBIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-06-08T00:00:00.000Z',
				orderId: 128459004,
				billingSequenceNumber: 1,
				customerId: 1142968611,
				authAttemptNumber: 1,
				installmentNumber: 1,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'MARCO',
				billingLastName: 'PORTER',
				billingAddress: '2223 W GEORGIA AVE',
				billingCity: 'PHOENIX',
				billingStateProvince: 'AZ',
				billingPostalCode: '85015',
				settledAmount: 1.95,
				paymentCardBrand: 'V',
				startedOn: '2021-06-08T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 371,
				flexibleBillingId: null,
				levelId: 1341,
				paymentCardBankIdentificationNumber: '475055',
				merchantId: '345045125886',
				paymentCardLast4: '5703',
				issuingBankLabel: 'JPMORGAN CHASE BANK',
				clientId: 218,
				status: 'CANCEL',
				productId: 101958,
				sourceId: 37220,
				categoryId: 65,
				membershipType: 'FTO',
				billingDistributionGroupId: null,
				promotionPeriodInDays: 30,
				membershipFee: 1.95,
				chargeback: 'chargeback',
				postedOn: '2021-07-15T00:00:00.000Z',
				cycle: 1,
				caseId: '811964998601',
				amount: 1.95,
				authorizationCode: '040114',
				code: '1040',
				receivedByMerchantOn: '2021-07-19T07:30:21.000Z',
				reconciledOn: '2021-07-18T00:00:00.000Z',
				debitCreditCode: 'DEBIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-06-18T00:00:00.000Z',
				orderId: 128446969,
				billingSequenceNumber: 1,
				customerId: 1142932144,
				authAttemptNumber: 3,
				installmentNumber: 1,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'TYRON',
				billingLastName: 'BAUM',
				billingAddress: '409 WYANDANCH AVE',
				billingCity: 'WEST BABYLON',
				billingStateProvince: 'NY',
				billingPostalCode: '11704',
				settledAmount: 29.95,
				paymentCardBrand: 'V',
				startedOn: '2021-06-04T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 303,
				flexibleBillingId: null,
				levelId: 1032,
				paymentCardBankIdentificationNumber: '420767',
				merchantId: '345045125886',
				paymentCardLast4: '8304',
				issuingBankLabel: 'JPMORGAN CHASE BANK',
				clientId: 190,
				status: 'CANCEL',
				productId: 101520,
				sourceId: 42220,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 29.95,
				chargeback: 'chargeback',
				postedOn: '2021-07-23T00:00:00.000Z',
				cycle: 1,
				caseId: '812043593301',
				amount: 29.95,
				authorizationCode: '003310',
				code: '1040',
				receivedByMerchantOn: '2021-07-27T07:30:18.000Z',
				reconciledOn: '2021-07-26T00:00:00.000Z',
				debitCreditCode: 'DEBIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-07-14T00:00:00.000Z',
				orderId: 128469039,
				billingSequenceNumber: 2,
				customerId: 1142943306,
				authAttemptNumber: 1,
				installmentNumber: 2,
				addressVerificationServiceResponse: 'I8',
				billingFirstName: 'DUANE',
				billingLastName: 'ANTHONY',
				billingAddress: '5230 LENAPE LN APT H',
				billingCity: 'EVANSVILLE',
				billingStateProvince: 'IN',
				billingPostalCode: '47715',
				settledAmount: 29.95,
				paymentCardBrand: 'V',
				startedOn: '2021-06-12T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 303,
				flexibleBillingId: null,
				levelId: 1032,
				paymentCardBankIdentificationNumber: '483313',
				merchantId: '345045125886',
				paymentCardLast4: '6347',
				issuingBankLabel: 'JPMORGAN CHASE BANK',
				clientId: 190,
				status: 'CANCEL',
				productId: 101520,
				sourceId: 42220,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 29.95,
				chargeback: 'chargeback',
				postedOn: '2021-07-24T00:00:00.000Z',
				cycle: 1,
				caseId: '812055067701',
				amount: 29.95,
				authorizationCode: '033310',
				code: '1040',
				receivedByMerchantOn: '2021-07-27T07:30:18.000Z',
				reconciledOn: '2021-07-26T00:00:00.000Z',
				debitCreditCode: 'DEBIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-07-02T00:00:00.000Z',
				orderId: 128440823,
				billingSequenceNumber: 2,
				customerId: 1142929079,
				authAttemptNumber: 1,
				installmentNumber: 2,
				addressVerificationServiceResponse: 'I4',
				billingFirstName: 'IRENE',
				billingLastName: 'ALEXANDER',
				billingAddress: '1408 BUS LOOP 70 W APT N201',
				billingCity: 'COLUMBIA',
				billingStateProvince: 'MO',
				billingPostalCode: '65202',
				settledAmount: 29.95,
				paymentCardBrand: 'V',
				startedOn: '2021-06-02T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 198,
				flexibleBillingId: null,
				levelId: 1236,
				paymentCardBankIdentificationNumber: '414709',
				merchantId: '345045125886',
				paymentCardLast4: '6973',
				issuingBankLabel: 'CAPITAL ONE',
				clientId: 144,
				status: 'CANCEL',
				productId: 102064,
				sourceId: 40481,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 29.95,
				chargeback: 'chargeback',
				postedOn: '2021-07-24T00:00:00.000Z',
				cycle: 1,
				caseId: '812053426201',
				amount: 29.95,
				authorizationCode: '02326C',
				code: '1040',
				receivedByMerchantOn: '2021-07-27T07:30:18.000Z',
				reconciledOn: '2021-07-26T00:00:00.000Z',
				debitCreditCode: 'CREDIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-06-08T00:00:00.000Z',
				orderId: 128459165,
				billingSequenceNumber: 1,
				customerId: 1142968719,
				authAttemptNumber: 1,
				installmentNumber: 1,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'ALBERT',
				billingLastName: 'FERRER',
				billingAddress: 'PO BOX 21141',
				billingCity: 'LOS ANGELES',
				billingStateProvince: 'CA',
				billingPostalCode: '90021',
				settledAmount: 1.95,
				paymentCardBrand: 'V',
				startedOn: '2021-06-08T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 371,
				flexibleBillingId: null,
				levelId: 1341,
				paymentCardBankIdentificationNumber: '441103',
				merchantId: '345045125886',
				paymentCardLast4: '1145',
				issuingBankLabel: 'JPMORGAN CHASE BANK',
				clientId: 218,
				status: 'CANCEL',
				productId: 101958,
				sourceId: 37220,
				categoryId: 65,
				membershipType: 'FTO',
				billingDistributionGroupId: null,
				promotionPeriodInDays: 30,
				membershipFee: 1.95,
				chargeback: 'chargeback',
				postedOn: '2021-07-24T00:00:00.000Z',
				cycle: 1,
				caseId: '812054181901',
				amount: 1.95,
				authorizationCode: '040114',
				code: '1040',
				receivedByMerchantOn: '2021-07-27T07:30:18.000Z',
				reconciledOn: '2021-07-26T00:00:00.000Z',
				debitCreditCode: 'DEBIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-06-02T00:00:00.000Z',
				orderId: 128440823,
				billingSequenceNumber: 1,
				customerId: 1142929079,
				authAttemptNumber: 1,
				installmentNumber: 1,
				addressVerificationServiceResponse: 'I4',
				billingFirstName: 'IRENE',
				billingLastName: 'ALEXANDER',
				billingAddress: '1408 BUS LOOP 70 W APT N201',
				billingCity: 'COLUMBIA',
				billingStateProvince: 'MO',
				billingPostalCode: '65202',
				settledAmount: 29.95,
				paymentCardBrand: 'V',
				startedOn: '2021-06-02T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 198,
				flexibleBillingId: null,
				levelId: 1236,
				paymentCardBankIdentificationNumber: '414709',
				merchantId: '345045125886',
				paymentCardLast4: '6973',
				issuingBankLabel: 'CAPITAL ONE',
				clientId: 144,
				status: 'CANCEL',
				productId: 102064,
				sourceId: 40481,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 29.95,
				chargeback: 'chargeback',
				postedOn: '2021-07-24T00:00:00.000Z',
				cycle: 1,
				caseId: '812053426101',
				amount: 29.95,
				authorizationCode: '02835C',
				code: '1040',
				receivedByMerchantOn: '2021-07-27T07:30:18.000Z',
				reconciledOn: '2021-07-26T00:00:00.000Z',
				debitCreditCode: 'CREDIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-07-09T00:00:00.000Z',
				orderId: 128540179,
				billingSequenceNumber: 1,
				customerId: 1142968719,
				authAttemptNumber: 1,
				installmentNumber: 1,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'ALBERT',
				billingLastName: 'FERRER',
				billingAddress: 'PO BOX 21141',
				billingCity: 'LOS ANGELES',
				billingStateProvince: 'CA',
				billingPostalCode: '90021',
				settledAmount: 27.95,
				paymentCardBrand: 'V',
				startedOn: '2021-07-09T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 371,
				flexibleBillingId: null,
				levelId: 1341,
				paymentCardBankIdentificationNumber: '441103',
				merchantId: '345045125886',
				paymentCardLast4: '1145',
				issuingBankLabel: 'JPMORGAN CHASE BANK',
				clientId: 218,
				status: 'CANCEL',
				productId: 101955,
				sourceId: 37220,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 27.95,
				chargeback: 'chargeback',
				postedOn: '2021-07-24T00:00:00.000Z',
				cycle: 1,
				caseId: '812054182101',
				amount: 27.95,
				authorizationCode: '053410',
				code: '1040',
				receivedByMerchantOn: '2021-07-27T07:30:18.000Z',
				reconciledOn: '2021-07-26T00:00:00.000Z',
				debitCreditCode: 'DEBIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-07-13T00:00:00.000Z',
				orderId: 128446969,
				billingSequenceNumber: 2,
				customerId: 1142932144,
				authAttemptNumber: 1,
				installmentNumber: 2,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'TYRON',
				billingLastName: 'BAUM',
				billingAddress: '409 WYANDANCH AVE',
				billingCity: 'WEST BABYLON',
				billingStateProvince: 'NY',
				billingPostalCode: '11704',
				settledAmount: 29.95,
				paymentCardBrand: 'V',
				startedOn: '2021-06-04T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 303,
				flexibleBillingId: null,
				levelId: 1032,
				paymentCardBankIdentificationNumber: '420767',
				merchantId: '345045125886',
				paymentCardLast4: '8304',
				issuingBankLabel: 'JPMORGAN CHASE BANK',
				clientId: 190,
				status: 'CANCEL',
				productId: 101520,
				sourceId: 42220,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 29.95,
				chargeback: 'chargeback',
				postedOn: '2021-07-23T00:00:00.000Z',
				cycle: 1,
				caseId: '812043593401',
				amount: 29.95,
				authorizationCode: '043410',
				code: '1040',
				receivedByMerchantOn: '2021-07-27T07:30:18.000Z',
				reconciledOn: '2021-07-26T00:00:00.000Z',
				debitCreditCode: 'DEBIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-06-14T00:00:00.000Z',
				orderId: 128436701,
				billingSequenceNumber: 1,
				customerId: 1142926582,
				authAttemptNumber: 3,
				installmentNumber: 1,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'JOHN',
				billingLastName: 'LARSON',
				billingAddress: '1205 N 22ND ST',
				billingCity: 'BISMARCK',
				billingStateProvince: 'ND',
				billingPostalCode: '58501',
				settledAmount: 29.95,
				paymentCardBrand: 'V',
				startedOn: '2021-05-31T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 198,
				flexibleBillingId: null,
				levelId: 1236,
				paymentCardBankIdentificationNumber: '400344',
				merchantId: '345045125886',
				paymentCardLast4: '7217',
				issuingBankLabel: 'CAPITAL ONE',
				clientId: 144,
				status: 'CANCEL',
				productId: 102064,
				sourceId: 40481,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 29.95,
				chargeback: 'chargeback',
				postedOn: '2021-07-31T00:00:00.000Z',
				cycle: 1,
				caseId: '812123036901',
				amount: 29.95,
				authorizationCode: '07224C',
				code: '1040',
				receivedByMerchantOn: '2021-08-03T07:40:36.000Z',
				reconciledOn: '2021-08-02T00:00:00.000Z',
				debitCreditCode: 'CREDIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-07-09T00:00:00.000Z',
				orderId: 128436701,
				billingSequenceNumber: 2,
				customerId: 1142926582,
				authAttemptNumber: 1,
				installmentNumber: 2,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'JOHN',
				billingLastName: 'LARSON',
				billingAddress: '1205 N 22ND ST',
				billingCity: 'BISMARCK',
				billingStateProvince: 'ND',
				billingPostalCode: '58501',
				settledAmount: 29.95,
				paymentCardBrand: 'V',
				startedOn: '2021-05-31T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 198,
				flexibleBillingId: null,
				levelId: 1236,
				paymentCardBankIdentificationNumber: '400344',
				merchantId: '345045125886',
				paymentCardLast4: '7217',
				issuingBankLabel: 'CAPITAL ONE',
				clientId: 144,
				status: 'CANCEL',
				productId: 102064,
				sourceId: 40481,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 29.95,
				chargeback: 'chargeback',
				postedOn: '2021-07-31T00:00:00.000Z',
				cycle: 1,
				caseId: '812123037001',
				amount: 29.95,
				authorizationCode: '03366C',
				code: '1040',
				receivedByMerchantOn: '2021-08-03T07:40:36.000Z',
				reconciledOn: '2021-08-02T00:00:00.000Z',
				debitCreditCode: 'CREDIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-05-13T00:00:00.000Z',
				orderId: 128393856,
				billingSequenceNumber: 1,
				customerId: 1142932144,
				authAttemptNumber: 1,
				installmentNumber: 1,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'TYRON',
				billingLastName: 'BAUM',
				billingAddress: '409 WIAN DANCH AVE',
				billingCity: 'WEST BABYLON',
				billingStateProvince: 'NY',
				billingPostalCode: '11704',
				settledAmount: 1.95,
				paymentCardBrand: 'V',
				startedOn: '2021-05-13T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 303,
				flexibleBillingId: null,
				levelId: 1032,
				paymentCardBankIdentificationNumber: '420767',
				merchantId: '345045125886',
				paymentCardLast4: '8304',
				issuingBankLabel: 'JPMORGAN CHASE BANK',
				clientId: 190,
				status: 'CANCEL',
				productId: 102156,
				sourceId: 42220,
				categoryId: 65,
				membershipType: 'FTO',
				billingDistributionGroupId: null,
				promotionPeriodInDays: 21,
				membershipFee: 1.95,
				chargeback: 'chargeback',
				postedOn: '2021-07-23T00:00:00.000Z',
				cycle: 1,
				caseId: '812043593201',
				amount: 1.95,
				authorizationCode: '063410',
				code: '1040',
				receivedByMerchantOn: '2021-08-03T09:01:22.000Z',
				reconciledOn: '2021-07-31T00:00:00.000Z',
				debitCreditCode: 'DEBIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-07-30T00:00:00.000Z',
				orderId: 128597404,
				billingSequenceNumber: 1,
				customerId: 1143013192,
				authAttemptNumber: 1,
				installmentNumber: 1,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'JUAN',
				billingLastName: 'MATEODIEGO',
				billingAddress: '1103 E 2ND ST',
				billingCity: 'CASA GRANDE',
				billingStateProvince: 'AZ',
				billingPostalCode: '85122',
				settledAmount: 29.95,
				paymentCardBrand: 'V',
				startedOn: '2021-07-30T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 303,
				flexibleBillingId: null,
				levelId: 1032,
				paymentCardBankIdentificationNumber: '475055',
				merchantId: '345045125886',
				paymentCardLast4: '4204',
				issuingBankLabel: 'JPMORGAN CHASE BANK',
				clientId: 190,
				status: 'CANCEL',
				productId: 101520,
				sourceId: 42220,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 29.95,
				chargeback: 'chargeback',
				postedOn: '2021-08-08T00:00:00.000Z',
				cycle: 1,
				caseId: '812203924701',
				amount: 29.95,
				authorizationCode: '073310',
				code: '1040',
				receivedByMerchantOn: '2021-08-11T07:30:24.000Z',
				reconciledOn: '2021-08-10T00:00:00.000Z',
				debitCreditCode: 'DEBIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-07-30T00:00:00.000Z',
				orderId: 128597214,
				billingSequenceNumber: 1,
				customerId: 1142999666,
				authAttemptNumber: 1,
				installmentNumber: 1,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'CORINA',
				billingLastName: 'MUSSER',
				billingAddress: '2694 BECKER ST',
				billingCity: 'NEW BRAUNFELS',
				billingStateProvince: 'TX',
				billingPostalCode: '78130',
				settledAmount: 27.95,
				paymentCardBrand: 'V',
				startedOn: '2021-07-30T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 371,
				flexibleBillingId: null,
				levelId: 1341,
				paymentCardBankIdentificationNumber: '461046',
				merchantId: '345045125886',
				paymentCardLast4: '1049',
				issuingBankLabel: 'JPMORGAN CHASE BANK',
				clientId: 218,
				status: 'CANCEL',
				productId: 101955,
				sourceId: 37220,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 27.95,
				chargeback: 'chargeback',
				postedOn: '2021-08-08T00:00:00.000Z',
				cycle: 1,
				caseId: '812203771301',
				amount: 27.95,
				authorizationCode: '073310',
				code: '1040',
				receivedByMerchantOn: '2021-08-11T07:30:24.000Z',
				reconciledOn: '2021-08-10T00:00:00.000Z',
				debitCreditCode: 'DEBIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-05-30T00:00:00.000Z',
				orderId: 128434966,
				billingSequenceNumber: 1,
				customerId: 1142925718,
				authAttemptNumber: 1,
				installmentNumber: 1,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'JANECE',
				billingLastName: 'KINDIG',
				billingAddress: '2215 ROAD 34',
				billingCity: 'PASCO',
				billingStateProvince: 'WA',
				billingPostalCode: '99301',
				settledAmount: 29.95,
				paymentCardBrand: 'V',
				startedOn: '2021-05-30T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 198,
				flexibleBillingId: null,
				levelId: 1236,
				paymentCardBankIdentificationNumber: '414709',
				merchantId: '345045125886',
				paymentCardLast4: '7607',
				issuingBankLabel: 'CAPITAL ONE',
				clientId: 144,
				status: 'CANCEL',
				productId: 102064,
				sourceId: 40481,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 29.95,
				chargeback: 'chargeback',
				postedOn: '2021-08-10T00:00:00.000Z',
				cycle: 1,
				caseId: '812223344401',
				amount: 29.95,
				authorizationCode: '00646C',
				code: '1040',
				receivedByMerchantOn: '2021-08-12T07:30:34.000Z',
				reconciledOn: '2021-08-11T00:00:00.000Z',
				debitCreditCode: 'CREDIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-06-29T00:00:00.000Z',
				orderId: 128434966,
				billingSequenceNumber: 2,
				customerId: 1142925718,
				authAttemptNumber: 1,
				installmentNumber: 2,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'JANECE',
				billingLastName: 'KINDIG',
				billingAddress: '2215 ROAD 34',
				billingCity: 'PASCO',
				billingStateProvince: 'WA',
				billingPostalCode: '99301',
				settledAmount: 29.95,
				paymentCardBrand: 'V',
				startedOn: '2021-05-30T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 198,
				flexibleBillingId: null,
				levelId: 1236,
				paymentCardBankIdentificationNumber: '414709',
				merchantId: '345045125886',
				paymentCardLast4: '7607',
				issuingBankLabel: 'CAPITAL ONE',
				clientId: 144,
				status: 'CANCEL',
				productId: 102064,
				sourceId: 40481,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 29.95,
				chargeback: 'chargeback',
				postedOn: '2021-08-10T00:00:00.000Z',
				cycle: 1,
				caseId: '812223344501',
				amount: 29.95,
				authorizationCode: '02310C',
				code: '1040',
				receivedByMerchantOn: '2021-08-12T07:30:34.000Z',
				reconciledOn: '2021-08-11T00:00:00.000Z',
				debitCreditCode: 'CREDIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-07-28T00:00:00.000Z',
				orderId: 128592403,
				billingSequenceNumber: 1,
				customerId: 1143044778,
				authAttemptNumber: 1,
				installmentNumber: 1,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'ARTHUR',
				billingLastName: 'WILLIAMS',
				billingAddress: '1801 COLETA PL',
				billingCity: 'GARLAND',
				billingStateProvince: 'TX',
				billingPostalCode: '75040',
				settledAmount: 1.95,
				paymentCardBrand: 'V',
				startedOn: '2021-07-28T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 303,
				flexibleBillingId: null,
				levelId: 1032,
				paymentCardBankIdentificationNumber: '461046',
				merchantId: '345045125886',
				paymentCardLast4: '4260',
				issuingBankLabel: 'JPMORGAN CHASE BANK',
				clientId: 190,
				status: 'CANCEL',
				productId: 102156,
				sourceId: 42220,
				categoryId: 65,
				membershipType: 'FTO',
				billingDistributionGroupId: null,
				promotionPeriodInDays: 21,
				membershipFee: 1.95,
				chargeback: 'chargeback',
				postedOn: '2021-08-09T00:00:00.000Z',
				cycle: 1,
				caseId: '812213770901',
				amount: 1.95,
				authorizationCode: '043410',
				code: '1040',
				receivedByMerchantOn: '2021-08-13T07:30:33.000Z',
				reconciledOn: '2021-08-12T00:00:00.000Z',
				debitCreditCode: 'DEBIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-08-05T00:00:00.000Z',
				orderId: 128576149,
				billingSequenceNumber: 1,
				customerId: 1142988013,
				authAttemptNumber: 3,
				installmentNumber: 1,
				addressVerificationServiceResponse: 'I8',
				billingFirstName: 'MARGARETTE',
				billingLastName: 'WALLS',
				billingAddress: '315 LUDDINGTON AVE',
				billingCity: 'CLIFTON',
				billingStateProvince: 'NJ',
				billingPostalCode: '07011',
				settledAmount: 27.95,
				paymentCardBrand: 'V',
				startedOn: '2021-07-22T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 371,
				flexibleBillingId: null,
				levelId: 1341,
				paymentCardBankIdentificationNumber: '434769',
				merchantId: '345045125886',
				paymentCardLast4: '6181',
				issuingBankLabel: 'JPMORGAN CHASE BANK',
				clientId: 218,
				status: 'CANCEL',
				productId: 101955,
				sourceId: 37220,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 27.95,
				chargeback: 'chargeback',
				postedOn: '2021-08-12T00:00:00.000Z',
				cycle: 1,
				caseId: '812244087901',
				amount: 27.95,
				authorizationCode: '043410',
				code: '1040',
				receivedByMerchantOn: '2021-08-16T07:30:36.000Z',
				reconciledOn: '2021-08-15T00:00:00.000Z',
				debitCreditCode: 'DEBIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-08-09T00:00:00.000Z',
				orderId: 128625270,
				billingSequenceNumber: 1,
				customerId: 1143028747,
				authAttemptNumber: 1,
				installmentNumber: 1,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'DWAYNE',
				billingLastName: 'MCCLELLND',
				billingAddress: '2104 LINCOLN AVE APT 2R',
				billingCity: 'WHITING',
				billingStateProvince: 'IN',
				billingPostalCode: '46394',
				settledAmount: 29.95,
				paymentCardBrand: 'V',
				startedOn: '2021-08-09T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 303,
				flexibleBillingId: null,
				levelId: 1032,
				paymentCardBankIdentificationNumber: '406032',
				merchantId: '345045125886',
				paymentCardLast4: '2837',
				issuingBankLabel: 'JPMORGAN CHASE BANK',
				clientId: 190,
				status: 'CANCEL',
				productId: 101520,
				sourceId: 42220,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 29.95,
				chargeback: 'chargeback',
				postedOn: '2021-08-14T00:00:00.000Z',
				cycle: 1,
				caseId: '812263105201',
				amount: 29.95,
				authorizationCode: '083310',
				code: '1040',
				receivedByMerchantOn: '2021-08-17T07:30:26.000Z',
				reconciledOn: '2021-08-16T00:00:00.000Z',
				debitCreditCode: 'DEBIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-08-13T00:00:00.000Z',
				orderId: 128597213,
				billingSequenceNumber: 1,
				customerId: 1142999667,
				authAttemptNumber: 3,
				installmentNumber: 1,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'BARBARA',
				billingLastName: 'NAPOLEON',
				billingAddress: '225 S MAIN ST',
				billingCity: 'VIDOR',
				billingStateProvince: 'TX',
				billingPostalCode: '77662',
				settledAmount: 27.95,
				paymentCardBrand: 'V',
				startedOn: '2021-07-30T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 371,
				flexibleBillingId: null,
				levelId: 1341,
				paymentCardBankIdentificationNumber: '406042',
				merchantId: '345045125886',
				paymentCardLast4: '1399',
				issuingBankLabel: 'JPMORGAN CHASE BANK',
				clientId: 218,
				status: 'CANCEL',
				productId: 101955,
				sourceId: 37220,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 27.95,
				chargeback: 'chargeback',
				postedOn: '2021-08-20T00:00:00.000Z',
				cycle: 1,
				caseId: '812323126401',
				amount: 27.95,
				authorizationCode: '033310',
				code: '1040',
				receivedByMerchantOn: '2021-08-22T11:10:11.000Z',
				reconciledOn: '2021-08-21T00:00:00.000Z',
				debitCreditCode: 'DEBIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-08-13T00:00:00.000Z',
				orderId: 128637865,
				billingSequenceNumber: 1,
				customerId: 1143021806,
				authAttemptNumber: 1,
				installmentNumber: 1,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'JEANETTE',
				billingLastName: 'LEMMON',
				billingAddress: '1549 TREETOP TRL APT C',
				billingCity: 'AKRON',
				billingStateProvince: 'OH',
				billingPostalCode: '44313',
				settledAmount: 27.95,
				paymentCardBrand: 'V',
				startedOn: '2021-08-13T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 371,
				flexibleBillingId: null,
				levelId: 1341,
				paymentCardBankIdentificationNumber: '434769',
				merchantId: '345045125886',
				paymentCardLast4: '4420',
				issuingBankLabel: 'JPMORGAN CHASE BANK',
				clientId: 218,
				status: 'CANCEL',
				productId: 101955,
				sourceId: 37220,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 27.95,
				chargeback: 'chargeback',
				postedOn: '2021-08-20T00:00:00.000Z',
				cycle: 1,
				caseId: '812324139301',
				amount: 27.95,
				authorizationCode: '033310',
				code: '1040',
				receivedByMerchantOn: '2021-08-24T07:30:40.000Z',
				reconciledOn: '2021-08-23T00:00:00.000Z',
				debitCreditCode: 'DEBIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-06-12T00:00:00.000Z',
				orderId: 126943186,
				billingSequenceNumber: 15,
				customerId: 1142012859,
				authAttemptNumber: 1,
				installmentNumber: 15,
				addressVerificationServiceResponse: 'I8',
				billingFirstName: 'A',
				billingLastName: 'GORDON',
				billingAddress: '8989 NORTH GAINEY CENTER DR',
				billingCity: 'SCOTTSDALE',
				billingStateProvince: 'AZ',
				billingPostalCode: '85258',
				settledAmount: 19.95,
				paymentCardBrand: 'V',
				startedOn: '2020-04-18T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 198,
				flexibleBillingId: null,
				levelId: 1236,
				paymentCardBankIdentificationNumber: '414709',
				merchantId: '345045125886',
				paymentCardLast4: '4383',
				issuingBankLabel: 'CAPITAL ONE',
				clientId: 144,
				status: 'CANCEL',
				productId: 102083,
				sourceId: 40579,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 19.95,
				chargeback: 'chargeback',
				postedOn: '2021-08-26T00:00:00.000Z',
				cycle: 1,
				caseId: '812383370901',
				amount: 19.95,
				authorizationCode: '00351D',
				code: '1040',
				receivedByMerchantOn: '2021-08-29T08:25:22.000Z',
				reconciledOn: '2021-08-28T00:00:00.000Z',
				debitCreditCode: 'CREDIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-05-13T00:00:00.000Z',
				orderId: 126943186,
				billingSequenceNumber: 14,
				customerId: 1142012859,
				authAttemptNumber: 1,
				installmentNumber: 14,
				addressVerificationServiceResponse: 'I8',
				billingFirstName: 'A',
				billingLastName: 'GORDON',
				billingAddress: '8989 NORTH GAINEY CENTER DR',
				billingCity: 'SCOTTSDALE',
				billingStateProvince: 'AZ',
				billingPostalCode: '85258',
				settledAmount: 19.95,
				paymentCardBrand: 'V',
				startedOn: '2020-04-18T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 198,
				flexibleBillingId: null,
				levelId: 1236,
				paymentCardBankIdentificationNumber: '414709',
				merchantId: '345045125886',
				paymentCardLast4: '4383',
				issuingBankLabel: 'CAPITAL ONE',
				clientId: 144,
				status: 'CANCEL',
				productId: 102083,
				sourceId: 40579,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 19.95,
				chargeback: 'chargeback',
				postedOn: '2021-08-26T00:00:00.000Z',
				cycle: 1,
				caseId: '812383370801',
				amount: 19.95,
				authorizationCode: '02216D',
				code: '1040',
				receivedByMerchantOn: '2021-08-29T08:25:22.000Z',
				reconciledOn: '2021-08-28T00:00:00.000Z',
				debitCreditCode: 'CREDIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-08-11T00:00:00.000Z',
				orderId: 126943186,
				billingSequenceNumber: 17,
				customerId: 1142012859,
				authAttemptNumber: 1,
				installmentNumber: 17,
				addressVerificationServiceResponse: 'I8',
				billingFirstName: 'A',
				billingLastName: 'GORDON',
				billingAddress: '8989 NORTH GAINEY CENTER DR',
				billingCity: 'SCOTTSDALE',
				billingStateProvince: 'AZ',
				billingPostalCode: '85258',
				settledAmount: 19.95,
				paymentCardBrand: 'V',
				startedOn: '2020-04-18T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 198,
				flexibleBillingId: null,
				levelId: 1236,
				paymentCardBankIdentificationNumber: '414709',
				merchantId: '345045125886',
				paymentCardLast4: '4383',
				issuingBankLabel: 'CAPITAL ONE',
				clientId: 144,
				status: 'CANCEL',
				productId: 102083,
				sourceId: 40579,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 19.95,
				chargeback: 'chargeback',
				postedOn: '2021-08-26T00:00:00.000Z',
				cycle: 1,
				caseId: '812383371101',
				amount: 19.95,
				authorizationCode: '02163D',
				code: '1040',
				receivedByMerchantOn: '2021-08-29T08:25:22.000Z',
				reconciledOn: '2021-08-28T00:00:00.000Z',
				debitCreditCode: 'CREDIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-07-12T00:00:00.000Z',
				orderId: 126943186,
				billingSequenceNumber: 16,
				customerId: 1142012859,
				authAttemptNumber: 1,
				installmentNumber: 16,
				addressVerificationServiceResponse: 'I8',
				billingFirstName: 'A',
				billingLastName: 'GORDON',
				billingAddress: '8989 NORTH GAINEY CENTER DR',
				billingCity: 'SCOTTSDALE',
				billingStateProvince: 'AZ',
				billingPostalCode: '85258',
				settledAmount: 19.95,
				paymentCardBrand: 'V',
				startedOn: '2020-04-18T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 198,
				flexibleBillingId: null,
				levelId: 1236,
				paymentCardBankIdentificationNumber: '414709',
				merchantId: '345045125886',
				paymentCardLast4: '4383',
				issuingBankLabel: 'CAPITAL ONE',
				clientId: 144,
				status: 'CANCEL',
				productId: 102083,
				sourceId: 40579,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 19.95,
				chargeback: 'chargeback',
				postedOn: '2021-08-26T00:00:00.000Z',
				cycle: 1,
				caseId: '812383371001',
				amount: 19.95,
				authorizationCode: '09252D',
				code: '1040',
				receivedByMerchantOn: '2021-08-29T08:25:22.000Z',
				reconciledOn: '2021-08-28T00:00:00.000Z',
				debitCreditCode: 'CREDIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-08-19T00:00:00.000Z',
				orderId: 128490045,
				billingSequenceNumber: 3,
				customerId: 1142954252,
				authAttemptNumber: 1,
				installmentNumber: 3,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'NIISACKEY',
				billingLastName: 'SOSI',
				billingAddress: '1002 CRYSTAL CARBON WAY',
				billingCity: 'VALRICO',
				billingStateProvince: 'FL',
				billingPostalCode: '33594',
				settledAmount: 29.95,
				paymentCardBrand: 'V',
				startedOn: '2021-06-20T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 303,
				flexibleBillingId: null,
				levelId: 1032,
				paymentCardBankIdentificationNumber: '483312',
				merchantId: '345045125886',
				paymentCardLast4: '9856',
				issuingBankLabel: 'JPMORGAN CHASE BANK',
				clientId: 190,
				status: 'CANCEL',
				productId: 101520,
				sourceId: 42220,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 29.95,
				chargeback: 'chargeback',
				postedOn: '2021-08-27T00:00:00.000Z',
				cycle: 1,
				caseId: '812395057201',
				amount: 29.95,
				authorizationCode: '043310',
				code: '1040',
				receivedByMerchantOn: '2021-08-29T08:25:22.000Z',
				reconciledOn: '2021-08-28T00:00:00.000Z',
				debitCreditCode: 'DEBIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-08-06T00:00:00.000Z',
				orderId: 124847478,
				billingSequenceNumber: 34,
				customerId: 1140699836,
				authAttemptNumber: 1,
				installmentNumber: 34,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'ROOSEVELT',
				billingLastName: 'NEVERS',
				billingAddress: '3602 THOMAS DR',
				billingCity: 'HOUMA',
				billingStateProvince: 'LA',
				billingPostalCode: '70363',
				settledAmount: 24.95,
				paymentCardBrand: 'V',
				startedOn: '2018-11-18T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 371,
				flexibleBillingId: null,
				levelId: 1341,
				paymentCardBankIdentificationNumber: '406042',
				merchantId: '345045125886',
				paymentCardLast4: '2434',
				issuingBankLabel: 'JPMORGAN CHASE BANK',
				clientId: 218,
				status: 'CANCEL',
				productId: 101092,
				sourceId: 25407,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 24.95,
				chargeback: 'chargeback',
				postedOn: '2021-08-29T00:00:00.000Z',
				cycle: 1,
				caseId: '812413051301',
				amount: 24.95,
				authorizationCode: '023410',
				code: '1320',
				receivedByMerchantOn: '2021-08-31T07:30:40.000Z',
				reconciledOn: '2021-08-30T00:00:00.000Z',
				debitCreditCode: 'DEBIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-06-07T00:00:00.000Z',
				orderId: 124847478,
				billingSequenceNumber: 32,
				customerId: 1140699836,
				authAttemptNumber: 1,
				installmentNumber: 32,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'ROOSEVELT',
				billingLastName: 'NEVERS',
				billingAddress: '3602 THOMAS DR',
				billingCity: 'HOUMA',
				billingStateProvince: 'LA',
				billingPostalCode: '70363',
				settledAmount: 24.95,
				paymentCardBrand: 'V',
				startedOn: '2018-11-18T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 371,
				flexibleBillingId: null,
				levelId: 1341,
				paymentCardBankIdentificationNumber: '406042',
				merchantId: '345045125886',
				paymentCardLast4: '2434',
				issuingBankLabel: 'JPMORGAN CHASE BANK',
				clientId: 218,
				status: 'CANCEL',
				productId: 101092,
				sourceId: 25407,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 24.95,
				chargeback: 'chargeback',
				postedOn: '2021-08-29T00:00:00.000Z',
				cycle: 1,
				caseId: '812413050901',
				amount: 24.95,
				authorizationCode: '053410',
				code: '1320',
				receivedByMerchantOn: '2021-08-31T07:30:40.000Z',
				reconciledOn: '2021-08-30T00:00:00.000Z',
				debitCreditCode: 'DEBIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-07-07T00:00:00.000Z',
				orderId: 124847478,
				billingSequenceNumber: 33,
				customerId: 1140699836,
				authAttemptNumber: 1,
				installmentNumber: 33,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'ROOSEVELT',
				billingLastName: 'NEVERS',
				billingAddress: '3602 THOMAS DR',
				billingCity: 'HOUMA',
				billingStateProvince: 'LA',
				billingPostalCode: '70363',
				settledAmount: 24.95,
				paymentCardBrand: 'V',
				startedOn: '2018-11-18T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 371,
				flexibleBillingId: null,
				levelId: 1341,
				paymentCardBankIdentificationNumber: '406042',
				merchantId: '345045125886',
				paymentCardLast4: '2434',
				issuingBankLabel: 'JPMORGAN CHASE BANK',
				clientId: 218,
				status: 'CANCEL',
				productId: 101092,
				sourceId: 25407,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 24.95,
				chargeback: 'chargeback',
				postedOn: '2021-08-29T00:00:00.000Z',
				cycle: 1,
				caseId: '812413051101',
				amount: 24.95,
				authorizationCode: '003310',
				code: '1320',
				receivedByMerchantOn: '2021-08-31T07:30:40.000Z',
				reconciledOn: '2021-08-30T00:00:00.000Z',
				debitCreditCode: 'DEBIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-07-04T00:00:00.000Z',
				orderId: 127289915,
				billingSequenceNumber: 13,
				customerId: 1142224557,
				authAttemptNumber: 1,
				installmentNumber: 13,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'BRENT',
				billingLastName: 'ESANCY',
				billingAddress: '293 NORTHERN AVE',
				billingCity: 'AUGUSTA',
				billingStateProvince: 'ME',
				billingPostalCode: '04330',
				settledAmount: 29.95,
				paymentCardBrand: 'V',
				startedOn: '2020-07-09T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 198,
				flexibleBillingId: null,
				levelId: 1236,
				paymentCardBankIdentificationNumber: '414709',
				merchantId: '345045125886',
				paymentCardLast4: '5645',
				issuingBankLabel: 'CAPITAL ONE',
				clientId: 144,
				status: 'CANCEL',
				productId: 102064,
				sourceId: 40481,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 29.95,
				chargeback: 'chargeback',
				postedOn: '2021-09-03T00:00:00.000Z',
				cycle: 1,
				caseId: '812463355801',
				amount: 29.95,
				authorizationCode: '02751C',
				code: '1040',
				receivedByMerchantOn: '2021-09-06T07:30:29.000Z',
				reconciledOn: '2021-09-05T00:00:00.000Z',
				debitCreditCode: 'CREDIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-08-03T00:00:00.000Z',
				orderId: 127289915,
				billingSequenceNumber: 14,
				customerId: 1142224557,
				authAttemptNumber: 1,
				installmentNumber: 14,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'BRENT',
				billingLastName: 'ESANCY',
				billingAddress: '293 NORTHERN AVE',
				billingCity: 'AUGUSTA',
				billingStateProvince: 'ME',
				billingPostalCode: '04330',
				settledAmount: 29.95,
				paymentCardBrand: 'V',
				startedOn: '2020-07-09T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 198,
				flexibleBillingId: null,
				levelId: 1236,
				paymentCardBankIdentificationNumber: '414709',
				merchantId: '345045125886',
				paymentCardLast4: '5645',
				issuingBankLabel: 'CAPITAL ONE',
				clientId: 144,
				status: 'CANCEL',
				productId: 102064,
				sourceId: 40481,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 29.95,
				chargeback: 'chargeback',
				postedOn: '2021-09-03T00:00:00.000Z',
				cycle: 1,
				caseId: '812463355901',
				amount: 29.95,
				authorizationCode: '09259C',
				code: '1040',
				receivedByMerchantOn: '2021-09-06T07:30:29.000Z',
				reconciledOn: '2021-09-05T00:00:00.000Z',
				debitCreditCode: 'CREDIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-06-04T00:00:00.000Z',
				orderId: 127289915,
				billingSequenceNumber: 12,
				customerId: 1142224557,
				authAttemptNumber: 1,
				installmentNumber: 12,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'BRENT',
				billingLastName: 'ESANCY',
				billingAddress: '293 NORTHERN AVE',
				billingCity: 'AUGUSTA',
				billingStateProvince: 'ME',
				billingPostalCode: '04330',
				settledAmount: 29.95,
				paymentCardBrand: 'V',
				startedOn: '2020-07-09T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 198,
				flexibleBillingId: null,
				levelId: 1236,
				paymentCardBankIdentificationNumber: '414709',
				merchantId: '345045125886',
				paymentCardLast4: '5645',
				issuingBankLabel: 'CAPITAL ONE',
				clientId: 144,
				status: 'CANCEL',
				productId: 102064,
				sourceId: 40481,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 29.95,
				chargeback: 'chargeback',
				postedOn: '2021-09-03T00:00:00.000Z',
				cycle: 1,
				caseId: '812463355701',
				amount: 29.95,
				authorizationCode: '02187C',
				code: '1040',
				receivedByMerchantOn: '2021-09-06T07:30:29.000Z',
				reconciledOn: '2021-09-05T00:00:00.000Z',
				debitCreditCode: 'CREDIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-09-02T00:00:00.000Z',
				orderId: 127289915,
				billingSequenceNumber: 15,
				customerId: 1142224557,
				authAttemptNumber: 1,
				installmentNumber: 15,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'BRENT',
				billingLastName: 'ESANCY',
				billingAddress: '293 NORTHERN AVE',
				billingCity: 'AUGUSTA',
				billingStateProvince: 'ME',
				billingPostalCode: '04330',
				settledAmount: 29.95,
				paymentCardBrand: 'V',
				startedOn: '2020-07-09T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 198,
				flexibleBillingId: null,
				levelId: 1236,
				paymentCardBankIdentificationNumber: '414709',
				merchantId: '345045125886',
				paymentCardLast4: '5645',
				issuingBankLabel: 'CAPITAL ONE',
				clientId: 144,
				status: 'CANCEL',
				productId: 102064,
				sourceId: 40481,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 29.95,
				chargeback: 'chargeback',
				postedOn: '2021-09-06T00:00:00.000Z',
				cycle: 1,
				caseId: '812493225601',
				amount: 29.95,
				authorizationCode: '02514C',
				code: '1040',
				receivedByMerchantOn: '2021-09-08T07:30:33.000Z',
				reconciledOn: '2021-09-07T00:00:00.000Z',
				debitCreditCode: 'CREDIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-09-07T00:00:00.000Z',
				orderId: 128703377,
				billingSequenceNumber: 1,
				customerId: 1143109120,
				authAttemptNumber: 1,
				installmentNumber: 1,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'JOSEPH',
				billingLastName: 'HARRIS',
				billingAddress: '13220 S 48TH ST APT 2142',
				billingCity: 'PHOENIX',
				billingStateProvince: 'AZ',
				billingPostalCode: '85044',
				settledAmount: 1.95,
				paymentCardBrand: 'V',
				startedOn: '2021-09-07T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 371,
				flexibleBillingId: null,
				levelId: 1341,
				paymentCardBankIdentificationNumber: '475055',
				merchantId: '345045125886',
				paymentCardLast4: '4881',
				issuingBankLabel: 'JPMORGAN CHASE BANK',
				clientId: 218,
				status: 'CANCEL',
				productId: 101958,
				sourceId: 37220,
				categoryId: 65,
				membershipType: 'FTO',
				billingDistributionGroupId: null,
				promotionPeriodInDays: 30,
				membershipFee: 1.95,
				chargeback: 'chargeback',
				postedOn: '2021-09-14T00:00:00.000Z',
				cycle: 1,
				caseId: '812574817701',
				amount: 1.95,
				authorizationCode: '000114',
				code: '1040',
				receivedByMerchantOn: '2021-09-16T07:30:26.000Z',
				reconciledOn: '2021-09-15T00:00:00.000Z',
				debitCreditCode: 'DEBIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-09-07T00:00:00.000Z',
				orderId: 128703551,
				billingSequenceNumber: 1,
				customerId: 1143109236,
				authAttemptNumber: 1,
				installmentNumber: 1,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'ROSE',
				billingLastName: 'DAVIS',
				billingAddress: '2465 MAIN ST',
				billingCity: 'SARASOTA',
				billingStateProvince: 'FL',
				billingPostalCode: '34237',
				settledAmount: 1.95,
				paymentCardBrand: 'V',
				startedOn: '2021-09-07T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 371,
				flexibleBillingId: null,
				levelId: 1341,
				paymentCardBankIdentificationNumber: '483312',
				merchantId: '345045125886',
				paymentCardLast4: '9234',
				issuingBankLabel: 'JPMORGAN CHASE BANK',
				clientId: 218,
				status: 'CANCEL',
				productId: 101958,
				sourceId: 37220,
				categoryId: 65,
				membershipType: 'FTO',
				billingDistributionGroupId: null,
				promotionPeriodInDays: 30,
				membershipFee: 1.95,
				chargeback: 'chargeback',
				postedOn: '2021-09-14T00:00:00.000Z',
				cycle: 1,
				caseId: '812574938501',
				amount: 1.95,
				authorizationCode: '000114',
				code: '1040',
				receivedByMerchantOn: '2021-09-17T07:40:16.000Z',
				reconciledOn: '2021-09-16T00:00:00.000Z',
				debitCreditCode: 'DEBIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-06-02T00:00:00.000Z',
				orderId: 128440815,
				billingSequenceNumber: 1,
				customerId: 1142929113,
				authAttemptNumber: 1,
				installmentNumber: 1,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'JACK',
				billingLastName: 'RUDIO',
				billingAddress: '951 RONALD AVE APT 401',
				billingCity: 'MISSOULA',
				billingStateProvince: 'MT',
				billingPostalCode: '59801',
				settledAmount: 29.95,
				paymentCardBrand: 'M',
				startedOn: '2021-06-02T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 198,
				flexibleBillingId: null,
				levelId: 1236,
				paymentCardBankIdentificationNumber: '546616',
				merchantId: '345045125886',
				paymentCardLast4: '0112',
				issuingBankLabel: 'CITIBANK',
				clientId: 144,
				status: 'CANCEL',
				productId: 102064,
				sourceId: 40481,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 29.95,
				chargeback: 'chargeback',
				postedOn: '2021-09-23T00:00:00.000Z',
				cycle: 1,
				caseId: '712663816301',
				amount: 29.95,
				authorizationCode: '47516P',
				code: '37',
				receivedByMerchantOn: '2021-09-25T07:30:33.000Z',
				reconciledOn: '2021-09-24T00:00:00.000Z',
				debitCreditCode: 'CREDIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-08-21T00:00:00.000Z',
				orderId: 128658781,
				billingSequenceNumber: 1,
				customerId: 1143034350,
				authAttemptNumber: 1,
				installmentNumber: 1,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'JULIE',
				billingLastName: 'RODRIGUEZ',
				billingAddress: '16524 SW 59TH TER',
				billingCity: 'MIAMI',
				billingStateProvince: 'FL',
				billingPostalCode: '33193',
				settledAmount: 27.95,
				paymentCardBrand: 'M',
				startedOn: '2021-08-21T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 371,
				flexibleBillingId: null,
				levelId: 1341,
				paymentCardBankIdentificationNumber: '542418',
				merchantId: '345045125886',
				paymentCardLast4: '8229',
				issuingBankLabel: 'CITIBANK',
				clientId: 218,
				status: 'CANCEL',
				productId: 101955,
				sourceId: 37220,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 27.95,
				chargeback: 'chargeback',
				postedOn: '2021-10-29T00:00:00.000Z',
				cycle: 1,
				caseId: '713023556701',
				amount: 27.95,
				authorizationCode: '01097Y',
				code: '37',
				receivedByMerchantOn: '2021-10-31T07:30:27.000Z',
				reconciledOn: '2021-10-30T00:00:00.000Z',
				debitCreditCode: 'CREDIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-09-20T00:00:00.000Z',
				orderId: 128658781,
				billingSequenceNumber: 2,
				customerId: 1143034350,
				authAttemptNumber: 1,
				installmentNumber: 2,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'JULIE',
				billingLastName: 'RODRIGUEZ',
				billingAddress: '16524 SW 59TH TER',
				billingCity: 'MIAMI',
				billingStateProvince: 'FL',
				billingPostalCode: '33193',
				settledAmount: 27.95,
				paymentCardBrand: 'M',
				startedOn: '2021-08-21T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 371,
				flexibleBillingId: null,
				levelId: 1341,
				paymentCardBankIdentificationNumber: '542418',
				merchantId: '345045125886',
				paymentCardLast4: '8229',
				issuingBankLabel: 'CITIBANK',
				clientId: 218,
				status: 'CANCEL',
				productId: 101955,
				sourceId: 37220,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 27.95,
				chargeback: 'chargeback',
				postedOn: '2021-10-29T00:00:00.000Z',
				cycle: 1,
				caseId: '713023556801',
				amount: 27.95,
				authorizationCode: '49824Y',
				code: '37',
				receivedByMerchantOn: '2021-10-31T07:30:27.000Z',
				reconciledOn: '2021-10-30T00:00:00.000Z',
				debitCreditCode: 'CREDIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-07-20T00:00:00.000Z',
				orderId: 128337211,
				billingSequenceNumber: 4,
				customerId: 1142865255,
				authAttemptNumber: 1,
				installmentNumber: 4,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'CAMILLA',
				billingLastName: 'ISLEY',
				billingAddress: '1061 SOUTHERN HIGH SCHOOL RD',
				billingCity: 'BURLINGTON',
				billingStateProvince: 'NC',
				billingPostalCode: '27215',
				settledAmount: 29.95,
				paymentCardBrand: 'M',
				startedOn: '2021-04-21T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 198,
				flexibleBillingId: null,
				levelId: 1236,
				paymentCardBankIdentificationNumber: '515735',
				merchantId: '345045125886',
				paymentCardLast4: '2996',
				issuingBankLabel: 'CITIBANK',
				clientId: 144,
				status: 'CANCEL',
				productId: 102064,
				sourceId: 40481,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 29.95,
				chargeback: 'chargeback',
				postedOn: '2021-11-05T00:00:00.000Z',
				cycle: 1,
				caseId: '713093099301',
				amount: 29.95,
				authorizationCode: '98337Z',
				code: '37',
				receivedByMerchantOn: '2021-11-07T08:50:40.000Z',
				reconciledOn: '2021-11-06T00:00:00.000Z',
				debitCreditCode: 'CREDIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-07-24T00:00:00.000Z',
				orderId: 127918057,
				billingSequenceNumber: 9,
				customerId: 1142604836,
				authAttemptNumber: 1,
				installmentNumber: 8,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'BENJAMIN',
				billingLastName: 'VIETH',
				billingAddress: '7680 BACK ACRES RD',
				billingCity: 'AMARILLO',
				billingStateProvince: 'TX',
				billingPostalCode: '79119',
				settledAmount: 29.95,
				paymentCardBrand: 'M',
				startedOn: '2020-12-23T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 303,
				flexibleBillingId: null,
				levelId: 1032,
				paymentCardBankIdentificationNumber: '552869',
				merchantId: '345045125886',
				paymentCardLast4: '4500',
				issuingBankLabel: 'CAPITAL ONE',
				clientId: 190,
				status: 'CANCEL',
				productId: 101520,
				sourceId: 42220,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 29.95,
				chargeback: 'chargeback',
				postedOn: '2021-11-06T00:00:00.000Z',
				cycle: 1,
				caseId: '713103804801',
				amount: 29.95,
				authorizationCode: '00056J',
				code: '37',
				receivedByMerchantOn: '2021-11-08T07:30:41.000Z',
				reconciledOn: '2021-11-07T00:00:00.000Z',
				debitCreditCode: 'CREDIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-08-03T00:00:00.000Z',
				orderId: 127528493,
				billingSequenceNumber: 12,
				customerId: 1142345520,
				authAttemptNumber: 1,
				installmentNumber: 12,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'KENNETH',
				billingLastName: 'BRAY',
				billingAddress: '136 WOODLAND HILLS DR',
				billingCity: 'ALEDO',
				billingStateProvince: 'TX',
				billingPostalCode: '76008',
				settledAmount: 27.95,
				paymentCardBrand: 'M',
				startedOn: '2020-09-07T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 371,
				flexibleBillingId: null,
				levelId: 1341,
				paymentCardBankIdentificationNumber: '518941',
				merchantId: '345045125886',
				paymentCardLast4: '5880',
				issuingBankLabel: 'CITIBANK',
				clientId: 218,
				status: 'CANCEL',
				productId: 101955,
				sourceId: 37220,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 27.95,
				chargeback: 'chargeback',
				postedOn: '2021-11-29T00:00:00.000Z',
				cycle: 1,
				caseId: '713333215101',
				amount: 27.95,
				authorizationCode: '86705Z',
				code: '37',
				receivedByMerchantOn: '2021-12-01T07:30:17.000Z',
				reconciledOn: '2021-11-30T00:00:00.000Z',
				debitCreditCode: 'CREDIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-08-06T00:00:00.000Z',
				orderId: 128300214,
				billingSequenceNumber: 5,
				customerId: 1142817890,
				authAttemptNumber: 1,
				installmentNumber: 5,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'MYLLICENT',
				billingLastName: 'BUCHANAN',
				billingAddress: '5712 S KENWOOD AVE APT 1',
				billingCity: 'CHICAGO',
				billingStateProvince: 'IL',
				billingPostalCode: '60637',
				settledAmount: 27.95,
				paymentCardBrand: 'M',
				startedOn: '2021-04-08T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 371,
				flexibleBillingId: null,
				levelId: 1341,
				paymentCardBankIdentificationNumber: '549113',
				merchantId: '345045125886',
				paymentCardLast4: '6626',
				issuingBankLabel: 'CITIBANK',
				clientId: 218,
				status: 'CANCEL',
				productId: 101955,
				sourceId: 37220,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 27.95,
				chargeback: 'chargeback',
				postedOn: '2021-12-01T00:00:00.000Z',
				cycle: 1,
				caseId: '713353823501',
				amount: 27.95,
				authorizationCode: '38875P',
				code: '37',
				receivedByMerchantOn: '2021-12-03T08:40:21.000Z',
				reconciledOn: '2021-12-02T00:00:00.000Z',
				debitCreditCode: 'CREDIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-10-05T00:00:00.000Z',
				orderId: 128300214,
				billingSequenceNumber: 7,
				customerId: 1142817890,
				authAttemptNumber: 1,
				installmentNumber: 7,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'MYLLICENT',
				billingLastName: 'BUCHANAN',
				billingAddress: '5712 S KENWOOD AVE APT 1',
				billingCity: 'CHICAGO',
				billingStateProvince: 'IL',
				billingPostalCode: '60637',
				settledAmount: 27.95,
				paymentCardBrand: 'M',
				startedOn: '2021-04-08T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 371,
				flexibleBillingId: null,
				levelId: 1341,
				paymentCardBankIdentificationNumber: '549113',
				merchantId: '345045125886',
				paymentCardLast4: '6626',
				issuingBankLabel: 'CITIBANK',
				clientId: 218,
				status: 'CANCEL',
				productId: 101955,
				sourceId: 37220,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 27.95,
				chargeback: 'chargeback',
				postedOn: '2021-12-09T00:00:00.000Z',
				cycle: 1,
				caseId: '713433748201',
				amount: 27.95,
				authorizationCode: '02778P',
				code: '37',
				receivedByMerchantOn: '2021-12-11T07:30:15.000Z',
				reconciledOn: '2021-12-10T00:00:00.000Z',
				debitCreditCode: 'CREDIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-11-04T00:00:00.000Z',
				orderId: 128300214,
				billingSequenceNumber: 8,
				customerId: 1142817890,
				authAttemptNumber: 1,
				installmentNumber: 8,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'MYLLICENT',
				billingLastName: 'BUCHANAN',
				billingAddress: '5712 S KENWOOD AVE APT 1',
				billingCity: 'CHICAGO',
				billingStateProvince: 'IL',
				billingPostalCode: '60637',
				settledAmount: 27.95,
				paymentCardBrand: 'M',
				startedOn: '2021-04-08T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 371,
				flexibleBillingId: null,
				levelId: 1341,
				paymentCardBankIdentificationNumber: '549113',
				merchantId: '345045125886',
				paymentCardLast4: '6626',
				issuingBankLabel: 'CITIBANK',
				clientId: 218,
				status: 'CANCEL',
				productId: 101955,
				sourceId: 37220,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 27.95,
				chargeback: 'chargeback',
				postedOn: '2021-12-09T00:00:00.000Z',
				cycle: 1,
				caseId: '713433748301',
				amount: 27.95,
				authorizationCode: '54897P',
				code: '37',
				receivedByMerchantOn: '2021-12-11T07:30:15.000Z',
				reconciledOn: '2021-12-10T00:00:00.000Z',
				debitCreditCode: 'CREDIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-09-05T00:00:00.000Z',
				orderId: 128300214,
				billingSequenceNumber: 6,
				customerId: 1142817890,
				authAttemptNumber: 1,
				installmentNumber: 6,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'MYLLICENT',
				billingLastName: 'BUCHANAN',
				billingAddress: '5712 S KENWOOD AVE APT 1',
				billingCity: 'CHICAGO',
				billingStateProvince: 'IL',
				billingPostalCode: '60637',
				settledAmount: 27.95,
				paymentCardBrand: 'M',
				startedOn: '2021-04-08T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 371,
				flexibleBillingId: null,
				levelId: 1341,
				paymentCardBankIdentificationNumber: '549113',
				merchantId: '345045125886',
				paymentCardLast4: '6626',
				issuingBankLabel: 'CITIBANK',
				clientId: 218,
				status: 'CANCEL',
				productId: 101955,
				sourceId: 37220,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 27.95,
				chargeback: 'chargeback',
				postedOn: '2021-12-09T00:00:00.000Z',
				cycle: 1,
				caseId: '713433748101',
				amount: 27.95,
				authorizationCode: '08481P',
				code: '37',
				receivedByMerchantOn: '2021-12-11T07:30:15.000Z',
				reconciledOn: '2021-12-10T00:00:00.000Z',
				debitCreditCode: 'CREDIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-08-24T00:00:00.000Z',
				orderId: 128503931,
				billingSequenceNumber: 3,
				customerId: 1142960732,
				authAttemptNumber: 1,
				installmentNumber: 3,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'DOUGLAS',
				billingLastName: 'RODRIGUEZ',
				billingAddress: '2530 INDEPENDENCE AVE',
				billingCity: 'BRONX',
				billingStateProvince: 'NY',
				billingPostalCode: '10463',
				settledAmount: 29.95,
				paymentCardBrand: 'M',
				startedOn: '2021-06-25T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 198,
				flexibleBillingId: null,
				levelId: 1236,
				paymentCardBankIdentificationNumber: '526219',
				merchantId: '345045125886',
				paymentCardLast4: '3988',
				issuingBankLabel: 'CITIBANK',
				clientId: 144,
				status: 'CANCEL',
				productId: 102064,
				sourceId: 40481,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 29.95,
				chargeback: 'chargeback',
				postedOn: '2021-12-13T00:00:00.000Z',
				cycle: 1,
				caseId: '713473364601',
				amount: 29.95,
				authorizationCode: '03084Z',
				code: '37',
				receivedByMerchantOn: '2021-12-15T07:30:39.000Z',
				reconciledOn: '2021-12-14T00:00:00.000Z',
				debitCreditCode: 'DEBIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-11-19T00:00:00.000Z',
				orderId: 128809165,
				billingSequenceNumber: 1,
				customerId: 1143133487,
				authAttemptNumber: 6,
				installmentNumber: 1,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'YVONNE',
				billingLastName: 'BEVIS',
				billingAddress: '131 E CR 500 N',
				billingCity: 'ARTHUR',
				billingStateProvince: 'IL',
				billingPostalCode: '61911',
				settledAmount: 29.95,
				paymentCardBrand: 'V',
				startedOn: '2021-10-15T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 198,
				flexibleBillingId: null,
				levelId: 1236,
				paymentCardBankIdentificationNumber: '414709',
				merchantId: '345045125886',
				paymentCardLast4: '9022',
				issuingBankLabel: 'CAPITAL ONE',
				clientId: 144,
				status: 'CANCEL',
				productId: 102394,
				sourceId: 42452,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 29.95,
				chargeback: 'chargeback',
				postedOn: '2021-12-15T00:00:00.000Z',
				cycle: 1,
				caseId: '813493440101',
				amount: 29.95,
				authorizationCode: '02220C',
				code: '1040',
				receivedByMerchantOn: '2021-12-17T07:30:32.000Z',
				reconciledOn: '2021-12-16T00:00:00.000Z',
				debitCreditCode: 'CREDIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-11-10T00:00:00.000Z',
				orderId: 128880962,
				billingSequenceNumber: 1,
				customerId: 1143175459,
				authAttemptNumber: 1,
				installmentNumber: 1,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'LEO',
				billingLastName: 'BEZANSON',
				billingAddress: '720 CHANDLER ST',
				billingCity: 'TEWKSBURY',
				billingStateProvince: 'MA',
				billingPostalCode: '01876',
				settledAmount: 29.95,
				paymentCardBrand: 'M',
				startedOn: '2021-11-10T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 198,
				flexibleBillingId: null,
				levelId: 1236,
				paymentCardBankIdentificationNumber: '549460',
				merchantId: '345045125886',
				paymentCardLast4: '5906',
				issuingBankLabel: 'CITIBANK',
				clientId: 144,
				status: 'CANCEL',
				productId: 102394,
				sourceId: 42452,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 29.95,
				chargeback: 'chargeback',
				postedOn: '2021-12-31T00:00:00.000Z',
				cycle: 1,
				caseId: '713653700001',
				amount: 29.95,
				authorizationCode: '01027Y',
				code: '37',
				receivedByMerchantOn: '2022-01-04T07:30:38.000Z',
				reconciledOn: '2022-01-03T00:00:00.000Z',
				debitCreditCode: 'CREDIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-10-03T00:00:00.000Z',
				orderId: 128692543,
				billingSequenceNumber: 2,
				customerId: 1143069819,
				authAttemptNumber: 1,
				installmentNumber: 2,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'RANDY K',
				billingLastName: 'GINTER',
				billingAddress: '12 HELEN ST',
				billingCity: 'ENOLA',
				billingStateProvince: 'PA',
				billingPostalCode: '17025',
				settledAmount: 29.95,
				paymentCardBrand: 'M',
				startedOn: '2021-09-03T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 303,
				flexibleBillingId: null,
				levelId: 1032,
				paymentCardBankIdentificationNumber: '542418',
				merchantId: '345045125886',
				paymentCardLast4: '5072',
				issuingBankLabel: 'CITIBANK',
				clientId: 190,
				status: 'CANCEL',
				productId: 101520,
				sourceId: 42220,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 29.95,
				chargeback: 'chargeback',
				postedOn: '2022-01-24T00:00:00.000Z',
				cycle: 1,
				caseId: '720243561001',
				amount: 29.95,
				authorizationCode: '80871P',
				code: '37',
				receivedByMerchantOn: '2022-01-26T07:30:37.000Z',
				reconciledOn: '2022-01-25T00:00:00.000Z',
				debitCreditCode: 'CREDIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-12-30T00:00:00.000Z',
				orderId: 129013193,
				billingSequenceNumber: 1,
				customerId: 1143252262,
				authAttemptNumber: 1,
				installmentNumber: 1,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'ALFRED',
				billingLastName: 'GARCIA',
				billingAddress: '2004 GURLEY LN',
				billingCity: 'WACO',
				billingStateProvince: 'TX',
				billingPostalCode: '76706',
				settledAmount: 34.95,
				paymentCardBrand: 'M',
				startedOn: '2021-12-30T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 303,
				flexibleBillingId: null,
				levelId: 1032,
				paymentCardBankIdentificationNumber: '542418',
				merchantId: '345045125886',
				paymentCardLast4: '8844',
				issuingBankLabel: 'CITIBANK',
				clientId: 190,
				status: 'CANCEL',
				productId: 101520,
				sourceId: 42510,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 34.95,
				chargeback: 'chargeback',
				postedOn: '2022-01-24T00:00:00.000Z',
				cycle: 1,
				caseId: '720243542701',
				amount: 34.95,
				authorizationCode: '07445Y',
				code: '37',
				receivedByMerchantOn: '2022-01-27T07:30:24.000Z',
				reconciledOn: '2022-01-26T00:00:00.000Z',
				debitCreditCode: 'CREDIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-12-27T00:00:00.000Z',
				orderId: 129004090,
				billingSequenceNumber: 1,
				customerId: 1143234541,
				authAttemptNumber: 1,
				installmentNumber: 1,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'GRACE',
				billingLastName: 'HANLEY',
				billingAddress: '4371 E 1200 N',
				billingCity: 'DEMOTTE',
				billingStateProvince: 'IN',
				billingPostalCode: '46310',
				settledAmount: 27.95,
				paymentCardBrand: 'M',
				startedOn: '2021-12-27T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 371,
				flexibleBillingId: null,
				levelId: 1341,
				paymentCardBankIdentificationNumber: '512107',
				merchantId: '345045125886',
				paymentCardLast4: '8110',
				issuingBankLabel: 'CITIBANK',
				clientId: 218,
				status: 'CANCEL',
				productId: 101955,
				sourceId: 37220,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 27.95,
				chargeback: 'chargeback',
				postedOn: '2022-01-24T00:00:00.000Z',
				cycle: 1,
				caseId: '720243018801',
				amount: 27.95,
				authorizationCode: '22712Z',
				code: '37',
				receivedByMerchantOn: '2022-01-27T07:30:24.000Z',
				reconciledOn: '2022-01-26T00:00:00.000Z',
				debitCreditCode: 'CREDIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-12-16T00:00:00.000Z',
				orderId: 128974621,
				billingSequenceNumber: 1,
				customerId: 1143217063,
				authAttemptNumber: 1,
				installmentNumber: 1,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'MARIA',
				billingLastName: 'MCCONNELL',
				billingAddress: '3606 VISTA REY UNIT 39',
				billingCity: 'OCEANSIDE',
				billingStateProvince: 'CA',
				billingPostalCode: '92057',
				settledAmount: 27.95,
				paymentCardBrand: 'M',
				startedOn: '2021-12-16T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 371,
				flexibleBillingId: null,
				levelId: 1341,
				paymentCardBankIdentificationNumber: '542418',
				merchantId: '345045125886',
				paymentCardLast4: '3343',
				issuingBankLabel: 'CITIBANK',
				clientId: 218,
				status: 'CANCEL',
				productId: 101955,
				sourceId: 37220,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 27.95,
				chargeback: 'chargeback',
				postedOn: '2022-02-01T00:00:00.000Z',
				cycle: 1,
				caseId: '720323583101',
				amount: 27.95,
				authorizationCode: '27857Y',
				code: '37',
				receivedByMerchantOn: '2022-02-03T07:30:38.000Z',
				reconciledOn: '2022-02-02T00:00:00.000Z',
				debitCreditCode: 'CREDIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-12-30T00:00:00.000Z',
				orderId: 128929660,
				billingSequenceNumber: 2,
				customerId: 1143204333,
				authAttemptNumber: 1,
				installmentNumber: 2,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'JEAN',
				billingLastName: 'NELSON',
				billingAddress: '12443 S 74TH AVE',
				billingCity: 'PALOS HEIGHTS',
				billingStateProvince: 'IL',
				billingPostalCode: '60463',
				settledAmount: 29.95,
				paymentCardBrand: 'M',
				startedOn: '2021-11-30T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 198,
				flexibleBillingId: null,
				levelId: 1236,
				paymentCardBankIdentificationNumber: '541065',
				merchantId: '345045125886',
				paymentCardLast4: '3419',
				issuingBankLabel: 'CITIBANK',
				clientId: 144,
				status: 'CANCEL',
				productId: 102394,
				sourceId: 42452,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 29.95,
				chargeback: 'chargeback',
				postedOn: '2022-02-11T00:00:00.000Z',
				cycle: 1,
				caseId: '720423540101',
				amount: 29.95,
				authorizationCode: '07626P',
				code: '37',
				receivedByMerchantOn: '2022-02-13T07:30:19.000Z',
				reconciledOn: '2022-02-12T00:00:00.000Z',
				debitCreditCode: 'CREDIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2022-01-21T00:00:00.000Z',
				orderId: 129072584,
				billingSequenceNumber: 1,
				customerId: 1143288074,
				authAttemptNumber: 1,
				installmentNumber: 1,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'NORMA',
				billingLastName: 'VILLAREAL',
				billingAddress: '6120 CENTRAL DR',
				billingCity: 'MUKILTEO',
				billingStateProvince: 'WA',
				billingPostalCode: '98275',
				settledAmount: 34.95,
				paymentCardBrand: 'M',
				startedOn: '2022-01-21T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 402,
				flexibleBillingId: null,
				levelId: 1331,
				paymentCardBankIdentificationNumber: '542418',
				merchantId: '345045125886',
				paymentCardLast4: '9656',
				issuingBankLabel: 'CITIBANK',
				clientId: 248,
				status: 'CANCEL',
				productId: 101107,
				sourceId: 42529,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 34.95,
				chargeback: 'chargeback',
				postedOn: '2022-02-10T00:00:00.000Z',
				cycle: 1,
				caseId: '720413686201',
				amount: 34.95,
				authorizationCode: '14025P',
				code: '41',
				receivedByMerchantOn: '2022-02-13T07:30:19.000Z',
				reconciledOn: '2022-02-12T00:00:00.000Z',
				debitCreditCode: 'CREDIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2022-01-25T00:00:00.000Z',
				orderId: 128921627,
				billingSequenceNumber: 3,
				customerId: 1143185084,
				authAttemptNumber: 1,
				installmentNumber: 3,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'FLOYD',
				billingLastName: 'BURKHART',
				billingAddress: '35340 E 600 NORTH RD',
				billingCity: 'ARROWSMITH',
				billingStateProvince: 'IL',
				billingPostalCode: '61722',
				settledAmount: 27.95,
				paymentCardBrand: 'M',
				startedOn: '2021-11-26T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 371,
				flexibleBillingId: null,
				levelId: 1341,
				paymentCardBankIdentificationNumber: '546616',
				merchantId: '345045125886',
				paymentCardLast4: '4891',
				issuingBankLabel: 'CITIBANK',
				clientId: 218,
				status: 'CANCEL',
				productId: 101955,
				sourceId: 37220,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 27.95,
				chargeback: 'chargeback',
				postedOn: '2022-02-11T00:00:00.000Z',
				cycle: 1,
				caseId: '720423667901',
				amount: 27.95,
				authorizationCode: '08634Z',
				code: '41',
				receivedByMerchantOn: '2022-02-13T07:30:19.000Z',
				reconciledOn: '2022-02-12T00:00:00.000Z',
				debitCreditCode: 'CREDIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-12-26T00:00:00.000Z',
				orderId: 128921627,
				billingSequenceNumber: 2,
				customerId: 1143185084,
				authAttemptNumber: 1,
				installmentNumber: 2,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'FLOYD',
				billingLastName: 'BURKHART',
				billingAddress: '35340 E 600 NORTH RD',
				billingCity: 'ARROWSMITH',
				billingStateProvince: 'IL',
				billingPostalCode: '61722',
				settledAmount: 27.95,
				paymentCardBrand: 'M',
				startedOn: '2021-11-26T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 371,
				flexibleBillingId: null,
				levelId: 1341,
				paymentCardBankIdentificationNumber: '546616',
				merchantId: '345045125886',
				paymentCardLast4: '4891',
				issuingBankLabel: 'CITIBANK',
				clientId: 218,
				status: 'CANCEL',
				productId: 101955,
				sourceId: 37220,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 27.95,
				chargeback: 'chargeback',
				postedOn: '2022-02-11T00:00:00.000Z',
				cycle: 1,
				caseId: '720423667701',
				amount: 27.95,
				authorizationCode: '92572Z',
				code: '41',
				receivedByMerchantOn: '2022-02-13T07:30:19.000Z',
				reconciledOn: '2022-02-12T00:00:00.000Z',
				debitCreditCode: 'CREDIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2022-02-05T00:00:00.000Z',
				orderId: 129119269,
				billingSequenceNumber: 1,
				customerId: 1143344148,
				authAttemptNumber: 1,
				installmentNumber: 0,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'ERIC',
				billingLastName: 'JUSTIN',
				billingAddress: '15881 SW 14TH ST',
				billingCity: 'PEMBROKE PINES',
				billingStateProvince: 'FL',
				billingPostalCode: '33027',
				settledAmount: 3.95,
				paymentCardBrand: 'M',
				startedOn: '2022-02-05T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 505,
				flexibleBillingId: null,
				levelId: 1582,
				paymentCardBankIdentificationNumber: '525475',
				merchantId: '345045125886',
				paymentCardLast4: '2630',
				issuingBankLabel: 'CITIBANK',
				clientId: 247,
				status: 'CANCEL',
				productId: 102488,
				sourceId: 42541,
				categoryId: 57,
				membershipType: 'FTO',
				billingDistributionGroupId: null,
				promotionPeriodInDays: 21,
				membershipFee: 3.95,
				chargeback: 'chargeback',
				postedOn: '2022-02-12T00:00:00.000Z',
				cycle: 1,
				caseId: '720433306801',
				amount: 3.95,
				authorizationCode: '10159Z',
				code: '37',
				receivedByMerchantOn: '2022-02-14T07:30:13.000Z',
				reconciledOn: '2022-02-13T00:00:00.000Z',
				debitCreditCode: 'CREDIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-12-27T00:00:00.000Z',
				orderId: 129003676,
				billingSequenceNumber: 1,
				customerId: 1143246545,
				authAttemptNumber: 1,
				installmentNumber: 1,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'CRAIG',
				billingLastName: 'HOFFBAUER',
				billingAddress: '109 S AIRPORT RD',
				billingCity: 'COTTONWOOD',
				billingStateProvince: 'AZ',
				billingPostalCode: '86326',
				settledAmount: 29.95,
				paymentCardBrand: 'M',
				startedOn: '2021-12-27T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 198,
				flexibleBillingId: null,
				levelId: 1236,
				paymentCardBankIdentificationNumber: '512106',
				merchantId: '345045125886',
				paymentCardLast4: '6337',
				issuingBankLabel: 'CITIBANK',
				clientId: 144,
				status: 'CANCEL',
				productId: 102394,
				sourceId: 42452,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 29.95,
				chargeback: 'chargeback',
				postedOn: '2022-02-15T00:00:00.000Z',
				cycle: 1,
				caseId: '720463039801',
				amount: 29.95,
				authorizationCode: '02761Z',
				code: '37',
				receivedByMerchantOn: '2022-02-18T07:30:28.000Z',
				reconciledOn: '2022-02-17T00:00:00.000Z',
				debitCreditCode: 'CREDIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2022-01-26T00:00:00.000Z',
				orderId: 129003676,
				billingSequenceNumber: 2,
				customerId: 1143246545,
				authAttemptNumber: 1,
				installmentNumber: 2,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'CRAIG',
				billingLastName: 'HOFFBAUER',
				billingAddress: '109 S AIRPORT RD',
				billingCity: 'COTTONWOOD',
				billingStateProvince: 'AZ',
				billingPostalCode: '86326',
				settledAmount: 29.95,
				paymentCardBrand: 'M',
				startedOn: '2021-12-27T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 198,
				flexibleBillingId: null,
				levelId: 1236,
				paymentCardBankIdentificationNumber: '512106',
				merchantId: '345045125886',
				paymentCardLast4: '6337',
				issuingBankLabel: 'CITIBANK',
				clientId: 144,
				status: 'CANCEL',
				productId: 102394,
				sourceId: 42452,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 29.95,
				chargeback: 'chargeback',
				postedOn: '2022-02-15T00:00:00.000Z',
				cycle: 1,
				caseId: '720463039901',
				amount: 29.95,
				authorizationCode: '02626Z',
				code: '37',
				receivedByMerchantOn: '2022-02-18T07:30:28.000Z',
				reconciledOn: '2022-02-17T00:00:00.000Z',
				debitCreditCode: 'CREDIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2022-01-24T00:00:00.000Z',
				orderId: 129000951,
				billingSequenceNumber: 2,
				customerId: 1143232505,
				authAttemptNumber: 1,
				installmentNumber: 2,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'PATRICIA',
				billingLastName: 'POPE',
				billingAddress: '709 W 10TH AVE',
				billingCity: 'TALLAHASSEE',
				billingStateProvince: 'FL',
				billingPostalCode: '32303',
				settledAmount: 27.95,
				paymentCardBrand: 'M',
				startedOn: '2021-12-25T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 371,
				flexibleBillingId: null,
				levelId: 1341,
				paymentCardBankIdentificationNumber: '512106',
				merchantId: '345045125886',
				paymentCardLast4: '1573',
				issuingBankLabel: 'CITIBANK',
				clientId: 218,
				status: 'CANCEL',
				productId: 101955,
				sourceId: 37220,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 27.95,
				chargeback: 'chargeback',
				postedOn: '2022-02-19T00:00:00.000Z',
				cycle: 1,
				caseId: '720503037601',
				amount: 27.95,
				authorizationCode: '02414B',
				code: '37',
				receivedByMerchantOn: '2022-02-23T07:30:37.000Z',
				reconciledOn: '2022-02-22T00:00:00.000Z',
				debitCreditCode: 'CREDIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2022-01-28T00:00:00.000Z',
				orderId: 129048830,
				billingSequenceNumber: 2,
				customerId: 1143306586,
				authAttemptNumber: 1,
				installmentNumber: 0,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'ROXANNA L',
				billingLastName: 'RHOTON',
				billingAddress: '266 GIEKE RD',
				billingCity: 'OAK HILL',
				billingStateProvince: 'OH',
				billingPostalCode: '45656',
				settledAmount: 2.95,
				paymentCardBrand: 'M',
				startedOn: '2022-01-11T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 402,
				flexibleBillingId: null,
				levelId: 1331,
				paymentCardBankIdentificationNumber: '512107',
				merchantId: '345045125886',
				paymentCardLast4: '0200',
				issuingBankLabel: 'CITIBANK',
				clientId: 248,
				status: 'CANCEL',
				productId: 102452,
				sourceId: 42529,
				categoryId: 65,
				membershipType: 'FTO',
				billingDistributionGroupId: null,
				promotionPeriodInDays: 21,
				membershipFee: 2.95,
				chargeback: 'chargeback',
				postedOn: '2022-02-17T00:00:00.000Z',
				cycle: 1,
				caseId: '720483048301',
				amount: 2.95,
				authorizationCode: '22868B',
				code: '37',
				receivedByMerchantOn: '2022-02-24T07:30:44.000Z',
				reconciledOn: '2022-02-23T00:00:00.000Z',
				debitCreditCode: 'CREDIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-10-28T00:00:00.000Z',
				orderId: 125570199,
				billingSequenceNumber: 31,
				customerId: 1141140625,
				authAttemptNumber: 1,
				installmentNumber: 31,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'GOLDIE',
				billingLastName: 'NOLTE',
				billingAddress: '1111 S LAKEMONT AVE APT 327',
				billingCity: 'WINTER PARK',
				billingStateProvince: 'FL',
				billingPostalCode: '32792',
				settledAmount: 24.95,
				paymentCardBrand: 'M',
				startedOn: '2019-05-12T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 371,
				flexibleBillingId: null,
				levelId: 1341,
				paymentCardBankIdentificationNumber: '546616',
				merchantId: '345045125886',
				paymentCardLast4: '4713',
				issuingBankLabel: 'CITIBANK',
				clientId: 218,
				status: 'CANCEL',
				productId: 101092,
				sourceId: 25407,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 24.95,
				chargeback: 'chargeback',
				postedOn: '2022-02-23T00:00:00.000Z',
				cycle: 1,
				caseId: '720543995901',
				amount: 24.95,
				authorizationCode: '05824Z',
				code: '37',
				receivedByMerchantOn: '2022-02-25T07:30:36.000Z',
				reconciledOn: '2022-02-24T00:00:00.000Z',
				debitCreditCode: 'CREDIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2022-01-26T00:00:00.000Z',
				orderId: 125570199,
				billingSequenceNumber: 34,
				customerId: 1141140625,
				authAttemptNumber: 1,
				installmentNumber: 34,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'GOLDIE',
				billingLastName: 'NOLTE',
				billingAddress: '1111 S LAKEMONT AVE APT 327',
				billingCity: 'WINTER PARK',
				billingStateProvince: 'FL',
				billingPostalCode: '32792',
				settledAmount: 24.95,
				paymentCardBrand: 'M',
				startedOn: '2019-05-12T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 371,
				flexibleBillingId: null,
				levelId: 1341,
				paymentCardBankIdentificationNumber: '546616',
				merchantId: '345045125886',
				paymentCardLast4: '4713',
				issuingBankLabel: 'CITIBANK',
				clientId: 218,
				status: 'CANCEL',
				productId: 101092,
				sourceId: 25407,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 24.95,
				chargeback: 'chargeback',
				postedOn: '2022-03-03T00:00:00.000Z',
				cycle: 1,
				caseId: '720623686201',
				amount: 24.95,
				authorizationCode: '55837Z',
				code: '37',
				receivedByMerchantOn: '2022-03-05T07:30:20.000Z',
				reconciledOn: '2022-03-04T00:00:00.000Z',
				debitCreditCode: 'CREDIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-11-27T00:00:00.000Z',
				orderId: 125570199,
				billingSequenceNumber: 32,
				customerId: 1141140625,
				authAttemptNumber: 1,
				installmentNumber: 32,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'GOLDIE',
				billingLastName: 'NOLTE',
				billingAddress: '1111 S LAKEMONT AVE APT 327',
				billingCity: 'WINTER PARK',
				billingStateProvince: 'FL',
				billingPostalCode: '32792',
				settledAmount: 24.95,
				paymentCardBrand: 'M',
				startedOn: '2019-05-12T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 371,
				flexibleBillingId: null,
				levelId: 1341,
				paymentCardBankIdentificationNumber: '546616',
				merchantId: '345045125886',
				paymentCardLast4: '4713',
				issuingBankLabel: 'CITIBANK',
				clientId: 218,
				status: 'CANCEL',
				productId: 101092,
				sourceId: 25407,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 24.95,
				chargeback: 'chargeback',
				postedOn: '2022-03-09T00:00:00.000Z',
				cycle: 1,
				caseId: '720683670701',
				amount: 24.95,
				authorizationCode: '68548Z',
				code: '37',
				receivedByMerchantOn: '2022-03-11T07:30:16.000Z',
				reconciledOn: '2022-03-10T00:00:00.000Z',
				debitCreditCode: 'CREDIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-12-27T00:00:00.000Z',
				orderId: 125570199,
				billingSequenceNumber: 33,
				customerId: 1141140625,
				authAttemptNumber: 1,
				installmentNumber: 33,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'GOLDIE',
				billingLastName: 'NOLTE',
				billingAddress: '1111 S LAKEMONT AVE APT 327',
				billingCity: 'WINTER PARK',
				billingStateProvince: 'FL',
				billingPostalCode: '32792',
				settledAmount: 24.95,
				paymentCardBrand: 'M',
				startedOn: '2019-05-12T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 371,
				flexibleBillingId: null,
				levelId: 1341,
				paymentCardBankIdentificationNumber: '546616',
				merchantId: '345045125886',
				paymentCardLast4: '4713',
				issuingBankLabel: 'CITIBANK',
				clientId: 218,
				status: 'CANCEL',
				productId: 101092,
				sourceId: 25407,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 24.95,
				chargeback: 'chargeback',
				postedOn: '2022-03-09T00:00:00.000Z',
				cycle: 1,
				caseId: '720683670901',
				amount: 24.95,
				authorizationCode: '89421Z',
				code: '37',
				receivedByMerchantOn: '2022-03-11T07:30:16.000Z',
				reconciledOn: '2022-03-10T00:00:00.000Z',
				debitCreditCode: 'CREDIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2022-01-29T00:00:00.000Z',
				orderId: 128929660,
				billingSequenceNumber: 3,
				customerId: 1143204333,
				authAttemptNumber: 1,
				installmentNumber: 3,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'JEAN',
				billingLastName: 'NELSON',
				billingAddress: '12443 S 74TH AVE',
				billingCity: 'PALOS HEIGHTS',
				billingStateProvince: 'IL',
				billingPostalCode: '60463',
				settledAmount: 29.95,
				paymentCardBrand: 'M',
				startedOn: '2021-11-30T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 198,
				flexibleBillingId: null,
				levelId: 1236,
				paymentCardBankIdentificationNumber: '541065',
				merchantId: '345045125886',
				paymentCardLast4: '3419',
				issuingBankLabel: 'CITIBANK',
				clientId: 144,
				status: 'CANCEL',
				productId: 102394,
				sourceId: 42452,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 29.95,
				chargeback: 'chargeback',
				postedOn: '2022-03-12T00:00:00.000Z',
				cycle: 1,
				caseId: '720713513501',
				amount: 29.95,
				authorizationCode: '25009P',
				code: '41',
				receivedByMerchantOn: '2022-03-14T07:30:21.000Z',
				reconciledOn: '2022-03-13T00:00:00.000Z',
				debitCreditCode: 'CREDIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2022-03-19T00:00:00.000Z',
				orderId: 129240532,
				billingSequenceNumber: 1,
				customerId: 1143408846,
				authAttemptNumber: 1,
				installmentNumber: 0,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'BARBARA',
				billingLastName: 'EMERY',
				billingAddress: '367 BLAZEY RD',
				billingCity: 'VICTOR',
				billingStateProvince: 'NY',
				billingPostalCode: '14564',
				settledAmount: 3.95,
				paymentCardBrand: 'M',
				startedOn: '2022-03-19T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 505,
				flexibleBillingId: 3859,
				levelId: 1582,
				paymentCardBankIdentificationNumber: '549460',
				merchantId: '345045125886',
				paymentCardLast4: '0296',
				issuingBankLabel: 'CITIBANK',
				clientId: 247,
				status: 'inactive',
				productId: 102488,
				sourceId: 42540,
				categoryId: 57,
				membershipType: 'FTO',
				billingDistributionGroupId: 231,
				promotionPeriodInDays: 21,
				membershipFee: 3.95,
				chargeback: 'chargeback',
				postedOn: '2022-03-29T00:00:00.000Z',
				cycle: 1,
				caseId: '720883689001',
				amount: 3.95,
				authorizationCode: '01924W',
				code: '37',
				receivedByMerchantOn: '2022-03-31T07:30:34.000Z',
				reconciledOn: '2022-03-30T00:00:00.000Z',
				debitCreditCode: 'CREDIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2021-12-22T00:00:00.000Z',
				orderId: 128828060,
				billingSequenceNumber: 3,
				customerId: 1143148134,
				authAttemptNumber: 1,
				installmentNumber: 3,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'LEONA',
				billingLastName: 'UHLER',
				billingAddress: '149 ROBINSON RD',
				billingCity: 'HOWARD',
				billingStateProvince: 'PA',
				billingPostalCode: '16841',
				settledAmount: 29.95,
				paymentCardBrand: 'M',
				startedOn: '2021-10-23T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 198,
				flexibleBillingId: null,
				levelId: 1236,
				paymentCardBankIdentificationNumber: '541065',
				merchantId: '345045125886',
				paymentCardLast4: '4547',
				issuingBankLabel: 'CITIBANK',
				clientId: 144,
				status: 'CANCEL',
				productId: 102394,
				sourceId: 42452,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 29.95,
				chargeback: 'chargeback',
				postedOn: '2022-04-13T00:00:00.000Z',
				cycle: 1,
				caseId: '721033399101',
				amount: 29.95,
				authorizationCode: '12884P',
				code: '37',
				receivedByMerchantOn: '2022-04-15T07:40:38.000Z',
				reconciledOn: '2022-04-14T00:00:00.000Z',
				debitCreditCode: 'CREDIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2022-04-08T00:00:00.000Z',
				orderId: 128623124,
				billingSequenceNumber: 9,
				customerId: 1143014160,
				authAttemptNumber: 1,
				installmentNumber: 9,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'JAMES',
				billingLastName: 'REYMER',
				billingAddress: '114 W REED ST',
				billingCity: 'FULTON',
				billingStateProvince: 'MO',
				billingPostalCode: '65251',
				settledAmount: 27.95,
				paymentCardBrand: 'M',
				startedOn: '2021-08-08T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 371,
				flexibleBillingId: null,
				levelId: 1341,
				paymentCardBankIdentificationNumber: '514887',
				merchantId: '345045125886',
				paymentCardLast4: '3966',
				issuingBankLabel: 'BARCLAYS BANK',
				clientId: 218,
				status: 'CANCEL',
				productId: 101955,
				sourceId: 37220,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 27.95,
				chargeback: 'chargeback',
				postedOn: '2022-04-19T00:00:00.000Z',
				cycle: 1,
				caseId: '721093092301',
				amount: 27.95,
				authorizationCode: '00064Z',
				code: '37',
				receivedByMerchantOn: '2022-04-21T07:30:38.000Z',
				reconciledOn: '2022-04-20T00:00:00.000Z',
				debitCreditCode: 'CREDIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2022-03-09T00:00:00.000Z',
				orderId: 128623124,
				billingSequenceNumber: 8,
				customerId: 1143014160,
				authAttemptNumber: 1,
				installmentNumber: 8,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'JAMES',
				billingLastName: 'REYMER',
				billingAddress: '114 W REED ST',
				billingCity: 'FULTON',
				billingStateProvince: 'MO',
				billingPostalCode: '65251',
				settledAmount: 27.95,
				paymentCardBrand: 'M',
				startedOn: '2021-08-08T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 371,
				flexibleBillingId: null,
				levelId: 1341,
				paymentCardBankIdentificationNumber: '514887',
				merchantId: '345045125886',
				paymentCardLast4: '3966',
				issuingBankLabel: 'BARCLAYS BANK',
				clientId: 218,
				status: 'CANCEL',
				productId: 101955,
				sourceId: 37220,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 27.95,
				chargeback: 'chargeback',
				postedOn: '2022-04-19T00:00:00.000Z',
				cycle: 1,
				caseId: '721093092201',
				amount: 27.95,
				authorizationCode: '00495Z',
				code: '37',
				receivedByMerchantOn: '2022-04-21T07:30:38.000Z',
				reconciledOn: '2022-04-20T00:00:00.000Z',
				debitCreditCode: 'CREDIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			},
			{
				settledOn: '2022-04-17T00:00:00.000Z',
				orderId: 129151311,
				billingSequenceNumber: 3,
				customerId: 1143327717,
				authAttemptNumber: 1,
				installmentNumber: 3,
				addressVerificationServiceResponse: 'I3',
				billingFirstName: 'STANLEY',
				billingLastName: 'TIMBREZA',
				billingAddress: '1421 KAWELU ST',
				billingCity: 'PEARL CITY',
				billingStateProvince: 'HI',
				billingPostalCode: '96782',
				settledAmount: 29.95,
				paymentCardBrand: 'M',
				startedOn: '2022-02-16T00:00:00.000Z',
				label: 'Fiserv - Pantry Champ',
				contractId: 198,
				flexibleBillingId: null,
				levelId: 1236,
				paymentCardBankIdentificationNumber: '537802',
				merchantId: '345045125886',
				paymentCardLast4: '1051',
				issuingBankLabel: 'BARCLAYS BANK',
				clientId: 144,
				status: 'VALID',
				productId: 102394,
				sourceId: 42452,
				categoryId: 65,
				membershipType: 'FTOBILL',
				billingDistributionGroupId: null,
				promotionPeriodInDays: null,
				membershipFee: 29.95,
				chargeback: 'chargeback',
				postedOn: '2022-05-07T00:00:00.000Z',
				cycle: 1,
				caseId: '721273423001',
				amount: 29.95,
				authorizationCode: '07777Z',
				code: '37',
				receivedByMerchantOn: '2022-05-09T07:40:18.000Z',
				reconciledOn: '2022-05-08T00:00:00.000Z',
				debitCreditCode: 'CREDIT',
				recordingStatus: 'Unavailable',
				customerServiceRecordingStatus: 'Unavailable',
				customerSalesRecordingStatus: 'Unavailable'
			}
		]
	},
	10: {
		results: [
			{
				Merchant: 'Monthly Mow',
				MonthEnd: 'Month Ended: 5/31/2022',
				Affiliate: "Mickey's Lawncare",
				Product: 'Grass-Eater',
				Billing: 505467,
				Fraud: 4155,
				FraudPercent: '0.0082'
			},
			{
				Merchant: 'Monthly Mow',
				Affiliate: "Mickey's Lawncare",
				MonthEnd: 'Month Ended: 4/30/2022',
				Product: 'Grass-Eater',
				Billing: 1066412,
				Fraud: 6031,
				FraudPercent: '0.0057'
			},
			{
				Merchant: 'Monthly Mow',
				MonthEnd: 'Month Ended: 3/31/2022',
				Affiliate: "Mickey's Lawncare",
				Product: 'Grass-Eater',
				Billing: 1102574,
				Fraud: 5940,
				FraudPercent: '0.0054'
			},
			{
				Merchant: 'Monthly Mow',
				MonthEnd: 'Month Ended: 2/28/2022',
				Affiliate: "Mickey's Lawncare",
				Product: 'Grass-Eater',
				Billing: 1045752,
				Fraud: 5215,
				FraudPercent: '0.0050'
			},
			{
				Merchant: 'Monthly Mow',
				MonthEnd: 'Month Ended: 1/31/2022',
				Affiliate: "Mickey's Lawncare",
				Product: 'Grass-Eater',
				Billing: 1174887,
				Fraud: 4820,
				FraudPercent: '0.0041'
			},
			{
				Merchant: 'Monthly Mow',
				MonthEnd: 'Month Ended: 12/31/2021',
				Affiliate: "Mickey's Lawncare",
				Product: 'Grass-Eater',
				Billing: 1207148,
				Fraud: 5928,
				FraudPercent: '0.0049'
			},
			{
				Merchant: 'Monthly Mow',
				MonthEnd: 'Month Ended: 11/30/2021',
				Affiliate: "Mickey's Lawncare",
				Product: 'Grass-Eater',
				Billing: 1150621,
				Fraud: 4805,
				FraudPercent: '0.0042'
			},

			{
				Merchant: 'Coffee Club',
				MonthEnd: 'Month Ended: 5/31/2022',
				Affiliate: 'Morning Grind',
				Product: 'PourOver',
				Billing: 415455,
				Fraud: 4025,
				FraudPercent: '0.0097'
			},
			{
				Merchant: 'Coffee Club',
				MonthEnd: 'Month Ended: 4/30/2022',
				Affiliate: 'Morning Grind',
				Product: 'PourOver',
				Billing: 827496,
				Fraud: 6724,
				FraudPercent: '0.0081'
			},
			{
				Merchant: 'Coffee Club',
				MonthEnd: 'Month Ended: 3/31/2022',
				Affiliate: 'Morning Grind',
				Product: 'PourOver',
				Billing: 880535,
				Fraud: 6384,
				FraudPercent: '0.0072'
			},
			{
				Merchant: 'Coffee Club',
				MonthEnd: 'Month Ended: 2/28/2022',
				Affiliate: 'Morning Grind',
				Product: 'PourOver',
				Billing: 837694,
				Fraud: 4611,
				FraudPercent: '0.0055'
			},
			{
				Merchant: 'Coffee Club',
				MonthEnd: 'Month Ended: 1/31/2022',
				Affiliate: 'Morning Grind',
				Product: 'PourOver',
				Billing: 929538,
				Fraud: 6187,
				FraudPercent: '0.0067'
			},
			{
				Merchant: 'Coffee Club',
				MonthEnd: 'Month Ended: 12/31/2021',
				Affiliate: 'Morning Grind',
				Product: 'PourOver',
				Billing: 948761,
				Fraud: 6768,
				FraudPercent: '0.0071'
			},
			{
				Merchant: 'Coffee Club',
				MonthEnd: 'Month Ended: 11/30/2021',
				Affiliate: 'Morning Grind',
				Product: 'PourOver',
				Billing: 913246,
				Fraud: 6511,
				FraudPercent: '0.0071'
			},
			{
				Merchant: 'Baby Box',
				Affiliate: 'Bjorn Yesterday',
				Product: 'Stroller RZ-456',
				MonthEnd: 'Month Ended: 5/31/2022',
				Billing: 326072,
				Fraud: 3103,
				FraudPercent: '0.0095'
			},
			{
				Merchant: 'Baby Box',
				Affiliate: 'Bjorn Yesterday',
				Product: 'Stroller RZ-456',
				MonthEnd: 'Month Ended: 4/30/2022',
				Billing: 697010,
				Fraud: 6163,
				FraudPercent: '0.0088'
			},
			{
				Merchant: 'Baby Box',
				Affiliate: 'Bjorn Yesterday',
				Product: 'Stroller RZ-456',
				MonthEnd: 'Month Ended: 3/31/2022',
				Billing: 685974,
				Fraud: 6278,
				FraudPercent: '0.0092'
			},
			{
				Merchant: 'Baby Box',
				Affiliate: 'Bjorn Yesterday',
				Product: 'Stroller RZ-456',
				MonthEnd: 'Month Ended: 2/28/2022',
				Billing: 645860,
				Fraud: 5907,
				FraudPercent: '0.0091'
			},
			{
				Merchant: 'Baby Box',
				Affiliate: 'Bjorn Yesterday',
				Product: 'Stroller RZ-456',
				MonthEnd: 'Month Ended: 1/31/2022',
				Billing: 703849,
				Fraud: 6698,
				FraudPercent: '0.0091'
			},
			{
				Merchant: 'Baby Box',
				Affiliate: 'Bjorn Yesterday',
				Product: 'Stroller RZ-456',
				MonthEnd: 'Month Ended: 12/31/2021',
				Billing: 719877,
				Fraud: 5893,
				FraudPercent: '0.0082'
			},
			{
				Merchant: 'Baby Box',
				Affiliate: 'Bjorn Yesterday',
				Product: 'Stroller RZ-456',
				MonthEnd: 'Month Ended: 11/30/2021',
				Billing: 705053,
				Fraud: 5614,
				FraudPercent: '0.0080'
			},
			{
				Merchant: 'Vino of the Month',
				MonthEnd: 'Month Ended: 5/31/2022',
				Affiliate: 'Red White And Blueberry',
				Product: 'Zinfandel',
				Billing: 266241,
				Fraud: 2471,
				FraudPercent: '0.0093'
			},
			{
				Merchant: 'Vino of the Month',
				MonthEnd: 'Month Ended: 4/30/2022',
				Affiliate: 'Red White And Blueberry',
				Product: 'Zinfandel',
				Billing: 535352,
				Fraud: 1856,
				FraudPercent: '0.0035'
			},
			{
				Merchant: 'Vino of the Month',
				MonthEnd: 'Month Ended: 3/31/2022',
				Affiliate: 'Red White And Blueberry',
				Product: 'Zinfandel',
				Billing: 574836,
				Fraud: 2279,
				FraudPercent: '0.0040'
			},
			{
				Merchant: 'Vino of the Month',
				MonthEnd: 'Month Ended: 2/28/2022',
				Affiliate: 'Red White And Blueberry',
				Product: 'Zinfandel',
				Billing: 552748,
				Fraud: 2343,
				FraudPercent: '0.0042'
			},
			{
				Merchant: 'Vino of the Month',
				MonthEnd: 'Month Ended: 1/31/2022',
				Affiliate: 'Red White And Blueberry',
				Product: 'Zinfandel',
				Billing: 621520,
				Fraud: 1342,
				FraudPercent: '0.0022'
			},
			{
				Merchant: 'Vino of the Month',
				MonthEnd: 'Month Ended: 12/31/2021',
				Affiliate: 'Red White And Blueberry',
				Product: 'Zinfandel',
				Billing: 638206,
				Fraud: 1458,
				FraudPercent: '0.0023'
			},
			{
				Merchant: 'Vino of the Month',
				MonthEnd: 'Month Ended: 11/30/2021',
				Affiliate: 'Red White And Blueberry',
				Product: 'Zinfandel',
				Billing: 637019,
				Fraud: 1733,
				FraudPercent: '0.0027'
			},
			{
				Merchant: 'Paradise Parking',
				MonthEnd: 'Month Ended: 5/31/2022',
				Product: 'Grease Boy',
				Affiliate: "Milton's Maneuvers",
				Billing: 311817,
				Fraud: 2086,
				FraudPercent: '0.0067'
			},
			{
				Merchant: 'Paradise Parking',
				MonthEnd: 'Month Ended: 4/30/2022',
				Product: 'Grease Boy',
				Affiliate: "Milton's Maneuvers",
				Billing: 580466,
				Fraud: 2404,
				FraudPercent: '0.0041'
			},
			{
				Merchant: 'Paradise Parking',
				MonthEnd: 'Month Ended: 3/31/2022',
				Product: 'Grease Boy',
				Affiliate: "Milton's Maneuvers",
				Billing: 629266,
				Fraud: 2372,
				FraudPercent: '0.0038'
			},
			{
				Merchant: 'Paradise Parking',
				MonthEnd: 'Month Ended: 2/28/2022',
				Product: 'Grease Boy',
				Affiliate: "Milton's Maneuvers",
				Billing: 604533,
				Fraud: 2914,
				FraudPercent: '0.0048'
			},
			{
				Merchant: 'Paradise Parking',
				MonthEnd: 'Month Ended: 1/31/2022',
				Affiliate: "Milton's Maneuvers",
				Product: 'Grease Boy',
				Billing: 723118,
				Fraud: 1889,
				FraudPercent: '0.0026'
			},
			{
				Merchant: 'Paradise Parking',
				MonthEnd: 'Month Ended: 12/31/2021',
				Product: 'Grease Boy',
				Affiliate: "Milton's Maneuvers",
				Billing: 716269,
				Fraud: 2170,
				FraudPercent: '0.0030'
			},
			{
				Merchant: 'Paradise Parking',
				MonthEnd: 'Month Ended: 11/30/2021',
				Product: 'Grease Boy',
				Affiliate: "Milton's Maneuvers",
				Billing: 732269,
				Fraud: 2860,
				FraudPercent: '0.0039'
			},
			{
				Merchant: 'Flowers By Friday',
				MonthEnd: 'Month Ended: 5/31/2022',
				Affiliate: "Thelma's Thorn Bushes",
				Billing: 29891,
				Fraud: 352,
				FraudPercent: '0.0118'
			},
			{
				Merchant: 'Flowers By Friday',
				MonthEnd: 'Month Ended: 5/31/2022',
				Affiliate: "Vinny's Vines",
				Product: 'Clematis',
				Billing: 5963,
				Fraud: 372,
				FraudPercent: '0.0624'
			},
			{
				Merchant: 'Flowers By Friday',
				MonthEnd: 'Month Ended: 5/31/2022',
				Affiliate: "Vinny's Vines",
				Product: 'Sweet Pea',
				Billing: 5112,
				Fraud: 354,
				FraudPercent: '0.0692'
			},
			{
				Merchant: 'Flowers By Friday',
				MonthEnd: 'Month Ended: 5/31/2022',
				Affiliate: "Vinny's Vines",
				Product: 'Black-Eyed Susan',
				Billing: 5395,
				Fraud: 372,
				FraudPercent: '0.0690'
			},
			{
				Merchant: 'Flowers By Friday',
				MonthEnd: 'Month Ended: 5/31/2022',
				Affiliate: "Vinny's Vines",
				Product: 'Mandevilla',
				Billing: 5679,
				Fraud: 391,
				FraudPercent: '0.0689'
			},
			{
				Merchant: 'Flowers By Friday',
				MonthEnd: 'Month Ended: 5/31/2022',
				Affiliate: "Vinny's Vines",
				Product: 'Star Jasmine',
				Billing: 6247,
				Fraud: 372,
				FraudPercent: '0.0595'
			},
			{
				Merchant: 'Flowers By Friday',
				MonthEnd: 'Month Ended: 5/31/2022',
				Affiliate: "Tom's Tulips",
				Product: 'Tuliper',
				Billing: 32880,
				Fraud: 385,
				FraudPercent: '0.0117'
			},
			{
				Merchant: 'Flowers By Friday',
				MonthEnd: 'Month Ended: 5/31/2022',
				Affiliate: "Patty's Petunias",
				Billing: 26902,
				Fraud: 321,
				FraudPercent: '0.0119'
			},
			{
				Merchant: 'Flowers By Friday',
				MonthEnd: 'Month Ended: 5/31/2022',
				Affiliate: "Roger's Roses",
				Product: 'Radical Rose',
				Billing: 31386,
				Fraud: 289,
				FraudPercent: '0.0092'
			},
			{
				Merchant: 'Flowers By Friday',
				MonthEnd: 'Month Ended: 4/30/2022',
				Affiliate: "Thelma's Thorn Bushes",
				Billing: 56459,
				Fraud: 621,
				FraudPercent: '0.0110'
			},
			{
				Merchant: 'Flowers By Friday',
				MonthEnd: 'Month Ended: 4/30/2022',
				Affiliate: "Vinny's Vines",
				Product: 'Clematis',
				Billing: 12480,
				Fraud: 118,
				FraudPercent: '0.0095'
			},
			{
				Merchant: 'Flowers By Friday',
				MonthEnd: 'Month Ended: 4/30/2022',
				Affiliate: "Vinny's Vines",
				Product: 'Sweet Pea',
				Billing: 10697,
				Fraud: 112,
				FraudPercent: '0.0105'
			},
			{
				Merchant: 'Flowers By Friday',
				MonthEnd: 'Month Ended: 4/30/2022',
				Affiliate: "Vinny's Vines",
				Product: 'Black-Eyed Susan',
				Billing: 11292,
				Fraud: 118,
				FraudPercent: '0.0104'
			},
			{
				Merchant: 'Flowers By Friday',
				MonthEnd: 'Month Ended: 4/30/2022',
				Affiliate: "Vinny's Vines",
				Product: 'Mandevilla',
				Billing: 11886,
				Fraud: 124,
				FraudPercent: '0.0104'
			},
			{
				Merchant: 'Flowers By Friday',
				MonthEnd: 'Month Ended: 4/30/2022',
				Affiliate: "Vinny's Vines",
				Product: 'Star Jasmine',
				Billing: 13075,
				Fraud: 118,
				FraudPercent: '0.0090'
			},
			{
				Merchant: 'Flowers By Friday',
				MonthEnd: 'Month Ended: 4/30/2022',
				Affiliate: "Tom's Tulips",
				Product: 'Tuliper',
				Billing: 62401,
				Fraud: 683,
				FraudPercent: '0.0109'
			},
			{
				Merchant: 'Flowers By Friday',
				MonthEnd: 'Month Ended: 4/30/2022',
				Affiliate: "Patty's Petunias",
				Billing: 56459,
				Fraud: 559,
				FraudPercent: '0.0099'
			},
			{
				Merchant: 'Flowers By Friday',
				MonthEnd: 'Month Ended: 4/30/2022',
				Affiliate: "Roger's Roses",
				Product: 'Radical Rose',
				Billing: 62402,
				Fraud: 652,
				FraudPercent: '0.0104'
			},
			{
				Merchant: 'Flowers By Friday',
				MonthEnd: 'Month Ended: 3/31/2022',
				Affiliate: "Thelma's Thorn Bushes",
				Billing: 57270,
				Fraud: 537,
				FraudPercent: '0.0094'
			},
			{
				Merchant: 'Flowers By Friday',
				MonthEnd: 'Month Ended: 3/31/2022',
				Affiliate: "Vinny's Vines",
				Product: 'Clematis',
				Billing: 12660,
				Fraud: 102,
				FraudPercent: '0.0081'
			},
			{
				Merchant: 'Flowers By Friday',
				MonthEnd: 'Month Ended: 3/31/2022',
				Affiliate: "Vinny's Vines",
				Product: 'Sweet Pea',
				Billing: 10851,
				Fraud: 97,
				FraudPercent: '0.0089'
			},
			{
				Merchant: 'Flowers By Friday',
				MonthEnd: 'Month Ended: 3/31/2022',
				Affiliate: "Vinny's Vines",
				Product: 'Black-Eyed Susan',
				Billing: 11454,
				Fraud: 103,
				FraudPercent: '0.0090'
			},
			{
				Merchant: 'Flowers By Friday',
				MonthEnd: 'Month Ended: 3/31/2022',
				Affiliate: "Vinny's Vines",
				Product: 'Mandevilla',
				Billing: 12057,
				Fraud: 107,
				FraudPercent: '0.0089'
			},
			{
				Merchant: 'Flowers By Friday',
				MonthEnd: 'Month Ended: 3/31/2022',
				Affiliate: "Vinny's Vines",
				Product: 'Star Jasmine',
				Billing: 13262,
				Fraud: 102,
				FraudPercent: '0.0077'
			},
			{
				Merchant: 'Flowers By Friday',
				MonthEnd: 'Month Ended: 3/31/2022',
				Affiliate: "Tom's Tulips",
				Product: 'Tuliper',
				Billing: 63298,
				Fraud: 591,
				FraudPercent: '0.0093'
			},
			{
				Merchant: 'Flowers By Friday',
				MonthEnd: 'Month Ended: 3/31/2022',
				Affiliate: "Patty's Petunias",
				Billing: 57269,
				Fraud: 484,
				FraudPercent: '0.0085'
			},
			{
				Merchant: 'Flowers By Friday',
				MonthEnd: 'Month Ended: 3/31/2022',
				Affiliate: "Roger's Roses",
				Product: 'Radical Rose',
				Billing: 63298,
				Fraud: 564,
				FraudPercent: '0.0089'
			},
			{
				Merchant: 'Flowers By Friday',
				MonthEnd: 'Month Ended: 2/28/2022',
				Affiliate: "Thelma's Thorn Bushes",
				Billing: 55722,
				Fraud: 507,
				FraudPercent: '0.0091'
			},
			{
				Merchant: 'Flowers By Friday',
				MonthEnd: 'Month Ended: 2/28/2022',
				Affiliate: "Vinny's Vines",
				Product: 'Clematis',
				Billing: 12318,
				Fraud: 96,
				FraudPercent: '0.0078'
			},
			{
				Merchant: 'Flowers By Friday',
				MonthEnd: 'Month Ended: 2/28/2022',
				Affiliate: "Vinny's Vines",
				Product: 'Sweet Pea',
				Billing: 10558,
				Fraud: 92,
				FraudPercent: '0.0087'
			},
			{
				Merchant: 'Flowers By Friday',
				MonthEnd: 'Month Ended: 2/28/2022',
				Affiliate: "Vinny's Vines",
				Product: 'Black-Eyed Susan',
				Billing: 11144,
				Fraud: 96,
				FraudPercent: '0.0086'
			},
			{
				Merchant: 'Flowers By Friday',
				MonthEnd: 'Month Ended: 2/28/2022',
				Affiliate: "Vinny's Vines",
				Product: 'Mandevilla',
				Billing: 11731,
				Fraud: 102,
				FraudPercent: '0.0087'
			},
			{
				Merchant: 'Flowers By Friday',
				MonthEnd: 'Month Ended: 2/28/2022',
				Affiliate: "Vinny's Vines",
				Product: 'Star Jasmine',
				Billing: 12904,
				Fraud: 96,
				FraudPercent: '0.0074'
			},
			{
				Merchant: 'Flowers By Friday',
				MonthEnd: 'Month Ended: 2/28/2022',
				Affiliate: "Tom's Tulips",
				Product: 'Tuliper',
				Billing: 61588,
				Fraud: 559,
				FraudPercent: '0.0091'
			},
			{
				Merchant: 'Flowers By Friday',
				MonthEnd: 'Month Ended: 2/28/2022',
				Affiliate: "Patty's Petunias",
				Billing: 55722,
				Fraud: 456,
				FraudPercent: '0.0082'
			},
			{
				Merchant: 'Flowers By Friday',
				MonthEnd: 'Month Ended: 2/28/2022',
				Affiliate: "Roger's Roses",
				Product: 'Radical Rose',
				Billing: 61587,
				Fraud: 532,
				FraudPercent: '0.0086'
			},
			{
				Merchant: 'Flowers By Friday',
				MonthEnd: 'Month Ended: 1/31/2022',
				Affiliate: "Thelma's Thorn Bushes",
				Billing: 63091,
				Fraud: 539,
				FraudPercent: '0.0085'
			},
			{
				Merchant: 'Flowers By Friday',
				MonthEnd: 'Month Ended: 1/31/2022',
				Affiliate: "Vinny's Vines",
				Product: 'Clematis',
				Billing: 13946,
				Fraud: 102,
				FraudPercent: '0.0073'
			},
			{
				Merchant: 'Flowers By Friday',
				MonthEnd: 'Month Ended: 1/31/2022',
				Affiliate: "Vinny's Vines",
				Product: 'Sweet Pea',
				Billing: 11954,
				Fraud: 97,
				FraudPercent: '0.0081'
			},
			{
				Merchant: 'Flowers By Friday',
				MonthEnd: 'Month Ended: 1/31/2022',
				Affiliate: "Vinny's Vines",
				Product: 'Black-Eyed Susan',
				Billing: 12618,
				Fraud: 102,
				FraudPercent: '0.0081'
			},
			{
				Merchant: 'Flowers By Friday',
				MonthEnd: 'Month Ended: 1/31/2022',
				Affiliate: "Vinny's Vines",
				Product: 'Mandevilla',
				Billing: 13283,
				Fraud: 109,
				FraudPercent: '0.0082'
			},
			{
				Merchant: 'Flowers By Friday',
				MonthEnd: 'Month Ended: 1/31/2022',
				Affiliate: "Vinny's Vines",
				Product: 'Star Jasmine',
				Billing: 14610,
				Fraud: 102,
				FraudPercent: '0.0070'
			},
			{
				Merchant: 'Flowers By Friday',
				MonthEnd: 'Month Ended: 1/31/2022',
				Affiliate: "Tom's Tulips",
				Product: 'Tuliper',
				Billing: 69732,
				Fraud: 593,
				FraudPercent: '0.0085'
			},
			{
				Merchant: 'Flowers By Friday',
				MonthEnd: 'Month Ended: 1/31/2022',
				Affiliate: "Patty's Petunias",
				Billing: 63091,
				Fraud: 485,
				FraudPercent: '0.0077'
			},
			{
				Merchant: 'Flowers By Friday',
				MonthEnd: 'Month Ended: 1/31/2022',
				Affiliate: "Roger's Roses",
				Product: 'Radical Rose',
				Billing: 69732,
				Fraud: 566,
				FraudPercent: '0.0081'
			},
			{
				Merchant: 'Flowers By Friday',
				MonthEnd: 'Month Ended: 12/31/2021',
				Affiliate: "Thelma's Thorn Bushes",
				Billing: 64538,
				Fraud: 428,
				FraudPercent: '0.0066'
			},
			{
				Merchant: 'Flowers By Friday',
				MonthEnd: 'Month Ended: 12/31/2021',
				Affiliate: "Vinny's Vines",
				Product: 'Clematis',
				Billing: 14267,
				Fraud: 81,
				FraudPercent: '0.0057'
			},
			{
				Merchant: 'Flowers By Friday',
				MonthEnd: 'Month Ended: 12/31/2021',
				Affiliate: "Vinny's Vines",
				Product: 'Sweet Pea',
				Billing: 12228,
				Fraud: 78,
				FraudPercent: '0.0064'
			},
			{
				Merchant: 'Flowers By Friday',
				MonthEnd: 'Month Ended: 12/31/2021',
				Affiliate: "Vinny's Vines",
				Product: 'Black-Eyed Susan',
				Billing: 12908,
				Fraud: 81,
				FraudPercent: '0.0063'
			},
			{
				Merchant: 'Flowers By Friday',
				MonthEnd: 'Month Ended: 12/31/2021',
				Affiliate: "Vinny's Vines",
				Product: 'Mandevilla',
				Billing: 13587,
				Fraud: 86,
				FraudPercent: '0.0063'
			},
			{
				Merchant: 'Flowers By Friday',
				MonthEnd: 'Month Ended: 12/31/2021',
				Affiliate: "Vinny's Vines",
				Product: 'Star Jasmine',
				Billing: 14946,
				Fraud: 81,
				FraudPercent: '0.0054'
			},
			{
				Merchant: 'Flowers By Friday',
				MonthEnd: 'Month Ended: 12/31/2021',
				Affiliate: "Tom's Tulips",
				Product: 'Tuliper',
				Billing: 71333,
				Fraud: 471,
				FraudPercent: '0.0066'
			},
			{
				Merchant: 'Flowers By Friday',
				MonthEnd: 'Month Ended: 12/31/2021',
				Affiliate: "Patty's Petunias",
				Billing: 64539,
				Fraud: 385,
				FraudPercent: '0.0060'
			},
			{
				Merchant: 'Flowers By Friday',
				MonthEnd: 'Month Ended: 12/31/2021',
				Affiliate: "Roger's Roses",
				Product: 'Radical Rose',
				Billing: 71333,
				Fraud: 449,
				FraudPercent: '0.0063'
			},
			{
				Merchant: 'Flowers By Friday',
				MonthEnd: 'Month Ended: 11/30/2021',
				Affiliate: "Thelma's Thorn Bushes",
				Billing: 55742,
				Fraud: 402,
				FraudPercent: '0.0072'
			},
			{
				Merchant: 'Flowers By Friday',
				MonthEnd: 'Month Ended: 11/30/2021',
				Affiliate: "Vinny's Vines",
				Product: 'Clematis',
				Billing: 12322,
				Fraud: 76,
				FraudPercent: '0.0062'
			},
			{
				Merchant: 'Flowers By Friday',
				MonthEnd: 'Month Ended: 11/30/2021',
				Affiliate: "Vinny's Vines",
				Product: 'Sweet Pea',
				Billing: 10562,
				Fraud: 73,
				FraudPercent: '0.0069'
			},
			{
				Merchant: 'Flowers By Friday',
				MonthEnd: 'Month Ended: 11/30/2021',
				Affiliate: "Vinny's Vines",
				Product: 'Black-Eyed Susan',
				Billing: 11148,
				Fraud: 76,
				FraudPercent: '0.0068'
			},
			{
				Merchant: 'Flowers By Friday',
				MonthEnd: 'Month Ended: 11/30/2021',
				Affiliate: "Vinny's Vines",
				Product: 'Mandevilla',
				Billing: 11735,
				Fraud: 81,
				FraudPercent: '0.0069'
			},
			{
				Merchant: 'Flowers By Friday',
				MonthEnd: 'Month Ended: 11/30/2021',
				Affiliate: "Vinny's Vines",
				Product: 'Star Jasmine',
				Billing: 12909,
				Fraud: 76,
				FraudPercent: '0.0059'
			},
			{
				Merchant: 'Flowers By Friday',
				MonthEnd: 'Month Ended: 11/30/2021',
				Affiliate: "Tom's Tulips",
				Product: 'Tuliper',
				Billing: 61610,
				Fraud: 442,
				FraudPercent: '0.0072'
			},
			{
				Merchant: 'Flowers By Friday',
				MonthEnd: 'Month Ended: 11/30/2021',
				Affiliate: "Patty's Petunias",
				Product: 'Pretty Floral',
				Billing: 55742,
				Fraud: 362,
				FraudPercent: '0.0065'
			},
			{
				Merchant: 'Flowers By Friday',
				MonthEnd: 'Month Ended: 11/30/2021',
				Affiliate: "Roger's Roses",
				Product: 'Radical Rose',
				Billing: 61610,
				Fraud: 422,
				FraudPercent: '0.0068'
			},

			{
				Merchant: 'Cigar Central',
				MonthEnd: 'Month Ended: 5/31/2022',
				Affiliate: 'Smokey Robinson',
				Product: 'Puffer',
				Billing: 138483,
				Fraud: 715,
				FraudPercent: '0.0052'
			},
			{
				Merchant: 'Cigar Central',
				MonthEnd: 'Month Ended: 4/30/2022',
				Affiliate: 'Smokey Robinson',
				Product: 'Puffer',
				Billing: 296822,
				Fraud: 754,
				FraudPercent: '0.0025'
			},
			{
				Merchant: 'Cigar Central',
				MonthEnd: 'Month Ended: 3/31/2022',
				Affiliate: 'Smokey Robinson',
				Product: 'Puffer',
				Billing: 322717,
				Fraud: 1396,
				FraudPercent: '0.0043'
			},
			{
				Merchant: 'Cigar Central',
				MonthEnd: 'Month Ended: 2/28/2022',
				Affiliate: 'Smokey Robinson',
				Product: 'Puffer',
				Billing: 301224,
				Fraud: 1093,
				FraudPercent: '0.0036'
			},
			{
				Merchant: 'Cigar Central',
				MonthEnd: 'Month Ended: 1/31/2022',
				Affiliate: 'Smokey Robinson',
				Product: 'Puffer',
				Billing: 354624,
				Fraud: 1348,
				FraudPercent: '0.0038'
			},
			{
				Merchant: 'Cigar Central',
				MonthEnd: 'Month Ended: 12/31/2021',
				Affiliate: 'Smokey Robinson',
				Product: 'Puffer',
				Billing: 339159,
				Fraud: 1072,
				FraudPercent: '0.0032'
			},
			{
				Merchant: 'Cigar Central',
				MonthEnd: 'Month Ended: 11/30/2021',
				Affiliate: 'Smokey Robinson',
				Product: 'Puffer',
				Billing: 329335,
				Fraud: 1211,
				FraudPercent: '0.0037'
			},
			{
				Merchant: 'Squeaky Clean Carwash',
				MonthEnd: 'Month Ended: 5/31/2022',
				Affiliate: 'Scrubby',
				Product: 'Tire Wax',
				Billing: 159765,
				Fraud: 753,
				FraudPercent: '0.0047'
			},
			{
				Merchant: 'Squeaky Clean Carwash',
				MonthEnd: 'Month Ended: 4/30/2022',
				Affiliate: 'Scrubby',
				Product: 'Tire Wax',
				Billing: 321094,
				Fraud: 1248,
				FraudPercent: '0.0039'
			},
			{
				Merchant: 'Squeaky Clean Carwash',
				MonthEnd: 'Month Ended: 3/31/2022',
				Affiliate: 'Scrubby',
				Product: 'Tire Wax',
				Billing: 331582,
				Fraud: 2052,
				FraudPercent: '0.0062'
			},
			{
				Merchant: 'Squeaky Clean Carwash',
				MonthEnd: 'Month Ended: 2/28/2022',
				Affiliate: 'Scrubby',
				Product: 'Tire Wax',
				Billing: 313499,
				Fraud: 1616,
				FraudPercent: '0.0052'
			},
			{
				Merchant: 'Squeaky Clean Carwash',
				MonthEnd: 'Month Ended: 1/31/2022',
				Affiliate: 'Scrubby',
				Product: 'Tire Wax',
				Billing: 353905,
				Fraud: 1271,
				FraudPercent: '0.0036'
			},
			{
				Merchant: 'Squeaky Clean Carwash',
				MonthEnd: 'Month Ended: 12/31/2021',
				Affiliate: 'Scrubby',
				Product: 'Tire Wax',
				Billing: 357781,
				Fraud: 1785,
				FraudPercent: '0.0050'
			},
			{
				Merchant: 'Squeaky Clean Carwash',
				MonthEnd: 'Month Ended: 11/30/2021',
				Affiliate: 'Scrubby',
				Product: 'Tire Wax',
				Billing: 346562,
				Fraud: 1021,
				FraudPercent: '0.0029'
			}
		]
	},
	12: {
		results: [
			{
				Merchant: 'Monthly Mow',
				WeekEnd: 'Week Ended: 5/14/2022',
				Billing: 247679,
				Fraud: 2119,
				FraudPercent: '0.0086',
				Index: '0.89'
			},
			{
				Merchant: 'Monthly Mow',
				WeekEnd: 'Week Ended: 5/7/2022',
				Billing: 257788,
				Fraud: 2036,
				FraudPercent: '0.0079',
				Index: '0.93'
			},
			{
				Merchant: 'Monthly Mow',
				WeekEnd: 'Week Ended: 4/30/2022',
				Billing: 287931,
				Fraud: 1447,
				FraudPercent: '0.0050',
				Index: '0.84'
			},
			{
				Merchant: 'Monthly Mow',
				WeekEnd: 'Week Ended: 4/23/2022',
				Billing: 245275,
				Fraud: 1508,
				FraudPercent: '0.0061',
				Index: '0.99'
			},
			{
				Merchant: 'Monthly Mow',
				WeekEnd: 'Week Ended: 4/16/2022',
				Billing: 266603,
				Fraud: 1387,
				FraudPercent: '0.0052',
				Index: '0.86'
			},
			{
				Merchant: 'Monthly Mow',
				WeekEnd: 'Week Ended: 4/9/2022',
				Billing: 234611,
				Fraud: 1508,
				FraudPercent: '0.0064',
				Index: '1.03'
			},
			{
				Merchant: 'Monthly Mow',
				WeekEnd: 'Week Ended: 4/2/2022',
				Billing: 206733,
				Fraud: 1025,
				FraudPercent: '0.0050',
				Index: '0.84'
			},
			{
				Merchant: 'Coffee Club',
				WeekEnd: 'Week Ended: 5/14/2022',
				Billing: 216037,
				Fraud: 1932,
				FraudPercent: '0.0089',
				Index: '0.93'
			},
			{
				Merchant: 'Coffee Club',
				WeekEnd: 'Week Ended: 5/7/2022',
				Billing: 199419,
				Fraud: 2093,
				FraudPercent: '0.0105',
				Index: '1.24'
			},
			{
				Merchant: 'Coffee Club',
				WeekEnd: 'Week Ended: 4/30/2022',
				Billing: 198599,
				Fraud: 1614,
				FraudPercent: '0.0081',
				Index: '1.36'
			},
			{
				Merchant: 'Coffee Club',
				WeekEnd: 'Week Ended: 4/23/2022',
				Billing: 206874,
				Fraud: 1614,
				FraudPercent: '0.0078',
				Index: '1.25'
			},
			{
				Merchant: 'Coffee Club',
				WeekEnd: 'Week Ended: 4/16/2022',
				Billing: 190324,
				Fraud: 1614,
				FraudPercent: '0.0085',
				Index: '1.40'
			},
			{
				Merchant: 'Coffee Club',
				WeekEnd: 'Week Ended: 4/9/2022',
				Billing: 206874,
				Fraud: 1614,
				FraudPercent: '0.0078',
				Index: '1.25'
			},
			{
				Merchant: 'Coffee Club',
				WeekEnd: 'Week Ended: 4/2/2022',
				Billing: 151892,
				Fraud: 1197,
				FraudPercent: '0.0079',
				Index: '1.33'
			},
			{
				Merchant: 'Baby Box',
				WeekEnd: 'Week Ended: 5/14/2022',
				Billing: 166297,
				Fraud: 1521,
				FraudPercent: '0.0091',
				Index: '0.95'
			},
			{
				Merchant: 'Baby Box',
				WeekEnd: 'Week Ended: 5/7/2022',
				Billing: 159775,
				Fraud: 1583,
				FraudPercent: '0.0099',
				Index: '1.17'
			},
			{
				Merchant: 'Baby Box',
				WeekEnd: 'Week Ended: 4/30/2022',
				Billing: 167283,
				Fraud: 1541,
				FraudPercent: '0.0092',
				Index: '1.54'
			},
			{
				Merchant: 'Baby Box',
				WeekEnd: 'Week Ended: 4/23/2022',
				Billing: 167283,
				Fraud: 1541,
				FraudPercent: '0.0092',
				Index: '1.48'
			},
			{
				Merchant: 'Baby Box',
				WeekEnd: 'Week Ended: 4/16/2022',
				Billing: 167283,
				Fraud: 1602,
				FraudPercent: '0.0096',
				Index: '1.59'
			},
			{
				Merchant: 'Baby Box',
				WeekEnd: 'Week Ended: 4/9/2022',
				Billing: 167283,
				Fraud: 1418,
				FraudPercent: '0.0085',
				Index: '1.36'
			},
			{
				Merchant: 'Baby Box',
				WeekEnd: 'Week Ended: 4/2/2022',
				Billing: 123475,
				Fraud: 1130,
				FraudPercent: '0.0092',
				Index: '1.55'
			},

			{
				Merchant: 'Vino of the Month',
				WeekEnd: 'Week Ended: 5/14/2022',
				Billing: 127796,
				Fraud: 1285,
				FraudPercent: '0.0101',
				Index: '1.05'
			},
			{
				Merchant: 'Vino of the Month',
				WeekEnd: 'Week Ended: 5/7/2022',
				Billing: 138445,
				Fraud: 1186,
				FraudPercent: '0.0086',
				Index: '1.01'
			},
			{
				Merchant: 'Vino of the Month',
				WeekEnd: 'Week Ended: 4/30/2022',
				Billing: 133838,
				Fraud: 408,
				FraudPercent: '0.0031',
				Index: '0.51'
			},
			{
				Merchant: 'Vino of the Month',
				WeekEnd: 'Week Ended: 4/23/2022',
				Billing: 133838,
				Fraud: 464,
				FraudPercent: '0.0035',
				Index: '0.56'
			},
			{
				Merchant: 'Vino of the Month',
				WeekEnd: 'Week Ended: 4/16/2022',
				Billing: 139192,
				Fraud: 501,
				FraudPercent: '0.0036',
				Index: '0.60'
			},
			{
				Merchant: 'Vino of the Month',
				WeekEnd: 'Week Ended: 4/9/2022',
				Billing: 123131,
				Fraud: 445,
				FraudPercent: '0.0036',
				Index: '0.58'
			},
			{
				Merchant: 'Vino of the Month',
				WeekEnd: 'Week Ended: 4/2/2022',
				Billing: 112093,
				Fraud: 427,
				FraudPercent: '0.0038',
				Index: '0.64'
			},
			{
				Merchant: 'Paradise Parking',
				WeekEnd: 'Week Ended: 5/14/2022',
				Billing: 159027,
				Fraud: 1022,
				FraudPercent: '0.0064',
				Index: '0.67'
			},
			{
				Merchant: 'Paradise Parking',
				WeekEnd: 'Week Ended: 5/7/2022',
				Billing: 152790,
				Fraud: 1064,
				FraudPercent: '0.0070',
				Index: '0.82'
			},
			{
				Merchant: 'Paradise Parking',
				WeekEnd: 'Week Ended: 4/30/2022',
				Billing: 127703,
				Fraud: 625,
				FraudPercent: '0.0049',
				Index: '0.82'
			},
			{
				Merchant: 'Paradise Parking',
				WeekEnd: 'Week Ended: 4/23/2022',
				Billing: 145117,
				Fraud: 529,
				FraudPercent: '0.0036',
				Index: '0.59'
			},
			{
				Merchant: 'Paradise Parking',
				WeekEnd: 'Week Ended: 4/16/2022',
				Billing: 156726,
				Fraud: 577,
				FraudPercent: '0.0037',
				Index: '0.61'
			},
			{
				Merchant: 'Paradise Parking',
				WeekEnd: 'Week Ended: 4/9/2022',
				Billing: 139312,
				Fraud: 625,
				FraudPercent: '0.0045',
				Index: '0.72'
			},
			{
				Merchant: 'Paradise Parking',
				WeekEnd: 'Week Ended: 4/2/2022',
				Billing: 127426,
				Fraud: 445,
				FraudPercent: '0.0035',
				Index: '0.59'
			},
			{
				Merchant: 'Flowers By Friday',
				WeekEnd: 'Week Ended: 5/14/2022',
				Billing: 76222,
				Fraud: 2278,
				FraudPercent: '0.0299',
				Index: '3.12'
			},
			{
				Merchant: 'Flowers By Friday',
				WeekEnd: 'Week Ended: 5/7/2022',
				Billing: 73233,
				Fraud: 930,
				FraudPercent: '0.0127',
				Index: '1.50'
			},
			{
				Merchant: 'Flowers By Friday',
				WeekEnd: 'Week Ended: 4/30/2022',
				Billing: 77259,
				Fraud: 714,
				FraudPercent: '0.0092',
				Index: '1.55'
			},
			{
				Merchant: 'Flowers By Friday',
				WeekEnd: 'Week Ended: 4/23/2022',
				Billing: 65373,
				Fraud: 745,
				FraudPercent: '0.0114',
				Index: '1.83'
			},
			{
				Merchant: 'Flowers By Friday',
				WeekEnd: 'Week Ended: 4/16/2022',
				Billing: 71316,
				Fraud: 683,
				FraudPercent: '0.0096',
				Index: '1.59'
			},
			{
				Merchant: 'Flowers By Friday',
				WeekEnd: 'Week Ended: 4/9/2022',
				Billing: 77259,
				Fraud: 776,
				FraudPercent: '0.0100',
				Index: '1.61'
			},
			{
				Merchant: 'Flowers By Friday',
				WeekEnd: 'Week Ended: 4/2/2022',
				Billing: 54255,
				Fraud: 443,
				FraudPercent: '0.0082',
				Index: '1.38'
			},
			{
				Merchant: 'Cigar Central',
				WeekEnd: 'Week Ended: 5/14/2022',
				Billing: 65087,
				Fraud: 379,
				FraudPercent: '0.0058',
				Index: '0.61'
			},
			{
				Merchant: 'Cigar Central',
				WeekEnd: 'Week Ended: 5/7/2022',
				Billing: 73396,
				Fraud: 336,
				FraudPercent: '0.0046',
				Index: '0.54'
			},
			{
				Merchant: 'Cigar Central',
				WeekEnd: 'Week Ended: 4/30/2022',
				Billing: 68269,
				Fraud: 188,
				FraudPercent: '0.0028',
				Index: '0.46'
			},
			{
				Merchant: 'Cigar Central',
				WeekEnd: 'Week Ended: 4/23/2022',
				Billing: 71237,
				Fraud: 166,
				FraudPercent: '0.0023',
				Index: '0.37'
			},
			{
				Merchant: 'Cigar Central',
				WeekEnd: 'Week Ended: 4/16/2022',
				Billing: 65301,
				Fraud: 181,
				FraudPercent: '0.0028',
				Index: '0.46'
			},
			{
				Merchant: 'Cigar Central',
				WeekEnd: 'Week Ended: 4/9/2022',
				Billing: 74205,
				Fraud: 196,
				FraudPercent: '0.0026',
				Index: '0.42'
			},
			{
				Merchant: 'Cigar Central',
				WeekEnd: 'Week Ended: 4/2/2022',
				Billing: 53248,
				Fraud: 251,
				FraudPercent: '0.0047',
				Index: '0.80'
			},
			{
				Merchant: 'Squeaky Clean Carwash',
				WeekEnd: 'Week Ended: 5/14/2022',
				Billing: 80681,
				Fraud: 373,
				FraudPercent: '0.0046',
				Index: '0.48'
			},
			{
				Merchant: 'Squeaky Clean Carwash',
				WeekEnd: 'Week Ended: 5/7/2022',
				Billing: 79084,
				Fraud: 380,
				FraudPercent: '0.0048',
				Index: '0.57'
			},
			{
				Merchant: 'Squeaky Clean Carwash',
				WeekEnd: 'Week Ended: 4/30/2022',
				Billing: 80274,
				Fraud: 287,
				FraudPercent: '0.0036',
				Index: '0.60'
			},
			{
				Merchant: 'Squeaky Clean Carwash',
				WeekEnd: 'Week Ended: 4/23/2022',
				Billing: 70641,
				Fraud: 312,
				FraudPercent: '0.0044',
				Index: '0.71'
			},
			{
				Merchant: 'Squeaky Clean Carwash',
				WeekEnd: 'Week Ended: 4/16/2022',
				Billing: 77063,
				Fraud: 299,
				FraudPercent: '0.0039',
				Index: '0.64'
			},
			{
				Merchant: 'Squeaky Clean Carwash',
				WeekEnd: 'Week Ended: 4/9/2022',
				Billing: 83484,
				Fraud: 324,
				FraudPercent: '0.0039',
				Index: '0.62'
			},
			{
				Merchant: 'Squeaky Clean Carwash',
				WeekEnd: 'Week Ended: 4/2/2022',
				Billing: 59685,
				Fraud: 339,
				FraudPercent: '0.0057',
				Index: '0.96'
			},
			{
				Merchant: 'Total',
				WeekEnd: 'Week Ended: 5/14/2022',
				Billing: 1138826,
				Fraud: 10909,
				FraudPercent: '0.0096',
				Index: '1.00'
			},
			{
				Merchant: 'Total',
				WeekEnd: 'Week Ended: 5/7/2022',
				Billing: 1133930,
				Fraud: 9608,
				FraudPercent: '0.0085',
				Index: '1.00'
			},
			{
				Merchant: 'Total',
				WeekEnd: 'Week Ended: 4/30/2022',
				Billing: 1141156,
				Fraud: 6824,
				FraudPercent: '0.0060',
				Index: '1.00'
			},
			{
				Merchant: 'Total',
				WeekEnd: 'Week Ended: 4/23/2022',
				Billing: 1105638,
				Fraud: 6879,
				FraudPercent: '0.0062',
				Index: '1.00'
			},
			{
				Merchant: 'Total',
				WeekEnd: 'Week Ended: 4/16/2022',
				Billing: 1133808,
				Fraud: 6844,
				FraudPercent: '0.0060',
				Index: '1.00'
			},
			{
				Merchant: 'Total',
				WeekEnd: 'Week Ended: 4/9/2022',
				Billing: 1106159,
				Fraud: 6906,
				FraudPercent: '0.0062',
				Index: '1.00'
			},
			{
				Merchant: 'Total',
				WeekEnd: 'Week Ended: 4/2/2022',
				Billing: 888807,
				Fraud: 5257,
				FraudPercent: '0.0059',
				Index: '1.00'
			}
		]
	},
	13: {
		results: [
			{
				Merchant: 'Monthly Mow',
				Bank: 'Wellner & Assoc. Bank',
				BIN: '434129',
				MonthEnd: 'Month Ended: 5/31/2022',
				Credit: 2935,
				Debit: 2753,
				Prepaid: 3,
				CreditPercent: '0.516',
				DebitPercent: '0.484',
				PrepaidPercent: '0.001'
			},
			{
				Merchant: 'Monthly Mow',
				Bank: 'Wellner & Assoc. Bank',
				BIN: '434129',
				MonthEnd: 'Month Ended: 4/30/2022',
				Credit: 5312,
				Debit: 4904,
				Prepaid: 11,
				CreditPercent: '0.519',
				DebitPercent: '0.480',
				PrepaidPercent: '0.001'
			},
			{
				Merchant: 'Monthly Mow',
				Bank: 'Wellner & Assoc. Bank',
				BIN: '434129',
				MonthEnd: 'Month Ended: 3/31/2022',
				Credit: 5495,
				Debit: 5121,
				Prepaid: 11,
				CreditPercent: '0.517',
				DebitPercent: '0.482',
				PrepaidPercent: '0.001'
			},
			{
				Merchant: 'Coffee Club',
				Bank: 'Quiet Place Trust',
				BIN: '442287',
				MonthEnd: 'Month Ended: 5/31/2022',
				Credit: 948,
				Debit: 3542,
				Prepaid: 49,
				CreditPercent: '0.209',
				DebitPercent: '0.780',
				PrepaidPercent: '0.011'
			},
			{
				Merchant: 'Coffee Club',
				Bank: 'Quiet Place Trust',
				BIN: '442287',
				MonthEnd: 'Month Ended: 4/30/2022',
				Credit: 1751,
				Debit: 6434,
				Prepaid: 92,
				CreditPercent: '0.212',
				DebitPercent: '0.777',
				PrepaidPercent: '0.011'
			},
			{
				Merchant: 'Coffee Club',
				Bank: 'Quiet Place Trust',
				BIN: '442287',
				MonthEnd: 'Month Ended: 3/31/2022',
				Credit: 1833,
				Debit: 6819,
				Prepaid: 110,
				CreditPercent: '0.209',
				DebitPercent: '0.778',
				PrepaidPercent: '0.013'
			},
			{
				Merchant: 'Vino of the Month',
				Bank: 'UNCF Bank & Trust',
				BIN: '533122',
				MonthEnd: 'Month Ended: 5/31/2022',
				Credit: 992,
				Debit: 1606,
				Prepaid: 45,
				CreditPercent: '0.375',
				DebitPercent: '0.608',
				PrepaidPercent: '0.017'
			},
			{
				Merchant: 'Vino of the Month',
				Bank: 'UNCF Bank & Trust',
				BIN: '533122',
				MonthEnd: 'Month Ended: 4/30/2022',
				Credit: 1862,
				Debit: 2983,
				Prepaid: 93,
				CreditPercent: '0.377',
				DebitPercent: '0.604',
				PrepaidPercent: '0.019'
			},
			{
				Merchant: 'Vino of the Month',
				Bank: 'UNCF Bank & Trust',
				BIN: '533122',
				MonthEnd: 'Month Ended: 3/31/2022',
				Credit: 2037,
				Debit: 3634,
				Prepaid: 125,
				CreditPercent: '0.351',
				DebitPercent: '0.627',
				PrepaidPercent: '0.022'
			},
			{
				Merchant: 'Paradise Parking',
				Bank: 'Queens Keep Bank',
				BIN: '535543',
				MonthEnd: 'Month Ended: 5/31/2022',
				Credit: 719,
				Debit: 2061,
				Prepaid: 36,
				CreditPercent: '0.255',
				DebitPercent: '0.732',
				PrepaidPercent: '0.013'
			},
			{
				Merchant: 'Paradise Parking',
				Bank: 'Queens Keep Bank',
				BIN: '535543',
				MonthEnd: 'Month Ended: 4/30/2022',
				Credit: 1284,
				Debit: 3571,
				Prepaid: 64,
				CreditPercent: '0.261',
				DebitPercent: '0.726',
				PrepaidPercent: '0.013'
			},
			{
				Merchant: 'Paradise Parking',
				Bank: 'Queens Keep Bank',
				BIN: '535543',
				MonthEnd: 'Month Ended: 3/31/2022',
				Credit: 1326,
				Debit: 3935,
				Prepaid: 68,
				CreditPercent: '0.249',
				DebitPercent: '0.738',
				PrepaidPercent: '0.013'
			},
			{
				Merchant: 'Flowers by Friday',
				Bank: 'Northern Credit Union',
				BIN: '444324',
				MonthEnd: 'Month Ended: 5/31/2022',
				Credit: 1156,
				Debit: 1643,
				Prepaid: 19,
				CreditPercent: '0.410',
				DebitPercent: '0.583',
				PrepaidPercent: '0.007'
			},
			{
				Merchant: 'Flowers by Friday',
				Bank: 'Northern Credit Union',
				BIN: '444324',
				MonthEnd: 'Month Ended: 4/30/2022',
				Credit: 2021,
				Debit: 2863,
				Prepaid: 32,
				CreditPercent: '0.411',
				DebitPercent: '0.582',
				PrepaidPercent: '0.007'
			},
			{
				Merchant: 'Flowers by Friday',
				Bank: 'Northern Credit Union',
				BIN: '444324',
				MonthEnd: 'Month Ended: 3/31/2022',
				Credit: 2154,
				Debit: 3075,
				Prepaid: 36,
				CreditPercent: '0.409',
				DebitPercent: '0.584',
				PrepaidPercent: '0.007'
			},
			{
				Merchant: 'Cigar Central',
				Bank: 'Unity Trust & Bank',
				BIN: '423456',
				MonthEnd: 'Month Ended: 5/31/2022',
				Credit: 1130,
				Debit: 1569,
				Prepaid: 75,
				CreditPercent: '0.407',
				DebitPercent: '0.566',
				PrepaidPercent: '0.027'
			},
			{
				Merchant: 'Cigar Central',
				Bank: 'Unity Trust & Bank',
				BIN: '423456',
				MonthEnd: 'Month Ended: 4/30/2022',
				Credit: 2101,
				Debit: 2780,
				Prepaid: 134,
				CreditPercent: '0.419',
				DebitPercent: '0.554',
				PrepaidPercent: '0.027'
			},
			{
				Merchant: 'Cigar Central',
				Bank: 'Unity Trust & Bank',
				BIN: '423456',
				MonthEnd: 'Month Ended: 3/31/2022',
				Credit: 2086,
				Debit: 2567,
				Prepaid: 120,
				CreditPercent: '0.437',
				DebitPercent: '0.538',
				PrepaidPercent: '0.025'
			},
			{
				Merchant: 'Baby Box',
				Bank: 'Northland Bank',
				BIN: '441344',
				MonthEnd: 'Month Ended: 5/31/2022',
				Credit: 78,
				Debit: 233,
				Prepaid: 21,
				CreditPercent: '0.235',
				DebitPercent: '0.702',
				PrepaidPercent: '0.063'
			},
			{
				Merchant: 'Baby Box',
				Bank: 'Northland Bank',
				BIN: '441344',
				MonthEnd: 'Month Ended: 4/30/2022',
				Credit: 121,
				Debit: 431,
				Prepaid: 36,
				CreditPercent: '0.206',
				DebitPercent: '0.733',
				PrepaidPercent: '0.062'
			},
			{
				Merchant: 'Baby Box',
				Bank: 'Northland Bank',
				BIN: '441344',
				MonthEnd: 'Month Ended: 3/31/2022',
				Credit: 116,
				Debit: 432,
				Prepaid: 41,
				CreditPercent: '0.197',
				DebitPercent: '0.733',
				PrepaidPercent: '0.070'
			},
			{
				Merchant: 'Baby Box',
				Bank: 'Mighty River Bank & Trust',
				BIN: '451431',
				MonthEnd: 'Month Ended: 5/31/2022',
				Credit: 0,
				Debit: 0,
				Prepaid: 176,
				CreditPercent: '0.000',
				DebitPercent: '0.000',
				PrepaidPercent: '1.000'
			},
			{
				Merchant: 'Baby Box',
				Bank: 'Mighty River Bank & Trust',
				BIN: '451431',
				MonthEnd: 'Month Ended: 4/30/2022',
				Credit: 0,
				Debit: 0,
				Prepaid: 16,
				CreditPercent: '0.000',
				DebitPercent: '0.000',
				PrepaidPercent: '1.000'
			},
			{
				Merchant: 'Baby Box',
				Bank: 'Mighty River Bank & Trust',
				BIN: '451431',
				MonthEnd: 'Month Ended: 3/31/2022',
				Credit: 0,
				Debit: 0,
				Prepaid: 20,
				CreditPercent: '0.000',
				DebitPercent: '0.000',
				PrepaidPercent: '1.000'
			},
			{
				Merchant: 'Baby Box',
				Bank: 'Mighty River Bank & Trust',
				BIN: '526219',
				MonthEnd: 'Month Ended: 5/31/2022',
				Credit: 0,
				Debit: 81,
				Prepaid: 0,
				CreditPercent: '0.000',
				DebitPercent: '1.000',
				PrepaidPercent: '0.000'
			},
			{
				Merchant: 'Baby Box',
				Bank: 'Mighty River Bank & Trust',
				BIN: '526219',
				MonthEnd: 'Month Ended: 4/30/2022',
				Credit: 0,
				Debit: 156,
				Prepaid: 0,
				CreditPercent: '0.000',
				DebitPercent: '1.000',
				PrepaidPercent: '0.000'
			},
			{
				Merchant: 'Baby Box',
				Bank: 'Mighty River Bank & Trust',
				BIN: '526219',
				MonthEnd: 'Month Ended: 3/31/2022',
				Credit: 0,
				Debit: 170,
				Prepaid: 0,
				CreditPercent: '0.000',
				DebitPercent: '1.000',
				PrepaidPercent: '0.000'
			},
			{
				Merchant: 'Baby Box',
				Bank: 'Mighty River Bank & Trust',
				BIN: '462766',
				MonthEnd: 'Month Ended: 5/31/2022',
				Credit: 0,
				Debit: 0,
				Prepaid: 16,
				CreditPercent: '0.000',
				DebitPercent: '0.000',
				PrepaidPercent: '1.000'
			},
			{
				Merchant: 'Baby Box',
				Bank: 'Mighty River Bank & Trust',
				BIN: '462766',
				MonthEnd: 'Month Ended: 4/30/2022',
				Credit: 0,
				Debit: 0,
				Prepaid: 22,
				CreditPercent: '0.000',
				DebitPercent: '0.000',
				PrepaidPercent: '1.000'
			},
			{
				Merchant: 'Baby Box',
				Bank: 'Mighty River Bank & Trust',
				BIN: '462766',
				MonthEnd: 'Month Ended: 3/31/2022',
				Credit: 0,
				Debit: 0,
				Prepaid: 25,
				CreditPercent: '0.000',
				DebitPercent: '0.000',
				PrepaidPercent: '1.000'
			},
			{
				Merchant: 'Baby Box',
				Bank: 'Mighty River Bank & Trust',
				BIN: '523914',
				MonthEnd: 'Month Ended: 5/31/2022',
				Credit: 37,
				Debit: 0,
				Prepaid: 0,
				CreditPercent: '1.000',
				DebitPercent: '0.000',
				PrepaidPercent: '0.000'
			},
			{
				Merchant: 'Baby Box',
				Bank: 'Mighty River Bank & Trust',
				BIN: '523914',
				MonthEnd: 'Month Ended: 4/30/2022',
				Credit: 81,
				Debit: 0,
				Prepaid: 0,
				CreditPercent: '1.000',
				DebitPercent: '0.000',
				PrepaidPercent: '0.000'
			},
			{
				Merchant: 'Baby Box',
				Bank: 'Mighty River Bank & Trust',
				BIN: '523914',
				MonthEnd: 'Month Ended: 3/31/2022',
				Credit: 84,
				Debit: 0,
				Prepaid: 0,
				CreditPercent: '1.000',
				DebitPercent: '0.000',
				PrepaidPercent: '0.000'
			},
			{
				Merchant: 'Baby Box',
				Bank: 'Mighty River Bank & Trust',
				BIN: '559591',
				MonthEnd: 'Month Ended: 5/31/2022',
				Credit: 49,
				Debit: 0,
				Prepaid: 0,
				CreditPercent: '1.000',
				DebitPercent: '0.000',
				PrepaidPercent: '0.000'
			},
			{
				Merchant: 'Baby Box',
				Bank: 'Mighty River Bank & Trust',
				BIN: '559591',
				MonthEnd: 'Month Ended: 4/30/2022',
				Credit: 53,
				Debit: 0,
				Prepaid: 0,
				CreditPercent: '1.000',
				DebitPercent: '0.000',
				PrepaidPercent: '0.000'
			},
			{
				Merchant: 'Baby Box',
				Bank: 'Mighty River Bank & Trust',
				BIN: '559591',
				MonthEnd: 'Month Ended: 3/31/2022',
				Credit: 44,
				Debit: 0,
				Prepaid: 0,
				CreditPercent: '1.000',
				DebitPercent: '0.000',
				PrepaidPercent: '0.000'
			},
			{
				Merchant: 'Baby Box',
				Bank: 'Mighty River Bank & Trust',
				BIN: '543465',
				MonthEnd: 'Month Ended: 5/31/2022',
				Credit: 0,
				Debit: 165,
				Prepaid: 0,
				CreditPercent: '0.000',
				DebitPercent: '1.000',
				PrepaidPercent: '0.000'
			},
			{
				Merchant: 'Baby Box',
				Bank: 'Mighty River Bank & Trust',
				BIN: '543465',
				MonthEnd: 'Month Ended: 4/30/2022',
				Credit: 0,
				Debit: 299,
				Prepaid: 0,
				CreditPercent: '0.000',
				DebitPercent: '1.000',
				PrepaidPercent: '0.000'
			},
			{
				Merchant: 'Baby Box',
				Bank: 'Mighty River Bank & Trust',
				BIN: '543465',
				MonthEnd: 'Month Ended: 3/31/2022',
				Credit: 0,
				Debit: 286,
				Prepaid: 0,
				CreditPercent: '0.000',
				DebitPercent: '1.000',
				PrepaidPercent: '0.000'
			},
			{
				Merchant: 'Baby Box',
				Bank: 'Minnesota State Bank',
				BIN: '442233',
				MonthEnd: 'Month Ended: 5/31/2022',
				Credit: 74,
				Debit: 259,
				Prepaid: 25,
				CreditPercent: '0.207',
				DebitPercent: '0.723',
				PrepaidPercent: '0.070'
			},
			{
				Merchant: 'Baby Box',
				Bank: 'Minnesota State Bank',
				BIN: '442233',
				MonthEnd: 'Month Ended: 4/30/2022',
				Credit: 115,
				Debit: 480,
				Prepaid: 41,
				CreditPercent: '0.181',
				DebitPercent: '0.755',
				PrepaidPercent: '0.064'
			},
			{
				Merchant: 'Baby Box',
				Bank: 'Minnesota State Bank',
				BIN: '442233',
				MonthEnd: 'Month Ended: 3/31/2022',
				Credit: 110,
				Debit: 480,
				Prepaid: 39,
				CreditPercent: '0.175',
				DebitPercent: '0.763',
				PrepaidPercent: '0.062'
			},
			{
				Merchant: 'Baby Box',
				Bank: 'Iowa Farmers Credit',
				BIN: '486895',
				MonthEnd: 'Month Ended: 5/31/2022',
				Credit: 30,
				Debit: 0,
				Prepaid: 0,
				CreditPercent: '1.000',
				DebitPercent: '0.000',
				PrepaidPercent: '0.000'
			},
			{
				Merchant: 'Baby Box',
				Bank: 'Iowa Farmers Credit',
				BIN: '486895',
				MonthEnd: 'Month Ended: 4/30/2022',
				Credit: 50,
				Debit: 0,
				Prepaid: 0,
				CreditPercent: '1.000',
				DebitPercent: '0.000',
				PrepaidPercent: '0.000'
			},
			{
				Merchant: 'Baby Box',
				Bank: 'Iowa Farmers Credit',
				BIN: '486895',
				MonthEnd: 'Month Ended: 3/31/2022',
				Credit: 45,
				Debit: 0,
				Prepaid: 0,
				CreditPercent: '1.000',
				DebitPercent: '0.000',
				PrepaidPercent: '0.000'
			},
			{
				Merchant: 'Baby Box',
				Bank: 'Iowa Farmers Credit',
				BIN: '551070',
				MonthEnd: 'Month Ended: 5/31/2022',
				Credit: 0,
				Debit: 214,
				Prepaid: 0,
				CreditPercent: '0.000',
				DebitPercent: '1.000',
				PrepaidPercent: '0.000'
			},
			{
				Merchant: 'Baby Box',
				Bank: 'Iowa Farmers Credit',
				BIN: '551070',
				MonthEnd: 'Month Ended: 4/30/2022',
				Credit: 0,
				Debit: 401,
				Prepaid: 0,
				CreditPercent: '0.000',
				DebitPercent: '1.000',
				PrepaidPercent: '0.000'
			},
			{
				Merchant: 'Baby Box',
				Bank: 'Iowa Farmers Credit',
				BIN: '551070',
				MonthEnd: 'Month Ended: 3/31/2022',
				Credit: 0,
				Debit: 396,
				Prepaid: 0,
				CreditPercent: '0.000',
				DebitPercent: '1.000',
				PrepaidPercent: '0.000'
			},
			{
				Merchant: 'Baby Box',
				Bank: 'Iowa Farmers Credit',
				BIN: '535971',
				MonthEnd: 'Month Ended: 5/31/2022',
				Credit: 0,
				Debit: 0,
				Prepaid: 8,
				CreditPercent: '0.000',
				DebitPercent: '0.000',
				PrepaidPercent: '1.000'
			},
			{
				Merchant: 'Baby Box',
				Bank: 'Iowa Farmers Credit',
				BIN: '535971',
				MonthEnd: 'Month Ended: 4/30/2022',
				Credit: 0,
				Debit: 0,
				Prepaid: 6,
				CreditPercent: '0.000',
				DebitPercent: '0.000',
				PrepaidPercent: '1.000'
			},
			{
				Merchant: 'Baby Box',
				Bank: 'Iowa Farmers Credit',
				BIN: '535971',
				MonthEnd: 'Month Ended: 3/31/2022',
				Credit: 0,
				Debit: 0,
				Prepaid: 9,
				CreditPercent: '0.000',
				DebitPercent: '0.000',
				PrepaidPercent: '1.000'
			},
			{
				Merchant: 'Baby Box',
				Bank: 'Iowa Farmers Credit',
				BIN: '552318',
				MonthEnd: 'Month Ended: 5/31/2022',
				Credit: 36,
				Debit: 0,
				Prepaid: 0,
				CreditPercent: '1.000',
				DebitPercent: '0.000',
				PrepaidPercent: '0.000'
			},
			{
				Merchant: 'Baby Box',
				Bank: 'Iowa Farmers Credit',
				BIN: '552318',
				MonthEnd: 'Month Ended: 4/30/2022',
				Credit: 53,
				Debit: 0,
				Prepaid: 0,
				CreditPercent: '1.000',
				DebitPercent: '0.000',
				PrepaidPercent: '0.000'
			},
			{
				Merchant: 'Baby Box',
				Bank: 'Iowa Farmers Credit',
				BIN: '552318',
				MonthEnd: 'Month Ended: 3/31/2022',
				Credit: 54,
				Debit: 0,
				Prepaid: 0,
				CreditPercent: '1.000',
				DebitPercent: '0.000',
				PrepaidPercent: '0.000'
			},
			{
				Merchant: 'Baby Box',
				Bank: 'Iowa Farmers Credit',
				BIN: '431451',
				MonthEnd: 'Month Ended: 5/31/2022',
				Credit: 0,
				Debit: 0,
				Prepaid: 168,
				CreditPercent: '0.000',
				DebitPercent: '0.000',
				PrepaidPercent: '1.000'
			},
			{
				Merchant: 'Baby Box',
				Bank: 'Iowa Farmers Credit',
				BIN: '431451',
				MonthEnd: 'Month Ended: 4/30/2022',
				Credit: 0,
				Debit: 0,
				Prepaid: 38,
				CreditPercent: '0.000',
				DebitPercent: '0.000',
				PrepaidPercent: '1.000'
			},
			{
				Merchant: 'Baby Box',
				Bank: 'Iowa Farmers Credit',
				BIN: '431451',
				MonthEnd: 'Month Ended: 3/31/2022',
				Credit: 0,
				Debit: 0,
				Prepaid: 37,
				CreditPercent: '0.000',
				DebitPercent: '0.000',
				PrepaidPercent: '1.000'
			},
			{
				Merchant: 'Baby Box',
				Bank: 'Iowa Farmers Credit',
				BIN: '542432',
				MonthEnd: 'Month Ended: 5/31/2022',
				Credit: 0,
				Debit: 71,
				Prepaid: 0,
				CreditPercent: '0.000',
				DebitPercent: '1.000',
				PrepaidPercent: '0.000'
			},
			{
				Merchant: 'Baby Box',
				Bank: 'Iowa Farmers Credit',
				BIN: '542432',
				MonthEnd: 'Month Ended: 4/30/2022',
				Credit: 0,
				Debit: 126,
				Prepaid: 0,
				CreditPercent: '0.000',
				DebitPercent: '1.000',
				PrepaidPercent: '0.000'
			},
			{
				Merchant: 'Baby Box',
				Bank: 'Iowa Farmers Credit',
				BIN: '542432',
				MonthEnd: 'Month Ended: 3/31/2022',
				Credit: 0,
				Debit: 132,
				Prepaid: 0,
				CreditPercent: '0.000',
				DebitPercent: '1.000',
				PrepaidPercent: '0.000'
			},
			{
				Merchant: 'Baby Box',
				Bank: 'Iowa Farmers Credit',
				BIN: '524038',
				MonthEnd: 'Month Ended: 5/31/2022',
				Credit: 23,
				Debit: 0,
				Prepaid: 0,
				CreditPercent: '1.000',
				DebitPercent: '0.000',
				PrepaidPercent: '0.000'
			},
			{
				Merchant: 'Baby Box',
				Bank: 'Iowa Farmers Credit',
				BIN: '524038',
				MonthEnd: 'Month Ended: 4/30/2022',
				Credit: 34,
				Debit: 0,
				Prepaid: 0,
				CreditPercent: '1.000',
				DebitPercent: '0.000',
				PrepaidPercent: '0.000'
			},
			{
				Merchant: 'Baby Box',
				Bank: 'Iowa Farmers Credit',
				BIN: '524038',
				MonthEnd: 'Month Ended: 3/31/2022',
				Credit: 32,
				Debit: 0,
				Prepaid: 0,
				CreditPercent: '1.000',
				DebitPercent: '0.000',
				PrepaidPercent: '0.000'
			},
			{
				Merchant: 'Baby Box',
				Bank: 'North Dakota Credit Union',
				BIN: '554433',
				MonthEnd: 'Month Ended: 5/31/2022',
				Credit: 84,
				Debit: 272,
				Prepaid: 24,
				CreditPercent: '0.221',
				DebitPercent: '0.716',
				PrepaidPercent: '0.063'
			},
			{
				Merchant: 'Baby Box',
				Bank: 'North Dakota Credit Union',
				BIN: '554433',
				MonthEnd: 'Month Ended: 4/30/2022',
				Credit: 130,
				Debit: 504,
				Prepaid: 43,
				CreditPercent: '0.192',
				DebitPercent: '0.744',
				PrepaidPercent: '0.064'
			},
			{
				Merchant: 'Baby Box',
				Bank: 'North Dakota Credit Union',
				BIN: '554433',
				MonthEnd: 'Month Ended: 3/31/2022',
				Credit: 125,
				Debit: 503,
				Prepaid: 43,
				CreditPercent: '0.186',
				DebitPercent: '0.750',
				PrepaidPercent: '0.064'
			},
			{
				Merchant: 'Squeaky Clean Carwash',
				Bank: 'Bison Bank',
				BIN: '553132',
				MonthEnd: 'Month Ended: 5/31/2022',
				Credit: 426,
				Debit: 953,
				Prepaid: 12,
				CreditPercent: '0.306',
				DebitPercent: '0.685',
				PrepaidPercent: '0.009'
			},
			{
				Merchant: 'Squeaky Clean Carwash',
				Bank: 'Bison Bank',
				BIN: '553132',
				MonthEnd: 'Month Ended: 4/30/2022',
				Credit: 805,
				Debit: 1677,
				Prepaid: 29,
				CreditPercent: '0.321',
				DebitPercent: '0.668',
				PrepaidPercent: '0.012'
			},
			{
				Merchant: 'Squeaky Clean Carwash',
				Bank: 'Bison Bank',
				BIN: '553132',
				MonthEnd: 'Month Ended: 3/31/2022',
				Credit: 873,
				Debit: 1830,
				Prepaid: 32,
				CreditPercent: '0.319',
				DebitPercent: '0.669',
				PrepaidPercent: '0.012'
			}
		]
	},
	'3c6bc96a-0dfb-4271-bcbf-aabc091247a1': {
		title: 'High Risk Merchants',
		results: [
			{
				level: NotificationMasterList.notifications.one.severity,
				type: NotificationMasterList.notifications.one.category,
				description: NotificationMasterList.notifications.one.text,
				assignedTo: '',
				date: '05/20/2022 10:01',
				notificationId: 131,
				assignedTo: 'Ethan Soper'
			},
			{
				level: NotificationMasterList.notifications.two.severity,
				type: NotificationMasterList.notifications.two.category,
				description: NotificationMasterList.notifications.two.text,
				assignedTo: '',
				date: '05/18/2022 06:44',
				notificationId: 132
			},
			{
				level: NotificationMasterList.notifications.three.severity,
				type: NotificationMasterList.notifications.three.category,
				description: NotificationMasterList.notifications.three.text,
				assignedTo: '',
				date: '05/15/2022 17:12',
				notificationId: 133
			},
			{
				level: NotificationMasterList.notifications.four.severity,
				type: NotificationMasterList.notifications.four.category,
				description: NotificationMasterList.notifications.four.text,
				assignedTo: '',
				date: '05/09/2022 20:13',
				notificationId: 134
			}
		]
	},
	'fe8e55e1-4d7b-4fe6-99da-53d5ec71a048': {
		title: 'High Risk Merchants',
		results: [
			{
				level: NotificationMasterList.notifications.one.severity,
				type: NotificationMasterList.notifications.one.category,
				description: NotificationMasterList.notifications.one.text,
				assignedTo: '',
				date: '05/20/2022 08:12',
				notificationId: 131,
				assignedTo: 'Ethan Soper'
			},
			{
				level: NotificationMasterList.notifications.two.severity,
				type: NotificationMasterList.notifications.two.category,
				description: NotificationMasterList.notifications.two.text,
				assignedTo: '',
				date: '05/18/2022 11:28',
				notificationId: 132
			},
			{
				level: NotificationMasterList.notifications.three.severity,
				type: NotificationMasterList.notifications.three.category,
				description: NotificationMasterList.notifications.three.text,
				assignedTo: '',
				date: '05/15/2022 14:01',
				notificationId: 133
			},
			{
				level: NotificationMasterList.notifications.four.severity,
				type: NotificationMasterList.notifications.four.category,
				description: NotificationMasterList.notifications.four.text,
				assignedTo: '',
				date: '05/09/2022 20:13',
				notificationId: 134
			}
		]
	},
	'3c6bc96a-0dfb-4271-bcbf-aabc09124722': {
		title: 'Merchants With Current Violations',
		results: [
			{
				level: NotificationMasterList.notifications.five.severity,
				type: NotificationMasterList.notifications.five.category,
				description: NotificationMasterList.notifications.five.text,
				assignedTo: '',
				date: '05/20/2022 13:34',
				notificationId: 141
			},
			{
				level: NotificationMasterList.notifications.six.severity,
				type: NotificationMasterList.notifications.six.category,
				description: NotificationMasterList.notifications.six.text,
				assignedTo: '',
				date: '05/17/2022 06:04',
				notificationId: 142
			},
			{
				level: NotificationMasterList.notifications.seven.severity,
				type: NotificationMasterList.notifications.seven.category,
				description: NotificationMasterList.notifications.seven.text,
				assignedTo: '',
				date: '05/15/2022 12:50',
				notificationId: 143
			},
			{
				level: NotificationMasterList.notifications.eight.severity,
				type: NotificationMasterList.notifications.eight.category,
				description: NotificationMasterList.notifications.eight.text,
				assignedTo: '',
				date: '05/13/2022 05:01',
				notificationId: 143
			}
		]
	},
	'346bc96a-0dfb-4271-bcbf-aabc09124711': {
		title: 'Newly Onboarded Merchants',
		results: [
			{
				level: NotificationMasterList.notifications.nine.severity,
				type: NotificationMasterList.notifications.nine.category,
				description: NotificationMasterList.notifications.nine.text,
				assignedTo: '',
				date: '05/20/2022 11:37',
				notificationId: 121,
				assignedTo: 'Ethan Soper'
			},
			{
				level: NotificationMasterList.notifications.ten.severity,
				type: NotificationMasterList.notifications.ten.category,
				description: NotificationMasterList.notifications.ten.text,
				assignedTo: '',
				date: '05/17/2022 19:21',
				notificationId: 123
			},
			{
				level: NotificationMasterList.notifications.eleven.severity,
				type: NotificationMasterList.notifications.eleven.category,
				description: NotificationMasterList.notifications.eleven.text,
				assignedTo: '',
				date: '05/15/2022 04:17',
				notificationId: 124
			},
			{
				level: NotificationMasterList.notifications.twelve.severity,
				type: NotificationMasterList.notifications.twelve.category,
				description: NotificationMasterList.notifications.twelve.text,
				assignedTo: '',
				date: '05/14/2022 13:07',
				notificationId: 124
			}
		]
	},
	'346bc96a-0dfb-4271-bcbf-aabc09124733': {
		title: 'Merchants With Former Violations',
		results: [
			{
				level: NotificationMasterList.notifications.thirteen.severity,
				type: NotificationMasterList.notifications.thirteen.category,
				description: NotificationMasterList.notifications.thirteen.text,
				assignedTo: '',
				date: '05/20/2022 10:19',
				notificationId: 151
			},
			{
				level: NotificationMasterList.notifications.fourteen.severity,
				type: NotificationMasterList.notifications.fourteen.category,
				description: NotificationMasterList.notifications.fourteen.text,
				assignedTo: '',
				date: '05/17/2022 06:58',
				notificationId: 152
			}
		]
	}
};
