import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { recoveryHistoryActions } from '../../../containers/RecoveryHistory';
import generateDateRange from '../../../helpers/shared/generateDateRange';
import reasonTableMapper from '../../../helpers/recoveryHistory/reasonTableMapper';
import CONSTANTS from '../../../helpers/constants';

import BreakdownBox from './BreakdownBox';

const styles = (theme) => ({
	stack: {
		display: 'flex',
		flexDirection: 'row',
		alignSelf: 'stretch',
		margin: '10px 0px',
		padding: '0px',
		justifyContent: 'space-between',
		flexWrap: 'wrap'
	}
});

export const BreakdownByChargebackReason = (props) => {
	const {
		classes,
		dateRange,
		data,
		getHistoryByReason,
		isLoading,
		hasBeenLoaded
	} = props;
	const { stack } = classes;

	React.useEffect(() => {
		const formattedDateRange = generateDateRange(dateRange);

		getHistoryByReason(formattedDateRange);
	}, [dateRange, getHistoryByReason]);

	return (
		<div className={stack}>
			{hasBeenLoaded && data && (
				<Fragment>
					<BreakdownBox
						data={reasonTableMapper(data.visa)}
						card={CONSTANTS.CARD_TYPES.uo_visa}
						isLoading={isLoading}
					/>
					<BreakdownBox
						data={reasonTableMapper(data.mastercard)}
						card={CONSTANTS.CARD_TYPES.uo_mastercard}
						isLoading={isLoading}
					/>
					<BreakdownBox
						data={reasonTableMapper(data.discover)}
						card={CONSTANTS.CARD_TYPES.uo_discover}
						isLoading={isLoading}
					/>
					<BreakdownBox
						data={reasonTableMapper(data.amex)}
						card={CONSTANTS.CARD_TYPES.uo_amex}
						isLoading={isLoading}
					/>
				</Fragment>
			)}
		</div>
	);
};

BreakdownByChargebackReason.propTypes = {
	dateRange: PropTypes.string,
	data: PropTypes.object,
	isLoading: PropTypes.bool,
	hasBeenLoaded: PropTypes.bool,
	getHistoryByReason: PropTypes.func,
	classes: PropTypes.object
};

const mapStateToProps = (state) => {
	return {
		data: state.recoveryHistoryReducer.getIn(['historyByReason', 'data']),
		isLoading: state.recoveryHistoryReducer.getIn([
			'historyByReason',
			'isLoading'
		]),
		hasBeenLoaded: state.recoveryHistoryReducer.getIn([
			'historyByReason',
			'hasBeenLoaded'
		]),
		error: state.recoveryHistoryReducer.getIn(['historyByReason', 'error'])
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getHistoryByReason: (payload) => {
			dispatch(recoveryHistoryActions.getRecoveryHistoryByReason(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(BreakdownByChargebackReason));
