import React from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { sanitizeLabels } from '../../../../helpers/sanitizeLabels';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import handleComplexFilters from '../../../../helpers/widget/handleComplexFilters';

const styles = (theme) => ({
	genericInput: {
		fontSize: '.8rem',
		minWidth: '200px',
		margin: '5px 3px',
		height: '55px',
		backgroundColor: theme.palette.common.white
	}
});

const SimpleSelectInput = (props) => {
	const { classes, label, onUpdate, option, value } = props;
	const { genericInput } = classes;

	const handleSelectionUpdate = (value) => {
		onUpdate(label, value);
	};

	return (
		<FormControl>
			<InputLabel id={`simple-select-${label}=label`}>
				{sanitizeLabels(label)}
			</InputLabel>
			<Select
				labelId={`simple-select-${label}-label`}
				id={`simple-select-${label}`}
				label={sanitizeLabels(label)}
				className={genericInput}
				value={value}
				onChange={(event) => {
					handleSelectionUpdate(handleComplexFilters(event.target.value));
				}}
			>
				{option.values.map((value) => {
					const optionValue = handleComplexFilters(value);
					return (
						<MenuItem key={optionValue} value={optionValue}>
							{optionValue}
						</MenuItem>
					);
				})}
			</Select>
		</FormControl>
	);
};

SimpleSelectInput.propTypes = {
	classes: PropTypes.object,
	label: PropTypes.string,
	onUpdate: PropTypes.func,
	option: PropTypes.object,
	value: PropTypes.string
};

export default withStyles(styles)(SimpleSelectInput);
