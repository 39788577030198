import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import deepCopy from '../../../../../utilities/deepCopy';

import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

import Paper from '@mui/material/Paper';

const NEWLAYOUT = 'newLayout';
const CLONELAYOUT = 'cloneLayout';

const styles = (theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		padding: '20px'
	},
	instructionsRow: {
		display: 'flex',
		flexDirection: 'row',
		color: theme.palette.createLayoutModal.instructionsText,
		fontSize: '1rem',
		padding: '5px 10px 5px 20px'
	},
	selectionArea: {
		display: 'flex',
		flexDirection: 'row',
		flex: 1
	},
	selectionItemContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		flex: 1,
		margin: '10px',
		padding: '20px'
	},
	selectionItem: {
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		justifyContent: 'center',
		cursor: 'pointer'
	},
	selectionItemSelected: {
		backgroundColor:
			theme.palette.createLayoutModal.creationTypeSelection.selectedPaper
	},
	innerContainer: {
		display: 'flex',
		margin: '2px',
		justifyContent: 'center'
	},
	icon: {
		fontSize: '3rem',
		margin: '5px',
		color: theme.palette.primary.main
	},
	iconSelected: {
		color: `${theme.palette.createLayoutModal.creationTypeSelection.selectedPaperIcon} !important`
	},
	label: {
		fontSize: '1.8rem',
		textAlign: 'center',
		color: theme.palette.secondary.main
	},
	labelSelected: {
		color: `${theme.palette.createLayoutModal.creationTypeSelection.selectedPaperText} !important`
	}
});

export const CloneOrNew = (props) => {
	const { classes, t, activeStep, createdLayoutConfiguration } = props;
	const {
		container,
		instructionsRow,
		selectionArea,
		selectionItemContainer,
		selectionItem,
		selectionItemSelected,
		innerContainer,
		icon,
		iconSelected,
		label,
		labelSelected
	} = classes;

	const onSelection = (selectedType) => {
		const { setActiveStep, updateCreatedLayoutConfiguration } = props;
		const updatedCreatedLayoutConfiguration = deepCopy(
			createdLayoutConfiguration
		);
		updatedCreatedLayoutConfiguration.layoutCreationType = selectedType;

		updateCreatedLayoutConfiguration(updatedCreatedLayoutConfiguration);
		setActiveStep(activeStep + 1);
	};

	return (
		<div className={container}>
			<div className={instructionsRow}>
				{t('components.modals.createLayout.instructions.cloneOrNew')}
			</div>
			<div className={selectionArea}>
				<div className={selectionItemContainer}>
					<Paper
						className={clsx(
							selectionItem,
							createdLayoutConfiguration.layoutCreationType === CLONELAYOUT &&
								selectionItemSelected
						)}
						elevation={1}
						onClick={() => {
							onSelection(CLONELAYOUT);
						}}
					>
						<div className={innerContainer}>
							<AutoAwesomeMotionIcon
								className={clsx(
									icon,
									createdLayoutConfiguration.layoutCreationType ===
										CLONELAYOUT && iconSelected
								)}
							/>
						</div>
						<div className={innerContainer}>
							<span
								className={clsx(
									label,
									createdLayoutConfiguration.layoutCreationType ===
										CLONELAYOUT && labelSelected
								)}
							>
								{t('components.modals.createLayout.cloneExistingLayout')}
							</span>
						</div>
					</Paper>
				</div>
				<div className={selectionItemContainer}>
					<Paper
						className={clsx(
							selectionItem,
							createdLayoutConfiguration.layoutCreationType === NEWLAYOUT &&
								selectionItemSelected
						)}
						elevation={1}
						onClick={() => {
							onSelection(NEWLAYOUT);
						}}
					>
						<div className={innerContainer}>
							<AutoAwesomeIcon
								className={clsx(
									icon,
									createdLayoutConfiguration.layoutCreationType === NEWLAYOUT &&
										iconSelected
								)}
							/>
						</div>
						<div className={innerContainer}>
							<span
								className={clsx(
									label,
									createdLayoutConfiguration.layoutCreationType === NEWLAYOUT &&
										labelSelected
								)}
							>
								{t('components.modals.createLayout.newLayout')}
							</span>
						</div>
					</Paper>
				</div>
			</div>
		</div>
	);
};

CloneOrNew.propTypes = {
	activeStep: PropTypes.number,
	createdLayoutConfiguration: PropTypes.object,
	updateCreatedLayoutConfiguration: PropTypes.func,
	setActiveStep: PropTypes.func,
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(CloneOrNew));
