import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import './styles.css';
import Loader from '../../Loader';
import { withStyles } from '@mui/styles';
import { dataSourceOrchestration } from './DataSourceOrchestration';
import { __mockPivotData__ } from '../../../containers/Reporter/__TESTDATA__/__pivotData__';
import PivotTableNoCalc from './PivotTable_noCalc';
import { PivotViewComponent } from '@syncfusion/ej2-react-pivotview';

import Typography from '@mui/material/Typography';

const styles = (theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		overflow: 'hidden',
		margin: '20px'
	}
});

const _renderCustomCells = async (args) => {
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const notificationId = urlParams.get('notificationId');

	if (notificationId === '123') {
		return new Promise((resolve) => {
			const { data } = args;
			for (let [key, value] of Object.entries(data)) {
				if (
					(value.actualText === 'Baby Box' ||
						value.actualText === 'Mighty River Bank & Trust' ||
						value.actualText === 'Iowa Farmers Credit' ||
						value.actualText === 'Sweet Pea' ||
						value.actualText === 'Black-Eyed Susan' ||
						value.actualText === 'Mandevilla' ||
						value.actualText === 'Star Jasmine') &&
					value.colIndex === 0 &&
					value.axis === 'row'
				) {
					const selected = args.cell.querySelector('.e-cellvalue');
					if (
						selected &&
						(selected.innerText === 'Baby Box' ||
							selected.innerText === 'Mighty River Bank & Trust' ||
							selected.innerText === 'Iowa Farmers Credit' ||
							selected.innerText === 'Sweet Pea' ||
							selected.innerText === 'Black-Eyed Susan' ||
							selected.innerText === 'Mandevilla' ||
							selected.innerText === 'Star Jasmine')
					) {
						selected.style.color = 'red';
					}
				}

				if (
					(value.rowHeaders === 'Baby Box' ||
						value.rowHeaders === 'Baby Box.Mighty River Bank & Trust' ||
						value.rowHeaders === 'Baby Box.Iowa Farmers Credit') &&
					value.actualText === 'PrepaidPercent' &&
					value.colIndex === 6
				) {
					value.formattedText = `<p style="color:red;font-weight:bold">${value.formattedText}</p>`;
				}
			}

			return resolve(args);
		});
	} else if (notificationId === '121') {
		return new Promise((resolve) => {
			const { data } = args;

			for (let [key, value] of Object.entries(data)) {
				if (
					(value.actualText === 'Flowers By Friday' ||
						value.actualText === "Vinny's Vines" ||
						value.actualText === 'Clematis' ||
						value.actualText === 'Sweet Pea' ||
						value.actualText === 'Black-Eyed Susan' ||
						value.actualText === 'Mandevilla' ||
						value.actualText === 'Star Jasmine') &&
					value.colIndex === 0 &&
					value.axis === 'row'
				) {
					const selected = args.cell.querySelector('.e-cellvalue');
					if (
						selected &&
						(selected.innerText === 'Flowers By Friday' ||
							selected.innerText === "Vinny's Vines" ||
							selected.innerText === 'Clematis' ||
							selected.innerText === 'Sweet Pea' ||
							selected.innerText === 'Black-Eyed Susan' ||
							selected.innerText === 'Mandevilla' ||
							selected.innerText === 'Star Jasmine')
					) {
						selected.style.color = 'red';
					}
				}

				if (
					(value.rowHeaders === "Flowers By Friday - Vinny's Vines" ||
						value.rowHeaders ===
							"Flowers By Friday - Vinny's Vines - Clematis" ||
						value.rowHeaders ===
							"Flowers By Friday - Vinny's Vines - Sweet Pea" ||
						value.rowHeaders ===
							"Flowers By Friday - Vinny's Vines - Black-Eyed Susan" ||
						value.rowHeaders ===
							"Flowers By Friday - Vinny's Vines - Mandevilla" ||
						value.rowHeaders ===
							"Flowers By Friday - Vinny's Vines - Star Jasmine") &&
					value.actualText === 'FraudPercent' &&
					value.colIndex === 3
				) {
					value.formattedText = `<p style="color:red;font-weight:bold">${value.formattedText}</p>`;
				}
			}

			return resolve(args);
		});
	} else {
		return args;
	}
};

const _renderCustomColumns = (args) => {
	for (let i = 0; i < args.columns.length; i++) {
		if (i === 0) {
			const curColumn = args.columns[i];
			curColumn.width = 300;
		}
	}
};

const PivotTable = (props) => {
	const { classes, data, hasBeenLoaded, isLoading } = props;
	const { container } = classes;

	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const dataId = urlParams.get('id');

	const determineLabels = (id) => {
		switch (id) {
			case '10':
				return ['Merchant', 'Affiliate', 'Product'];
			case '12':
				return ['Merchant'];
			case '13':
				return ['Merchant', 'Issuer', 'BIN'];
			default:
				return null;
		}
	};

	return (
		<Fragment>
			{isLoading || !hasBeenLoaded ? (
				<Loader />
			) : dataId === '12' ? (
				<PivotTableNoCalc data={data} />
			) : (
				<div className={container}>
					<div>
						<Typography variant="caption">
							{determineLabels(dataId).join(' / ')}
						</Typography>
					</div>
					<PivotViewComponent
						allowCalculatedField
						allowNumberFormatting
						dataSourceSettings={dataSourceOrchestration(dataId, data)}
						width={'85vw'}
						height={1000}
						gridSettings={{
							columnRender: _renderCustomColumns,
							queryCellInfo: _renderCustomCells,
							aggregateCellInfo: (args) => {
								console.log('WHAT ARE aggregateCellInfo', args);
							},
							allowResizing: false,
							columnWidth: 105,
							gridLines: 'Both',
							allowSelection: true,
							selectionSettings: {
								cellSelectionMode: 'Box',
								type: 'Multiple',
								mode: 'Cell'
							}
						}}
					></PivotViewComponent>
				</div>
			)}
		</Fragment>
	);
};

PivotTable.propTypes = {
	classes: PropTypes.object,
	data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	hasBeenLoaded: PropTypes.bool,
	isLoading: PropTypes.bool,
	size: PropTypes.object
};

export default withStyles(styles)(PivotTable);
