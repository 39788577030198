export default {
	amex: {
		chargebacks: 0,
		value: 0
	},
	discover: {
		chargebacks: 5,
		value: '136.75'
	},
	mastercard: {
		chargebacks: 10,
		value: '291.50'
	},
	visa: {
		chargebacks: 7,
		value: '210.65'
	}
};
