import chargebackRatiosBar from './dataFormatters/chargebackRatios_bar';
import chargebacksForYesterdayBar from './dataFormatters/chargebacksForYesterday_bar';
import chargebackDetailLine from './dataFormatters/chargebackDetail_line';
import fourteenDayChargebacksLine from './dataFormatters/fourteenDayChargebacks_line';
import chargebacksForYesterdayLine from './dataFormatters/chargebacksForYesterday_line';

const _dataMappers = {
	'bar-4': chargebackRatiosBar,
	'bar-5': chargebacksForYesterdayBar,
	'line-2': chargebackDetailLine,
	'line-5': chargebacksForYesterdayLine,
	'line-6': fourteenDayChargebacksLine
};

export default function (tileData, reportId, selectedView) {
	let mappedResults = {};

	const dataMapper = _dataMappers[`${selectedView}-${reportId}`];

	if (dataMapper) {
		mappedResults = dataMapper(tileData.data);
	}

	return mappedResults;
}
