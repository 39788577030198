import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Link } from 'react-router-dom';

import Button from '@mui/material/Button';

const styles = (theme) => ({
	link: {
		fontSize: '14px'
	},
	root: {
		display: 'flex',
		justifyContent: 'center'
	}
});

const ViewAll = (props) => {
	const { classes } = props;
	const { link, root } = classes;

	return (
		<Link className={root} to="/notifications/121">
			<Button color="primary" className={link}>
				View All Notifications
			</Button>
		</Link>
	);
};

ViewAll.propTypes = {
	classes: PropTypes.object
};

export default withStyles(styles)(ViewAll);
