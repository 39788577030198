import * as actionTypes from './actionTypes';

export const addNotification = (payload) => ({
	type: actionTypes.ADD_NOTIFICATION,
	payload
});

export const addNotificationCompleted = (payload) => ({
	type: actionTypes.ADD_NOTIFICATION_COMPLETED,
	payload
});

export const addNotificationFailed = (payload) => ({
	type: actionTypes.ADD_NOTIFICATION_FAILED,
	payload
});

export const addNotificationDetail = (payload) => ({
	type: actionTypes.ADD_NOTIFICATION_DETAIL,
	payload
});

export const addNotificationDetailCompleted = (payload) => ({
	type: actionTypes.ADD_NOTIFICATION_DETAIL_COMPLETED,
	payload
});

export const addNotificationDetailFailed = (payload) => ({
	type: actionTypes.ADD_NOTIFICATION_DETAIL_FAILED,
	payload
});

export const getNotifications = (payload) => ({
	type: actionTypes.GET_NOTIFICATIONS,
	payload
});

export const getNotificationsCompleted = (payload) => ({
	type: actionTypes.GET_NOTIFICATIONS_COMPLETED,
	payload
});

export const getNotificationsFailed = (payload) => ({
	type: actionTypes.GET_NOTIFICATIONS_FAILED,
	payload
});

export const getNotificationById = (payload) => ({
	type: actionTypes.GET_NOTIFICATION_BY_ID,
	payload
});

export const getNotificationByIdCompleted = (payload) => ({
	type: actionTypes.GET_NOTIFICATION_BY_ID_COMPLETED,
	payload
});

export const getNotificationByIdFailed = (payload) => ({
	type: actionTypes.GET_NOTIFICATION_BY_ID_FAILED,
	payload
});

export const getNotificationDetailsById = (payload) => ({
	type: actionTypes.GET_NOTIFICATION_DETAILS_BY_ID,
	payload
});

export const getNotificationDetailsByIdCompleted = (payload) => ({
	type: actionTypes.GET_NOTIFICATION_DETAILS_BY_ID_COMPLETED,
	payload
});

export const getNotificationDetailsByIdFailed = (payload) => ({
	type: actionTypes.GET_NOTIFICATION_DETAILS_BY_ID_FAILED,
	payload
});

export const getArchivedNotifications = (payload) => ({
	type: actionTypes.GET_ARCHIVED_NOTIFICATIONS,
	payload
});

export const getArchivedNotificationsCompleted = (payload) => ({
	type: actionTypes.GET_ARCHIVED_NOTIFICATIONS_COMPLETED,
	payload
});

export const getArchivedNotificationsFailed = (payload) => ({
	type: actionTypes.GET_ARCHIVED_NOTIFICATIONS_FAILED,
	payload
});

export const getArchivedNotificationDetailsById = (payload) => ({
	type: actionTypes.GET_ARCHIVED_NOTIFICATION_DETAILS_BY_ID,
	payload
});

export const getArchivedNotificationDetailsByIdCompleted = (payload) => ({
	type: actionTypes.GET_ARCHIVED_NOTIFICATION_DETAILS_BY_ID_COMPLETED,
	payload
});

export const getArchivedNotificationDetailsByIdFailed = (payload) => ({
	type: actionTypes.GET_ARCHIVED_NOTIFICATION_DETAILS_BY_ID_FAILED,
	payload
});

export const updateNotification = (payload) => ({
	type: actionTypes.UPDATE_NOTIFICATION,
	payload
});

export const updateNotificationCompleted = (payload) => ({
	type: actionTypes.UPDATE_NOTIFICATION_COMPLETED,
	payload
});

export const updateNotificationFailed = (payload) => ({
	type: actionTypes.UPDATE_NOTIFICATION_FAILED,
	payload
});

export const updateNotificationDetail = (payload) => ({
	type: actionTypes.UPDATE_NOTIFICATION_DETAIL,
	payload
});

export const updateNotificationDetailCompleted = (payload) => ({
	type: actionTypes.UPDATE_NOTIFICATION_DETAIL_COMPLETED,
	payload
});

export const updateNotificationDetailFailed = (payload) => ({
	type: actionTypes.UPDATE_NOTIFICATION_DETAIL_FAILED,
	payload
});

export const deleteNotification = (payload) => ({
	type: actionTypes.DELETE_NOTIFICATION,
	payload
});

export const deleteNotificationCompleted = (payload) => ({
	type: actionTypes.DELETE_NOTIFICATION_COMPLETED,
	payload
});

export const deleteNotificationFailed = (payload) => ({
	type: actionTypes.DELETE_NOTIFICATION_FAILED,
	payload
});

export const deleteNotificationDetail = (payload) => ({
	type: actionTypes.DELETE_NOTIFICATION_DETAIL,
	payload
});

export const deleteNotificationDetailCompleted = (payload) => ({
	type: actionTypes.DELETE_NOTIFICATION_DETAIL_COMPLETED,
	payload
});

export const deleteNotificationDetailFailed = (payload) => ({
	type: actionTypes.DELETE_NOTIFICATION_DETAIL_FAILED,
	payload
});
