import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { modalActions } from '../../../containers/Modal';
import { withStyles } from '@mui/styles';

import HeaderRow from '../shared/HeaderRow';

import notificationTestImage from '../../../__Demo_Data__/Assets/notificationTestImage.png';

const demoAssets = {
	'notificationTestImage.PNG': notificationTestImage
};

import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';

const styles = (theme) => ({
	modal: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		flex: 1
	},
	paper: {
		display: 'flex',
		flexFlow: 'column',
		padding: '1rem',
		maxWidth: '80vw'
	}
});

export const AttachmentViewerModal = (props) => {
	const { classes, t, attachmentModalData } = props;
	const { modal, paper } = classes;

	const onCloseModal = () => {
		const { closeModal } = props;

		closeModal();
	};

	return (
		<Modal
			open={
				attachmentModalData &&
				(attachmentModalData.assetName || attachmentModalData.value)
					? true
					: false
			}
			onClose={onCloseModal}
			className={modal}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Paper className={paper} elevation={1}>
				<HeaderRow
					translationPath={'components.modals.AttachmentViewer.viewAttachment'}
					onClickCancel={onCloseModal}
				/>
				{attachmentModalData && attachmentModalData.assetName && (
					<img src={demoAssets[attachmentModalData.assetName]} />
				)}
				{attachmentModalData && attachmentModalData.value && (
					<img src={attachmentModalData.value} />
				)}
			</Paper>
		</Modal>
	);
};

AttachmentViewerModal.propTypes = {
	closeModal: PropTypes.func,
	classes: PropTypes.object,
	t: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		attachmentModalData: state.modalReducer.get('attachmentModalData')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		closeModal: (payload) => {
			dispatch(modalActions.setAttachmentModalData(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(withTranslation()(AttachmentViewerModal)));
