import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@mui/styles';
import { reporterActions } from '../../../../containers/Reporter';
import findReportItemInList from '../../../../helpers/reporter/findReportItemInList';
import formatQueryParameters from '../../../../helpers/reporter/formatQueryParameters';
import isDataEmpty from '../../../../helpers/shared/isDataEmpty';
import DataViewer from '../../../DataViewer/DataViewer';
import formatDataForTileView from '../../../../helpers/tiles/formatDataForTileView';
import isFiltersSet from '../../../../helpers/tiles/isFiltersSet';
import NoData from './NoData';
import LoadingState from './LoadingState';

import SettingsIcon from '@mui/icons-material/Settings';

const styles = (theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%'
	},
	selectFiltersContainer: {
		padding: '5%',
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		justifyContent: 'center'
	},
	row: {
		display: 'flex',
		margin: '5px',
		flexDirection: 'row',
		justifyContent: 'center'
	}
});

const _renderWidgetViewer = (
	tileData,
	reportId,
	selectedView = 'line',
	tileId,
	height,
	width,
	classes,
	t
) => {
	let renderedItem = <></>;
	const { textContainer } = classes;

	if (tileData && tileData.hasBeenLoaded && isDataEmpty(tileData.data)) {
		renderedItem = <NoData height={height * 0.9} width={width * 0.9} />;
	} else if (tileData && tileData.hasBeenLoaded) {
		const formattedDataForView = formatDataForTileView(
			tileData,
			reportId,
			selectedView
		);

		renderedItem = (
			<DataViewer
				reportData={formattedDataForView}
				dataViewType={selectedView}
				tileId={tileId}
				height={height * 0.9}
				width={width * 0.9}
			/>
		);
	} else {
		renderedItem = <LoadingState height={height * 0.9} width={width * 0.9} />;
	}

	return renderedItem;
};

const DashboardWidgetTile = (props) => {
	const {
		classes,
		t,
		tileProperties,
		height,
		width,
		reportData,
		getTileData
	} = props;
	const { container, selectFiltersContainer, row } = classes;

	const tileData = findReportItemInList(
		reportData,
		tileProperties.universalUniqueIdentifier
	);

	React.useEffect(() => {
		if (
			tileProperties &&
			Object.keys(tileProperties.filters).length > 0 &&
			!tileData.hasBeenLoaded &&
			!tileData.isLoading
		) {
			getTileData({
				id: tileProperties.reportId,
				queryParameters: formatQueryParameters(tileProperties.filters),
				uuid: tileProperties.universalUniqueIdentifier
			});
		}
	}, [tileProperties, tileData, getTileData]);

	return (
		<div className={container}>
			{!isFiltersSet(tileProperties) ? (
				<div className={selectFiltersContainer}>
					<div className={row}>
						{t('components.dashboardTiles.DashboardWidgetTile.filtersRequired')}
					</div>
					<div className={row}>
						<span>
							{t(
								'components.dashboardTiles.DashboardWidgetTile.instructionsPartOne'
							)}
						</span>
						<SettingsIcon />
						<span>
							{t(
								'components.dashboardTiles.DashboardWidgetTile.instructionsPartTwo'
							)}
						</span>
					</div>
				</div>
			) : (
				_renderWidgetViewer(
					tileData,
					tileProperties.reportId,
					tileProperties.dataViewStyle,
					tileProperties.universalUniqueIdentifier,
					height,
					width,
					classes,
					t
				)
			)}
		</div>
	);
};

DashboardWidgetTile.propTypes = {
	tileProperties: PropTypes.object,
	reportData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	getTileData: PropTypes.func,
	height: PropTypes.number,
	classes: PropTypes.object,
	t: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		reportData: state.reporterReducer.get('reportData')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getTileData: (payload) => {
			dispatch(reporterActions.executeReport(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(withTranslation()(DashboardWidgetTile)));
