import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import { usersActions } from '../../containers/Users';
import Loader from '../../components/Loader/Loader';
// import AddNotificationToStream from '../../components/modals/s/AddNotificationToStream';
import { notificationActions } from '../../containers/Notifications';

import NotificationList from './NotificationList';
import NotificationTimeline from './NotificationTimeline';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import SearchIcon from '@mui/icons-material/Search';

const styles = (theme) => ({
	notificationContentContainer: {
		display: 'flex',
		flexDirection: 'column'
	},
	addNotificationButtonContainer: {
		margin: '1vh'
	},
	contentContainer: {
		display: 'flex',
		flexDirection: 'row',
		flex: 1
	},
	selectStream: {
		overflowY: 'auto',
		display: 'flex',
		flex: 4,
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		'& svg': {
			fontSize: '10em',
			color: 'rgba(0,0,0,.2)'
		}
	}
});

const NotificationContentManager = (props) => {
	const {
		classes,
		t,
		notifications,
		notificationsAreLoading,
		notificationsHasBeenLoaded,
		getNotifications
	} = props;
	const {
		notificationContentContainer,
		addNotificationButtonContainer,
		contentContainer,
		selectStream
	} = classes;

	React.useEffect(() => {
		if (!notificationsAreLoading && !notificationsHasBeenLoaded) {
			getNotifications();
		}
	}, [notificationsAreLoading, notificationsHasBeenLoaded, getNotifications]);

	return (
		<div className={notificationContentContainer}>
			{notificationsAreLoading || !notificationsHasBeenLoaded ? (
				<Loader />
			) : (
				<Fragment>
					<div className={addNotificationButtonContainer}>
						<Button
							onClick={() => {}} // Disabled for demo
							variant="outlined"
						>
							{t('pages.notifications.timeline.addNew')}
						</Button>
					</div>
					<div className={contentContainer}>
						<NotificationList
							notifications={notifications}
							isLoading={notificationsAreLoading}
							hasBeenLoaded={notificationsHasBeenLoaded}
						/>
						<NotificationTimeline />
					</div>
					{/* <AddNotificationToStream
				addNotification={addNotification}
				listOfUsers={listOfUsers}
				open={openAddNotificationModal}
				setOpen={setOpenAddNotificationModal}
				user={user}
			/> */}
				</Fragment>
			)}
		</div>
	);
};

NotificationContentManager.propTypes = {
	notifications: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	notificationsAreLoading: PropTypes.bool,
	notificationsHasBeenLoaded: PropTypes.bool,
	classes: PropTypes.object,
	t: PropTypes.func,
	user: PropTypes.object
};

const mapStateToProps = (state) => {
	return {
		notifications: state.notificationReducer.getIn(['notifications', 'data']),
		notificationsAreLoading: state.notificationReducer.getIn([
			'notifications',
			'isLoading'
		]),
		notificationsHasBeenLoaded: state.notificationReducer.getIn([
			'notifications',
			'hasBeenLoaded'
		])
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		addNotification: (payload) => {
			dispatch(notificationActions.addNotification(payload));
		},
		getNotifications: () => {
			dispatch(notificationActions.getNotifications());
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(withTranslation()(NotificationContentManager)));

/*
	const renderTimelineTitle = (data, id) => {
		const filteredTitle = data
			.filter((notification) => notification.id === id)
			.map((notification) => notification.label);
		if (filteredTitle.length) {
			return filteredTitle[0];
		}
		return [];
	};
	*/
