import { fromJS } from 'immutable';
import * as actionTypes from './actionTypes';

export const initialState = fromJS({
	user: {},
	challengeName: undefined,
	isAuthed: false,
	isAuthingUser: false,
	hasChangedPassword: false,
	isChangingPassword: false,
	logInError: false
});

export const authReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.AUTH_USER:
			return state.set('isAuthingUser', true).set('challengeName', undefined);

		case actionTypes.AUTH_USER_COMPLETED:
			return state
				.set('isAuthingUser', false)
				.set('user', action.payload.user)
				.set('challengeName', action.payload.challengeName)
				.set('isAuthed', true)
				.set('logInError', false);

		case actionTypes.AUTH_USER_FAILED:
			return state
				.set('isAuthingUser', false)
				.set('isAuthed', false)
				.set('logInError', true);

		case actionTypes.AUTH_USER_SESSION:
			return state.set('isAuthingUser', true);

		case actionTypes.AUTH_USER_SESSION_COMPLETED:
			return state
				.set('isAuthingUser', false)
				.set('user', action.payload)
				.set('isAuthed', true);

		case actionTypes.AUTH_USER_SESSION_FAILED:
			return state.set('isAuthingUser', false).set('isAuthed', false);

		case actionTypes.DE_AUTH_USER:
			return initialState;

		case actionTypes.COMPLETE_NEW_PASSWORD:
			return state.set('isChangingPassword', true);

		case actionTypes.COMPLETE_NEW_PASSWORD_COMPLETED:
			return state
				.set('isChangingPassword', false)
				.set('hasChangedPassword', true)
				.set('challengeName', undefined);

		case actionTypes.COMPLETE_NEW_PASSWORD_FAILED:
			return state
				.set('isChangingPassword', false)
				.set('hasChangedPassword', false);

		default:
			return state;
	}
};
