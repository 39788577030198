import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import { withSize } from 'react-sizeme';
import { creditCardColorCodes } from '../../../../../Theme/colorCodes/creditCards';
import groupDataForLineChart from '../../../../../../helpers/widget/viewHelpers/groupDataForLineChart';

import Paper from '@mui/material/Paper';

import {
	CartesianGrid,
	Line,
	LineChart,
	Tooltip,
	YAxis,
	XAxis,
	Legend
} from 'recharts';

const styles = (theme) => ({
	container: {
		display: 'flex',
		maxHeight: '600px',
		padding: '5px 10px 5px 10px',
		alignSelf: 'stretch',
		flexDirection: 'column',
		alignItems: 'center'
	}
});

const LineGraph = (props) => {
	const { classes, t, size, reportData, reportViewId } = props;
	const { container } = classes;

	const containerWidth = size.width;
	const containerHeight = size.height;

	const groupedData = groupDataForLineChart(reportData.data, reportViewId);

	return (
		<Paper className={container}>
			<LineChart
				width={containerWidth - containerWidth * 0.1}
				height={containerHeight}
				data={groupedData}
			>
				<CartesianGrid strokeDasharray="3 3" />
				<XAxis
					dataKey="date"
					label={t('components.Widget.WidgetViewer.LineGraph.xAxis')}
				/>
				<YAxis label={t('components.Widget.WidgetViewer.LineGraph.yAxis')} />
				<Tooltip />
				<Legend />
				<Line
					type="monotone"
					dataKey="Amex"
					stroke={creditCardColorCodes.amex.primary}
				/>
				<Line
					type="monotone"
					dataKey="Discover"
					stroke={creditCardColorCodes.discover.primary}
				/>
				<Line
					type="monotone"
					dataKey="MasterCard"
					stroke={creditCardColorCodes.masterCard.base}
				/>
				<Line
					type="monotone"
					dataKey="Visa"
					stroke={creditCardColorCodes.visa.primary}
				/>
			</LineChart>
		</Paper>
	);
};

LineGraph.propTypes = {
	reportData: PropTypes.object,
	classes: PropTypes.object,
	size: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(
	withTranslation()(withSize({ monitorHeight: true })(LineGraph))
);
