import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';

const styles = (theme) => ({
	list: {
		width: '80%',
		overflow: 'auto'
	},
	listItemText: {
		display: 'flex',
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'flex-start'
	},
	coloredText: {
		color: theme.palette.createLayoutModal.cloneLayout.layoutCountText,
		fontWeight: '700'
	}
});

export const SelectUser = (props) => {
	const { classes, t, organizationUsers, onUserSelect } = props;
	const { list, listItemText, coloredText } = classes;

	return (
		<List className={list}>
			{organizationUsers.map((user) => {
				return (
					<ListItem key={user.username} disablePadding>
						<ListItemButton
							onClick={() => {
								onUserSelect(user);
							}}
						>
							<ListItemIcon>
								<Avatar
									alt={`${user.firstName} ${user.lastName}`}
									src={`https://dev.assets.slyce360.com${user.avatar}`}
									variant="rounded"
									sx={{ width: 25, height: 25 }}
								/>
							</ListItemIcon>
							<ListItemText
								className={listItemText}
							>{`${user.firstName} ${user.lastName}`}</ListItemText>
							<ListItemText className={listItemText}>
								<span className={coloredText}>{user.layoutCount}</span>
								{` ${t(
									'components.modals.createLayout.instructions.cloneLayout.availableLayouts'
								)}`}
							</ListItemText>
						</ListItemButton>
					</ListItem>
				);
			})}
		</List>
	);
};

SelectUser.propTypes = {
	organizationUsers: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	onUserSelect: PropTypes.func,
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(SelectUser));
