import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@mui/styles';
import DataViewer from '../../../DataViewer/DataViewer';
import moment from 'moment';

const styles = (theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		flex: '1'
	}
});

const _mockData = {
	lineKeys: ['severe', 'warning', 'info'],
	results: [
		{
			severe: 5,
			warning: 3,
			info: 2,
			date: moment().add(-14, 'days').format('MM-DD')
		},
		{
			severe: 1,
			warning: 2,
			info: 4,
			date: moment().add(-13, 'days').format('MM-DD')
		},
		{
			severe: 1,
			warning: 3,
			info: 1,
			date: moment().add(-12, 'days').format('MM-DD')
		},
		{
			severe: 2,
			warning: 12,
			info: 9,
			date: moment().add(-11, 'days').format('MM-DD')
		},
		{
			severe: 7,
			warning: 7,
			info: 22,
			date: moment().add(-10, 'days').format('MM-DD')
		},
		{
			severe: 1,
			warning: 0,
			info: 17,
			date: moment().add(-9, 'days').format('MM-DD')
		},
		{
			severe: 6,
			warning: 2,
			info: 8,
			date: moment().add(-8, 'days').format('MM-DD')
		}
	]
};

const NotificationsByDay = (props) => {
	const { classes, height, width } = props;
	const { stack } = classes;

	return (
		<DataViewer
			reportData={_mockData}
			dataViewType={'line'}
			height={height * 0.9}
			width={width * 0.9}
		/>
	);
};

NotificationsByDay.propTypes = {
	tileProperties: PropTypes.object,
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(NotificationsByDay));
