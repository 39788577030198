import React from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { systemActions } from '../../../containers/System';

const styles = (theme) => ({
	leftDrawer: {
		width: theme.panel.width,
		flexShrink: 0,
		animationFillMode: 'forwards',
		animationDuration: '0.25s'
	},
	leftDrawerClose: {
		animationName: '$leftDrawerClose'
	},
	leftDrawerOpen: {
		animationName: '$leftDrawerOpen'
	},
	'@keyframes leftDrawerOpen': {
		from: {
			marginLeft: `calc(${theme.panel.width} * -1)`
		},
		to: {
			marginLeft: '0px'
		}
	},
	'@keyframes leftDrawerClose': {
		from: {
			marginLeft: '0px'
		},
		to: {
			marginLeft: `calc(${theme.panel.width} * -1)`
		}
	}
});

const LeftDrawer = (props) => {
	const { classes, leftDrawerIsOpen } = props;
	const { leftDrawer, leftDrawerClose, leftDrawerOpen } = classes;

	return (
		<div
			className={clsx(
				leftDrawer,
				leftDrawerIsOpen ? leftDrawerOpen : leftDrawerClose
			)}
		>
			<p>LEFT DRAWER</p>
		</div>
	);
};

LeftDrawer.propTypes = {
	leftDrawerIsOpen: PropTypes.bool,
	classes: PropTypes.object
};

const mapStateToProps = (state) => {
	return {
		leftDrawerIsOpen: state.systemReducer.get('leftDrawerIsOpen')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		toggleLeftDrawer: () => {
			dispatch(systemActions.toggleLeftDrawer());
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(LeftDrawer));
