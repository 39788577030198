import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import Tooltip from '@mui/material/Tooltip';

import AppsIcon from '@mui/icons-material/Apps';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';

const styles = (theme) => ({
	list: {
		width: '80%',
		overflow: 'auto'
	},
	listItemWrapper: {
		margin: '0px'
	},
	listItem: {
		display: 'flex',
		flex: 1
	},
	activeListItem: {
		backgroundColor:
			theme.palette.createLayoutModal.cloneLayout.selectLayout
				.activeExpandedLayout
	},
	cloneContainer: {
		display: 'flex',
		padding: '0px 0px 0px 10px',
		color: theme.palette.createLayoutModal.cloneLayout.selectLayout.cloneIcon,
		'&:hover': {
			color:
				theme.palette.createLayoutModal.cloneLayout.selectLayout.cloneIconHover,
			cursor: 'pointer'
		}
	},
	listItemText: {
		display: 'flex',
		flexDirection: 'row',
		flex: 1,
		justifyContent: 'flex-start'
	},
	rightAlign: {
		justifyContent: 'flex-end !important'
	},
	coloredTextContainer: {
		minWidth: '30px',
		color: theme.palette.createLayoutModal.cloneLayout.layoutCountText,
		fontWeight: '700'
	},
	collapsableSection: {
		marginLeft: '10%'
	},
	purpleIcon: {
		color:
			theme.palette.createLayoutModal.cloneLayout.selectLayout.cloneIconHover
	}
});

export const SelectLayout = (props) => {
	const {
		classes,
		t,
		selectedUserLayouts,
		setSelectedLayout,
		selectedLayout
	} = props;
	const {
		list,
		listItemWrapper,
		listItem,
		activeListItem,
		cloneContainer,
		listItemText,
		rightAlign,
		coloredTextContainer,
		collapsableSection,
		purpleIcon
	} = classes;

	const [
		openLayoutuniversalUniqueIdentifier,
		setOpenLayoutuniversalUniqueIdentifier
	] = React.useState();

	const onSelectRow = (universalUniqueIdentifier) => {
		if (openLayoutuniversalUniqueIdentifier === universalUniqueIdentifier) {
			setOpenLayoutuniversalUniqueIdentifier();
		} else {
			setOpenLayoutuniversalUniqueIdentifier(universalUniqueIdentifier);
		}
	};

	return (
		<List className={list}>
			{selectedUserLayouts.map((layout) => {
				return (
					<div
						className={listItemWrapper}
						key={layout.universalUniqueIdentifier}
					>
						<ListItem className={listItem} disablePadding>
							<ListItemButton
								className={
									openLayoutuniversalUniqueIdentifier ===
										layout.universalUniqueIdentifier && activeListItem
								}
								onClick={() => {
									onSelectRow(layout.universalUniqueIdentifier);
								}}
							>
								<ListItemIcon></ListItemIcon>
								<ListItemText className={listItemText}>
									{layout.label}
								</ListItemText>
								<ListItemText className={listItemText}>
									<span className={coloredTextContainer}>
										{layout.tiles.length}
									</span>
									<span>{` ${t(
										'components.modals.createLayout.instructions.cloneLayout.tiles'
									)}`}</span>
								</ListItemText>
								{openLayoutuniversalUniqueIdentifier ===
								layout.universalUniqueIdentifier ? (
									<ExpandLess />
								) : (
									<ExpandMore />
								)}
							</ListItemButton>
							<div
								className={cloneContainer}
								onClick={() => {
									setSelectedLayout(layout);
								}}
							>
								<Tooltip
									placement="right"
									title={t(
										'components.modals.createLayout.instructions.cloneLayout.cloneLayout'
									)}
								>
									<AutoAwesomeMotionIcon
										className={
											selectedLayout &&
											selectedLayout.universalUniqueIdentifier ===
												layout.universalUniqueIdentifier &&
											purpleIcon
										}
									/>
								</Tooltip>
							</div>
						</ListItem>
						<Collapse
							className={collapsableSection}
							in={
								openLayoutuniversalUniqueIdentifier ===
								layout.universalUniqueIdentifier
							}
							timeout="auto"
							unmountOnExit
						>
							<List className={list}>
								{layout.tiles.map((tile) => {
									return (
										<ListItem
											key={tile.universalUniqueIdentifier}
											disablePadding
										>
											<ListItemIcon>
												{!tile.reportId ? (
													<AppsIcon />
												) : (
													<AppRegistrationIcon />
												)}
											</ListItemIcon>
											<ListItemText
												className={listItemText}
											>{`${tile.label}`}</ListItemText>
											<ListItemText className={clsx(listItemText, rightAlign)}>
												<span className={coloredTextContainer}>
													{Object.keys(tile.filters).length}
												</span>
												<span>{` ${t(
													'components.modals.createLayout.instructions.cloneLayout.filters'
												)}`}</span>
											</ListItemText>
										</ListItem>
									);
								})}
							</List>
						</Collapse>
					</div>
				);
			})}
		</List>
	);
};

SelectLayout.propTypes = {
	selectedUserLayouts: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	setSelectedLayout: PropTypes.func,
	selectedLayout: PropTypes.object,
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(SelectLayout));
