import querier from '../../../utilities/querier';

export const getPreviousLayout = (layouts, currentLayout) => {
	const activeLayoutIndex = layouts.findIndex((layout) => {
		return (
			layout.universalUniqueIdentifier ===
			currentLayout.universalUniqueIdentifier
		);
	});

	const nextIndex =
		activeLayoutIndex - 1 < 0 ? layouts.length - 1 : activeLayoutIndex - 1;

	return layouts[nextIndex];
};

export const getNextLayout = (layouts, currentLayout) => {
	const activeLayoutIndex = layouts.findIndex((layout) => {
		return (
			layout.universalUniqueIdentifier ===
			currentLayout.universalUniqueIdentifier
		);
	});

	const nextIndex = (activeLayoutIndex + 1) % layouts.length;

	return layouts[nextIndex];
};

export const getFirstLayout = (layouts) => {
	return layouts[0];
};

export const isActiveLayoutDeleted = (layouts, currentLayout) => {
	let result = false;

	if (layouts && currentLayout) {
		const currentLayoutUniversalUniqueIdentifier = querier(
			currentLayout,
			'universalUniqueIdentifier'
		);

		const matchingLayouts = querier(
			layouts,
			`[$[universalUniqueIdentifier = "${currentLayoutUniversalUniqueIdentifier}"]]`
		);

		result = matchingLayouts.length < 1;
	}

	return result;
};
