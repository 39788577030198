import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { numberWithCommas } from '../../../utilities/formatNumber';
import clsx from 'clsx';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { withTranslation } from 'react-i18next';
import { visuallyHidden } from '@mui/utils';
import TableSortLabel from '@mui/material/TableSortLabel';
import Skeleton from '@mui/material/Skeleton';

const styles = (theme) => ({
	box: {
		display: 'flex',
		flex: 1,
		alignSelf: 'stretch',
		flexFlow: 'column',
		padding: '5px 10px',
		flexBasis: '100%'
	},
	boxTitle: {
		fontSize: '1.2rem',
		marginTop: '10px',
		marginBotton: '10px'
	},
	stackContainer: {
		flex: 1,
		borderRadius: '5px',
		margin: '5px',
		border: `1px solid ${theme.palette.recoveryHistory.border}`,
		backgroundColor: theme.palette.common.white
	},
	reasonCell: {
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		overflow: 'hidden'
	},
	noBorder: {
		'&:last-child td, &:last-child th': { border: 0 }
	},
	activeColumnTitle: {
		fontWeight: 900
	}
});

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) {
			return order;
		}
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

const _cellColumnDefinition = [
	{
		id: 'reason',
		numeric: false,
		label: 'components.RecoveryHistory.RecoveryHistoryManager.reason'
	},
	{
		id: 'firstChargebackAmount',
		numeric: true,
		label: 'components.RecoveryHistory.RecoveryHistoryManager.chargebackDollars'
	},
	{
		id: 'chargebackRecoveryAmount',
		numeric: true,
		label: 'components.RecoveryHistory.RecoveryHistoryManager.recoveryDollars'
	},
	{
		id: 'successRate',
		numeric: true,
		label: 'components.RecoveryHistory.RecoveryHistoryManager.winPercent'
	}
];

const _renderRows = (data, isLoading, order, orderBy, classes, t) => {
	const { noBorder, reasonCell } = classes;

	if (isLoading) {
		return (
			<TableRow>
				<TableCell className={reasonCell}>
					<Skeleton variant="text" height={30} width={175} />
				</TableCell>
				<TableCell align={'right'} flex={1}>
					<Skeleton variant="text" height={30} width={70} />
				</TableCell>
				<TableCell align={'right'} flex={1}>
					<Skeleton variant="text" height={30} width={70} />
				</TableCell>
				<TableCell align={'right'} flex={1}>
					<Skeleton variant="text" height={30} width={70} />
				</TableCell>
			</TableRow>
		);
	} else if (data.length === 0) {
		return (
			<TableRow key={'no-data'} className={noBorder}>
				<TableCell>
					{t('components.Representment.TransactionsList.noData')}
				</TableCell>
			</TableRow>
		);
	} else {
		return stableSort(data, getComparator(order, orderBy)).map((row, index) => {
			return (
				<TableRow key={index} className={noBorder}>
					<TableCell className={reasonCell}>{row.reason}</TableCell>
					<TableCell align={'right'} flex={1}>
						{numberWithCommas(row.firstChargebackAmount)}
					</TableCell>
					<TableCell align={'right'} flex={1}>
						{numberWithCommas(row.chargebackRecoveryAmount)}
					</TableCell>
					<TableCell
						flex={1}
						align={'right'}
					>{`${row.successRate}%`}</TableCell>
				</TableRow>
			);
		});
	}
};

export const BreakdownBox = (props) => {
	const { classes, t, card, isLoading, data } = props;
	const { box, boxTitle, stackContainer, activeColumnTitle } = classes;

	const [order, setOrder] = React.useState('desc');
	const [orderBy, setOrderBy] = React.useState('successRate');

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const createSortHandler = (property) => (event) => {
		handleRequestSort(event, property);
	};

	//

	return (
		<Box className={box}>
			<Stack
				direction="column"
				justifyContent="flex-start"
				alignItems="center"
				className={stackContainer}
			>
				<span className={boxTitle}>{`${card} ${t(
					'components.RecoveryHistory.RecoveryHistoryManager.chargebackReasons'
				)}`}</span>
				<TableContainer>
					<Table size="small">
						<TableHead>
							<TableRow>
								{_cellColumnDefinition.map((headerCell, index) => {
									return (
										<TableCell
											key={headerCell.id}
											align={headerCell.numeric ? 'right' : 'left'}
											sortDirection={orderBy === headerCell.id ? order : false}
										>
											<TableSortLabel
												className={clsx(
													orderBy === headerCell.id && activeColumnTitle
												)}
												active={orderBy === headerCell.id}
												direction={orderBy === headerCell.id ? order : 'asc'}
												onClick={createSortHandler(headerCell.id)}
											>
												{t(headerCell.label)}
												{orderBy === headerCell.id ? (
													<Box component="span" sx={visuallyHidden}>
														{order === 'desc'
															? 'sorted descending'
															: 'sorted ascending'}
													</Box>
												) : null}
											</TableSortLabel>
										</TableCell>
									);
								})}
							</TableRow>
						</TableHead>
						<TableBody>
							{_renderRows(data, isLoading, order, orderBy, classes, t)}
						</TableBody>
					</Table>
				</TableContainer>
			</Stack>
		</Box>
	);
};

BreakdownBox.propTypes = {
	data: PropTypes.array,
	isLoading: PropTypes.bool,
	card: PropTypes.string,
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(BreakdownBox));
