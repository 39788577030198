import querier from '../../utilities/querier';

export default function (reportViewId, reportViews) {
	let reportViewIds = [];

	if (reportViews.length > 0) {
		reportViewIds = querier(
			reportViews,
			`[$[id = "${reportViewId}"].reportIds]`
		);
	}

	return reportViewIds;
}
