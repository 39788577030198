import * as actionTypes from './actionTypes';

export const getRecoveryHistoryByOutcome = (payload) => ({
	type: actionTypes.GET_RECOVERY_HISTORY_BY_OUTCOME,
	payload
});

export const getRecoveryHistoryByOutcomeCompleted = (payload) => ({
	type: actionTypes.GET_RECOVERY_HISTORY_BY_OUTCOME_COMPLETED,
	payload
});

export const getRecoveryHistoryByOutcomeFailed = (payload) => ({
	type: actionTypes.GET_RECOVERY_HISTORY_BY_OUTCOME_FAILED,
	payload
});

export const getRecoveryHistoryByCard = (payload) => ({
	type: actionTypes.GET_RECOVERY_HISTORY_BY_CARD,
	payload
});

export const getRecoveryHistoryByCardCompleted = (payload) => ({
	type: actionTypes.GET_RECOVERY_HISTORY_BY_CARD_COMPLETED,
	payload
});

export const getRecoveryHistoryByCardFailed = (payload) => ({
	type: actionTypes.GET_RECOVERY_HISTORY_BY_CARD_FAILED,
	payload
});

export const getRecoveryHistoryByReason = (payload) => ({
	type: actionTypes.GET_RECOVERY_HISTORY_BY_REASON,
	payload
});

export const getRecoveryHistoryByReasonCompleted = (payload) => ({
	type: actionTypes.GET_RECOVERY_HISTORY_BY_REASON_COMPLETED,
	payload
});

export const getRecoveryHistoryByReasonFailed = (payload) => ({
	type: actionTypes.GET_RECOVERY_HISTORY_BY_REASON_FAILED,
	payload
});
