import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

const styles = (theme) => ({
	root: {
		display: 'flex',
		alignItems: 'center'
	}
});

const NotificationDetailEditable = (props) => {
	const {
		classes,
		handleUpdate,
		message,
		setIsEditable,
		setUpdatedTitle,
		t,
		updatedTitle
	} = props;
	const { root } = classes;

	return (
		<div className={root}>
			<TextField
				sx={{ m: 1 }}
				multiline
				fullWidth
				label={t('pages.notifications.inputs.title')}
				value={updatedTitle}
				size="small"
				helperText={t('pages.notifications.inputs.updateTitle')}
				onChange={(e) => setUpdatedTitle(e.target.value)}
			/>
			<Button onClick={() => setIsEditable(false)}>
				{t('pages.notifications.timeline.cancelButton')}
			</Button>
			<Button
				onClick={() => handleUpdate()}
				disabled={updatedTitle === message}
			>
				{t('pages.notifications.timeline.update')}
			</Button>
		</div>
	);
};

NotificationDetailEditable.propTypes = {
	classes: PropTypes.object,
	handleUpdate: PropTypes.func,
	message: PropTypes.string,
	setIsEditable: PropTypes.func,
	setUpdatedTitle: PropTypes.func,
	t: PropTypes.func,
	updatedTitle: PropTypes.string
};

export default withStyles(styles)(
	withTranslation()(NotificationDetailEditable)
);
