export default [
	{
		id: 1,
		avatar: '/sempris/1/avatar/avatar.png',
		createdOn: '2022-01-31T00:00:00.000Z',
		email: 'ethan.soper@slyce360.com',
		firstName: 'Ethan',
		lastName: 'Soper',
		phone: '8043840463',
		roles: null,
		sub: '0b1d04cf-ba7b-4e7c-b03e-6d8bce4f2c5f',
		username: 'esoper'
	},
	{
		id: 2,
		avatar: '/sempris/2/avatar/avatar.jpg',
		createdOn: '2022-02-15T00:00:00.000Z',
		email: 'jason.pederson@slyce360.com',
		firstName: 'Jason',
		lastName: 'Pederson',
		phone: '9522582086',
		roles: null,
		sub: '1168a295-ea27-44bf-b04c-85e6d6ece91a',
		username: 'jpederson'
	},
	{
		id: 4,
		avatar: '/3/83/avatar/avatar421.jpg',
		createdOn: '2022-02-24T00:00:00.000Z',
		email: 'bradley.service@slyce360.com',
		firstName: 'Bradley',
		lastName: 'Service',
		phone: '9524125147',
		roles: 'Super',
		sub: '9278b0af-528f-4547-b481-3d66acb8f58d',
		username: 'bservice'
	},
	{
		id: 5,
		avatar: null,
		createdOn: '2022-03-09T00:00:00.000Z',
		email: 'Cristina.Petrescu@slyce360.com',
		firstName: 'Cristina',
		lastName: 'Petrescu',
		phone: null,
		roles: null,
		sub: 'd6a23939-c3aa-4e9f-bb32-925633ae1244',
		username: 'cpetrescu'
	},
	{
		id: 6,
		avatar: '/sempris/6/avatar/avatar.jpg',
		createdOn: '2022-05-04T00:00:00.000Z',
		email: 'eric.kinneberg@slyce360.com',
		firstName: 'Eric',
		lastName: 'Kinneberg',
		phone: null,
		roles: null,
		sub: '0391a42e-3fd7-43c0-a7a9-206cdd6070a6',
		username: 'ekinneberg'
	}
];
