import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import moment from 'moment';

import formatQueryParameters from '../../../helpers/chargebackSummary/formatQueryParameters';

import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { CircularProgress } from '@mui/material';

const styles = (theme) => ({
	container: {
		display: 'flex',
		flex: 1,
		flexDirection: 'row',
		alignSelf: 'stretch',
		justifyContent: 'flex-end',
		marginTop: '20px'
	},
	titleContainer: {
		display: 'flex',
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignContent: 'center'
	},
	title: {
		fontSize: '1.5rem',
		fontWeight: 600
	},
	valueSelect: {
		display: 'flex',
		flexFlow: 'row wrap',
		minWidth: '160px',
		fontSize: '1rem',
		margin: '0px 3px',
		backgroundColor: theme.palette.common.white
	},
	dateSelect: {
		fontSize: '.8rem',
		margin: '0px 3px',
		backgroundColor: theme.palette.common.white
	},
	submitButton: {
		marginLeft: '.8rem',
		cursor: 'pointer',
		'&hover': {
			backgroundColor: theme.palette.common.white
		}
	}
});

const _listOfProcessors = [
	'Fiserv',
	'BlueSnap Santander',
	'BlueSnap Privat',
	'IPPay',
	'Merrick'
];

const OptionsSelector = (props) => {
	const { classes, t, onSubmit, reportDataIsLoading } = props;
	const {
		container,
		titleContainer,
		title,
		valueSelect,
		dateSelect,
		submitButton
	} = classes;

	const [selectedProcessor, setSelectedProcessor] = React.useState(
		_listOfProcessors[0]
	);
	const [lookupDate, setLookupDate] = React.useState(
		moment().format('YYYY-MM-DD')
	);

	const onSubmitFilters = (selectedProcessor, lookupDate) => {
		const queryParameters = formatQueryParameters(
			selectedProcessor,
			lookupDate
		);

		onSubmit('1', queryParameters);
	};

	return (
		<div className={container}>
			<div className={titleContainer}>
				<Typography className={title} variant="h5">
					{t('pages.reports.chargebacks.summary.title')}
				</Typography>
			</div>
			<FormControl>
				<LocalizationProvider dateAdapter={AdapterDateFns}>
					<DatePicker
						label={t(
							'components.staticReportViews.ChargebackSummaryView.OptionSelector.lookupDate'
						)}
						value={lookupDate}
						onChange={(newValue) => {
							setLookupDate(newValue);
						}}
						renderInput={(params) => (
							<TextField {...params} className={dateSelect} />
						)}
					/>
				</LocalizationProvider>
			</FormControl>
			<FormControl>
				<InputLabel id="processor-label">
					{t(
						'components.staticReportViews.ChargebackSummaryView.OptionSelector.processor'
					)}
				</InputLabel>
				<Select
					labelId="processor-label"
					id="processor-select"
					label={t(
						'components.staticReportViews.ChargebackSummaryView.OptionSelector.processor'
					)}
					className={valueSelect}
					value={selectedProcessor}
					onChange={(e) => {
						setSelectedProcessor(e.target.value);
					}}
					renderValue={(selected) => <span>{selected}</span>}
				>
					{_listOfProcessors.map((option) => (
						<MenuItem key={option} value={option}>
							{option}
						</MenuItem>
					))}
				</Select>
			</FormControl>
			<Button
				className={submitButton}
				variant="outlined"
				onClick={() => {
					onSubmitFilters(selectedProcessor, lookupDate);
				}}
			>
				{reportDataIsLoading ? (
					<CircularProgress size="1rem" />
				) : (
					<span>Submit</span>
				)}
			</Button>
		</div>
	);
};

OptionsSelector.propTypes = {
	onSubmit: PropTypes.func,
	reportDataIsLoading: PropTypes.bool,
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(OptionsSelector));
