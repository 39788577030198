import * as htmlToImage from 'html-to-image';

export const handleBase64Encode = async (element) => {
	if (element) {
		const width = element.clientWidth;
		const height = element.clientHeight;

		return new Promise((resolve) => {
			htmlToImage.toCanvas(element, { width, height }).then((canvas) => {
				return resolve(canvas.toDataURL());
			});
		});
	}
};
