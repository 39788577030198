import { Auth } from 'aws-amplify';
import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { failureMessage } from '../ToastNotifications/actions';
import Logger from '../../utilities/logger';
import { authActions } from '../Auth';
import { multiFactorAuthConstants } from '../MultiFactorAuth';
import { userActions } from '../User';

import * as actions from './actions.js';
import * as actionTypes from './actionTypes.js';

export const activateMultiFactorAuth = (action$) =>
	action$.pipe(
		ofType(actionTypes.ACTIVATE_MULTI_FACTOR_AUTH),
		mergeMap(async (action) => {
			const user = action.payload.user;
			const challengeAnswer = action.payload.challengeAnswer;

			await Auth.verifyTotpToken(user, challengeAnswer);
			await Auth.setPreferredMFA(user, 'TOTP');

			return multiFactorAuthConstants.softwareMfa;
		}),
		switchMap((res) => [actions.activateMultiFactorAuthCompleted(res)]),
		catchError((error, source) => {
			Logger.error('Activate MFA Failure', error);

			return merge(
				of(
					actions.activateMultiFactorAuthFailed(error.message),
					failureMessage(error.message)
				),
				source
			);
		})
	);

export const verifyMultiFactorAuthCode = (action$) =>
	action$.pipe(
		ofType(actionTypes.VERIFY_MULTI_FACTOR_AUTH_CODE),
		mergeMap(async (action) => {
			const user = action.payload.user;
			const challengeAnswer = action.payload.challengeAnswer;

			const loggedUser = await Auth.confirmSignIn(
				user,
				challengeAnswer,
				multiFactorAuthConstants.softwareMfa
			);

			return {
				user: loggedUser
			};
		}),
		switchMap((res) => [
			actions.verifyMultiFactorAuthCodeCompleted(true),
			authActions.authUserCompleted(res),
			userActions.getUser(res.user.username)
		]),
		catchError((error, source) => {
			Logger.error('Verify MFA Code Failure', error);

			return merge(
				of(
					actions.verifyMultiFactorAuthCodeFailed(error.message),
					failureMessage(error.message)
				),
				source
			);
		})
	);

export const disableMultiFactorAuth = (action$) =>
	action$.pipe(
		ofType(actionTypes.DISABLE_MULTI_FACTOR_AUTH),
		mergeMap(async (action) => {
			const user = action.payload.user;
			const challengeAnswer = action.payload.challengeAnswer;

			await Auth.verifyTotpToken(user, challengeAnswer);
			await Auth.setPreferredMFA(user, 'NOMFA');

			return 'NOMFA';
		}),
		switchMap((res) => [actions.disableMultiFactorAuthCompleted(res)]),
		catchError((error, source) => {
			Logger.error('Disable MFA Failure', error);

			return merge(
				of(
					actions.disableMultiFactorAuthFailed(error.message),
					failureMessage(error.message)
				),
				source
			);
		})
	);
