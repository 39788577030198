import moment from 'moment';

export default function (tileList, layoutUniversalUniqueIdentifier, userId) {
	return tileList.map((tile) => {
		const currentMoment = moment().format('YYYY-MM-DD');

		if (tile.reportId) {
			return {
				universalUniqueIdentifier: tile.universalUniqueIdentifier,
				layoutUuid: layoutUniversalUniqueIdentifier,
				label: tile.label,
				userInterfaceDefinition: 'dashboardWidget',
				reportId: tile.reportId,
				dataViewStyle: 'line',
				minWidth: 700,
				maxWidth: 800,
				createdOn: currentMoment,
				createdByUserId: userId,
				updatedOn: currentMoment
			};
		} else {
			return {
				universalUniqueIdentifier: tile.universalUniqueIdentifier,
				layoutUuid: layoutUniversalUniqueIdentifier,
				label: tile.label,
				userInterfaceDefinition: tile.userInterfaceDefinition,
				minWidth: 400,
				maxWidth: 500,
				createdOn: currentMoment,
				createdByUserId: userId,
				updatedOn: currentMoment
			};
		}
	});
}
