import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { systemActions } from '../../../containers/System';
import { notificationActions } from '../../../containers/Notifications';
import DrawerNotifications from '../../../pages/Notifications/DrawerNotifications/DrawerNotifications';
import { useLocation } from 'react-router-dom';

const styles = (theme) => ({
	rightDrawer: {
		width: theme.panel.width,
		flexShrink: 0,
		animationFillMode: 'forwards',
		animationDuration: '0.25s',
		marginRight: `calc(${theme.panel.width} * -1)`
	},
	rightDrawerClose: {
		animationName: '$rightDrawerClose',
		height: 0
	},
	rightDrawerOpen: {
		animationName: '$rightDrawerOpen',
		maxHeight: '95vh',
		overflowY: 'scroll'
	},
	'@keyframes rightDrawerOpen': {
		from: {
			marginRight: `calc(${theme.panel.width} * -1)`
		},
		to: {
			marginRight: '0px'
		}
	},
	'@keyframes rightDrawerClose': {
		from: {
			marginRight: '0px'
		},
		to: {
			marginRight: `calc(${theme.panel.width} * -1)`
		}
	}
});

const RightDrawer = (props) => {
	const {
		classes,
		getNotifications,
		hasBeenLoaded,
		isLoading,
		notifications,
		rightDrawerIsOpen,
		toggleRightDrawer
	} = props;
	const { rightDrawer, rightDrawerOpen, rightDrawerClose } = classes;

	const location = useLocation();

	useEffect(() => {
		if (!hasBeenLoaded && !isLoading) {
			getNotifications();
		}
	}, [getNotifications, notifications]);

	useEffect(() => {
		if (location.pathname.includes('notifications') && rightDrawerIsOpen) {
			toggleRightDrawer();
		}
	}, [location, rightDrawerIsOpen, toggleRightDrawer]);

	return (
		<div
			className={clsx(
				rightDrawer,
				rightDrawerIsOpen ? rightDrawerOpen : rightDrawerClose
			)}
		>
			<DrawerNotifications
				data={notifications}
				hasBeenLoaded={hasBeenLoaded}
				isLoading={isLoading}
			/>
		</div>
	);
};

RightDrawer.propTypes = {
	classes: PropTypes.object,
	getNotifications: PropTypes.func,
	hasBeenLoaded: PropTypes.bool,
	isLoading: PropTypes.bool,
	notifications: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	rightDrawerIsOpen: PropTypes.bool,
	toggleRightDrawer: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		hasBeenLoaded: state.notificationReducer.getIn([
			'notifications',
			'hasBeenLoaded'
		]),
		isLoading: state.notificationReducer.getIn(['notifications', 'isLoading']),
		notifications: state.notificationReducer.getIn(['notifications', 'data']),
		rightDrawerIsOpen: state.systemReducer.get('rightDrawerIsOpen')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getNotifications: () => {
			dispatch(notificationActions.getNotifications());
		},
		toggleRightDrawer: () => {
			dispatch(systemActions.toggleRightDrawer());
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(RightDrawer));
