import axios from '../../utilities/axios';
import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { refreshToken } from '../../utilities/refreshToken';
import { failureMessage, successMessage } from '../ToastNotifications/actions';

import * as actions from './actions';
import * as actionTypes from './actionTypes';
import { primaryRestGateway } from '../../utilities/apiEndpointUtility';

import demoGetNotificationsEpic from '../../__Demo_Data__/Notifications/getNotificationsEpic';
import demoGetNotificationDetailsByIdEpic from '../../__Demo_Data__/Notifications/getNotificationDetailsByIdEpic';
import { demoWait } from '../../__Demo_Data__/mockRandomLoad';

export const addNotificationEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.ADD_NOTIFICATION),
		mergeMap(async (action) => {
			await refreshToken();
			const { assigned, tags, title, type } = action.payload;

			const result = await axios.post(
				`${primaryRestGateway()}/api/notifications`,
				{
					assigned,
					tags,
					title,
					type
				}
			);

			return result;
		}),
		switchMap((result) => [
			actions.addNotificationCompleted(result),
			actions.getNotifications(),
			successMessage('Successfully Added Notification')
		]),
		catchError((error, source) =>
			merge(
				of(
					actions.addNotificationFailed(error.message),
					failureMessage(error.message)
				),
				source
			)
		)
	);

export const addNotificationDetailEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.ADD_NOTIFICATION_DETAIL),
		mergeMap(async (action) => {
			// await refreshToken();
			const { assigned, notificationId, title, tags, type } = action.payload;

			// const result = await axios.post(
			// 	`${primaryRestGateway()}/api/notifications/${notificationId}/details`,
			// 	{
			// 		assigned,
			// 		title,
			// 		tags,
			// 		type
			// 	}
			// );

			return {
				data: action.payload,
				notificationId: action.payload.parentNotificationId
			};
		}),
		switchMap((result) => [
			actions.addNotificationDetailCompleted(result.data),
			actions.getNotificationDetailsById({
				notificationId: result.notificationId
			}),
			successMessage('Successfully Added Notification Detail')
		]),
		catchError((error, source) =>
			merge(
				of(
					actions.addNotificationDetailFailed(error.message),
					failureMessage(error.message)
				),
				source
			)
		)
	);

export const getNotificationsEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.GET_NOTIFICATIONS),
		mergeMap(async (action) => {
			await demoWait();
			// await refreshToken();

			// const result = await axios.get(
			// 	`${primaryRestGateway()}/api/notifications`
			// );

			// TODO: sorting needs to happen on returned notifications

			const response = {
				data: demoGetNotificationsEpic
			};

			return response.data;
		}),
		switchMap((response) => [
			actions.getNotificationsCompleted(response),
			successMessage('Successfully Returned Notifications')
		]),
		catchError((error, source) =>
			merge(
				of(
					actions.getNotificationsFailed(error.message),
					failureMessage(error.message)
				),
				source
			)
		)
	);

export const getNotificationDetailsByIdEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.GET_NOTIFICATION_DETAILS_BY_ID),
		mergeMap(async (action) => {
			await demoWait();

			//await refreshToken();
			const { notificationId } = action.payload;

			// const result = await axios.get(
			// 	`${primaryRestGateway()}/api/notifications/${notificationId}/details`
			// );
			const notificationDetails = demoGetNotificationDetailsByIdEpic.filter(
				(detail) => {
					return detail.parentNotificationId === notificationId;
				}
			);

			const response = {
				data: notificationDetails
			};

			// TODO: sorting needs to happen on returned notifications

			return response.data;
		}),
		switchMap((result) => [
			actions.getNotificationDetailsByIdCompleted(result),
			successMessage('Successfully Returned Notification Details')
		]),
		catchError((error, source) =>
			merge(
				of(
					actions.getNotificationDetailsByIdFailed(error.message),
					failureMessage(error.message)
				),
				source
			)
		)
	);

export const deleteNotificationEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.DELETE_NOTIFICATION),
		mergeMap(async (action) => {
			await refreshToken();

			const { notificationId } = action.payload;

			const result = await axios.delete(
				`${primaryRestGateway()}/api/notifications/${notificationId}`
			);

			return result.data;
		}),
		switchMap((result) => [
			actions.deleteNotificationCompleted(result),
			actions.getNotifications(),
			successMessage('Successfully Deleted Notification')
		]),
		catchError((error, source) =>
			merge(
				of(
					actions.deleteNotificationFailed(error.message),
					failureMessage(error.message)
				),
				source
			)
		)
	);

export const deleteNotificationDetailEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.DELETE_NOTIFICATION_DETAIL),
		mergeMap(async (action) => {
			await refreshToken();

			const { notificationId, detailId } = action.payload;

			const result = await axios.delete(
				`${primaryRestGateway()}/api/notifications/${notificationId}/details/${detailId}`
			);

			return { data: result.data, notificationId };
		}),
		switchMap((result) => [
			actions.deleteNotificationDetailCompleted(result),
			actions.getNotificationDetailsById({
				notificationId: result.notificationId
			}),
			successMessage('Successfully Deleted Notification Detail')
		]),
		catchError((error, source) =>
			merge(
				of(
					actions.deleteNotificationDetailFailed(error.message),
					failureMessage(error.message)
				),
				source
			)
		)
	);

export const updateNotificationEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.UPDATE_NOTIFICATION),
		mergeMap(async (action) => {
			await refreshToken();

			const { notificationId, title } = action.payload;

			const result = await axios.put(
				`${primaryRestGateway()}/api/notifications/${notificationId}`,
				{
					title
				}
			);

			return result.data;
		}),
		switchMap((result) => [
			actions.updateNotificationCompleted(result),
			actions.getNotifications(),
			successMessage('Successfully Updated Notification')
		]),
		catchError((error, source) =>
			merge(
				of(
					actions.updateNotificationFailed(error.message),
					failureMessage(error.message)
				),
				source
			)
		)
	);

export const updateNotificationDetailEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.UPDATE_NOTIFICATION_DETAIL),
		mergeMap(async (action) => {
			await refreshToken();

			const { notificationId, detailId, title } = action.payload;

			const result = await axios.put(
				`${primaryRestGateway()}/api/notifications/${notificationId}/details/${detailId}`,
				{
					title
				}
			);

			return { data: result.data, notificationId };
		}),
		switchMap((result) => [
			actions.updateNotificationDetailCompleted(result),
			actions.getNotificationDetailsById({
				notificationId: result.notificationId
			}),
			successMessage('Successfully Updated Notification Detail')
		]),
		catchError((error, source) =>
			merge(
				of(
					actions.updateNotificationDetailFailed(error.message),
					failureMessage(error.message)
				),
				source
			)
		)
	);
