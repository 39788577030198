import * as RecordingsEpics from './epics';
import * as recordingsActions from './actions';
import * as recordingsActionTypes from './actionTypes';
import { recordingsReducer } from './reducer';

const recordingsEpics = Object.values({ ...RecordingsEpics });

export {
	recordingsActions,
	recordingsActionTypes,
	recordingsEpics,
	recordingsReducer
};
