export default function (tile, tileBeingUpdated) {
	let result = false;

	if (tileBeingUpdated) {
		result =
			tile.universalUniqueIdentifier ===
			tileBeingUpdated.universalUniqueIdentifier;
	}

	return result;
}
