import React from 'react';
import PropTypes from 'prop-types';
import TextInputForm from './common/TextInputForm';

const organizationFormSettings = [
	{
		fieldName: 'friendlyName',
		translationPath: 'components.forms.OrganizationInformation.organization'
	},
	{
		fieldName: 'techContact',
		translationPath: 'components.forms.OrganizationInformation.techContact'
	},
	{
		fieldName: 'techContactEmail',
		translationPath: 'components.forms.OrganizationInformation.techContactEmail'
	},
	{
		fieldName: 'techContactPhone',
		translationPath: 'components.forms.OrganizationInformation.techContactPhone'
	}
];

export const OrganizationInformationForm = (props) => {
	const { organization, onUpdate, disableEdit } = props;
	return (
		<TextInputForm
			formStructure={organizationFormSettings}
			defaultValues={organization}
			onSubmit={onUpdate}
			disableEdit={disableEdit}
		/>
	);
};

OrganizationInformationForm.propTypes = {
	organization: PropTypes.object,
	onUpdate: PropTypes.func,
	disableEdit: PropTypes.bool
};

export default OrganizationInformationForm;
