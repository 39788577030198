import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import { notificationActions } from '../../../containers/Notifications';
import { mockTags } from '../../Notifications/NotificationTimeline/mocks/mockTags';
import clsx from 'clsx';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { usersActions } from '../../../containers/Users';

const styles = (theme) => ({
	addContainer: {
		width: '75%'
	},
	root: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center'
	},
	titleContainer: {
		display: 'flex',
		justifyContent: 'center'
	},
	topInputs: {
		display: 'flex',
		justifyContent: 'space-around'
	}
});

const AddNewDetailTemplate = (props) => {
	const {
		addNotificationDetail,
		attachments,
		classes,
		getListOfUsers,
		handleClose,
		listOfUsers,
		onSubmit,
		parentNotificationId,
		selected,
		t,
		typeOptions,
		user
	} = props;
	const { addContainer, root, titleContainer, topInputs } = classes;

	const [validToSubmit, setValidToSubmit] = React.useState(false);
	const [inputValues, setInputValues] = React.useState({
		assigned: user.id,
		title: '',
		notificationId: parentNotificationId,
		parentNotificationId,
		tags: [],
		type: ''
	});

	const handleInputChange = React.useCallback(
		(key, value) => {
			const inputs = Object.assign({}, inputValues);
			inputs[key] = value;

			if (key === 'assigned') {
				const getAssigned = listOfUsers.filter((user) => user.id === value)[0];

				inputs['assignedUserId'] = getAssigned.id;
				inputs[
					'assignedTo'
				] = `${getAssigned.firstName} ${getAssigned.lastName}`;
			}

			if (inputs.title !== '' && inputs.type !== '') {
				setValidToSubmit(true);
			} else {
				setValidToSubmit(false);
			}
			setInputValues(inputs);
		},
		[inputValues, setInputValues]
	);

	const handleSubmit = async () => {
		onSubmit(inputValues);
		handleClose();
	};

	useEffect(() => {
		if (!listOfUsers.size > 0) {
			getListOfUsers();
		}
	}, [getListOfUsers, listOfUsers]);

	return (
		<div className={root}>
			<div className={clsx(addContainer, topInputs)}>
				<TextField
					select
					SelectProps={{
						multiple: true
					}}
					sx={{ m: 1 }}
					label={`Tags Selected: (${
						inputValues.tags.length ? inputValues.tags.length : 0
					})`}
					value={inputValues.tags}
					color="primary"
					size="small"
					helperText={'Please Select All That Apply'}
					onChange={(e) => handleInputChange('tags', e.target.value)}
					InputLabelProps={{
						shrink: true
					}}
					style={{ width: '300px' }}
				>
					{mockTags.map((tag) => (
						<MenuItem key={tag} value={tag}>
							{tag}
						</MenuItem>
					))}
				</TextField>
				<TextField
					select
					sx={{ m: 1 }}
					label={t('pages.notifications.inputs.type')}
					value={inputValues.type ? inputValues.type : ''}
					color="primary"
					size="small"
					helperText={t('pages.notifications.inputs.select')}
					onChange={(e) => handleInputChange('type', e.target.value)}
					InputLabelProps={{
						shrink: true
					}}
					style={{ width: '190px' }}
				>
					{typeOptions.map((option) => {
						return (
							<MenuItem key={option} value={option}>
								{option}
							</MenuItem>
						);
					})}
				</TextField>
				<TextField
					select
					sx={{ m: 1 }}
					label={t('pages.notifications.inputs.users')}
					value={inputValues.assigned ? inputValues.assigned : ''}
					color="primary"
					size="small"
					helperText={t('pages.notifications.inputs.selectUser')}
					onChange={(e) => handleInputChange('assigned', e.target.value)}
					InputLabelProps={{
						shrink: true
					}}
					style={{ width: '210px' }}
				>
					{listOfUsers.map((user) => {
						return (
							<MenuItem key={user.sub} value={user.id}>
								{`${user.firstName} ${user.lastName}`}
							</MenuItem>
						);
					})}
				</TextField>
			</div>
			<div className={clsx(addContainer, root)}>
				<TextField
					fullWidth
					multiline
					sx={{ m: 1 }}
					label={t('pages.notifications.inputs.description')}
					InputLabelProps={{
						shrink: true
					}}
					onChange={(e) => handleInputChange('title', e.target.value)}
				/>
				<div>
					<Button onClick={handleClose}>{t('generic.buttons.cancel')}</Button>
					<Button onClick={handleSubmit} disabled={!validToSubmit}>
						{t('generic.buttons.submit')}
					</Button>
				</div>
			</div>
		</div>
	);
};

AddNewDetailTemplate.propTypes = {
	attachments: PropTypes.any,
	addNotificationDetail: PropTypes.func,
	classes: PropTypes.object,
	getListOfUsers: PropTypes.func,
	handleClose: PropTypes.func,
	listOfUsers: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	selected: PropTypes.object,
	onSubmit: PropTypes.func,
	parentNotificationId: PropTypes.string,
	t: PropTypes.func,
	typeOptions: PropTypes.array,
	user: PropTypes.object
};

const mapStateToProps = (state) => {
	return {
		user: state.userReducer.get('user')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		addNotificationDetail: (payload) => {
			dispatch(notificationActions.addNotificationDetail(payload));
		},
		getListOfUsers: () => {
			dispatch(usersActions.getUsers());
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(withTranslation()(AddNewDetailTemplate)));
