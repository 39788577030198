import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import { withTranslation } from 'react-i18next';

const styles = (theme) => ({
	actions: {
		display: 'flex'
	},
	button: {
		textTransform: 'none',
		color: theme.palette.common.black,
		margin: '2px'
	}
});

const ActionColumn = (props) => {
	const {
		classes,
		t,
		forecastName,
		handleDailyRedirect,
		handleFourteenDayRedirect
	} = props;
	const { actions, button } = classes;

	return (
		<div className={actions}>
			<Button
				size="small"
				disabled
				variant="outlined"
				className={button}
				color="primary"
				onClick={() => handleDailyRedirect(forecastName)}
			>
				{t('pages.reports.chargebacks.summary.actions.daily')}
			</Button>
			<Button
				size="small"
				disabled
				variant="outlined"
				className={button}
				color="primary"
				onClick={() => handleFourteenDayRedirect(forecastName)}
			>
				{t('pages.reports.chargebacks.summary.actions.fourteen')}
			</Button>
		</div>
	);
};

ActionColumn.propTypes = {
	forecastName: PropTypes.string,
	handleFourteenDayRedirect: PropTypes.func,
	handleDailyRedirect: PropTypes.func,
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(ActionColumn));
