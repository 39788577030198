export const GET_GROUPS = 'groupManagement/GET_GROUPS';
export const GET_GROUPS_COMPLETED = 'groupManagement/GET_GROUPS_COMPLETED';
export const GET_GROUPS_FAILED = 'groupManagement/GET_GROUPS_FAILED';

export const GET_MERCHANT_LIST = 'groupManagement/GET_MERCHANT_LIST';
export const GET_MERCHANT_LIST_COMPLETED =
	'groupManagement/GET_MERCHANT_LIST_COMPLETED';
export const GET_MERCHANT_LIST_FAILED =
	'groupManagement/GET_MERCHANT_LIST_FAILED';

export const GET_MERCHANTS_IN_GROUP = 'groupManagement/GET_MERCHANTS_IN_GROUP';
export const GET_MERCHANTS_IN_GROUP_COMPLETED =
	'groupManagement/GET_MERCHANTS_IN_GROUP_COMPLETED';
export const GET_MERCHANTS_IN_GROUP_FAILED =
	'groupManagement/GET_MERCHANTS_IN_GROUP_FAILED';

export const SET_MERCHANTS_IN_GROUP = 'groupManagement/SET_MERCHANTS_IN_GROUP';

export const GET_GROUP_NOTIFICATION_RULES =
	'groupManagement/GET_GROUP_NOTIFICATION_RULES';
export const GET_GROUP_NOTIFICATION_RULES_COMPLETED =
	'groupManagement/GET_GROUP_NOTIFICATION_RULES_COMPLETED';
export const GET_GROUP_NOTIFICATION_RULES_FAILED =
	'groupManagement/GET_GROUP_NOTIFICATION_RULES_FAILED';

export const SET_GROUP_NOTIFICATION_RULE_BEING_EDITED =
	'groupManagement/SET_GROUP_NOTIFICATION_RULE_BEING_EDITED';
