import axios from '../../utilities/axios';
import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { refreshToken } from '../../utilities/refreshToken';
import { failureMessage } from '../ToastNotifications/actions';
import { primaryRestGateway } from '../../utilities/apiEndpointUtility';

import * as actions from './actions.js';
import * as actionTypes from './actionTypes.js';

// MOCK FOR DEMO
import mockGetTileDataEpic from '../../__Demo_Data__/Tiles/getTileDataEpic';
import { demoWait } from '../../__Demo_Data__/mockRandomLoad';

export const getTileDataEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.GET_TILES_FOR_LAYOUT),
		mergeMap(async (action) => {
			await demoWait();
			//await refreshToken();
			const { layoutUniversalUniqueIdentifier } = action.payload;

			// const response = await axios.get(
			// 	`${primaryRestGateway()}/api/tiles/${layoutUniversalUniqueIdentifier}`
			// );

			const response = {
				data: mockGetTileDataEpic[layoutUniversalUniqueIdentifier]
			};

			return {
				layoutUniversalUniqueIdentifier,
				data: response.data.results
			};
		}),
		switchMap((res) => [actions.getTilesForLayoutCompleted(res)]),
		catchError((error, source) =>
			merge(
				of(
					actions.getTilesForLayoutFailed(error.message),
					failureMessage(error.message)
				),
				source
			)
		)
	);

export const updateTileEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.UPDATE_LAYOUT_TILE),
		mergeMap(async (action) => {
			await refreshToken();
			const {
				layoutUniversalUniqueIdentifier,
				universalUniqueIdentifier,
				data
			} = action.payload;

			const response = await axios.put(
				`${primaryRestGateway()}/api/tiles/${layoutUniversalUniqueIdentifier}/${universalUniqueIdentifier}`,
				data
			);

			return {
				layoutUniversalUniqueIdentifier,
				universalUniqueIdentifier,
				data: response.data.results
			};
		}),
		switchMap((res) => [actions.updateLayoutTileCompleted(res)]),
		catchError((error, source) =>
			merge(
				of(
					actions.updateLayoutTileFailed(error.message),
					failureMessage(error.message)
				),
				source
			)
		)
	);

export const getAvailableTilesEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.GET_AVAILABLE_TILES),
		mergeMap(async (action) => {
			await refreshToken();

			const response = await axios.get(`${primaryRestGateway()}/api/tiles`);

			return response.data.results;
		}),
		switchMap((res) => [actions.getAvailableTilesCompleted(res)]),
		catchError((error, source) =>
			merge(
				of(
					actions.getAvailableTilesFailed(error.message),
					failureMessage(error.message)
				),
				source
			)
		)
	);

export const addTilesToLayoutEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.ADD_TILES_TO_LAYOUT),
		mergeMap(async (action) => {
			await refreshToken();
			const { layoutUniversalUniqueIdentifier, tiles } = action.payload;

			const response = await axios.post(
				`${primaryRestGateway()}/api/tiles/${layoutUniversalUniqueIdentifier}`,
				{
					tiles
				}
			);

			return {
				layoutUniversalUniqueIdentifier,
				data: response.data.results
			};
		}),
		switchMap((res) => [actions.addTilesToLayoutCompleted(res)]),
		catchError((error, source) =>
			merge(
				of(
					actions.addTilesToLayoutFailed(error.message),
					failureMessage(error.message)
				),
				source
			)
		)
	);

export const deleteTilesByLayoutEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.DELETE_TILES_BY_LAYOUT),
		mergeMap(async (action) => {
			await refreshToken();
			const { universalUniqueId } = action.payload;

			await axios.delete(
				`${primaryRestGateway()}/api/tiles/${universalUniqueId}`
			);

			return {
				universalUniqueId
			};
		}),
		switchMap((res) => [actions.deleteTilesByLayoutCompleted(res)]),
		catchError((error, source) =>
			merge(
				of(
					actions.deleteTilesByLayoutFailed(error.message),
					failureMessage(error.message)
				),
				source
			)
		)
	);

export const deleteTileFromLayoutEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.DELETE_TILE_FROM_LAYOUT),
		mergeMap(async (action) => {
			await refreshToken();
			const {
				layoutUniversalUniqueIdentifier,
				tileUniversalUniqueIdentifier
			} = action.payload;

			const response = await axios.delete(
				`${primaryRestGateway()}/api/tiles/${layoutUniversalUniqueIdentifier}/${tileUniversalUniqueIdentifier}`
			);

			return {
				layoutUniversalUniqueIdentifier,
				data: response.data.results
			};
		}),
		switchMap((res) => [actions.deleteTileFromLayoutCompleted(res)]),
		catchError((error, source) =>
			merge(
				of(
					actions.deleteTileFromLayoutFailed(error.message),
					failureMessage(error.message)
				),
				source
			)
		)
	);
