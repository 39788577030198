import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import { capitalCase } from 'change-case';
import deepCopy from '../../../utilities/deepCopy';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

const styles = (theme) => ({
	box: {
		width: '30vw',
		height: '50vh',
		backgroundColor: '#ffccc00',
		overflowY: 'scroll'
	},
	button: {
		textTransform: 'none',
		margin: '5px'
	},
	columnStyle: {
		display: 'flex',
		flexDirection: 'column'
	},
	listItem: {
		display: 'flex',
		alignItems: 'center'
	},
	modal: {
		display: 'flex',
		marginTop: '5vh',
		alignItems: 'center',
		justifyContent: 'center'
	},
	modalTitle: {
		display: 'flex',
		justifyContent: 'space-between'
	}
});

const ColumnController = (props) => {
	const { classes, columns, t } = props;
	const { box, button, columnStyle, listItem, modal, modalTitle } = classes;

	const [open, setOpen] = useState(false);

	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const onColumnClicked = (columnName) => {
		const { columns, onUpdateColumns } = props;
		const updatedColumns = deepCopy(columns);

		const columnToUpdate = updatedColumns.find((column) => {
			return column.name === columnName;
		});

		columnToUpdate.isDisplayed = !columnToUpdate.isDisplayed;

		onUpdateColumns(updatedColumns);
	};

	const selectAll = (value) => {
		const { columns, onUpdateColumns } = props;

		const updatedColumns = deepCopy(columns);

		updatedColumns.forEach((column) => {
			column.isDisplayed = value;
		});

		onUpdateColumns(updatedColumns);
	};

	return (
		<Fragment>
			<div
				style={{
					display: 'flex',
					justifyContent: 'flex-end',
					marginBottom: '2vh',
					padding: '5px'
				}}
			>
				<Button className={button} onClick={handleOpen} variant="outlined">
					{t('pages.reports.chargebacks.itemization.table.columns')}
				</Button>
			</div>
			<Modal open={open} onClose={handleClose} className={modal}>
				<Box className={box}>
					<Paper className={columnStyle} square={true}>
						<div className={modalTitle}>
							<div>
								<Typography variant="h6">
									{t(
										'pages.reports.chargebacks.itemization.table.columnselect'
									)}
								</Typography>
							</div>
							<div>
								<Button
									onClick={() => selectAll(true)}
									className={button}
									variant="outlined"
								>
									{t('pages.reports.chargebacks.itemization.table.selectall')}
								</Button>
								<Button
									onClick={() => selectAll(false)}
									className={button}
									variant="outlined"
								>
									{t('pages.reports.chargebacks.itemization.table.deselectall')}
								</Button>
							</div>
						</div>
						<div style={{ display: 'flex', flexFlow: 'row wrap' }}>
							{columns.map((column) => {
								return (
									<div
										style={{ flexBasis: '50%' }}
										key={column.name}
										className={listItem}
									>
										<Checkbox
											size="small"
											checked={column.isDisplayed}
											onClick={() => onColumnClicked(column.name)}
										/>
										<Typography variant="subtitle2">
											{capitalCase(column.name)}
										</Typography>
									</div>
								);
							})}
						</div>
					</Paper>
				</Box>
			</Modal>
		</Fragment>
	);
};

ColumnController.propTypes = {
	classes: PropTypes.object,
	columns: PropTypes.array,
	onUpdateColumns: PropTypes.func,
	selectAll: PropTypes.func,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(ColumnController));
