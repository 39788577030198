export default function (midList) {
	let groupbedByMidLabel = {};

	midList.reduce(function (result, item) {
		if (!result[item.label]) {
			result[item.label] = {
				ids: [],
				paymentEnablerAcquiringBankId: item.paymentEnablerAcquiringBankId,
				merchantIds: []
			};
		}

		result[item.label].ids.push(item.id);
		result[item.label].merchantIds.push(item.merchantId);

		return result;
	}, groupbedByMidLabel);

	const results = [];

	Object.entries(groupbedByMidLabel).forEach(([key, value]) => {
		results.push({
			label: key,
			...value
		});
	});

	return results;
}
