import React, { Fragment } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { sentenceCase, capitalCase } from 'change-case';

import generateInitialFilters from '../../../helpers/widget/generateInitialFilters';

import FilterSelectDialog from './partials/FilterSelectDialog';
import CompressedAppBarSkeleton from './skeletons/CompressedAppBarSkeleton';

import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import FilterListIcon from '@mui/icons-material/FilterList';

const styles = (theme) => ({
	toolbar: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center'
	},
	actionSection: {
		display: 'flex',
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'flex-end',
		alignItems: 'center'
	},
	labelText: {
		marginLeft: '5px',
		fontSize: '1.2rem',
		color: theme.palette.widget.appBar.label
	},
	icons: {
		color: theme.palette.widget.appBar.icons,
		cursor: 'pointer'
	}
});

const CompressedAppBar = (props) => {
	const { classes, data, isLoading, hasBeenLoaded, error, onSubmit } = props;
	const { toolbar, actionSection, labelText, icons } = classes;

	const [filters, setFilters] = React.useState(undefined);
	const [isFilterDialogOpen, setIsFilterDialogOpen] = React.useState(false);

	React.useEffect(() => {
		if (hasBeenLoaded) {
			const initialFilters = generateInitialFilters(data.options);
			setFilters(initialFilters);
		}
	}, [hasBeenLoaded, data]);

	const onFilterUpdate = (updatedFilters) => {
		setFilters(updatedFilters);
	};

	const onSubmitFilters = () => {
		onSubmit(filters);
	};

	const onToggleFilterSelectDialog = () => {
		setIsFilterDialogOpen(!isFilterDialogOpen);
	};

	return (
		<Toolbar className={toolbar}>
			{!hasBeenLoaded || !filters || isLoading || error ? (
				<CompressedAppBarSkeleton />
			) : (
				<Fragment>
					<Typography variant="subtitle2" noWrap className={labelText}>
						{capitalCase(sentenceCase(data.label))}
					</Typography>
					<div className={actionSection}>
						<FilterListIcon
							className={icons}
							onClick={onToggleFilterSelectDialog}
						/>
					</div>
				</Fragment>
			)}
			{hasBeenLoaded && filters && !error && (
				<FilterSelectDialog
					options={data.options}
					filters={filters}
					isOpen={isFilterDialogOpen}
					onClose={onToggleFilterSelectDialog}
					onFilterUpdate={onFilterUpdate}
					onSubmitFilters={onSubmitFilters}
				/>
			)}
		</Toolbar>
	);
};

CompressedAppBar.propTypes = {
	data: PropTypes.object,
	isLoading: PropTypes.bool,
	hasBeenLoaded: PropTypes.bool,
	error: PropTypes.string,
	filters: PropTypes.object,
	onFilterUpdate: PropTypes.func,
	onSubmit: PropTypes.func,
	classes: PropTypes.object
};

export default withStyles(styles)(CompressedAppBar);
