import * as actionTypes from './actionTypes';

export const getUser = (payload) => ({
	type: actionTypes.GET_USER,
	payload
});

export const getUserCompleted = (payload) => ({
	type: actionTypes.GET_USER_COMPLETED,
	payload
});

export const getUserFailed = (payload) => ({
	type: actionTypes.GET_USER_FAILED,
	payload
});

export const updateUser = (payload) => ({
	type: actionTypes.UPDATE_USER,
	payload
});

export const updateUserCompleted = (payload) => ({
	type: actionTypes.UPDATE_USER_COMPLETED,
	payload
});

export const updateUserFailed = (payload) => ({
	type: actionTypes.UPDATE_USER_FAILED,
	payload
});

export const updateUserAvatar = (payload) => ({
	type: actionTypes.UPDATE_USER_AVATAR,
	payload
});

export const updateUserAvatarCompleted = (payload) => ({
	type: actionTypes.UPDATE_USER_AVATAR_COMPLETED,
	payload
});

export const updateUserAvatarFailed = (payload) => ({
	type: actionTypes.UPDATE_USER_AVATAR_FAILED,
	payload
});

export const resetUserAvatarUpload = (payload) => ({
	type: actionTypes.RESET_USER_AVATAR_UPLOAD,
	payload
});

export const getUserFavoriteReports = (payload) => ({
	type: actionTypes.GET_USER_FAVORITE_REPORTS,
	payload
});

export const getUserFavoriteReportsCompleted = (payload) => ({
	type: actionTypes.GET_USER_FAVORITE_REPORTS_COMPLETED,
	payload
});

export const getUserFavoriteReportsFailed = (payload) => ({
	type: actionTypes.GET_USER_FAVORITE_REPORTS_FAILED,
	payload
});

export const addUserFavoriteReport = (payload) => ({
	type: actionTypes.ADD_USER_FAVORITE_REPORT,
	payload
});

export const addUserFavoriteReportCompleted = (payload) => ({
	type: actionTypes.ADD_USER_FAVORITE_REPORT_COMPLETED,
	payload
});

export const addUserFavoriteReportFailed = (payload) => ({
	type: actionTypes.ADD_USER_FAVORITE_REPORT_FAILED,
	payload
});

export const removeUserFavoriteReport = (payload) => ({
	type: actionTypes.REMOVE_USER_FAVORITE_REPORT,
	payload
});

export const removeUserFavoriteReportCompleted = (payload) => ({
	type: actionTypes.REMOVE_USER_FAVORITE_REPORT_COMPLETED,
	payload
});

export const removeUserFavoriteReportFailed = (payload) => ({
	type: actionTypes.REMOVE_USER_FAVORITE_REPORT_FAILED,
	payload
});

export const logout = (payload) => ({
	type: actionTypes.LOGOUT,
	payload
});
