import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import ColumnController from './ColumnController';
import TableView from '../../Table/TableView';
// import { sanitizeTableRow } from '../../../helpers/itemizationReport/sanitizeTableRow';
import generateColumnList from '../../../helpers/itemizationReport/generateColumnList';
import CircularProgress from '@mui/material/CircularProgress';
import { useHistory } from 'react-router-dom';
import { modalActions } from '../../../containers/Modal';

const styles = (theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		width: '100vw'
	},
	tableContainer: {
		padding: '1vw'
	},
	loadingContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center'
	},
	noData: {
		alignSelf: 'center'
	}
});

const DynamicItemizationTable = (props) => {
	const { classes, data, hasBeenLoaded, isLoading, t, toggleModal } = props;
	const { root, noData, tableContainer, loadingContainer } = classes;

	const history = useHistory();

	const [columns, setColumns] = React.useState([]);

	React.useEffect(() => {
		if (data && hasBeenLoaded) {
			const generatedColumns = generateColumnList(data, openRecordingsModal);
			setColumns(generatedColumns);
		}
	}, [data, hasBeenLoaded, history, setColumns]);

	const openRecordingsModal = (orderNumber) => {
		toggleModal();
		history.push(
			`/reports/b8b804d8-e685-410c-bb7f-f0d03e531459/recordings/${orderNumber}`
		);
	};

	const filterColumnData = (columns) => {
		return columns.filter((column) => column.isDisplayed === true);
	};

	const updateColumns = (updatedColumns) => {
		setColumns(updatedColumns);
	};

	return (
		<div className={root}>
			{data === undefined ? (
				<p className={noData}>
					{t('pages.reports.chargebacks.itemization.submitToLoad')}
				</p>
			) : (
				<div>
					{!isLoading && columns.length > 0 ? (
						<div className={tableContainer}>
							<ColumnController
								columns={columns}
								onUpdateColumns={updateColumns}
							/>
							<TableView
								data={data}
								pageSize={10}
								columns={filterColumnData(columns)}
								openRecordingsModal={openRecordingsModal}
							/>
						</div>
					) : (
						<div className={loadingContainer}>
							<CircularProgress />
						</div>
					)}
				</div>
			)}
		</div>
	);
};

DynamicItemizationTable.propTypes = {
	classes: PropTypes.object,
	data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	filters: PropTypes.object,
	handleSubmit: PropTypes.func,
	hasBeenLoaded: PropTypes.bool,
	isLoading: PropTypes.bool,
	t: PropTypes.func,
	toggleModal: PropTypes.func
};

const mapDispatchToProps = (dispatch) => {
	return {
		toggleModal: () => {
			dispatch(modalActions.toggleRecordingsModal());
		}
	};
};

export default connect(
	null,
	mapDispatchToProps
)(withStyles(styles)(withTranslation()(DynamicItemizationTable)));
