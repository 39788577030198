import { fromJS } from 'immutable';
import * as actionTypes from './actionTypes';

export const initialState = fromJS({
	error: undefined,
	hasBeenLoaded: false,
	isLoading: false,
	recordingPath: ''
});

export const recordingsReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_TEMPORARY_RECORDING_DIRECTORY:
			return state.set('isLoading', true);

		case actionTypes.GET_TEMPORARY_RECORDING_DIRECTORY_COMPLETED:
			return state
				.set('isLoading', false)
				.set('hasBeenLoaded', true)
				.set('recordingPath', action.payload);

		case actionTypes.GET_TEMPORARY_RECORDING_DIRECTORY_FAILED:
			return state.set('isLoading', false).set('error', action.payload);

		default:
			return state;
	}
};
