import querier from '../../../utilities/querier';
import deepCopy from '../../../utilities/deepCopy';
import findLayoutInList from '../../layouts/findLayoutInList';

const _checkForValidTile = (tile) => {
	return tile && tile.universalUniqueIdentifier;
};

const _checkIfRowIsFull = (currentRowWidthUsed, nextTileWidth, screenWidth) => {
	return (
		currentRowWidthUsed === screenWidth ||
		currentRowWidthUsed + nextTileWidth > screenWidth
	);
};

const _determineTileSizeProperty = (screenWidth) => {
	let tileSizeProperty = 'maxWidth';

	if (screenWidth < 1200) {
		tileSizeProperty = 'minWidth';
	}

	return tileSizeProperty;
};

export default function (currentLayoutTiles, screenWidth) {
	const effectiveScreenWidth = screenWidth - 5;
	const tileSizeProperty = _determineTileSizeProperty(effectiveScreenWidth);

	const tileList = querier(currentLayoutTiles, '[$.data]');

	const rowDefinitions = [];

	if (tileList.length > 0 && _checkForValidTile(tileList[0])) {
		let currentRow = [];
		let currentRowWidthUsed = 0;

		tileList.forEach((tile) => {
			const tileWidth = tile[tileSizeProperty];

			tile.tileWidth = tileWidth;
			tile.isCompressed = tileSizeProperty !== 'maxWidth';

			if (
				_checkIfRowIsFull(currentRowWidthUsed, tileWidth, effectiveScreenWidth)
			) {
				rowDefinitions.push(deepCopy(currentRow));
				currentRow = [];
				currentRowWidthUsed = 0;

				currentRow.push(tile);
				currentRowWidthUsed += tileWidth;
			} else {
				currentRow.push(tile);
				currentRowWidthUsed += tileWidth;
			}
		});

		rowDefinitions.push(deepCopy(currentRow));
	}

	return rowDefinitions;
}
