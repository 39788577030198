import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';

import RepresentmentTextField from './components/RepresentmentTextField';

import Collapse from '@mui/material/Collapse';

import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';

const styles = (theme) => ({
	container: {
		display: 'flex',
		flexGrow: 1,
		flexFlow: 'column',
		padding: '10px',
		margin: '10px',
		borderRadius: '10px',
		border: `1px solid ${theme.palette.representment.sectionContainer.border}`,
		backgroundColor: theme.palette.representment.sectionContainer.background
	},
	sectionTitle: {
		backgroundColor:
			theme.palette.representment.sectionContainer.sectionTitleBackground,
		borderTopLeftRadius: '5px',
		borderTopRightRadius: '5px',
		width: 'fit-content',
		fontSize: '1rem',
		color: theme.palette.representment.sectionContainer.sectionText,
		margin: '-22px 0 0 2px',
		padding: '0 5px 0 5px'
	},
	row: {
		display: 'flex',
		flex: 1,
		flexDirection: 'row'
	},
	flex: {
		flexGrow: 1
	}
});

export const Overview = (props) => {
	const { classes, t, data } = props;
	const { container, sectionTitle, row, flex } = classes;

	const [collapsedDescription, setCollapsedDescription] = React.useState(false);

	return (
		<div className={container}>
			<div className={sectionTitle}>
				{t('components.Representment.Overview.label')}
			</div>
			<div className={row}>
				<RepresentmentTextField
					label={t('components.Representment.Overview.mid')}
					id="mid"
					defaultValue={data.mid}
				/>
				<RepresentmentTextField
					label={t('components.Representment.Overview.billingDescriptor')}
					id="billingDescriptor"
					defaultValue={data.billingDescriptor}
				/>
				<RepresentmentTextField
					id="billingPhoneNumber"
					label={t('components.Representment.Overview.billingPhoneNumber')}
					defaultValue={data.billingPhoneNumber}
				/>
			</div>
			<div className={row}>
				<Collapse in={collapsedDescription} collapsedSize={40} className={flex}>
					<RepresentmentTextField
						id="programDescription"
						label={t('components.Representment.Overview.programDescription')}
						defaultValue={data.programDesctription}
						isMultiline={true}
						isFlexed={true}
					/>
				</Collapse>
				<div
					onClick={() => {
						setCollapsedDescription(!collapsedDescription);
					}}
				>
					{!collapsedDescription ? <UnfoldMoreIcon /> : <UnfoldLessIcon />}
				</div>
			</div>
		</div>
	);
};

Overview.propTypes = {
	data: PropTypes.object,
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(Overview));
