import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';

const styles = (theme) => ({
	listEditIcon: {
		paddingRight: '10px',
		color: theme.palette.icons.default,
		'&:hover': {
			color: theme.palette.icons.selected
		}
	},
	disabled: {
		color: theme.palette.icons.disabled,
		paddingRight: '10px',
		cursor: 'default'
	}
});

export const EditIconWrapper = (props) => {
	const { classes, t, uuid, isMarkedForDeletion, onEdit } = props;
	const {
		listEditIcon,
		disabled
	} = classes;

	return (
		<>
			{ isMarkedForDeletion
				? <span className={disabled}><EditIcon /></span>
				: <Tooltip placement='left' title={t('components.modals.ManageDashboard.edit')}>
					<span className={listEditIcon} onClick={() => { onEdit(uuid); }}><EditIcon /></span>
				</Tooltip>
			}
		</>
	);
};

EditIconWrapper.propTypes = {
	uuid: PropTypes.string,
	isMarkedForDeletion: PropTypes.bool,
	onEdit: PropTypes.func,
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(EditIconWrapper));
