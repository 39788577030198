import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import TransferList from './TransferList';
import NotificationSettings from './NotificationSettings';
import { groupManagementActions } from '../../../containers/GroupManagement';
import clsx from 'clsx';
import getDynamicItemFromList from '../../../helpers/groupManagement/getDynamicItemFromList';

import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { withSize } from 'react-sizeme';
import TransferListActionRow from './TransferListActionRow';

const styles = (theme) => ({
	container: {
		display: 'flex',
		alignSelf: 'stretch',
		flex: 4,
		flexDirection: 'column',
		margin: '10px'
	},
	tabsDisabled: {
		'& .MuiTabs-indicator': {
			display: 'none'
		}
	},
	tab: {
		backgroundColor: theme.palette.common.offWhite
	},
	centered: {
		display: 'flex',
		padding: '20px',
		justifyContent: 'center',
		alignItems: 'center'
	},
	transferListContainer: {
		display: 'flex',
		flexDirection: 'column'
	}
});

const _renderManagementArea = (
	selectedGroup,
	selectedTab,
	merchantList,
	merchantListIsLoading,
	merchantListHasBeenLoaded,
	selectedGroupMerchantList,
	setMerchantsInGroup,
	selectedGroupNotificationRules,
	setGroupNotificationRuleBeingEdited,
	size,
	classes
) => {
	const { centered, transferListContainer } = classes;
	let rendered = <></>;

	if (selectedGroup && selectedGroup.id) {
		if (
			selectedTab === 0 &&
			!merchantListIsLoading &&
			merchantListHasBeenLoaded &&
			selectedGroupMerchantList &&
			selectedGroupMerchantList.hasBeenLoaded
		) {
			rendered = (
				<div className={transferListContainer}>
					<TransferListActionRow />
					<TransferList
						selectedGroup={selectedGroup}
						allMerchantsList={merchantList}
						currentGroupMerchantsList={selectedGroupMerchantList.data}
						setMerchantsInGroup={setMerchantsInGroup}
						size={size}
					/>
				</div>
			);
		} else if (
			selectedTab === 1 &&
			selectedGroupNotificationRules &&
			selectedGroupNotificationRules.hasBeenLoaded
		) {
			rendered = (
				<NotificationSettings
					selectedGroup={selectedGroup}
					notificationRulesList={selectedGroupNotificationRules}
					setGroupNotificationRuleBeingEdited={
						setGroupNotificationRuleBeingEdited
					}
				/>
			);
		} else {
			rendered = <CircularProgress />;
		}
	} else {
		rendered = (
			<Container className={centered}>
				Please select group from left to manage.
			</Container>
		);
	}

	return rendered;
};

export const ManagementArea = (props) => {
	const {
		classes,
		selectedGroup,
		merchantList,
		merchantListIsLoading,
		merchantListHasBeenLoaded,
		groupMerchants,
		getMerchantList,
		getMerchantsInGroup,
		setMerchantsInGroup,
		groupNotificationRules,
		getGroupNotificationRules,
		setGroupNotificationRuleBeingEdited,
		size
	} = props;
	const { container, tabsDisabled, tab } = classes;

	const [selectedTab, setSelectedTab] = React.useState(0);

	const selectedGroupMerchantList = getDynamicItemFromList(
		groupMerchants,
		selectedGroup
	);

	const selectedGroupNotificationRules = getDynamicItemFromList(
		groupNotificationRules,
		selectedGroup
	);

	React.useEffect(() => {
		if (!merchantListIsLoading && !merchantListHasBeenLoaded) {
			getMerchantList();
		}
	}, [merchantListIsLoading, merchantListHasBeenLoaded, getMerchantList]);

	React.useEffect(() => {
		if (
			selectedGroup &&
			selectedGroup.id &&
			!selectedGroupMerchantList.data &&
			!selectedGroupMerchantList.isLoading &&
			!selectedGroupMerchantList.hasBeenLoaded
		) {
			getMerchantsInGroup({
				id: selectedGroup.id
			});
		}
	}, [selectedGroupMerchantList, selectedGroup, getMerchantsInGroup]);

	React.useEffect(() => {
		if (
			selectedGroup &&
			selectedGroup.id &&
			!selectedGroupNotificationRules.data &&
			!selectedGroupNotificationRules.isLoading &&
			!selectedGroupNotificationRules.hasBeenLoaded
		) {
			getGroupNotificationRules({
				id: selectedGroup.id
			});
		}
	}, [
		selectedGroupNotificationRules,
		selectedGroup,
		getGroupNotificationRules
	]);

	const onSelectTab = (newValue) => {
		setSelectedTab(newValue);
	};

	const isDisabled = !selectedGroup || !selectedGroup.id;

	return (
		<div className={container}>
			<ButtonGroup variant="outlined" aria-label="outlined button group">
				<Button
					style={{ width: '100%' }}
					onClick={() => onSelectTab(0)}
					variant={selectedTab === 0 ? 'contained' : 'outlined'}
				>
					Add / Remove Merchant
				</Button>
				<Button
					style={{ width: '100%' }}
					onClick={() => onSelectTab(1)}
					variant={selectedTab === 1 ? 'contained' : 'outlined'}
				>
					Set Notifications
				</Button>
			</ButtonGroup>
			{_renderManagementArea(
				selectedGroup,
				selectedTab,
				merchantList,
				merchantListIsLoading,
				merchantListHasBeenLoaded,
				selectedGroupMerchantList,
				setMerchantsInGroup,
				selectedGroupNotificationRules,
				setGroupNotificationRuleBeingEdited,
				size,
				classes
			)}
		</div>
	);
};

ManagementArea.propTypes = {
	merchantList: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	merchantListIsLoading: PropTypes.bool,
	merchantListHasBeenLoaded: PropTypes.bool,
	groupMerchants: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	groupNotificationRules: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.array
	]),
	selectedGroup: PropTypes.object,
	getMerchantList: PropTypes.func,
	getMerchantsInGroup: PropTypes.func,
	setMerchantsInGroup: PropTypes.func,
	getGroupNotificationRules: PropTypes.func,
	setGroupNotificationRuleBeingEdited: PropTypes.func,
	classes: PropTypes.object,
	t: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		merchantList: state.groupManagementReducer.getIn(['merchantList', 'data']),
		merchantListIsLoading: state.groupManagementReducer.getIn([
			'merchantList',
			'isLoading'
		]),
		merchantListHasBeenLoaded: state.groupManagementReducer.getIn([
			'merchantList',
			'hasBeenLoaded'
		]),
		groupMerchants: state.groupManagementReducer.get('groupMerchants'),
		groupNotificationRules: state.groupManagementReducer.get(
			'groupNotificationRules'
		)
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getMerchantList: (payload) => {
			dispatch(groupManagementActions.getMerchantList(payload));
		},
		getMerchantsInGroup: (payload) => {
			dispatch(groupManagementActions.getMerchantsInGroup(payload));
		},
		setMerchantsInGroup: (payload) => {
			dispatch(groupManagementActions.setMerchantsInGroup(payload));
		},
		getGroupNotificationRules: (payload) => {
			dispatch(groupManagementActions.getGroupNotificationRules(payload));
		},
		setGroupNotificationRuleBeingEdited: (payload) => {
			dispatch(
				groupManagementActions.setGroupNotificationRuleBeingEdited(payload)
			);
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(
	withStyles(styles)(
		withTranslation()(withSize({ monitorHeight: true })(ManagementArea))
	)
);
