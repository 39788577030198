import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@mui/styles';
import CardQuickView from './CardQuickView';
import DisputeChart from './DisputeChart';

import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Typography from '@mui/material/Typography';

const styles = (theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center'
	},
	row: {
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'row',
		alignSelf: 'stretch'
	},
	chartHeader: {
		display: 'flex',
		flexDirection: 'row',
		alignSelf: 'stretch',
		justifyContent: 'flex-start'
	},
	unitToggle: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		minWidth: '100px'
	},
	headerTextContainer: {
		display: 'flex',
		flexDirection: 'row',
		// justifyContent: 'center',
		// alignItems: 'center',
		fontSize: '18px',
		fontWeight: 'bold',
		marginBottom: '8px',
		// flex: 3,
		// textAlign: 'center',
		color: theme.palette.primary.main
	}
});

const _cardData = [
	{
		card: 'Amex',
		percentage: '3.75',
		transactions: '4,869',
		chargebacks: '231',
		mitigation: '-',
		indicator: 'red'
	},
	{
		card: 'Discover',
		percentage: '2.10',
		transactions: '36,878',
		chargebacks: '912',
		mitigation: '154',
		indicator: 'red'
	},
	{
		card: 'Mastercard',
		percentage: '0.88',
		transactions: '1,285,338',
		chargebacks: '5,187',
		mitigation: '3,553',
		indicator: 'green'
	},
	{
		card: 'Visa',
		percentage: '0.44',
		transactions: '2,878,787',
		chargebacks: '2,755',
		mitigation: '6,745',
		indicator: 'green'
	}
];

const QuickView = (props) => {
	const { classes, height, width } = props;
	const {
		container,
		row,
		chartHeader,
		headerTextContainer,
		unitToggle
	} = classes;

	return (
		// Ammount / COUNT toggle button (not functional)
		<div
			className={container}
			style={{ height: height * 0.98, width: width * 0.98 }}
		>
			<Typography className={headerTextContainer}>
				Merchants on Parole
			</Typography>
			<div className={row}>
				<div className={unitToggle}>
					<ButtonGroup orientation="vertical" variant="text" size="small">
						<Button variant="outlined">Unit</Button>
						<Button variant="text">Dollar</Button>
					</ButtonGroup>
				</div>
				{_cardData.map((data, index) => {
					return <CardQuickView key={index} data={data} />;
				})}
			</div>
			<div className={chartHeader}>Dispute Rates, Last 6-months</div>
			<DisputeChart />
		</div>
	);
};

QuickView.propTypes = {
	tileProperties: PropTypes.object,
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(QuickView));
