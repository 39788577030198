import axios from '../../utilities/axios';
import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { refreshToken } from '../../utilities/refreshToken';
import { failureMessage } from '../ToastNotifications/actions';
import { primaryRestGateway } from '../../utilities/apiEndpointUtility';

import * as actions from './actions';
import * as actionTypes from './actionTypes';

import mockGetRepresentmentEpic from '../../__Demo_Data__/Representments/getRepresentmentEpic';
import { demoWait } from '../../__Demo_Data__/mockRandomLoad';

export const getRepresentmentEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.GET_REPRESENTMENT),
		mergeMap(async (action) => {
			await demoWait();
			//await refreshToken();
			const caseId = action.payload.caseId;

			// const response = await axios.get(
			// 	`${primaryRestGateway()}/api/representments/${caseId}`
			// );

			// if (Object.keys(response.data).length === 0) {
			// 	throw Error('No Representment Data Found');
			// }

			const response = {
				data: mockGetRepresentmentEpic
			};

			return response.data;
		}),
		switchMap((res) => [actions.getRepresentmentCompleted(res)]),
		catchError((error, source) =>
			merge(
				of(
					actions.getRepresentmentFailed(error.message),
					failureMessage(error.message)
				),
				source
			)
		)
	);
