import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { withTranslation, Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { currentInventoryActions } from '../../../containers/CurrentInventory';
import isDataEmpty from '../../../helpers/currentInventory/isDataEmpty';
import filterCurrentInventory from '../../../helpers/currentInventory/filterCurrentInventory';

import generateDateRange from '../../../helpers/shared/generateDateRange';
import querier from '../../../utilities/querier';

import Group from './components/Group';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';

const styles = (theme) => ({
	container: {
		display: 'flex',
		flexFlow: 'column',
		margin: '10px',
		justifyContent: 'flex-end'
	},
	controlsRow: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end'
	},
	listContainer: {
		display: 'flex',
		flexDirection: 'column',
		marginTop: '10px'
	},
	groupBySelect: {
		minWidth: '160px'
	},
	listGroup: {
		display: 'flex',
		flexDirection: 'column',
		borderRadius: '3px',
		border: `1px solid ${theme.palette.currentInventory.listGroup.border}`
	},
	sectionTitle: {
		backgroundColor:
			theme.palette.representment.sectionContainer.sectionTitleBackground,
		width: 'fit-content',
		fontSize: '1rem',
		color: theme.palette.representment.sectionContainer.sectionText,
		margin: '-22px 0 0 2px',
		padding: '0 2px 0 2px'
	},
	centeredContent: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center'
	}
});

const groupByOptions = [
	{
		value: 'date',
		label: 'Date'
	},
	{
		value: 'cardType',
		label: 'Card'
	}
];

const _renderInventoryList = (
	isLoading,
	hasBeenLoaded,
	data,
	groupBy,
	selectedCards,
	onGenerateDocument,
	classes,
	t
) => {
	const { centeredContent } = classes;
	let rendered = <></>;

	const isDataEmptyResult = isDataEmpty(data, groupBy);

	if (isLoading || !hasBeenLoaded) {
		rendered = (
			<div className={centeredContent}>
				<CircularProgress />
			</div>
		);
	} else if (hasBeenLoaded && isDataEmptyResult) {
		rendered = (
			<div className={centeredContent}>
				<span>
					{t('components.CurrentInventory.CurrentInventoryList.noData')}
				</span>
			</div>
		);
	} else if (hasBeenLoaded && !isDataEmptyResult) {
		const filteredGroups = filterCurrentInventory(data, groupBy, selectedCards);

		rendered = (
			<Fragment>
				{filteredGroups.map((group) => {
					return (
						<Group
							key={group.key}
							group={group}
							groupBy={groupBy}
							onGenerateDocument={onGenerateDocument}
						/>
					);
				})}
			</Fragment>
		);
	} else {
		rendered = (
			<div className={centeredContent}>
				<span>
					<Trans
						t={t}
						i18nKey="components.dashboardTiles.DashboardWidgetTile.unableToDisplay"
					>
						There was an error loading the current inventory: {{ error: error }}
					</Trans>
				</span>
			</div>
		);
	}

	return rendered;
};

export const CurrentInventoryList = (props) => {
	const {
		classes,
		t,
		groupBy,
		setGroupBy,
		dateRange,
		selectedCards,
		data,
		isLoading,
		hasBeenLoaded,
		error,
		getCurrentInventory
	} = props;
	const {
		container,
		controlsRow,
		groupBySelect,
		listContainer,
		centeredContent
	} = classes;

	const history = useHistory();

	React.useEffect(() => {
		getCurrentInventory({
			groupBy,
			dateRange: generateDateRange(dateRange)
		});
	}, [groupBy, dateRange, getCurrentInventory]);

	const onGenerateDocument = (caseId) => {
		history.push(`/representments/view/${caseId}`);
	};

	return (
		<div className={container}>
			<div className={controlsRow}>
				<FormControl>
					<InputLabel id="group-by-label">
						{t('components.CurrentInventory.CurrentInventoryList.groupBy')}
					</InputLabel>
					<Select
						labelId="group-by-label"
						id="group-by-select"
						label={t(
							'components.CurrentInventory.CurrentInventoryList.groupBy'
						)}
						className={groupBySelect}
						value={groupBy}
						onChange={(e) => {
							setGroupBy(e.target.value);
						}}
					>
						{groupByOptions.map((option) => (
							<MenuItem key={option.value} value={option.value}>
								{option.label}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</div>
			{_renderInventoryList(
				isLoading,
				hasBeenLoaded,
				data,
				groupBy,
				selectedCards,
				onGenerateDocument,
				classes,
				t
			)}
		</div>
	);
};

CurrentInventoryList.propTypes = {
	groupBy: PropTypes.string,
	setGroupBy: PropTypes.func,
	dateRange: PropTypes.string,
	isLoading: PropTypes.bool,
	hasBeenLoaded: PropTypes.bool,
	error: PropTypes.string,
	selectedCards: PropTypes.array,
	data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	getCurrentInventory: PropTypes.func,
	classes: PropTypes.object,
	t: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		isLoading: state.currentInventoryReducer.getIn([
			'currentInventory',
			'isLoading'
		]),
		hasBeenLoaded: state.currentInventoryReducer.getIn([
			'currentInventory',
			'hasBeenLoaded'
		]),
		error: state.currentInventoryReducer.getIn(['currentInventory', 'error']),
		data: state.currentInventoryReducer.getIn(['currentInventory', 'data'])
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getCurrentInventory: (payload) => {
			dispatch(currentInventoryActions.getCurrentInventory(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(withTranslation()(CurrentInventoryList)));
