import React from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import Skeleton from '@mui/material/Skeleton';
import Toolbar from '@mui/material/Toolbar';

const styles = (theme) => ({
	toolbar: {
		display: 'flex',
		width: '100%',
		alignItems: 'flex-start',
		flexDirection: 'row',
		justifyContent: 'spaceBetween',
		padding: '4px'
	},
	filterRowContainer: {
		display: 'flex',
		alignSelf: 'stretch',
		width: '100%',
		justifyContent: 'flex-end',
		flexDirection: 'row',
		alignItems: 'center'
	},
	skeletonMargin: {
		margin: '0 3px 0 3px'
	}
});

const CompressedAppBarSkeleton = (props) => {
	const { classes } = props;
	const { toolbar, filterRowContainer, skeletonMargin } = classes;

	return (
		<Toolbar className={toolbar}>
			<Skeleton variant="text" width={150} height={40} />
			<div className={filterRowContainer}>
				<Skeleton
					className={skeletonMargin}
					variant="square"
					width={50}
					height={30}
				/>
				<Skeleton
					className={skeletonMargin}
					variant="square"
					width={20}
					height={30}
				/>
			</div>
		</Toolbar>
	);
};

CompressedAppBarSkeleton.propTypes = {
	classes: PropTypes.object
};

export default withStyles(styles)(CompressedAppBarSkeleton);
