import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import SettingsList from '../../../../partials/SettingsList';
import KpiSmallSettings from './KpiSmallSettings';
import KpiSmallView from './KpiSmallView';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Tooltip from '@mui/material/Tooltip';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const styles = (theme) => ({
	accordionContainer: {
		padding: 0,
		minHeight: '0px!important',
		maxHeight: '28px',
		'& div': {
			margin: 0,
			cursor: 'default',
			display: 'flex',
			justifyContent: 'flex-end'
		}
	},
	expandIcon: {
		fontSize: '30px',
		cursor: 'pointer'
	}
});

const KpiSmall = (props) => {
	const { classes, data, t } = props;
	const { accordionContainer, expandIcon } = classes;

	const [toggleAccordion, setToggleAccordion] = useState(true);
	const [toggleSettings, setToggleSettings] = useState(false);

	return (
		<Accordion expanded={toggleAccordion} style={{ padding: 0 }}>
			<AccordionSummary
				expandIcon={
					<Tooltip title={toggleAccordion ? 'Collapse' : 'Expand'}>
						<ExpandMoreIcon
							className={expandIcon}
							onClick={() => setToggleAccordion(!toggleAccordion)}
						/>
					</Tooltip>
				}
				className={accordionContainer}
			>
				<SettingsList
					toggleSettings={setToggleSettings}
					showSettings={toggleSettings}
				/>
			</AccordionSummary>
			<AccordionDetails>
				{!toggleSettings ? (
					<KpiSmallView data={data} />
				) : (
					<KpiSmallSettings t={t} toggleSettings={setToggleSettings} />
				)}
			</AccordionDetails>
		</Accordion>
	);
};

KpiSmall.propTypes = {
	classes: PropTypes.object,
	data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(KpiSmall));
