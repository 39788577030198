export default function (state, action, key) {
	const groupId = action.payload.id;
	const existingGroup = state.get(key).get(`${groupId}`);

	if (existingGroup === undefined) {
		return state.setIn(`${key}.${groupId}`.split('.'), {
			isLoading: true,
			hasBeenLoaded: false,
			error: '',
			data: {}
		});
	}

	if (action.payload.data) {
		return state
			.setIn(`${key}.${groupId}.isLoading`.split('.'), false)
			.setIn(`${key}.${groupId}.hasBeenLoaded`.split('.'), true)
			.setIn(`${key}.${groupId}.error`.split('.'), '')
			.setIn(`${key}.${groupId}.data`.split('.'), action.payload.data);
	}

	if (action.payload.data === undefined) {
		return state.setIn(`${key}.${groupId}.isLoading`.split('.'), true);
	}

	return state;
}
