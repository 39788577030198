import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import Slyce360Icon from '../../../../Icons/Slyce360Logo';

const styles = (theme) => ({
	leftContainer: {
		display: 'flex',
		flex: 1,
		minWidth: theme.branding.width,
		maxWidth: theme.branding.width,
		borderRight: `1px solid ${theme.palette.background.default}`
	},
	logo: {
		display: 'flex',
		width: '100%',
		alignContent: 'center',
		marginLeft: '30px',
		'& svg': {
			width: '70%'
		},
		textDecoration: 'none'
	}
});

const Header = (props) => {
	const { classes } = props;
	const { leftContainer, logo } = classes;

	return (
		<div className={leftContainer}>
			<Link to="/dashboard" className={logo}>
				<Slyce360Icon />
			</Link>
		</div>
	);
};

Header.propTypes = {
	classes: PropTypes.object,
	dashboardRef: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({ current: PropTypes.Element })
	])
};

export default withStyles(styles)(Header);
