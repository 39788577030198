import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
	BrowserRouter as Router,
	Redirect,
	Route,
	Switch
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { authActions } from '../../containers/Auth';
import PrivateRoute from './PrivateRoute';

// import ResetPasswordPage from '../../pages/ResetPassword';
import AccountPage from '../../pages/Account';
import CurrentInventory from '../../pages/CurrentInventory';
import DashboardPage from '../../pages/Dashboard';
import GroupManagementPage from '../../pages/GroupManagement';
import LoginPage from '../../pages/Login';
import MultiFactorAuthentication from '../../pages/MultiFactorAuthentication';
import NewPasswordRequired from '../../pages/NewPasswordRequired';
import NotificationsPage from '../../pages/Notifications';
import PostLogin from '../../pages/PostLogin';
import RecoveryHistory from '../../pages/RecoveryHistory';
import ReportPage from '../../pages/Report';
import ReportViews from '../../pages/ReportViews';
import RepresentmentPage from '../../pages/Representment';
import RepresentmentViewerPage from '../../pages/RepresentmentViewer';
import UsersPage from '../../pages/Users';

const styles = (theme) => ({
	pageWrapper: {
		display: 'flex',
		minHeight: '100vh',
		overflow: 'hidden'
	}
});

const AppRouter = (props) => {
	const { classes, isAuthed } = props;
	const { pageWrapper } = classes;

	return (
		<div className={pageWrapper}>
			<Router>
				<Switch>
					<Route
						exact
						path="/"
						render={(props) => <Redirect {...props} to="/login" />}
					/>

					<Route exact path="/login">
						<LoginPage isAuthed={isAuthed} />
					</Route>

					<PrivateRoute exact path="/post-login" isAuthed={isAuthed}>
						<PostLogin />
					</PrivateRoute>

					<PrivateRoute exact path="/multi-factor-auth" isAuthed={isAuthed}>
						<MultiFactorAuthentication />
					</PrivateRoute>

					<PrivateRoute exact path="/new-password-required" isAuthed={isAuthed}>
						<NewPasswordRequired />
					</PrivateRoute>

					<PrivateRoute path="/account" isAuthed={isAuthed}>
						<AccountPage />
					</PrivateRoute>

					<PrivateRoute exact path="/dashboard" isAuthed={isAuthed}>
						<DashboardPage />
					</PrivateRoute>

					<PrivateRoute exact path="/group-management" isAuthed={isAuthed}>
						<GroupManagementPage />
					</PrivateRoute>

					<PrivateRoute
						exact
						path="/account/:modalFunction"
						isAuthed={isAuthed}
					>
						<AccountPage />
					</PrivateRoute>

					<PrivateRoute exact path="/notifications" isAuthed={isAuthed}>
						<NotificationsPage />
					</PrivateRoute>

					<PrivateRoute
						exact
						path="/notifications/:notificationId"
						isAuthed={isAuthed}
					>
						<NotificationsPage />
					</PrivateRoute>

					<PrivateRoute exact path="/reports" isAuthed={isAuthed}>
						<ReportViews />
					</PrivateRoute>

					<PrivateRoute path="/reports/:reportId" isAuthed={isAuthed}>
						<ReportPage />
					</PrivateRoute>

					<PrivateRoute
						path="/reports/:reportId/recordings/:recordingId"
						isAuthed={isAuthed}
					>
						<ReportPage />
					</PrivateRoute>

					<PrivateRoute
						exact
						path="/representments/build/:representmentCaseNumber"
						isAuthed={isAuthed}
					>
						<RepresentmentPage />
					</PrivateRoute>

					<PrivateRoute
						exact
						path="/representments/view/:representmentCaseNumber"
						isAuthed={isAuthed}
					>
						<RepresentmentViewerPage />
					</PrivateRoute>

					<PrivateRoute exact path="/current-inventory" isAuthed={isAuthed}>
						<CurrentInventory />
					</PrivateRoute>

					<PrivateRoute exact path="/recovery-history" isAuthed={isAuthed}>
						<RecoveryHistory />
					</PrivateRoute>

					<PrivateRoute exact path="/users" isAuthed={isAuthed}>
						<UsersPage />
					</PrivateRoute>
				</Switch>
			</Router>
		</div>
	);
};

AppRouter.propTypes = {
	authUserSession: PropTypes.func,
	isAuthed: PropTypes.bool,
	isAuthingUser: PropTypes.bool,
	classes: PropTypes.object,
	t: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		isAuthed: state.authReducer.get('isAuthed'),
		isAuthingUser: state.authReducer.get('isAuthingUser')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		authUserSession: () => {
			dispatch(authActions.authUserSession());
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(withTranslation()(AppRouter)));
