import { fromJS } from 'immutable';
import * as actionTypes from './actionTypes';
import handleDynamicUserLayouts from '../../helpers/layouts/handleDynamicUserLayouts';
import handleDynamicDeleteList from '../../helpers/shared/handleDynamicDeleteList';

export const initialState = fromJS({
	data: {},
	isLoading: false,
	hasBeenLoaded: false,
	error: undefined,
	layoutsBeingDeleted: [],
	userLayouts: {}
});

export const layoutsReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_LAYOUTS:
			return state.set('isLoading', true);

		case actionTypes.GET_LAYOUTS_COMPLETED:
			return state
				.set('isLoading', false)
				.set('data', action.payload)
				.set('hasBeenLoaded', true);

		case actionTypes.GET_LAYOUTS_FAILED:
			return state.set('isLoading', false).set('error', action.payload);

		case actionTypes.ADD_NEW_LAYOUT:
			return state.set('isLoading', true);

		case actionTypes.ADD_NEW_LAYOUT_COMPLETED:
			return state
				.set('isLoading', false)
				.set('data', action.payload)
				.set('hasBeenLoaded', true);

		case actionTypes.ADD_NEW_LAYOUT_FAILED:
			return state.set('isLoading', false).set('error', action.payload);

		case actionTypes.DELETE_LAYOUT:
			return handleDynamicDeleteList(state, action, 'layoutsBeingDeleted');

		case actionTypes.DELETE_LAYOUT_COMPLETED:
			return handleDynamicDeleteList(
				state,
				action,
				'layoutsBeingDeleted',
				'data'
			);

		case actionTypes.DELETE_LAYOUT_FAILED:
			return handleDynamicDeleteList(state, action, 'layoutsBeingDeleted');

		case actionTypes.GET_SELECTED_USER_LAYOUTS:
			return handleDynamicUserLayouts(state, action, 'userLayouts');

		case actionTypes.GET_SELECTED_USER_LAYOUTS_COMPLETED:
			return handleDynamicUserLayouts(state, action, 'userLayouts');

		case actionTypes.GET_SELECTED_USER_LAYOUTS_FAILED:
			return handleDynamicUserLayouts(state, action, 'userLayouts');

		default:
			return state;
	}
};
