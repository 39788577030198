import { fromJS } from 'immutable';
import * as actionTypes from './actionTypes';

export const initialState = fromJS({
	isCreateLayoutModalOpen: false,
	isManageDashboardModalOpen: false,
	isMultiFactorAuthSetupModalOpen: false,
	isRecordingsModalOpen: false,
	isUpdateAvatarModalOpen: false,
	attachmentModalData: undefined
});

export const modalReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.TOGGLE_CREATE_LAYOUT_MODAL:
			return state.set(
				'isCreateLayoutModalOpen',
				!state.get('isCreateLayoutModalOpen')
			);

		case actionTypes.TOGGLE_MANAGE_DASHBOARD_MODAL:
			return state.set(
				'isManageDashboardModalOpen',
				!state.get('isManageDashboardModalOpen')
			);

		case actionTypes.TOGGLE_MULTI_FACTOR_AUTH_SETUP_MODAL:
			return state.set(
				'isMultiFactorAuthSetupModalOpen',
				!state.get('isMultiFactorAuthSetupModalOpen')
			);

		case actionTypes.TOGGLE_RECORDINGS_MODAL:
			return state.set(
				'isRecordingsModalOpen',
				!state.get('isRecordingsModalOpen')
			);

		case actionTypes.TOGGLE_UPDATE_AVATAR_MODAL:
			return state.set(
				'isUpdateAvatarModalOpen',
				!state.get('isUpdateAvatarModalOpen')
			);

		case actionTypes.SET_ATTACHMENT_MODAL_DATA:
			return state.set('attachmentModalData', action.payload);

		default:
			return state;
	}
};
