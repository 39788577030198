import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import PageTitle from '../../components/RecoveryHistory/PageTitle';
import RecoveryHistoryManager from '../../components/RecoveryHistory/RecoveryHistoryManager';

import Container from '@mui/material/Container';

const styles = (theme) => ({
	root: {
		display: 'flex',
		flexFlow: 'column',
		padding: '40px',
		[theme.breakpoints.up('tablet')]: {
			width: '80%'
		},
		[theme.breakpoints.down('tablet')]: {
			width: '100%'
		}
	}
});

export const RecoveryHistory = (props) => {
	const { classes } = props;
	const { root } = classes;

	return (
		<Container className={root} maxWidth={false}>
			<PageTitle />
			<RecoveryHistoryManager />
		</Container>
	);
};

RecoveryHistory.propTypes = {
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(RecoveryHistory);
