export const allMerchantsList = [
	'Monthly Mow',
	'Coffee Club',
	"Darling's Cosmetic Subscription",
	'Vino of the Month',
	'Paradise parking',
	'Flowers by Friday',
	'Cigar Central',
	'Squeaky Clean Carwash',
	'Great Crates',
	'Handmade Monthly',
	'Packaged Delights',
	'Big Ideas Sampler',
	'Better Day Box',
	'Serenity Sampler',
	'Baby Box',
	'Samples by Sarah',
	'Magazines bi-Monthly',
	'Protein Powders Now',
	'Horoscopes by Lenny',
	'Cozy Koozies Monthly',
	'Pen of the Month',
	'Flavored Cup of Joe',
	'Crafty Moms Monthly',
	'Big Deals Weekly',
	'ABC Vitamins Subscription',
	'I Love Shopping Deals',
	'Book of the Month',
	'Unlimited Washes Monthly',
	'City Parking Lot 10',
	'Donuts Forever',
	'Dog Box',
	'Best Face Cream Ever',
	'Make-up Monthly',
	'Wiz Kid Subscriber',
	'The Weekly Chemist',
	'Creative Minds by Darla',
	'Ample Samples',
	'Trinkets Monthly',
	'Remove Vineyard',
	'Weekly Fisherman',
	'Global Gift Co.',
	'Beautiful Memories',
	'Treasure Chest Weekly',
	'Cookie Club'
];

export const groupMerchantList = {
	1: [
		'Ample Samples',
		'Trinkets Monthly',
		'Remove Vineyard',
		'Weekly Fisherman',
		'Global Gift Co.',
		'Beautiful Memories',
		'Treasure Chest Weekly',
		'Cookie Club'
	],
	2: [
		'Flavored Cup of Joe',
		'Crafty Moms Monthly',
		'Big Deals Weekly',
		'ABC Vitamins Subscription',
		'I Love Shopping Deals',
		'Book of the Month',
		'Unlimited Washes Monthly',
		'City Parking Lot 10'
	],
	3: [
		'Ample Samples',
		'Trinkets Monthly',
		'Remove Vineyard',
		'Weekly Fisherman',
		'Global Gift Co.'
	],
	4: [
		'Flowers by Friday',
		'Cigar Centeral',
		'Squeaky Clean Carwash',
		'Great Crates'
	]
};
