import React from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';

import DesktopAccessDisabledIcon from '@mui/icons-material/DesktopAccessDisabled';
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';

const styles = (theme) => ({
	icon: {
		padding: '5px',
		'& svg': {
			fontSize: '22px'
		}
	}
});

const ActiveOnDashboard = (props) => {
	const { classes, showOnDashboard, handleShowOnDashboard, isDisabled } = props;
	const { icon } = classes;

	const _renderTitle = () => {
		if (isDisabled) {
			return 'Data required to add widget';
		}

		return 'Show on Dashboard';
	};

	return (
		<Tooltip title={_renderTitle()}>
			<Checkbox
				className={icon}
				disabled={isDisabled}
				icon={<DesktopAccessDisabledIcon color="disabled" />}
				checkedIcon={<DesktopWindowsIcon color="primary" />}
				size="small"
				checked={showOnDashboard}
				onChange={handleShowOnDashboard}
			/>
		</Tooltip>
	);
};

ActiveOnDashboard.propTypes = {
	classes: PropTypes.object,
	handleShowOnDashboard: PropTypes.func,
	isDisabled: PropTypes.bool,
	showOnDashboard: PropTypes.bool
};

export default withStyles(styles)(ActiveOnDashboard);
