import React from 'react';
import { withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Logout from '../../buttons/Logout';

import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';

import ExpandMore from '@mui/icons-material/ExpandMore';
import BugIcon from '@mui/icons-material/BugReport';
// import PersonAdd from '@mui/icons-material/PersonAdd';
// import Settings from '@mui/icons-material/Settings';

const styles = (theme) => ({
	root: {
		display: 'flex',
		justifyContent: 'flex-end',
		padding: '0px',
		margin: '0px 6px',
		borderLeft: `1px solid ${theme.palette.background.default}`
	},
	nameContainer: {
		display: 'flex',
		alignItems: 'center',
		marginLeft: '6px',
		justifyContent: 'center',
		'& button': {
			textTransform: 'none'
		}
	},
	avatarContainer: {
		flex: 1,
		flexDirection: 'column',
		height: theme.panel.height,
		width: theme.panel.height,
		alignItems: 'center'
	},
	avatar: {
		width: '100%',
		height: '100%'
	},
	link: {
		textDecoration: 'none'
	}
});

const Profile = (props) => {
	const { classes, user, userAvatar, t } = props;
	const { nameContainer, avatarContainer, avatar, root, link } = classes;

	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<div className={root}>
			<div className={nameContainer}>
				<Button
					variant="text"
					color="secondary"
					endIcon={<ExpandMore />}
					onClick={handleClick}
					disableRipple
				>
					{user.username}
				</Button>
			</div>
			<div className={avatarContainer}>
				<Avatar
					variant="square"
					className={avatar}
					src={`https://dev.assets.slyce360.com${userAvatar}`}
				/>
			</div>
			<Menu
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				onClick={handleClose}
				PaperProps={{
					elevation: 0,
					sx: {
						overflow: 'visible',
						filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
						mt: 1.5,
						'& .MuiAvatar-root': {
							width: 32,
							height: 32,
							ml: -0.5,
							mr: 1
						},
						'&:before': {
							content: '""',
							display: 'block',
							position: 'absolute',
							top: 0,
							right: 14,
							width: 10,
							height: 10,
							bgcolor: 'background.paper',
							transform: 'translateY(-50%) rotate(45deg)',
							zIndex: 0
						}
					}
				}}
				transformOrigin={{ horizontal: 'right', vertical: 'top' }}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
			>
				<Link to="/account" className={link}>
					<MenuItem>
						<Avatar /> {t('components.Layout.Navbar.routes.account')}
					</MenuItem>
				</Link>
				<Divider />
				<MenuItem disabled>
					<ListItemIcon>
						<BugIcon fontSize="small" />
					</ListItemIcon>
					Report an Issue
				</MenuItem>
				<Logout />
			</Menu>
		</div>
	);
};

Profile.propTypes = {
	userAvatar: PropTypes.string,
	classes: PropTypes.object,
	t: PropTypes.func,
	user: PropTypes.object
};

const mapStateToProps = (state) => {
	return {
		user: state.userReducer.get('user'),
		userAvatar: state.userReducer.get('userAvatar')
	};
};

export default connect(
	mapStateToProps,
	null
)(withStyles(styles)(withTranslation()(Profile)));
