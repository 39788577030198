import axios from '../../utilities/axios';
import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { refreshToken } from '../../utilities/refreshToken';
import { failureMessage } from '../ToastNotifications/actions';
import Logger from '../../utilities/logger';
import { primaryRestGateway } from '../../utilities/apiEndpointUtility';

import * as actions from './actions.js';
import * as actionTypes from './actionTypes.js';

import demoGetHistoryByOutcomeEpic from '../../__Demo_Data__/RecoveryHistory/getHistoryByOutcomeEpic';
import demoGetHistoryByCardEpic from '../../__Demo_Data__/RecoveryHistory/getHistoryByCardEpic';
import demoGetHistoryByReasonEpic from '../../__Demo_Data__/RecoveryHistory/getHistoryByReasonEpic';
import { demoWait } from '../../__Demo_Data__/mockRandomLoad';

export const getHistoryByOutcomeEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.GET_RECOVERY_HISTORY_BY_OUTCOME),
		mergeMap(async (action) => {
			await demoWait();
			// await refreshToken();
			// const dateRange = action.payload;

			// const response = await axios.get(
			// 	`${primaryRestGateway()}/api/representments/recoveryHistory?dateRange=${dateRange}&groupBy=outcome`
			// );

			const response = {
				data: demoGetHistoryByOutcomeEpic
			};

			return response.data;
		}),
		switchMap((res) => [actions.getRecoveryHistoryByOutcomeCompleted(res)]),
		catchError((error, source) => {
			Logger.error('Get History By Outcome Failure', error);

			return merge(
				of(
					actions.getRecoveryHistoryByOutcomeFailed(error.message),
					failureMessage(error.message)
				),
				source
			);
		})
	);

export const getHistoryByCardEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.GET_RECOVERY_HISTORY_BY_CARD),
		mergeMap(async (action) => {
			await demoWait();
			// await refreshToken();
			// const dateRange = action.payload;

			// const response = await axios.get(
			// 	`${primaryRestGateway()}/api/representments/recoveryHistory?dateRange=${dateRange}&groupBy=cardType`
			// );

			const response = {
				data: demoGetHistoryByCardEpic
			};

			return response.data;
		}),
		switchMap((res) => [actions.getRecoveryHistoryByCardCompleted(res)]),
		catchError((error, source) => {
			Logger.error('Get History By Card Failure', error);

			return merge(
				of(
					actions.getRecoveryHistoryByCardFailed(error.message),
					failureMessage(error.message)
				),
				source
			);
		})
	);

export const getHistoryByReasonEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.GET_RECOVERY_HISTORY_BY_REASON),
		mergeMap(async (action) => {
			await demoWait();
			// await refreshToken();
			// const dateRange = action.payload;

			// const response = await axios.get(
			// 	`${primaryRestGateway()}/api/representments/recoveryHistory?dateRange=${dateRange}&groupBy=chargebackReason`
			// );

			const response = {
				data: demoGetHistoryByReasonEpic
			};

			return response.data;
		}),
		switchMap((res) => [actions.getRecoveryHistoryByReasonCompleted(res)]),
		catchError((error, source) => {
			Logger.error('Get History By Reason Failure', error);

			return merge(
				of(
					actions.getRecoveryHistoryByReasonFailed(error.message),
					failureMessage(error.message)
				),
				source
			);
		})
	);
