import React, { lazy } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import views from './views';
import DownloadToCsv from '../actions/DownloadToCsv';
import isDataEmpty from '../../../helpers/shared/isDataEmpty';
import ViewTypeSelector from './ViewTypeSelector';

import Container from '@mui/material/Container';

const styles = (theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		flex: 1,
		borderRadius: '3px',
		marginTop: '5px',
		width: '100%'
	},
	actionRow: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between'
	},
	textContainer: {
		display: 'flex',
		padding: '10px',
		justifyContent: 'center'
	}
});

const _widgetViewStates = {
	optionsLoading: 'components.Widget.WidgetViewer.loadingOptions',
	noData: 'components.Widget.WidgetViewer.noData',
	submitToLoad: 'components.Widget.WidgetViewer.submitToLoad',
	loadingReport: 'components.Widget.WidgetViewer.loadingReport'
};

const _renderView = (view) => {
	switch (view) {
		case 'table':
			return views.viewComponents.fullTable;
		case 'flag':
			return views.viewComponents.flagGraph;
		case 'line':
			return views.viewComponents.lineGraph;
		case 'counter':
			return views.viewComponents.counter;
		case 'json':
			return views.viewComponents.debug;
		case 'kpi':
			return views.viewComponents.kpi;
		default:
			return views.viewComponents.fallback;
	}
};

const _renderText = (renderState, t, textContainer) => {
	return (
		<div className={textContainer}>{t(_widgetViewStates[renderState])}</div>
	);
};

const _renderWidgetViewer = (
	reportData,
	selectedView = 'table',
	options,
	reportViewId,
	classes,
	t
) => {
	let renderedItem = <></>;
	const { textContainer } = classes;

	if (options && options.isLoading) {
		renderedItem = _renderText('optionsLoading', t, textContainer);
	} else if (reportData && reportData.isLoading) {
		renderedItem = _renderText('loadingReport', t, textContainer);
	} else if (
		reportData &&
		reportData.hasBeenLoaded &&
		isDataEmpty(reportData.data)
	) {
		renderedItem = _renderText('noData', t, textContainer);
	} else if (reportData && reportData.hasBeenLoaded) {
		const Component = _renderView(selectedView);
		renderedItem = (
			<Component reportData={reportData} reportViewId={reportViewId} />
		);
	} else {
		renderedItem = _renderText('submitToLoad', t, textContainer);
	}

	return renderedItem;
};

const WidgetViewer = (props) => {
	const { classes, t, reportData, options, reportViewId, view } = props;
	const { container, actionRow } = classes;

	const [selectedView, setSelectedView] = React.useState('table');

	return (
		<Container maxWidth={false} className={container}>
			{reportData.hasBeenLoaded && (
				<div className={actionRow}>
					<ViewTypeSelector
						view={selectedView}
						onUpdateView={setSelectedView}
						noData={isDataEmpty(reportData.data)}
					/>
					<DownloadToCsv
						data={reportData.data}
						isDisabled={isDataEmpty(reportData.data)}
						label={options.data.label}
					/>
				</div>
			)}
			{_renderWidgetViewer(
				reportData,
				selectedView,
				options,
				reportViewId,
				classes,
				t
			)}
		</Container>
	);
};

WidgetViewer.propTypes = {
	reportViewId: PropTypes.string,
	reportData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	options: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	view: PropTypes.string,
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(WidgetViewer));
