import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import optionsStandardizer from '../../../helpers/widget/optionsStandardizer';
import generateFilterSelect from '../../../helpers/widget/generateFilterSelect';
import { capitalCase } from 'change-case';

import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { SingleSelectInput } from '../../inputs';

const styles = (theme) => ({
	editTitleSection: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		padding: '20px'
	},
	editOptionsSection: {
		display: 'flex',
		flexDirection: 'column',
		padding: '20px'
	},
	subHeader: {
		fontSize: '1.2rem'
	},
	titleTextField: {
		margin: '5px 0'
	}
});

const _dataViewStyleOptions = ['line', 'bar'];

export const TileSettingsList = (props) => {
	const {
		classes,
		t,
		tile,
		onTitleUpdate,
		filters,
		onFilterUpdate,
		dataViewStyle,
		setDataViewStyle
	} = props;
	const {
		editTitleSection,
		editOptionsSection,
		subHeader,
		titleTextField
	} = classes;

	return (
		<Fragment>
			<div className={editTitleSection}>
				<span className={subHeader}>
					{t('components.modals.EditTile.editTitle')}
				</span>
				<TextField
					label={t('components.modals.EditTile.title')}
					className={titleTextField}
					value={tile.label}
					onChange={(e) => onTitleUpdate(e.target.value)}
					variant="outlined"
				/>
			</div>
			<div className={editTitleSection}>
				<span className={subHeader}>Data View Style</span>
				<SingleSelectInput
					label={t('components.modals.EditTile.title')}
					className={titleTextField}
					value={capitalCase(dataViewStyle)}
					onUpdate={setDataViewStyle}
					selectList={_dataViewStyleOptions.map((option) =>
						capitalCase(option)
					)}
				/>
			</div>
			<div className={editOptionsSection}>
				<span className={subHeader}>
					{t('components.modals.EditTile.editOptions')}
				</span>
				<Stack
					direction="column"
					spacing={1}
					justifyContent="flex-start"
					alignItems="center"
					padding={2}
				>
					{generateFilterSelect(
						optionsStandardizer(tile.options),
						filters,
						onFilterUpdate
					)}
				</Stack>
			</div>
		</Fragment>
	);
};

TileSettingsList.propTypes = {
	tile: PropTypes.object,
	filters: PropTypes.object,
	classes: PropTypes.object
};

export default withStyles(styles)(withTranslation()(TileSettingsList));
