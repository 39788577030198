import moment from 'moment';
import querier from '../../../utilities/querier';
import deepCopy from '../../../utilities/deepCopy';

export const getMidsByProcessor = (processor, options) => {
	const queryResults = querier(
		options,
		`$.MID.values[paymentEnablerAcquiringBankId = ${processor.id}]`
	);

	return queryResults || [];
};

export function generateInitialFilters(options) {
	const initialOptions = deepCopy(options);
	const processor = querier(initialOptions, '$.PROCESSOR.values[0]');

	let mids = [];

	if (processor) {
		mids = getMidsByProcessor(processor, initialOptions);
	}

	return {
		processor,
		mids,
		lookupDate: moment().format('YYYY-MM-DD')
	};
}

export function handleProcessorUpdate(filterChangeValue, options) {
	return querier(options, `$.PROCESSOR.values[label = "${filterChangeValue}"]`);
}

export function getMerchantIdsByLabel(mids, options) {
	const merchantIdList = [];

	mids.forEach((mid) => {
		const result = options.MID.values.find((value) => {
			return value.label === mid;
		});

		merchantIdList.push(...result.merchantIds);
	});

	return merchantIdList;
}
