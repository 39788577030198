import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import constants from '../../../helpers/constants';
import moment from 'moment';

import formatQueryParameters from '../../../helpers/itemizationReport/formatQueryParameters';
import Tooltip from '@mui/material/Tooltip';

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import { CircularProgress } from '@mui/material';

const styles = (theme) => ({
	container: {
		display: 'flex',
		flex: 1,
		flexFlow: 'column',
		margin: '10px'
	},
	row: {
		display: 'flex',
		marginBottom: '10px',
		flexDirection: 'row',
		justifyContent: 'flex-end'
	},
	valueSelect: {
		display: 'flex',
		flexFlow: 'row wrap',
		minWidth: '160px',
		fontSize: '.8rem',
		margin: '0px 3px',
		backgroundColor: theme.palette.common.white
	},
	genericInput: {
		fontSize: '.8rem',
		margin: '0px 3px',
		height: '39px',
		backgroundColor: theme.palette.common.white
	},
	dateSelect: {
		fontSize: '.8rem',
		margin: '0px 3px',
		backgroundColor: theme.palette.common.white
	},
	submitButton: {
		marginLeft: '2rem',
		cursor: 'pointer',
		'&hover': {
			backgroundColor: theme.palette.common.white
		}
	},
	actionButton: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		borderRadius: '10px',
		marginLeft: '10px',
		padding: '0px 10px 0px 10px',
		backgroundColor: theme.palette.common.white,
		border: `1px solid ${theme.palette.navigationActionButton.background}`
	},
	actionButtonDisabled: {
		opacity: 0.9
	},
	icon: {
		color: theme.palette.navigationActionButton.background,
		fontSize: '2rem',
		'&hover': {
			color: `${theme.palette.icons.selected} !important`
		}
	},
	iconActive: {
		cursor: 'pointer'
	}
});

const _defaultCards = [
	constants.CARD_TYPES.uo_amex,
	constants.CARD_TYPES.uo_discover,
	constants.CARD_TYPES.uo_mastercard,
	constants.CARD_TYPES.uo_visa
];

const _banksNames = [
	constants.BANK_NAMES.capitalOne,
	constants.BANK_NAMES.jPMorgan,
	constants.BANK_NAMES.citi,
	constants.BANK_NAMES.barclays,
	constants.BANK_NAMES.pnc,
	constants.BANK_NAMES.republicLimited
];

const OptionsSelector = (props) => {
	const { classes, t, onSubmit, reportDataIsLoading } = props;
	const {
		actionButton,
		actionButtonDisabled,
		container,
		row,
		genericInput,
		valueSelect,
		dateSelect,
		submitButton,
		icon,
		iconActive
	} = classes;

	const [cardTypes, setCardTypes] = React.useState(_defaultCards);
	const [bankNames, setBanknames] = React.useState(_banksNames);
	const [merchantCode, setMerchantCode] = React.useState('345045125886');
	const [reconcileStart, setReconcileStart] = React.useState(
		moment().add(-2, 'month').format('YYYY-MM-DD')
	);
	const [reconcileEnd, setReconcileEnd] = React.useState(
		moment().format('YYYY-MM-DD')
	);

	const onSubmitFilters = (
		cardTypes,
		bankNames,
		merchantCode,
		reconcileStart,
		reconcileEnd
	) => {
		const queryParameters = formatQueryParameters(
			cardTypes,
			bankNames,
			merchantCode,
			reconcileStart,
			reconcileEnd
		);

		onSubmit('9', queryParameters);
	};

	return (
		<div className={container}>
			<div
				className={row}
				style={{ display: 'flex', justifyContent: 'space-between' }}
			>
				<h2>Chargeback Itemization Report</h2>
				<div>
					<FormControl>
						<InputLabel id="card-type-label">
							{t(
								'components.staticReportViews.ChargebackItemizationView.OptionSelector.cardTypes'
							)}
						</InputLabel>
						<Select
							labelId="card-type-label"
							id="card-type-select"
							multiple
							size="small"
							label={t(
								'components.staticReportViews.ChargebackItemizationView.OptionSelector.cardTypes'
							)}
							className={valueSelect}
							value={cardTypes}
							onChange={(e) => {
								setCardTypes(e.target.value);
							}}
							renderValue={(selected) => (
								<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
									{selected.map((value) => (
										<Chip key={value} label={value} />
									))}
								</Box>
							)}
						>
							{_defaultCards.map((option) => (
								<MenuItem key={option} value={option}>
									{option}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<FormControl>
						<InputLabel id="bank-name-label">
							{t(
								'components.staticReportViews.ChargebackItemizationView.OptionSelector.bankNames'
							)}
						</InputLabel>
						<Select
							labelId="bank-name-label"
							id="bank-name-select"
							size="small"
							multiple
							label={t(
								'components.staticReportViews.ChargebackItemizationView.OptionSelector.bankNames'
							)}
							className={valueSelect}
							value={bankNames}
							onChange={(e) => {
								setBanknames(e.target.value);
							}}
							renderValue={(selected) => (
								<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
									{selected.map((value) => (
										<Chip key={value} label={value} />
									))}
								</Box>
							)}
						>
							{_banksNames.map((option) => (
								<MenuItem key={option} value={option}>
									{option}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</div>
			</div>
			<div className={row}>
				<TextField
					id="merchant-code-input"
					size="small"
					className={genericInput}
					label={t(
						'components.staticReportViews.ChargebackItemizationView.OptionSelector.merchantCode'
					)}
					value={merchantCode}
					onChange={(e) => {
						setMerchantCode(e.target.value);
					}}
				/>
				<FormControl>
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<DatePicker
							label={t(
								'components.staticReportViews.ChargebackItemizationView.OptionSelector.reconcileStart'
							)}
							value={reconcileStart}
							onChange={(newValue) => {
								setReconcileStart(newValue);
							}}
							renderInput={(params) => (
								<TextField {...params} size="small" className={dateSelect} />
							)}
						/>
					</LocalizationProvider>
				</FormControl>
				<FormControl>
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<DatePicker
							label={t(
								'components.staticReportViews.ChargebackItemizationView.OptionSelector.reconcileEnd'
							)}
							value={reconcileEnd}
							onChange={(newValue) => {
								setReconcileEnd(newValue);
							}}
							renderInput={(params) => (
								<TextField {...params} size="small" className={dateSelect} />
							)}
						/>
					</LocalizationProvider>
				</FormControl>
				<div className={row}>
					<Tooltip
						title={t('components.Widget.WidgetViewer.actionSection.download')}
					>
						<div
							className={clsx(actionButton, actionButtonDisabled)}
							onClick={null}
						>
							<FileDownloadIcon className={clsx(icon)} />
						</div>
					</Tooltip>
					<Button
						className={submitButton}
						variant="outlined"
						onClick={() => {
							onSubmitFilters(
								cardTypes,
								bankNames,
								merchantCode,
								reconcileStart,
								reconcileEnd
							);
						}}
					>
						{reportDataIsLoading ? (
							<CircularProgress size="1rem" />
						) : (
							<span>Submit</span>
						)}
					</Button>
				</div>
			</div>
		</div>
	);
};

OptionsSelector.propTypes = {
	onSubmit: PropTypes.func,
	reportDataIsLoading: PropTypes.bool,
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(OptionsSelector));
