import * as actionTypes from './actionTypes';

export const getUsers = (payload) => ({
	type: actionTypes.GET_USERS,
	payload
});

export const getUsersCompleted = (payload) => ({
	type: actionTypes.GET_USERS_COMPLETED,
	payload
});

export const getUsersFailed = (payload) => ({
	type: actionTypes.GET_USERS_FAILED,
	payload
});

export const getUserLayouts = (payload) => ({
	type: actionTypes.GET_USER_LAYOUTS,
	payload
});

export const getUserLayoutsCompleted = (payload) => ({
	type: actionTypes.GET_USER_LAYOUTS_COMPLETED,
	payload
});

export const getUserLayoutsFailed = (payload) => ({
	type: actionTypes.GET_USER_LAYOUTS_FAILED,
	payload
});

export const clearUserLayouts = (payload) => ({
	type: actionTypes.CLEAR_USER_LAYOUTS,
	payload
});
