import React from 'react';
import PropTypes from 'prop-types';

import {
	UserInformationSection,
	OrganizationInformationSection,
	NotificationSettingsSection,
	MultiFactorAuthenticationSection
} from '../../components/Account';
import EditAvatarModal from '../../components/modals/EditAvatarModal';
import MultiFactorAuthSetupModal from '../../components/modals/MultiFactorAuthSetupModal';

import { withStyles } from '@mui/styles';
import Container from '@mui/material/Container';

const styles = (theme) => ({
	root: {
		display: 'flex',
		flexFlow: 'column',
		paddingLeft: '40px',
		paddingRight: '40px'
	},
	sectionRow: {
		display: 'flex',
		flexFlow: 'row',
		alignSelf: 'stretch',
		marginTop: '10px',
		flexWrap: 'wrap',
		justifyContent: 'center'
	}
});

export const Account = (props) => {
	const { classes } = props;
	const { root, sectionRow } = classes;

	return (
		<Container className={root} maxWidth={false}>
			<div className={sectionRow}>
				<UserInformationSection />
				<OrganizationInformationSection />
			</div>
			<div className={sectionRow}>
				<MultiFactorAuthenticationSection />
				<NotificationSettingsSection />
			</div>
			<EditAvatarModal />
			<MultiFactorAuthSetupModal />
		</Container>
	);
};

Account.propTypes = {
	classes: PropTypes.object,
	updateUser: PropTypes.func,
	updateOrganization: PropTypes.func
};

export default withStyles(styles)(Account);
