import * as actionTypes from './actionTypes';

export const getAlerts = (payload) => ({
	type: actionTypes.GET_ALERTS,
	payload
});
export const getAlertsCompleted = (payload) => ({
	type: actionTypes.GET_ALERTS_COMPLETED,
	payload
});

export const getAlertsFailed = (payload) => ({
	type: actionTypes.GET_ALERTS_FAILED,
	payload
});
