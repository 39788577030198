import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

const styles = (theme) => ({
	actions: {
		display: 'flex'
	},
	container: {
		display: 'flex',
		width: '100%'
	}
});

const InitialNotificationEditable = (props) => {
	const {
		classes,
		handleUpdate,
		label,
		setIsEditable,
		setUpdatedTitle,
		t,
		updatedTitle
	} = props;
	const { actions, container } = classes;

	return (
		<div className={container}>
			<TextField
				fullWidth
				multiline
				sx={{ m: 1 }}
				label="Title*"
				value={updatedTitle}
				size="small"
				helperText="Update Title Here"
				onChange={(e) => setUpdatedTitle(e.target.value)}
			/>
			<div className={actions}>
				<Button onClick={() => setIsEditable(false)}>
					{t('pages.notifications.timeline.cancelButton')}
				</Button>
				<Button
					onClick={() => handleUpdate()}
					disabled={updatedTitle === label}
				>
					{t('pages.notifications.timeline.update')}
				</Button>
			</div>
		</div>
	);
};

InitialNotificationEditable.propTypes = {
	classes: PropTypes.object,
	handleUpdate: PropTypes.func,
	label: PropTypes.string,
	setIsEditable: PropTypes.func,
	setUpdatedTitle: PropTypes.func,
	t: PropTypes.func,
	updatedTitle: PropTypes.string
};

export default withStyles(styles)(
	withTranslation()(InitialNotificationEditable)
);
