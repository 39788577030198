import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@mui/styles';
import QRCode from 'qrcode.react';
import { Auth } from 'aws-amplify';
import clsx from 'clsx';

import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const styles = (theme) => ({
	row: {
		display: 'flex',
		flexFlow: 'row'
	},
	flex: {
		flex: 1
	},
	instructionText: {
		padding: '5px'
	},
	errorText: {
		padding: '5px',
		color: theme.palette.error.main
	},
	qrContainer: {
		justifyContent: 'center',
		marginTop: '20px'
	},
	displayCodeContainer: {
		justifyContent: 'center'
	},
	accordianContainer: {
		flex: 1,
		margin: '20px 5px 5px 5px',
		backgroundColor: '#ffcc00'
	},
	summaryText: {
		color: theme.palette.modals.MultiFactorAuthSetupModal.summaryText
	},
	secretKeyArea: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		alignContent: 'center',
		justifyContent: 'center',
		backgroundColor: theme.palette.modals.MultiFactorAuthSetupModal.secretKeyBackground
	},
	secretKeyText: {
		color: theme.palette.modals.MultiFactorAuthSetupModal.secretKey
	},
	authCodeContainer: {
		alignContent: 'center',
		alignItems: 'center',
		textAlign: 'middle',
		marginTop: '20px'
	},
	codeField: {
		marginLeft: '20px'
	},
	actionRow: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end'
	},
	cancelButton: {
		width: '150px',
		color: theme.palette.primary.main,
		padding: '5px',
		marginRight: '10px',
		fontSize: '16px',
		fontWeight: '300'
	},
	inputButton: {
		borderRadius: '4px',
		width: '150px',
		border: 'none',
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.secondary.alt,
		padding: '5px',
		fontSize: '16px',
		fontWeight: '300',
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
			cursor: 'pointer'
		}
	}
});

export const SetupPage = (props) => {
	const { classes, t, user, onActivateMfa, mfaSetupError } = props;
	const { row, qrContainer, accordianContainer, flex, instructionText, errorText, summaryText, secretKeyArea, secretKeyText, authCodeContainer, codeField, actionRow, cancelButton, inputButton } = classes;

	const [setupCode, setSetupCode] = React.useState({});
	const [expanded, setExpanded] = React.useState(false);
	const [auththenticationCode, setAuththenticationCode] = React.useState();

	React.useEffect(() => {
		if (!setupCode.code) {
			Auth.setupTOTP(user).then((code) => {
				// HACK - Hardcoding region use-east-2 for now
				// https://cognito-idp.<cognito_region>.amazonaws.com/<cognito_userpool_id>
				const issuer = `https://cognito-idp.us-east-2.amazonaws.com/${user.pool.userPoolId}`;

				const codeString = `otpauth://totp/AWSCognito:${user.username}?secret=${code}&issuer=${issuer}`;

				setSetupCode({
					code,
					fullSetupString: codeString
				});
			});
		}
	}, [setupCode, setSetupCode, user]);

	return (
		<Fragment>
			<div className={clsx(row, instructionText)}>
				{t('components.modals.MultiFactorAuth.scanWithApp')}
			</div>
			<div className={clsx(row, qrContainer)}>
				{ setupCode.code
					? <QRCode size={256} value={setupCode.fullSetupString}/>
					: <CircularProgress />
				}
			</div>
			<div className={clsx(row, accordianContainer)}>
				<Accordion
					className={flex}
					onChange={(_, expanded) => {
						setExpanded(expanded);
					}}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="secret-configuration-header"
						id="secret-configuration-header"
					>
						<Typography className={summaryText}>
							{ expanded
								? <span>{t('components.modals.MultiFactorAuth.hideSecretKey')}</span>
								: <span>{t('components.modals.MultiFactorAuth.showSecretKey')}</span>
							}
						</Typography>
					</AccordionSummary>
					<AccordionDetails
						className={secretKeyArea}
					>
						<Typography className={secretKeyText}>
							{ setupCode.code }
						</Typography>
					</AccordionDetails>
				</Accordion>
			</div>
			<div className={row}>
				<Typography className={instructionText}>
					{t('components.modals.MultiFactorAuth.afterConfiguredApplication')}
				</Typography>
			</div>
			<div className={clsx(row, authCodeContainer)}>
				{t('components.modals.MultiFactorAuth.authenticationCode')}
				<TextField onChange={ e => setAuththenticationCode(e.target.value)} className={codeField} id='outlined-basic' variant='outlined' size='small' />
			</div>
			{ mfaSetupError &&
					<div className={row}>
						<span className={errorText}>{mfaSetupError}</span>
					</div>
			}
			<div className={actionRow}>
				<Button
					variant="text"
					size="small"
					className={cancelButton}
					onClick={() => {}}
				>
					{t('generic.buttons.cancel')}
				</Button>
				<Button
					variant="contained"
					size="small"
					className={inputButton}
					onClick={() => { onActivateMfa(user, auththenticationCode); }}
				>
					{t('components.modals.MultiFactorAuth.activate')}
				</Button>
			</div>
		</Fragment>
	);
};

SetupPage.propTypes = {
	user: PropTypes.object,
	onActivateMfa: PropTypes.func,
	mfaSetupError: PropTypes.string,
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(SetupPage));
