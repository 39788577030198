import React from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import Skeleton from '@mui/material/Skeleton';
import Toolbar from '@mui/material/Toolbar';

const styles = (theme) => ({
	actionRowContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		flexDirection: 'row',
		margin: '10px 0 10px 0'
	},
	skeletonMargin: {
		margin: '0 10px 0 10px'
	}
});

const DashboardControlSkeleton = (props) => {
	const { classes } = props;
	const { actionRowContainer, skeletonMargin } = classes;

	return (
		<div className={actionRowContainer}>
			<Skeleton
				className={skeletonMargin}
				variant="square"
				width={150}
				height={40}
			/>
			<Skeleton
				className={skeletonMargin}
				variant="square"
				width={80}
				height={40}
			/>
			<Skeleton
				className={skeletonMargin}
				variant="square"
				width={100}
				height={40}
			/>
		</div>
	);
};

DashboardControlSkeleton.propTypes = {
	classes: PropTypes.object
};

export default withStyles(styles)(DashboardControlSkeleton);
