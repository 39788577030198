import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { reporterActions } from '../../../../../containers/Reporter';
import OptionsSelectorSkeleton from './OptionsSelectorSkeleton';
import OptionsFrom from './OptionsForm';
import formatQueryParameters from '../../../../../helpers/dailyChargebackReport/formatQueryParameters';
import findReportItemInList from '../../../../../helpers/reporter/findReportItemInList';
import { generateInitialFilters } from '../../../../../helpers/dailyChargebackReport/twelveMonthLook/filterHelper';

import Typography from '@mui/material/Typography';

const styles = (theme) => ({
	container: {
		display: 'flex',
		flex: 1,
		flexDirection: 'row',
		alignSelf: 'stretch',
		justifyContent: 'flex-end',
		marginTop: '20px'
	},
	titleContainer: {
		display: 'flex',
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignContent: 'center'
	},
	title: {
		fontSize: '1.5rem',
		fontWeight: 600
	}
});

// HACK UNTIL A BETTER SOLUTION IS FOUND FOR WHERE TO STORE
const CUSTOM_OPTIONS_ROUTE =
	'/api/reports/static/options/monthly-summary-by-mid';
const CUSTOM_REPORT_ROUTE =
	'/api/reports/static/execute/monthly-summary-by-mid';

const OptionsSelector = (props) => {
	const {
		classes,
		t,
		reportViewId,
		getReportOptions,
		optionsList,
		onSubmit,
		reportDataIsLoading
	} = props;
	const { container, titleContainer, title } = classes;

	const reportOptions = findReportItemInList(optionsList, reportViewId);

	React.useEffect(() => {
		if (!reportOptions.hasBeenLoaded && !reportOptions.isLoading) {
			getReportOptions({
				uuid: reportViewId,
				customRoute: CUSTOM_OPTIONS_ROUTE
			});
		}
	}, [reportOptions, getReportOptions]);

	const onSubmitFilters = (selectedProcessor, selectedMid, lookupDate) => {
		const queryParameters = formatQueryParameters(
			selectedProcessor,
			selectedMid,
			lookupDate
		);

		onSubmit('8', queryParameters, CUSTOM_REPORT_ROUTE);
	};

	return (
		<div className={container}>
			<div className={titleContainer}>
				<Typography className={title}>
					{t(
						'components.staticReportViews.DailyChargebackReport.MonthlySummaryByMid.title'
					)}
				</Typography>
			</div>
			{!reportOptions.hasBeenLoaded ? (
				<OptionsSelectorSkeleton />
			) : (
				<OptionsFrom
					onSubmitFilters={onSubmitFilters}
					initialFilters={generateInitialFilters(reportOptions.data)}
					options={reportOptions.data}
					reportDataIsLoading={reportDataIsLoading}
				/>
			)}
		</div>
	);
};

OptionsSelector.propTypes = {
	reportViewId: PropTypes.string,
	onSubmit: PropTypes.func,
	reportDataIsLoading: PropTypes.bool,
	optionsList: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
	getReportOptions: PropTypes.func,
	classes: PropTypes.object,
	t: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		optionsList: state.reporterReducer.get('reportOptions')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getReportOptions: (payload) => {
			dispatch(reporterActions.getReportOptions(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(withTranslation()(OptionsSelector)));
