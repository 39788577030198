import { createTheme } from '@mui/material/styles';
import { creditCardColorCodes } from './colorCodes/creditCards';

const branding = {
	main1: '#51284F',
	main2: '#005776',
	secondary2: '#C1C6C8',
	secondary1: '#63666A'
};

const _brandingMain1Shades = [
	'#30182f', // Dark - 0
	'#381c37',
	'#40203f',
	'#482447',
	'#51284f', // Original - 5
	'#623d60',
	'#735272',
	'#856883',
	'#967e95' // Light - 8
];

const lightTheme = {
	branding: {
		width: '200px'
	},
	panel: {
		height: '3.25rem',
		width: '230px'
	},
	header: {
		height: '3.50rem'
	},
	typography: {
		fontFamily: ['Montserrat', 'sans-serif'].join(',')
	},
	palette: {
		type: 'light',
		background: {
			default: '#ffffff'
		},
		common: {
			black: '#000',
			white: '#fff',
			offWhite: '#f2f2f2'
		},
		currentInventory: {
			cardOverview: {
				cardGradientDark: '#c1c6c8',
				cardGradientLight: '#d3d5d6',
				visibilityToggleActive: '#7f8388',
				visibilityToggleInactive: '#7f8388',
				visibilityBorder: '#ffffff'
			},
			listGroup: {
				border: '#c1c6c8',
				headerTextColor: '#676a6e',
				cellTextColor: '#63666A',
				highlightedCell: branding.main1,
				warningActive: '#B79906',
				sentToProcessorActive: '#74B72E',
				buildIcon: '#676a6e'
			}
		},
		dashboard: {
			dashboardControl: {
				switchLayoutArrow: _brandingMain1Shades[3],
				switchLayoutArrowHover: _brandingMain1Shades[5],
				switchLayoutDisabled: branding.secondary2
			},
			tiles: {
				baseTile: {
					settingsIcon: branding.secondary1
				},
				chargebackKpi: {
					infoIcon: branding.secondary2
				},
				divider: '#c1c6c8'
			}
		},
		error: {
			main: '#BF0000',
			light: '#df7f7f'
		},
		icons: {
			default: branding.secondary1,
			selected: branding.main1,
			disabled: branding.secondary2
		},
		navigation: {
			background: '#E0E2E3',
			color: '#63666a',
			active: _brandingMain1Shades[2],
			shadow: '#a9a9a9'
		},
		navigationActionButton: {
			background: '#c1c6c8'
		},
		notifications: {
			icons: {
				assigned: '#75bab8',
				escalation: '#f78c19',
				default: '#bdbdbd'
			},
			type: {
				info: '#74B72E',
				severe: '#e00429',
				warning: '#dbc202'
			}
		},
		primary: {
			main: branding.main1,
			alt: branding.main2
		},
		secondary: {
			main: branding.secondary1,
			alt: branding.secondary2
		},
		success: {
			main: '#74B72E'
		},
		warning: {
			main: '#B79906'
		},
		info: {
			main: '#87a4e8'
		},
		creditCards: {
			...creditCardColorCodes
		},
		modals: {
			MultiFactorAuthSetupModal: {
				summaryText: _brandingMain1Shades[3],
				secretKeyBackground: _brandingMain1Shades[8],
				secretKey: '#ffffff'
			}
		},
		createLayoutModal: {
			creationTypeSelection: {
				selectedPaper: branding.main1,
				selectedPaperIcon: '#ffffff',
				selectedPaperText: '#ffffff'
			},
			cloneLayout: {
				layoutCountText: branding.main1,
				layoutCountLabel: branding.secondary1,
				selectLayout: {
					selectedUserNameBackground: branding.secondary2,
					selectedUserNameText: branding.main1,
					removeSelectedUserIcon: '#ffffff',
					removeSelectedUserIconHover: '#BF0000',
					activeExpandedLayout: '#ededed',
					cloneIcon: branding.secondary2,
					cloneIconHover: branding.main1
				}
			},
			navigation: {
				inactiveNavigationItem: branding.secondary2,
				navigationItem: branding.secondary1,
				navigationHighlight: branding.main1
			},
			selectTiles: {
				divider: branding.main1
			},
			instructionsText: branding.secondary1,
			removeIcon: branding.secondary2,
			removeIconHover: '#BF0000',
			successIcon: branding.main2
		},
		groupManagement: {
			divider: '#c1c6c8'
		},
		recoveryHistory: {
			border: '#c1c6c8',
			misses: '#63666a',
			wins: '#856883',
			pending: '#c0c1c3',
			valueText: '#ffffff',
			breakdownByCard: {
				fillColors: {
					firstChargebackAmount: '#63666a',
					recoveryAmount: '#482447'
				}
			}
		},
		reportViews: {
			reportName: branding.main1,
			tagContainerBackground: branding.secondary2,
			systemTag: branding.main1,
			userTag: branding.main2,
			suggestedDisplayLabel: branding.main2,
			icons: {
				actionIcon: branding.secondary2,
				actionIconHover: branding.main1,
				favoriteIcon: branding.secondary2,
				favoriteIconActive: '#B79906'
			}
		},
		representment: {
			sectionContainer: {
				sectionTitleBackground: '#ffffff',
				sectionText: branding.main2,
				border: '#c6c6c6',
				background: '#ffffff'
			},
			sectionHeader: {
				color: branding.main2
			},
			inputField: {
				background: '#dcdcdc',
				outlineHover: branding.main2
			},
			reversalReasons: {
				addButton: branding.main1,
				addButtonHover: _brandingMain1Shades[6],
				moveIcon: branding.secondary2,
				moveIconHover: branding.main1,
				moveIconDisabled: '#c6c6c6',
				removeIcon: branding.secondary2,
				removeIconHover: '#BF0000'
			}
		},
		representments: {
			page: {
				background: '#e0e2e3'
			},
			statusSelector: {
				tabBorder: branding.secondary2,
				tabBorderSelected: branding.main2,
				backgroundSelected: branding.main2,
				backgroundHover: '#196783',
				color: branding.secondary2,
				activeColor: '#ffffff'
			},
			buttons: {
				color: '#ffffff',
				hover: branding.main1
			},
			header: {
				color: branding.main2,
				border: branding.secondary2,
				background: branding.main1
			},
			text: {
				caseNumber: branding.main2
			},
			rowItem: {
				border: '#f4f0ec'
			},
			icons: {
				inactive: '#f4f0ec',
				active: branding.main1
			}
		},
		widget: {
			appBar: {
				label: _brandingMain1Shades[4],
				icons: branding.secondary1
			},
			viewTypeSelector: {
				icons: {
					actionIcon: branding.secondary2,
					actionIconSelected: branding.main1
				}
			}
		}
	},
	breakpoints: {
		values: {
			mobile: 0,
			tablet: 640,
			smallLaptop: 920,
			laptop: 1024,
			desktop: 1200
		}
	}
};

export default createTheme(lightTheme);
