import { setAuth } from '../../utilities/axios';
import { Auth } from 'aws-amplify';
import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { successMessage, failureMessage } from '../ToastNotifications/actions';
import Logger from '../../utilities/logger';
import { getUser } from '../User/actions';
import {
	multiFactorAuthActions,
	multiFactorAuthConstants
} from '../MultiFactorAuth';

import * as actions from './actions.js';
import * as actionTypes from './actionTypes.js';

import demoUser from '../../__Demo_Data__/Auth/authUser';
import { demoWait } from '../../__Demo_Data__/mockRandomLoad';

export const authUserEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.AUTH_USER),
		mergeMap(async (action) => {
			const { username, password } = action.payload;
			await demoWait();
			// const user = await Auth.signIn(username, password);

			// Logger.sessionMetrics = { key: 'username', value: username };
			// Logger.sessionMetrics = { key: 'loggedInAt', value: String(new Date()) };

			if (password !== 'Sempris@2022') {
				throw Error();
			}

			let challengeName;

			// if (user.challengeName) {
			// 	Logger.info('User requires challenge');

			// 	challengeName = user.challengeName;
			// } else {
			// 	Logger.info('User Logged in', { username });
			// 	const session = await Auth.currentSession();

			// 	setAuth(session.getAccessToken().getJwtToken());
			// }

			let user = demoUser;

			return {
				user,
				challengeName,
				preferredMFA: multiFactorAuthConstants.noMfa
			};
		}),
		switchMap((res) => {
			if (res.challengeName) {
				return [
					actions.authUserCompleted(res),
					multiFactorAuthActions.setPreferredMultiFactorAuth(res.preferredMFA)
				];
			} else {
				return [
					actions.authUserCompleted(res),
					getUser(res.user.username),
					successMessage('Successfully Logged In')
				];
			}
		}),
		catchError((error, source) => {
			Logger.error('User Auth Failure', error);

			return merge(
				of(
					actions.authUserFailed(error.message),
					failureMessage(error.message)
				),
				source
			);
		})
	);

export const authUserSessionEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.AUTH_USER_SESSION),
		mergeMap(async (action) => {
			await demoWait();
			// const session = await Auth.currentSession();
			// const user = await Auth.currentAuthenticatedUser({
			// 	bypassCache: true
			// });

			// setAuth(session.getAccessToken().getJwtToken());

			// const getRepresentments = await axios({
			// 	method: 'get',
			// 	url:
			// 		'https://5mzctws2z2.execute-api.us-east-2.amazonaws.com/development/api/representments?name=ethan',
			// 	// data: bodyFormData,
			// 	headers: {
			// 		// Authorization: session.getAccessToken().getJwtToken()
			// 		// 'Content-Type': 'multipart/form-data'
			// 	}
			// });

			let user = demoUser;

			return {
				user,
				username: user.username,
				preferredMFA: user.preferredMFA
			};
		}),
		switchMap((res) => [
			actions.authUserSessionCompleted(res.user),
			multiFactorAuthActions.setPreferredMultiFactorAuth(res.preferredMFA),
			getUser(res.username)
		]),
		catchError((error, source) =>
			merge(of(actions.authUserSessionFailed(error)), source)
		)
	);

export const setNewPasswordEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.COMPLETE_NEW_PASSWORD),
		mergeMap(async (action) => {
			const { user, newPassword } = action.payload;

			Auth.completeNewPassword(user, newPassword);

			return { user, newPassword };
		}),
		switchMap((res) => [
			actions.completeNewPasswordCompleted(res),
			actions.authUser({
				username: res.user.username,
				password: res.newPassword
			})
		]),
		catchError((error, source) =>
			merge(of(actions.completeNewPasswordFailed(error)), source)
		)
	);
