import React from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withSize } from 'react-sizeme';
import { PieChart, Pie, Cell } from 'recharts';
import renderActiveShape from './renderActiveShape';
import { getLogoByBrandName } from '../../../helpers/shared/cardBrandHelper';
import cardPieChartMapper from '../../../helpers/recoveryHistory/cardPieChartMapper';
import { numberWithCommas } from '../../../utilities/formatNumber';

import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';

const styles = (theme) => ({
	container: {
		display: 'flex',
		flexFlow: 'column',
		margin: '0px',
		flex: 1
	},
	stackContainer: {
		flex: 1,
		borderRadius: '5px',
		margin: '5px',
		border: `1px solid ${theme.palette.recoveryHistory.border}`,
		backgroundColor: theme.palette.common.white,
		midWidth: '220px'
	},
	grid: {
		display: 'flex',
		flexDirection: 'row'
	},
	detailContainer: {
		display: 'flex',
		padding: '5px',
		justifyContent: 'center',
		alignItems: 'center'
	},
	dividerStyle: {
		marginLeft: '5px',
		marginRight: '5px'
	},
	detailText: {
		textAlign: 'center',
		fontSize: '1.2rem'
	},
	detailTextValue: {
		textAlign: 'center',
		fontSize: '1.2rem'
	}
});

// HACK UNTIL ABLE TO ACCESS THEME COLORS WITHIN COMPONENT
const _fillColors = {
	base: '#ffffff',
	chargebackAmount: '#c0c1c3',
	receoveryAmount: '#856883'
};

const _calculatePieDimensions = (size) => {
	const smalledDimension = size.height < size.width ? size.height : size.width;
	return {
		height: 150, //smalledDimension * 0.8,
		width: 150, //smalledDimension * 0.8,
		outerRadius: 150 * 0.3 //smalledDimension * 0.3
	};
};

export const StatusBreakdown = (props) => {
	const { classes, t, size, title, data, isLoading } = props;
	const {
		container,
		stackContainer,
		grid,
		dividerStyle,
		detailContainer,
		detailText,
		detailTextValue
	} = classes;

	const [activeIndex, setActiveIndex] = React.useState(1);

	const onPieEnter = (_, index) => {
		setActiveIndex(index);
	};

	const pieDimensions = _calculatePieDimensions(size);

	const mappedPieData = cardPieChartMapper(data, _fillColors);

	return (
		<div className={container}>
			<Stack
				direction="column"
				spacing={1}
				alignItems="center"
				className={stackContainer}
			>
				<img src={getLogoByBrandName(title)} height={60} width={100} />
				{isLoading || mappedPieData.length === 0 ? (
					<Skeleton
						variant="circular"
						animation={isLoading ? 'wave' : false}
						height={150}
						width={150}
					/>
				) : (
					<PieChart width={pieDimensions.width} height={pieDimensions.height}>
						<Pie
							activeIndex={activeIndex}
							activeShape={renderActiveShape}
							data={mappedPieData}
							cx="50%"
							cy="50%"
							outerRadius={pieDimensions.outerRadius}
							startAngle={90}
							endAngle={480}
							fill={_fillColors.base}
							dataKey="value"
							onMouseEnter={onPieEnter}
						>
							{mappedPieData.map((entry, index) => {
								return <Cell key={`cell-${index}`} fill={entry.fillColor} />;
							})}
						</Pie>
					</PieChart>
				)}
				{isLoading || mappedPieData.length === 0 ? (
					<Skeleton
						variant="text"
						animation={isLoading ? 'wave' : false}
						height={40}
						width={200}
					/>
				) : (
					<Grid className={grid}>
						<Grid className={detailContainer} item xs={6}>
							<Typography className={detailText}>
								{t(mappedPieData[activeIndex].nameTranslationPath)}
							</Typography>
						</Grid>
						<Divider className={dividerStyle} orientation="vertical" flexItem />
						<Grid className={detailContainer} item xs={6}>
							<Typography className={detailTextValue}>
								{`$${numberWithCommas(mappedPieData[activeIndex].value)}`}
							</Typography>
						</Grid>
					</Grid>
				)}
			</Stack>
		</div>
	);
};

StatusBreakdown.propTypes = {
	title: PropTypes.string,
	data: PropTypes.object,
	isLoading: PropTypes.bool,
	size: PropTypes.object,
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(
	withTranslation()(withSize({ monitorHeight: true })(StatusBreakdown))
);
