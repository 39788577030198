import * as actionTypes from './actionTypes';

export const getLayouts = (payload) => ({
	type: actionTypes.GET_LAYOUTS,
	payload
});

export const getLayoutsCompleted = (payload) => ({
	type: actionTypes.GET_LAYOUTS_COMPLETED,
	payload
});

export const getLayoutsFailed = (payload) => ({
	type: actionTypes.GET_LAYOUTS_FAILED,
	payload
});

export const addNewLayout = (payload) => ({
	type: actionTypes.ADD_NEW_LAYOUT,
	payload
});

export const addNewLayoutCompleted = (payload) => ({
	type: actionTypes.ADD_NEW_LAYOUT_COMPLETED,
	payload
});

export const addNewLayoutFailed = (payload) => ({
	type: actionTypes.ADD_NEW_LAYOUT_FAILED,
	payload
});

export const deleteLayout = (payload) => ({
	type: actionTypes.DELETE_LAYOUT,
	payload
});

export const deleteLayoutCompleted = (payload) => ({
	type: actionTypes.DELETE_LAYOUT_COMPLETED,
	payload
});

export const deleteLayoutFailed = (payload) => ({
	type: actionTypes.DELETE_LAYOUT_FAILED,
	payload
});

export const getSelectedUserLayouts = (payload) => ({
	type: actionTypes.GET_SELECTED_USER_LAYOUTS,
	payload
});

export const getSelectedUserLayoutsCompleted = (payload) => ({
	type: actionTypes.GET_SELECTED_USER_LAYOUTS_COMPLETED,
	payload
});

export const getSelectedUserLayoutsFailed = (payload) => ({
	type: actionTypes.GET_SELECTED_USER_LAYOUTS_FAILED,
	payload
});
