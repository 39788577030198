import * as actionTypes from './actionTypes';

export const getCardOverview = (payload) => ({
	type: actionTypes.GET_CARD_OVERVIEW,
	payload
});

export const getCardOverviewCompleted = (payload) => ({
	type: actionTypes.GET_CARD_OVERVIEW_COMPLETED,
	payload
});

export const getCardOverviewFailed = (payload) => ({
	type: actionTypes.GET_CARD_OVERVIEW_FAILED,
	payload
});

export const getCurrentInventory = (payload) => ({
	type: actionTypes.GET_CURRENT_INVENTORY,
	payload
});

export const getCurrentInventoryCompleted = (payload) => ({
	type: actionTypes.GET_CURRENT_INVENTORY_COMPLETED,
	payload
});

export const getCurrentInventoryFailed = (payload) => ({
	type: actionTypes.GET_CURRENT_INVENTORY_FAILED,
	payload
});
