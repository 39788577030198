import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';

const styles = (theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		margin: '2px'
	},
	innerBox: {
		display: 'flex',
		flex: 1,
		alignSelf: 'stretch',
		flexDirection: 'column',
		border: `1px solid #d3d3d3`
	},
	indicator: {
		height: '5px',
		alignSelf: 'stretch'
	},
	red: {
		backgroundColor: theme.palette.error.main
	},
	green: {
		backgroundColor: theme.palette.success.main
	},
	percentageText: {
		fontSize: '2rem'
	},
	row: {
		display: 'flex',
		alignSelf: 'stretch',
		justifyContent: 'center',
		flexDirection: 'row'
	},
	detailText: {
		fontSize: '.7rem',
		paddingLeft: '2px',
		paddingRight: '2px'
	}
});

const CardQuickView = (props) => {
	const { classes, data } = props;
	const {
		container,
		row,
		innerBox,
		indicator,
		red,
		green,
		percentageText,
		detailText
	} = classes;

	return (
		<div className={container}>
			<div className={innerBox}>
				<div
					className={clsx(
						row,
						indicator,
						data.indicator === 'green' ? green : red
					)}
				/>
				<div className={row}>{data.card}</div>
				<div className={clsx(row, percentageText)}>{`${data.percentage}%`}</div>
			</div>
			<div className={innerBox}>
				<div className={row}>
					<span className={detailText}>Transactions:</span>
					<span className={detailText}>{data.transactions}</span>
				</div>
				<div className={row}>
					<span className={detailText}>Chargebacks:</span>
					<span className={detailText}>{data.chargebacks}</span>
				</div>
				<div className={row}>
					<span className={detailText}>Mitigation:</span>
					<span className={detailText}>{data.mitigation}</span>
				</div>
			</div>
		</div>
	);
};

CardQuickView.propTypes = {
	tileProperties: PropTypes.object,
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(CardQuickView));
