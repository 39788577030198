import { fromJS } from 'immutable';
import * as actionTypes from './actionTypes';

export const initialState = fromJS({
	reversalReason: {
		data: [],
		isLoading: false,
		hasBeenLoaded: false,
		error: undefined
	},
	buildType: undefined
});

export const reversalReasonBuilderReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_REPRESENTMENT_REVERSAL_REASON:
			return state.setIn(['reversalReason', 'isLoading'], true);

		case actionTypes.GET_REPRESENTMENT_REVERSAL_REASON_COMPLETED:
			return state
				.setIn(['reversalReason', 'isLoading'], false)
				.setIn(['reversalReason', 'data'], action.payload || [])
				.set(
					'buildType',
					action.payload && action.payload.length > 0 ? 'update' : 'new'
				)
				.setIn(['reversalReason', 'hasBeenLoaded'], true);

		case actionTypes.GET_REPRESENTMENT_REVERSAL_REASON_FAILED:
			return state
				.setIn(['reversalReason', 'isLoading'], false)
				.setIn(['reversalReason', 'error'], action.payload);

		case actionTypes.SET_CHARGEBACK_REVERSAL_REASON:
			return state.setIn(['reversalReason', 'data'], action.payload || []);

		case actionTypes.INSERT_REPRESENTMENT_REVERSAL_REASON:
			return state.setIn(['reversalReason', 'isLoading'], true);

		case actionTypes.INSERT_REPRESENTMENT_REVERSAL_REASON_COMPLETED:
			return state
				.setIn(['reversalReason', 'isLoading'], false)
				.setIn(['reversalReason', 'data'], action.payload);

		case actionTypes.INSERT_REPRESENTMENT_REVERSAL_REASON_FAILED:
			return state
				.setIn(['reversalReason', 'isLoading'], false)
				.setIn(['reversalReason', 'error'], action.payload);

		case actionTypes.UPDATE_REPRESENTMENT_REVERSAL_REASON:
			return state.setIn(['reversalReason', 'isLoading'], true);

		case actionTypes.UPDATE_REPRESENTMENT_REVERSAL_REASON_COMPLETED:
			return state
				.setIn(['reversalReason', 'isLoading'], false)
				.setIn(['reversalReason', 'data'], action.payload);

		case actionTypes.UPDATE_REPRESENTMENT_REVERSAL_REASON_FAILED:
			return state
				.setIn(['reversalReason', 'isLoading'], false)
				.setIn(['reversalReason', 'error'], action.payload);

		case actionTypes.SET_BUILDER_TYPE:
			return state.set('buildType', action.payload);

		default:
			return state;
	}
};
