import React from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import MultiFactorAuthChallenge from '../../components/MultiFactorAuthentication/MultiFactorAuthChallenge';

import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';

const styles = (theme) => ({
	pageContainer: {
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		width: '100%',
		alignItems: 'center',
		justifyContent: 'center',
		background:
			'linear-gradient(114deg, rgba(57,181,221,1) 0%, rgba(35,111,135,1) 100%)'
	},
	container: {
		display: 'flex',
		flexDirection: 'column',
		width: 'fit-content',
		padding: '32px',
		alignItems: 'center'
	}
});

const MultiFactorAuthentication = (props) => {
	const { classes } = props;
	const { pageContainer, container } = classes;

	return (
		<Container className={pageContainer} maxWidth={false}>
			<Paper className={container} elevation={2}>
				<MultiFactorAuthChallenge />
			</Paper>
		</Container>
	);
};

MultiFactorAuthentication.propTypes = {
	classes: PropTypes.object
};

export default withStyles(styles)(MultiFactorAuthentication);
