import { fromJS } from 'immutable';
import * as actionTypes from './actionTypes';

export const initialState = fromJS({
	leftDrawerIsOpen: false,
	rightDrawerIsOpen: false,
	navigationDrawerIsOpen: false,
	theme: 'light',

	// BELOW IS REQUIRED FOR SCREENSHOTTER

	email: {
		hasBeenSent: false,
		isSending: false,
		error: ''
	},
	text: {
		hasBeenSent: false,
		isSending: false,
		error: ''
	}
});

export const systemReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.TOGGLE_LEFT_DRAWER:
			return state.set('leftDrawerIsOpen', !state.get('leftDrawerIsOpen'));

		case actionTypes.TOGGLE_RIGHT_DRAWER:
			return state.set('rightDrawerIsOpen', !state.get('rightDrawerIsOpen'));

		case actionTypes.TOGGLE_NAVIGATION_DRAWER:
			return state.set(
				'navigationDrawerIsOpen',
				!state.get('navigationDrawerIsOpen')
			);

		case actionTypes.TOGGLE_THEME:
			return state.set(
				'theme',
				state.get('theme') === 'dark' ? 'light' : 'dark'
			);

		// BELOW IS REQUIRED FOR SCREENSHOTTER

		case actionTypes.SEND_EMAIL:
			return state.setIn('email.isSending'.split('.'), true);

		case actionTypes.SEND_EMAIL_COMPLETED:
			return state
				.setIn('email.hasBeenSent'.split('.'), true)
				.setIn('email.isSending'.split('.'), false)
				.setIn('email.error'.split('.'), '');

		case actionTypes.SEND_EMAIL_FAILED:
			return state
				.setIn('email.hasBeenSent'.split('.'), false)
				.setIn('email.isSending'.split('.'), false)
				.setIn('email.error'.split('.'), action.payload);

		case actionTypes.SEND_TEXT:
			return state.setIn('text.isSending'.split('.'), true);

		case actionTypes.SEND_TEXT_COMPLETED:
			return state
				.setIn('text.hasBeenSent'.split('.'), true)
				.setIn('text.isSending'.split('.'), false);

		case actionTypes.SEND_TEXT_FAILED:
			return state
				.setIn('text.hasBeenSent'.split('.'), false)
				.setIn('text.isSending'.split('.'), false)
				.setIn('text.error'.split('.'), action.payload);

		case actionTypes.RESET_EMAIL_VALUES:
			return state
				.setIn('email.hasBeenSent'.split('.'), false)
				.setIn('email.error'.split('.'), '');

		case actionTypes.RESET_TEXT_VALUES:
			return state
				.setIn('text.hasBeenSent'.split('.'), false)
				.setIn('text.error'.split('.'), '');

		default:
			return state;
	}
};
