import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import { generate } from 'short-uuid';
import clsx from 'clsx';

import TableCell from '@mui/material/TableCell';

const styles = (theme) => ({
	leftHeader: {
		borderRight: '1px solid black',
		fontWeight: '600'
	},
	rowStyle: {
		fontWeight: '600'
	},
	subHeader: {
		borderLeft: '1px solid black'
	}
});

const ChargebackPercentageRow = (props) => {
	const { classes, cardData, t } = props;
	const { leftHeader, rowStyle, subHeader } = classes;

	const { monthToDate } = cardData;

	return (
		<Fragment>
			<TableCell colSpan={2} className={leftHeader}>
				{t('pages.reports.chargebacks.daily-chargeback-report.percent')}
			</TableCell>
			<TableCell colSpan={4}></TableCell>
			<TableCell colSpan={4} className={subHeader}></TableCell>
			{[monthToDate].map((entry) => {
				return Object.entries(entry.chargebacks).map(([key, value]) => {
					return (
						<TableCell
							align="center"
							key={generate()}
							className={clsx(key === 'actual' && subHeader, rowStyle)}
						>
							{value === null ? '-' : `${value}%`}
						</TableCell>
					);
				});
			})}
			<TableCell colSpan={2}></TableCell>
		</Fragment>
	);
};

ChargebackPercentageRow.propTypes = {
	classes: PropTypes.object,
	cardData: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(ChargebackPercentageRow));
