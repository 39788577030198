import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import Filters from './Filters';
import CardOverview from './CardOverview';
import CurrentInventoryList from './CurrentInventoryList';

const styles = (theme) => ({
	container: {
		display: 'flex',
		flexFlow: 'column'
	}
});

export const CurrentInventoryManager = (props) => {
	const { classes } = props;
	const { container } = classes;

	const [dateRange, setDateRange] = React.useState('7d');
	const [selectedCards, setSelectedCards] = React.useState([
		'visa',
		'mastercard',
		'discover',
		'amex'
	]);
	const [groupBy, setGroupBy] = React.useState('date');

	return (
		<div className={container}>
			<Filters
				dateRange={dateRange}
				setDateRange={setDateRange}
				selectedCards={selectedCards}
				setSelectedCards={setSelectedCards}
			/>
			<CardOverview
				selectedCards={selectedCards}
				setSelectedCards={setSelectedCards}
				dateRange={dateRange}
			/>
			<CurrentInventoryList
				groupBy={groupBy}
				setGroupBy={setGroupBy}
				dateRange={dateRange}
				selectedCards={selectedCards}
			/>
		</div>
	);
};

CurrentInventoryManager.propTypes = {
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(CurrentInventoryManager);
