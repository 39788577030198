import axios from '../../utilities/axios';
import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { refreshToken } from '../../utilities/refreshToken';
import { failureMessage } from '../ToastNotifications/actions';
import Logger from '../../utilities/logger';
import { primaryRestGateway } from '../../utilities/apiEndpointUtility';

import * as actions from './actions.js';
import * as actionTypes from './actionTypes.js';

// MOCK IMPORTS FOR TESTING
import mockGroupList from './__mocks__/__mockGroupList__';
import {
	allMerchantsList,
	groupMerchantList
} from './__mocks__/__mockMerchantLists__';
import mockGroupNotificationRules from './__mocks__/__mockGroupNotificationRules__';

export const getGroupsEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.GET_GROUPS),
		mergeMap(async (action) => {
			return mockGroupList;
		}),
		switchMap((res) => [actions.getGroupsCompleted(res)]),
		catchError((error, source) => {
			Logger.error('Get Groups', error);

			return merge(
				of(
					actions.getGroupsFailed(error.message),
					failureMessage(error.message)
				),
				source
			);
		})
	);

export const getMerchantsListEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.GET_MERCHANT_LIST),
		mergeMap(async (action) => {
			return allMerchantsList;
		}),
		switchMap((res) => [actions.getMerchantListCompleted(res)]),
		catchError((error, source) => {
			Logger.error('Get Groups', error);

			return merge(
				of(
					actions.getMerchantListFailed(error.message),
					failureMessage(error.message)
				),
				source
			);
		})
	);

export const getMerchantsInGroupEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.GET_MERCHANTS_IN_GROUP),
		mergeMap(async (action) => {
			const { id } = action.payload;

			const selectedGroupMerchantList = groupMerchantList[id];

			return {
				id,
				data: selectedGroupMerchantList
			};
		}),
		switchMap((res) => [actions.getMerchantsInGroupCompleted(res)]),
		catchError((error, source) => {
			Logger.error('Get Groups', error);

			return merge(
				of(
					actions.getMerchantsInGroupFailed(error.message),
					failureMessage(error.message)
				),
				source
			);
		})
	);

export const getGroupNotificationRulesEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.GET_GROUP_NOTIFICATION_RULES),
		mergeMap(async (action) => {
			const { id } = action.payload;

			const groupNotificationRules = mockGroupNotificationRules.filter(
				(rule) => {
					return rule.groupId === id;
				}
			);

			return {
				id,
				data: groupNotificationRules
			};
		}),
		switchMap((res) => [actions.getGroupNotificationRulesCompleted(res)]),
		catchError((error, source) => {
			Logger.error('Get Groups', error);

			return merge(
				of(
					actions.getGroupNotificationRulesFailed(error.message),
					failureMessage(error.message)
				),
				source
			);
		})
	);
