function capitalize(word) {
	const lower = word.toLowerCase();
	return word.charAt(0).toUpperCase() + lower.slice(1);
}

export const sanitizeLabels = (label) => {
	let sanitizedLabel = label.replace(/_/g, ' ');
	sanitizedLabel = capitalize(sanitizedLabel);
	return sanitizedLabel;
};
