import { fromJS } from 'immutable';
import * as actionTypes from './actionTypes';

export const initialState = fromJS({
	isLoadingUser: false,
	isUpdatingUser: false,
	userHasBeenLoaded: false,
	preferences: {},
	user: {},
	userAvatar: undefined,
	isUpdatingAvatar: false,
	avatarHasBeenUpdated: false,
	userFavoritedReports: {
		data: [],
		hasBeenLoaded: false,
		isLoading: false,
		error: undefined
	}
});

export const userReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_USER:
			return state.set('isLoadingUser', true);

		case actionTypes.GET_USER_COMPLETED:
			return state
				.set('user', action.payload)
				.set('userAvatar', action.payload.avatar)
				.set('preferences', action.payload.preferences)
				.set('isLoadingUser', false)
				.set('userHasBeenLoaded', true);

		case actionTypes.GET_USER_FAILED:
			return state
				.set('user', {})
				.set('isLoadingUser', false)
				.set('userHasBeenLoaded', false);

		case actionTypes.GET_USER_FAVORITE_REPORTS:
		case actionTypes.ADD_USER_FAVORITE_REPORT:
		case actionTypes.REMOVE_USER_FAVORITE_REPORT:
			return state.setIn(['userFavoritedReports', 'isLoadingUser'], true);

		case actionTypes.GET_USER_FAVORITE_REPORTS_COMPLETED:
		case actionTypes.ADD_USER_FAVORITE_REPORT_COMPLETED:
		case actionTypes.REMOVE_USER_FAVORITE_REPORT_COMPLETED:
			return state
				.setIn(['userFavoritedReports', 'isLoadingUser'], false)
				.setIn(['userFavoriteReports', 'hasBeenLoaded'], true)
				.setIn(['userFavoriteReports', 'data'], action.payload);

		case actionTypes.GET_USER_FAVORITE_REPORTS_FAILED:
		case actionTypes.ADD_USER_FAVORITE_REPORT_FAILED:
		case actionTypes.REMOVE_USER_FAVORITE_REPORT_FAILED:
			return state
				.setIn(['userFavoritedReports', 'isLoadingUser'], false)
				.setIn(['userFavoriteReports', 'error'], action.payload);

		case actionTypes.UPDATE_USER:
			return state.set('isUpdatingUser', true);

		case actionTypes.UPDATE_USER_COMPLETED:
			return state
				.set('isUpdatingUser', false)
				.set('user', action.payload)
				.set('preferences', action.payload.preferences);

		case actionTypes.UPDATE_USER_FAILED:
			return state.set('isUpdatingUser', false);

		case actionTypes.UPDATE_USER_AVATAR:
			return state.set('isUpdatingAvatar', true);

		case actionTypes.UPDATE_USER_AVATAR_COMPLETED:
			return state
				.set('isUpdatingAvatar', false)
				.set('userAvatar', action.payload.avatar)
				.set('avatarHasBeenUpdated', true);

		case actionTypes.UPDATE_USER_AVATAR_FAILED:
			return state.set('isUpdatingAvatar', false);

		case actionTypes.RESET_USER_AVATAR_UPLOAD:
			return state.set('avatarHasBeenUpdated', false);

		case actionTypes.LOGOUT:
			return initialState;

		default:
			return state;
	}
};
