import * as actionTypes from './actionTypes';

export const getTemporaryRecordingDirectory = (payload) => ({
	type: actionTypes.GET_TEMPORARY_RECORDING_DIRECTORY,
	payload
});

export const getTemporaryRecordingDirectoryCompleted = (payload) => ({
	type: actionTypes.GET_TEMPORARY_RECORDING_DIRECTORY_COMPLETED,
	payload
});

export const getTemporaryRecordingDirectoryFailed = (payload) => ({
	type: actionTypes.GET_TEMPORARY_RECORDING_DIRECTORY_FAILED,
	payload
});
