import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import { cloneLayoutActions } from '../../../../../../containers/CloneLayout';
import OrganizationUserList from './OrganizationUserList';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

const styles = (theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		padding: '5px 10px 5px 10px'
	},
	instructionsRow: {
		display: 'flex',
		flexDirection: 'row',
		color: theme.palette.createLayoutModal.instructionsText,
		fontSize: '1rem',
		padding: '10px'
	},
	box: {
		display: 'flex',
		justifyContent: 'center',
		maxHeight: '400px',
		flex: 1
	},
	loadingContainer: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: '5%'
	}
});

const _renderList = (
	organizationUsers,
	onUserSelect,
	isLoadingOrganizationUsers,
	organizationUsersHasBeenLoaded,
	organizationUsersError,
	classes
) => {
	const { loadingContainer } = classes;

	let rendered = <></>;

	if (!isLoadingOrganizationUsers && organizationUsersHasBeenLoaded) {
		rendered = (
			<OrganizationUserList
				organizationUsers={organizationUsers}
				onUserSelect={onUserSelect}
			/>
		);
	} else if (!isLoadingOrganizationUsers && organizationUsersError) {
		// TODO IMPLEMENT ERROR HANDLING
	} else {
		rendered = (
			<div className={loadingContainer}>
				<CircularProgress />
			</div>
		);
	}

	return rendered;
};

export const SelectUser = (props) => {
	const {
		classes,
		t,
		organizationUsers,
		onUserSelect,
		isLoadingUsers,
		usersHaveBeenLoaded,
		usersError,
		getUsers
	} = props;
	const { container, instructionsRow, box } = classes;

	React.useEffect(() => {
		if (!usersHaveBeenLoaded && !isLoadingUsers && !usersError) {
			getUsers();
		}
	}, [usersHaveBeenLoaded, isLoadingUsers, usersError, getUsers]);

	return (
		<div className={container}>
			<div className={instructionsRow}>
				{t(
					'components.modals.createLayout.instructions.cloneLayout.selectUser'
				)}
			</div>
			<Box className={box}>
				{_renderList(
					organizationUsers,
					onUserSelect,
					isLoadingUsers,
					usersHaveBeenLoaded,
					usersError,
					classes
				)}
			</Box>
		</div>
	);
};

SelectUser.propTypes = {
	onUserSelect: PropTypes.func,
	organizationUsers: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	isLoadingUsers: PropTypes.bool,
	usersHaveBeenLoaded: PropTypes.bool,
	usersError: PropTypes.string,
	getUsers: PropTypes.func,
	classes: PropTypes.object,
	t: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		organizationUsers: state.cloneLayoutReducer.getIn(['users', 'data']),
		isLoadingUsers: state.cloneLayoutReducer.getIn(['users', 'isLoading']),
		usersHaveBeenLoaded: state.cloneLayoutReducer.getIn([
			'users',
			'hasBeenLoaded'
		]),
		usersError: state.cloneLayoutReducer.getIn(['users', 'error'])
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getUsers: (payload) => {
			dispatch(cloneLayoutActions.getUsers(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(withTranslation()(SelectUser)));
