import * as actionTypes from './actionTypes';

export const generatePdf = (payload) => ({
	type: actionTypes.GENERATE_PDF,
	payload
});

export const generatePdfCompleted = (payload) => ({
	type: actionTypes.GENERATE_PDF_COMPLETED,
	payload
});

export const generatePdfFailed = (payload) => ({
	type: actionTypes.GENERATE_PDF_FAILED,
	payload
});
