import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { multiFactorAuthActions } from '../../containers/MultiFactorAuth';

const styles = (theme) => ({
	row: {
		display: 'flex',
		flexFlow: 'row'
	},
	instructionText: {
		padding: '5px'
	},
	flex: {
		flex: 1
	},
	authCodeContainer: {
		alignContent: 'center',
		alignItems: 'center',
		textAlign: 'middle',
		marginTop: '20px'
	},
	codeField: {
		marginLeft: '20px'
	},
	actionRow: {
		display: 'flex',
		alignSelf: 'stretch',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		marginTop: '10px'
	},
	cancelButton: {
		marginTop: '5px',
		width: '150px',
		color: theme.palette.primary.main,
		padding: '5px',
		marginRight: '10px',
		fontSize: '16px',
		fontWeight: '300'
	},
	inputButton: {
		marginTop: '5px',
		borderRadius: '4px',
		width: '150px',
		border: 'none',
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.secondary.alt,
		padding: '5px',
		fontSize: '16px',
		fontWeight: '300',
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
			cursor: 'pointer'
		}
	}
});

const MultiFactorAuthChallenge = (props) => {
	const {
		classes,
		t,
		authUser,
		codeVerified,
		userHasBeenLoaded,
		isVerifying,
		isLoadingUser
	} = props;
	const {
		row,
		instructionText,
		actionRow,
		cancelButton,
		inputButton,
		authCodeContainer,
		codeField
	} = classes;

	const [authenticationCode, setAuthenticationCode] = React.useState();

	const { state } = useLocation();
	const history = useHistory();

	const onVerifyCode = (authUser, challengeAnswer) => {
		const { verifyMultiFactorAuthCode } = props;

		verifyMultiFactorAuthCode({
			user: authUser,
			challengeAnswer
		});
	};

	React.useEffect(() => {
		if (codeVerified && userHasBeenLoaded) {
			if (state && state.pathname) {
				history.push(state.pathname);
			} else {
				history.push('/dashboard');
			}
		}
	}, [codeVerified, userHasBeenLoaded, history, state]);

	return (
		<Fragment>
			<div className={row}>
				<Typography className={instructionText}>
					{t('components.modals.MultiFactorAuth.enterYourCode')}
				</Typography>
			</div>
			{isLoadingUser || isVerifying ? (
				<CircularProgress />
			) : (
				<>
					<div className={clsx(row, authCodeContainer)}>
						{t('components.modals.MultiFactorAuth.authenticationCode')}
						<TextField
							onChange={(e) => setAuthenticationCode(e.target.value)}
							className={codeField}
							id="outlined-basic"
							variant="outlined"
							size="small"
						/>
					</div>
					<div className={actionRow}>
						<Button
							variant="text"
							size="small"
							className={cancelButton}
							onClick={() => {}}
						>
							{t('generic.buttons.cancel')}
						</Button>
						<Button
							variant="contained"
							size="small"
							className={inputButton}
							onClick={() => {
								onVerifyCode(authUser, authenticationCode);
							}}
						>
							{t('components.modals.MultiFactorAuth.submit')}
						</Button>
					</div>
				</>
			)}
		</Fragment>
	);
};

MultiFactorAuthChallenge.propTypes = {
	authUser: PropTypes.object,
	codeVerified: PropTypes.bool,
	isVerifying: PropTypes.bool,
	verifyMultiFactorAuthCode: PropTypes.func,
	userHasBeenLoaded: PropTypes.bool,
	isLoadingUser: PropTypes.bool,
	classes: PropTypes.object,
	t: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		authUser: state.authReducer.get('user'),
		challengeName: state.authReducer.get('challengeName'),
		codeVerified: state.multiFactorAuthReducer.get('codeVerified'),
		isVerifying: state.multiFactorAuthReducer.get('isVerifying'),
		logInError: state.authReducer.get('logInError'),
		userHasBeenLoaded: state.userReducer.get('userHasBeenLoaded'),
		isLoadingUser: state.userReducer.get('isLoadingUser')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		verifyMultiFactorAuthCode: (payload) => {
			dispatch(multiFactorAuthActions.verifyMultiFactorAuthCode(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(withTranslation()(MultiFactorAuthChallenge)));
