import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { OrganizationInformationForm } from './Forms';
import { organizationActions } from '../../containers/Organization';
import { userIsAdmin } from '../../utilities/userIsAdmin';

import { withStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';

const styles = (theme) => ({
	informationContainer: {
		display: 'flex',
		flexFlow: 'row',
		width: '500px',
		margin: '30px',
		borderColor: theme.palette.primary.main
	},
	avatarContainer: {
		display: 'flex',
		flex: '1',
		justifyContent: 'flex-end',
		alignItems: 'center'
	},
	detailsContainer: {
		display: 'flex',
		flex: '3',
		justifyContent: 'center'
	}
});

const _organizationData = {
	friendlyName: 'Sempris',
	techContact: 'Ethan Soper',
	techContactEmail: 'ethan.soper@sempris.com',
	techContactPhone: '8043840463'
};

export const OrganizationInformationSection = (props) => {
	const { organization, user, classes } = props;
	const { informationContainer, avatarContainer, detailsContainer } = classes;

	const onUpdateOrganizationInformation = (updatedOrganization) => {
		const { updateOrganization } = props;

		updateOrganization(updatedOrganization);
	};

	return (
		<Paper elevation={1} className={informationContainer} square>
			<div className={avatarContainer}>
				<Avatar
					alt={`${organization.friendlyName}`}
					src={organization.avatar}
					variant="rounded"
					sx={{ width: 100, height: 100 }}
				/>
			</div>
			<div className={detailsContainer}>
				<OrganizationInformationForm
					organization={_organizationData}
					onUpdate={onUpdateOrganizationInformation}
					disableEdit={!userIsAdmin(user)}
				/>
			</div>
		</Paper>
	);
};

OrganizationInformationSection.propTypes = {
	organization: PropTypes.object,
	user: PropTypes.object,
	updateOrganization: PropTypes.func,
	classes: PropTypes.object
};

const mapStateToProps = (state) => {
	return {
		user: state.userReducer.get('user'),
		organization: state.organizationReducer.get('organization')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateOrganization: (payload) => {
			dispatch(organizationActions.updateOrganization(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(OrganizationInformationSection));
