import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { withStyles } from '@mui/styles';
import Container from '@mui/material/Container';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';

const styles = (theme) => ({
	form: {
		width: '100%',
		marginBottom: '20px'
	},
	allowToastContainer: {
		display: 'flex',
		flexFlow: 'row',
		marginTop: '10px',
		marginBottom: '10px'
	},
	labelContainer: {
		flex: '4',
		padding: '5px'
	},
	inputButtonContainer: {
		display: 'flex',
		justifyContent: 'flex-end'
	},
	inputButton: {
		borderRadius: '4px',
		width: '100px',
		border: 'none',
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.secondary.alt,
		padding: '5px',
		fontSize: '16px',
		fontWeight: '300',
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
			cursor: 'pointer'
		}
	}
});

export const NotificationSettingsForm = (props) => {
	const { currentToastSetting, onUpdate, classes, t } = props;
	const {
		form,
		allowToastContainer,
		labelContainer,
		inputButtonContainer,
		inputButton
	} = classes;
	const [showToastNotifications, setToasterSetting] = React.useState(
		currentToastSetting
	);

	return (
		<Container className={form}>
			<div className={allowToastContainer}>
				<div className={labelContainer}>
					{t('components.forms.NotificationSettings.showToastNotifications')}
				</div>
				<Switch
					checked={showToastNotifications}
					onChange={() => {
						setToasterSetting(!showToastNotifications);
					}}
					size="large"
					color="secondary"
				/>
			</div>
			<div className={inputButtonContainer}>
				<Button
					variant="contained"
					size="small"
					className={inputButton}
					disabled={true}
					value="Update"
				>
					{t('generic.buttons.update')}
				</Button>
			</div>
		</Container>
	);
};

NotificationSettingsForm.propTypes = {
	currentToastSetting: PropTypes.bool,
	classes: PropTypes.object,
	onUpdate: PropTypes.func,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(NotificationSettingsForm));
