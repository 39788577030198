import { fromJS } from 'immutable';
import * as actionTypes from './actionTypes';

export const initialState = fromJS({
	reportViews: [],
	hasBeenLoaded: false,
	isLoading: false,
	error: '',
	reportTileBeingCreated: undefined
});

export const reportViewsReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_REPORT_VIEWS:
			return state.set('isLoading', true);

		case actionTypes.GET_REPORT_VIEWS_COMPLETED:
			return state
				.set('reportViews', action.payload)
				.set('isLoading', false)
				.set('hasBeenLoaded', true);

		case actionTypes.GET_REPORT_VIEWS_FAILED:
			return state
				.set('isLoading', false)
				.set('hasBeenLoaded', false)
				.set('error', action.payload);

		case actionTypes.SET_REPORT_TILE_BEING_CREATED:
			return state.set('reportTileBeingCreated', action.payload);

		default:
			return state;
	}
};
