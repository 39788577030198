import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withTranslation, Trans } from 'react-i18next';

const styles = (theme) => ({
	container: {
		marginTop: '24%'
	},
	textRow: {
		display: 'flex',
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'center'
	}
});

const NotDisplayable = (props) => {
	const { classes, t, height, width, dataViewType } = props;
	const { container, textRow } = classes;

	return (
		<div className={container} width={width} height={height}>
			<div className={textRow}>
				<Trans
					t={t}
					i18nKey="components.dashboardTiles.DashboardWidgetTile.unableToDisplay"
				>
					Whoops! This data isn't viewable as {{ dataViewType: dataViewType }}.
				</Trans>
			</div>
			<div className={textRow}>
				{t(
					'components.dashboardTiles.DashboardWidgetTile.selectAnotherViewType'
				)}
			</div>
		</div>
	);
};

NotDisplayable.propTypes = {
	dataViewType: PropTypes.string,
	classes: PropTypes.object,
	size: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(NotDisplayable));
