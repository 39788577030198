import ChargebackItemizationView from './ChargebackItemization/ChargebackItemizationView';
import ChargebackSummary from './ChargebackSummary/ChargebackSummaryView';
import DailyChargebackReport from './DailyChargebackReport/DailyChargebackReportView';
import PivotTableView from './PivotTable/PivotTableView';

export default [
	{
		reportViewId: 'b8b804d8-e685-410c-bb7f-f0d03e531459',
		component: ChargebackItemizationView
	},
	{
		reportViewId: '576794ac-2cc2-4ab8-ab88-2d6fca0fa20d',
		component: ChargebackSummary
	},
	{
		reportViewId: '40642d65-a2e9-4e43-a925-33be0a27e9e5',
		component: DailyChargebackReport
	},
	{
		reportViewId: 'y66fh62-j22j-410a-h6d3-f052sdbb092',
		component: PivotTableView
	}
];
