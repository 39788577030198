import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';

const styles = (theme) => ({
	container: {
		display: 'flex',
		flexFlow: 'row',
		margin: '10px',
		justifyContent: 'flex-end'
	},
	selectBox: {
		margin: '0px 3px',
		minWidth: '160px'
	},
	cardSelect: {
		height: '50px',
		minWidth: '300px',
		margin: '5px 5px 3px 5px',
		fontSize: '14px'
	}
});

const dateRanges = [
	// {
	// 	value: '1d',
	// 	label: 'Today'
	// },
	{
		value: '7d',
		label: 'Last 7 Days'
	}
];

const processors = [
	{
		value: 'fiserv',
		label: 'Fiserv'
	}
];

export const Filters = (props) => {
	const { classes, t, dateRange, setDateRange } = props;
	const { container, selectBox } = classes;

	// TODO WIRE UP TO PASS PROCESSOR TO QUERY
	const [processor, setProcessor] = React.useState('fiserv');

	return (
		<div className={container}>
			<FormControl>
				<InputLabel id="processor-label">
					{t('components.CurrentInventory.CurrentInventoryList.processor')}
				</InputLabel>
				<Select
					labelId="processor-label"
					id="processor-select"
					label={t(
						'components.CurrentInventory.CurrentInventoryList.processor'
					)}
					className={selectBox}
					value={processor}
					onChange={(e) => {
						setProcessor(e.target.value);
					}}
				>
					{processors.map((option) => (
						<MenuItem key={option.value} value={option.value}>
							{option.label}
						</MenuItem>
					))}
				</Select>
			</FormControl>
			<FormControl>
				<InputLabel id="date-range-label">
					{t('components.CurrentInventory.CurrentInventoryList.dateRange')}
				</InputLabel>
				<Select
					labelId="date-range-label"
					id="date-range-select"
					label={t(
						'components.CurrentInventory.CurrentInventoryList.dateRange'
					)}
					className={selectBox}
					value={dateRange}
					onChange={(e) => {
						setDateRange(e.target.value);
					}}
				>
					{dateRanges.map((option) => (
						<MenuItem key={option.value} value={option.value}>
							{option.label}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</div>
	);
};

Filters.propTypes = {
	dateRange: PropTypes.string,
	setDateRange: PropTypes.func,
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(Filters));
