import { capitalCase } from 'change-case';

export default function (results) {
	const columns = [];

	// HACK: hardcoded for now but write a function that finds the first id that doesn't match the mock
	if (results && results.length > 0) {
		const firstDataSet = results[0];

		Object.keys(firstDataSet).forEach((key) => {
			if (
				[
					'recordingStatus',
					'customerServiceRecordingStatus',
					'customerSalesRecordingStatus'
				].indexOf(key) > -1
			) {
				return;
			} else {
				columns.push({
					name: key,
					title: capitalCase(key),
					field: key,
					isDisplayed: true
				});
			}
		});
	}

	return columns;
}
