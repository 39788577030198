import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@mui/styles';
import groupNotificationsByType from '../../helpers/notifications/groupNotificationsByType';
import { CircularProgress } from '@mui/material';
import AccordionNotificationDisplay from './AccordionNotificationDisplay';

const styles = (theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		flex: 1
	}
});

const NotificationList = (props) => {
	const { classes, t, notifications, isLoading, hasBeenLoaded } = props;
	const { container } = classes;

	const [expanded, setExpanded] = React.useState('');

	const history = useHistory();
	const theme = useTheme();

	const _notifictionTypes = [
		{
			key: 'severe',
			labelTranslationPath: 'pages.notifications.general.severe',
			style: { color: theme.palette.notifications.type.severe }
		},
		{
			key: 'warning',
			labelTranslationPath: 'pages.notifications.general.warning',
			style: { color: theme.palette.notifications.type.warning }
		},
		{
			key: 'info',
			labelTranslationPath: 'pages.notifications.general.info',
			style: { color: theme.palette.notifications.type.info }
		}
	];

	const handleSetExpand = (type, expanded) => {
		setExpanded(type !== expanded ? type : '');
	};

	const handleSelect = (id, notification) => {
		if ([121, 123].includes(id)) {
			history.push(`/notifications/${id}`);
		}
	};

	return (
		<div className={container}>
			{isLoading || !hasBeenLoaded ? (
				<CircularProgress />
			) : (
				_notifictionTypes.map((notificationType) => {
					return (
						<AccordionNotificationDisplay
							key={notificationType.key}
							expanded={expanded}
							handleChange={handleSetExpand}
							filteredNotificationsByType={groupNotificationsByType(
								notifications,
								notificationType.key
							)}
							handleSelect={handleSelect}
							label={t(notificationType.labelTranslationPath)}
							displayStyle={notificationType.style}
							type={notificationType.key}
						/>
					);
				})
			)}
		</div>
	);
};

NotificationList.propTypes = {
	notifications: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	isLoading: PropTypes.bool,
	hasBeenLoaded: PropTypes.bool,
	getNotifications: PropTypes.func,
	t: PropTypes.func,
	classes: PropTypes.object
};

export default withStyles(styles)(withTranslation()(NotificationList));

// const handleSelect = (id) => {
// 	const filteredNotification = data.filter(
// 		(notification) => notification.id === id
// 	);
// 	history.push(`/notifications/${filteredNotification[0].id}`);
// 	setSelected(filteredNotification[0]);
// };

// const handleFilterNotificationsByType = (data, type) => {
// 	const filteredNotificationsByType = data.filter(
// 		(notification) => notification.type === type
// 	);

// 	return filteredNotificationsByType || [];
// };

// const handleChange = (panel) => (event, isExpanded) => {
// 	setExpanded(isExpanded ? panel : false);
// };

/*
			<AccordionNotificationDisplay
				expanded={expanded === 'info'}
				handleChange={handleChange}
				filteredNotificationsByType={handleFilterNotificationsByType(
					data,
					'Info'
				)}
				handleSelect={handleSelect}
				label={t('pages.notifications.general.info')}
				topLevelTypeOptionColors={topLevelTypeOptionColors}
				type={'info'}
			/>
			<AccordionNotificationDisplay
				expanded={expanded === 'warning'}
				handleChange={handleChange}
				filteredNotificationsByType={handleFilterNotificationsByType(
					data,
					'Warning'
				)}
				handleSelect={handleSelect}
				label={t('pages.notifications.general.warning')}
				topLevelTypeOptionColors={topLevelTypeOptionColors}
				type={'warning'}
			/>
			<AccordionNotificationDisplay
				expanded={expanded === 'severe'}
				handleChange={handleChange}
				filteredNotificationsByType={handleFilterNotificationsByType(
					data,
					'Severe'
				)}
				handleSelect={handleSelect}
				label={t('pages.notifications.general.severe')}
				topLevelTypeOptionColors={topLevelTypeOptionColors}
				type={'severe'}
			/>
			*/

/*
					notificationDetails: state.notificationReducer.getIn([
			'notificationDetails',
			'data'
		]),
		isLoadingDetails: state.notificationReducer.getIn([
			'notificationDetails',
			'isLoading'
		]),
		hasLoadedDetails: state.notificationReducer.getIn([
			'notificationDetails',
			'hasBeenLoaded'
		])
		*/

/*
				getNotificationDetailsById: (payload) => {
			dispatch(notificationActions.getNotificationDetailsById(payload));
		}
		*/
