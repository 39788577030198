import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';

const styles = (theme) => ({
	container: {
		display: 'flex',
		flexFlow: 'row',
		margin: '10px',
		justifyContent: 'flex-end'
	},
	dateSelect: {
		minWidth: '160px',
		backgroundColor: theme.palette.common.white
	}
});

const dateRanges = [
	// {
	// 	value: '7d',
	// 	label: 'Last 7 Days'
	// },
	// {
	// 	value: '14d',
	// 	label: 'Last 14 Days'
	// },
	{
		value: '30d',
		label: 'Last 30 Days'
	}
	// {
	// 	value: '60d',
	// 	label: 'Last 60 Days'
	// },
	// {
	// 	value: '365d',
	// 	label: 'Trailing Year'
	// }
];

export const Filters = (props) => {
	const { classes, t, dateRange, setDateRange } = props;
	const { container, dateSelect } = classes;

	return (
		<div className={container}>
			<FormControl>
				<InputLabel id="date-range-label">
					{t('components.CurrentInventory.CurrentInventoryList.dateRange')}
				</InputLabel>
				<Select
					labelId="date-range-label"
					id="date-range-select"
					label={t(
						'components.CurrentInventory.CurrentInventoryList.dateRange'
					)}
					className={dateSelect}
					value={dateRange}
					onChange={(e) => {
						setDateRange(e.target.value);
					}}
				>
					{dateRanges.map((option) => (
						<MenuItem key={option.value} value={option.value}>
							{option.label}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</div>
	);
};

Filters.propTypes = {
	dateRange: PropTypes.string,
	setDateRange: PropTypes.func,
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(Filters));
