import NotificationMasterList from '../../__Demo_Data__/Notifications/notificationMasterList';

export default [
	{
		id: 1,
		parentNotificationId: '121',
		assignedUserId: 2,
		assignedTo: 'Ethan Soper',
		createdByUserId: 4,
		createdBy: 'System',
		updatedByUserId: 4,
		message: NotificationMasterList.notifications.nine.text,
		tags: NotificationMasterList.notifications.nine.category,
		createdOn: '05/20/2022 11:37',
		updatedOn: '05/20/2022 11:37',
		type: 'Initial',
		actionItems: [
			{
				link:
					'/reports/y66fh62-j22j-410a-h6d3-f052sdbb092?id=12&autoApply=true&notificationId=121&header=Newly%20Onboarded%20Merchant%20Group&subHeader=Fraud%20Index%20Report&notification=Flowers%20by%20Friday%20had%20greater%20than%202X%20increase%20in%20fraud%20index%20score%20compared%20to%20prior%20week',
				title: 'Fraud Index Report'
			},
			{
				link:
					'/reports/y66fh62-j22j-410a-h6d3-f052sdbb092?id=10&autoApply=true&notificationId=121&header=Newly%20Onboarded%20Merchant%20Group&subHeader=Fraud%20Insights&notification=Flowers%20by%20Friday%20had%20greater%20than%202X%20increase%20in%20fraud%20index%20score%20compared%20to%20prior%20week',
				title: 'Fraud Insights'
			}
		],
		attachments: []
	},
	{
		id: 2,
		parentNotificationId: '121',
		assignedUserId: 2,
		assignedTo: 'Jason Pederson',
		createdByUserId: 4,
		createdBy: 'Jason Pederson',
		updatedByUserId: 4,
		message: '',
		tags: 'Research',
		createdOn: '05/20/2022 17:31',
		updatedOn: '05/20/2022 17:31',
		type: 'Assigned',
		actionItems: [],
		attachments: []
	},
	{
		id: 3,
		parentNotificationId: '121',
		assignedUserId: 2,
		assignedTo: 'Ethan Soper',
		createdByUserId: 4,
		createdBy: 'Jason Pederson',
		updatedByUserId: 4,
		message: 'Suggesting Ethan takes a look at this',
		tags: '',
		createdOn: '05/20/2022 19:02',
		updatedOn: '05/20/2022 19:02',
		type: 'Escalation',
		actionItems: [],
		attachments: []
	},
	{
		id: 4,
		parentNotificationId: '121',
		assignedUserId: 2,
		assignedTo: 'Ethan Soper',
		createdByUserId: 4,
		createdBy: 'Ethan Soper',
		updatedByUserId: 4,
		message:
			"Week ending 5/14 is showing an index of 3.12. i'm going to dig a little deeper.",
		tags: 'Research',
		createdOn: '05/21/2022 08:02',
		updatedOn: '05/21/2022 08:02',
		type: 'Alert',
		actionItems: [],
		attachments: []
	},
	{
		id: 5,
		parentNotificationId: '121',
		assignedUserId: 2,
		assignedTo: 'Ethan Soper',
		createdByUserId: 4,
		createdBy: 'Ethan Soper',
		updatedByUserId: 4,
		message:
			"It seems the issue resides at an affiliate level given that all products under Vinny's Vines are between 5.95% and 6.92%",
		tags: 'Affiliate,Product',
		createdOn: '05/21/2022 10:16',
		updatedOn: '05/21/2022 10:16',
		type: 'Resolved',
		actionItems: [],
		attachments: [
			{
				type: 'image',
				title: 'Fraud issues',
				assetName: 'notificationTestImage.PNG'
			}
		]
	},
	{
		id: 1,
		parentNotificationId: '123',
		assignedUserId: 2,
		createdByUserId: 4,
		updatedByUserId: 4,
		message: NotificationMasterList.notifications.ten.text,
		tags: NotificationMasterList.notifications.ten.category,
		createdOn: '05/17/2022 19:21',
		updatedOn: '05/17/2022 19:21',
		type: 'Initial',
		actionItems: [
			{
				link:
					'/reports/y66fh62-j22j-410a-h6d3-f052sdbb092?id=13&autoApply=true&notificationId=123&header=Newly%20Onboarded%20Merchant%20Group&subHeader=Sales%20Distribution%20by%20BIN%20Report&notification=Baby%20Box%20has%20marginal%20bin%20sales%20greater%20than%2020%25%20of%20overall%20sales',
				title: 'Sales Distribution by BIN Report'
			}
		],
		attachments: []
	}
	// {
	// 	id: 1,
	// 	parentNotificationId: '124',
	// 	assignedUserId: 2,
	// 	createdByUserId: 4,
	// 	updatedByUserId: 4,
	// 	message:
	// 		'[GNDBIE] Merchant: Coffee Club Mitigation Leakage has exceeded 20% one time in the past three weeks',
	// 	tags: 'Mitigation',
	// 	createdOn: '05/11/2022 18:47',
	// 	updatedOn: '05/11/2022 18:47',
	// 	type: 'Initial',
	// 	actionItems: [],
	// 	attachments: []
	// }
];
