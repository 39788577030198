export const GET_USERS = 'cloneLayout/GET_USERS';
export const GET_USERS_COMPLETED = 'cloneLayout/GET_USERS_COMPLETED';
export const GET_USERS_FAILED = 'cloneLayout/GET_USERS_FAILED';

export const GET_USER_LAYOUTS = 'cloneLayout/GET_USER_LAYOUTS';
export const GET_USER_LAYOUTS_COMPLETED =
	'cloneLayout/GET_USER_LAYOUTS_COMPLETED';
export const GET_USER_LAYOUTS_FAILED = 'cloneLayout/GET_USER_LAYOUTS_FAILED';

export const CLEAR_USER_LAYOUTS = 'cloneLayout/CLEAR_USER_LAYOUTS';
