import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import { sumOfActualsAndForecast } from '../../../../helpers/chargebackSummary/sumOfActualsAndForecast';

import Stack from '@mui/material/Stack';

const styles = (theme) => ({
	container: {
		display: 'flex',
		flex: 1
	},
	itemContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		flex: 1
	},
	labelText: {
		fontSize: '1rem',
		marginRight: '5px'
	},
	valueText: {
		fontWeight: 600,
		fontSize: '1rem'
	}
});

const _renderItem = (label, data, classes) => {
	const { itemContainer, labelText, valueText } = classes;

	return (
		<div className={itemContainer}>
			<span className={labelText}>{label}</span>
			<span className={valueText}>{data}</span>
		</div>
	);
};

const CollapsedTotals = (props) => {
	const { classes, data, t } = props;
	const { container } = classes;

	const summedData = sumOfActualsAndForecast(data);

	return (
		<Stack direction="row" spacing={2} className={container}>
			{_renderItem(
				t('pages.reports.chargebacks.summary.total.daily'),
				summedData.dailyChb,
				classes
			)}

			{_renderItem(
				t('pages.reports.chargebacks.summary.total.monthly'),
				summedData.monthlyChb,
				classes
			)}

			{_renderItem(
				t('pages.reports.chargebacks.summary.total.forecast'),
				summedData.forecastChb,
				classes
			)}
		</Stack>
	);
};

CollapsedTotals.propTypes = {
	cardType: PropTypes.string,
	classes: PropTypes.object,
	data: PropTypes.array,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(CollapsedTotals));
