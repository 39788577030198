import axios from '../../utilities/axios';
import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { refreshToken } from '../../utilities/refreshToken';
import { failureMessage } from '../ToastNotifications/actions';
import { primaryRestGateway } from '../../utilities/apiEndpointUtility';
import { dashboardActions } from '../Dashboard';

import * as actions from './actions.js';
import * as actionTypes from './actionTypes.js';
import querier from '../../utilities/querier';

// MOCK FOR DEMO
import mockLayouts from './__mocks__/__mockLayouts__';
import demoGetLayoutsEpic from '../../__Demo_Data__/Layouts/getLayoutsEpic';
import { demoWait } from '../../__Demo_Data__/mockRandomLoad';

export const getLayoutsEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.GET_LAYOUTS),
		mergeMap(async (action) => {
			await demoWait();
			// await refreshToken();
			// const userId = action.payload.userId;

			// const response = await axios.get(
			// 	`${primaryRestGateway()}/api/layouts/${userId}`
			// );

			const response = demoGetLayoutsEpic;

			return {
				layouts: response.data.results,
				defaultLayout: querier(response, 'data.results[0]')
			};
		}),
		switchMap((res) => [
			actions.getLayoutsCompleted(res.layouts),
			dashboardActions.setActiveDashboardLayout(res.defaultLayout)
		]),
		catchError((error, source) =>
			merge(
				of(
					actions.getLayoutsFailed(error.message),
					failureMessage(error.message)
				),
				source
			)
		)
	);

export const addLayoutEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.ADD_NEW_LAYOUT),
		mergeMap(async (action) => {
			await refreshToken();
			const userId = action.payload.userId;
			const data = action.payload.data;

			const response = await axios.post(
				`${primaryRestGateway()}/api/layouts/${userId}`,
				data
			);

			return response.data.results;
		}),
		switchMap((res) => [actions.addNewLayoutCompleted(res)]),
		catchError((error, source) =>
			merge(
				of(
					actions.addNewLayoutFailed(error.message),
					failureMessage(error.message)
				),
				source
			)
		)
	);

export const deleteLayoutEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.DELETE_LAYOUT),
		mergeMap(async (action) => {
			await refreshToken();
			const userId = action.payload.userId;
			const universalUniqueId = action.payload.universalUniqueId;

			const response = await axios.delete(
				`${primaryRestGateway()}/api/layouts/${userId}/layout/${universalUniqueId}`
			);

			return {
				universalUniqueId,
				data: response.data.results
			};
		}),
		switchMap((res) => [actions.deleteLayoutCompleted(res)]),
		catchError((error, source) =>
			merge(
				of(
					actions.deleteLayoutFailed(error.message),
					failureMessage(error.message)
				),
				source
			)
		)
	);

export const getSelectedUserLayouts = (action$) =>
	action$.pipe(
		ofType(actionTypes.GET_SELECTED_USER_LAYOUTS),
		mergeMap(async (action) => {
			await refreshToken();
			const userId = action.payload.userId;

			const response = await axios.get(
				`${primaryRestGateway()}/api/layouts/${userId}`
			);

			return {
				data: response.data.results,
				userId
			};
		}),
		switchMap((res) => [actions.getSelectedUserLayoutsCompleted(res)]),
		catchError((error, source) =>
			merge(
				of(
					actions.getSelectedUserLayoutsFailed(error.message),
					failureMessage(error.message)
				),
				source
			)
		)
	);
