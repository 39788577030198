import React, { Fragment } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import QuickGlanceItem from './QuickGlanceItem';

import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';

const styles = (theme) => ({
	container: {
		borderRadius: '5px',
		width: '100%',
		flexFlow: 'wrap'
	},
	missesText: {
		color: theme.palette.recoveryHistory.misses,
		[theme.breakpoints.up('laptop')]: {
			fontSize: '2rem'
		},
		[theme.breakpoints.down('laptop')]: {
			fontSize: '1.5rem'
		},
		[theme.breakpoints.down('tablet')]: {
			fontSize: '1rem'
		}
	},
	winsText: {
		color: theme.palette.recoveryHistory.wins,
		[theme.breakpoints.up('laptop')]: {
			fontSize: '2rem'
		},
		[theme.breakpoints.down('laptop')]: {
			fontSize: '1.5rem'
		},
		[theme.breakpoints.down('tablet')]: {
			fontSize: '1rem'
		}
	},
	pendingText: {
		color: theme.palette.recoveryHistory.pending,
		[theme.breakpoints.up('laptop')]: {
			fontSize: '2rem'
		},
		[theme.breakpoints.down('laptop')]: {
			fontSize: '1.5rem'
		},
		[theme.breakpoints.down('tablet')]: {
			fontSize: '1rem'
		}
	}
});

const _calculatePending = (wins, misses, total) => {
	let calculated = {};

	if (wins && misses && total) {
		calculated = {
			count: total.count - (misses.count + wins.count),
			value: total.value - (misses.value + wins.value)
		};
	}

	return calculated;
};

export const QuickGlance = (props) => {
	const { classes, data, isLoading, hasBeenLoaded } = props;
	const { container, missesText, winsText, pendingText } = classes;

	return (
		<Stack
			direction="row"
			spacing={2}
			alignItems="center"
			justifyContent="center"
			className={container}
		>
			{!hasBeenLoaded || isLoading ? (
				<Fragment>
					<Skeleton variant="text" width={200} height={60} />
					<Skeleton variant="text" width={200} height={60} />
				</Fragment>
			) : (
				<Fragment>
					<QuickGlanceItem
						data={data.misses}
						textClass={missesText}
						title={'components.RecoveryHistory.RecoveryHistoryManager.misses'}
					/>
					<QuickGlanceItem
						data={data.wins}
						textClass={winsText}
						title={'components.RecoveryHistory.RecoveryHistoryManager.wins'}
					/>
					<QuickGlanceItem
						data={_calculatePending(data.wins, data.misses, data.total)}
						textClass={pendingText}
						title={'components.RecoveryHistory.RecoveryHistoryManager.pending'}
					/>
				</Fragment>
			)}
		</Stack>
	);
};

QuickGlance.propTypes = {
	data: PropTypes.object,
	isLoading: PropTypes.bool,
	hasBeenLoaded: PropTypes.bool,
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(QuickGlance);
