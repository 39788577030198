import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const styles = (theme) => ({
	backButton: {
		display: 'flex',
		marginRight: '25px'
	},
	container: {
		display: 'flex',
		flex: 1,
		margin: '6px'
	},
	row: {
		display: 'flex',
		flexFlow: 'column'
	},
	title: {
		fontWeight: '600'
	}
});

const OptionsSelector = (props) => {
	const { classes, onSubmit } = props;
	const { backButton, container, row, title } = classes;

	const history = useHistory();

	const [autoApplyHasTriggered, setAutoApplyHasTriggered] = React.useState(
		false
	);

	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const isAutoApply = urlParams.get('autoApply');
	const dataId = urlParams.get('id');
	const header = urlParams.get('header');
	const subHeader = urlParams.get('subHeader');
	const notificationId = urlParams.get('notificationId');
	const notification = urlParams.get('notification');

	const handleSubmit = () => {
		onSubmit(dataId);
	};

	const handleNavigateBack = () => {
		history.push(`/notifications/${notificationId}`);
	};

	React.useEffect(() => {
		if (isAutoApply && dataId && !autoApplyHasTriggered) {
			handleSubmit();
			setAutoApplyHasTriggered(true);
		}
	}, [autoApplyHasTriggered, dataId, handleSubmit, isAutoApply]);

	return (
		<div className={container}>
			<div className={backButton}>
				<Tooltip title="Back To Notification">
					<IconButton onClick={() => handleNavigateBack()}>
						<ArrowBackIcon />
					</IconButton>
				</Tooltip>
			</div>
			<div className={row}>
				<Typography variant="h5" className={title}>
					{header}
				</Typography>
				<Typography variant="h6" className={title}>
					{subHeader}
				</Typography>
				{notification && (
					<Typography
						variant="subtitle2"
						className={title}
						style={{ fontStyle: 'italic', color: 'rgba(0,0,0,0.7)' }}
					>
						"{notification}"
					</Typography>
				)}
			</div>
		</div>
	);
};

OptionsSelector.propTypes = {
	classes: PropTypes.object,
	onSubmit: PropTypes.func
};

export default withStyles(styles)(withTranslation()(OptionsSelector));
