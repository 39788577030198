import * as GroupManagementEpics from './epics';
import * as groupManagementActions from './actions';
import * as groupManagementActionTypes from './actionTypes';
import { groupManagementReducer } from './reducer';

const groupManagementEpics = Object.values({ ...GroupManagementEpics });

export {
	groupManagementActions,
	groupManagementActionTypes,
	groupManagementReducer,
	groupManagementEpics
};
