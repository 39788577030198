import deepCopy from '../../utilities/deepCopy';

export default function (state, action, key, dataKey) {
	const { universalUniqueId, data } = action.payload;

	const deleteList = deepCopy(state.get(key));

	if (!deleteList.includes(universalUniqueId)) {
		deleteList.push(universalUniqueId);
	} else {
		const itemIndex = deleteList.indexOf(universalUniqueId);

		deleteList.splice(itemIndex, 1);
	}

	let updatedState = state.set(key, deleteList);

	if (data) {
		updatedState = updatedState.set(dataKey, data);
	}

	return updatedState;
}
