import deepCopy from '../../utilities/deepCopy';

export default function (state, action) {
	const layoutUuid = action.payload.layoutUniversalUniqueIdentifier;
	const tileUuid = action.payload.universalUniqueIdentifier;

	const existingTileData = state.getIn([
		'layoutTiles',
		`${layoutUuid}`,
		'data'
	]);

	let updatedState = state.set('tileBeingUpdated', undefined);

	if (existingTileData) {
		const tileIndex = existingTileData.findIndex((tile) => {
			return tile.universalUniqueIdentifier === tileUuid;
		});

		const updatedTileData = deepCopy(existingTileData);

		updatedTileData[tileIndex] = action.payload.data;

		updatedState = updatedState.setIn(
			['layoutTiles', layoutUuid, 'data'],
			updatedTileData
		);
	}

	return updatedState;
}
