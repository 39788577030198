import XLSX from 'xlsx';

export const downloadToCsv = (toWrite, label) => {
	const data = Array.isArray(toWrite) ? toWrite : [toWrite];

	const worksheet = XLSX.utils.json_to_sheet(data);
	const wb = XLSX.utils.book_new();
	const wopts = { bookType: 'xlsx', bookSST: false, type: 'array' };
	XLSX.utils.book_append_sheet(wb, worksheet);
	XLSX.writeFile(wb, `${label}-${Date.now()}.xlsx`, wopts);
};
