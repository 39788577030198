import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import { numberWithCommas } from '../../../utilities/formatNumber';

import Stack from '@mui/material/Stack';

const styles = (theme) => ({
	headerText: {
		marginLeft: '10px',
		marginRight: '10px',
		fontSize: '1.2rem'
	}
});

export const LegendItem = (props) => {
	const { classes, t, totalValue } = props;
	const { headerText } = classes;

	return (
		<Stack
			direction="row"
			spacing={2}
			justifyContent="space-between"
			alignItems="center"
		>
			<span className={headerText}>
				{t('components.RecoveryHistory.RecoveryHistoryManager.summary')}
			</span>
			<span className={headerText}>
				{`${t(
					'components.RecoveryHistory.RecoveryHistoryManager.totalValue'
				)} ${totalValue ? numberWithCommas(Math.round(totalValue)) : '-'}`}
			</span>
		</Stack>
	);
};

LegendItem.propTypes = {
	totalValue: PropTypes.number,
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(LegendItem));
