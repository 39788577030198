import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import LayoutListItem from './LayoutListItem';
import { layoutsActions } from '../../../../containers/Layouts';
import { tilesActions } from '../../../../containers/Tiles';
import shouldShowLoadingState from '../../../../helpers/dashboard/dashboardTileManager/shouldShowLoadingState';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

const styles = (theme) => ({
	layoutListContainer: {
		display: 'flex',
		flex: 1,
		flexFlow: 'column',
		padding: '2px'
	},
	headerText: {
		margin: '5px 0 10px 0',
		fontSize: '1.2rem'
	},
	buttonContainer: {
		display: 'flex',
		flexDirection: 'column',
		flex: '1',
		justifyContent: 'flex-end'
	},
	inputButton: {
		borderRadius: '.5rem',
		flexShrink: '0.1',
		border: 'none',
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.secondary.alt,
		padding: '5px',
		fontSize: '16px',
		fontWeight: '300',
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
			cursor: 'pointer'
		}
	},
	loadingContainer: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: '5%'
	}
});

export const LayoutList = (props) => {
	const {
		classes,
		layouts,
		t,
		activeDashboardLayout,
		isModifyingLayouts,
		layoutsBeingDeleted,
		layoutTilesBeingDeleted
	} = props;
	const {
		layoutListContainer,
		headerText,
		buttonContainer,
		inputButton,
		loadingContainer
	} = classes;

	const [
		layoutsMarkedForDeletion,
		setLayoutsMarkedForDeletion
	] = React.useState([]);

	const onEditClicked = (uuid) => {
		// TODO: GO TO LAYOUT EDIT SCREEN
	};

	const onSetAsDefaultClicked = (uuid) => {
		setTemporaryDefaultLayoutUuid(uuid);
	};

	const onMarkForDeletion = (uuid) => {
		const { sendDeleteWarning, layouts } = props;

		if (layouts.length - 1 === layoutsMarkedForDeletion.length) {
			sendDeleteWarning('Deleting all layouts is not allowed.');
		} else {
			setLayoutsMarkedForDeletion([...layoutsMarkedForDeletion, uuid]);
		}
	};

	const onRestoreClicked = (uuid) => {
		const updatedLayoutsList = layoutsMarkedForDeletion.filter((layoutUuid) => {
			return layoutUuid !== uuid;
		});

		setLayoutsMarkedForDeletion(updatedLayoutsList);
	};

	const onUpdateClicked = (layoutsMarkedForDeletion) => {
		const { deleteLayout, deleteTilesByLayout, user } = props;

		layoutsMarkedForDeletion.forEach((layoutUniversalUniqueId) => {
			deleteLayout({
				userId: user.id,
				universalUniqueId: layoutUniversalUniqueId
			});
			deleteTilesByLayout({
				universalUniqueId: layoutUniversalUniqueId
			});
		});
	};

	const renderLayoutListItem = (
		layoutListItem,
		index,
		layoutsMarkedForDeletion
	) => {
		const isMarkedForDeletion = layoutsMarkedForDeletion.includes(
			layoutListItem.universalUniqueIdentifier
		);
		return (
			<LayoutListItem
				key={layoutListItem.universalUniqueIdentifier}
				index={index}
				uuid={layoutListItem.universalUniqueIdentifier}
				label={layoutListItem.label}
				onEdit={onEditClicked}
				onSetAsDefault={onSetAsDefaultClicked}
				onMarkForDeletion={onMarkForDeletion}
				onRestore={onRestoreClicked}
				isMarkedForDeletion={isMarkedForDeletion}
			/>
		);
	};

	return (
		<div className={layoutListContainer}>
			{shouldShowLoadingState(
				isModifyingLayouts,
				layoutTilesBeingDeleted,
				layoutsBeingDeleted
			) ? (
				<div className={loadingContainer}>
					<CircularProgress />
				</div>
			) : (
				<>
					<span className={headerText}>Active Layouts</span>
					{layouts.map((layoutListItem, index) => {
						return renderLayoutListItem(
							layoutListItem,
							index,
							layoutsMarkedForDeletion,
							activeDashboardLayout.universalUniqueIdentifier
						);
					})}
				</>
			)}
			<div className={buttonContainer}>
				<Button
					variant="contained"
					size="small"
					className={inputButton}
					type="submit"
					onClick={() => {
						onUpdateClicked(layoutsMarkedForDeletion);
					}}
					disabled={isModifyingLayouts}
				>
					{t('components.modals.ManageDashboard.update')}
				</Button>
			</div>
		</div>
	);
};

LayoutList.propTypes = {
	user: PropTypes.object,
	layouts: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	activeDashboardLayout: PropTypes.object,
	modifyLayouts: PropTypes.func,
	deleteLayout: PropTypes.func,
	layoutsBeingDeleted: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	layoutTilesBeingDeleted: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.array
	]),
	classes: PropTypes.object,
	t: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		user: state.userReducer.get('user'),
		layouts: state.layoutsReducer.get('data'),
		layoutsBeingDeleted: state.layoutsReducer.get('layoutsBeingDeleted'),
		layoutTilesBeingDeleted: state.tilesReducer.get('layoutTilesBeingDeleted'),
		activeDashboardLayout: state.dashboardReducer.get('activeDashboardLayout')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		deleteLayout: (payload) => {
			dispatch(layoutsActions.deleteLayout(payload));
		},
		deleteTilesByLayout: (payload) => {
			dispatch(tilesActions.deleteTilesByLayout(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(withTranslation()(LayoutList)));
