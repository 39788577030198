import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
// import Amplify from 'aws-amplify';
import config from './config.json';

import 'ace-builds/src-min-noconflict/ace';
import 'ace-builds/src-min-noconflict/mode-mysql';
import 'ace-builds/src-min-noconflict/mode-json';
import 'ace-builds/src-min-noconflict/theme-monokai';

const setPool = () => {
	const queryString = window.location.search;
	// if (queryString) {
	const urlParams = new URLSearchParams(queryString);
	const ORG = urlParams.get('ORG') || 'Slyce360';
	const UNAME = urlParams.get('UNAME') || 'grichards';

	// if (CPI) {
	// const decodedCPI = decodeURIComponent(CPI);
	// const { ACID, ORG, REG, UNAME, UPID } = JSON.parse(decodedCPI);

	// if (ACID && ORG && REG && UNAME && UPID) {
	// eslint-disable-next-line
	localStorage.setItem(
		'CPI',
		JSON.stringify({
			ACID: '',
			ORG,
			REG: '',
			UNAME,
			UPID: ''
		})
	);
	// window.location.search = '';
	// return true;
	// }
	// }
	// }

	// eslint-disable-next-line
	// const CPI = localStorage.getItem('CPI');
	// if (CPI) {
	// 	return true;
	// }
};

// if (setPool()) {
// 	// eslint-disable-next-line
// 	const { ACID, REG, UPID } = JSON.parse(localStorage.getItem('CPI'));
// 	Amplify.configure({
// 		Auth: {
// 			mandatorySignIn: true,
// 			region: REG,
// 			userPoolId: UPID,
// 			userPoolWebClientId: ACID
// 		}
// 	});
setPool();
ReactDOM.render(<App />, document.getElementById('root'));
// } else {
// 	if (process.env.REACT_APP_LOCAL !== 'true') {
// 		window.location.replace(config[process.env.REACT_APP_STAGE].landingPageURI);
// 	} else {
// 		console.log('Please set your Pool');
// 	}
// }
