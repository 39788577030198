export const GET_LAYOUTS = 'layouts/GET_LAYOUTS';
export const GET_LAYOUTS_COMPLETED = 'layouts/GET_LAYOUTS_COMPLETED';
export const GET_LAYOUTS_FAILED = 'layouts/GET_LAYOUTS_FAILED';

export const ADD_NEW_LAYOUT = 'layouts/ADD_NEW_LAYOUT';
export const ADD_NEW_LAYOUT_COMPLETED = 'layouts/ADD_NEW_LAYOUT_COMPLETED';
export const ADD_NEW_LAYOUT_FAILED = 'layouts/ADD_NEW_LAYOUT_FAILED';

export const DELETE_LAYOUT = 'layouts/DELETE_LAYOUT';
export const DELETE_LAYOUT_COMPLETED = 'layouts/DELETE_LAYOUT_COMPLETED';
export const DELETE_LAYOUT_FAILED = 'layouts/DELETE_LAYOUT_FAILED';

export const GET_SELECTED_USER_LAYOUTS = 'layouts/GET_SELECTED_USER_LAYOUTS';
export const GET_SELECTED_USER_LAYOUTS_COMPLETED =
	'layouts/GET_SELECTED_USER_LAYOUTS_COMPLETED';
export const GET_SELECTED_USER_LAYOUTS_FAILED =
	'layouts/GET_SELECTED_USER_LAYOUTS_FAILED';
