import axios from '../../utilities/axios';
import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { refreshToken } from '../../utilities/refreshToken';
import { successMessage, failureMessage } from '../ToastNotifications/actions';
import { primaryRestGateway } from '../../utilities/apiEndpointUtility';

import * as actions from './actions.js';
import * as actionTypes from './actionTypes.js';

import demoGetUser from '../../__Demo_Data__/User/getUserEpic';
import demoGetUserFavoritedReports from '../../__Demo_Data__/User/getUserFavoritedReports';
import { demoWait } from '../../__Demo_Data__/mockRandomLoad';

export const getUserEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.GET_USER),
		mergeMap(async (action) => {
			await demoWait();
			// await refreshToken();

			// const username = action.payload;

			// const getUser = await axios.get(
			// 	`${primaryRestGateway()}/api/users/${username}`
			// );

			return {
				data: demoGetUser
			};
		}),
		switchMap((res) => [
			actions.getUserCompleted(res.data),
			successMessage('Successfully Fetched User Data')
		]),
		catchError((error, source) =>
			merge(
				of(actions.getUserFailed(error.message), failureMessage(error.message)),
				source
			)
		)
	);

export const getUserFavoritedReports = (action$) =>
	action$.pipe(
		ofType(actionTypes.GET_USER),
		mergeMap(async (action) => {
			await demoWait();
			// await refreshToken();

			// const username = action.payload;

			// const response = await axios.get(
			// 	`${primaryRestGateway()}/api/users/${username}/favorites/reports`
			// );

			const response = demoGetUserFavoritedReports;

			return response;
		}),
		switchMap((res) => [actions.getUserFavoriteReportsCompleted(res.data)]),
		catchError((error, source) =>
			merge(
				of(
					actions.getUserFavoriteReportsFailed(error.message),
					failureMessage(error.message)
				),
				source
			)
		)
	);

export const addUserFavoritedReports = (action$) =>
	action$.pipe(
		ofType(actionTypes.ADD_USER_FAVORITE_REPORT),
		mergeMap(async (action) => {
			await refreshToken();

			const username = action.payload.username;
			const reportId = action.payload.reportId;

			const response = await axios.post(
				`${primaryRestGateway()}/api/users/${username}/favorites/reports/${reportId}`
			);

			return response;
		}),
		switchMap((res) => [actions.addUserFavoriteReportCompleted(res.data)]),
		catchError((error, source) =>
			merge(
				of(
					actions.addUserFavoriteReportFailed(error.message),
					failureMessage(error.message)
				),
				source
			)
		)
	);

export const removeUserFavoritedReports = (action$) =>
	action$.pipe(
		ofType(actionTypes.REMOVE_USER_FAVORITE_REPORT),
		mergeMap(async (action) => {
			await refreshToken();

			const username = action.payload.username;
			const reportId = action.payload.reportId;

			const response = await axios.delete(
				`${primaryRestGateway()}/api/users/${username}/favorites/reports/${reportId}`
			);

			return response;
		}),
		switchMap((res) => [actions.removeUserFavoriteReportCompleted(res.data)]),
		catchError((error, source) =>
			merge(
				of(
					actions.removeUserFavoriteReportFailed(error.message),
					failureMessage(error.message)
				),
				source
			)
		)
	);

export const updateUserEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.UPDATE_USER),
		mergeMap(async (action) => {
			await refreshToken();

			const userId = action.payload.userId;
			const username = action.payload.username;
			const userData = action.payload.userData;

			const response = await axios.put(
				`${primaryRestGateway()}/api/users/${userId}`,
				{
					userData,
					username
				}
			);

			return response;
		}),
		switchMap((res) => [
			actions.updateUserCompleted(res.data),
			successMessage('Successfully Updated User Data')
		]),
		catchError((error, source) =>
			merge(
				of(
					actions.updateUserFailed(error.message),
					failureMessage(error.message)
				),
				source
			)
		)
	);

export const updateUserAvatarEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.UPDATE_USER_AVATAR),
		mergeMap(async (action) => {
			await refreshToken();

			const userId = action.payload.userId;
			const username = action.payload.username;
			const userData = action.payload.userData;

			const response = await axios.put(
				`${primaryRestGateway()}/api/users/${userId}`,
				{
					userData,
					username
				}
			);

			return response;
		}),
		switchMap((res) => [
			actions.updateUserAvatarCompleted(res.data),
			successMessage('Successfully Updated User Data')
		]),
		catchError((error, source) =>
			merge(
				of(
					actions.updateUserAvatarFailed(error.message),
					failureMessage(error.message)
				),
				source
			)
		)
	);
