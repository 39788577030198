import React from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { numberWithCommas } from '../../../utilities/formatNumber';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { withTranslation } from 'react-i18next';

const styles = (theme) => ({
	container: {
		borderRadius: '5px',
		[theme.breakpoints.up('tablet')]: {
			flexBasis: '31%'
		},
		[theme.breakpoints.down('tablet')]: {
			flexBasis: '100%'
		}
	}
});

export const QuickGlanceItem = (props) => {
	const { classes, t, data, title, textClass } = props;
	const { container } = classes;

	return (
		<Box className={container}>
			{data && (
				<Stack
					direction="row"
					spacing={1}
					alignItems="center"
					justifyContent="center"
				>
					<Typography className={textClass}>{t(title)}</Typography>
					<Divider orientation="vertical" flexItem />
					<Typography className={textClass}>
						{`$${numberWithCommas(Math.round(data.value))}`}
					</Typography>
				</Stack>
			)}
		</Box>
	);
};

QuickGlanceItem.propTypes = {
	data: PropTypes.object,
	title: PropTypes.string,
	textClass: PropTypes.string,
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(QuickGlanceItem));
