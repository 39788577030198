import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { modalActions } from '../../../../containers/Modal';
import { layoutsActions } from '../../../../containers/Layouts';
import shortUuid from 'short-uuid';
import moment from 'moment';
import generateNewTileConfiguration from '../../../../helpers/tiles/generateNewTileConfiguration';

import WizardProgressBar from './WizardProgressBar';
import WizardContent from './WizardContent';
import { tilesActions } from '../../../../containers/Tiles';

const styles = (theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		flex: 1
	}
});

const _initialConfiguration = {
	layoutCreationType: undefined,
	clonedLayoutUuid: undefined,
	name: '',
	tiles: []
};

const configuration = [
	{
		label: 'components.modals.createLayout.steps.cloneOrNew'
	},
	{
		label: 'components.modals.createLayout.steps.tileSelection'
	},
	{
		label: 'components.modals.createLayout.steps.nameAndOrder'
	},
	{
		label: 'components.modals.createLayout.steps.completed'
	}
];

export const WizardContentManager = (props) => {
	const { classes, openManageModal, isLoadingLayouts, layoutTiles } = props;
	const { container } = classes;

	const [activeStep, setActiveStep] = React.useState(0);
	const [activeStepCompleted, setActiveStepCompleted] = React.useState(false);
	const [
		createdLayoutConfiguration,
		setCreatedLayoutConfiguration
	] = React.useState();

	React.useEffect(() => {
		if (createdLayoutConfiguration === undefined) {
			setCreatedLayoutConfiguration({
				..._initialConfiguration,
				layoutUniversalUniqueIdentifier: shortUuid.uuid()
			});
		}
	}, [
		activeStep,
		createdLayoutConfiguration,
		setCreatedLayoutConfiguration,
		_initialConfiguration
	]);

	const submitLayout = () => {
		const { user, addTilesToLayout, addNewLayout } = props;

		const layoutUniversalUniqueIdentifier =
			createdLayoutConfiguration.layoutUniversalUniqueIdentifier;

		const newLayout = {
			universalUniqueIdentifier: layoutUniversalUniqueIdentifier,
			label: createdLayoutConfiguration.name,
			createdOn: moment.utc().format('YYYY-MM-DD'),
			createdByUserId: user.id,
			updatedOn: moment.utc().format('YYYY-MM-DD')
		};

		const newTiles = generateNewTileConfiguration(
			createdLayoutConfiguration.tiles,
			layoutUniversalUniqueIdentifier,
			user.id
		);

		addNewLayout({
			userId: user.id,
			data: newLayout
		});
		addTilesToLayout({
			layoutUniversalUniqueIdentifier,
			tiles: newTiles
		});

		setActiveStep(activeStep + 1);
	};

	const onCloseModal = () => {
		const { closeModal } = props;

		setCreatedLayoutConfiguration(undefined);
		closeModal();
	};

	return (
		<div className={container}>
			{createdLayoutConfiguration && (
				<Fragment>
					<WizardContent
						activeStep={activeStep}
						setActiveStep={setActiveStep}
						createdLayoutConfiguration={createdLayoutConfiguration}
						updateCreatedLayoutConfiguration={setCreatedLayoutConfiguration}
						activeStepCompleted={activeStepCompleted}
						setActiveStepCompleted={setActiveStepCompleted}
						closeModal={onCloseModal}
						openManageModal={openManageModal}
						onSubmit={submitLayout}
						isLoadingLayouts={isLoadingLayouts}
						layoutTiles={layoutTiles}
					/>
					<WizardProgressBar
						configuration={configuration}
						activeStep={activeStep}
					/>
				</Fragment>
			)}
		</div>
	);
};

WizardContentManager.propTypes = {
	user: PropTypes.object,
	isLoadingLayouts: PropTypes.bool,
	layoutTiles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	closeModal: PropTypes.func,
	openManageModal: PropTypes.func,
	addNewLayout: PropTypes.func,
	addTilesToLayout: PropTypes.func,
	classes: PropTypes.object,
	t: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		user: state.userReducer.get('user'),
		isLoadingLayouts: state.layoutsReducer.getIn(['layouts', 'isLoading']),
		layoutTiles: state.tilesReducer.get('layoutTiles')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		addNewLayout: (payload) => {
			dispatch(layoutsActions.addNewLayout(payload));
		},
		addTilesToLayout: (payload) => {
			dispatch(tilesActions.addTilesToLayout(payload));
		},
		closeModal: () => {
			dispatch(modalActions.toggleCreateLayoutModal());
		},
		openManageModal: () => {
			dispatch(modalActions.toggleManageDashboardModal());
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(withTranslation()(WizardContentManager)));
