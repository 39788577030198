import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';

import Paper from '@mui/material/Paper';
import { sentenceCase, capitalCase } from 'change-case';

import Tooltip from '@mui/material/Tooltip';

import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import SuggestedDisplayTypeList from './SuggestedDisplayTypeList';

const styles = (theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		width: '300px',
		margin: '20px',
		padding: 0,
		cursor: 'pointer'
	},
	iconRow: {
		display: 'flex',
		alignSelf: 'stretch',
		flexDirection: 'row',
		padding: '5px'
	},
	quickActionArea: {
		display: 'flex',
		flex: 1,
		justifyContent: 'flex-end'
	},
	dashboardActionItem: {
		color: theme.palette.reportViews.icons.actionIcon,
		'&:hover': {
			color: theme.palette.reportViews.icons.actionIconHover,
			cursor: 'pointer'
		}
	},
	configurableIcon: {
		color: theme.palette.reportViews.icons.actionIcon,
		'&:hover': {
			cursor: 'default'
		}
	},
	titleRow: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		marginTop: '3px',
		padding: '10px',
		fontSize: '1.2rem',
		color: theme.palette.reportViews.reportName
	},
	descriptionRow: {
		display: 'flex',
		height: '20px',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		marginTop: '3px',
		padding: '0 10px 0 10px'
	},
	systemTag: {
		border: `1px solid ${theme.palette.reportViews.icons.actionIcon}`,
		color: theme.palette.reportViews.systemTag,
		borderRadius: '3px',
		margin: '2px',
		fontSize: '.6rem',
		paddingLeft: '1rem',
		paddingRight: '1rem'
	},
	hidden: {
		visibility: 'hidden'
	}
});

export const ReportCard = (props) => {
	const {
		classes,
		t,
		onClick,
		reportView,
		isConfigurable,
		setReportTileBeingCreated
	} = props;
	const {
		container,
		iconRow,
		quickActionArea,
		titleRow,
		descriptionRow,
		dashboardActionItem,
		configurableIcon,
		systemTag,
		hidden
	} = classes;

	return (
		<Paper
			className={container}
			onClick={() => {
				onClick(reportView.id);
			}}
		>
			<div className={iconRow}>
				{isConfigurable && (
					<Tooltip title={t('components.ReportViews.ReportCard.configurable')}>
						<BuildCircleIcon className={configurableIcon} />
					</Tooltip>
				)}
				<div className={quickActionArea}>
					<DashboardCustomizeIcon
						className={clsx(
							dashboardActionItem,
							!reportView.allowAddToDashboard && hidden
						)}
						onClick={(e) => {
							e.stopPropagation();
							setReportTileBeingCreated(reportView);
						}}
					/>
				</div>
			</div>
			<div className={titleRow}>
				{capitalCase(sentenceCase(reportView.label))}
			</div>
			<div className={descriptionRow}>
				{reportView.reportIds.length > 1 && (
					<Fragment>
						{reportView.reportIds.map((report) => {
							return (
								<div key={report.id} className={systemTag}>
									{capitalCase(sentenceCase(report.label))}
								</div>
							);
						})}
					</Fragment>
				)}
			</div>
			<SuggestedDisplayTypeList
				suggestedDisplayTypes={reportView.suggestedDisplayTypes}
			/>
		</Paper>
	);
};

ReportCard.propTypes = {
	onClick: PropTypes.func,
	reportView: PropTypes.object,
	isConfigurable: PropTypes.bool,
	setReportTileBeingCreated: PropTypes.func,
	classes: PropTypes.object,
	t: PropTypes.func
};

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(withTranslation()(ReportCard)));
