import React from 'react';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Notifications from './Notifications';
import Screenshotter from '../../../../Screenshotter';
import Users from './Users';

const styles = (theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'row'
	}
});

const ActionSection = (props) => {
	const { classes, dashboardRef } = props;
	const { root } = classes;

	return (
		<div className={root}>
			<Users />
			<Notifications />
			<Screenshotter elementRef={dashboardRef} label="full-screen" />
		</div>
	);
};

ActionSection.propTypes = {
	classes: PropTypes.object,
	dashboardRef: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(ActionSection));
