import DebugView from './system/debug';
import FallbackView from './system/fallback';
import FullTableView from './tables/fullTable';
import CounterSmallView from './counters/counterSmall';
import KpiSmallView from './counters/kpiSmall';
import LineGraphView from './graphs/lineGraph';
import FlagGraphView from './graphs/flagGraph';

/* @Info: This list defines what shows up in the dropdown view list. */
const viewTypes = [
	{ title: 'Debug', id: 'DebugView' },
	{ title: 'Table', id: 'FullTableView' },
	{ title: 'Counter', id: 'CounterSmallView' },
	{ title: 'KPI', id: 'KpiSmallView' },
	{ title: 'Line Graph', id: 'LineGraphView' },
	{ title: 'Flag Graph', id: 'FlagGraphView' }
];

/* @Info: This list defines what views are accessible to the app. */
const viewComponents = {
	counter: CounterSmallView,
	debug: DebugView,
	fallback: FallbackView,
	fullTable: FullTableView,
	kpi: KpiSmallView,
	lineGraph: LineGraphView,
	flagGraph: FlagGraphView
};

export default {
	viewTypes,
	viewComponents
};
