import * as RecoveryHistoryEpics from './epics';
import * as recoveryHistoryActions from './actions';
import * as recoveryHistoryActionTypes from './actionTypes';
import { recoveryHistoryReducer } from './reducer';

const recoveryHistoryEpics = Object.values({ ...RecoveryHistoryEpics });

export {
	recoveryHistoryActions,
	recoveryHistoryActionTypes,
	recoveryHistoryReducer,
	recoveryHistoryEpics
};
