import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';

const styles = (theme) => ({
	container: {
		display: 'flex',
		flexFlow: 'column',
		padding: '5px',
		justifyContent: 'center',
		alignItems: 'center',
		padding: '5px'
	}
});

export const LoadingState = (props) => {
	const { classes, t, height, width } = props;
	const { container } = classes;

	return (
		<Container className={container} style={{ height, width }}>
			<CircularProgress color="secondary" />
		</Container>
	);
};

LoadingState.propTypes = {
	height: PropTypes.number,
	width: PropTypes.number,
	classes: PropTypes.object
};

export default withStyles(styles)(LoadingState);
