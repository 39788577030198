import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import FlagGraphView from './FlagGraphView';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Tooltip from '@mui/material/Tooltip';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FlagGraphSettings from './FlagGraphSettings';
import SettingsList from '../../../../partials/SettingsList';

const styles = (theme) => ({
	accordionContainer: {
		padding: 0,
		minHeight: '0px!important',
		maxHeight: '28px',
		'& div': {
			margin: 0,
			cursor: 'default',
			display: 'flex',
			justifyContent: 'flex-end'
		}
	},
	expandIcon: {
		fontSize: '30px',
		cursor: 'pointer'
	}
});

const FlagGraph = (props) => {
	const { classes, t } = props;
	const { accordionContainer, expandIcon } = classes;

	const determineSelectOptions = () => {
		const options = [];
		return options;
	};

	const [toggleAccordion, setToggleAccordion] = useState(true);
	const [toggleSettings, setToggleSettings] = useState(false);
	const [settingsValues, setSettingsValues] = useState({
		selectCount: determineSelectOptions()[0]
	});

	const handleClickSelect = useCallback(
		(key, value) => {
			const settings = Object.assign({}, settingsValues);
			settings[key] = value;
			setSettingsValues(settings);
		},
		[settingsValues, setSettingsValues]
	);

	return (
		<Accordion expanded={toggleAccordion} style={{ padding: 0 }}>
			<AccordionSummary
				expandIcon={
					<Tooltip title={toggleAccordion ? 'Collapse' : 'Expand'}>
						<ExpandMoreIcon
							className={expandIcon}
							onClick={() => setToggleAccordion(!toggleAccordion)}
						/>
					</Tooltip>
				}
				className={accordionContainer}
			>
				<SettingsList
					toggleSettings={setToggleSettings}
					showSettings={toggleSettings}
				/>
			</AccordionSummary>
			<AccordionDetails>
				{!toggleSettings ? (
					<FlagGraphView countType={settingsValues} />
				) : (
					<FlagGraphSettings
						determineSelectOptions={determineSelectOptions}
						handleClickSelect={handleClickSelect}
						settings={settingsValues}
						t={t}
						toggleSettings={setToggleSettings}
					/>
				)}
			</AccordionDetails>
		</Accordion>
	);
};

FlagGraph.propTypes = {
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(FlagGraph));
