import axios from '../../utilities/axios';
import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { failureMessage } from '../ToastNotifications/actions';
import { refreshToken } from '../../utilities/refreshToken';
import { primaryRestGateway } from '../../utilities/apiEndpointUtility';
import {
	__mockPivotData__,
	__mockPivotData2__
} from './__TESTDATA__/__pivotData__';

import * as actions from './actions';
import * as actionTypes from './actionTypes';

import mockExecuteReport from '../../__Demo_Data__/Reporter/executeReportEpic';
import { demoWait } from '../../__Demo_Data__/mockRandomLoad';

export const getReportOptionsEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.GET_REPORT_OPTIONS),
		mergeMap(async (action) => {
			await refreshToken();
			const { reportId, uuid, customRoute } = action.payload;

			let response;

			if (customRoute) {
				response = await axios.get(`${primaryRestGateway()}${customRoute}`);
			} else {
				response = await axios.get(
					`${primaryRestGateway()}/api/reports/${reportId}`
				);
			}

			return {
				uuid,
				data: response.data
			};
		}),
		switchMap((res) => [actions.getReportOptionsCompleted(res)]),
		catchError((error, source) =>
			merge(
				of(
					actions.getReportOptionsFailed(error.message),
					failureMessage(error.message)
				),
				source
			)
		)
	);

export const executeReportEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.EXECUTE_REPORT),
		mergeMap(async (action) => {
			await demoWait();
			//await refreshToken();
			const { id, queryParameters, uuid, customRoute } = action.payload;

			let response;

			// if (customRoute) {
			// 	response = await axios.get(`${primaryRestGateway()}${customRoute}`, {
			// 		params: queryParameters
			// 	});
			// } else {
			// 	response = await axios.get(
			// 		`${primaryRestGateway()}/api/reports/execute/${id}`,
			// 		{
			// 			params: queryParameters
			// 		}
			// 	);
			// }
			response = {
				data: mockExecuteReport[id || uuid].results
			};

			return {
				uuid,
				data: response.data
			};
		}),
		switchMap((res) => [actions.executeReportCompleted(res)]),
		catchError((error, source) =>
			merge(
				of(
					actions.executeReportFailed(error.message),
					failureMessage(error.message)
				),
				source
			)
		)
	);
