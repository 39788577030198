import React from 'react';

export default function (tile, dashboardTileList, size) {
	let SelectedTile = dashboardTileList[tile.userInterfaceDefinition];

	return (
		<SelectedTile
			tileProperties={tile}
			height={size.height}
			width={size.width}
		/>
	);
}
