import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';

import Button from '@mui/material/Button';

const styles = (theme) => ({
	button: {
		textTransform: 'none',
		margin: '5px',
		height: 'fit-content'
	},
	root: {
		display: 'flex'
	}
});

const FullTableColumnSelect = (props) => {
	const { classes, handleModal, t } = props;
	const { button, root } = classes;

	return (
		<div className={root}>
			<Button
				className={button}
				onClick={() => handleModal(true)}
				variant="outlined"
				size="small"
			>
				{t('components.Widget.WidgetViewer.FullTable.columns')}
			</Button>
		</div>
	);
};

FullTableColumnSelect.propTypes = {
	classes: PropTypes.object,
	handleModal: PropTypes.func,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(FullTableColumnSelect));
