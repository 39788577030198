import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import MaterialTable from '../../../../../Table/MaterialTable';
import FullTableColumnSelect from './FullTableColumnSelect';
import FullTableModal from './FullTableModal';
import generateColumnList from '../../../../../../helpers/shared/generateColumnList';
import formatFullTableRowData from '../../../../../../helpers/widget/formatFullTableRowData';

import { MTableToolbar } from '@material-table/core';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import TablePagination from '@mui/material/TablePagination';

const styles = (theme) => ({
	container: {
		display: 'flex',
		flex: 1,
		flexDirection: 'column'
	},
	expandIcon: {
		fontSize: '30px',
		cursor: 'pointer'
	},
	toolbar: {
		display: 'flex',
		justifyContent: 'space-between'
	},
	loadingContainer: {
		flexDirection: 'row',
		justifyContent: 'center'
	}
});

const FullTable = (props) => {
	const { classes, reportData } = props;
	const { toolbar, loadingContainer } = classes;

	const [columns, setColumns] = useState([]);
	const [isModalOpen, setIsModalOpen] = useState(false);

	React.useEffect(() => {
		if (reportData.data && reportData.hasBeenLoaded) {
			const generatedColumns = generateColumnList(reportData.data);

			setColumns(generatedColumns);
		}
	}, [reportData, setColumns]);

	const selectAll = (isTrue) => {
		const clonedColumns = Object.assign([], columns);
		clonedColumns.forEach((column) => {
			column.value = isTrue;
		});
		setColumns(clonedColumns);
	};

	const onUpdateColumns = (updatedColumns) => {
		setColumns(updatedColumns);
	};

	const filterColumnData = (columns) => {
		return columns.filter((column) => column.isDisplayed === true);
	};

	const toggleModal = () => {
		setIsModalOpen(!isModalOpen);
	};

	return (
		<Fragment>
			{!reportData.isLoading && columns.length > 0 ? (
				<MaterialTable
					columns={filterColumnData(columns)}
					components={{
						Toolbar: (props) => (
							<div className={toolbar}>
								<FullTableColumnSelect handleModal={toggleModal} />
								<MTableToolbar {...props} />
							</div>
						)
					}}
					data={reportData.data}
					options={{
						pageSize: 20,
						padding: 'dense',
						rowStyle: { fontSize: '1rem' },
						headerStyle: { fontSize: '12px', fontWeight: 550, width: '5vw' },
						showTitle: false
					}}
				/>
			) : (
				<Container maxWidth={false} className={loadingContainer}>
					<CircularProgress />
				</Container>
			)}
			<FullTableModal
				columns={columns}
				toggleModal={toggleModal}
				isModalOpen={isModalOpen}
				selectAll={selectAll}
				onUpdateColumns={onUpdateColumns}
			/>
		</Fragment>
	);
};

FullTable.propTypes = {
	classes: PropTypes.object,
	reportData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(FullTable));
