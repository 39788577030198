import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import RepresentmentTextField from './components/RepresentmentTextField';
import moment from 'moment';

const styles = (theme) => ({
	container: {
		display: 'flex',
		flexGrow: 1,
		flexDirection: 'column',
		padding: '10px',
		margin: '10px',
		borderRadius: '10px',
		border: `1px solid ${theme.palette.representment.sectionContainer.border}`,
		backgroundColor: theme.palette.representment.sectionContainer.background
	},
	sectionTitle: {
		backgroundColor:
			theme.palette.representment.sectionContainer.sectionTitleBackground,
		borderTopLeftRadius: '5px',
		borderTopRightRadius: '5px',
		width: 'fit-content',
		fontSize: '1rem',
		color: theme.palette.representment.sectionContainer.sectionText,
		margin: '-22px 0 0 2px',
		padding: '0 5px 0 5px'
	},
	row: {
		display: 'flex',
		flexDirection: 'row'
	}
});

export const ChargebackInformation = (props) => {
	const { classes, t, data } = props;
	const { container, sectionTitle, row } = classes;

	return (
		<div className={container}>
			<div className={sectionTitle}>
				{t('components.Representment.ChargebackInformation.label')}
			</div>
			<div className={row}>
				<RepresentmentTextField
					id="caseId"
					label={t('components.Representment.ChargebackInformation.caseId')}
					defaultValue={data.caseId}
				/>
				<RepresentmentTextField
					id="postedOn"
					label={t('components.Representment.ChargebackInformation.postedOn')}
					defaultValue={moment(data.postedOn).format('YYYY-MM-DD')}
				/>
			</div>
			<div className={row}>
				<RepresentmentTextField
					id="code"
					label={t('components.Representment.ChargebackInformation.code')}
					defaultValue={data.code}
				/>
				<RepresentmentTextField
					id="amount"
					label={t('components.Representment.ChargebackInformation.amount')}
					defaultValue={`${data.amount}`}
				/>
			</div>
		</div>
	);
};

ChargebackInformation.propTypes = {
	data: PropTypes.object,
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(ChargebackInformation));
