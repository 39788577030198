import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@mui/styles';
import { Switch, Case, Default } from 'react-if';

import { modalActions } from '../../../containers/Modal';
import {
	multiFactorAuthActions,
	multiFactorAuthConstants
} from '../../../containers/MultiFactorAuth';

import HeaderRow from '../shared/HeaderRow';

import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import SetupPage from './components/SetupPage';
import SuccessPage from './components/SuccessPage';
import ModifyPage from './components/ModifyPage';

const styles = (theme) => ({
	modal: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		flex: 1
	},
	paper: {
		display: 'flex',
		flexFlow: 'column',
		padding: '1rem'
	}
});

export const MultiFactorAuthSetupModal = (props) => {
	const {
		classes,
		user,
		isMultiFactorAuthSetupModalOpen,
		userPreferredMFA,
		mfaSetupError
	} = props;
	const { modal, paper } = classes;

	const location = useLocation();
	const history = useHistory();

	const modalFunction = location.pathname.split('/')[2];

	const conCloseModal = () => {
		const { closeModal } = props;

		closeModal();
		history.push('/account');
	};

	const onActivateMfa = (user, challengeAnswer) => {
		const { activateMultiFactorAuth } = props;

		activateMultiFactorAuth({
			user,
			challengeAnswer
		});
	};

	const onDisableMfa = (user, challengeAnswer) => {
		const { disableMultiFactorAuth, closeModal } = props;

		disableMultiFactorAuth({
			user,
			challengeAnswer
		});

		closeModal();
		history.push('/account');
	};

	return (
		<Modal
			open={isMultiFactorAuthSetupModalOpen}
			className={modal}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Paper className={paper} elevation={1}>
				<HeaderRow
					translationPath={
						'components.modals.MultiFactorAuth.multiFactorAuthSetup'
					}
					onClickCancel={conCloseModal}
				/>
				<Switch>
					<Case condition={modalFunction === 'deactivate-mfa'}>
						<ModifyPage
							user={user}
							onCloseModal={conCloseModal}
							onDisableMfa={onDisableMfa}
							mfaSetupError={mfaSetupError}
						/>
					</Case>
					<Case
						condition={
							modalFunction === 'activate-mfa' &&
							userPreferredMFA === multiFactorAuthConstants.softwareMfa
						}
					>
						<SuccessPage
							onCloseModal={conCloseModal}
							userPreferredMfa={userPreferredMFA}
						/>
					</Case>
					<Default>
						<SetupPage
							user={user}
							onActivateMfa={onActivateMfa}
							mfaSetupError={mfaSetupError}
						/>
					</Default>
				</Switch>
			</Paper>
		</Modal>
	);
};

MultiFactorAuthSetupModal.propTypes = {
	isMultiFactorAuthSetupModalOpen: PropTypes.bool,
	user: PropTypes.object,
	closeModal: PropTypes.func,
	activateMultiFactorAuth: PropTypes.func,
	disableMultiFactorAuth: PropTypes.func,
	userPreferredMFA: PropTypes.string,
	mfaSetupError: PropTypes.string,
	classes: PropTypes.object,
	t: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		isMultiFactorAuthSetupModalOpen: state.modalReducer.get(
			'isMultiFactorAuthSetupModalOpen'
		),
		userPreferredMFA: state.multiFactorAuthReducer.get('preferredMFA'),
		mfaSetupError: state.multiFactorAuthReducer.get('erorr'),
		user: state.authReducer.get('user')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		closeModal: () => {
			dispatch(modalActions.toggleMultiFactorAuthSetupModal());
		},
		activateMultiFactorAuth: (payload) => {
			dispatch(multiFactorAuthActions.activateMultiFactorAuth(payload));
		},
		disableMultiFactorAuth: (payload) => {
			dispatch(multiFactorAuthActions.disableMultiFactorAuth(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(withTranslation()(MultiFactorAuthSetupModal)));
