import * as actionTypes from './actionTypes';

export const toggleCreateLayoutModal = () => ({
	type: actionTypes.TOGGLE_CREATE_LAYOUT_MODAL
});

export const toggleManageDashboardModal = () => ({
	type: actionTypes.TOGGLE_MANAGE_DASHBOARD_MODAL
});

export const toggleMultiFactorAuthSetupModal = () => ({
	type: actionTypes.TOGGLE_MULTI_FACTOR_AUTH_SETUP_MODAL
});

export const toggleUpdateAvatarModal = () => ({
	type: actionTypes.TOGGLE_UPDATE_AVATAR_MODAL
});

export const toggleRecordingsModal = () => ({
	type: actionTypes.TOGGLE_RECORDINGS_MODAL
});

export const setAttachmentModalData = (payload) => ({
	type: actionTypes.SET_ATTACHMENT_MODAL_DATA,
	payload
});
