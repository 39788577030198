export default {
	'3fdc2d68-f431-4fcd-9c1d-f1252361c9e7': {
		request: 'api/v1/getTilesByLayout',
		pathParameters: {
			layoutUuid: '3fdc2d68-f431-4fcd-9c1d-f1252361c9e7'
		},
		results: [
			{
				universalUniqueIdentifier: '3c6bc96a-0dfb-4271-bcbf-aabc091247a1',
				layoutUniversalUniqueIdentifier: '3fdc2d68-f431-4fcd-9c1d-f1252361c9e7',
				label: 'High Risk Merchants',
				userInterfaceDefinition: 'recentNotifications',
				minWidth: 600,
				maxWidth: 700
			},
			{
				universalUniqueIdentifier: '346bc96a-0dfb-4271-bcbf-aabc09124711',
				layoutUniversalUniqueIdentifier: '3fdc2d68-f431-4fcd-9c1d-f1252361c9e7',
				label: 'Newly Onboarded Merchants',
				userInterfaceDefinition: 'recentNotifications',
				minWidth: 600,
				maxWidth: 700
			},
			{
				universalUniqueIdentifier: 'de7af4c2-cc6f-4fd4-ab90-f4a234a6e5b8',
				layoutUniversalUniqueIdentifier: '3fdc2d68-f431-4fcd-9c1d-f1252361c9e7',
				label: 'Notification Count by Mid',
				userInterfaceDefinition: 'staticMetricList',
				minWidth: 300,
				maxWidth: 350
			},
			{
				universalUniqueIdentifier: '3c6bc96a-0dfb-4271-bcbf-aabc09124722',
				layoutUniversalUniqueIdentifier: '3fdc2d68-f431-4fcd-9c1d-f1252361c9e7',
				label: 'Merchants With Current Violations',
				userInterfaceDefinition: 'recentNotifications',
				minWidth: 600,
				maxWidth: 700
			},
			{
				universalUniqueIdentifier: '346bc96a-0dfb-4271-bcbf-aabc09124733',
				layoutUniversalUniqueIdentifier: '3fdc2d68-f431-4fcd-9c1d-f1252361c9e7',
				label: 'Merchants With Former Violations',
				userInterfaceDefinition: 'recentNotifications',
				minWidth: 600,
				maxWidth: 700
			},
			{
				universalUniqueIdentifier: '0393c3be-08be-43a8-8aca-10b52568002a',
				layoutUniversalUniqueIdentifier: '3fdc2d68-f431-4fcd-9c1d-f1252361c9e7',
				label: 'Breakdown By Notification Type for High Risk Merchants',
				userInterfaceDefinition: 'breakdownBySeverity',
				minWidth: 300,
				maxWidth: 400
			},
			{
				universalUniqueIdentifier: 'f6940618-16b2-4808-b6b5-feac2a335961',
				layoutUniversalUniqueIdentifier: '3fdc2d68-f431-4fcd-9c1d-f1252361c9e7',
				label: '7-Day Notification Count for Newly Onboarded Merchants',
				userInterfaceDefinition: 'notificationByDay',
				minWidth: 700,
				maxWidth: 800
			}
		]
	},
	'a363d072-d387-4eb1-a472-6b5891875003': {
		request: 'api/v1/getTilesByLayout',
		pathParameters: {
			layoutUuid: 'a363d072-d387-4eb1-a472-6b5891875003'
		},
		results: [
			{
				universalUniqueIdentifier: 'fd336775-e53b-4550-907a-abce93877a04',
				layoutUniversalUniqueIdentifier: 'a363d072-d387-4eb1-a472-6b5891875003',
				userInterfaceDefinition: 'quickView',
				minWidth: 900,
				maxWidth: 1000
			},
			{
				universalUniqueIdentifier: 'fe8e55e1-4d7b-4fe6-99da-53d5ec71a048',
				layoutUniversalUniqueIdentifier: 'a363d072-d387-4eb1-a472-6b5891875003',
				label: 'High Risk Merchants',
				userInterfaceDefinition: 'recentNotifications',
				minWidth: 700,
				maxWidth: 800
			},
			{
				universalUniqueIdentifier: '4c5ecbcf-c0bf-4980-9604-391b2c37cea4',
				layoutUniversalUniqueIdentifier: 'a363d072-d387-4eb1-a472-6b5891875003',
				label: 'Fraud By Affiliate, Last 6 Months',
				userInterfaceDefinition: 'flagChartMetric',
				minWidth: 700,
				maxWidth: 800
			},
			{
				universalUniqueIdentifier: 'e1b1f221-bc6a-4b25-aa96-ba79a658abf0',
				layoutUniversalUniqueIdentifier: 'a363d072-d387-4eb1-a472-6b5891875003',
				label: 'Fraud Rates, Last 6 Months',
				userInterfaceDefinition: 'lineChartMetric',
				minWidth: 900,
				maxWidth: 1000
			}
		]
	}
};
