import moment from 'moment';
import handleComplexFilters from './handleComplexFilters';

export default function (reportOptions, filters) {
	const reportOptionsForm = {};

	if (reportOptions) {
		Object.keys(reportOptions).forEach((key) => {
			if (filters && filters[key]) {
				reportOptionsForm[key] = filters[key];
			} else if (reportOptions[key].isMultiSelect) {
				reportOptionsForm[key] = reportOptions[key].values;
			} else if (reportOptions[key].isDate) {
				reportOptionsForm[key] = moment().format('YYYY-MM-DD');
			} else {
				let value = handleComplexFilters(reportOptions[key].values[0]);

				if (key === 'LIMIT') {
					value = `${value}`;
				}

				reportOptionsForm[key] = value;
			}
		});
	}

	return reportOptionsForm;
}
