export default {
	avatar: '/sempris/1/avatar/avatar.png',
	email: 'grichards@bigbridgeholdings.com',
	firstName: 'George',
	lastName: 'Richards',
	phone: '8043840463',
	username: 'grichards',
	preferences: {
		dashboardLayoutId: null,
		showToastNotifications: 1
	}
};
