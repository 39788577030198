import React from 'react';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import TripOriginIcon from '@mui/icons-material/TripOrigin';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

export default function (theme, type) {
	switch (type) {
		case 'Alert':
			return (
				<ErrorOutlineIcon
					style={{ color: theme.palette.notifications.type.warning }}
				/>
			);
		case 'Assigned':
			return (
				<HowToRegIcon
					style={{ color: theme.palette.notifications.icons.assigned }}
				/>
			);
		case 'Blocked':
			return (
				<NotInterestedIcon
					style={{ color: theme.palette.notifications.type.severe }}
				/>
			);
		case 'Escalation':
			return (
				<TrendingUpIcon
					style={{ color: theme.palette.notifications.icons.escalation }}
				/>
			);
		case 'Resolved':
			return (
				<CheckCircleOutlineIcon
					style={{ color: theme.palette.notifications.type.info }}
				/>
			);
		default:
			return (
				<TripOriginIcon
					style={{ color: theme.palette.notifications.icons.default }}
				/>
			);
	}
}
