import React, { useRef } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import Screenshotter from '../Screenshotter';

import WidgetController from './WidgetController';
import WidgetViewer from './WidgetViewer';

const styles = ({ theme }) => ({
	widgetRoot: {
		display: 'flex',
		height: '100%',
		width: '100%',
		flexDirection: 'column',
		margin: '10px'
	}
});

const Widget = (props) => {
	const { classes, reportViewId, reportData, options } = props;
	const { widgetRoot } = classes;

	const widgetRef = useRef();

	return (
		<div className={widgetRoot} ref={widgetRef}>
			<WidgetController
				reportViewId={reportViewId}
				options={options}
				isLoadingReportData={reportData.isLoading || false}
			/>
			<WidgetViewer
				reportViewId={reportViewId}
				reportData={reportData}
				options={options}
			/>
		</div>
	);
};

Widget.propTypes = {
	reportViewId: PropTypes.string,
	reportData: PropTypes.object,
	options: PropTypes.object,
	classes: PropTypes.object
};

export default withStyles(styles)(Widget);

/*
	const sanitizeOptions = (options = {}) => {
		const defaultOptions = {
			dashboardActive: false,
			Screenshotter: (
				<Screenshotter label={label} elementRef={widgetRef} embedded={true} />
			),
			useScreenshotter: true
		};

		return Object.assign({}, defaultOptions, options);
	};
	*/
