import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';

const styles = (theme) => ({
	form: {
		display: 'flex',
		flexDirection: 'column',
		'& div': {
			margin: '8px 0px 8px 0px'
		}
	},
	root: {
		display: 'flex'
	}
});

const ConfirmDeleteModal = (props) => {
	const { classes, deleteData, open, t } = props;
	const { form, root } = classes;

	return (
		<div className={root}>
			<Dialog open={open}>
				<DialogTitle>{deleteData.label}</DialogTitle>
				<DialogContent className={form}>
					<Typography variant="body2">{deleteData.message}</Typography>
				</DialogContent>
				<DialogActions>
					<Button onClick={deleteData.handleClose} size="small">
						{t('generic.buttons.cancel')}
					</Button>
					<Button
						onClick={() => deleteData.handleSubmit(deleteData.parameters)}
						size="small"
					>
						{t('generic.buttons.delete')}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

ConfirmDeleteModal.propTypes = {
	classes: PropTypes.object,
	deleteData: PropTypes.object,
	open: PropTypes.bool,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(ConfirmDeleteModal));
