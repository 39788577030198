import amexLogo from './amex.svg';
import discoverLogo from './discover.svg';
import mastercardLogo from './mastercard.svg';
import visaLogo from './visa.svg';

export default {
	amex: amexLogo,
	discover: discoverLogo,
	mastercard: mastercardLogo,
	visa: visaLogo
};
