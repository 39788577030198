import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';

const styles = (theme) => ({});

const TableView = (props) => {
	const { classes, t, height, width, reportData } = props;
	const {} = classes;

	return <>Table View Here</>;
};

TableView.propTypes = {
	reportData: PropTypes.object,
	classes: PropTypes.object,
	size: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(TableView));
