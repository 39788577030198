export default function (state, action, key) {
	const layoutUuid = action.payload.layoutUniversalUniqueIdentifier;
	const existingLayout = state.get(key).get(layoutUuid);

	if (existingLayout === undefined) {
		return state.setIn(`${key}.${layoutUuid}`.split('.'), {
			isLoading: true,
			hasBeenLoaded: false,
			error: '',
			data: {}
		});
	}

	if (action.payload.data) {
		return state
			.setIn(`${key}.${layoutUuid}.isLoading`.split('.'), false)
			.setIn(`${key}.${layoutUuid}.hasBeenLoaded`.split('.'), true)
			.setIn(`${key}.${layoutUuid}.error`.split('.'), '')
			.setIn(`${key}.${layoutUuid}.data`.split('.'), action.payload.data);
	}

	if (action.payload.data === undefined) {
		return state.setIn(`${key}.${layoutUuid}.isLoading`.split('.'), true);
	}

	return state;
}
