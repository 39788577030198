import React, { Fragment } from 'react';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import MonthlySummaryByMidTable from './MonthlySummaryByMidTable';
import PropTypes from 'prop-types';
// import { reorder } from '../../../../helpers/dailyChargebackReport/reorder';
// import { capitalCase } from 'change-case';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

const styles = (theme) => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column'
	}
});

// TODO - WAIT FOR DATA TO FINISH THE RENDER STATES AS DATA MAY CHANGE
const _renderMonthlySummaryByMid = (
	data,
	hasBeenLoaded,
	isLoading,
	classes,
	t
) => {
	const { noData } = classes;

	let rendered;

	if (!hasBeenLoaded && isLoading) {
		rendered = <CircularProgress />;
	} else if (!hasBeenLoaded && !isLoading) {
		rendered = (
			<Typography className={noData}>
				{t(
					'components.staticReportViews.DailyChargebackReport.MonthlySummaryByMid.submitToLoad'
				)}
			</Typography>
		);
	} else if (hasBeenLoaded) {
		if (Object.keys(data).length === 0) {
			rendered = (
				<Typography className={noData}>
					{t(
						'components.staticReportViews.DailyChargebackReport.TwelveMonthLook.noData'
					)}
				</Typography>
			);
		} else {
			rendered = (
				<Fragment>
					{Object.entries(data).map(([key, cardData]) => {
						if (Object.entries(cardData.data).length > 0) {
							return <MonthlySummaryByMidTable key={key} cardData={cardData} />;
						}
					})}
				</Fragment>
			);
		}
	}

	return rendered;
};

const MonthlySummaryByMid = (props) => {
	const { classes, data, hasBeenLoaded, isLoading, report, t } = props;
	const { root } = classes;

	// TODO: bring in the mid name and potentially the processor during the data massage
	return (
		<Container className={root}>
			{_renderMonthlySummaryByMid(
				example, // TODO: This should be "data"
				hasBeenLoaded,
				isLoading,
				classes,
				t
			)}
		</Container>
	);
};

MonthlySummaryByMid.propTypes = {
	classes: PropTypes.object,
	data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	hasBeenLoaded: PropTypes.bool,
	isLoading: PropTypes.bool,
	report: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(MonthlySummaryByMid));

// @TODO: this is demo data - Remove when we have the call wired up
const example = [
	{
		cardType: 'visa',
		dateRangeStart: '2022-04-13',
		dateRangeEnd: '2022-04-13',
		mid: [
			'345045100889',
			'345045111886',
			'345045101887',
			'345045112884',
			'345045102885',
			'345045113882',
			'345045103883',
			'345045114880',
			'345045104881',
			'345045115887',
			'345045105888',
			'345045116885',
			'345045106886',
			'345045117883',
			'345045107884',
			'345045118881',
			'345045108882',
			'345045119889',
			'345045109880',
			'345045120887',
			'345045110888',
			'345045122883',
			'345045123881',
			'345045124889',
			'345045125886',
			'345045126884',
			'345045127882',
			'345045128880',
			'345045129888'
		],
		processor: 'FISERV',
		data: {
			daily: {
				chargebacks: {
					actual: 0,
					forecast: 2,
					deltaInteger: 2,
					deltaPercent: -100
				},
				transactions: {
					actual: 653,
					forecast: 802,
					deltaInteger: 149,
					deltaPercent: -18.58
				}
			},
			weekToDate: {
				chargebacks: {
					actual: 2,
					forecast: 12,
					deltaInteger: 10,
					deltaPercent: -83.33
				},
				transactions: {
					actual: 1745,
					forecast: 2215,
					deltaInteger: 470,
					deltaPercent: -21.22
				}
			},
			monthToDate: {
				chargebacks: {
					actual: 18,
					forecast: 54,
					deltaInteger: 36,
					deltaPercent: -66.67
				},
				transactions: {
					actual: 8344,
					forecast: 8769,
					deltaInteger: 425,
					deltaPercent: -4.85
				}
			}
		}
	},
	{
		cardType: 'mastercard',
		dateRangeStart: '2022-04-13',
		dateRangeEnd: '2022-04-13',
		mid: [
			'345045100889',
			'345045111886',
			'345045101887',
			'345045112884',
			'345045102885',
			'345045113882',
			'345045103883',
			'345045114880',
			'345045104881',
			'345045115887',
			'345045105888',
			'345045116885',
			'345045106886',
			'345045117883',
			'345045107884',
			'345045118881',
			'345045108882',
			'345045119889',
			'345045109880',
			'345045120887',
			'345045110888',
			'345045122883',
			'345045123881',
			'345045124889',
			'345045125886',
			'345045126884',
			'345045127882',
			'345045128880',
			'345045129888'
		],
		processor: 'FISERV',
		data: {
			daily: {
				chargebacks: {
					actual: 3,
					forecast: 2,
					deltaInteger: 1,
					deltaPercent: 50
				},
				transactions: {
					actual: 385,
					forecast: 543,
					deltaInteger: 158,
					deltaPercent: -29.1
				}
			},
			weekToDate: {
				chargebacks: {
					actual: 5,
					forecast: 4,
					deltaInteger: 1,
					deltaPercent: 25
				},
				transactions: {
					actual: 1041,
					forecast: 1465,
					deltaInteger: 424,
					deltaPercent: -28.94
				}
			},
			monthToDate: {
				chargebacks: {
					actual: 31,
					forecast: 22,
					deltaInteger: 9,
					deltaPercent: 40.91
				},
				transactions: {
					actual: 5024,
					forecast: 4881,
					deltaInteger: 143,
					deltaPercent: 2.39
				}
			}
		}
	},
	{
		cardType: 'discover',
		dateRangeStart: '2022-04-13',
		dateRangeEnd: '2022-04-13',
		mid: [
			'345045100889',
			'345045111886',
			'345045101887',
			'345045112884',
			'345045102885',
			'345045113882',
			'345045103883',
			'345045114880',
			'345045104881',
			'345045115887',
			'345045105888',
			'345045116885',
			'345045106886',
			'345045117883',
			'345045107884',
			'345045118881',
			'345045108882',
			'345045119889',
			'345045109880',
			'345045120887',
			'345045110888',
			'345045122883',
			'345045123881',
			'345045124889',
			'345045125886',
			'345045126884',
			'345045127882',
			'345045128880',
			'345045129888'
		],
		processor: 'FISERV',
		data: {
			daily: {
				chargebacks: {
					actual: 1,
					forecast: 0,
					deltaInteger: 1,
					deltaPercent: 0
				},
				transactions: {
					actual: 0,
					forecast: null,
					deltaInteger: 0,
					deltaPercent: 0
				}
			},
			weekToDate: {
				chargebacks: {
					actual: 7,
					forecast: 1,
					deltaInteger: 6,
					deltaPercent: 600
				},
				transactions: {
					actual: 77,
					forecast: 63,
					deltaInteger: 14,
					deltaPercent: 22.22
				}
			},
			monthToDate: {
				chargebacks: {
					actual: 24,
					forecast: 4,
					deltaInteger: 20,
					deltaPercent: 500
				},
				transactions: {
					actual: 353,
					forecast: 255,
					deltaInteger: 98,
					deltaPercent: 38.43
				}
			}
		}
	}
];
