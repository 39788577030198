import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@mui/styles';
import DataViewer from '../../../DataViewer/DataViewer';

const styles = (theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		flex: '1'
	}
});

const _mockData = [
	{
		key: "Tom's Tulips",
		value: 3339
	},
	{
		key: "Roger's Roses",
		value: 3185
	},
	{
		key: "Thelma's Thorn Bushes",
		value: 3034
	},
	{
		key: "Vinny's Vines",
		value: 2884
	},
	{
		key: "Patty's Petunias",
		value: 2731
	}
];

const FlagChartMetric = (props) => {
	const { classes, height, width } = props;
	const { stack } = classes;

	return (
		<DataViewer
			reportData={_mockData}
			dataViewType={'bar'}
			height={height * 0.9}
			width={width * 0.9}
			viewProps={{
				layout: 'horizontal'
			}}
		/>
	);
};

FlagChartMetric.propTypes = {
	tileProperties: PropTypes.object,
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(FlagChartMetric));
