import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import ReportViewsManager from '../../components/ReportViews/ReportViewsManager';
import AddReportToDashboardModal from '../../components/modals/AddReportToDashboardModal';

import Container from '@mui/material/Container';

const styles = (theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: 0,
		margin: '10px'
	}
});

const ReportViews = (props) => {
	const { classes } = props;
	const { root } = classes;

	return (
		<Container className={root} maxWidth={false}>
			<ReportViewsManager />
			<AddReportToDashboardModal />
		</Container>
	);
};

ReportViews.propTypes = {
	classes: PropTypes.object
};

export default withStyles(styles)(ReportViews);
