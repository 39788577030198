import React from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { sanitizeLabels } from '../../../../helpers/sanitizeLabels';
import { sanitizeKeys } from '../../../../helpers/sanitizeKeys';
import { getLabel } from '../../../../helpers/shared/cardBrandHelper';

import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const styles = (theme) => ({
	valueSelect: {
		display: 'flex',
		flexFlow: 'row wrap',
		minWidth: '200px',
		fontSize: '.8rem',
		height: '55px',
		margin: '5px 3px',
		backgroundColor: theme.palette.common.white
	}
});

const _formatCardTypes = (label, value) => {
	let formattedValue = value;

	if (label === 'CARD_TYPES') {
		formattedValue = getLabel(value);
	}

	return formattedValue;
};

const MultiSelectInput = (props) => {
	const { classes, label, onUpdate, option, value } = props;
	const { valueSelect } = classes;

	const handleSelectionUpdate = (value) => {
		onUpdate(label, value);
	};

	return (
		<FormControl>
			<InputLabel id={`multi-select-${label}-label`}>
				{sanitizeLabels(label)}
			</InputLabel>
			<Select
				labelId={`multi-select-${label}-label`}
				id={`multi-select-${label}`}
				multiple
				size="small"
				label={sanitizeLabels(label)}
				className={valueSelect}
				value={value}
				onChange={(e) => {
					handleSelectionUpdate(e.target.value);
				}}
				renderValue={(selected) => (
					<Box
						sx={{
							display: 'flex',
							flexWrap: 'wrap',
							gap: 0.5,
							flexWrap: 'nowrap !important'
						}}
					>
						{selected.map((value) => (
							<Chip key={value} label={_formatCardTypes(label, value)} />
						))}
					</Box>
				)}
			>
				{option.values.map((value) => (
					<MenuItem key={value} value={value}>
						{sanitizeKeys(value, label)}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

MultiSelectInput.propTypes = {
	classes: PropTypes.object,
	label: PropTypes.string,
	onUpdate: PropTypes.func,
	option: PropTypes.object,
	value: PropTypes.array
};

MultiSelectInput.defaultProps = {
	value: []
};

export default withStyles(styles)(MultiSelectInput);
