import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';

import Tooltip from '@mui/material/Tooltip';

import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const styles = (theme) => ({
	container: {
		display: 'flex',
		flex: 1,
		flexFlow: 'row',
		margin: '10px 20px 5px 20px'
	},
	reorderContainer: {
		display: 'flex',
		flexDirection: 'column'
	},
	contentContainer: {
		flex: 1,
		padding: '0 10px'
	},
	removeContainer: {
		display: 'flex',
		flexDirection: 'row'
	},
	deleteIcon: {
		color: theme.palette.representment.reversalReasons.removeIcon,
		'&:hover': {
			color: `${theme.palette.representment.reversalReasons.removeIconHover} !important`
		}
	},
	arrow: {
		color: theme.palette.representment.reversalReasons.moveIcon,
		'&:hover': {
			color: `${theme.palette.representment.reversalReasons.moveIconHover} !important`
		}
	},
	disabledArrow: {
		visibility: 'hidden'
	}
});

export const ReversalReasonItem = (props) => {
	const { classes, t, data, onRemoveItem, itemIndex, totalItems, onReorderReason } = props;
	const { container, reorderContainer, contentContainer, removeContainer, deleteIcon, arrow, disabledArrow } = classes;

	return (
		<div className={container}>
			<div className={reorderContainer}>
				{ itemIndex === 0
					? <KeyboardArrowUpIcon className={disabledArrow} />
					: <Tooltip title={t('components.Representment.ChargebackReversalReasons.moveUp')} placement='left'>
						<KeyboardArrowUpIcon className={arrow} onClick={() => { onReorderReason(itemIndex, itemIndex - 1); }}/>
					</Tooltip>
				}
				{ itemIndex === (totalItems - 1)
					? <KeyboardArrowDownIcon className={disabledArrow} />
					: <Tooltip title={t('components.Representment.ChargebackReversalReasons.moveDown')} placement='left'>
						<KeyboardArrowDownIcon className={arrow} onClick={() => { onReorderReason(itemIndex, itemIndex + 1); }}/>
					</Tooltip>
				}

			</div>
			<div className={contentContainer}>{data}</div>
			<div className={removeContainer}><DeleteIcon className={deleteIcon} onClick={() => { onRemoveItem(itemIndex); }}/></div>
		</div>
	);
};

ReversalReasonItem.propTypes = {
	data: PropTypes.string,
	onRemoveItem: PropTypes.func,
	itemIndex: PropTypes.number,
	totalItems: PropTypes.number,
	onReorderReason: PropTypes.func,
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(ReversalReasonItem));
