import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import { reversalReasons } from './constants/reversalReasons';

const styles = (theme) => ({
	container: {
		display: 'flex',
		flex: 1,
		flexFlow: 'row',
		padding: '10px',
		margin: '10px'
	},
	reasonContainer: {
		display: 'flex',
		flex: 1,
		flexDirection: 'row',
		padding: '5px 20px 5px 10px'
	},
	selectionField: {
		flex: 1
	},
	addButtonContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		marginTop: '10px'
	},
	addButton: {
		color: theme.palette.representment.reversalReasons.addButton,
		fontSize: '2.2rem',
		'&:hover': {
			color: `${theme.palette.representment.reversalReasons.addButtonHover} !important`,
			cursor: 'pointer'
		}
	}
});

export const AddChargebackReason = (props) => {
	const { classes, onAddChargebackReason } = props;
	const { container, reasonContainer, selectionField } = classes;

	const handleReasonChange = (e) => {
		if (e.target.value) {
			onAddChargebackReason(e.target.value);
		}
	};

	return (
		<div className={container}>
			<div className={reasonContainer}>
				<TextField
					className={selectionField}
					id='selet-reason'
					select
					label='Select'
					size='small'
					value={reversalReasons[0].value}
					onChange={handleReasonChange}
					helperText="Please select chargeback reversal reason to add"
					variant="filled"
				>
					{reversalReasons.map((option, index) => (
						<MenuItem key={index} value={option.value}>
							{option.label}
						</MenuItem>
					))}
				</TextField>
			</div>
		</div>
	);
};

AddChargebackReason.propTypes = {
	onAddChargebackReason: PropTypes.func,
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(AddChargebackReason));
