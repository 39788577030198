import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { useDrag, useDrop } from 'react-dnd';
import clsx from 'clsx';

import DehazeIcon from '@mui/icons-material/Dehaze';
import AppsIcon from '@mui/icons-material/Apps';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';

const ITEMTYPE = 'TileListItem';

const styles = (theme) => ({
	listItem: {
		display: 'flex',
		flexFlow: 'row',
		cursor: 'pointer'
	},
	listItemDragging: {
		border: `2px dotted ${theme.palette.primary.main}`,
		opacity: '0.5'
	},
	dragIcon: {
		padding: '0px 10px 0px 5px',
		fontSize: '.25rem',
		color: theme.palette.icons.disabled
	},
	tileTypeIcon: {
		padding: '0px 10px 0px 5px',
		fontSize: '.25rem',
		color: theme.palette.icons.default
	}
});

export const OrderTileListItem = (props) => {
	const {
		classes,
		universalUniqueIdentifier,
		label,
		type,
		index,
		moveLayoutItem
	} = props;
	const { listItem, listItemDragging, dragIcon, tileTypeIcon } = classes;

	const ref = React.useRef(null);

	const [{ handlerId }, drop] = useDrop({
		accept: ITEMTYPE,
		collect(monitor) {
			return {
				handlerId: monitor.getHandlerId()
			};
		},
		hover(item, monitor) {
			if (!ref.current) {
				return;
			}
			const dragIndex = item.index;
			const hoverIndex = index;
			if (dragIndex === hoverIndex) {
				return;
			}
			const hoverBoundingRect = ref.current?.getBoundingClientRect();
			const hoverMiddleY =
				(hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
			const clientOffset = monitor.getClientOffset();
			const hoverClientY = clientOffset.y - hoverBoundingRect.top;
			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
				return;
			}
			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
				return;
			}
			moveLayoutItem(dragIndex, hoverIndex);
			item.index = hoverIndex;
		}
	});

	const [{ isDragging }, drag] = useDrag({
		type: ITEMTYPE,
		item: () => {
			return { universalUniqueIdentifier, index };
		},
		collect: (monitor) => ({
			isDragging: monitor.isDragging()
		})
	});
	drag(drop(ref));

	return (
		<div
			ref={ref}
			className={clsx(listItem, isDragging && listItemDragging)}
			data-handler-id={handlerId}
		>
			<span className={dragIcon}>
				<DehazeIcon />
			</span>
			<span className={tileTypeIcon}>
				{type === 'static' ? <AppsIcon /> : <AppRegistrationIcon />}
			</span>
			<span>{label}</span>
		</div>
	);
};

OrderTileListItem.propTypes = {
	uuid: PropTypes.string,
	label: PropTypes.string,
	type: PropTypes.string,
	index: PropTypes.number,
	moveLayoutItem: PropTypes.func,
	isDefaultLayout: PropTypes.bool,
	classes: PropTypes.object
};

export default withStyles(styles)(OrderTileListItem);
