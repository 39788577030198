import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import { sumOfActualsAndForecast } from '../../../../helpers/chargebackSummary/sumOfActualsAndForecast';
import { sanitizeProcessors } from '../../../../helpers/sanitizeKeys';
import { useHistory } from 'react-router-dom';
import CONSTANTS from '../../../../helpers/constants';

import Button from '@mui/material/Button';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import Typography from '@mui/material/Typography';

const styles = (theme) => ({
	button: {
		textTransform: 'none',
		color: theme.palette.common.black,
		margin: '2px'
	},
	footerCell: {
		color: theme.palette.common.black
	},
	footerFontSize: {
		fontSize: '.8rem'
	},
	total: {
		fontWeight: 600
	}
});

const Footer = (props) => {
	const { classes, data, processor, t } = props;
	const { button, footerCell, footerFontSize, total } = classes;

	const history = useHistory();

	const processorCheck = (processor) => {
		const {
			BAMS,
			BLSNP_PRI,
			BLSNP_SAN,
			IPPAY_WAM,
			PYSF_MERRI
		} = CONSTANTS.PROCESSORS_AND_MIDS;
		switch (processor) {
			case 'BAMS':
				return BAMS;
			case 'BLSNP_PRI':
				return BLSNP_PRI;
			case 'BLSNP_SAN':
				return BLSNP_SAN;
			case 'IPPAY_WAM':
				return IPPAY_WAM;
			case 'PYSF_MERRI':
				return PYSF_MERRI;
			default:
				break;
		}
	};

	const handleSummaryRedirect = () => {
		// TODO: pathing is deprecated, but currently the button assoc. with this is disabled
		const merchantCodes = processorCheck(processor);
		const sanitizedProcessor = encodeURIComponent(processor);
		const sanitizedMids = encodeURIComponent(merchantCodes);

		history.push(
			`/chargebacks/daily-chargeback-report?processor=${sanitizedProcessor}&mids=${sanitizedMids}&autoApply=true`
		);
	};

	return (
		<TableFooter>
			<TableRow>
				<TableCell className={footerCell}>
					<Typography variant="subtitle2" className={total}>
						{t('pages.reports.chargebacks.summary.total.total')}
					</Typography>
				</TableCell>
				<TableCell className={footerCell}>
					<Typography variant="subtitle2" className={footerFontSize}>
						{sumOfActualsAndForecast(data).dailyChb}
					</Typography>
				</TableCell>
				<TableCell className={footerCell}>
					<Typography variant="subtitle2" className={footerFontSize}>
						{sumOfActualsAndForecast(data).monthlyChb}
					</Typography>
				</TableCell>
				<TableCell className={footerCell}>
					<Typography variant="subtitle2" className={footerFontSize}>
						{sumOfActualsAndForecast(data).forecastChb}
					</Typography>
				</TableCell>
				<TableCell></TableCell>
				<TableCell></TableCell>
				<TableCell>
					<Button
						size="small"
						disabled
						variant="outlined"
						className={button}
						color="primary"
						onClick={() => handleSummaryRedirect()}
					>
						Summary
					</Button>
				</TableCell>
			</TableRow>
		</TableFooter>
	);
};

Footer.propTypes = {
	classes: PropTypes.object,
	data: PropTypes.array,
	processor: PropTypes.string,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(Footer));
