import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';

import Button from '@mui/material/Button';

const styles = (theme) => ({
	containerRow: {
		display: 'flex',
		alignSelf: 'stretch',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		margin: '1rem 0 1rem 0',
		marginLeft: '2%'
	}
});

export const ActionRow = (props) => {
	const { classes, t } = props;
	const { containerRow, manageButton } = classes;

	return (
		<div className={containerRow}>
			<Button className={manageButton} onClick={() => {}} variant="outlined">
				{t('components.GroupManagement.ActionRow.createGroup')}
			</Button>
		</div>
	);
};

ActionRow.propTypes = {
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(ActionRow));
