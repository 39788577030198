import moment from 'moment';

const FORMAT_STRING = 'YYYY-MM-DD';

export default function (dateRange) {
	let generatedDateRange;

	const daysBack = dateRange.slice(0, dateRange.length - 1);

	if (daysBack) {
		const startDate = moment
			.utc()
			.add(`-${daysBack}`, 'days')
			.format(FORMAT_STRING);
		const endDate = moment.utc().format(FORMAT_STRING);

		generatedDateRange = `${startDate},${endDate}`;
	} else {
		const startDate = moment.utc().add(-1, 'days').format(FORMAT_STRING);
		const endDate = moment.utc().format(FORMAT_STRING);

		generatedDateRange = `${startDate},${endDate}`;
	}

	return generatedDateRange;
}
