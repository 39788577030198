import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';

import RepresentmentTextField from './components/RepresentmentTextField';

const styles = (theme) => ({
	container: {
		display: 'flex',
		flexGrow: 1,
		flexFlow: 'column',
		padding: '10px',
		margin: '10px',
		borderRadius: '10px',
		border: `1px solid ${theme.palette.representment.sectionContainer.border}`,
		backgroundColor: theme.palette.representment.sectionContainer.background
	},
	sectionTitle: {
		backgroundColor:
			theme.palette.representment.sectionContainer.sectionTitleBackground,
		borderTopLeftRadius: '5px',
		borderTopRightRadius: '5px',
		width: 'fit-content',
		fontSize: '1rem',
		color: theme.palette.representment.sectionContainer.sectionText,
		margin: '-22px 0 0 2px',
		padding: '0 5px 0 5px'
	},
	row: {
		display: 'flex',
		flexDirection: 'row'
	}
});

export const CustomerInformation = (props) => {
	const { classes, t, data } = props;
	const { container, sectionTitle, row } = classes;

	return (
		<div className={container}>
			<div className={sectionTitle}>
				{t('components.Representment.CustomerInformation.label')}
			</div>
			<div className={row}>
				<RepresentmentTextField
					id="billingName"
					label={t('components.Representment.CustomerInformation.billingName')}
					defaultValue={`${data.billingFirstName} ${data.billingLastName}`}
				/>
				<RepresentmentTextField
					id="customerId"
					label={t('components.Representment.CustomerInformation.customerId')}
					defaultValue={`${data.customerId}`}
				/>
				<RepresentmentTextField
					id="phoneNumber"
					label={t('components.Representment.CustomerInformation.phoneNumber')}
					defaultValue={data.phoneNumber}
				/>
			</div>
			<RepresentmentTextField
				id="address"
				label={t('components.Representment.CustomerInformation.address')}
				isFlexed={true}
				defaultValue={`${data.billingAddress} ${data.billingCity}, ${data.billingStateProvince} ${data.billingPostalCode}`}
			/>
			<RepresentmentTextField
				id="email"
				label={t('components.Representment.CustomerInformation.email')}
				isFlexed={true}
				defaultValue={data.email}
			/>
		</div>
	);
};

CustomerInformation.propTypes = {
	data: PropTypes.object,
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(CustomerInformation));
