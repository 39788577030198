import React from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import IconButton from '@mui/material/IconButton';

import CameraIcon from '@mui/icons-material/Camera';

const StyledIconButton = withStyles((theme) => ({
	screenShotIcon: {
		color: theme.palette.secondary.main,
		padding: '5px',
		'& svg': {
			fontSize: '22px'
		}
	}
}))((props) => {
	const { classes, onClick } = props;
	const { screenShotIcon } = classes;
	return (
		<IconButton
			aria-label="component screenshot"
			className={screenShotIcon}
			component="span"
			onClick={onClick}
		>
			<CameraIcon />
		</IconButton>
	);
});

const ActionSelect = (props) => {
	const { onClick } = props;

	return (
		<div>
			<StyledIconButton onClick={onClick} />
		</div>
	);
};

ActionSelect.propTypes = {
	onClick: PropTypes.func
};

export default ActionSelect;
