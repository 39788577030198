import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import Container from '@mui/material/Container';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

const styles = (theme) => ({
	progressContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		marginBottom: '10px'

	},
	stepper: {
		flex: 1
	}
});

export const WizardProgressBar = (props) => {
	const { classes, t, configuration, activeStep } = props;
	const { progressContainer, stepper } = classes;

	return (
		<Container className={progressContainer}>
			<Stepper className={stepper} activeStep={activeStep}>
				{configuration.map(({ label }) => {
					return (
						<Step key={label} >
							<StepLabel >{t(label)}</StepLabel>
						</Step>
					);
				})}
			</Stepper>
		</Container>
	);
};

WizardProgressBar.propTypes = {
	configuration: PropTypes.array,
	activeStep: PropTypes.number,
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(WizardProgressBar));
