import { fromJS } from 'immutable';
import * as actionTypes from './actionTypes';

export const initialState = fromJS({
	notifications: {
		data: [],
		error: undefined,
		hasBeenLoaded: false,
		isLoading: false
	},
	notificationDetails: {
		data: [],
		error: undefined,
		hasBeenLoaded: false,
		isLoading: false
	}
});

const getAppropriateTimeToUpdate = () => {
	const date = new Date();
	let fullDate = `${
		date.getMonth() + 1
	}/${date.getDate()}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;

	return fullDate;
};

const setNewNotificationDetails = (notificationDetails, newDetail) => {
	notificationDetails[newDetail.parentNotificationId].push({
		id: notificationDetails[newDetail.parentNotificationId].length + 1,
		parentNotificationId: newDetail.parentNotificationId,
		assignedUserId: newDetail.assignedUserId,
		assignedTo: newDetail.assignedTo,
		createdByUserId: 4,
		createdBy: 'George Richards',
		updatedByUserId: 4,
		message: newDetail.title,
		tags: newDetail.tags.join(','),
		createdOn: getAppropriateTimeToUpdate(),
		updatedOn: getAppropriateTimeToUpdate(),
		type: newDetail.type,
		actionItems: [],
		attachments: newDetail.attachments || []
	});

	return notificationDetails;
};

export const notificationReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_NOTIFICATIONS:
			return state.setIn(['notifications', 'isLoading'], true);

		case actionTypes.GET_NOTIFICATIONS_COMPLETED:
			return state
				.setIn(['notifications', 'isLoading'], false)
				.setIn(['notifications', 'hasBeenLoaded'], true)
				.setIn(['notifications', 'data'], action.payload);

		case actionTypes.GET_NOTIFICATIONS_FAILED:
			return state
				.setIn(['notifications', 'isLoading'], false)
				.setIn(['notifications', 'error'], action.payload);

		case actionTypes.GET_NOTIFICATION_DETAILS_BY_ID:
			return state.setIn(['notificationDetails', 'isLoading'], true);

		case actionTypes.GET_NOTIFICATION_DETAILS_BY_ID_COMPLETED:
			const currentDetails = state.get('notificationDetails').get('data');

			let notificationDetail = {};

			if (!action.payload.length) {
				notificationDetail = currentDetails;
			} else {
				const requestedParentId = action.payload[0].parentNotificationId;
				notificationDetail[requestedParentId] = action.payload;

				if (currentDetails && Object.keys(currentDetails).length > 0) {
					if (
						currentDetails[requestedParentId] &&
						Object.keys(currentDetails[requestedParentId]).length
					) {
						notificationDetail = Object.assign({}, currentDetails);

						notificationDetail[requestedParentId] =
							currentDetails[requestedParentId];
					} else {
						notificationDetail = Object.assign({}, currentDetails);
						notificationDetail[requestedParentId] = action.payload;
					}
				}
			}

			return state
				.setIn(['notificationDetails', 'isLoading'], false)
				.setIn(['notificationDetails', 'hasBeenLoaded'], true)
				.setIn(['notificationDetails', 'data'], notificationDetail);

		case actionTypes.GET_NOTIFICATION_DETAILS_BY_ID_FAILED:
			return state
				.setIn(['notificationDetails', 'isLoading'], false)
				.setIn(['notificationDetails', 'error'], action.payload);

		case actionTypes.ADD_NOTIFICATION:
			return state.setIn(['notifications', 'isLoading'], true);

		case actionTypes.ADD_NOTIFICATION_COMPLETED:
			return state
				.setIn(['notifications', 'isLoading'], false)
				.setIn(['notifications', 'hasBeenLoaded'], true);

		case actionTypes.ADD_NOTIFICATION_FAILED:
			return state
				.setIn(['notifications', 'isLoading'], false)
				.setIn(['notifications', 'error'], action.payload);

		case actionTypes.ADD_NOTIFICATION_DETAIL:
			return state.setIn(['notificationDetails', 'isLoading'], true);

		case actionTypes.ADD_NOTIFICATION_DETAIL_COMPLETED:
			const newNotificationDetails = setNewNotificationDetails(
				state.get('notificationDetails').get('data'),
				action.payload
			);

			return state
				.setIn(['notificationDetails', 'isLoading'], false)
				.setIn(['notificationDetails', 'hasBeenLoaded'], true)
				.setIn(['notificationDetails', 'data'], newNotificationDetails);

		case actionTypes.ADD_NOTIFICATION_DETAIL_FAILED:
			return state.setIn(['notificationDetails', 'isLoading'], false);

		case actionTypes.UPDATE_NOTIFICATION:
			return state.setIn(['notifications', 'isLoading'], true);

		case actionTypes.UPDATE_NOTIFICATION_COMPLETED:
			return state
				.setIn(['notifications', 'isLoading'], false)
				.setIn(['notifications', 'hasBeenLoaded'], true)
				.setIn(['notifications', 'data'], action.payload);

		case actionTypes.UPDATE_NOTIFICATION_FAILED:
			return state
				.setIn(['notificationDetails', 'isLoading'], false)
				.setIn(['notificationDetails', 'error'], action.payload);

		case actionTypes.UPDATE_NOTIFICATION_DETAIL:
			return state.setIn(['notificationDetails', 'isLoading'], true);

		case actionTypes.UPDATE_NOTIFICATION_DETAIL_COMPLETED:
			return state
				.setIn(['notificationDetails', 'isLoading'], false)
				.setIn(['notificationDetails', 'hasBeenLoaded'], true)
				.setIn(['notificationDetails', 'data'], action.payload);

		case actionTypes.UPDATE_NOTIFICATION_DETAIL_FAILED:
			return state
				.setIn(['notificationDetails', 'isLoading'], false)
				.setIn(['notificationDetails', 'error'], action.payload);

		case actionTypes.DELETE_NOTIFICATION:
			return state.setIn(['notifications', 'isLoading'], true);

		case actionTypes.DELETE_NOTIFICATION_COMPLETED:
			return state
				.setIn(['notifications', 'isLoading'], false)
				.setIn(['notifications', 'hasBeenLoaded'], true)
				.setIn(['notifications', 'data'], action.payload);

		case actionTypes.DELETE_NOTIFICATION_FAILED:
			return state
				.setIn(['notifications', 'isLoading'], false)
				.setIn(['notifications', 'error'], action.payload);

		case actionTypes.DELETE_NOTIFICATION_DETAIL:
			return state.setIn(['notificationDetails', 'isLoading'], true);

		case actionTypes.DELETE_NOTIFICATION_DETAIL_COMPLETED:
			return state
				.setIn(['notificationDetails', 'isLoading'], false)
				.setIn(['notificationDetails', 'hasBeenLoaded'], true)
				.setIn(['notificationDetails', 'data'], action.payload);

		case actionTypes.DELETE_NOTIFICATION_DETAIL_FAILED:
			return state
				.setIn(['notificationDetails', 'isLoading'], false)
				.setIn(['notificationDetails', 'error'], action.payload);

		default:
			return state;
	}
};
