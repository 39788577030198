import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import RepresentmentTextField from './components/RepresentmentTextField';
import moment from 'moment';

const styles = (theme) => ({
	container: {
		display: 'flex',
		flexGrow: 1,
		flexFlow: 'column',
		padding: '10px',
		margin: '10px',
		borderRadius: '10px',
		border: `1px solid ${theme.palette.representment.sectionContainer.border}`,
		backgroundColor: theme.palette.representment.sectionContainer.background
	},
	sectionTitle: {
		backgroundColor:
			theme.palette.representment.sectionContainer.sectionTitleBackground,
		borderTopLeftRadius: '5px',
		borderTopRightRadius: '5px',
		width: 'fit-content',
		fontSize: '1rem',
		color: theme.palette.representment.sectionContainer.sectionText,
		margin: '-22px 0 0 2px',
		padding: '0 5px 0 5px'
	},
	row: {
		display: 'flex',
		flexDirection: 'row'
	}
});

export const TransactionDetails = (props) => {
	const { classes, t, data } = props;
	const { container, row, inputField, sectionTitle } = classes;

	return (
		<div className={container}>
			<div className={sectionTitle}>
				{t('components.Representment.TransactionDetails.label')}
			</div>
			<div className={row}>
				<RepresentmentTextField
					id="maskedCardNumber"
					label={t(
						'components.Representment.TransactionDetails.maskedCardNumber'
					)}
					className={inputField}
					defaultValue={data.maskedCardNumber}
				/>
				<RepresentmentTextField
					id="settledOn"
					label={t('components.Representment.TransactionDetails.settledOn')}
					className={inputField}
					defaultValue={moment(data.settledOn).format('YYYY-MM-DD')}
				/>
				<RepresentmentTextField
					id="settledAmount"
					label={t('components.Representment.TransactionDetails.settledAmount')}
					className={inputField}
					defaultValue={`${data.settledAmount}`}
				/>
			</div>
			<div className={row}>
				<RepresentmentTextField
					id="addressVerificationServiceResponse"
					label={t(
						'components.Representment.TransactionDetails.addressVerificationServiceResponse'
					)}
					defaultValue={data.addressVerificationServiceResponse}
					disabled={!data.addressVerificationServiceResponse}
				/>
				<RepresentmentTextField
					id="authorizationCode"
					label={t(
						'components.Representment.TransactionDetails.authorizationCode'
					)}
					defaultValue={data.authorizationCode}
				/>
				<RepresentmentTextField
					id="authorizedOn"
					label={t('components.Representment.TransactionDetails.authorizedOn')}
					defaultValue={moment(data.authorizedOn).format('YYYY-MM-DD')}
				/>
			</div>
			<div className={row}>
				<RepresentmentTextField
					id="acquirerReferenceNumber"
					label={t(
						'components.Representment.TransactionDetails.acquirerReferenceNumber'
					)}
					defaultValue={data.acquirerReferenceNumber}
				/>
				<RepresentmentTextField
					id="paymentCardAssociationTransactionId"
					label={t(
						'components.Representment.TransactionDetails.paymentCardAssociationTransactionId'
					)}
					defaultValue={data.paymentCardAssociationTransactionId}
					disabled={!data.paymentCardAssociationTransactionId}
				/>
				<RepresentmentTextField
					id="paymentCardVerificationValueResponse"
					label={t(
						'components.Representment.TransactionDetails.paymentCardVerificationValueResponse'
					)}
					defaultValue={data.paymentCardVerificationValueResponse}
					disabled={!data.paymentCardVerificationValueResponse}
				/>
			</div>
		</div>
	);
};

TransactionDetails.propTypes = {
	data: PropTypes.object,
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(TransactionDetails));
