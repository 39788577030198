import { combineEpics } from 'redux-observable';
import { combineReducers } from 'redux';
import { catchError } from 'rxjs/operators';
import { alertsEpics, alertsReducer } from '../containers/Alerts';
import { authEpics, authReducer } from '../containers/Auth';
import {
	cloneLayoutEpics,
	cloneLayoutReducer
} from '../containers/CloneLayout';
import {
	currentInventoryEpics,
	currentInventoryReducer
} from '../containers/CurrentInventory';
import { dashboardEpics, dashboardReducer } from '../containers/Dashboard';
import {
	groupManagementEpics,
	groupManagementReducer
} from '../containers/GroupManagement';
import { layoutsEpics, layoutsReducer } from '../containers/Layouts';
import { modalReducer } from '../containers/Modal';
import { toastNotificationReducer } from '../containers/ToastNotifications';
import { reporterEpics, reporterReducer } from '../containers/Reporter';
import {
	reportViewsEpics,
	reportViewsReducer
} from '../containers/ReportViews';
import { systemReducer } from '../containers/System';
import { tilesEpics, tilesReducer } from '../containers/Tiles';
import {
	notificationEpics,
	notificationReducer
} from '../containers/Notifications';
import { userEpics, userReducer } from '../containers/User';
import { usersEpic, usersReducer } from '../containers/Users';
import {
	organizationEpics,
	organizationReducer
} from '../containers/Organization';
import { recordingsEpics, recordingsReducer } from '../containers/Recordings';
import {
	recoveryHistoryEpics,
	recoveryHistoryReducer
} from '../containers/RecoveryHistory';
import {
	representmentEpics,
	representmentReducer
} from '../containers/Representment';
import {
	reversalReasonBuilderEpics,
	reversalReasonBuilderReducer
} from '../containers/ReversalReasonBuilder';
import { servicePdfEpics, servicePdfReducer } from '../containers/ServicePdf';
import {
	multiFactorAuthEpics,
	multiFactorAuthReducer
} from '../containers/MultiFactorAuth';

const epics = combineEpics(
	...alertsEpics,
	...authEpics,
	...cloneLayoutEpics,
	...currentInventoryEpics,
	...dashboardEpics,
	...groupManagementEpics,
	...layoutsEpics,
	...multiFactorAuthEpics,
	...notificationEpics,
	...organizationEpics,
	...recordingsEpics,
	...recoveryHistoryEpics,
	...reportViewsEpics,
	...reporterEpics,
	...reversalReasonBuilderEpics,
	...representmentEpics,
	...servicePdfEpics,
	...tilesEpics,
	...userEpics,
	...usersEpic
);

const rootReducer = combineReducers({
	alertsReducer,
	authReducer,
	cloneLayoutReducer,
	currentInventoryReducer,
	dashboardReducer,
	groupManagementReducer,
	layoutsReducer,
	modalReducer,
	multiFactorAuthReducer,
	notificationReducer,
	organizationReducer,
	recordingsReducer,
	recoveryHistoryReducer,
	reportViewsReducer,
	reporterReducer,
	reversalReasonBuilderReducer,
	representmentReducer,
	servicePdfReducer,
	systemReducer,
	tilesReducer,
	toastNotificationReducer,
	userReducer,
	usersReducer
});

const rootEpic = (action$, store$, dependencies) =>
	epics(action$, store$, dependencies).pipe(
		catchError((error, source) => {
			console.error('Root Epic Error Catcher', error);
			return source;
		})
	);

export { rootEpic, rootReducer };
