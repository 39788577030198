import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import IconButton from '@mui/material/IconButton';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import SettingsIcon from '@mui/icons-material/Settings';
import Tooltip from '@mui/material/Tooltip';

const styles = (theme) => ({
	settings: {
		display: 'flex',
		alignItems: 'center',
		'& div': {
			padding: 0
		}
	}
});

const SettingsList = (props) => {
	const { classes, showSettings, toggleSettings } = props;
	const { settings } = classes;

	const onClickSettings = () => {
		toggleSettings((prevState) => !prevState);
	};

	return (
		<div className={settings}>
			<Tooltip title={showSettings ? 'Back' : 'Settings'}>
				<span>
					<IconButton onClick={() => onClickSettings()}>
						{!showSettings ? (
							<SettingsIcon fontSize="small" />
						) : (
							<KeyboardBackspaceIcon fontSize="small" />
						)}
					</IconButton>
				</span>
			</Tooltip>
		</div>
	);
};

SettingsList.propTypes = {
	classes: PropTypes.object,
	showSettings: PropTypes.bool,
	toggleSettings: PropTypes.func
};

export default withStyles(styles)(SettingsList);
