import React, { Fragment, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { generate } from 'short-uuid';
import * as htmlToImage from 'html-to-image';
import CanvasDraw from 'react-canvas-draw';
// import { ColorPicker } from 'material-ui-color';
import { ChromePicker } from 'react-color';
import Commenter from './partials_old/Commenter';
import EmailScreenshotModal from './partials_old/EmailScreenshotModal';
import SendTextScreenshotModal from './partials_old/SendTextScreenshotModal';
import AttachToNotificationModal from './partials_old/AttachToNotificationModal';
import { handleDownload } from './partials_old/handleDownload';
import { handleBase64Encode } from './partials_old/handleBase64Encode';

import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';

import AddIcon from '@mui/icons-material/Add';
import AddLinkIcon from '@mui/icons-material/AddLink';
import CloseIcon from '@mui/icons-material/Close';
import CheckboxEmptyIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckboxCheckedIcon from '@mui/icons-material/CheckBox';
import DownloadIcon from '@mui/icons-material/GetApp';
import EmailIcon from '@mui/icons-material/Email';
import BrushIcon from '@mui/icons-material/Brush';
import PaletteIcon from '@mui/icons-material/Palette';
import RemoveIcon from '@mui/icons-material/Remove';
import UndoIcon from '@mui/icons-material/Undo';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import TextsmsIcon from '@mui/icons-material/Textsms';

import Loader from '../Loader';

const styles = (theme) => ({
	actionButton: {
		margin: theme.spacing(1),
		textTransform: 'none'
	},
	brushRoot: {
		width: 212,
		height: '100px',
		display: 'flex',
		flexFlow: 'column'
	},
	brushSliderRoot: {
		width: 150
	},
	brushSliderContainer: {
		width: '180px'
	},
	brushSlider: {
		width: '100px'
	},
	brushColorSelector: {
		display: 'flex'
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500]
	},
	controllerElement: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'baseline',
		'& div': {
			padding: '10px'
		}
	},
	controllerElementTwo: {
		// display: 'flex',
		flexDirection: 'row',
		alignItems: 'baseline',
		flexWrap: 'wrap',
		width: '684px',
		marginBottom: 'auto',
		'& div': {
			padding: '10px'
		}
	},
	controllerHeader: {
		backgroundColor: 'white',
		display: 'flex',
		margin: '2px 0px 10px 0px',
		justifyContent: 'space-between',
		borderBottom: '1px solid lightgray'
	},
	dialogHeader: {
		display: 'flex',
		flexFlow: 'column',
		justifyContent: 'space-between'
	},
	dialogRootClass: {
		overflowX: 'hidden'
	},
	modalRoot: {
		// backgroundColor: 'darkgray', // TODO: This should be a theme variable
		padding: '0px',
		overflow: 'initial'
	},
	screenshotCanvas: {
		position: 'absolute',
		zIndex: 1
	},
	screenshotContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	screenshotImage: {
		position: 'absolute'
	},
	color: {
		width: '36px',
		height: '14px',
		borderRadius: '2px'
		// background: `#59BFB6`
	},
	swatch: {
		padding: '5px',
		background: '#fff',
		borderRadius: '1px',
		boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
		display: 'inline-block',
		cursor: 'pointer'
	},
	popover: {
		position: 'absolute',
		zIndex: '2'
	},
	cover: {
		position: 'fixed',
		top: '0px',
		right: '0px',
		bottom: '0px',
		left: '0px'
	}
});

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const AnnotatorModal = (props) => {
	const { classes, elementRef, onClose, label, isOpen } = props;
	const {
		actionButton,
		brushColorSelector,
		brushRoot,
		brushSlider,
		brushSliderContainer,
		brushSliderRoot,
		controllerElementTwo,
		closeButton,
		color,
		cover,
		controllerElement,
		controllerHeader,
		dialogHeader,
		modalRoot,
		dialogRootClass,
		popover,
		screenshotCanvas,
		screenshotContainer,
		screenshotImage,
		swatch
	} = classes;

	const [isInCloseState, setIsInCloseState] = useState(false);
	const [screenshotIsLoaded, setScreenshotIsLoaded] = useState(false);
	const [drawingIsDisabled, setDrawingIsDisabled] = useState(true);
	const [displayColorPicker, setDisplayColorPicker] = useState(false);
	const [emailModalIsOpen, setEmailModalIsOpen] = useState(false);
	const [sendTextModalIsOpen, setSendTextModalIsOpen] = useState(false);
	const [
		attachToNotificationModalIsOpen,
		setAttachToNotificationModalIsOpen
	] = useState(false);
	const [canvasDrawWidth, setCanvasDrawWidth] = useState(400);
	const [canvasDrawHeight, setCanvasDrawHeight] = useState(400);
	const [canvasDrawColor, setCanvasDrawColor] = useState('#51284f');
	const [colorPickerData, setColorPickerData] = useState('#51284f');
	const [comments, setComments] = useState([]);
	const [hideDrawTool, setHideDrawTool] = useState(true);
	const [brushSize, setBrushSize] = useState(2);
	const [notificationId, setNotificationId] = useState();
	const canvasDrawRef = React.createRef();
	const canvasElementId = generate();

	const setScreenshot = useCallback(() => {
		// if (elementRef) {
		// TODO: This clone is working properly, its just not rendering the preview when htmlToImage runs.
		// 	   : I think it's because it's not rendered to the DOM, so it can't actually take a screenshot
		// 	   : Try appending a temporary div to the body, storing the ref, and on completion of the render, kill it.
		// const screenshotElement = elementRef.current.cloneNode(true);
		// const screenshotElement = elementRef.current;
		// TODO: Remove dropdowns
		// TODO: Add element padding
		// screenshotElement.style.backgroundColor = 'aqua';
		// screenshotElement.style.margin = '200px';
		// } else {
		// console.log('NO ELEMENT REF');
		// screenshotElement = document.getElementById(generatedElementId) || null;
		// }

		// hideScreenshotterElements(true);

		// const
		// const screenshotElement = React.cloneElement(elementRef.current);

		// console.log('ORIGINAL', elementRef.current);
		// console.log('CLONED', screenshotElement);

		// const screenshotContainer = document.createElement('div');
		// screenshotContainer.appendChild(screenshotElement);
		// const cloned = screenshotContainer.cloneNode(true);

		htmlToImage
			.toCanvas(elementRef.current, {
				quality: 0.5
			})
			.then((preview) => {
				const imageElement = document.getElementById(
					'annotator-modal-image-preview'
				);

				if (imageElement) {
					preview.style.backgroundColor = 'white';
					preview.style.marginTop = '10px';

					setScreenshotIsLoaded(true);
					imageElement.appendChild(preview);
					getScreenshotSize();
					hideScreenshotterElements(false);
				}
			});
	}, [elementRef]);

	const hideScreenshotterElements = (hide) => {
		const elements = document.getElementsByClassName('screenshotter-hidden');
		for (let i = 0; i < elements.length; i++) {
			elements.item(i).style.display = hide ? 'none' : 'inherit';
		}
	};

	const handleColorPicker = () => {
		setDisplayColorPicker(!displayColorPicker);
	};

	const getScreenshotSize = () => {
		const canvas = document.getElementById('annotator-modal-image-preview')
			.firstChild;

		if (!canvas) {
			setCanvasDrawWidth('0px');
			setCanvasDrawHeight('0px');
		} else {
			const width = canvas.width;
			const height = canvas.height;
			const bufferSize = 0.2;

			const setWidth = width + width * bufferSize;
			const setHeight = height + height * bufferSize;

			setCanvasDrawWidth(setWidth > 40 ? width + 40 : setWidth);
			setCanvasDrawHeight(setHeight > 40 ? height + 40 : setHeight);
		}
	};

	const handleClose = () => {
		setIsInCloseState(true);
		setHideDrawTool(true);
		setScreenshotIsLoaded(false);
		setComments([]);
		onClose();
	};

	const handleBrushSliderUpdate = (event, newvalue) => {
		setBrushSize(newvalue);
	};

	const handleCanvasBufferSize = (direction, maths) => {
		const amount = 30;

		if (direction === 'height' && maths === 'minus') {
			setCanvasDrawHeight(canvasDrawHeight - amount);
		} else if (direction === 'height' && maths === 'add') {
			setCanvasDrawHeight(canvasDrawHeight + amount);
		} else if (direction === 'width' && maths === 'minus') {
			setCanvasDrawWidth(canvasDrawWidth - amount);
		} else {
			setCanvasDrawWidth(canvasDrawWidth + amount);
		}
	};

	const _addScreenshotBufferHeight = () => (
		<div className={brushRoot}>
			<Typography id="input-slider" gutterBottom>
				Padding Height
			</Typography>
			<Grid container spacing={2} alignItems="center">
				<Grid item xs>
					<ButtonGroup color="primary">
						<Button onClick={() => handleCanvasBufferSize('height', 'minus')}>
							<RemoveIcon />
						</Button>
						<Button onClick={() => handleCanvasBufferSize('height', 'add')}>
							<AddIcon />
						</Button>
					</ButtonGroup>
				</Grid>
			</Grid>
		</div>
	);

	const _addScreenshotBufferWidth = () => (
		<div className={brushRoot}>
			<Typography id="input-slider" gutterBottom>
				Padding Width
			</Typography>
			<Grid container spacing={2} alignItems="center">
				<Grid item xs>
					<ButtonGroup color="primary">
						<Button onClick={() => handleCanvasBufferSize('width', 'minus')}>
							<RemoveIcon />
						</Button>
						<Button onClick={() => handleCanvasBufferSize('width', 'add')}>
							<AddIcon />
						</Button>
					</ButtonGroup>
				</Grid>
			</Grid>
		</div>
	);

	const _drawBrushSizeSlider = () => (
		<div className={brushSliderRoot}>
			<Typography id="input-slider" gutterBottom>
				Brush Size
			</Typography>
			<Grid
				container
				spacing={2}
				alignItems="center"
				className={brushSliderContainer}
			>
				{/* <Grid item>
					<BrushIcon />
				</Grid> */}
				<Grid item xs className={brushSlider}>
					<Slider
						min={1}
						step={0.1}
						max={20}
						value={typeof brushSize === 'number' ? brushSize : 0}
						onChange={handleBrushSliderUpdate}
						aria-labelledby="input-slider"
					/>
				</Grid>
				{/* <Grid item>
					<p>{brushSize}</p>
				</Grid> */}
			</Grid>
		</div>
	);

	const _drawColorPicker = () => (
		<div className={brushRoot} style={{ paddingLeft: '20px' }}>
			<Typography id="input-slider" gutterBottom>
				Brush/Comment Color
			</Typography>
			<Grid
				container
				spacing={2}
				alignItems="center"
				className={brushColorSelector}
			>
				{/* <Grid item>
					<PaletteIcon />
				</Grid> */}
				<Grid item xs>
					{/* <ColorPicker
						value={colorPickerData}
						disableTextfield={true}
						hideTextfield={true}
						disableAlpha={true}
						onChange={(color) => {
							setColorPickerData(color);
							setCanvasDrawColor(`#${color.hex}`);
						}}
					/> */}
					<div className={swatch} onClick={handleColorPicker}>
						<div
							className={color}
							style={{ backgroundColor: canvasDrawColor }}
						/>
					</div>
					{displayColorPicker && (
						<div className={popover}>
							<div className={cover} onClick={handleColorPicker} />
							<ChromePicker
								color={colorPickerData}
								disableAlpha={true}
								onChange={(color) => {
									setColorPickerData(color);
									// setCanvasDrawColor(`#${color.hex}`);
									setCanvasDrawColor(color.hex);
								}}
							/>
						</div>
					)}

					{/* {displayColorPicker && (
						<ChromePicker
							color={colorPickerData}
							disableAlpha={true}
							onChange={(color) => {
								console.log('COLOR CHANGED', color);
								setColorPickerData(color);
								// setCanvasDrawColor(`#${color.hex}`);
								setCanvasDrawColor(color.hex);
							}}
						/>
					)} */}
				</Grid>
			</Grid>
		</div>
	);

	const addCommentToScreenshot = () => {
		const commentsClone = [...comments];

		const usedIndexes = commentsClone.map((comment) => comment.idx);
		const commentIndex = isFinite(Math.max(...usedIndexes))
			? Math.max(...usedIndexes) + 1
			: 1;

		commentsClone.push({
			idx: commentIndex,
			value: '...'
		});

		setComments(commentsClone);
	};

	const handleRemoveComment = (index) => {
		const commentsClone = [...comments];
		const removeComment = commentsClone.filter(
			(comment) => comment.idx !== index
		);

		setComments(removeComment);
	};

	const _renderComments = () => {
		return comments.map((comment) => (
			<Commenter
				key={comment.idx}
				color={canvasDrawColor}
				comment={comment}
				onRemove={handleRemoveComment}
			/>
		));
	};

	const getScreenshotElement = () => {
		const element = document.getElementById(canvasElementId);
		// TODO: Use element.cloneNode(true);

		// element.removeChild(element.firstChild); // TODO: We should remove it by id, not by it's first child. This would also make more sence to get just the canvas element ID, not the container with the controls.
		// element.style.backgroundColor = 'white';

		return element;
	};

	const handleScreenshotDownload = async () => {
		const element = getScreenshotElement();

		await handleDownload(label, element);
		handleClose(); // TODO: This needs a little timing work. We don't want it to remove elements while we're looking at them.
	};

	const _handleGetScreenshot = async () => {
		const screenshotEncoded = await handleBase64Encode(getScreenshotElement());
		return screenshotEncoded;
	};

	const handleEmailModal = () => {
		setEmailModalIsOpen(false);
	};

	const handleTextModal = () => {
		setSendTextModalIsOpen(false);
	};

	const handleNotificationModal = () => {
		setAttachToNotificationModalIsOpen(false);
	};

	const _renderController = () => (
		<Container
			maxWidth={false}
			className={controllerHeader}
			id="annotator-modal-controller"
		>
			<div className={controllerElement}>
				{/* {_addScreenshotBufferWidth()} */}
				{/* {_addScreenshotBufferHeight()} */}
				{_drawBrushSizeSlider()}
				{_drawColorPicker()}
			</div>
			<div className={controllerElementTwo}>
				<Button
					variant="contained"
					className={actionButton}
					startIcon={<TextsmsIcon />}
					onClick={() => setSendTextModalIsOpen(true)}
				>
					Text A Screenshot
				</Button>
				<Button
					variant="contained"
					className={actionButton}
					startIcon={<EmailIcon />}
					onClick={() => {
						setEmailModalIsOpen(true);
					}}
				>
					Email
				</Button>
				<Button
					variant="contained"
					className={actionButton}
					startIcon={<DownloadIcon />}
					onClick={handleScreenshotDownload}
				>
					Download
				</Button>
				{notificationId && (
					<Button
						variant="contained"
						className={actionButton}
						startIcon={<AddLinkIcon />}
						onClick={() => setAttachToNotificationModalIsOpen(true)}
					>
						Attach To Notification
					</Button>
				)}
				<Button
					variant="contained"
					className={actionButton}
					startIcon={
						drawingIsDisabled ? <CheckboxEmptyIcon /> : <CheckboxCheckedIcon />
					}
					onClick={() => {
						setDrawingIsDisabled(!drawingIsDisabled);
						setHideDrawTool(!drawingIsDisabled);

						setCanvasDrawHeight(
							drawingIsDisabled
								? canvasDrawHeight + 0.15
								: canvasDrawHeight - 0.15
						);
					}}
				>
					Draw
				</Button>
				<Button
					variant="contained"
					className={actionButton}
					startIcon={<TextFieldsIcon />}
					onClick={addCommentToScreenshot}
				>
					Add Comment
				</Button>
				<Button
					startIcon={<UndoIcon />}
					variant="contained"
					className={actionButton}
					onClick={() => canvasDrawRef.current.undo()}
				>
					Undo
				</Button>
				<Button
					startIcon={<ClearAllIcon />}
					variant="contained"
					className={actionButton}
					onClick={() => canvasDrawRef.current.clear()}
				>
					Clear
				</Button>
			</div>
		</Container>
	);

	useEffect(() => {
		if (!screenshotIsLoaded && isOpen && !isInCloseState) {
			setScreenshot();
		}

		const queryString = window.location.search;
		if (queryString) {
			const urlParams = new URLSearchParams(queryString);
			const notificationId = urlParams.get('notificationId');
			setNotificationId(notificationId);
		}

		if (!isOpen) {
			handleClose();
			setIsInCloseState(false);
		}
	}, [isOpen, screenshotIsLoaded, setScreenshot]);

	const _renderLoader = () => {
		return (
			!screenshotIsLoaded && (
				<Fragment>
					<Loader type="linear" label="Loading Preview" />
				</Fragment>
			)
		);
	};

	return (
		<Dialog
			fullScreen
			onClose={handleClose}
			open={isOpen}
			aria-labelledby="annotator-modal"
			TransitionComponent={Transition}
			PaperProps={{ classes: { root: dialogRootClass } }}
		>
			<Container maxWidth={false} className={dialogHeader}>
				<Fragment>
					<DialogTitle id="annotator-modal-title">
						Screenshot Annotator v2.0
					</DialogTitle>
					<IconButton
						aria-label="close"
						className={closeButton}
						onClick={handleClose}
					>
						<CloseIcon />
					</IconButton>
				</Fragment>
				<Fragment>{_renderController()}</Fragment>
			</Container>
			<DialogContent className={modalRoot} id={canvasElementId}>
				{_renderLoader()}
				<Container
					className={screenshotContainer}
					maxWidth={false}
					style={{
						height: canvasDrawHeight,
						width: canvasDrawWidth
					}}
				>
					{_renderComments()}
					<CanvasDraw
						ref={canvasDrawRef}
						disabled={drawingIsDisabled}
						hideGrid={true}
						brushRadius={brushSize}
						brushColor={canvasDrawColor}
						lazyRadius={0}
						className={screenshotCanvas}
						canvasHeight={canvasDrawHeight}
						canvasWidth={canvasDrawWidth}
						hideInterface={hideDrawTool}
						backgroundColor={null}
					/>
					<div id="annotator-modal-image-preview" className={screenshotImage} />
				</Container>
			</DialogContent>
			<EmailScreenshotModal
				isOpen={emailModalIsOpen}
				onClose={handleEmailModal}
				getScreenshot={_handleGetScreenshot}
				screenshotLabel={label}
				title={'Email a Screenshot'}
			/>
			<SendTextScreenshotModal
				isOpen={sendTextModalIsOpen}
				onClose={handleTextModal}
				getScreenshot={_handleGetScreenshot}
				screenshotLabel={label}
				title={'Text A Screenshot'}
			/>
			<AttachToNotificationModal
				isOpen={attachToNotificationModalIsOpen}
				onClose={handleNotificationModal}
				getScreenshot={_handleGetScreenshot}
				notificationId={notificationId}
				screenshotLabel={label}
				title={'Attach To A Notification'}
			/>
		</Dialog>
	);
};

AnnotatorModal.propTypes = {
	classes: PropTypes.object,
	// generatedElementId: PropTypes.string,
	elementRef: PropTypes.object,
	isOpen: PropTypes.bool,
	label: PropTypes.string,
	onClose: PropTypes.func,
	t: PropTypes.func
};

export default withStyles(styles)(AnnotatorModal);
