export default function (state, action, key) {
	const userId = `${action.payload.userId}`;

	const existingLayout = state.get(key).get(userId);

	if (existingLayout === undefined) {
		return state.setIn(`${key}.${userId}`.split('.'), {
			isLoading: true,
			hasBeenLoaded: false,
			error: '',
			layouts: {}
		});
	}

	if (action.payload.data) {
		return state
			.setIn(`${key}.${userId}.isLoading`.split('.'), false)
			.setIn(`${key}.${userId}.hasBeenLoaded`.split('.'), true)
			.setIn(`${key}.${userId}.error`.split('.'), '')
			.setIn(`${key}.${userId}.layouts`.split('.'), action.payload.data);
	}

	if (action.payload.data === undefined) {
		return state.setIn(`${key}.${userId}.isLoading`.split('.'), true);
	}

	return state;
}
