const _cardAbbreviations = {
	Amex: 'A',
	Discover: 'D',
	MasterCard: 'M',
	Visa: 'V'
};

const _bankNames = {
	'Capital One': 'CAPITAL ONE',
	'JPMorgan Chase': 'JPMORGAN CHASE BANK',
	'Citi Bank': 'CITIBANK',
	'Barclays Bank': 'BARCLAYS BANK',
	'PNC Bank': 'PNC BANK',
	'Republic Bank Limited': 'REPUBLIC BANK LIMITED'
};

const _convertCardTypes = (selectedCards) => {
	let abbreviatedCards = [];

	if (selectedCards) {
		abbreviatedCards = selectedCards.map((card) => {
			return _cardAbbreviations[card];
		});
	}

	return abbreviatedCards.join(',');
};

const _convertBanknames = (selectedBankNames) => {
	let formattedBankNames = [];

	if (selectedBankNames) {
		formattedBankNames = selectedBankNames.map((bankName) => {
			return _bankNames[bankName];
		});
	}

	return formattedBankNames.join(',');
};

export default function (
	cardTypes,
	bankNames,
	merchantCode,
	reconcileStart,
	reconcileEnd
) {
	const formattedCardTypes = _convertCardTypes(cardTypes);
	const formattedBankNames = _convertBanknames(bankNames);

	return {
		CARD_TYPES: formattedCardTypes,
		BANK_NAMES: formattedBankNames,
		MERCHANT_CODE: merchantCode,
		RECONCILE_START_DATE: reconcileStart,
		RECONCILE_END_DATE: reconcileEnd
	};
}
