import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import SummaryTableByCardType from './SummaryTableByCardType';

const styles = (theme) => ({
	container: {
		display: 'flex',
		flex: 1,
		flexDirection: 'column'
	}
});

const SummaryReport = (props) => {
	const { classes, data } = props;
	const { container } = classes;

	return (
		<div className={container}>
			{data.map((cardData) => {
				return (
					<SummaryTableByCardType
						key={cardData.key}
						data={cardData.data}
						cardType={cardData.key}
					/>
				);
			})}
		</div>
	);
};

SummaryReport.propTypes = {
	classes: PropTypes.object,
	data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	getListOfMidsByProcessor: PropTypes.func,
	getMidNameList: PropTypes.func,
	hasBeenLoaded: PropTypes.bool,
	isLoading: PropTypes.bool,
	processor: PropTypes.string
};

export default withStyles(styles)(SummaryReport);
