import React, { Fragment, useState } from 'react';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { generate } from 'short-uuid';
import clsx from 'clsx';
import { handleZero } from '../../../../helpers/dailyChargebackReport/handleZero';
import { sanitizeKeys } from '../../../../helpers/sanitizeKeys';
import { capitalCase } from 'change-case';
import RemediationTable from './RemediationTable';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const styles = (theme) => ({
	currentMonth: {
		borderLeft: '2px solid black',
		borderRight: '2px solid black'
	},
	root: {
		display: 'flex',
		maxWidth: '84vw',
		margin: '20px 0px 20px 0px'
	},
	row: {
		backgroundColor: theme.palette.navigation.background
	},
	rowStyle: {
		fontWeight: '600'
	}
});

const TwelveMonthLookTable = (props) => {
	const { cardType, classes, data, t } = props;
	const { currentMonth, root, row, rowStyle } = classes;

	const cardData = data;

	const [remediationOpen, setRemediationOpen] = useState(false);

	const sanitizeColumns = () => {
		const categories = cardData.map((entry) => entry.month);
		return categories;
	};

	const _renderEmptyRow = () => {
		return (
			<Fragment>
				<TableCell>&nbsp;</TableCell>
				<TableCell colSpan={5}></TableCell>
				<TableCell className={currentMonth}></TableCell>
				<TableCell colSpan={6}></TableCell>
			</Fragment>
		);
	};

	const determineValueType = (type, value) => {
		switch (type) {
			case 'chargebackPercent':
			case 'disputePercent':
			case 'deflectionPercent':
				return value === undefined ? null : `${value}%`;
			default:
				return value;
		}
	};

	const _renderRow = (value) => {
		return cardData.map((entry) => {
			const tableCells = [];
			tableCells.push(
				<TableCell
					align="center"
					key={generate()}
					className={clsx(
						entry.currentMonth && currentMonth,
						(value === 'cbUnitsOver' || value === 'transactionsOver') &&
							rowStyle
					)}
				>
					{determineValueType(value, entry[value]) ||
						handleZero(entry[value]) ||
						null}
				</TableCell>
			);
			return tableCells;
		});
	};

	return (
		<TableContainer component={Paper} className={root}>
			<Table size="small">
				<TableHead>
					<TableRow>
						<TableCell className={rowStyle}>
							{capitalCase(sanitizeKeys(cardType))}
						</TableCell>
						{sanitizeColumns().map((column, idx) => {
							return (
								<TableCell align="center" className={rowStyle} key={idx}>
									{column}
								</TableCell>
							);
						})}
					</TableRow>
				</TableHead>
				{/* TODO: map over these so theres no duplicate code */}
				<TableBody>
					<TableRow className={row}>
						<TableCell>
							{t('pages.reports.chargebacks.daily-chargeback-report.received')}
						</TableCell>
						{_renderRow('chargebacks')}
					</TableRow>
					<TableRow>
						<TableCell>
							{t(
								'pages.reports.chargebacks.daily-chargeback-report.transactions'
							)}
						</TableCell>
						{_renderRow('transactions')}
					</TableRow>
					<TableRow className={row}>{_renderEmptyRow()}</TableRow>
					<TableRow>
						<TableCell>
							{t('pages.reports.chargebacks.daily-chargeback-report.percent')}
						</TableCell>
						{_renderRow('chargebackPercent')}
					</TableRow>
					<TableRow className={row}>
						<TableCell className={rowStyle}>
							{t('pages.reports.chargebacks.daily-chargeback-report.cbunits')}
						</TableCell>
						{_renderRow('cbUnitsOver')}
					</TableRow>
					<TableRow>
						<TableCell className={rowStyle}>
							{t('pages.reports.chargebacks.daily-chargeback-report.over')}
						</TableCell>
						{_renderRow('transactionsOver')}
					</TableRow>
					{(cardType === 'mastercard' || cardType === 'visa') && (
						<RemediationTable
							remediationOpen={remediationOpen}
							setRemediationOpen={setRemediationOpen}
							cardType={cardType}
							renderRow={_renderRow}
						/>
					)}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

TwelveMonthLookTable.propTypes = {
	cardType: PropTypes.string,
	classes: PropTypes.object,
	data: PropTypes.array,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(TwelveMonthLookTable));
