import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@mui/styles';
import DataViewer from '../../../DataViewer/DataViewer';

const styles = (theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		flex: '1'
	}
});

const _mockChartData = {
	lineKeys: ['', 'Visa', 'MasterCard'],
	results: [
		{
			MasterCard: 0.56,
			Visa: 0.4,
			date: '12-31-2022'
		},
		{
			MasterCard: 0.44,
			Visa: 0.36,
			date: '01-31-2022'
		},
		{
			MasterCard: 1.97,
			Visa: 0.23,
			date: '02-28-2022'
		},
		{
			MasterCard: 0.77,
			Visa: 0.27,
			date: '03-31-2022'
		},
		{
			MasterCard: 0.75,
			Visa: 0.21,
			date: '04-30-2022'
		},
		{
			MasterCard: 0.68,
			Visa: 0.33,
			date: '05-31-2022'
		}
	]
};

const LineChartMetric = (props) => {
	const { classes, height, width } = props;
	const { stack } = classes;

	return (
		<DataViewer
			reportData={_mockChartData}
			dataViewType={'line'}
			height={height * 0.9}
			width={width * 0.9}
		/>
	);
};

LineChartMetric.propTypes = {
	tileProperties: PropTypes.object,
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(LineChartMetric));
