import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

import Container from '@mui/material/Container';
import WizardNavigation from './WizardNavigation';
import CloneOrNew from './WizardContentComponents/CloneOrNew';
import AddTiles from './WizardContentComponents/NewLayout/AddTiles';
import CloneLayout from './WizardContentComponents/CloneLayout';
import NameAndOrderTiles from './WizardContentComponents/shared/NameAndOrderTiles';
import CreationCompletion from './WizardContentComponents/shared/CreationCompletion';

const styles = (theme) => ({
	contentContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignSelf: 'stretch',
		paddingLeft: '10px',
		flex: 1
	}
});

const _renderContent = (
	activeStep,
	setActiveStep,
	setActiveStepCompleted,
	createdLayoutConfiguration,
	updateCreatedLayoutConfiguration,
	closeModal,
	backToManage,
	isLoadingLayouts,
	layoutTiles
) => {
	switch (`${activeStep} ${createdLayoutConfiguration.layoutCreationType}`) {
		case '0 undefined':
		case '0 newLayout':
		case '0 cloneLayout':
			return (
				<CloneOrNew
					activeStep={activeStep}
					createdLayoutConfiguration={createdLayoutConfiguration}
					updateCreatedLayoutConfiguration={updateCreatedLayoutConfiguration}
					setActiveStep={setActiveStep}
				/>
			);
		case '1 newLayout':
			return (
				<AddTiles
					createdLayoutConfiguration={createdLayoutConfiguration}
					updateCreatedLayoutConfiguration={updateCreatedLayoutConfiguration}
					setActiveStepCompleted={setActiveStepCompleted}
				/>
			);
		case '1 cloneLayout':
			return (
				<CloneLayout
					createdLayoutConfiguration={createdLayoutConfiguration}
					updateCreatedLayoutConfiguration={updateCreatedLayoutConfiguration}
					setActiveStepCompleted={setActiveStepCompleted}
				/>
			);
		case '2 cloneLayout':
		case '2 newLayout':
			return (
				<DndProvider backend={HTML5Backend}>
					<NameAndOrderTiles
						createdLayoutConfiguration={createdLayoutConfiguration}
						updateCreatedLayoutConfiguration={updateCreatedLayoutConfiguration}
						setCurrentStepCompleted={setActiveStepCompleted}
					/>
				</DndProvider>
			);
		case '3 cloneLayout':
		case '3 newLayout':
			return (
				<CreationCompletion
					closeModal={closeModal}
					backToManage={backToManage}
					createdLayoutConfiguration={createdLayoutConfiguration}
					isLoadingLayouts={isLoadingLayouts}
					layoutTiles={layoutTiles}
				/>
			);
		default:
	}
};

export const WizardContent = (props) => {
	const {
		classes,
		activeStep,
		setActiveStep,
		activeStepCompleted,
		createdLayoutConfiguration,
		setActiveStepCompleted,
		updateCreatedLayoutConfiguration,
		closeModal,
		onSubmit,
		isLoadingLayouts,
		layoutTiles
	} = props;
	const { contentContainer } = classes;

	const onPreviousStep = () => {
		const { setActiveStep, activeStep } = props;

		setActiveStep(activeStep - 1);
		setActiveStepCompleted(true);
	};

	const onNextStep = () => {
		const { setActiveStep, activeStep } = props;

		setActiveStep(activeStep + 1);
		setActiveStepCompleted(false);
	};

	const onBackToManage = () => {
		const { closeModal, openManageModal } = props;

		openManageModal();
		closeModal();
	};

	return (
		<Container className={contentContainer}>
			<WizardNavigation
				isActiveStepCompleted={activeStepCompleted}
				activeStep={activeStep}
				onPreviousStep={onPreviousStep}
				onNextStep={onNextStep}
				onSubmit={onSubmit}
			/>
			{_renderContent(
				activeStep,
				setActiveStep,
				setActiveStepCompleted,
				createdLayoutConfiguration,
				updateCreatedLayoutConfiguration,
				closeModal,
				onBackToManage,
				isLoadingLayouts,
				layoutTiles
			)}
		</Container>
	);
};

WizardContent.propTypes = {
	activeStep: PropTypes.number,
	setActiveStep: PropTypes.func,
	activeStepCompleted: PropTypes.bool,
	setActiveStepCompleted: PropTypes.func,
	createdLayoutConfiguration: PropTypes.object,
	updateCreatedLayoutConfiguration: PropTypes.func,
	closeModal: PropTypes.func,
	openManageModal: PropTypes.func,
	onSubmit: PropTypes.func,
	isLoadingLayouts: PropTypes.bool,
	layoutTiles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(WizardContent);
