import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@mui/styles';
import { Switch, Case, Default } from 'react-if';
import clsx from 'clsx';

const styles = (theme) => ({
	wizardNavigationContainer: {
		flexDirection: 'row'
	},
	previousContainer: {
		display: 'flex',
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'flex-start'
	},
	inactiveNavigationText: {
		color: theme.palette.createLayoutModal.navigation.inactiveNavigationItem,
		fontWeight: '500'
	},
	navigationText: {
		color: theme.palette.createLayoutModal.navigation.navigationItem,
		fontWeight: '700',
		'&:hover': {
			color: theme.palette.createLayoutModal.navigation.navigationHighlight,
			cursor: 'pointer'
		}
	},
	nextContainer: {
		display: 'flex',
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'flex-end'
	},
	flex: {
		display: 'flex',
		marginTop: '10px'
	},
	hidden: {
		display: 'none'
	}
});

const _visibleSteps = [1, 2];

export const WizardNavigationButton = (props) => {
	const { classes, t, activeStep, onPreviousStep, onNextStep, isActiveStepCompleted, onSubmit } = props;
	const { wizardNavigationContainer, previousContainer, nextContainer, navigationText, inactiveNavigationText, flex, hidden } = classes;

	return (
		<div className={clsx(
			wizardNavigationContainer,
			_visibleSteps.includes(activeStep) ? flex : hidden
		)}>
			<div className={previousContainer}><span className={navigationText} onClick={onPreviousStep}>{t('components.modals.createLayout.navigation.back')}</span></div>
			<div className={nextContainer}>
				<Switch>
					<Case condition={activeStep === 2 && isActiveStepCompleted}>
						<span className={navigationText} onClick={ onSubmit}>{t('components.modals.createLayout.navigation.submit')}</span>
					</Case>
					<Case condition={activeStep === 2 && !isActiveStepCompleted}>
						<span className={inactiveNavigationText} >{t('components.modals.createLayout.navigation.submit')}</span>
					</Case>
					<Case condition={isActiveStepCompleted}>
						<span className={navigationText} onClick={ onNextStep }>{t('components.modals.createLayout.navigation.next')}</span>
					</Case>
					<Default>
						<span className={inactiveNavigationText}>{t('components.modals.createLayout.navigation.next')}</span>
					</Default>
				</Switch>
			</div>
		</div>
	);
};

WizardNavigationButton.propTypes = {
	activeStep: PropTypes.number,
	onPreviousStep: PropTypes.func,
	onNextStep: PropTypes.func,
	isActiveStepCompleted: PropTypes.bool,
	icon: PropTypes.object,
	onClick: PropTypes.func,
	classes: PropTypes.object,
	onSubmit: PropTypes.func,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(WizardNavigationButton));
