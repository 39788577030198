import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';

import TextField from '@mui/material/TextField';

const styles = (theme) => ({
	inputField: {
		margin: '5px 5px 3px 5px',
		fontSize: '14px',
		overflowWrap: 'break-word'
	},
	flex: {
		flex: 1
	},
	oneHundred: {
		width: '100%'
	}
});

export const RepresentmentTextField = (props) => {
	const { classes, id, label, defaultValue, isMultiline, isFlexed, isDisabled } = props;
	const { inputField, flex, oneHundred } = classes;

	return (
		<TextField
			id={id}
			label={label}
			className={clsx(inputField, isFlexed && flex, id === 'programDescription' && oneHundred)}
			defaultValue={defaultValue}
			variant="standard"
			size="small"
			multiline={isMultiline}
			disabled={isDisabled}
			InputProps={{
				readOnly: true,
				disableUnderline: true
			}}
		/>
	);
};

RepresentmentTextField.propTypes = {
	id: PropTypes.string,
	label: PropTypes.string,
	defaultValue: PropTypes.string,
	isMultiline: PropTypes.bool,
	isFlexed: PropTypes.bool,
	isDisabled: PropTypes.bool,
	classes: PropTypes.object
};

export default withStyles(styles)(RepresentmentTextField);
