import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@mui/styles';
import NotificationContentManager from '../../components/Notifications/NotificationContentManager';

const styles = (theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		flex: 1,
		width: '100%'
	},
	notificationHeader: {
		fontSize: '2rem',
		padding: '5px'
	}
});

const Notifications = (props) => {
	const { classes, t } = props;
	const { root, notificationHeader } = classes;

	return (
		<div className={root}>
			<div
				style={{
					display: 'flex',
					flexFlow: 'column',
					marginLeft: '10px',
					marginTop: '8px',
					marginBottom: '20px'
				}}
			>
				<span
					style={{ margin: '0px', padding: '0px' }}
					className={notificationHeader}
				>
					Notifications
				</span>
				<p style={{ margin: '0px', padding: '0px' }}>
					Merchants: <b>All</b>
				</p>
				<p style={{ margin: '0px', padding: '0px' }}>
					Groups: <b>All</b>
				</p>
			</div>
			<NotificationContentManager />
		</div>
	);
};

Notifications.propTypes = {
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(Notifications));

/*
	const [
		openAddNotificationModal,
		setOpenAddNotificationModal
	] = React.useState(false);

	React.useEffect(() => {
		if (!hasBeenLoaded && !isLoading) {
			getUsers();
		}
	}, [getUsers, hasBeenLoaded, isLoading]);
	*/

/*
	const renderTimelineTitle = (data, id) => {
		const filteredTitle = data
			.filter((notification) => notification.id === id)
			.map((notification) => notification.label);
		if (filteredTitle.length) {
			return filteredTitle[0];
		}
		return [];
	};
	*/

/*
				<div style={{ display: 'flex', padding: '10px', margin: '5px' }}>
					<Button
						onClick={() => setOpenAddNotificationModal(true)}
						variant="outlined"
					>
						{t('pages.notifications.timeline.addNew')}
					</Button>
				</div>
				<ActiveNotifications
					listOfUsers={listOfUsers}
					renderTimelineTitle={renderTimelineTitle}
				/>
				<AddNotificationToStream
					addNotification={addNotification}
					listOfUsers={listOfUsers}
					open={openAddNotificationModal}
					setOpen={setOpenAddNotificationModal}
					user={user}
				/>
			</div>
			*/
