import * as actionTypes from './actionTypes';

export const getGroups = (payload) => ({
	type: actionTypes.GET_GROUPS,
	payload
});

export const getGroupsCompleted = (payload) => ({
	type: actionTypes.GET_GROUPS_COMPLETED,
	payload
});

export const getGroupsFailed = (payload) => ({
	type: actionTypes.GET_GROUPS_FAILED,
	payload
});

export const getMerchantList = (payload) => ({
	type: actionTypes.GET_MERCHANT_LIST,
	payload
});

export const getMerchantListCompleted = (payload) => ({
	type: actionTypes.GET_MERCHANT_LIST_COMPLETED,
	payload
});

export const getMerchantListFailed = (payload) => ({
	type: actionTypes.GET_MERCHANT_LIST_FAILED,
	payload
});

export const getMerchantsInGroup = (payload) => ({
	type: actionTypes.GET_MERCHANTS_IN_GROUP,
	payload
});

export const getMerchantsInGroupCompleted = (payload) => ({
	type: actionTypes.GET_MERCHANTS_IN_GROUP_COMPLETED,
	payload
});

export const getMerchantsInGroupFailed = (payload) => ({
	type: actionTypes.GET_MERCHANTS_IN_GROUP_FAILED,
	payload
});

export const setMerchantsInGroup = (payload) => ({
	type: actionTypes.SET_MERCHANTS_IN_GROUP,
	payload
});

export const getGroupNotificationRules = (payload) => ({
	type: actionTypes.GET_GROUP_NOTIFICATION_RULES,
	payload
});

export const getGroupNotificationRulesCompleted = (payload) => ({
	type: actionTypes.GET_GROUP_NOTIFICATION_RULES_COMPLETED,
	payload
});

export const getGroupNotificationRulesFailed = (payload) => ({
	type: actionTypes.GET_GROUP_NOTIFICATION_RULES_FAILED,
	payload
});

export const setGroupNotificationRuleBeingEdited = (payload) => ({
	type: actionTypes.SET_GROUP_NOTIFICATION_RULE_BEING_EDITED,
	payload
});
