import React from 'react';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import FilterController from './FilterController';
import ReportController from './ReportController';

const styles = ({ theme }) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		flex: 1
	}
});

const Reporter = (props) => {
	const {
		classes,
		reportViewId,
		OverrideFilterComponent,
		OverrideReportComponent
	} = props;
	const { root } = classes;

	return (
		<div className={root}>
			<FilterController
				reportViewId={reportViewId}
				OverrideFilterComponent={OverrideFilterComponent}
			/>
			<ReportController
				reportViewId={reportViewId}
				OverrideReportComponent={OverrideReportComponent}
			/>
		</div>
	);
};

Reporter.propTypes = {
	reportViewId: PropTypes.string,
	OverrideFilterComponent: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.object
	]),
	OverrideReportComponent: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.object
	]),
	data: PropTypes.object,
	filters: PropTypes.object,
	classes: PropTypes.object
};

export default withStyles(styles)(withTranslation()(Reporter));
