import staticReportViews from '../../components/staticReportViews';
import Reporter from '../../components/Reporter/Reporter';

export default function (reportViewId) {
	const staticViewComponent = staticReportViews.find((reportView) => {
		return reportView.reportViewId === reportViewId;
	});

	return staticViewComponent ? staticViewComponent.component : Reporter;
}
