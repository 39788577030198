export const groupByDateColumnDefinition = [
	'components.CurrentInventory.CurrentInventoryList.tableColumnDefinitions.cardNumber',
	'components.CurrentInventory.CurrentInventoryList.tableColumnDefinitions.customerName',
	'components.CurrentInventory.CurrentInventoryList.tableColumnDefinitions.amount',
	'components.CurrentInventory.CurrentInventoryList.tableColumnDefinitions.card',
	'components.CurrentInventory.CurrentInventoryList.tableColumnDefinitions.mid',
	'components.CurrentInventory.CurrentInventoryList.tableColumnDefinitions.chargebackDate',
	'components.CurrentInventory.CurrentInventoryList.tableColumnDefinitions.reasonCode',
	'components.CurrentInventory.CurrentInventoryList.tableColumnDefinitions.expiration'
];

export const groupByCardColumnDefinition = [
	'components.CurrentInventory.CurrentInventoryList.tableColumnDefinitions.cardNumber',
	'components.CurrentInventory.CurrentInventoryList.tableColumnDefinitions.customerName',
	'components.CurrentInventory.CurrentInventoryList.tableColumnDefinitions.amount',
	'components.CurrentInventory.CurrentInventoryList.tableColumnDefinitions.card',
	'components.CurrentInventory.CurrentInventoryList.tableColumnDefinitions.mid',
	'components.CurrentInventory.CurrentInventoryList.tableColumnDefinitions.chargebackDate',
	'components.CurrentInventory.CurrentInventoryList.tableColumnDefinitions.reasonCode',
	'components.CurrentInventory.CurrentInventoryList.tableColumnDefinitions.expiration'
];
