import * as actionTypes from './actionTypes';

export const getRepresentmentReversalReason = (payload) => ({
	type: actionTypes.GET_REPRESENTMENT_REVERSAL_REASON,
	payload
});

export const getRepresentmentReversalReasonCompleted = (payload) => ({
	type: actionTypes.GET_REPRESENTMENT_REVERSAL_REASON_COMPLETED,
	payload
});

export const getRepresentmentReversalReasonFailed = (payload) => ({
	type: actionTypes.GET_REPRESENTMENT_REVERSAL_REASON_FAILED,
	payload
});

export const setChargebackReversalReason = (payload) => ({
	type: actionTypes.SET_CHARGEBACK_REVERSAL_REASON,
	payload
});

export const insertRepresentmentReversalReason = (payload) => ({
	type: actionTypes.INSERT_REPRESENTMENT_REVERSAL_REASON,
	payload
});

export const insertRepresentmentReversalReasonCompleted = (payload) => ({
	type: actionTypes.INSERT_REPRESENTMENT_REVERSAL_REASON_COMPLETED,
	payload
});

export const insertRepresentmentReversalReasonFailed = (payload) => ({
	type: actionTypes.INSERT_REPRESENTMENT_REVERSAL_REASON_FAILED,
	payload
});

export const updateRepresentmentReversalReason = (payload) => ({
	type: actionTypes.UPDATE_REPRESENTMENT_REVERSAL_REASON,
	payload
});

export const updateRepresentmentReversalReasonCompleted = (payload) => ({
	type: actionTypes.UPDATE_REPRESENTMENT_REVERSAL_REASON_COMPLETED,
	payload
});

export const updateRepresentmentReversalReasonFailed = (payload) => ({
	type: actionTypes.UPDATE_REPRESENTMENT_REVERSAL_REASON_FAILED,
	payload
});

export const setBuilderType = (payload) => ({
	type: actionTypes.SET_BUILDER_TYPE,
	payload
});
