import React from 'react';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { humanizeTitleCase } from '../../../../../../utilities/humanize';
import deepCopy from '../../../../../../utilities/deepCopy';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

const styles = (theme) => ({
	box: {
		width: '30vw'
	},
	button: {
		textTransform: 'none',
		margin: '5px',
		height: 'fit-content'
	},
	columnStyle: {
		display: 'flex',
		flexDirection: 'column',
		padding: '3%'
	},
	listItem: {
		display: 'flex',
		alignItems: 'center'
	},
	modal: {
		display: 'flex',
		marginTop: '5vh',
		maxHeight: '70vh',
		justifyContent: 'center'
	},
	modalTitle: {
		display: 'flex',
		justifyContent: 'space-between'
	}
});

const FullTableModal = (props) => {
	const { classes, columns, isModalOpen, toggleModal, t } = props;
	const { box, button, columnStyle, listItem, modal, modalTitle } = classes;

	const onColumnClicked = (columnName) => {
		const { columns, onUpdateColumns } = props;
		const updatedColumns = deepCopy(columns);

		const columnToUpdate = updatedColumns.find((column) => {
			return column.name === columnName;
		});

		columnToUpdate.isDisplayed = !columnToUpdate.isDisplayed;

		onUpdateColumns(updatedColumns);
	};

	const selectAll = (value) => {
		const { columns, onUpdateColumns } = props;

		const updatedColumns = deepCopy(columns);

		updatedColumns.forEach((column) => {
			column.isDisplayed = value;
		});

		onUpdateColumns(updatedColumns);
	};

	return (
		<Modal open={isModalOpen} onClose={() => toggleModal()} className={modal}>
			<Box className={box}>
				<Paper id="2134" className={columnStyle}>
					<div className={modalTitle}>
						<div>
							<Typography variant="h6">
								{t('components.Widget.WidgetViewer.FullTable.columnSelect')}
							</Typography>
						</div>
						<div>
							<Button
								onClick={() => selectAll(true)}
								className={button}
								variant="outlined"
							>
								{t('components.Widget.WidgetViewer.FullTable.selectAll')}
							</Button>
							<Button
								onClick={() => selectAll(false)}
								className={button}
								variant="outlined"
							>
								{t('components.Widget.WidgetViewer.FullTable.deselectAll')}
							</Button>
						</div>
					</div>
					<Grid container spacing={1}>
						{columns.map((column) => {
							return (
								<Grid key={column.name} item xs={6} className={listItem}>
									<Checkbox
										size="small"
										checked={column.isDisplayed}
										onClick={() => onColumnClicked(column.name)}
									/>
									<Typography variant="subtitle2">{column.title}</Typography>
								</Grid>
							);
						})}
					</Grid>
				</Paper>
			</Box>
		</Modal>
	);
};

FullTableModal.propTypes = {
	columns: PropTypes.array,
	toggleModal: PropTypes.func,
	isModalOpen: PropTypes.bool,
	onUpdateColumns: PropTypes.func,
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(FullTableModal));
