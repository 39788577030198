import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';

import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';

import notificationTestImage from '../../../../__Demo_Data__/Assets/notificationTestImage.png';

const demoAssets = {
	'notificationTestImage.PNG': notificationTestImage
};

const styles = (theme) => ({
	end: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-end'
	},
	title: {
		display: 'flex',
		justifyContent: 'space-between'
	},
	additionalContentContainer: {
		display: 'flex',
		flexDirection: 'column',
		paddingTop: '5px'
	},
	imageOutline: {
		borderRadius: '3px',
		border: `2px solid ${theme.palette.primary.main}`
	},
	additionalContentList: {
		display: 'flex',
		flexDirection: 'row',
		alignSelf: 'stretch',
		margin: '5px'
	},
	pointer: {
		cursor: 'pointer'
	}
});

const NotificationDetailDisplay = (props) => {
	const {
		classes,
		entry,
		handleSubmit,
		isEditable,
		renderAssigned,
		selected,
		setDeleteData,
		setIsEditable,
		onOpenAttachmentModal,
		t
	} = props;
	const {
		end,
		title,
		additionalContentContainer,
		additionalContentList,
		pointer,
		imageOutline
	} = classes;

	return (
		<Fragment>
			<div className={title}>
				<span>
					<Typography variant="body1">{entry.type}</Typography>
				</span>
				<span>
					<IconButton
						onClick={() => {
							//setIsEditable(true);
						}}
					>
						<EditIcon fontSize="small" />
					</IconButton>
					<IconButton
						disabled={isEditable}
						onClick={() => {}}
						// setDeleteData({
						// 	label: t('pages.notifications.deleteModal.label'),
						// 	message: t('pages.notifications.deleteModal.detailMessage'),
						// 	handleClose: () => setDeleteData({}),
						// 	handleSubmit,
						// 	parameters: {
						// 		notificationId: selected.id,
						// 		detailId: entry.id
						// 	}
						// })
					>
						<CloseIcon fontSize="small" />
					</IconButton>
				</span>
			</div>
			<Typography variant="subtitle2">{entry.message}</Typography>
			<div className={title}>
				<span>
					<Typography variant="caption" sx={{ m: 1 }}>
						{t('pages.notifications.timeline.assigned')}
						{entry.assignedTo ? entry.assignedTo : 'Not Assigned'}
					</Typography>
					<Typography variant="caption" sx={{ m: 1 }}>
						{t('pages.notifications.timeline.createdBy')}
						{entry.createdBy ? entry.createdBy : 'Bradley Servivce'}
					</Typography>
					{entry.tags.length ? (
						<Typography variant="caption" sx={{ m: 1 }}>
							{t('pages.notifications.timeline.tags')}
							{entry.tags.split(',').map((tag) => (
								<Chip size="small" variant="outlined" key={tag} label={tag} />
							))}
						</Typography>
					) : null}
				</span>
				<div className={end}>
					{entry.updatedOn !== null ? (
						<>
							<Typography variant="caption">
								{t('pages.notifications.timeline.updatedOn')}
							</Typography>
							<Typography variant="caption">{entry.updatedOn}</Typography>
						</>
					) : (
						<>
							<Typography variant="caption">
								{t('pages.notifications.timeline.createdOn')}
							</Typography>
							<Typography variant="caption">{entry.createdOn}</Typography>
						</>
					)}
				</div>
			</div>
			{/* TODO - MAKE ATTACHMENTS COMPONENT */}
			{entry && entry.attachments.length > 0 && (
				<div className={additionalContentContainer}>
					<Divider width="40%" />
					<span>Attachments</span>
					<div className={additionalContentList}>
						{entry.attachments.map((attachment, index) => {
							if (attachment.type === 'base64') {
								return (
									<Tooltip key={index} title={attachment.title}>
										<div className={imageOutline}>
											<img
												src={attachment.value}
												className={pointer}
												height={100}
												width={100}
												onClick={() => {
													onOpenAttachmentModal(attachment);
												}}
											/>
										</div>
									</Tooltip>
								);
							} else {
								return (
									<Tooltip key={index} title={attachment.title}>
										<div className={imageOutline}>
											<img
												src={demoAssets[attachment.assetName]}
												className={pointer}
												height={100}
												width={100}
												onClick={() => {
													onOpenAttachmentModal(attachment);
												}}
											/>
										</div>
									</Tooltip>
								);
							}
						})}
					</div>
				</div>
			)}
		</Fragment>
	);
};

NotificationDetailDisplay.propTypes = {
	classes: PropTypes.object,
	entry: PropTypes.object,
	handleSubmit: PropTypes.func,
	isEditable: PropTypes.bool,
	renderAssigned: PropTypes.func,
	setDeleteData: PropTypes.func,
	setIsEditable: PropTypes.func,
	selected: PropTypes.object,
	t: PropTypes.func,
	onOpenAttachmentModal: PropTypes.func
};

export default withStyles(styles)(withTranslation()(NotificationDetailDisplay));
