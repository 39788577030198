import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { reporterActions } from '../../containers/Reporter';
import safeIsReportLoading from '../../helpers/reporter/safeIsReportLoading';

import Container from '@mui/material/Container';

const styles = ({ theme }) => ({
	root: {
		display: 'flex'
	},
	hidden: {
		display: 'none'
	}
});

const FilterController = (props) => {
	const {
		classes,
		reportViewId,
		OverrideFilterComponent,
		filters,
		reportDataList
	} = props;
	const { root, hidden } = classes;

	const isReportLoading = safeIsReportLoading(reportDataList, reportViewId);

	const onSubmitFilters = (reportId, queryParameters, customRoute) => {
		const { executeReport } = props;

		executeReport({
			id: reportId,
			queryParameters: queryParameters,
			uuid: reportViewId,
			customRoute
		});
	};

	return (
		<Container className={clsx(root, !OverrideFilterComponent && hidden)}>
			{OverrideFilterComponent && (
				<OverrideFilterComponent
					reportViewId={reportViewId}
					filters={filters}
					onSubmit={onSubmitFilters}
					reportDataIsLoading={isReportLoading}
				/>
			)}
		</Container>
	);
};

FilterController.propTypes = {
	reportViewId: PropTypes.string,
	OverrideFilterComponent: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.object
	]),
	filters: PropTypes.object,
	reportDataList: PropTypes.object,
	executeReport: PropTypes.func,
	classes: PropTypes.object
};

const mapStateToProps = (state) => {
	return {
		reportDataList: state.reporterReducer.get('reportData')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		executeReport: (payload) => {
			dispatch(reporterActions.executeReport(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(FilterController));
