import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { authActions } from '../../containers/Auth';
import { useHistory, useLocation } from 'react-router-dom';
import Loader from '../Loader';
import {
	clearLocalStorage,
	getLocalStorage
} from '../../utilities/localStorageUtility';

import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import PasswordValidationInput from '../../validation/inputs/passwordValidationInput';

const styles = (theme) => ({
	detailsContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between'
	},
	progress: {
		alignItems: 'center',
		display: 'flex',
		height: '160px',
		justifyContent: 'center',
		margin: '10px 0px 20px 20px'
	},
	formContainer: {
		display: 'flex',
		flexDirection: 'column'
	},
	textField: {
		marginBottom: '10px',
		textDecoration: 'none!important'
	},
	submit: {
		textTransform: 'none',
		color: theme.palette.common.white,
		width: '10ch'
	}
});

const LoginForm = (props) => {
	const {
		classes,
		t,
		isAuthed,
		isAuthingUser,
		logInError,
		authUserSession
	} = props;
	const { detailsContainer, formContainer, textField, submit } = classes;

	const [hasValidationErrors, setHasValidationErrors] = React.useState(false);
	const [passwordInput, setPasswordInput] = React.useState('');
	const [userInfo, setUserInfo] = React.useState({});

	const history = useHistory();
	const { state } = useLocation();

	const handleHasValidationErrors = (hasErrors) => {
		if (hasErrors) {
			setHasValidationErrors(true);
		} else {
			setHasValidationErrors(false);
		}
	};

	const getStoredInfo = React.useCallback(() => {
		const cpiInfo = getLocalStorage('CPI');

		setUserInfo({
			organization: cpiInfo.ORG,
			username: cpiInfo.UNAME
		});
	}, [setUserInfo]);

	React.useEffect(() => {
		if (!userInfo.organization) {
			getStoredInfo();
		}
	}, [getStoredInfo, userInfo]);

	React.useEffect(() => {
		if (!isAuthed) {
			// authUserSession();
		} else if (isAuthed) {
			history.push('/post-login', {
				from: state ? state.from : undefined,
				username: userInfo.username
			});
		}
	}, [isAuthed, authUserSession, userInfo, state]);

	const handleSubmit = (event) => {
		const { authUser } = props;
		event.preventDefault();

		const username = event.target[0].value;
		const password = event.target[2].value;
		authUser(username, password);
	};

	const handleRemoveLocalStorage = () => {
		clearLocalStorage();
	};

	return (
		<>
			{isAuthingUser ? (
				<Loader />
			) : (
				<Fragment>
					<h1>{userInfo.organization || t('pages.login.header')}</h1>
					<form className={formContainer} onSubmit={handleSubmit}>
						<TextField
							size="small"
							error={logInError}
							className={textField}
							id="login-username-input"
							label={t('pages.login.input.username')}
							type="text"
							disabled
							InputLabelProps={{
								shrink: true
							}}
							variant="outlined"
							value={userInfo.username || ''}
						/>
						<PasswordValidationInput
							label={t('pages.login.input.password')}
							onChange={(value) => setPasswordInput(value)}
							loginError={logInError}
							value={passwordInput}
							showTooltip={false}
							onError={handleHasValidationErrors}
							t={t}
						/>
						<div className={detailsContainer}>
							<Link
								className={textField}
								href="/forgot-password"
								onClick={(e) => e.preventDefault}
							>
								{t('pages.login.forgot-password')}
							</Link>
							<Link
								className={textField}
								href=""
								onClick={handleRemoveLocalStorage}
							>
								Not you?
							</Link>
						</div>
						<Button
							type="submit"
							variant="contained"
							size="small"
							color="primary"
							disabled={hasValidationErrors}
							className={submit}
						>
							{t('pages.login.submit')}
						</Button>
					</form>
				</Fragment>
			)}
		</>
	);
};

LoginForm.propTypes = {
	isAuthed: PropTypes.bool,
	isAuthingUser: PropTypes.bool,
	logInError: PropTypes.bool,
	authUser: PropTypes.func,
	authUserSession: PropTypes.func,
	classes: PropTypes.object,
	t: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		challengeName: state.authReducer.get('challengeName'),
		isAuthed: state.authReducer.get('isAuthed'),
		isAuthingUser: state.authReducer.get('isAuthingUser'),
		logInError: state.authReducer.get('logInError')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		authUser: (username, password) => {
			dispatch(authActions.authUser(username, password));
		},
		authUserSession: (payload) => {
			dispatch(authActions.authUserSession());
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(withTranslation()(LoginForm)));
