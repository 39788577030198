import axios from '../../utilities/axios';
import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { refreshToken } from '../../utilities/refreshToken';
import { failureMessage } from '../ToastNotifications/actions';
import { primaryRestGateway } from '../../utilities/apiEndpointUtility';

import * as actions from './actions';
import * as actionTypes from './actionTypes';

import { demoWait } from '../../__Demo_Data__/mockRandomLoad';

export const generatePdfEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.GENERATE_PDF),
		mergeMap(async (action) => {
			await demoWait();
			//await refreshToken();

			// const caseId = action.payload.caseId;

			// const response = await axios.get(
			// 	`${primaryRestGateway()}/api/representments/build/${caseId}`
			// );

			return 'https://dev.assets.slyce360.com/sempris/exampleFlat_chargebackReversalRequest_WithRefund.pdf'; // response.data;
		}),
		switchMap((res) => [actions.generatePdfCompleted(res)]),
		catchError((error, source) =>
			merge(
				of(
					actions.generatePdfFailed(error.message),
					failureMessage(error.message)
				),
				source
			)
		)
	);
