export const calculatePendingPercent = (misses, wins, total) => {
	let calculatedPrecent = 0;

	if (total > 0) {
		calculatedPrecent = ((total - (misses + wins)) / total) * 100;
	}

	return calculatedPrecent;
};

export const getPercentage = (value, total) => {
	let calculatedPrecent = 0;

	if (total > 0) {
		calculatedPrecent = (value / total) * 100;
	}

	return calculatedPrecent;
};

export const isMissesGreaterThanZero = (misses) => {
	let result = false;

	if (misses && misses.count > 0) {
		result = true;
	}

	return result;
};
