import React from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import Skeleton from '@mui/material/Skeleton';

const styles = (theme) => ({
	tileRowContainer: {
		display: 'flex',
		justifyContent: 'flex-start',
		flexDirection: 'row',
		margin: '10px 0 10px 0'
	},
	skeletonMargin: {
		margin: '0 10px 0 10px'
	}
});

const DashboardSkeleton = (props) => {
	const { classes } = props;
	const { tileRowContainer, skeletonMargin } = classes;

	return (
		<div className={tileRowContainer}>
			<Skeleton
				className={skeletonMargin}
				variant="square"
				width={500}
				height={400}
			/>
			<Skeleton
				className={skeletonMargin}
				variant="square"
				width={500}
				height={400}
			/>
			<Skeleton
				className={skeletonMargin}
				variant="square"
				width={500}
				height={400}
			/>
			<Skeleton
				className={skeletonMargin}
				variant="square"
				width={500}
				height={400}
			/>
		</div>
	);
};

DashboardSkeleton.propTypes = {
	classes: PropTypes.object
};

export default withStyles(styles)(DashboardSkeleton);
