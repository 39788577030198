import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';

const styles = (theme) => ({
	root: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'center'
	}
});

const CounterSmallSettings = (props) => {
	const { classes, t } = props;
	const { root } = classes;

	return (
		<div className={root}>
			<p>{t('pages.widgets.settings.nocurrentsettings')}</p>
		</div>
	);
};

CounterSmallSettings.propTypes = {
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(CounterSmallSettings));
