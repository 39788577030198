import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import { layoutsActions } from '../../../containers/Layouts';
import { dashboardActions } from '../../../containers/Dashboard';
import { modalActions } from '../../../containers/Modal';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import {
	getNextLayout,
	getPreviousLayout,
	getFirstLayout,
	isActiveLayoutDeleted
} from '../../../containers/Dashboard/helpers/activeLayoutHelper';
import DashboardControlSkeleton from './DashboardControlSkeleton';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Button from '@mui/material/Button';

const styles = (theme) => ({
	containerRow: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		margin: '1rem 0 1rem 0'
	},
	layoutText: {
		paddingTop: '.5rem',
		fontWeight: '600',
		color: theme.palette.secondary.main
	},
	arrowIcons: {
		color: theme.palette.dashboard.dashboardControl.switchLayoutArrow,
		marginTop: '.5rem',
		cursor: 'pointer',
		'&hover': {
			color: theme.palette.dashboard.dashboardControl.switchLayoutArrowHover
		}
	},
	arrowIconsDisabled: {
		marginTop: '.5rem',
		color: theme.palette.dashboard.dashboardControl.switchLayoutDisabled
	},
	manageButton: {
		marginLeft: '2rem',
		cursor: 'pointer',
		'&hover': {
			backgroundColor: theme.palette.common.white
		}
	}
});

export const DashboardControl = (props) => {
	const {
		classes,
		t,
		openManageDashboardModal,
		activeDashboardLayout,
		layoutsHaveBeenLoaded,
		layoutsError,
		layoutsAreLoading,
		getLayouts,
		layouts,
		setActiveLayout,
		user
	} = props;
	const {
		containerRow,
		layoutText,
		arrowIcons,
		arrowIconsDisabled,
		manageButton
	} = classes;

	const [currentDashboardLabel, setCurrentDashboardLabel] = React.useState();

	const history = useHistory();

	React.useEffect(() => {
		if (user && !layoutsHaveBeenLoaded && !layoutsAreLoading && !layoutsError) {
			getLayouts({
				userId: user.id
			});
		}
	}, [
		layoutsAreLoading,
		layoutsHaveBeenLoaded,
		layoutsError,
		getLayouts,
		user
	]);

	React.useEffect(() => {
		if (activeDashboardLayout) {
			setCurrentDashboardLabel(activeDashboardLayout.label);
		}
	}, [activeDashboardLayout, setCurrentDashboardLabel]);

	React.useEffect(() => {
		if (layoutsHaveBeenLoaded) {
			const activeLayoutIsDeleted = isActiveLayoutDeleted(
				layouts,
				activeDashboardLayout
			);

			if (activeLayoutIsDeleted) {
				const nextLayout = getFirstLayout(layouts, activeDashboardLayout);
				setActiveLayout(nextLayout);
			}
		}
	}, [layoutsHaveBeenLoaded, layouts, activeDashboardLayout, setActiveLayout]);

	const setNextLayout = () => {
		const { layouts, activeDashboardLayout, setActiveLayout } = props;

		const nextLayout = getNextLayout(layouts, activeDashboardLayout);

		setActiveLayout(nextLayout);
	};

	const setPreviousLayout = () => {
		const { layouts, activeDashboardLayout, setActiveLayout } = props;

		const nextLayout = getPreviousLayout(layouts, activeDashboardLayout);

		setActiveLayout(nextLayout);
	};

	const onClickGroupManagement = () => {
		history.push('/group-management');
	};

	return (
		<Fragment>
			{!layoutsHaveBeenLoaded && !currentDashboardLabel ? (
				<DashboardControlSkeleton />
			) : (
				<div className={containerRow}>
					<ArrowBackIosIcon
						className={clsx(
							layouts && layouts.length > 1 ? arrowIcons : arrowIconsDisabled
						)}
						onClick={setPreviousLayout}
					/>
					<span className={layoutText}>{currentDashboardLabel}</span>
					<ArrowForwardIosIcon
						className={clsx(
							layouts && layouts.length > 1 ? arrowIcons : arrowIconsDisabled
						)}
						onClick={setNextLayout}
					/>
					<Button
						className={manageButton}
						variant="outlined"
						// Disabled for demo
						//onClick={openManageDashboardModal}
					>
						Layouts
					</Button>
					<Button
						className={manageButton}
						onClick={() => {
							onClickGroupManagement(history);
						}}
						variant="outlined"
					>
						{t('components.DashboardControl.groupManagement')}
					</Button>
				</div>
			)}
		</Fragment>
	);
};

DashboardControl.propTypes = {
	user: PropTypes.object,
	activeDashboardLayout: PropTypes.object,
	layouts: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	layoutsAreLoading: PropTypes.bool,
	layoutsHaveBeenLoaded: PropTypes.bool,
	layoutsError: PropTypes.string,
	getLayouts: PropTypes.func,
	setActiveLayout: PropTypes.func,
	openManageDashboardModal: PropTypes.func,
	classes: PropTypes.object,
	t: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		user: state.userReducer.get('user'),
		activeDashboardLayout: state.dashboardReducer.get('activeDashboardLayout'),
		layouts: state.layoutsReducer.get('data'),
		layoutsAreLoading: state.layoutsReducer.get('isLoading'),
		layoutsHaveBeenLoaded: state.layoutsReducer.get('hasBeenLoaded'),
		layoutsError: state.layoutsReducer.get('error')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getLayouts: (payload) => {
			dispatch(layoutsActions.getLayouts(payload));
		},
		setActiveLayout: (payload) => {
			dispatch(dashboardActions.setActiveDashboardLayout(payload));
		},
		openManageDashboardModal: () => {
			dispatch(modalActions.toggleManageDashboardModal());
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(withTranslation()(DashboardControl)));
