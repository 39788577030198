import React, { Fragment } from 'react';
import {
	calculatePendingPercent,
	getPercentage
} from './percentageCalculators';
import querier from '../../utilities/querier';

const _demoStatBar = (StatBarFragment, winsPercentage, pendingPercentage) => {
	return (
		<Fragment>
			<StatBarFragment
				sectionKey={'misses'}
				sectionValue={4}
				isRoundedLeft={true}
				isRoundedRight={false}
			/>
			<StatBarFragment
				sectionKey={'wins'}
				sectionValue={winsPercentage}
				isRoundedLeft={false}
				isRoundedRight={false}
			/>
			{pendingPercentage > 1 && (
				<StatBarFragment
					sectionKey={'pending'}
					sectionValue={pendingPercentage - 4}
					isRoundedLeft={false}
					isRoundedRight={true}
				/>
			)}
		</Fragment>
	);
};

export default function (data, StatBarFragment) {
	const formatted = <></>;

	if (Object.keys(data).length > 0) {
		const winsValue = querier(data, 'wins.value');
		const missesValue = querier(data, 'misses.value');
		const totalValue = querier(data, 'total.value');

		const missesPercentage = getPercentage(missesValue, totalValue);
		const winsPercentage = getPercentage(winsValue, totalValue);
		const pendingPercentage = calculatePendingPercent(
			missesValue,
			winsValue,
			totalValue
		);

		return _demoStatBar(StatBarFragment, winsPercentage, pendingPercentage);
		// return (
		// 	<Fragment>
		// 		{missesPercentage > 1 && (
		// 			<StatBarFragment
		// 				sectionKey={'misses'}
		// 				sectionValue={missesPercentage < 5 ? 10 : missesPercentage}
		// 				isRoundedLeft={true}
		// 				isRoundedRight={false}
		// 			/>
		// 		)}
		// 		<StatBarFragment
		// 			sectionKey={'wins'}
		// 			sectionValue={winsPercentage}
		// 			isRoundedLeft={missesPercentage <= 1}
		// 			isRoundedRight={pendingPercentage <= 1}
		// 		/>
		// 		{pendingPercentage > 1 && (
		// 			<StatBarFragment
		// 				sectionKey={'pending'}
		// 				sectionValue={pendingPercentage}
		// 				isRoundedLeft={false}
		// 				isRoundedRight={true}
		// 			/>
		// 		)}
		// 	</Fragment>
		// );
	}

	return formatted;
}
