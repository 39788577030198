import React from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import optionsStandardizer from '../../../../helpers/widget/optionsStandardizer';
import generateFilterSelect from '../../../../helpers/widget/generateFilterSelect';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { CircularProgress } from '@mui/material';

const styles = (theme) => ({
	filterContainer: {
		display: 'flex',
		flex: 1,
		alignSelf: 'stretch',
		justifyContent: 'flex-end',
		alignItems: 'center',
		flexFlow: 'row wrap',
		marginBottom: '5px'
	},
	stack: {
		display: 'flex',
		flexFlow: 'row wrap'
	},
	submitButton: {
		margin: '5px 10px 0 10px',
		height: '55px',
		cursor: 'pointer',
		'&hover': {
			backgroundColor: theme.palette.common.white
		}
	}
});

const FilterSelect = (props) => {
	const {
		options,
		filters,
		onFilterUpdate,
		onSubmit,
		isLoadingReportData,
		classes,
		t
	} = props;
	const { filterContainer, stack, submitButton } = classes;

	const standardizedOptions = optionsStandardizer(options);

	const handleFilterUpdate = (option, value) => {
		const newFilter = {};

		if (Array.isArray(value)) {
			newFilter[option] = value;
		} else {
			newFilter[option] = `${value}`;
		}

		onFilterUpdate(Object.assign({}, filters, newFilter));
	};

	return (
		<div className={filterContainer}>
			<Stack
				className={stack}
				direction="row"
				spacing={2}
				mt={1}
				alignItems="center"
				justifyContent="right"
			>
				{generateFilterSelect(standardizedOptions, filters, handleFilterUpdate)}
			</Stack>
			<Button
				className={submitButton}
				variant="outlined"
				disabled={isLoadingReportData}
				onClick={onSubmit}
				color="primary"
			>
				{isLoadingReportData ? (
					<CircularProgress size="1.2rem" />
				) : (
					t('components.Widget.WidgetController.submit')
				)}
			</Button>
		</div>
	);
};

FilterSelect.propTypes = {
	options: PropTypes.object,
	filters: PropTypes.object,
	onFilterUpdate: PropTypes.func,
	onSubmit: PropTypes.func,
	isLoadingReportData: PropTypes.bool,
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(FilterSelect));
