export default function (data) {
	if (data) {
		return Object.keys(data).map((item) => {
			return {
				key: item,
				reason: item,
				firstChargebackAmount: data[item].firstChargebackAmount,
				chargebackRecoveryAmount: data[item].chargebackRecoveryAmount,
				successRate: data[item].successRate
			};
		});
	} else {
		return [];
	}
}
