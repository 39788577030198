import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import { useLocation } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { notificationActions } from '../../../containers/Notifications';
import TimelineNotificationDetails from './components/TimelineNotificationDetails';
import AddNewDetailTemplate from './components/AddNewDetailTemplate';
import InitialNotification from './components/InitialNotification';
import { detailTypeOptions } from './mocks/mockTypes';

// TODO: implement these when we have the ability to render the users through their ids
// import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Timeline from '@mui/lab/Timeline';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

const styles = (theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'row',
		flex: 4,
		justifyContent: 'center'
	},

	buttonContainer: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		margin: '10px'
	},
	button: {
		display: 'flex',
		justifyContent: 'center'
	},
	loaderContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		alignContent: 'center'
	},
	root: {
		width: '70%',
		overflowY: 'auto'
	},
	top: {
		display: 'flex',
		flexDirection: 'column'
	},
	vertical: {
		'&::before': {
			height: '80%',
			marginTop: '24px'
		}
	}
});

const NotificationTimeline = (props) => {
	const {
		classes,
		notificationDetails,
		notificationDetailsIsLoading,
		notificationDetailsHasBeenLoaded,

		getNotificationDetailsById,
		listOfUsers,
		deleteNotification,
		deleteNotificationDetail,
		t,
		updateNotification,
		updateNotificationDetail
	} = props;
	const {
		container,
		button,
		buttonContainer,
		loaderContainer,
		root,
		selectStream,
		vertical
	} = classes;

	const [openDetailTemplate, setOpenDetailTemplate] = useState(false);
	const [currentNotificationId, setCurrentNotificationId] = React.useState();

	const location = useLocation();

	React.useEffect(() => {
		const notificationId = location.pathname.split('/')[2];

		if (!currentNotificationId || currentNotificationId !== notificationId) {
			setCurrentNotificationId(notificationId);
			getNotificationDetailsById({
				notificationId
			});
		}
	}, [
		location,
		currentNotificationId,
		notificationDetails,
		notificationDetailsIsLoading,
		notificationDetailsHasBeenLoaded
	]);

	const _addButton = () => {
		return (
			<Button
				className={button}
				onClick={() => setOpenDetailTemplate(true)}
				variant="outlined"
			>
				{t('pages.notifications.timeline.addNewDetail')}
			</Button>
		);
	};

	const handleClose = () => {
		setOpenDetailTemplate(false);
	};

	const _renderAssignedChip = (id) => {
		if (listOfUsers.length) {
			const author = listOfUsers.filter((user) => user.id === id);
			return (
				<Chip
					label={`${author[0].firstName} ${author[0].lastName}`}
					size="small"
					variant="outlined"
				/>
			);
		}
	};

	return (
		<div className={container}>
			{notificationDetailsIsLoading || !notificationDetailsHasBeenLoaded ? (
				<div className={loaderContainer}>
					<CircularProgress />
				</div>
			) : !notificationDetailsIsLoading &&
			  notificationDetailsHasBeenLoaded &&
			  notificationDetails[currentNotificationId] &&
			  notificationDetails[currentNotificationId].length > 0 ? (
				<div className={root}>
					<div className={buttonContainer}>{_addButton()}</div>
					<Timeline position={'right'} className={vertical}>
						{openDetailTemplate && (
							<AddNewDetailTemplate
								handleClose={handleClose}
								listOfUsers={listOfUsers}
								selected={notificationDetails[currentNotificationId][0]}
								typeOptions={detailTypeOptions}
								parentNotificationId={currentNotificationId}
							/>
						)}
						<TimelineNotificationDetails
							deleteNotificationDetail={deleteNotificationDetail}
							listOfUsers={listOfUsers}
							notificationDetails={notificationDetails[
								currentNotificationId
							].slice(1)}
							renderAssigned={_renderAssignedChip}
							selected={notificationDetails[currentNotificationId][0]}
							updateNotificationDetail={updateNotificationDetail}
						/>
						<InitialNotification
							deleteNotification={deleteNotification}
							listOfUsers={listOfUsers}
							renderAssigned={_renderAssignedChip}
							selected={notificationDetails[currentNotificationId][0]}
							updateNotification={updateNotification}
						/>
					</Timeline>
				</div>
			) : (
				<Typography>
					{t('pages.notifications.general.defaultNoData')}
				</Typography>
			)}
		</div>
	);
};

NotificationTimeline.propTypes = {
	classes: PropTypes.object,
	notificationDetails: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	notificationDetailsIsLoading: PropTypes.bool,
	notificationDetailsHasBeenLoaded: PropTypes.bool,

	notifications: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	notificationAreLoading: PropTypes.bool,
	notificationHasBeenLoaded: PropTypes.bool,

	getNotificationDetailsById: PropTypes.func,
	listOfUsers: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),

	selected: PropTypes.object,
	deleteNotification: PropTypes.func,
	deleteNotificationDetail: PropTypes.func,
	t: PropTypes.func,
	updateNotification: PropTypes.func,
	updateNotificationDetail: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		notificationDetails: state.notificationReducer.getIn([
			'notificationDetails',
			'data'
		]),
		notificationDetailsIsLoading: state.notificationReducer.getIn([
			'notificationDetails',
			'isLoading'
		]),
		notificationDetailsHasBeenLoaded: state.notificationReducer.getIn([
			'notificationDetails',
			'hasBeenLoaded'
		]),
		listOfUsers: state.usersReducer.get('users')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		deleteNotification: (payload) => {
			dispatch(notificationActions.deleteNotification(payload));
		},
		deleteNotificationDetail: (payload) => {
			dispatch(notificationActions.deleteNotificationDetail(payload));
		},
		getNotificationDetailsById: (payload) => {
			dispatch(notificationActions.getNotificationDetailsById(payload));
		},
		updateNotification: (payload) => {
			dispatch(notificationActions.updateNotification(payload));
		},
		updateNotificationDetail: (payload) => {
			dispatch(notificationActions.updateNotificationDetail(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(withTranslation()(NotificationTimeline)));
