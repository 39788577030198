import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';

const styles = (theme) => ({
	container: {
		display: 'flex',
		flex: 1,
		flexFlow: 'row',
		justifyContent: 'flex-start'
	},
	selectBox: {
		margin: '0px 3px',
		minWidth: '160px',
		height: '40px'
	}
});

const groupByOptions = [
	{
		value: 'activeStatus',
		label: 'Active Status'
	},
	{
		value: 'alertLevel',
		label: 'Alert Level'
	}
];

export const GroupByControl = (props) => {
	const { classes, t, sortBy, setSortBy } = props;
	const { container, selectBox } = classes;

	return (
		<div className={container}>
			<FormControl>
				<InputLabel id="group-by-label">Group By</InputLabel>
				<Select
					labelId="group-by-label"
					id="group-by-select"
					label="Group By"
					className={selectBox}
					value={sortBy}
					onChange={(e) => {
						setSortBy(e.target.value);
					}}
				>
					{groupByOptions.map((option) => (
						<MenuItem key={option.value} value={option.value}>
							{option.label}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</div>
	);
};

GroupByControl.propTypes = {
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(GroupByControl));
