import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { modalActions } from '../../../containers/Modal';
import { withStyles } from '@mui/styles';

import HeaderRow from '../shared/HeaderRow';
import WizardContentManager from './components/WizardContentManager';

import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';

const styles = (theme) => ({
	modal: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		flex: 1
	},
	paper: {
		display: 'flex',
		height: '50vh',
		width: '50vw',
		flexFlow: 'column',
		padding: '1rem'
	},
	paperRow: {
		display: 'flex',
		flexflow: 'row'
	},
	layoutText: {
		paddingTop: '.5rem',
		fontWeight: '600',
		color: theme.palette.secondary.main
	},
	buttonContainer: {
		display: 'flex',
		flexDirection: 'column',
		flex: '1',
		justifyContent: 'flex-end'
	},
	inputButton: {
		borderRadius: '.5rem',
		flexShrink: '0.1',
		border: 'none',
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.secondary.alt,
		padding: '5px',
		fontSize: '16px',
		fontWeight: '300',
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
			cursor: 'pointer'
		}
	}
});

export const CreateLayoutModal = (props) => {
	const { classes, t, isCreateLayoutModalOpen } = props;
	const { modal, paper } = classes;

	const onCloseModal = () => {
		const { closeModal } = props;

		if (
			window.confirm(
				t('components.modals.createLayout.navigation.confirmClose')
			)
		) {
			closeModal();
		}
	};

	return (
		<Modal
			open={isCreateLayoutModalOpen}
			className={modal}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Paper className={paper} elevation={1}>
				<HeaderRow
					translationPath={'components.modals.createLayout.createLayout'}
					onClickCancel={onCloseModal}
				/>
				<WizardContentManager />
			</Paper>
		</Modal>
	);
};

CreateLayoutModal.propTypes = {
	isCreateLayoutModalOpen: PropTypes.bool,
	closeModal: PropTypes.func,
	classes: PropTypes.object,
	t: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		isCreateLayoutModalOpen: state.modalReducer.get('isCreateLayoutModalOpen')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		closeModal: () => {
			dispatch(modalActions.toggleCreateLayoutModal());
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(withTranslation()(CreateLayoutModal)));
