// import axios from '../../utilities/axios';
import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { failureMessage } from '../ToastNotifications/actions';
import { refreshToken } from '../../utilities/refreshToken';
// import { primaryRestGateway } from '../../utilities/apiEndpointUtility';
import reportViews from './constants/reportViewList';

import * as actions from './actions';
import * as actionTypes from './actionTypes';

import { demoWait } from '../../__Demo_Data__/mockRandomLoad';

export const getReportViewsEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.GET_REPORT_VIEWS),
		mergeMap(async (action) => {
			await demoWait();
			// await refreshToken();

			// TODO - HOOK UP TO THE DATA ONCE DATABASE CREATED
			// const response = await axios.get(`${primaryRestGateway()}/api/reports`);

			return {
				data: reportViews
			};
		}),
		switchMap((res) => [actions.getReportViewsCompleted(res.data)]),
		catchError((error, source) =>
			merge(
				of(
					actions.getReportViewsFailed(error.message),
					failureMessage(error.message)
				),
				source
			)
		)
	);
