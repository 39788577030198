import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

const styles = (theme) => ({
	row: {
		display: 'flex',
		flexDirection: 'row',
		padding: '5px',
		justifyContent: 'center'
	},
	authCodeContainer: {
		alignContent: 'center',
		alignItems: 'center',
		textAlign: 'middle',
		marginTop: '20px'
	},
	codeField: {
		marginLeft: '20px'
	},
	errorText: {
		padding: '5px',
		color: theme.palette.error.main
	},
	actionRow: {
		display: 'flex',
		flexDirection: 'row',
		marginTop: '10px',
		justifyContent: 'flex-end'
	},
	cancelButton: {
		width: '150px',
		color: theme.palette.primary.main,
		padding: '5px',
		marginRight: '10px',
		fontSize: '16px',
		fontWeight: '300'
	},
	inputButton: {
		borderRadius: '4px',
		width: '180px',
		border: 'none',
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.secondary.alt,
		padding: '5px',
		fontSize: '16px',
		fontWeight: '300',
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
			cursor: 'pointer'
		}
	}
});

export const ModifyPage = (props) => {
	const { classes, t, user, onCloseModal, onDisableMfa, mfaSetupError } = props;
	const { row, actionRow, authCodeContainer, codeField, errorText, cancelButton, inputButton } = classes;

	const [auththenticationCode, setAuththenticationCode] = React.useState();

	return (
		<Fragment >
			<div className={row}>
				{t('components.modals.MultiFactorAuth.disableMfa')}
			</div>
			<div className={clsx(row, authCodeContainer)}>
				{t('components.modals.MultiFactorAuth.authenticationCode')}
				<TextField onChange={ e => setAuththenticationCode(e.target.value)} className={codeField} id='outlined-basic' label={t('components.modals.MultiFactorAuth.code')} variant='outlined' size='small' />
			</div>
			{ mfaSetupError &&
					<div className={row}>
						<span className={errorText}>{mfaSetupError}</span>
					</div>
			}
			<div className={actionRow}>
				<Button
					variant="text"
					size="small"
					className={cancelButton}
					onClick={onCloseModal}
				>
					{t('generic.buttons.cancel')}
				</Button>
				<Button
					variant="contained"
					size="small"
					className={inputButton}
					onClick={() => { onDisableMfa(user, auththenticationCode); }}
				>
					{t('components.modals.MultiFactorAuth.deactivate')}
				</Button>
			</div>
		</Fragment>
	);
};

ModifyPage.propTypes = {
	user: PropTypes.object,
	onCloseModal: PropTypes.func,
	onDisableMfa: PropTypes.func,
	mfaSetupError: PropTypes.string,
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(ModifyPage));
