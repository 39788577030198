import React from 'react';
import PropTypes from 'prop-types';
import TextInputForm from './common/TextInputForm';

const userFormSettings = [
	{
		fieldName: 'firstName',
		translationPath: 'components.forms.UserInformation.firstName'
	},
	{
		fieldName: 'lastName',
		translationPath: 'components.forms.UserInformation.lastName'
	},
	{
		fieldName: 'email',
		translationPath: 'components.forms.UserInformation.email'
	},
	{
		fieldName: 'phone',
		translationPath: 'components.forms.UserInformation.phone'
	}
];

export const UserInformationForm = (props) => {
	const { user, onUpdate } = props;
	return (
		<TextInputForm
			formStructure={userFormSettings}
			defaultValues={user}
			onSubmit={() => {}}
		/>
	);
};

UserInformationForm.propTypes = {
	user: PropTypes.object,
	onUpdate: PropTypes.func
};

export default UserInformationForm;
