import React from 'react';
import PropTypes from 'prop-types';
import { useTheme, withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import ConfirmDeleteModal from '../../../../components/modals/NotificationModals/ConfirmDeleteModal';
import InitialNotificationEditable from './InitialNotificationEditable';
import InitialNotificationDisplay from './InitialNotificationDisplay';
import getNotificationTypeIcon from '../../../../helpers/notifications/getNotificationTypeIcon';

import Paper from '@mui/material/Paper';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';

const styles = (theme) => ({
	dot: {
		margin: 0
	},
	initialEntry: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	notificationBox: {
		padding: '7px',
		'&::before': {
			content: 'none!important'
		}
	}
});

const InitialNotification = (props) => {
	const {
		classes,
		deleteNotification,
		renderAssigned,
		selected,
		t,
		updateNotification
	} = props;
	const { dot, initialEntry, notificationBox } = classes;

	const theme = useTheme();

	const [isEditable, setIsEditable] = React.useState(false);
	const [updatedTitle, setUpdatedTitle] = React.useState(selected.label);
	const [deleteData, setDeleteData] = React.useState({});

	const handleUpdate = () => {
		updateNotification({
			notificationId: selected.id,
			title: updatedTitle
		});
	};

	const handleSubmit = (parameters) => {
		deleteNotification(parameters);
		setDeleteData({});
	};

	return (
		<Paper elevation={1} sx={{ m: 0.25 }}>
			<TimelineItem className={notificationBox}>
				<TimelineSeparator>
					<TimelineConnector />
					<TimelineDot variant="outlined" className={dot}>
						{getNotificationTypeIcon(theme)}
					</TimelineDot>
					<TimelineConnector />
				</TimelineSeparator>
				<TimelineContent className={initialEntry}>
					{isEditable ? (
						<InitialNotificationEditable
							handleUpdate={handleUpdate}
							label={selected.label}
							setIsEditable={setIsEditable}
							setUpdatedTitle={setUpdatedTitle}
							updatedTitle={updatedTitle}
						/>
					) : (
						<InitialNotificationDisplay
							handleSubmit={handleSubmit}
							renderAssigned={renderAssigned}
							entry={selected}
							setDeleteData={setDeleteData}
							setIsEditable={setIsEditable}
						/>
					)}
				</TimelineContent>
			</TimelineItem>
			<ConfirmDeleteModal
				deleteData={deleteData}
				open={deleteData.label !== undefined}
			/>
		</Paper>
	);
};

InitialNotification.propTypes = {
	classes: PropTypes.object,
	deleteNotification: PropTypes.func,
	renderAssigned: PropTypes.func,
	selected: PropTypes.object,
	t: PropTypes.func,
	updateNotification: PropTypes.func
};

export default withStyles(styles)(withTranslation()(InitialNotification));
