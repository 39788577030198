import React from 'react';
import PropTypes from 'prop-types';

const DebugView = (props) => {
	const { data } = props;

	return <pre>{JSON.stringify(data, null, 2)}</pre>;
};

DebugView.propTypes = {
	data: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

export default DebugView;
