import { getLabel } from '../shared/cardBrandHelper';

export default function (mids) {
	let result = [];
	const midsClone = Array.from(mids);

	if (midsClone) {
		const groupedMidsByCard = {};

		midsClone.map((mid) => {
			const cardType = mid.pymt_obj_type;
			if (!groupedMidsByCard[cardType]) {
				groupedMidsByCard[cardType] = [];
			}

			return groupedMidsByCard[cardType].push(mid);
		});

		result = Object.keys(groupedMidsByCard).map(function (key) {
			return {
				key: getLabel(key),
				data: groupedMidsByCard[key]
			};
		});
	}

	return result;
}
