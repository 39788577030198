import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { modalActions } from '../../../containers/Modal';
import findReportItemInList from '../../../helpers/reporter/findReportItemInList';

import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { recordingsActions } from '../../../containers/Recordings';

const styles = (theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	errorStyle: {
		color: theme.palette.error.main
	},
	modal: {
		display: 'flex',
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center'
	},
	paper: {
		display: 'flex',
		flexDirection: 'column',
		padding: '4%',
		justifyContent: 'center',
		alignItems: 'center',
		'& h6, & audio': {
			margin: '8px'
		}
	},
	root: {
		display: 'flex',
		justifyContent: 'center'
	}
});

const RecordingsModal = (props) => {
	const {
		classes,
		error,
		getRecordingPath,
		hasBeenLoaded,
		isLoading,
		isRecordingsModalOpen,
		path,
		reportDataList,
		reportViewId,
		t
	} = props;
	const { errorStyle, modal, paper } = classes;

	const location = useLocation();
	const history = useHistory();

	const reportData = findReportItemInList(reportDataList, reportViewId);

	const orderNumber = location.pathname.split('/')[4];

	React.useEffect(() => {
		if (!reportData.data) {
			history.push(`/reports/${reportViewId}`);
		}

		getRecordingPath(orderNumber);
	}, [getRecordingPath, orderNumber, path]);

	const onCloseModal = () => {
		const { onClose } = props;
		history.push(`/reports/${reportViewId}`);
		onClose();
	};

	return (
		<Modal
			open={isRecordingsModalOpen}
			onClose={onCloseModal}
			className={modal}
		>
			<Paper className={paper} elevation={1}>
				{isLoading && !hasBeenLoaded ? (
					<CircularProgress />
				) : !isLoading && error ? (
					<Typography className={errorStyle} variant="h6">
						{error}
					</Typography>
				) : (
					<>
						<Typography variant="h6">
							{`${
								orderNumber === 'CustomerService'
									? 'Customer Service Recording'
									: 'Customer Sales Recording'
							} for Order Number: `}
							{(reportData &&
								reportData.data &&
								reportData.data[0] &&
								reportData.data[0].orderId) ||
								null}
						</Typography>
						<audio controls src={path} type="mp3" />
					</>
				)}
			</Paper>
		</Modal>
	);
};

RecordingsModal.propTypes = {
	classes: PropTypes.object,
	error: PropTypes.string,
	getRecordingPath: PropTypes.func,
	hasBeenLoaded: PropTypes.bool,
	isLoading: PropTypes.bool,
	isRecordingsModalOpen: PropTypes.bool,
	onClose: PropTypes.func,
	path: PropTypes.string,
	reportDataList: PropTypes.object,
	reportViewId: PropTypes.string,
	t: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		error: state.recordingsReducer.get('error'),
		hasBeenLoaded: state.recordingsReducer.get('hasBeenLoaded'),
		isLoading: state.recordingsReducer.get('isLoading'),
		isRecordingsModalOpen: state.modalReducer.get('isRecordingsModalOpen'),
		path: state.recordingsReducer.get('recordingPath'),
		reportDataList: state.reporterReducer.get('reportData')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getRecordingPath: (payload) => {
			dispatch(recordingsActions.getTemporaryRecordingDirectory(payload));
		},
		onClose: () => {
			dispatch(modalActions.toggleRecordingsModal());
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(withTranslation()(RecordingsModal)));
