import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { SingleSelectInput, GenericTextInput } from '../../inputs';
import deepCopy from '../../../utilities/deepCopy';

const styles = (theme) => ({
	paperRow: {
		display: 'flex',
		alignSelf: 'stretch',
		marginTop: '15px',
		flexflow: 'row'
	}
});

export const AddTileForm = (props) => {
	const {
		classes,
		tileLabel,
		setTileLabel,
		layouts,
		selectedLayout,
		setSelectedLayout
	} = props;
	const { paperRow } = classes;

	const onUpdateLabel = (event) => {
		setTileLabel(event.target.value);
	};

	const onLayoutUpdate = (selectedLayout) => {
		const newSelectedLayout = layouts.find((layout) => {
			return layout.label === selectedLayout;
		});

		setSelectedLayout(deepCopy(newSelectedLayout));
	};

	return (
		<Fragment>
			{selectedLayout !== undefined && (
				<div className={paperRow}>
					<SingleSelectInput
						label={'layout'}
						onUpdate={onLayoutUpdate}
						selectList={layouts.map((layout) => layout.label)}
						value={selectedLayout.label}
					/>
				</div>
			)}
			{tileLabel !== undefined && (
				<div className={paperRow}>
					<GenericTextInput
						label={'Tile Label'}
						onUpdate={onUpdateLabel}
						value={tileLabel}
					/>
				</div>
			)}
		</Fragment>
	);
};

AddTileForm.propTypes = {
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(AddTileForm));
