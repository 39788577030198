import * as actionTypes from './actionTypes';

export const successMessage = (payload) => ({
	type: actionTypes.SUCCESS_MESSAGE,
	payload
});

export const failureMessage = (payload) => ({
	type: actionTypes.FAILURE_MESSAGE,
	payload
});

export const clearMessage = (payload) => ({
	type: actionTypes.CLEAR_MESSAGE,
	payload
});
