import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';

const styles = (theme) => ({
	listLabel: {
		color: theme.palette.secondary.main
	},
	listLabelMarkedForDeletion: {
		textDecoration: 'line-through',
		color: `${theme.palette.secondary} !important`
	}
});

export const ListItemLabel = (props) => {
	const { classes, label, isMarkedForDeletion } = props;
	const {
		listLabel,
		listLabelMarkedForDeletion
	} = classes;

	return (
		<span className={clsx(
			listLabel,
			isMarkedForDeletion && listLabelMarkedForDeletion
		)}>{label}</span>
	);
};

ListItemLabel.propTypes = {
	label: PropTypes.string,
	isMarkedForDeletion: PropTypes.bool,
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(ListItemLabel));
