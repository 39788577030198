import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import DebugView from './DebugView';

import Paper from '@mui/material/Paper';

const styles = (theme) => ({
	container: {
		display: 'flex',
		padding: '5px 10px 5px 10px',
		flexDirection: 'column',
		alignItems: 'center'
	}
});

const Debug = (props) => {
	const { classes, reportData } = props;
	const { container } = classes;

	return (
		<Paper className={container}>
			<DebugView data={reportData.data} />
		</Paper>
	);
};

Debug.propTypes = {
	reportData: PropTypes.object,
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(Debug));
