import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import SummaryReport from './components/SummaryReport';
import restructureMidsData from '../../../helpers/chargebackSummary/restructureMidsData';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

const styles = (theme) => ({
	root: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: '20px'
	},
	noData: {
		fontSize: '1rem'
	}
});

const ChargebackSummaryView = (props) => {
	const { classes, data, hasBeenLoaded, isLoading, t } = props;
	const { root, noData } = classes;

	return (
		<Container className={root} maxWidth={false}>
			{hasBeenLoaded ? (
				<SummaryReport
					hasBeenLoaded={hasBeenLoaded}
					isLoading={isLoading}
					data={restructureMidsData(data)}
				/>
			) : (
				<Typography className={noData}>
					{t(
						'components.staticReportViews.DailyChargebackReport.TwelveMonthLook.submitToLoad'
					)}
				</Typography>
			)}
		</Container>
	);
};

ChargebackSummaryView.propTypes = {
	classes: PropTypes.object,
	data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	error: PropTypes.string,
	filters: PropTypes.object,
	handleFilterUpdate: PropTypes.func,
	handleSubmit: PropTypes.func,
	hasBeenLoaded: PropTypes.bool,
	isLoading: PropTypes.bool,
	report: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(ChargebackSummaryView));
