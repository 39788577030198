import * as htmlToImage from 'html-to-image';
import { saveAs } from 'file-saver';

export const handleDownload = async (label, element) => {
	const timestamp = new Date(Date.now())
		.toLocaleString()
		.split(',')[0]
		.replace(/\//g, '-');

	const width = element.clientWidth;
	const height = element.clientHeight;

	return new Promise((resolve) => {
		htmlToImage.toCanvas(element, { width, height }).then((canvas) => {
			canvas.toBlob(function (blob) {
				saveAs(blob, `${label}-${timestamp}.png`);
				return resolve();
			});
		});
	});
};
