import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import Button from '@mui/material/Button';

const styles = (theme) => ({
	actionRowContainer: {
		display: 'flex',
		flexFlow: 'row',
		justifyContent: 'flex-end'
	},
	inputButton: {
		borderRadius: '4px',
		color: theme.palette.primary.main,
		padding: '5px 10px 5px 10px',
		fontSize: '1rem',
		fontWeight: '300',
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
			color: '#ffffff',
			cursor: 'pointer'
		}
	}
});

export const ActionRow = (props) => {
	const { classes, t, onClickCreateLayout } = props;
	const {
		actionRowContainer,
		inputButton
	} = classes;

	return (
		<div className={actionRowContainer}>
			<Button
				variant="outlined"
				size="small"
				className={inputButton}
				type="submit"
				onClick={onClickCreateLayout}
			>
				{t('components.modals.ManageDashboard.createLayout')}
			</Button>
		</div>
	);
};

ActionRow.propTypes = {
	layouts: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.array]),
	onClickCreateLayout: PropTypes.func,
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(ActionRow));
