// for notification 1, first view -- id in epic is 12

const dataSourceSettingsNo1Part1 = (dataSource) => {
	return {
		enableSorting: false,
		columns: [{ name: 'WeekEnd' }],
		valueSortSettings: { headerDelimiter: ' - ' },
		values: [
			{ name: 'Billing', caption: 'Billing In $' },
			{ name: 'Fraud', caption: 'Fraud In $' },
			{ name: 'FraudPercent', caption: 'Fraud %' },
			{ name: 'Index', caption: 'Index' }
		],
		dataSource: dataSource,
		rows: [{ name: 'Merchant' }],
		formatSettings: [
			{ name: 'Billing', format: 'C0' },
			{ name: 'Fraud', format: 'C0' },
			{ name: 'FraudPercent', format: '0.##%' },
			{ name: 'Index', format: '0.00' }
		],
		expandAll: false,
		filters: [],
		calculatedFieldSettings: [
			{ name: 'FraudPercent', formula: '"Sum(Fraud)"/"Sum(Billing)"' }
		],
		showRowGrandTotals: false
	};
};

// for notification 1, second view -- id in epic is 10

const dataSourceSettingsNo1Part2 = (dataSource) => {
	return {
		enableSorting: false,
		columns: [{ name: 'MonthEnd' }],
		valueSortSettings: { headerDelimiter: ' - ' },
		values: [
			{ name: 'Billing', caption: 'Billing In $' },
			{ name: 'Fraud', caption: 'Fraud In $' },
			{ name: 'FraudPercent', caption: 'Fraud %' }
		],
		dataSource: dataSource,
		rows: [{ name: 'Merchant' }, { name: 'Affiliate' }, { name: 'Product' }],
		formatSettings: [
			{ name: 'Billing', format: 'C0' },
			{ name: 'Fraud', format: 'C0' },
			{ name: 'FraudPercent', format: '0.##%' }
		],
		expandAll: false,
		filters: [],
		calculatedFieldSettings: [
			{ name: 'FraudPercent', formula: '"Sum(Fraud)"/"Sum(Billing)"' }
		]
	};
};

// for notification 2, whole view -- id in epic is 13

const dataSourceSettingsNo2 = (dataSource) => {
	return {
		enableSorting: false,
		columns: [{ name: 'MonthEnd' }],
		values: [
			{ name: 'Credit', caption: 'Credit' },
			{ name: 'Debit', caption: 'Debit' },
			{ name: 'Prepaid', caption: 'Marginal Bins' },
			{
				name: 'CreditPercent',
				caption: '% Credit',
				type: 'Avg'
			},
			{
				name: 'DebitPercent',
				caption: '% Debit',
				type: 'Avg'
			},
			{
				name: 'PrepaidPercent',
				caption: '% Marginal Bins',
				type: 'Avg'
			}
		],
		dataSource: dataSource,
		rows: [{ name: 'Merchant' }, { name: 'Bank' }, { name: 'BIN' }],
		formatSettings: [
			{ name: 'Credit', format: 'N' },
			{ name: 'Debit', format: 'N' },
			{ name: 'Prepaid', format: 'N' },
			{ name: 'CreditPercent', format: '0.0%' },
			{ name: 'DebitPercent', format: '0.0%' },
			{ name: 'PrepaidPercent', format: '0.0%' }
		],
		expandAll: false,
		emptyCellsTextContent: '-',
		filters: [],
		// showGrandTotals: false,
		calculatedFieldSettings: [
			{
				name: 'CreditPercent',
				formula: '"Sum(Credit)"/"("Sum(Credit)"+"Sum(Debit)"+"Sum(Prepaid)")"'
			},
			{
				name: 'DebitPercent',
				formula: '"Sum(Debit)"/"("Sum(Credit)"+"Sum(Debit)"+"Sum(Prepaid)")"'
			},
			{
				name: 'PrepaidPercent',
				formula: '"Sum(Prepaid)"/"("Sum(Credit)"+"Sum(Debit)"+"Sum(Prepaid)")"'
			}
		]
	};
};

export const dataSourceOrchestration = (id, data) => {
	switch (id) {
		case '10':
			return dataSourceSettingsNo1Part2(data);
		case '11':
			return dataSourceSettingsNo1Part2(data);
		case '12':
			return dataSourceSettingsNo1Part1(data);
		case '13':
			return dataSourceSettingsNo2(data);
		default:
			return null;
	}
};
