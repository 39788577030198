import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import deepCopy from '../../../utilities/deepCopy';

import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';

const styles = (theme) => ({
	container: {
		display: 'flex',
		justifyContent: 'space-around',
		marginTop: '10px',
		maxWidth: '900px'
	}
});

function not(a, b) {
	return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
	return a.filter((value) => b.indexOf(value) !== -1);
}

export const TransferList = (props) => {
	const {
		classes,
		selectedGroup,
		allMerchantsList,
		currentGroupMerchantsList,
		setMerchantsInGroup,
		size
	} = props;
	const { container } = classes;

	const [checked, setChecked] = React.useState([]);

	const leftChecked = intersection(checked, allMerchantsList);
	const rightChecked = intersection(checked, currentGroupMerchantsList);

	const handleToggle = (value) => () => {
		const currentIndex = checked.indexOf(value);
		const newChecked = [...checked];

		if (currentIndex === -1) {
			newChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setChecked(newChecked);
	};

	const handleAllRight = () => {
		setMerchantsInGroup({
			id: selectedGroup.id,
			data: deepCopy(allMerchantsList)
		});
		setChecked([]);
	};

	const handleCheckedRight = () => {
		setMerchantsInGroup({
			id: selectedGroup.id,
			data: deepCopy(currentGroupMerchantsList.concat(leftChecked))
		});
		setChecked([]);
	};

	const handleCheckedLeft = () => {
		setMerchantsInGroup({
			id: selectedGroup.id,
			data: deepCopy(not(currentGroupMerchantsList, rightChecked))
		});
		setChecked([]);
	};

	const handleAllLeft = () => {
		setMerchantsInGroup({
			id: selectedGroup.id,
			data: []
		});
		setChecked([]);
	};

	const customList = (items) => (
		<Paper sx={{ height: size.height * 0.9, width: 300, overflow: 'auto' }}>
			<List dense component="div" role="list">
				{items.map((value) => {
					const labelId = `transfer-list-item-${value}-label`;

					return (
						<ListItem
							key={value}
							role="listitem"
							button
							onClick={handleToggle(value)}
						>
							<ListItemIcon>
								<Checkbox
									checked={checked.indexOf(value) !== -1}
									tabIndex={-1}
									disableRipple
									inputProps={{
										'aria-labelledby': labelId
									}}
								/>
							</ListItemIcon>
							<ListItemText id={labelId} primary={value} />
						</ListItem>
					);
				})}
				<ListItem />
			</List>
		</Paper>
	);

	return (
		<Grid className={container} container spacing={2} justifyContent="center">
			<Grid item>
				<p>All Merchants</p>
				{customList(not(allMerchantsList, currentGroupMerchantsList))}
			</Grid>
			<Grid item style={{ position: 'fixed', marginTop: '54px' }}>
				<Grid container direction="column">
					<Button
						sx={{ my: 0.5 }}
						variant="outlined"
						size="small"
						onClick={handleAllRight}
						disabled={allMerchantsList.length === 0}
						aria-label="move all right"
					>
						≫
					</Button>
					<Button
						sx={{ my: 0.5 }}
						variant="outlined"
						size="small"
						onClick={handleCheckedRight}
						disabled={leftChecked.length === 0}
						aria-label="move selected right"
					>
						&gt;
					</Button>
					<Button
						sx={{ my: 0.5 }}
						variant="outlined"
						size="small"
						onClick={handleCheckedLeft}
						disabled={rightChecked.length === 0}
						aria-label="move selected left"
					>
						&lt;
					</Button>
					<Button
						sx={{ my: 0.5 }}
						variant="outlined"
						size="small"
						onClick={handleAllLeft}
						disabled={currentGroupMerchantsList.length === 0}
						aria-label="move all left"
					>
						≪
					</Button>
				</Grid>
			</Grid>
			<Grid item>
				<p>In {selectedGroup.label} Group</p>
				{customList(currentGroupMerchantsList)}
			</Grid>
		</Grid>
	);
};

TransferList.propTypes = {
	selectedGroup: PropTypes.object,
	allMerchantsList: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	currentGroupMerchantsList: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.array
	]),
	setMerchantsInGroup: PropTypes.func,
	classes: PropTypes.object,
	size: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(TransferList));
