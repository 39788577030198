import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { authActions } from '../../containers/Auth';
import PasswordValidationInput from '../../validation/inputs/passwordValidationInput';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

const styles = (theme) => ({
	row: {
		display: 'flex',
		flexFlow: 'row'
	},
	instructionText: {
		padding: '5px'
	},
	flex: {
		flex: 1
	},
	authCodeContainer: {
		alignContent: 'center',
		alignItems: 'center',
		margin: '7px'
	},
	codeField: {
		marginLeft: '20px'
	},
	actionRow: {
		display: 'flex',
		alignSelf: 'stretch',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		marginTop: '10px'
	},
	formContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	inputButton: {
		marginTop: '5px',
		borderRadius: '4px',
		width: '150px',
		border: 'none',
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.secondary.alt,
		padding: '5px',
		fontSize: '16px',
		fontWeight: '300',
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
			cursor: 'pointer'
		}
	}
});

const NewPasswordRequiredChallenge = (props) => {
	const {
		classes,
		t,
		authUser,
		userHasBeenLoaded,
		hasChangedPassword,
		isLoadingUser,
		isChangingPassword
	} = props;
	const {
		row,
		instructionText,
		actionRow,
		inputButton,
		formContainer,
		authCodeContainer
	} = classes;

	const [temporaryPassword, setTemporaryPassword] = React.useState('');
	const [newUserPassword, setNewUserPassword] = React.useState('');
	const [confirmNewPassword, setConfirmNewPassword] = React.useState('');

	const history = useHistory();

	const onVerifyNewPassword = (event) => {
		const { completeNewPassword } = props;
		event.preventDefault();

		completeNewPassword({
			user: authUser,
			newPassword: newUserPassword
		});
	};

	React.useEffect(() => {
		if (hasChangedPassword && userHasBeenLoaded) {
			history.push('/dashboard');
		}
	}, [hasChangedPassword, userHasBeenLoaded, history]);

	return (
		<Fragment>
			<div className={row}>
				<Typography className={instructionText} variant="h6">
					{t('pages.NewPassword.header')}
				</Typography>
			</div>
			{isLoadingUser || isChangingPassword ? (
				<CircularProgress />
			) : (
				<form onSubmit={onVerifyNewPassword} className={formContainer}>
					<div className={authCodeContainer}>
						<PasswordValidationInput
							label={t('pages.NewPassword.temporaryPassword')}
							onChange={setTemporaryPassword}
							value={temporaryPassword}
						/>
					</div>
					<div className={authCodeContainer}>
						<PasswordValidationInput
							label={t('pages.NewPassword.newPassword')}
							onChange={setNewUserPassword}
							value={newUserPassword}
						/>
					</div>
					<div className={authCodeContainer}>
						<PasswordValidationInput
							label={t('pages.NewPassword.confirm')}
							onChange={setConfirmNewPassword}
							value={confirmNewPassword}
						/>
					</div>
					<div className={actionRow}>
						<Button
							variant="contained"
							size="small"
							type="submit"
							className={inputButton}
							disabled={newUserPassword !== confirmNewPassword}
						>
							{t('generic.buttons.submit')}
						</Button>
					</div>
				</form>
			)}
		</Fragment>
	);
};

NewPasswordRequiredChallenge.propTypes = {
	authUser: PropTypes.object,
	classes: PropTypes.object,
	completeNewPassword: PropTypes.func,
	hasChangedPassword: PropTypes.bool,
	isChangingPassword: PropTypes.bool,
	isLoadingUser: PropTypes.bool,
	t: PropTypes.func,
	userHasBeenLoaded: PropTypes.bool
};

const mapStateToProps = (state) => {
	return {
		authUser: state.authReducer.get('user'),
		hasChangedPassword: state.authReducer.get('hasChangedPassword'),
		isChangingPassword: state.authReducer.get('isChangingPassword'),
		isLoadingUser: state.userReducer.get('isLoadingUser'),
		logInError: state.authReducer.get('logInError'),
		userHasBeenLoaded: state.userReducer.get('userHasBeenLoaded')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		completeNewPassword: (payload) => {
			dispatch(authActions.completeNewPassword(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(withTranslation()(NewPasswordRequiredChallenge)));
