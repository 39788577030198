export default {
	CARD_TYPES: {
		uo_amex: 'Amex',
		uo_discover: 'Discover',
		uo_mastercard: 'MasterCard',
		uo_visa: 'Visa'
	},
	PROCESSORS: {
		BAMS: 'Fiserv',
		BLSNP_PRI: 'BlueSnap Privat',
		BLSNP_SAN: 'BlueSnap Santander',
		PYSF_MERRI: 'Merrick',
		IPPAY_WAM: 'IPpay'
	},
	BANK_NAMES: {
		capitalOne: 'Capital One',
		jPMorgan: 'JPMorgan Chase',
		citi: 'Citi Bank',
		barclays: 'Barclays Bank',
		pnc: 'PNC Bank',
		republicLimited: 'Republic Bank Limited'
	},
	// TODO: DELETE THIS
	PROCESSORS_AND_MIDS: {
		BAMS: [
			'345045100889',
			'345045101887',
			'345045102885',
			'345045103883',
			'345045104881',
			'345045105888',
			'345045106886',
			'345045107884',
			'345045108882',
			'345045109880',
			'345045110888',
			'345045111886',
			'345045112884',
			'345045113882',
			'345045114880',
			'345045115887',
			'345045116885',
			'345045117883',
			'345045118881',
			'345045119889',
			'345045120887',
			'345045122883',
			'345045123881',
			'345045124889',
			'345045125886',
			'345045126884',
			'345045127882',
			'345045128880',
			'345045129888'
		],
		BLSNP_PRI: [
			'1091389',
			'1091391',
			'1091393',
			'1091395',
			'1091397',
			'1091399'
		],
		BLSNP_SAN: [
			'1094273',
			'1094275',
			'1094277',
			'1094279',
			'1094281',
			'1094283',
			'1094285',
			'1212708',
			'1221620'
		],
		IPPAY_WAM: ['92860206141717', '92860206639181'],
		PYSF_MERRI: [
			'99911751',
			'99911752',
			'99911753',
			'99911754',
			'99911755',
			'99911757',
			'99911758',
			'99911759',
			'99911760',
			'99911761',
			'99911762',
			'99911763',
			'99911766',
			'99911767',
			'99911768',
			'99911769',
			'99911770',
			'99911771',
			'99911772',
			'99911773',
			'99911774',
			'99911775',
			'99911776',
			'99911777',
			'99911778',
			'99911779',
			'99911780',
			'99911784'
		]
	}
};
