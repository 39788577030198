import moment from 'moment';
import { getLabel } from '../../shared/cardBrandHelper';

const _groupDataForChargebackDetail = (data) => {
	let groupedByDate = {};

	data.reduce(function (result, item) {
		if (!result[item.CHB_DATE]) {
			result[item.CHB_DATE] = {
				Amex: 0,
				Discover: 0,
				Visa: 0,
				MasterCard: 0
			};
		}

		result[item.CHB_DATE][getLabel(item.card_type)] += 1;

		return result;
	}, groupedByDate);

	const results = [];

	Object.entries(groupedByDate).forEach(([key, value]) => {
		results.push({
			date: moment(key).format('YYYY-MM-DD'),
			...value
		});
	});

	return results;
};

export default function (data, reportViewId) {
	let groupedData = {};

	// HACK - Handle Line Chart Grouping for Chargeback Detail
	if (reportViewId === '064de551-43a8-40a0-8663-f9b8bb9ef8d4') {
		groupedData = _groupDataForChargebackDetail(data);
	}

	return groupedData;
}
