export default function (data, fillColors) {
	const mapped = [];

	if (data) {
		mapped.push(
			{
				key: 'chargebackAmount',
				fillColor: fillColors.chargebackAmount,
				nameTranslationPath:
					'components.RecoveryHistory.RecoveryHistoryManager.missesPlusPending',
				value: data.firstChargebackAmount
			},
			{
				name: 'receoveryAmount',
				fillColor: fillColors.receoveryAmount,
				nameTranslationPath:
					'components.RecoveryHistory.RecoveryHistoryManager.wins',
				value: data.recoveryAmount
			}
		);
	}

	return mapped;
}
