import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import { recoveryHistoryActions } from '../../../containers/RecoveryHistory';
import generateDateRange from '../../../helpers/shared/generateDateRange';
import querier from '../../../utilities/querier';

import HeaderRow from './HeaderRow';
import NoData from '../NoData';
import QuickGlance from './QuickGlance';
import StatBar from './StatBar';
import StatBarLegend from './StatBarLegend';

const styles = (theme) => ({
	container: {
		display: 'flex',
		flexFlow: 'column',
		borderRadius: '5px',
		width: '100%',
		border: `1px solid ${theme.palette.recoveryHistory.border}`,
		backgroundColor: theme.palette.common.white,
		padding: '5px 10px'
	},
	statRow: {
		display: 'flex',
		flexDirection: 'row'
	},
	baseLegendItem: {
		width: 20,
		height: 20,
		borderRadius: '5px'
	},
	pendingLegend: {
		backgroundColor: theme.palette.recoveryHistory.pending
	},
	missesLegend: {
		backgroundColor: theme.palette.recoveryHistory.misses
	},
	winsLegend: {
		backgroundColor: theme.palette.recoveryHistory.wins
	}
});

export const BreakdownByWinsMisses = (props) => {
	const {
		classes,
		dateRange,
		getHistoryByOutcome,
		data,
		isLoading,
		hasBeenLoaded
	} = props;
	const { container } = classes;

	React.useEffect(() => {
		const formattedDateRange = generateDateRange(dateRange);

		getHistoryByOutcome(formattedDateRange);
	}, [dateRange, getHistoryByOutcome]);

	return (
		<div className={container}>
			<HeaderRow totalValue={querier(data, 'total.value')} />
			<NoData data={data} isLoading={isLoading} hasBeenLoaded={hasBeenLoaded} />
			<QuickGlance
				data={data}
				isLoading={isLoading}
				hasBeenLoaded={hasBeenLoaded}
			/>
			<StatBar
				data={data}
				isLoading={isLoading}
				hasBeenLoaded={hasBeenLoaded}
			/>
			<StatBarLegend data={data} />
		</div>
	);
};

BreakdownByWinsMisses.propTypes = {
	data: PropTypes.object,
	isLoading: PropTypes.bool,
	error: PropTypes.string,
	hasBeenLoaded: PropTypes.bool,
	dateRange: PropTypes.string,
	getHistoryByOutcome: PropTypes.func,
	classes: PropTypes.object,
	t: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		data: state.recoveryHistoryReducer.getIn(['historyByOutcome', 'data']),
		isLoading: state.recoveryHistoryReducer.getIn([
			'historyByOutcome',
			'isLoading'
		]),
		hasBeenLoaded: state.recoveryHistoryReducer.getIn([
			'historyByOutcome',
			'hasBeenLoaded'
		]),
		error: state.recoveryHistoryReducer.getIn(['historyByOutcome', 'error'])
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getHistoryByOutcome: (payload) => {
			dispatch(recoveryHistoryActions.getRecoveryHistoryByOutcome(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(BreakdownByWinsMisses));
