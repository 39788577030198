import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';

import EditIcon from './EditIcon';
import DeletedIcon from './DeleteIcon';
import ListItemLabel from './ListItemLabel';

import DehazeIcon from '@mui/icons-material/Dehaze';

const ITEMTYPE = 'LayoutListItem';

const styles = (theme) => ({
	listItem: {
		display: 'flex',
		flexFlow: 'row',
		cursor: 'pointer'
	},
	listItemDragging: {
		border: `2px dotted ${theme.palette.primary.main}`,
		opacity: '0.5'
	},
	listIcon: {
		padding: '0px 10px 0px 5px',
		fontSize: '.25rem',
		color: theme.palette.icons.disabled
	},
	iconContainer: {
		display: 'flex',
		flexDirection: 'row',
		flex: '1',
		justifyContent: 'flex-end'
	}
});

export const LayoutListItem = (props) => {
	const {
		classes,
		index,
		uuid,
		label,
		onEdit,
		onMarkForDeletion,
		onRestore,
		isMarkedForDeletion
	} = props;
	const { listItem, listItemDragging, listIcon, iconContainer } = classes;

	return (
		<div className={clsx(listItem)}>
			<span className={listIcon}>
				<DehazeIcon />
			</span>
			<ListItemLabel label={label} isMarkedForDeletion={isMarkedForDeletion} />
			<div className={iconContainer}>
				<EditIcon
					uuid={uuid}
					isMarkedForDeletion={isMarkedForDeletion}
					onEdit={onEdit}
				/>
				<DeletedIcon
					uuid={uuid}
					isMarkedForDeletion={isMarkedForDeletion}
					onMarkForDeletion={onMarkForDeletion}
					onRestore={onRestore}
				/>
			</div>
		</div>
	);
};

LayoutListItem.propTypes = {
	uuid: PropTypes.string,
	label: PropTypes.string,
	index: PropTypes.number,
	moveLayoutItem: PropTypes.func,
	isMarkedForDeletion: PropTypes.bool,
	onDelete: PropTypes.func,
	onEdit: PropTypes.func,
	onMarkForDeletion: PropTypes.func,
	onRestore: PropTypes.func,
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(LayoutListItem);
