import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withSize } from 'react-sizeme';
import clsx from 'clsx';
import getTileListForLayout from '../../../helpers/tiles/getTileListForLayout';
import querier from '../../../utilities/querier';

import organizeLayoutTilesForRender from '../../../helpers/dashboard/dashboardTileManager/organizeLayoutTilesForRender';
import shouldDisplaySkeleton from '../../../helpers/dashboard/dashboardTileManager/shouldDisplaySkeleton';

import DashboardSkeleton from './DashboardSkeleton';
import BaseTile from './BaseTile';

import Stack from '@mui/material/Stack';
import { tilesActions } from '../../../containers/Tiles';

const styles = (theme) => ({
	stackContainer: {
		flex: 1,
		margin: 0,
		padding: '5px'
	},
	stackRow: {
		display: 'flex',
		flex: 1,
		alignSelf: 'stretch',
		justifyContent: 'flex-start',
		alignItems: 'center',
		minHeight: '300px',
		maxHeight: '450px'
	}
});

export const DashboardTileManager = (props) => {
	const {
		classes,
		size,
		activeDashboardLayout,
		layoutsHaveBeenLoaded,
		layoutTiles,
		getTilesForLayout
	} = props;
	const { stackContainer, stackRow } = classes;

	const currentLayoutTiles = getTileListForLayout(
		layoutTiles,
		querier(activeDashboardLayout, 'universalUniqueIdentifier')
	);

	React.useEffect(() => {
		if (layoutsHaveBeenLoaded && activeDashboardLayout) {
			if (!currentLayoutTiles) {
				getTilesForLayout({
					layoutUniversalUniqueIdentifier:
						activeDashboardLayout.universalUniqueIdentifier
				});
			}
		}
	}, [
		activeDashboardLayout,
		currentLayoutTiles,
		layoutsHaveBeenLoaded,
		getTilesForLayout
	]);

	const organizedTiles = organizeLayoutTilesForRender(
		currentLayoutTiles,
		size.width
	);

	return (
		<Fragment>
			{shouldDisplaySkeleton(currentLayoutTiles) ? (
				<DashboardSkeleton />
			) : (
				<Stack
					direction="column"
					spacing={2}
					justifyContent="flex-start"
					alignItems="center"
					className={stackContainer}
				>
					{organizedTiles.length > 0 ? (
						organizedTiles.map((row, index) => {
							return (
								<Stack
									key={`row-${index}`}
									direction="row"
									spacing={2}
									justifyContent="flex-start"
									alignItems="center"
									className={stackRow}
								>
									{row.map((tile) => {
										return (
											<BaseTile
												key={tile.universalUniqueIdentifier}
												tile={tile}
											/>
										);
									})}
								</Stack>
							);
						})
					) : (
						<>No Tiles In Layout</>
					)}
				</Stack>
			)}
		</Fragment>
	);
};

DashboardTileManager.propTypes = {
	layoutTiles: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	layoutsHaveBeenLoaded: PropTypes.bool,
	activeDashboardLayout: PropTypes.object,
	getTilesForLayout: PropTypes.func,
	classes: PropTypes.object
};

const mapStateToProps = (state) => {
	return {
		layoutTiles: state.tilesReducer.get('layoutTiles'),
		layoutsHaveBeenLoaded: state.layoutsReducer.get('hasBeenLoaded'),
		activeDashboardLayout: state.dashboardReducer.get('activeDashboardLayout')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getTilesForLayout: (payload) => {
			dispatch(tilesActions.getTilesForLayout(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(withSize()(DashboardTileManager)));
