export default [
	{
		id: 1,
		label: 'High Risk Merchants'
	},
	{
		id: 2,
		label: 'Newly Onboarded Merchants'
	},
	{
		id: 3,
		label: 'Merchants With Current Violations'
	},
	{
		id: 4,
		label: 'Merchants With Former Violations'
	}
];
