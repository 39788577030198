import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@mui/styles';
import { groupManagementActions } from '../../containers/GroupManagement';
import GroupsList from './GroupsList';
import ManagementArea from './ManagementArea/ManagementArea';

const styles = (theme) => ({
	container: {
		display: 'flex',
		alignSelf: 'stretch',
		flexFlow: 'row',
		flex: 1
	}
});

export const GroupManager = (props) => {
	const {
		classes,
		t,
		groupsList,
		groupsAreLoading,
		groupsHaveBeenLoaded,
		getGroups
	} = props;
	const { container } = classes;

	const [selectedGroup, setSelectedGroup] = React.useState({});

	React.useEffect(() => {
		if (!groupsAreLoading && !groupsHaveBeenLoaded) {
			getGroups();
		}
	}, [getGroups, groupsAreLoading, groupsHaveBeenLoaded]);

	React.useEffect(() => {
		if (groupsHaveBeenLoaded && groupsList.length > 0 && !selectedGroup.id) {
			setSelectedGroup(groupsList[0]);
		}
	}, [groupsList, groupsHaveBeenLoaded, selectedGroup]);

	return (
		<div className={container}>
			<GroupsList
				groupsList={groupsList}
				groupsAreLoading={groupsAreLoading}
				groupsHaveBeenLoaded={groupsHaveBeenLoaded}
				selectedGroup={selectedGroup}
				onClickGroup={setSelectedGroup}
			/>
			<ManagementArea selectedGroup={selectedGroup} />
		</div>
	);
};

GroupManager.propTypes = {
	groupsList: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	groupsAreLoading: PropTypes.bool,
	groupsHaveBeenLoaded: PropTypes.bool,
	getGroups: PropTypes.func,
	classes: PropTypes.object,
	t: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		groupsList: state.groupManagementReducer.getIn(['groups', 'data']),
		groupsAreLoading: state.groupManagementReducer.getIn([
			'groups',
			'isLoading'
		]),
		groupsHaveBeenLoaded: state.groupManagementReducer.getIn([
			'groups',
			'hasBeenLoaded'
		])
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getGroups: (payload) => {
			dispatch(groupManagementActions.getGroups(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(withTranslation()(GroupManager)));
