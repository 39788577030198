import moment from 'moment';
import { getLabel } from '../../shared/cardBrandHelper';

export default function (data) {
	let groupedByDate = {};

	data.reduce(function (result, item) {
		if (!result[item.CHB_DATE]) {
			result[item.CHB_DATE] = {
				Amex: 0,
				Discover: 0,
				Visa: 0,
				MasterCard: 0
			};
		}

		result[item.CHB_DATE][getLabel(item.card_type)] += 1;

		return result;
	}, groupedByDate);

	const results = [];

	Object.entries(groupedByDate).forEach(([key, value]) => {
		results.push({
			date: moment(key).format('YYYY-MM-DD'),
			...value
		});
	});

	return {
		lineKeys: ['Amex', 'Discover', 'MasterCard', 'Visa'],
		results
	};
}
