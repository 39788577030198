export default function (
	isModifyingLayouts,
	layoutTilesBeingDeleted,
	layoutsBeingDeleted
) {
	let result = false;

	if (isModifyingLayouts) {
		result = true;
	} else if (layoutTilesBeingDeleted && layoutTilesBeingDeleted.length > 0) {
		result = true;
	} else if (layoutsBeingDeleted && layoutsBeingDeleted.length > 0) {
		result = true;
	}

	return result;
}
