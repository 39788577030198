import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import roundToTwoDecimalPlaces from '../../../helpers/shared/roundToTwoDecimalPlaces';

import Box from '@mui/material/Box';
import ToolTip from '@mui/material/Tooltip';

const styles = (theme) => ({
	baseSection: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		height: '100%'
	},
	roundedLeft: {
		borderTopLeftRadius: '5px',
		borderBottomLeftRadius: '5px'
	},
	roundedRight: {
		borderTopRightRadius: '5px',
		borderBottomRightRadius: '5px'
	},
	pendingSection: {
		backgroundColor: theme.palette.recoveryHistory.pending
	},
	missesSection: {
		backgroundColor: theme.palette.recoveryHistory.misses
	},
	winsSection: {
		backgroundColor: theme.palette.recoveryHistory.wins
	}
});

export const StatBarFragment = (props) => {
	const {
		classes,
		sectionKey,
		sectionValue,
		isRoundedLeft,
		isRoundedRight
	} = props;
	const {
		baseSection,
		roundedLeft,
		roundedRight,
		pendingSection,
		missesSection,
		winsSection
	} = classes;

	return (
		<Fragment>
			<ToolTip title={`${roundToTwoDecimalPlaces(sectionValue)}%`}>
				<Box
					componet="span"
					className={clsx(
						baseSection,
						sectionKey === 'misses' && missesSection,
						sectionKey === 'pending' && pendingSection,
						sectionKey === 'wins' && winsSection,
						isRoundedLeft && roundedLeft,
						isRoundedRight && roundedRight
					)}
					sx={{ width: `${sectionValue}%` }}
				/>
			</ToolTip>
		</Fragment>
	);
};

StatBarFragment.propTypes = {
	sectionKey: PropTypes.string,
	sectionValue: PropTypes.number,
	isRoundedLeft: PropTypes.bool,
	isRoundedRight: PropTypes.bool,
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(StatBarFragment);
