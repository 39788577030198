export default [
	{
		id: '40642d65-a2e9-4e43-a925-33be0a27e9e5',
		label: 'Daily Chargeback Report',
		reportIds: [
			{
				id: '7',
				label: 'chargebackForecast'
			},
			{
				id: '8',
				label: 'dailyChargebacks'
			}
		],
		suggestedDisplayTypes: [],
		allowAddToDashboard: false
	},
	{
		id: '064de551-43a8-40a0-8663-f9b8bb9ef8d4',
		label: 'Chargeback Detail',
		isConfigurable: true,
		reportIds: [
			{
				id: '2',
				label: 'chargebackDetail'
			}
		],
		suggestedDisplayTypes: ['table', 'line'],
		allowAddToDashboard: true
	},
	{
		id: '38dd722f-bdd3-463c-932e-0800cbcac0c5',
		label: 'Chargeback Ratios',
		isConfigurable: true,
		reportIds: [
			{
				id: '4',
				label: 'chargebackRatios'
			}
		],
		suggestedDisplayTypes: ['pie'],
		allowAddToDashboard: true
	},
	{
		id: '095758ec-a12a-4a61-b76c-ceb3360cc3cf',
		label: 'Chargebacks for Yesterday',
		isConfigurable: true,
		reportIds: [
			{
				id: '5',
				label: 'chargebacksForYesterday'
			}
		],
		suggestedDisplayTypes: ['table'],
		allowAddToDashboard: true
	},
	{
		id: 'ee60d3ce-fcc9-49a6-9c50-3d790fd037c6',
		label: 'Fourteen Day Chargebacks',
		isConfigurable: true,
		reportIds: [
			{
				id: '6',
				label: 'fourteenDayChargebacks'
			}
		],
		suggestedDisplayTypes: ['table'],
		allowAddToDashboard: true
	},
	{
		id: 'b8b804d8-e685-410c-bb7f-f0d03e531459',
		label: 'Chargeback Itemization',
		reportIds: [
			{
				id: '9',
				label: 'chargebackItemization'
			}
		],
		suggestedDisplayTypes: [],
		allowAddToDashboard: false
	},
	{
		id: '576794ac-2cc2-4ab8-ab88-2d6fca0fa20d',
		label: 'Chargeback Summary',
		reportIds: [
			{
				id: '1',
				label: 'dailyChargebackSummaryByDateAndProcessor'
			}
		],
		suggestedDisplayTypes: [],
		allowAddToDashboard: true
	},
	{
		id: 'y66fh62-j22j-410a-h6d3-f052sdbb092',
		label: 'Pivot Table',
		reportIds: [
			{
				id: '10',
				label: 'pivotTable'
			}
		],
		suggestedDisplayTypes: [],
		allowAddToDashboard: true
	},

	// Mock data for demo
	{
		id: 'c4d62e9f-414e-42ea-a530-0a0c5e278cf3',
		label: 'Mitigation Detail',
		reportIds: [
			{
				id: '101',
				label: 'mitigationDetail'
			}
		],
		suggestedDisplayTypes: ['table', 'line'],
		allowAddToDashboard: true
	},
	{
		id: '2ddc3abf-cdf3-4de7-a018-ebde0885d8a4',
		label: 'Mitigation Ratios',
		reportIds: [
			{
				id: '102',
				label: 'mitigationRatios'
			}
		],
		suggestedDisplayTypes: [],
		allowAddToDashboard: true
	},
	{
		id: '4f26e113-d7ba-4ed1-8a81-cc8436d6e585',
		label: 'Mitigation Itemization',
		reportIds: [
			{
				id: '103',
				label: 'mitigationItemization'
			}
		],
		suggestedDisplayTypes: [],
		allowAddToDashboard: true
	},
	{
		id: '82d6d5ae-245e-4b1d-a4da-84d8d75876ba',
		label: 'Mitigation Summary',
		reportIds: [
			{
				id: '104',
				label: 'mitigationSummary'
			}
		],
		suggestedDisplayTypes: [],
		allowAddToDashboard: false
	},
	{
		id: '5d9051cc-8393-46fe-ada3-84ef67b9a672',
		label: 'Mitigation Leakage',
		reportIds: [
			{
				id: '105',
				label: 'mitigationLeakage'
			}
		],
		suggestedDisplayTypes: ['pie'],
		allowAddToDashboard: false
	},
	{
		id: '78e8ca8c-563b-483d-ae5f-3840634e2c8d',
		label: 'Dispute Ratios',
		reportIds: [
			{
				id: '106',
				label: 'disputeRatios'
			}
		],
		suggestedDisplayTypes: [],
		allowAddToDashboard: true
	},
	{
		id: '5216c89f-4fe4-42ad-99c5-d6f46e18b37e',
		label: 'Disputes For Yesterday',
		reportIds: [
			{
				id: '107',
				label: 'disputesForYesterday'
			}
		],
		suggestedDisplayTypes: ['table', 'line'],
		allowAddToDashboard: true
	},
	{
		id: '79827be8-f7bf-4b45-a3db-0f7e89d7b389',
		label: 'Authorization Rate Table',
		reportIds: [
			{
				id: '108',
				label: 'authorizationRateTable'
			}
		],
		suggestedDisplayTypes: [],
		allowAddToDashboard: true
	},
	{
		id: '4e345a47-5a1c-41cb-931b-b7fc837fdf6f',
		label: 'Settlement Rate Trailing 30-days',
		reportIds: [
			{
				id: '109',
				label: 'settlementRateTrailing30Days'
			}
		],
		suggestedDisplayTypes: [],
		allowAddToDashboard: true
	},
	{
		id: '0f7562e4-72c6-4acb-98eb-618d65a662db',
		label: 'Authorization Decline Ratios by decline reason',
		reportIds: [
			{
				id: '110',
				label: 'authorizationDeclineRatiosByDeclineReason'
			}
		],
		suggestedDisplayTypes: ['pie', 'line'],
		allowAddToDashboard: false
	},
	{
		id: '9da35f2b-1dd0-4a43-b246-f2909c499781',
		label: 'Fraud Detail',
		reportIds: [
			{
				id: '111',
				label: 'fraudDetail'
			}
		],
		suggestedDisplayTypes: ['line'],
		allowAddToDashboard: true
	},
	{
		id: 'eb4ce204-9d5c-4341-96d3-fb96be863115',
		label: 'Weekly Fraud Index Report',
		reportIds: [
			{
				id: '112',
				label: 'weeklyFraudIndexReport'
			}
		],
		suggestedDisplayTypes: [],
		allowAddToDashboard: true
	},
	{
		id: '54f32f92-72cd-4eed-b776-9c815cca0ca8',
		label: 'Fraud Ratios',
		reportIds: [
			{
				id: '113',
				label: 'fraudRatios'
			}
		],
		suggestedDisplayTypes: [],
		allowAddToDashboard: true
	},
	{
		id: 'ff949a0d-d726-4dc4-adf9-c02d0e2562c8',
		label: 'Fraud Itemization',
		reportIds: [
			{
				id: '114',
				label: 'fraudItemization'
			}
		],
		suggestedDisplayTypes: ['table', 'line'],
		allowAddToDashboard: false
	},
	{
		id: '366d04ba-751c-42c3-9f9b-386143b90c7c',
		label: 'Fraud for Yesterday',
		reportIds: [
			{
				id: '115',
				label: 'fraudForYesterday'
			}
		],
		suggestedDisplayTypes: ['table', 'pie'],
		allowAddToDashboard: true
	},
	{
		id: '995ed44d-35e4-4488-a57b-a002386435c5',
		label: 'Fraud Rates YTD',
		reportIds: [
			{
				id: '116',
				label: 'fraudRatesYTD'
			}
		],
		suggestedDisplayTypes: [],
		allowAddToDashboard: true
	}
];

// TODO - IMPLEMENT LOGIC FOR THE FOLLOWING
/*
	{
		id: '1ff26375-53a2-4b12-b2ae-85b0e326611b',
		label: 'Chargeback Rates YTD',
		isConfigurable: true,
		reportIds: [
			{
				id: '3',
				label: 'chargebackRatesYTD'
			}
		],
		suggestedDisplayTypes: []
	},
		{
		id: 'af35b3f1-d5ff-43dd-86d1-d4ee2e2c5f44',
		label: 'Chargeback Forecast',
		isConfigurable: true,
		reportIds: [
			{
				id: '7',
				label: 'chargebackForecast'
			}
		],
		suggestedDisplayTypes: []
	},
*/
