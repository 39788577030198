import { fromJS } from 'immutable';
import * as actionTypes from './actionTypes';
import handleDynamicGroupLists from '../../helpers/groupManagement/handleDynamicGroupLists';

export const initialState = fromJS({
	groups: {
		data: [],
		isLoading: false,
		hasBeenLoaded: false,
		error: undefined
	},
	merchantList: {
		data: [],
		isLoading: false,
		hasBeenLoaded: false,
		error: undefined
	},
	groupMerchants: {},
	groupNotificationRules: {},
	groupNotificationRuleBeingEdited: undefined
});

export const groupManagementReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_GROUPS:
			return state.setIn(['groups', 'isLoading'], true);

		case actionTypes.GET_GROUPS_COMPLETED:
			return state
				.setIn(['groups', 'isLoading'], false)
				.setIn(['groups', 'hasBeenLoaded'], true)
				.setIn(['groups', 'data'], action.payload);

		case actionTypes.GET_GROUPS_FAILED:
			return state
				.setIn(['groups', 'isLoading'], false)
				.setIn(['groups', 'error'], action.payload);

		case actionTypes.GET_MERCHANT_LIST:
			return state.setIn(['merchantList', 'isLoading'], true);

		case actionTypes.GET_MERCHANT_LIST_COMPLETED:
			return state
				.setIn(['merchantList', 'isLoading'], false)
				.setIn(['merchantList', 'hasBeenLoaded'], true)
				.setIn(['merchantList', 'data'], action.payload);

		case actionTypes.GET_MERCHANT_LIST_FAILED:
			return state
				.setIn(['merchantList', 'isLoading'], false)
				.setIn(['merchantList', 'error'], action.payload);

		case actionTypes.GET_MERCHANTS_IN_GROUP:
			return handleDynamicGroupLists(state, action, 'groupMerchants');

		case actionTypes.GET_MERCHANTS_IN_GROUP_COMPLETED:
			return handleDynamicGroupLists(state, action, 'groupMerchants');

		case actionTypes.GET_MERCHANTS_IN_GROUP_FAILED:
			return handleDynamicGroupLists(state, action, 'groupMerchants');

		case actionTypes.SET_MERCHANTS_IN_GROUP:
			return handleDynamicGroupLists(state, action, 'groupMerchants');

		case actionTypes.GET_GROUP_NOTIFICATION_RULES:
			return handleDynamicGroupLists(state, action, 'groupNotificationRules');

		case actionTypes.GET_GROUP_NOTIFICATION_RULES_COMPLETED:
			return handleDynamicGroupLists(state, action, 'groupNotificationRules');

		case actionTypes.GET_GROUP_NOTIFICATION_RULES_FAILED:
			return handleDynamicGroupLists(state, action, 'groupNotificationRules');

		case actionTypes.SET_GROUP_NOTIFICATION_RULE_BEING_EDITED:
			return state.set('groupNotificationRuleBeingEdited', action.payload);

		default:
			return state;
	}
};
