import { createTheme } from '@mui/material/styles';
import { creditCardColorCodes } from './colorCodes/creditCards';

const branding = {
	main1: '#51284F',
	main2: '#005776',
	secondary1: '#C1C6C8',
	secondary2: '#63666A'
};

const darkTheme = {
	panel: {
		height: '3.25rem',
		width: '230px'
	},
	navigationDrawer: {
		hidden: {
			height: '.1rem'
		},
		hover: {
			height: '.15rem'
		},
		open: {
			height: '6rem'
		}
	},
	header: {
		height: '3.50rem'
	},
	typography: {
		fontFamily: ['Montserrat', 'sans-serif'].join(',')
	},
	palette: {
		type: 'dark',
		background: {
			default: '#ffffff'
		},
		common: {
			black: '#000',
			white: '#fff'
		},
		error: {
			main: '#BF0000'
		},
		icons: {
			default: branding.secondary1,
			selected: branding.main1
		},
		navigation: {
			background: '#878a8c'
		},
		navigationDrawer: {
			background: '#a9a9a9'
		},
		primary: {
			main: branding.main1,
			alt: branding.main2
		},
		secondary: {
			main: branding.secondary1,
			alt: branding.secondary2
		},
		success: {
			main: '#74B72E'
		},
		warning: {
			main: '#B79906'
		},
		creditCards: {
			...creditCardColorCodes
		}
	}
};

export default createTheme(darkTheme);
