export default {
	visa: {
		'104 Allocation - Other Fraud Card Absent Environment': {
			count: 60,
			firstChargebackAmount: 1650,
			secondChargebackAmount: 0,
			chargebackRecoveryAmount: 28,
			successRate: 2,
			cardTypes: [],
			data: [
				{
					cardType: 'visa',
					processor: 'PYSF_MERRI',
					firstChargebackAmount: 1650,
					firstChargebackCount: 60,
					representmentAmount: 27.95,
					representmentCount: 1,
					secondChargebackAmount: 0,
					secondChargebackCount: 0,
					refundAmount: 189.65,
					refundCount: 7,
					chargebackRecoveryAmount: -161.70000000000002,
					successRate: -0.098,
					chargebackRecoveryNoRefundsAmount: 27.95,
					successRateNoRefunds: 0.01913924744068203,
					finalSuccessNoRefunds: 0.016939393939393938,
					reason: '104 Allocation - Other Fraud Card Absent Environment'
				}
			]
		},
		'Cancelled Recurring Transaction': {
			count: 10,
			firstChargebackAmount: 284,
			secondChargebackAmount: 0,
			chargebackRecoveryAmount: 0,
			successRate: 0,
			cardTypes: [],
			data: [
				{
					cardType: 'visa',
					processor: 'FISERV',
					firstChargebackAmount: 283.5,
					firstChargebackCount: 10,
					representmentAmount: 0,
					representmentCount: 0,
					secondChargebackAmount: 0,
					secondChargebackCount: 0,
					refundAmount: 283.5,
					refundCount: 10,
					chargebackRecoveryAmount: -283.5,
					successRate: -1,
					chargebackRecoveryNoRefundsAmount: 0,
					successRateNoRefunds: null,
					finalSuccessNoRefunds: 0,
					reason: 'Cancelled Recurring Transaction'
				}
			]
		},
		'Cancelled/Returned Merchandise or Services': {
			count: 1,
			firstChargebackAmount: 25,
			secondChargebackAmount: 0,
			chargebackRecoveryAmount: 0,
			successRate: 0,
			cardTypes: [],
			data: [
				{
					cardType: 'visa',
					processor: 'BLSNP_PRI',
					firstChargebackAmount: 24.95,
					firstChargebackCount: 1,
					representmentAmount: 0,
					representmentCount: 0,
					secondChargebackAmount: 0,
					secondChargebackCount: 0,
					refundAmount: 0,
					refundCount: 0,
					chargebackRecoveryAmount: 0,
					successRate: 0,
					chargebackRecoveryNoRefundsAmount: 0,
					successRateNoRefunds: 0,
					finalSuccessNoRefunds: 0,
					reason: 'Cancelled/Returned Merchandise or Services'
				}
			]
		},
		Fraud: {
			count: 6,
			firstChargebackAmount: 183,
			secondChargebackAmount: 0,
			chargebackRecoveryAmount: 0,
			successRate: 0,
			cardTypes: [],
			data: [
				{
					cardType: 'visa',
					processor: 'BLSNP_PRI',
					firstChargebackAmount: 182.7,
					firstChargebackCount: 6,
					representmentAmount: 0,
					representmentCount: 0,
					secondChargebackAmount: 0,
					secondChargebackCount: 0,
					refundAmount: 0,
					refundCount: 0,
					chargebackRecoveryAmount: 0,
					successRate: 0,
					chargebackRecoveryNoRefundsAmount: 0,
					successRateNoRefunds: 0,
					finalSuccessNoRefunds: 0,
					reason: 'Fraud'
				}
			]
		},
		'Fraud Card Absent Environment': {
			count: 138,
			firstChargebackAmount: 3623,
			secondChargebackAmount: 0,
			chargebackRecoveryAmount: 295,
			successRate: 8,
			cardTypes: [],
			data: [
				{
					cardType: 'visa',
					processor: 'FISERV',
					firstChargebackAmount: 3623.1,
					firstChargebackCount: 138,
					representmentAmount: 295.4,
					representmentCount: 12,
					secondChargebackAmount: 0,
					secondChargebackCount: 0,
					refundAmount: 2820.5,
					refundCount: 110,
					chargebackRecoveryAmount: -2525.1,
					successRate: -0.6969446054483729,
					chargebackRecoveryNoRefundsAmount: 295.4,
					successRateNoRefunds: 0.3680538250685273,
					finalSuccessNoRefunds: 0.08153238939030112,
					reason: 'Fraud Card Absent Environment'
				}
			]
		},
		'Incorrect Amount': {
			count: 4,
			firstChargebackAmount: 126,
			secondChargebackAmount: 34.95,
			chargebackRecoveryAmount: 91,
			successRate: 72,
			cardTypes: [],
			data: [
				{
					cardType: 'visa',
					processor: 'FISERV',
					firstChargebackAmount: 125.8,
					firstChargebackCount: 4,
					representmentAmount: 90.85,
					representmentCount: 3,
					secondChargebackAmount: 34.95,
					secondChargebackCount: 1,
					refundAmount: 0,
					refundCount: 0,
					chargebackRecoveryAmount: 55.89999999999999,
					successRate: 0.444356120826709,
					chargebackRecoveryNoRefundsAmount: 55.89999999999999,
					successRateNoRefunds: 0.444356120826709,
					finalSuccessNoRefunds: 0.444356120826709,
					reason: 'Incorrect Amount'
				}
			]
		},
		'Merchandise/Services Not Received': {
			count: 2,
			firstChargebackAmount: 63,
			secondChargebackAmount: 27.95,
			chargebackRecoveryAmount: 28,
			successRate: 44,
			cardTypes: [],
			data: [
				{
					cardType: 'visa',
					processor: 'FISERV',
					firstChargebackAmount: 62.9,
					firstChargebackCount: 2,
					representmentAmount: 27.95,
					representmentCount: 1,
					secondChargebackAmount: 27.95,
					secondChargebackCount: 1,
					refundAmount: 34.95,
					refundCount: 1,
					chargebackRecoveryAmount: -34.95,
					successRate: -0.5556438791732909,
					chargebackRecoveryNoRefundsAmount: 0,
					successRateNoRefunds: 0,
					finalSuccessNoRefunds: 0,
					reason: 'Merchandise/Services Not Received'
				}
			]
		},
		'Other Fraud- Card Absent Environment': {
			count: 14,
			firstChargebackAmount: 416,
			secondChargebackAmount: 0,
			chargebackRecoveryAmount: 0,
			successRate: 0,
			cardTypes: [],
			data: [
				{
					cardType: 'visa',
					processor: 'BLSNP_SAN',
					firstChargebackAmount: 416.3,
					firstChargebackCount: 14,
					representmentAmount: 0,
					representmentCount: 0,
					secondChargebackAmount: 0,
					secondChargebackCount: 0,
					refundAmount: 115.75,
					refundCount: 5,
					chargebackRecoveryAmount: -115.75,
					successRate: -0.27804467931779964,
					chargebackRecoveryNoRefundsAmount: 0,
					successRateNoRefunds: 0,
					finalSuccessNoRefunds: 0,
					reason: 'Other Fraud- Card Absent Environment'
				}
			]
		}
	},
	mastercard: {
		'Cancelled Recurring Transaction': {
			count: 4,
			firstChargebackAmount: 92,
			secondChargebackAmount: 0,
			chargebackRecoveryAmount: 92,
			successRate: 100,
			cardTypes: [],
			data: [
				{
					cardType: 'mastercard',
					processor: 'FISERV',
					firstChargebackAmount: 91.8,
					firstChargebackCount: 4,
					representmentAmount: 91.8,
					representmentCount: 4,
					secondChargebackAmount: 0,
					secondChargebackCount: 0,
					refundAmount: 38.9,
					refundCount: 2,
					chargebackRecoveryAmount: 52.9,
					successRate: 0.5762527233115469,
					chargebackRecoveryNoRefundsAmount: 91.8,
					successRateNoRefunds: 1.7353497164461247,
					finalSuccessNoRefunds: 1,
					reason: 'Cancelled Recurring Transaction'
				}
			]
		},
		'Cardholder Dispute - Defective/Not as described': {
			count: 2,
			firstChargebackAmount: 70,
			secondChargebackAmount: 0,
			chargebackRecoveryAmount: 0,
			successRate: 0,
			cardTypes: [],
			data: [
				{
					cardType: 'mastercard',
					processor: 'BLSNP_PRI',
					firstChargebackAmount: 69.9,
					firstChargebackCount: 2,
					representmentAmount: 0,
					representmentCount: 0,
					secondChargebackAmount: 0,
					secondChargebackCount: 0,
					refundAmount: 0,
					refundCount: 0,
					chargebackRecoveryAmount: 0,
					successRate: 0,
					chargebackRecoveryNoRefundsAmount: 0,
					successRateNoRefunds: 0,
					finalSuccessNoRefunds: 0,
					reason: 'Cardholder Dispute - Defective/Not as described'
				}
			]
		},
		'Cardholder Dispute - Defective/Not as Described': {
			count: 5,
			firstChargebackAmount: 123,
			secondChargebackAmount: 0,
			chargebackRecoveryAmount: 0,
			successRate: 0,
			cardTypes: [],
			data: [
				{
					cardType: 'mastercard',
					processor: 'PYSF_MERRI',
					firstChargebackAmount: 122.75,
					firstChargebackCount: 5,
					representmentAmount: 0,
					representmentCount: 0,
					secondChargebackAmount: 0,
					secondChargebackCount: 0,
					refundAmount: 0,
					refundCount: 0,
					chargebackRecoveryAmount: 0,
					successRate: 0,
					chargebackRecoveryNoRefundsAmount: 0,
					successRateNoRefunds: 0,
					finalSuccessNoRefunds: 0,
					reason: 'Cardholder Dispute - Defective/Not as Described'
				}
			]
		},
		'Cardholder Dispute- Not as Described': {
			count: 11,
			firstChargebackAmount: 379,
			secondChargebackAmount: 0,
			chargebackRecoveryAmount: 0,
			successRate: 0,
			cardTypes: [],
			data: [
				{
					cardType: 'mastercard',
					processor: 'BLSNP_SAN',
					firstChargebackAmount: 379.45,
					firstChargebackCount: 11,
					representmentAmount: 0,
					representmentCount: 0,
					secondChargebackAmount: 0,
					secondChargebackCount: 0,
					refundAmount: 314.55,
					refundCount: 9,
					chargebackRecoveryAmount: -314.55,
					successRate: -0.828962972723679,
					chargebackRecoveryNoRefundsAmount: 0,
					successRateNoRefunds: 0,
					finalSuccessNoRefunds: 0,
					reason: 'Cardholder Dispute- Not as Described'
				}
			]
		},
		'No cardholder authorization': {
			count: 12,
			firstChargebackAmount: 302,
			secondChargebackAmount: 0,
			chargebackRecoveryAmount: 0,
			successRate: 0,
			cardTypes: [],
			data: [
				{
					cardType: 'mastercard',
					processor: 'BLSNP_PRI',
					firstChargebackAmount: 302.4,
					firstChargebackCount: 12,
					representmentAmount: 0,
					representmentCount: 0,
					secondChargebackAmount: 0,
					secondChargebackCount: 0,
					refundAmount: 66.85,
					refundCount: 3,
					chargebackRecoveryAmount: -66.85,
					successRate: -0.22106481481481483,
					chargebackRecoveryNoRefundsAmount: 0,
					successRateNoRefunds: 0,
					finalSuccessNoRefunds: 0,
					reason: 'No cardholder authorization'
				}
			]
		},
		'No Cardholder Authorization': {
			count: 421,
			firstChargebackAmount: 12123,
			secondChargebackAmount: 147.75,
			chargebackRecoveryAmount: 6353,
			successRate: 52,
			cardTypes: [],
			data: [
				{
					cardType: 'mastercard',
					processor: 'BLSNP_SAN',
					firstChargebackAmount: 1962.65,
					firstChargebackCount: 67,
					representmentAmount: 0,
					representmentCount: 0,
					secondChargebackAmount: 0,
					secondChargebackCount: 0,
					refundAmount: 538.1,
					refundCount: 18,
					chargebackRecoveryAmount: -538.1,
					successRate: -0.2741701271240415,
					chargebackRecoveryNoRefundsAmount: 0,
					successRateNoRefunds: 0,
					finalSuccessNoRefunds: 0,
					reason: 'No Cardholder Authorization'
				},
				{
					cardType: 'mastercard',
					processor: 'FISERV',
					firstChargebackAmount: 7840.44,
					firstChargebackCount: 274,
					representmentAmount: 6293.42,
					representmentCount: 206,
					secondChargebackAmount: 122.8,
					secondChargebackCount: 4,
					refundAmount: 2096.35,
					refundCount: 73,
					chargebackRecoveryAmount: 4074.27,
					successRate: 0.5196481319925923,
					chargebackRecoveryNoRefundsAmount: 6170.62,
					successRateNoRefunds: 1.074255452125576,
					finalSuccessNoRefunds: 0.7870247077970114,
					reason: 'No Cardholder Authorization'
				},
				{
					cardType: 'mastercard',
					processor: 'PYSF_MERRI',
					firstChargebackAmount: 2320,
					firstChargebackCount: 80,
					representmentAmount: 59.9,
					representmentCount: 2,
					secondChargebackAmount: 24.95,
					secondChargebackCount: 1,
					refundAmount: 964.35,
					refundCount: 33,
					chargebackRecoveryAmount: -929.4,
					successRate: -0.4006034482758621,
					chargebackRecoveryNoRefundsAmount: 34.95,
					successRateNoRefunds: 0.02578099066868292,
					finalSuccessNoRefunds: 0.015064655172413795,
					reason: 'No Cardholder Authorization'
				}
			]
		},
		'Not as described': {
			count: 13,
			firstChargebackAmount: 385,
			secondChargebackAmount: 0,
			chargebackRecoveryAmount: 320,
			successRate: 83,
			cardTypes: [],
			data: [
				{
					cardType: 'mastercard',
					processor: 'FISERV',
					firstChargebackAmount: 385.35,
					firstChargebackCount: 13,
					representmentAmount: 320.45,
					representmentCount: 11,
					secondChargebackAmount: 0,
					secondChargebackCount: 0,
					refundAmount: 182.7,
					refundCount: 6,
					chargebackRecoveryAmount: 137.75,
					successRate: 0.3574672375762294,
					chargebackRecoveryNoRefundsAmount: 320.45,
					successRateNoRefunds: 1.5812978040957313,
					finalSuccessNoRefunds: 0.8315816789931231,
					reason: 'Not as described'
				}
			]
		}
	},
	amex: {
		'C28: Cancelled Recurring': {
			count: 2,
			firstChargebackAmount: 50,
			secondChargebackAmount: 0,
			chargebackRecoveryAmount: 0,
			successRate: 0,
			cardTypes: [],
			data: [
				{
					cardType: 'amex',
					processor: 'BLSNP_SAN',
					firstChargebackAmount: 49.9,
					firstChargebackCount: 2,
					representmentAmount: 0,
					representmentCount: 0,
					secondChargebackAmount: 0,
					secondChargebackCount: 0,
					refundAmount: 24.95,
					refundCount: 1,
					chargebackRecoveryAmount: -24.95,
					successRate: -0.5,
					chargebackRecoveryNoRefundsAmount: 0,
					successRateNoRefunds: 0,
					finalSuccessNoRefunds: 0,
					reason: 'C28: Cancelled Recurring'
				}
			]
		},
		'F29: Fraudulent Transaction': {
			count: 44,
			firstChargebackAmount: 1165,
			secondChargebackAmount: 0,
			chargebackRecoveryAmount: 0,
			successRate: 0,
			cardTypes: [],
			data: [
				{
					cardType: 'amex',
					processor: 'BLSNP_PRI',
					firstChargebackAmount: 167.7,
					firstChargebackCount: 6,
					representmentAmount: 0,
					representmentCount: 0,
					secondChargebackAmount: 0,
					secondChargebackCount: 0,
					refundAmount: 0,
					refundCount: 0,
					chargebackRecoveryAmount: 0,
					successRate: 0,
					chargebackRecoveryNoRefundsAmount: 0,
					successRateNoRefunds: 0,
					finalSuccessNoRefunds: 0,
					reason: 'F29: Fraudulent Transaction'
				},
				{
					cardType: 'amex',
					processor: 'BLSNP_SAN',
					firstChargebackAmount: 997.1,
					firstChargebackCount: 38,
					representmentAmount: 0,
					representmentCount: 0,
					secondChargebackAmount: 0,
					secondChargebackCount: 0,
					refundAmount: 0,
					refundCount: 0,
					chargebackRecoveryAmount: 0,
					successRate: 0,
					chargebackRecoveryNoRefundsAmount: 0,
					successRateNoRefunds: 0,
					finalSuccessNoRefunds: 0,
					reason: 'F29: Fraudulent Transaction'
				}
			]
		}
	},
	discover: {
		'AT - No Authorization': {
			count: 10,
			firstChargebackAmount: 291,
			secondChargebackAmount: 0,
			chargebackRecoveryAmount: 233,
			successRate: 80,
			cardTypes: [],
			data: [
				{
					cardType: 'discover',
					processor: 'FISERV',
					firstChargebackAmount: 290.5,
					firstChargebackCount: 10,
					representmentAmount: 232.6,
					representmentCount: 8,
					secondChargebackAmount: 0,
					secondChargebackCount: 0,
					refundAmount: 117.8,
					refundCount: 4,
					chargebackRecoveryAmount: 114.8,
					successRate: 0.39518072289156625,
					chargebackRecoveryNoRefundsAmount: 232.6,
					successRateNoRefunds: 1.3468442385639838,
					finalSuccessNoRefunds: 0.8006884681583477,
					reason: 'AT - No Authorization'
				}
			]
		},
		'Authorization Non Compliance': {
			count: 2,
			firstChargebackAmount: 53,
			secondChargebackAmount: 0,
			chargebackRecoveryAmount: 0,
			successRate: 0,
			cardTypes: [],
			data: [
				{
					cardType: 'discover',
					processor: 'PYSF_MERRI',
					firstChargebackAmount: 52.9,
					firstChargebackCount: 2,
					representmentAmount: 0,
					representmentCount: 0,
					secondChargebackAmount: 0,
					secondChargebackCount: 0,
					refundAmount: 52.9,
					refundCount: 2,
					chargebackRecoveryAmount: -52.9,
					successRate: -1,
					chargebackRecoveryNoRefundsAmount: 0,
					successRateNoRefunds: null,
					finalSuccessNoRefunds: 0,
					reason: 'Authorization Non Compliance'
				}
			]
		},
		'cardholder disputes quality of goods or services': {
			count: 1,
			firstChargebackAmount: 28,
			secondChargebackAmount: 0,
			chargebackRecoveryAmount: 0,
			successRate: 0,
			cardTypes: [],
			data: [
				{
					cardType: 'discover',
					processor: 'FISERV',
					firstChargebackAmount: 27.95,
					firstChargebackCount: 1,
					representmentAmount: 0,
					representmentCount: 0,
					secondChargebackAmount: 0,
					secondChargebackCount: 0,
					refundAmount: 27.95,
					refundCount: 1,
					chargebackRecoveryAmount: -27.95,
					successRate: -1,
					chargebackRecoveryNoRefundsAmount: 0,
					successRateNoRefunds: null,
					finalSuccessNoRefunds: 0,
					reason: 'cardholder disputes quality of goods or services'
				}
			]
		},
		'Credit Not Processed': {
			count: 3,
			firstChargebackAmount: 84,
			secondChargebackAmount: 27.95,
			chargebackRecoveryAmount: 84,
			successRate: 100,
			cardTypes: [],
			data: [
				{
					cardType: 'discover',
					processor: 'FISERV',
					firstChargebackAmount: 83.85,
					firstChargebackCount: 3,
					representmentAmount: 83.85,
					representmentCount: 3,
					secondChargebackAmount: 27.95,
					secondChargebackCount: 1,
					refundAmount: 55.9,
					refundCount: 2,
					chargebackRecoveryAmount: -7.105427357601002e-15,
					successRate: -8.473974189148482e-17,
					chargebackRecoveryNoRefundsAmount: 55.89999999999999,
					successRateNoRefunds: 2,
					finalSuccessNoRefunds: 0.6666666666666666,
					reason: 'Credit Not Processed'
				}
			]
		},
		'credit not processed': {
			count: 4,
			firstChargebackAmount: 125,
			secondChargebackAmount: 0,
			chargebackRecoveryAmount: 90,
			successRate: 72,
			cardTypes: [],
			data: [
				{
					cardType: 'discover',
					processor: 'PYSF_MERRI',
					firstChargebackAmount: 124.8,
					firstChargebackCount: 4,
					representmentAmount: 89.85,
					representmentCount: 3,
					secondChargebackAmount: 0,
					secondChargebackCount: 0,
					refundAmount: 124.8,
					refundCount: 4,
					chargebackRecoveryAmount: -34.95,
					successRate: -0.2800480769230769,
					chargebackRecoveryNoRefundsAmount: 89.85,
					successRateNoRefunds: null,
					finalSuccessNoRefunds: 0.719951923076923,
					reason: 'credit not processed'
				}
			]
		},
		'Declined Authorization': {
			count: 5,
			firstChargebackAmount: 143,
			secondChargebackAmount: 0,
			chargebackRecoveryAmount: 53,
			successRate: 37,
			cardTypes: [],
			data: [
				{
					cardType: 'discover',
					processor: 'PYSF_MERRI',
					firstChargebackAmount: 142.75,
					firstChargebackCount: 5,
					representmentAmount: 52.9,
					representmentCount: 2,
					secondChargebackAmount: 0,
					secondChargebackCount: 0,
					refundAmount: 142.75,
					refundCount: 5,
					chargebackRecoveryAmount: -89.85,
					successRate: -0.6294220665499124,
					chargebackRecoveryNoRefundsAmount: 52.9,
					successRateNoRefunds: null,
					finalSuccessNoRefunds: 0.37057793345008755,
					reason: 'Declined Authorization'
				}
			]
		},
		'Does Not Recognize': {
			count: 21,
			firstChargebackAmount: 655,
			secondChargebackAmount: 0,
			chargebackRecoveryAmount: 406,
			successRate: 62,
			cardTypes: [],
			data: [
				{
					cardType: 'discover',
					processor: 'FISERV',
					firstChargebackAmount: 654.95,
					firstChargebackCount: 21,
					representmentAmount: 406.35,
					representmentCount: 13,
					secondChargebackAmount: 0,
					secondChargebackCount: 0,
					refundAmount: 654.95,
					refundCount: 21,
					chargebackRecoveryAmount: -248.60000000000002,
					successRate: -0.3795709596152378,
					chargebackRecoveryNoRefundsAmount: 406.35,
					successRateNoRefunds: null,
					finalSuccessNoRefunds: 0.6204290403847622,
					reason: 'Does Not Recognize'
				}
			]
		},
		'Fraudulent Transaction Card Not Present': {
			count: 268,
			firstChargebackAmount: 8304,
			secondChargebackAmount: 59.9,
			chargebackRecoveryAmount: 469,
			successRate: 6,
			cardTypes: [],
			data: [
				{
					cardType: 'discover',
					processor: 'FISERV',
					firstChargebackAmount: 8303.6,
					firstChargebackCount: 268,
					representmentAmount: 469.25,
					representmentCount: 15,
					secondChargebackAmount: 59.9,
					secondChargebackCount: 2,
					refundAmount: 551.1,
					refundCount: 18,
					chargebackRecoveryAmount: -141.75,
					successRate: -0.017070909003323857,
					chargebackRecoveryNoRefundsAmount: 409.35,
					successRateNoRefunds: 0.05280232183166721,
					finalSuccessNoRefunds: 0.04929789488896382,
					reason: 'Fraudulent Transaction Card Not Present'
				}
			]
		},
		'Non-Receipt of Goods or Services': {
			count: 9,
			firstChargebackAmount: 257,
			secondChargebackAmount: 0,
			chargebackRecoveryAmount: 0,
			successRate: 0,
			cardTypes: [],
			data: [
				{
					cardType: 'discover',
					processor: 'BLSNP_SAN',
					firstChargebackAmount: 51.9,
					firstChargebackCount: 2,
					representmentAmount: 0,
					representmentCount: 0,
					secondChargebackAmount: 0,
					secondChargebackCount: 0,
					refundAmount: 29.95,
					refundCount: 1,
					chargebackRecoveryAmount: -29.95,
					successRate: -0.5770712909441233,
					chargebackRecoveryNoRefundsAmount: 0,
					successRateNoRefunds: 0,
					finalSuccessNoRefunds: 0,
					reason: 'Non-Receipt of Goods or Services'
				},
				{
					cardType: 'discover',
					processor: 'FISERV',
					firstChargebackAmount: 34.95,
					firstChargebackCount: 1,
					representmentAmount: 0,
					representmentCount: 0,
					secondChargebackAmount: 0,
					secondChargebackCount: 0,
					refundAmount: 0,
					refundCount: 0,
					chargebackRecoveryAmount: 0,
					successRate: 0,
					chargebackRecoveryNoRefundsAmount: 0,
					successRateNoRefunds: 0,
					finalSuccessNoRefunds: 0,
					reason: 'Non-Receipt of Goods or Services'
				},
				{
					cardType: 'discover',
					processor: 'PYSF_MERRI',
					firstChargebackAmount: 169.7,
					firstChargebackCount: 6,
					representmentAmount: 0,
					representmentCount: 0,
					secondChargebackAmount: 0,
					secondChargebackCount: 0,
					refundAmount: 0,
					refundCount: 0,
					chargebackRecoveryAmount: 0,
					successRate: 0,
					chargebackRecoveryNoRefundsAmount: 0,
					successRateNoRefunds: 0,
					finalSuccessNoRefunds: 0,
					reason: 'Non-Receipt of Goods or Services'
				}
			]
		},
		'Recurring Payments': {
			count: 21,
			firstChargebackAmount: 626,
			secondChargebackAmount: 0,
			chargebackRecoveryAmount: 0,
			successRate: 0,
			cardTypes: [],
			data: [
				{
					cardType: 'discover',
					processor: 'FISERV',
					firstChargebackAmount: 625.95,
					firstChargebackCount: 21,
					representmentAmount: 0,
					representmentCount: 0,
					secondChargebackAmount: 0,
					secondChargebackCount: 0,
					refundAmount: 29.95,
					refundCount: 1,
					chargebackRecoveryAmount: -29.95,
					successRate: -0.04784727214633756,
					chargebackRecoveryNoRefundsAmount: 0,
					successRateNoRefunds: 0,
					finalSuccessNoRefunds: 0,
					reason: 'Recurring Payments'
				}
			]
		},
		'Request Transaction Documentation for Card Not Present Card Tran': {
			count: 9,
			firstChargebackAmount: 290,
			secondChargebackAmount: 0,
			chargebackRecoveryAmount: 0,
			successRate: 0,
			cardTypes: [],
			data: [
				{
					cardType: 'discover',
					processor: 'BLSNP_SAN',
					firstChargebackAmount: 289.55,
					firstChargebackCount: 9,
					representmentAmount: 0,
					representmentCount: 0,
					secondChargebackAmount: 0,
					secondChargebackCount: 0,
					refundAmount: 0,
					refundCount: 0,
					chargebackRecoveryAmount: 0,
					successRate: 0,
					chargebackRecoveryNoRefundsAmount: 0,
					successRateNoRefunds: 0,
					finalSuccessNoRefunds: 0,
					reason:
						'Request Transaction Documentation for Card Not Present Card Tran'
				}
			]
		},
		'Request Transaction Documentation for Card Not Present Card Transaction': {
			count: 50,
			firstChargebackAmount: 1416,
			secondChargebackAmount: 0,
			chargebackRecoveryAmount: 0,
			successRate: 0,
			cardTypes: [],
			data: [
				{
					cardType: 'discover',
					processor: 'PYSF_MERRI',
					firstChargebackAmount: 1415.5,
					firstChargebackCount: 50,
					representmentAmount: 0,
					representmentCount: 0,
					secondChargebackAmount: 0,
					secondChargebackCount: 0,
					refundAmount: 29.95,
					refundCount: 1,
					chargebackRecoveryAmount: -29.95,
					successRate: -0.02115860120098905,
					chargebackRecoveryNoRefundsAmount: 0,
					successRateNoRefunds: 0,
					finalSuccessNoRefunds: 0,
					reason:
						'Request Transaction Documentation for Card Not Present Card Transaction'
				}
			]
		}
	}
};
