import { fromJS } from 'immutable';
import * as actionTypes from './actionTypes';
import handleDynamicTileLists from '../../helpers/tiles/handleDynamicTileLists';
import handleUpdatedTile from '../../helpers/tiles/handleUpdatedTile';
import handleDynamicDeleteList from '../../helpers/shared/handleDynamicDeleteList';

export const initialState = fromJS({
	layoutTiles: {},
	tileBeingModified: undefined,
	tileBeingUpdated: undefined,
	availableTiles: {
		isLoading: false,
		hasBeenLoaded: false,
		error: undefined,
		data: []
	},
	layoutTilesBeingDeleted: []
});

export const tilesReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_TILES_FOR_LAYOUT:
			return handleDynamicTileLists(state, action, 'layoutTiles');

		case actionTypes.GET_TILES_FOR_LAYOUT_COMPLETED:
			return handleDynamicTileLists(state, action, 'layoutTiles');

		case actionTypes.GET_TILES_FOR_LAYOUT_FAILED:
			return handleDynamicTileLists(state, action, 'layoutTiles');

		case actionTypes.SET_TILE_BEING_MODIFIED:
			return state.set('tileBeingModified', action.payload);

		case actionTypes.UPDATE_LAYOUT_TILE:
			return state.set('tileBeingUpdated', action.payload);

		case actionTypes.UPDATE_LAYOUT_TILE_COMPLETED:
			return handleUpdatedTile(state, action, 'layoutTiles');

		case actionTypes.UPDATE_LAYOUT_TILE_FAILED:
			return state.set('tileBeingUpdated', undefined);

		case actionTypes.GET_AVAILABLE_TILES:
			return state.setIn(['availableTiles', 'isLoading'], true);

		case actionTypes.GET_AVAILABLE_TILES_COMPLETED:
			return state
				.setIn(['availableTiles', 'isLoading'], false)
				.setIn(['availableTiles', 'data'], action.payload)
				.setIn(['availableTiles', 'hasBeenLoaded'], true);

		case actionTypes.GET_AVAILABLE_TILES_FAILED:
			return state
				.setIn(['availableTiles', 'isLoading'], false)
				.setIn(['availabeTiles', 'error'], action.payload);

		case actionTypes.ADD_TILES_TO_LAYOUT:
			return handleDynamicTileLists(state, action, 'layoutTiles');

		case actionTypes.ADD_TILES_TO_LAYOUT_COMPLETED:
			return handleDynamicTileLists(state, action, 'layoutTiles');

		case actionTypes.ADD_TILES_TO_LAYOUT_FAILED:
			return handleDynamicTileLists(state, action, 'layoutTiles');

		case actionTypes.DELETE_TILES_BY_LAYOUT:
			return handleDynamicDeleteList(state, action, 'layoutTilesBeingDeleted');

		case actionTypes.DELETE_TILES_BY_LAYOUT_COMPLETED:
			return handleDynamicDeleteList(state, action, 'layoutTilesBeingDeleted');

		case actionTypes.DELETE_TILES_BY_LAYOUT_FAILED:
			return handleDynamicDeleteList(state, action, 'layoutTilesBeingDeleted');

		case actionTypes.DELETE_TILE_FROM_LAYOUT:
			return handleDynamicTileLists(state, action, 'layoutTiles');

		case actionTypes.DELETE_TILE_FROM_LAYOUT_COMPLETED:
			return handleDynamicTileLists(state, action, 'layoutTiles');

		case actionTypes.DELETE_TILE_FROM_LAYOUT_FAILED:
			return handleDynamicTileLists(state, action, 'layoutTiles');

		default:
			return state;
	}
};
