import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import displaySections from './constants/displaySections';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';

const styles = (theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'column'
	},
	toggleButton: {
		fontSize: '.7rem'
	}
});

export const RefundInformation = (props) => {
	const { classes, t, displayedSections, setDisplayedSections } = props;
	const { container, toggleButton } = classes;

	const handleSelection = (event, newSelections) => {
		if (newSelections.includes('showAll') && newSelections.includes('hideAll')) {
			setDisplayedSections(displaySections);
		} else if (newSelections.indexOf('hideAll') > 0) {
			setDisplayedSections(['hideAll']);
		} else if (newSelections.indexOf('hideAll') === 0) {
			const hideAllIndex = newSelections.indexOf('hideAll');
			newSelections.splice(hideAllIndex, 1);
			setDisplayedSections(newSelections);
		} else {
			setDisplayedSections(newSelections);
		}
	};

	return (
		<div className={container}>
			<ToggleButtonGroup
				size="small"
				value={displayedSections}
				onChange={handleSelection}
				aria-label="Display Sections"
			>
				{ displayedSections.includes('hideAll') && <ToggleButton className={toggleButton} value='showAll'><VisibilityIcon /></ToggleButton> }
				{ !displayedSections.includes('hideAll') && <ToggleButton className={toggleButton} value='hideAll'><VisibilityOffIcon /></ToggleButton> }
				<ToggleButton className={toggleButton} value='overview' aria-label="overview">{t('components.Representment.Overview.label')}</ToggleButton >
				<ToggleButton className={toggleButton} value='customerInformation' aria-label='customerInformation'>{t('components.Representment.CustomerInformation.label')}</ToggleButton >
				<ToggleButton className={toggleButton} value='chargebackInformation' aria-label='chargebackInformation'>{t('components.Representment.ChargebackInformation.label')}</ToggleButton >
				<ToggleButton className={toggleButton} value='transactionDetails' aria-label='transactionDetails'>{t('components.Representment.TransactionDetails.label')}</ToggleButton >
				<ToggleButton className={toggleButton} value='refundInformation' aria-label='refundInformation'>{t('components.Representment.RefundInformation.label')}</ToggleButton >
				<ToggleButton className={toggleButton} value='transactionsList' aria-label='transactionsList'>{t('components.Representment.TransactionsList.label')}</ToggleButton >
			</ToggleButtonGroup>
		</div>
	);
};

RefundInformation.propTypes = {
	displayedSections: PropTypes.array,
	setDisplayedSections: PropTypes.func,
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(RefundInformation));
