import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import { calculatePendingPercent } from '../../../helpers/recoveryHistory/percentageCalculators';
import querier from '../../../utilities/querier';
import LegendItem from './LegendItem';

import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';

const styles = (theme) => ({
	baseLegendItem: {
		width: 20,
		height: 20,
		borderRadius: '5px'
	},
	hidden: {
		display: 'none'
	},
	pendingLegend: {
		backgroundColor: theme.palette.recoveryHistory.pending
	},
	missesLegend: {
		backgroundColor: theme.palette.recoveryHistory.misses
	},
	winsLegend: {
		backgroundColor: theme.palette.recoveryHistory.wins
	}
});

export const StatBarLegend = (props) => {
	const { classes, data } = props;
	const {
		baseLegendItem,
		hidden,
		pendingLegend,
		missesLegend,
		winsLegend
	} = classes;

	const pendingSectionPercentage = calculatePendingPercent(
		querier(data, 'misses.value'),
		querier(data, 'wins.value'),
		querier(data, 'total.value')
	);

	return (
		<Stack
			direction="row"
			spacing={2}
			alignItems="center"
			justifyContent="center"
			className={clsx(!querier(data, 'total.value') && hidden)}
		>
			<LegendItem
				titlePath="components.RecoveryHistory.RecoveryHistoryManager.misses"
				legendClass={clsx(baseLegendItem, missesLegend)}
			/>
			<Divider orientation="vertical" flexItem />
			<LegendItem
				titlePath="components.RecoveryHistory.RecoveryHistoryManager.wins"
				legendClass={clsx(baseLegendItem, winsLegend)}
			/>
			{pendingSectionPercentage > 1 && (
				<Fragment>
					<Divider orientation="vertical" flexItem />
					<LegendItem
						titlePath="components.RecoveryHistory.RecoveryHistoryManager.pending"
						legendClass={clsx(baseLegendItem, pendingLegend)}
					/>
				</Fragment>
			)}
		</Stack>
	);
};

StatBarLegend.propTypes = {
	data: PropTypes.object,
	classes: PropTypes.object
};

export default withStyles(styles)(StatBarLegend);
