import moment from 'moment';
import { sortAscending } from '../../shared/sortByDate';

export default function (data) {
	let groupedByDate = {};

	data.reduce(function (result, item) {
		if (!result[item.day]) {
			result[item.day] = {
				Chargebacks: 0
			};
		}

		result[item.day].Chargebacks += item.chargebacks;

		return result;
	}, groupedByDate);

	const results = [];

	Object.entries(groupedByDate).forEach(([key, value]) => {
		results.push({
			date: moment(key).format('YYYY-MM-DD'),
			...value
		});
	});

	return {
		lineKeys: ['Chargebacks'],
		results: sortAscending(results, (item) => item.date)
	};
}
