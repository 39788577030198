import React from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { downloadToCsv } from '../../../utilities/downloadToCsv';
import clsx from 'clsx';

import Tooltip from '@mui/material/Tooltip';

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { withTranslation } from 'react-i18next';

const styles = (theme) => ({
	actionButton: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		borderRadius: '10px',
		margin: '10px',
		padding: '0px 10px 0px 10px',
		backgroundColor: theme.palette.common.white,
		border: `1px solid ${theme.palette.navigationActionButton.background}`
	},
	actionButtonDisabled: {
		opacity: 0.9
	},
	icon: {
		color: theme.palette.navigationActionButton.background,
		fontSize: '2rem',
		'&hover': {
			color: `${theme.palette.icons.selected} !important`
		}
	},
	iconActive: {
		cursor: 'pointer'
	}
});

const DownloadToCsv = (props) => {
	const { classes, t, data, isDisabled, label } = props;
	const { actionButton, actionButtonDisabled, icon, iconActive } = classes;

	const unSanitizeLabels = (label) => {
		return label.replace(/ /g, '-');
	};

	return (
		<Tooltip title={t('components.Widget.WidgetViewer.actionSection.download')}>
			<div
				className={clsx(actionButton, isDisabled && actionButtonDisabled)}
				onClick={
					isDisabled
						? null
						: () => {
								downloadToCsv(data, unSanitizeLabels(label));
						  }
				}
			>
				<FileDownloadIcon className={clsx(icon, !isDisabled && iconActive)} />
			</div>
		</Tooltip>
	);
};

DownloadToCsv.propTypes = {
	data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	isDisabled: PropTypes.bool,
	label: PropTypes.string,
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(DownloadToCsv));
