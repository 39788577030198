import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withSize } from 'react-sizeme';
import { tilesActions } from '../../../containers/Tiles';
import tileSelector from '../../../helpers/dashboard/dashboardTileManager/tileSelector';
import DashboardTiles from '../../DashboardTiles';
import isTileBeingUpdated from '../../../helpers/tiles/isTileBeingUpdated';

import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import SettingsIcon from '@mui/icons-material/Settings';

const styles = (theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		flex: '1'
	},
	headerContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		padding: '3px'
	},
	headerTextContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		fontSize: '18px',
		fontWeight: 'bold',
		flex: 3,
		textAlign: 'center',
		color: theme.palette.primary.main
	},
	endContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		flex: 1
	},
	settingsIcon: {
		cursor: 'pointer',
		color: theme.palette.dashboard.tiles.baseTile.settingsIcon
	}
});

export const BaseTile = (props) => {
	const { classes, tile, size, tileBeingUpdated } = props;
	const {
		container,
		headerContainer,
		headerTextContainer,
		endContainer,
		settingsIcon
	} = classes;

	const options = tile.options;

	const TileComponent = tileSelector(tile, DashboardTiles, size);

	const onClickModifyTile = () => {
		const { setTileBeingModified, tile } = props;

		setTileBeingModified(tile);
	};

	return (
		<Fragment>
			{isTileBeingUpdated(tile, tileBeingUpdated) ? (
				<DashboardTiles.tileSkeleton size={size} />
			) : (
				<Paper
					key={tile.uuid}
					style={{
						width: `${tile.tileWidth}px`,
						backgroundColor: tile.background,
						height: '100%'
					}}
				>
					<Container className={container}>
						<div className={headerContainer}>
							<div className={endContainer} />
							<Typography className={headerTextContainer}>
								{tile.label === 'Fraud - Rates Last 6 Months' ? (
									<Fragment>
										{tile.label}
										<p style={{ fontSize: '12px', marginLeft: '10px' }}>
											(For All Groups)
										</p>
									</Fragment>
								) : (
									tile.label
								)}
							</Typography>
							<div className={endContainer}>
								{options && (
									<SettingsIcon
										className={settingsIcon}
										onClick={onClickModifyTile}
									/>
								)}
							</div>
						</div>
						{TileComponent}
					</Container>
				</Paper>
			)}
		</Fragment>
	);
};

BaseTile.propTypes = {
	tile: PropTypes.object,
	tileBeingUpdated: PropTypes.object,
	setTileBeingModified: PropTypes.func,
	classes: PropTypes.object
};

const mapStateToProps = (state) => {
	return {
		tileBeingUpdated: state.tilesReducer.get('tileBeingUpdated')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setTileBeingModified: (payload) => {
			dispatch(tilesActions.setTileBeingModified(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(withSize({ monitorHeight: true })(BaseTile)));
