import React from 'react';
import PropTypes from 'prop-types';
import { useTheme, withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import ConfirmDeleteModal from '../../../../components/modals/NotificationModals/ConfirmDeleteModal';
import NotificationDetailEditable from './NotificationDetailEditable';
import NotificationDetailDisplay from './NotificationDetailDisplay';
import getNotificationTypeIcon from '../../../../helpers/notifications/getNotificationTypeIcon';

import Paper from '@mui/material/Paper';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';

const styles = (theme) => ({
	dot: {
		margin: 0
	},
	end: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-end'
	},
	notificationBox: {
		padding: '7px',
		'&::before': {
			content: 'none!important'
		}
	}
});

const NotificationDetail = (props) => {
	const {
		classes,
		deleteNotificationDetail,
		entry,
		renderAssigned,
		selected,
		updateNotificationDetail,
		onOpenAttachmentModal
	} = props;
	const { dot, notificationBox } = classes;

	const theme = useTheme();

	const [isEditable, setIsEditable] = React.useState(false);
	const [updatedTitle, setUpdatedTitle] = React.useState(entry.message);
	const [deleteData, setDeleteData] = React.useState({});

	const handleUpdate = () => {
		updateNotificationDetail({
			notificationId: selected.id,
			detailId: entry.id,
			title: updatedTitle
		});
		setIsEditable(false);
	};

	const handleSubmit = (parameters) => {
		deleteNotificationDetail(parameters);
		setDeleteData({});
	};

	return (
		<Paper elevation={1} sx={{ m: 0.25 }}>
			<TimelineItem className={notificationBox}>
				<TimelineSeparator>
					<TimelineConnector />
					<TimelineDot variant="outlined" className={dot}>
						{getNotificationTypeIcon(theme, entry.type)}
					</TimelineDot>
					<TimelineConnector />
				</TimelineSeparator>
				<TimelineContent>
					{isEditable ? (
						<NotificationDetailEditable
							handleUpdate={handleUpdate}
							message={entry.message}
							setIsEditable={setIsEditable}
							setUpdatedTitle={setUpdatedTitle}
							updatedTitle={updatedTitle}
						/>
					) : (
						<NotificationDetailDisplay
							entry={entry}
							handleSubmit={handleSubmit}
							isEditable={isEditable}
							renderAssigned={renderAssigned}
							setDeleteData={setDeleteData}
							setIsEditable={setIsEditable}
							selected={selected}
							onOpenAttachmentModal={onOpenAttachmentModal}
						/>
					)}
				</TimelineContent>
			</TimelineItem>
			<ConfirmDeleteModal
				deleteData={deleteData}
				open={deleteData.label !== undefined}
			/>
		</Paper>
	);
};

NotificationDetail.propTypes = {
	classes: PropTypes.object,
	deleteNotificationDetail: PropTypes.func,
	entry: PropTypes.object,
	renderAssigned: PropTypes.func,
	selected: PropTypes.object,
	t: PropTypes.func,
	updateNotificationDetail: PropTypes.func,
	onOpenAttachmentModal: PropTypes.func
};

export default withStyles(styles)(withTranslation()(NotificationDetail));
