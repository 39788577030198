import { fromJS } from 'immutable';
import * as actionTypes from './actionTypes';

export const initialState = fromJS({
	historyByOutcome: {
		isLoading: false,
		hasBeenLoaded: false,
		error: undefined,
		data: {}
	},
	historyByCard: {
		isLoading: false,
		hasBeenLoaded: false,
		error: undefined,
		data: {}
	},
	historyByReason: {
		isLoading: false,
		hasBeenLoaded: false,
		error: undefined,
		data: {}
	}
});

export const recoveryHistoryReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_RECOVERY_HISTORY_BY_OUTCOME:
			return state.setIn(['historyByOutcome', 'isLoading'], true);

		case actionTypes.GET_RECOVERY_HISTORY_BY_OUTCOME_COMPLETED:
			return state
				.setIn(['historyByOutcome', 'isLoading'], false)
				.setIn(['historyByOutcome', 'hasBeenLoaded'], true)
				.setIn(['historyByOutcome', 'data'], action.payload);

		case actionTypes.GET_RECOVERY_HISTORY_BY_OUTCOME_FAILED:
			return state
				.setIn(['historyByOutcome', 'isLoading'], false)
				.setIn(['historyByOutcome', 'error'], action.payload);

		case actionTypes.GET_RECOVERY_HISTORY_BY_CARD:
			return state.setIn(['historyByCard', 'isLoading'], true);

		case actionTypes.GET_RECOVERY_HISTORY_BY_CARD_COMPLETED:
			return state
				.setIn(['historyByCard', 'isLoading'], false)
				.setIn(['historyByCard', 'hasBeenLoaded'], true)
				.setIn(['historyByCard', 'data'], action.payload);

		case actionTypes.GET_RECOVERY_HISTORY_BY_CARD_FAILED:
			return state
				.setIn(['historyByCard', 'isLoading'], false)
				.setIn(['historyByCard', 'error'], action.payload);

		case actionTypes.GET_RECOVERY_HISTORY_BY_REASON:
			return state.setIn(['historyByReason', 'isLoading'], true);

		case actionTypes.GET_RECOVERY_HISTORY_BY_REASON_COMPLETED:
			return state
				.setIn(['historyByReason', 'isLoading'], false)
				.setIn(['historyByReason', 'hasBeenLoaded'], true)
				.setIn(['historyByReason', 'data'], action.payload);

		case actionTypes.GET_RECOVERY_HISTORY_BY_REASON_FAILED:
			return state
				.setIn(['historyByReason', 'isLoading'], false)
				.setIn(['historyByReason', 'error'], action.payload);

		default:
			return state;
	}
};
