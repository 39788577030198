import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import AppsIcon from '@mui/icons-material/Apps';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import DeleteIcon from '@mui/icons-material/Delete';

const styles = (theme) => ({
	box: {
		display: 'flex',
		flexDirection: 'column',
		alignSelf: 'stretch',
		height: '100%',
		flex: 1
	},
	listHeader: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		color: theme.palette.createLayoutModal.instructionsText,
		fontSize: '1rem'
	},
	list: {
		paddingTop: '5px',
		paddingRight: '3px',
		overflow: 'auto'
	},
	listItemText: {
		display: 'flex',
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'flex-start'
	},
	deleteIcon: {
		color: theme.palette.createLayoutModal.removeIcon,
		'&:hover': {
			color: `${theme.palette.createLayoutModal.removeIconHover} !important`
		}
	}
});

export const SelectedTilesList = (props) => {
	const { selectedTiles, onTileRemove, classes, t } = props;
	const { box, listHeader, list, listItemText, deleteIcon } = classes;

	return (
		<Box className={box}>
			<span className={listHeader}>Selected Tiles</span>
			<List className={list}>
				{selectedTiles.map((tile) => {
					return (
						<ListItem key={tile.universalUniqueIdentifier} disablePadding>
							<ListItemButton>
								<ListItemIcon>
									{tile.type === 'static' ? (
										<AppsIcon />
									) : (
										<AppRegistrationIcon />
									)}
								</ListItemIcon>
								<ListItemText
									className={listItemText}
								>{`${tile.label}`}</ListItemText>
								<ListItemIcon>
									<DeleteIcon
										className={deleteIcon}
										onClick={() => {
											onTileRemove(tile.universalUniqueIdentifier);
										}}
									/>
								</ListItemIcon>
							</ListItemButton>
						</ListItem>
					);
				})}
			</List>
		</Box>
	);
};

SelectedTilesList.propTypes = {
	availableTiles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	onTileRemove: PropTypes.func,
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(SelectedTilesList));
