import React from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Widget from '../Widget';
import { reporterActions } from '../../containers/Reporter';

import reportViewList from '../../containers/ReportViews/constants/reportViewList';
import getReportIds from '../../helpers/reporter/getReportIds';
import findReportItemInList from '../../helpers/reporter/findReportItemInList';
import safeIsReportLoading from '../../helpers/reporter/safeIsReportLoading';

import Container from '@mui/material/Container';

const styles = ({ theme }) => ({
	root: {
		display: 'flex',
		justifyContent: 'center',
		flex: 1,
		marginTop: '10px'
	}
});

const ReportController = (props) => {
	const {
		classes,
		reportViewId,
		OverrideReportComponent,
		getReportOptions,
		optionsList,
		reportDataList
	} = props;
	const { root } = classes;

	React.useEffect(() => {
		if (!OverrideReportComponent && reportViewList.length > 0) {
			const reportIds = getReportIds(reportViewId, reportViewList);

			reportIds.forEach((report) => {
				getReportOptions({
					reportId: report.id,
					uuid: reportViewId
				});
			});
		}
	}, [
		reportViewId,
		OverrideReportComponent,
		reportViewList,
		getReportIds,
		getReportOptions
	]);

	const reportOptions = findReportItemInList(optionsList, reportViewId);
	const reportData = findReportItemInList(reportDataList, reportViewId);
	const reportIsLoading = safeIsReportLoading(reportDataList, reportViewId);

	return (
		<Container className={root}>
			{OverrideReportComponent ? (
				<OverrideReportComponent
					data={reportData.data}
					isLoading={reportIsLoading}
					hasBeenLoaded={reportData.hasBeenLoaded}
				/>
			) : (
				<Widget
					reportViewId={reportViewId}
					reportData={reportData}
					options={reportOptions}
				/>
			)}
		</Container>
	);
};

ReportController.propTypes = {
	reportViewId: PropTypes.string,
	OverrideReportComponent: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.object
	]),
	data: PropTypes.object,
	optionsList: PropTypes.object,
	reportDataList: PropTypes.object,
	getReportOptions: PropTypes.func,
	executeReport: PropTypes.func,
	classes: PropTypes.object
};

const mapStateToProps = (state) => {
	return {
		optionsList: state.reporterReducer.get('reportOptions'),
		reportDataList: state.reporterReducer.get('reportData')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getReportOptions: (payload) => {
			dispatch(reporterActions.getReportOptions(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(ReportController));
