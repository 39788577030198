import React from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { withTranslation } from 'react-i18next';

import Container from '@mui/material/Container';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import TableChartSharpIcon from '@mui/icons-material/TableChartSharp';
import CodeIcon from '@mui/icons-material/Code';
import BarChartIcon from '@mui/icons-material/BarChart';
import PieChartIcon from '@mui/icons-material/PieChart';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import PivotTableChartIcon from '@mui/icons-material/PivotTableChart';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import AlignHorizontalLeftIcon from '@mui/icons-material/AlignHorizontalLeft';

const _chartTypes = ['table', 'line', 'json'];

const _chartTypeIcons = {
	table: TableChartSharpIcon,
	bar: BarChartIcon,
	pie: PieChartIcon,
	counter: TextSnippetIcon,
	line: ShowChartIcon,
	pivot: PivotTableChartIcon,
	kpi: AnalyticsIcon,
	flag: AlignHorizontalLeftIcon,
	json: CodeIcon
};

const styles = (theme) => ({
	container: {
		display: 'flex',
		margin: '10px 0px 10px 0px'
	},
	viewTypeIcon: {
		color: theme.palette.widget.viewTypeSelector.icons.actionIcon
	},
	selectedIcon: {
		color: theme.palette.widget.viewTypeSelector.icons.actionIconSelected
	}
});

const WidgetViewer = (props) => {
	const { classes, t, view, onUpdateView, noData } = props;
	const { container, viewTypeIcon, selectedIcon } = classes;

	const handleViewTypeSelection = (event, newAlignment) => {
		onUpdateView(newAlignment);
	};

	return (
		<Container maxWidth={false} className={container}>
			<ToggleButtonGroup
				value={view}
				disabled={noData}
				exclusive
				onChange={handleViewTypeSelection}
				aria-label="view type"
			>
				{_chartTypes.map((chartType) => {
					const ChartIcon = _chartTypeIcons[chartType];
					return (
						<ToggleButton key={chartType} value={chartType}>
							<ChartIcon
								className={clsx(
									viewTypeIcon,
									view === chartType && !noData && selectedIcon
								)}
							/>
						</ToggleButton>
					);
				})}
			</ToggleButtonGroup>
		</Container>
	);
};

WidgetViewer.propTypes = {
	view: PropTypes.string,
	noData: PropTypes.bool,
	onUpdateView: PropTypes.func,
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(WidgetViewer));
