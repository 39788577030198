export default {
	generic: {
		buttons: {
			add: 'Add',
			cancel: 'Cancel',
			close: 'Close',
			confirm: 'Confirm',
			delete: 'Delete',
			submit: 'Submit',
			disable: 'Disable',
			edit: 'Edit',
			generate: 'Generate',
			download: 'Download',
			modify: 'Modify',
			setup: 'Setup',
			start: 'Start',
			submit: 'Submit',
			update: 'Update'
		},
		error: {
			nodata: 'No data to display'
		}
	},
	components: {
		Accounts: {
			MultiFactorAuthenticationSection: {
				mfa: 'Multi-Factor Authentication',
				enabled: 'Enabled',
				notConfigured: 'Not Configured'
			}
		},
		CurrentInventory: {
			pageTitle: 'Current Inventory',
			CurrentInventoryList: {
				groupBy: 'Group By',
				processor: 'Processor',
				dateRange: 'Date Range',
				tableColumnDefinitions: {
					cardNumber: 'Card Number',
					customerName: 'Customer Name',
					amount: 'Amount',
					card: 'Card',
					mid: 'MID',
					chargebackDate: 'Chargeback Date',
					reasonCode: 'Reason Code',
					expiration: 'Expiration'
				},
				expiringSoon: 'Expiring Soon',
				representmentBuilder: 'Representment Builder',
				generateDocument: 'Generate Document',
				noData: 'No Data for the current date range.',
				dataError: 'There was an error loading the current inventory: {{error}}'
			}
		},
		DashboardControl: {
			groupManagement: 'Groups'
		},
		forms: {
			UserInformation: {
				firstName: 'First Name',
				lastName: 'Last Name',
				phone: 'Phone',
				email: 'Email'
			},
			OrganizationInformation: {
				organization: 'Organization',
				techContact: 'Tech Contact',
				techContactEmail: 'Tech Contact Email',
				techContactPhone: 'Tech Contact Phone'
			},
			NotificationSettings: {
				showToastNotifications: 'Show Toast Notifications'
			}
		},
		GroupManagement: {
			pageTitle: 'Manage My Groups',
			ActionRow: {
				createGroup: 'Create Group'
			}
		},
		Layout: {
			Footer: {
				copywrite: 'My Basic Copywrite'
			},
			Navbar: {
				Navigation: {
					reports: 'Reports',
					currentInventory: 'Current Inventory',
					recoveryHistory: 'Recovery History'
				},
				actionButtons: {
					screenshot: 'Screenshot',
					notifications: 'Notifications'
				},
				routes: {
					home: 'Home',
					'log-in': 'Log in',
					register: 'Register',
					account: 'My Account'
				}
			},
			Router: {
				loading: 'Loading...'
			}
		},
		modals: {
			AddReportToDashboard: {
				addReportToDashboard: 'Add Report To Dashboard',
				successfullyAdded: 'Tile Has Been Added To Dashboard'
			},
			AttachmentViewer: {
				viewAttachment: 'View Attachment'
			},
			createLayout: {
				createLayout: 'Create Layout',
				cloneExistingLayout: 'Clone Existing Layout',
				newLayout: 'New Layout',
				navigation: {
					back: 'Back',
					next: 'Next',
					close: 'Close',
					submit: 'Submit',
					backToManage: 'Back to Manage screen',
					confirmClose:
						'Are you sure you wish to cancel? All current progress will be lost.'
				},
				steps: {
					cloneOrNew: 'Creation Type',
					tileSelection: 'Layout / Tile Selection',
					nameAndOrder: 'Name Layout and Order Tiles',
					completed: 'Completed'
				},
				instructions: {
					cloneOrNew: 'Select how you would like to create a layout:',
					cloneLayout: {
						selectUser: 'Select a user to view cloneable layouts.',
						selectLayout:
							'Select which layout you wish to clone. Viewing layouts of ',
						cloneLayout: 'Clone Layout',
						availableLayouts: 'available layouts',
						filters: 'Filter(s)',
						tiles: 'Tile(s)'
					},
					addTiles:
						'Add Tiles to the new layout (at least 1 is required to continue):',
					selectedTiles: 'Selected Tiles:',
					nameAndOrder:
						'Give your new layout a name and order the tiles as you would like:'
				}
			},
			EditAvatar: {
				updateAvatar: 'Update Avatar'
			},
			EditTile: {
				header: 'Tile Settings',
				editTitle: 'Title',
				title: 'Title',
				editOptions: 'Filters'
			},
			ManageDashboard: {
				manageDashboard: 'Manage Dashboard',
				createLayout: 'Create Layout',
				update: 'Update',
				edit: 'Edit',
				delete: 'Delete',
				restore: 'Restore',
				setAsDefault: 'Set As Default'
			},
			MultiFactorAuth: {
				multiFactorAuthSetup: 'Setup Multi-Factor Authentication',
				scanWithApp: 'Scan the QR code above with your MFA app.',
				typeSecret:
					'If scanning is not available, type the secret configuration key below into your MFA app.',
				hideSecretKey: 'Hide Secret Configuration Key',
				showSecretKey: 'Show Secret Configuration Key',
				afterConfiguredApplication:
					'After the application has been configured, please enter the authentication code displayed in the app in the box below and click Activate',
				enterYourCode: 'Enter the code displayed on your MFA App',
				authenticationCode: 'Authentication Code:',
				code: 'Code',
				activate: 'Activate MFA',
				deactivate: 'Deactivate MFA',
				submit: 'Submit',
				allSetUp: "You're All Set Up!",
				disableMfa:
					'If you are sure you wish to disable MFA, enter your code and click confirm.'
			}
		},
		dashboardTiles: {
			chargebackKPIsTile: {
				header: 'Chargeback KPIs',
				transactions: 'Transaction(s)',
				chargebacks: 'Chargeback(s)'
			},
			DashboardWidgetTile: {
				filtersRequired: 'Filters required to render tile.',
				instructionsPartOne: 'Please use the ',
				instructionsPartTwo: ' icon to select filters.',
				noData: 'No Data Was Found For The Selected Filters',
				confirmDeletion: 'Are you sure you wish to delete this tile?',
				successMessage: 'Tile Successfully Removed',
				unableToDisplay:
					"Whoops! This data isn't viewable as {{dataViewType}}.",
				selectAnotherViewType: 'Please select another view type.'
			}
		},
		RecoveryHistory: {
			pageTitle: 'Recovery History',
			RecoveryHistoryManager: {
				summary: 'Summary',
				totalValue: 'Total Value: $',
				noData: 'No Data Was Found For the Date Range Selected',
				pending: 'Pending',
				misses: 'Misses',
				wins: 'Wins',
				chargebackReasons: 'Chargeback Reasons',
				reason: 'Reason',
				chargebackDollars: 'Chargebacks($)',
				recoveryDollars: 'Recovery($)',
				winPercent: 'Win %',
				chargebackAmount: 'Chargeback Amount',
				missesPlusPending: 'Misses + Pending',
				recoveryAmount: 'Recovery Amount'
			}
		},
		Representment: {
			RepresentmentWrapper: {
				buttons: {
					generate: 'Generate'
				},
				notFound: 'Unable to retrieve representment data'
			},
			Overview: {
				label: 'Overview',
				mid: 'MID',
				billingDescriptor: 'Billing Descriptor',
				billingPhoneNumber: 'Billing Phone Number',
				programDescription: 'Program Description'
			},
			ChargebackInformation: {
				label: 'Chargeback Information',
				code: 'Chargeback Reason Code',
				amount: 'Chargeback Amount',
				postedOn: 'Chargeback Date',
				caseId: 'Chargeback Case Number'
			},
			CustomerInformation: {
				label: 'Customer Information',
				billingName: 'Name',
				customerId: 'Customer Number',
				phoneNumber: 'Phone Number',
				email: 'Email',
				address: 'Address'
			},
			RefundInformation: {
				label: 'Refund Information',
				maskedCardNumber: 'Masked Card Number',
				settledOn: 'Refund Billing Date',
				settledAmount: 'Refund Amount',
				merchantTransactionId: 'Refund Invoice',
				acquirerReferenceNumber: 'ARN of Refund',
				saleInvoice: 'Sale Invoice',
				saleArn: 'ARN of Sale'
			},
			TransactionDetails: {
				label: 'Transaction Details',
				maskedCardNumber: 'Masked Card Number',
				settledOn: 'Transaction Billing Date',
				settledAmount: 'Transaction Amount',
				addressVerificationServiceResponse: 'AVS Response',
				authorizationCode: 'Authorization Code',
				authorizedOn: 'Authorization Date',
				acquirerReferenceNumber: 'ARN',
				paymentCardAssociationTransactionId: 'Association Transaction Id',
				paymentCardVerificationValueResponse: 'CVV Response'
			},
			TransactionsList: {
				label: 'Previously Billed Undisputed Transactions',
				noData: 'No Transaction Data Found'
			},
			ChargebackReversalReasons: {
				chargebackReversalReasons: 'Chargeback Reversal Reasons',
				moveUp: 'Move Up',
				moveDown: 'Move Down'
			}
		},
		Representments: {
			ActiveRepresentmentListItem: {
				tooltips: {
					edited: 'Edited',
					expiringSoon: 'Expiring Soon'
				},
				buttons: {
					markAsSent: 'Mark As Sent'
				}
			},
			ActiveRepresentments: {
				text: {
					items: 'Item(s)'
				}
			},
			ErrorIndicator: {
				failedToLoad: 'Failed To Load - Please Try Again'
			},
			AllCaughtUp: {
				detailText: "You're All Caught Up!"
			},
			NothingInProgress: {
				detailText: 'Nothing In Progress'
			},
			NothingToDisplay: {
				detailText: 'No Matching Results'
			},
			StatusSelector: {
				new: 'New',
				inProgress: 'In Progress',
				completed: 'Completed',
				expired: 'Expired'
			},
			Filter: {
				collapseAll: 'Collapse All',
				expandAll: 'Expand All'
			}
		},
		staticReportViews: {
			ChargebackItemizationView: {
				OptionSelector: {
					cardTypes: 'Card Type(s)',
					bankNames: 'Banks',
					reconcileStart: 'Reconcile Start Date',
					reconcileEnd: 'Reconcile End Date',
					merchantCode: 'Merchant Code'
				},
				RecordingsModal: 'Recording(s) for Order Number: '
			},
			ChargebackSummaryView: {
				OptionSelector: {
					lookupDate: 'Lookup Date',
					processor: 'Processor'
				}
			},
			DailyChargebackReport: {
				TwelveMonthLook: {
					title: '12 Month Forecast',
					noData: 'No Data Returned',
					submitToLoad: 'Submit Filters To Load',
					OptionsSelector: {
						lookupDate: 'Lookup Date',
						processor: 'Processor',
						mids: 'MID(s)'
					}
				},
				MonthlySummaryByMid: {
					title: 'Monthly Summary By MID',
					noData: 'No Data Returned',
					submitToLoad: 'Submit Filters To Load',
					OptionsSelector: {
						lookupDate: 'Lookup Date',
						processor: 'Processor',
						mids: 'MID(s)'
					}
				}
			}
		},
		ReportViews: {
			ReportCard: {
				configurable: 'Configurable',
				suggestedDisplayStyle: 'Suggested Display Style',
				SuggestedViewTypeItem: {
					bar: 'Bar',
					pie: 'Pie',
					table: 'Table',
					line: 'Line',
					pivot: 'Pivot'
				}
			}
		},
		Widget: {
			WidgetController: {
				submit: 'Submit',
				selectFilters: 'Select Filters'
			},
			WidgetViewer: {
				loadingOptions: 'Filters Are Loading ...',
				noData: 'No Data Found For Selected Filters',
				submitToLoad: 'Submit Filters To Load Report',
				loadingReport: 'Loading Report ...',
				actionSection: {
					table: 'Table',
					lineGraph: 'Line Graph',
					json: 'JSON',
					download: 'Download To CSV'
				},
				FullTable: {
					columns: 'Columns',
					columnSelect: 'Select Columns',
					selectAll: 'Select All',
					deselectAll: 'Deselect All'
				},
				LineGraph: {
					xAxis: 'Date',
					yAxis: 'Count'
				}
			}
		}
	},
	pages: {
		home: {
			label: 'Dashboard Home',
			subtitle: 'Version 0.1'
		},
		login: {
			'forgot-password': 'Forgot password?',
			header: 'Log In',
			input: {
				password: 'Password',
				username: 'Username'
			},
			submit: 'Log In'
		},
		NewPassword: {
			header: 'Set Your New Password',
			temporaryPassword: 'Temporary Password:',
			newPassword: 'New Password:',
			confirm: 'Confirm New Password:',
			validation: {
				characters: 'Must contain between 8-20 characters',
				header: 'Password Requirements',
				letters: 'Must contain at least one uppercase and lowercase letter',
				number: 'Must contain at least one number',
				special:
					'Must contain at least one special character (i.e. !, @, _ etc.)'
			}
		},
		notifications: {
			deleteModal: {
				label: 'Delete Entry',
				detailMessage:
					'Are you sure you want to delete this notification detail?',
				notificationMessage:
					'Are you sure you want to delete this notification?'
			},
			general: {
				active: 'Active Notifications',
				archive: 'Archived Notifications',
				defaultNoData: 'Nothing To Show',
				info: 'Info',
				lastEntry: 'Latest Entry:',
				loading: 'Loading...',
				noDetails: 'No Further Details For This Notification',
				noEntries: 'No Entries Have Been Added To This Stream',
				noArchive: 'No Archived Notifications To Show',
				severe: 'Severe',
				unassigned: 'Unassigned',
				warning: 'Warning'
			},
			inputs: {
				description: 'Description*',
				select: 'Please Select One',
				selectUser: 'Please Select A User',
				title: 'Title*',
				type: 'Type*',
				updateTitle: 'Update Title Here',
				users: 'Users*'
			},
			piechart: {
				noNotifications: 'No Current Notifications',
				total: 'Total',
				unassigned: 'Unassigned'
			},
			timeline: {
				addButton: 'Add',
				addNew: 'Add Notification',
				addNewDetail: 'Add New Detail',
				assigned: 'Assigned To: ',
				cancelButton: 'Cancel',
				createdBy: 'Created By: ',
				createdOn: 'Submitted On:',
				subheader: 'Fill Out Fields To Add New Detail',
				tags: 'Tags: ',
				title: 'Add New Notification',
				loading: 'Loading...',
				select: 'Please Select A Stream',
				stream: 'Select A Notification Stream',
				update: 'Update',
				updatedOn: 'Updated On:'
			}
		},
		reports: {
			chargebacks: {
				'daily-chargeback-report': {
					cbunits: 'CB Units Over',
					cdrn: 'Verifi CDRN',
					cbreduction: 'CB Reduction %',
					cbmitigation: "CB's & Mitigation %",
					ethoca: 'Ethoca Issuer Direct Alert',
					mitigation: 'Mitigation',
					over: 'Transactions Over',
					percent: 'Chargeback %',
					precb: 'MasterCard Pre-CB',
					received: 'Chargebacks Rcvd',
					rdr: 'Visa RDR',
					subheaders: {
						actual: 'Actual',
						delta: 'Delta',
						forecast: 'Forecast',
						percent: 'Delta (%)'
					},
					title: 'Daily Chargeback Report',
					total: "Total CB's + Mitigiation Units",
					transactions: 'Transactions'
				},
				header: {
					title: 'Chargeback Reports'
				},
				itemization: {
					nodata: 'No data to display',
					submitToLoad: 'Submit Filters To View Report',
					table: {
						columns: 'Select Columns',
						columnselect: 'Select Columns To Display',
						deselectall: 'Deselect All',
						selectall: 'Select All'
					},
					title: 'Chargeback Itemization'
				},
				summary: {
					actions: {
						daily: 'Daily',
						fourteen: '14-day'
					},
					collapse: 'Collapse',
					columnnames: {
						chargebackmargin: 'Chargeback Margin',
						chargebackpercent: 'Chargeback % MTD',
						forecast: 'Forecast Chargebacks MTD',
						mtd: 'Month-to-Date',
						mid: 'Mid',
						yesterday: 'Yesterday'
					},
					expand: 'Expand',
					selectprocessor: 'Select A Processor',
					title: 'Chargeback Summary',
					total: {
						daily: 'Total Yesterday',
						forecast: 'Total Forecast MTD',
						monthly: 'Total Month-to-Date',
						total: 'Total:'
					}
				}
			}
		},
		users: {
			columns: {
				avatar: 'Avatar',
				email: 'Email',
				name: 'Name',
				phone: 'Phone',
				username: 'Username'
			}
		}
	}
};
