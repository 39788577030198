import { fromJS } from 'immutable';
import * as actionTypes from './actionTypes';

export const initialState = fromJS({
	hasBeenLoaded: false,
	isLoading: false,
	users: []
});

export const usersReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_USERS:
			return state.set('isLoading', true);

		case actionTypes.GET_USERS_COMPLETED:
			return state
				.set('users', action.payload)
				.set('hasBeenLoaded', true)
				.set('isLoading', false);

		case actionTypes.GET_USERS_FAILED:
			return state
				.set('users', [])
				.set('hasBeenLoaded', false)
				.set('isLoading', false);

		default:
			return state;
	}
};
