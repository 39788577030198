export default function (list, uuid) {
	let isLoading = false;

	const listItem = list.get(uuid);

	if (listItem) {
		isLoading = listItem.isLoading;
	}

	return isLoading;
}
