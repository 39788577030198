import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import CircularProgress from '@mui/material/CircularProgress';

const styles = (theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'row',
		flex: 1,
		margin: '10px',
		justifyContent: 'flex-start'
	},
	listContainer: {
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		borderRight: `1px solid ${theme.palette.groupManagement.divider}`
	},
	listItem: {
		display: 'flex'
	}
});

const _renderInventoryList = (
	isLoading,
	hasBeenLoaded,
	groupsList,
	selectedGroup,
	onClickGroup,
	classes,
	t
) => {
	const { listContainer } = classes;
	let rendered = <></>;

	if (isLoading || !hasBeenLoaded) {
		rendered = (
			<div>
				<CircularProgress />
			</div>
		);
	} else if (hasBeenLoaded && groupsList) {
		rendered = (
			<List spacing={2} className={listContainer}>
				{groupsList.map((group) => {
					return (
						<ListItem key={group.id}>
							<ListItemButton
								selected={selectedGroup.id === group.id}
								onClick={() => onClickGroup(group)}
							>
								<ListItemText primary={group.label} />
							</ListItemButton>
						</ListItem>
					);
				})}
			</List>
		);
	}

	return rendered;
};

export const GroupsList = (props) => {
	const {
		groupsList,
		groupsAreLoading,
		groupsHaveBeenLoaded,
		selectedGroup,
		onClickGroup,
		classes,
		t
	} = props;
	const { container } = classes;

	return (
		<div className={container}>
			{_renderInventoryList(
				groupsAreLoading,
				groupsHaveBeenLoaded,
				groupsList,
				selectedGroup,
				onClickGroup,
				classes,
				t
			)}
		</div>
	);
};

GroupsList.propTypes = {
	groupsList: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	groupsAreLoading: PropTypes.bool,
	groupsHaveBeenLoaded: PropTypes.bool,
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(GroupsList));
