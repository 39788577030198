import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@mui/styles';
import RowItem from './RowItem';
import { connect } from 'react-redux';
import findReportItemInList from '../../../../helpers/reporter/findReportItemInList';
import { reporterActions } from '../../../../containers/Reporter';

import Stack from '@mui/material/Stack';

const styles = (theme) => ({});

const RecentNotifications = (props) => {
	const { height, width, tileProperties, reportData, getTileData } = props;

	let tileData;

	if (reportData && tileProperties) {
		tileData = findReportItemInList(
			reportData,
			tileProperties.universalUniqueIdentifier
		);
	}

	React.useEffect(() => {
		if (
			tileProperties &&
			(!tileData || (!tileData.hasBeenLoaded && !tileData.isLoading))
		) {
			getTileData({
				id: tileProperties.reportId,
				uuid: tileProperties.universalUniqueIdentifier
			});
		}
	}, [tileProperties, tileData, getTileData]);

	return (
		<Stack
			direction="column"
			spacing={2}
			mt={2}
			width={width * 0.9}
			height={height * 0.9}
			justifyContent="flex-start"
		>
			{tileData &&
				tileData.hasBeenLoaded &&
				tileData.data.map((rowData, index) => {
					return (
						<RowItem
							key={index}
							itemData={rowData}
							lastIndex={tileData.data.length === index + 1 ? true : false}
						/>
					);
				})}
		</Stack>
	);
};

RecentNotifications.propTypes = {
	tileProperties: PropTypes.object,
	classes: PropTypes.object,
	t: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		reportData: state.reporterReducer.get('reportData')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getTileData: (payload) => {
			dispatch(reporterActions.executeReport(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(withTranslation()(RecentNotifications)));
