import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { cloneLayoutActions } from '../../../../../../containers/CloneLayout';
import isDataEmpty from '../../../../../../helpers/shared/isDataEmpty';
import UserLayoutList from './UserLayoutsList';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

import BackspaceIcon from '@mui/icons-material/Backspace';

const styles = (theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		padding: '5px 10px 5px 10px'
	},
	instructionsRow: {
		display: 'flex',
		flexDirection: 'row',
		color: theme.palette.createLayoutModal.instructionsText,
		fontSize: '1rem',
		padding: '10px'
	},
	selectedUserContainer: {
		display: 'flex',
		flexDirection: 'row',
		borderRadius: '5px',
		marginLeft: '3px',
		marginRight: '3px',
		paddingLeft: '5px',
		paddingRight: '2px',
		backgroundColor:
			theme.palette.createLayoutModal.cloneLayout.selectLayout
				.selectedUserNameBackground,
		color:
			theme.palette.createLayoutModal.cloneLayout.selectLayout
				.selectedUserNameText
	},
	removeIcon: {
		marginLeft: '3px',
		marginRight: '3px',
		color:
			theme.palette.createLayoutModal.cloneLayout.selectLayout
				.removeSelectedUserIcon,
		'&:hover': {
			color: `${theme.palette.createLayoutModal.cloneLayout.selectLayout.removeSelectedUserIconHover} !important`,
			cursor: 'pointer'
		}
	},
	box: {
		display: 'flex',
		justifyContent: 'center',
		maxHeight: '400px',
		flex: 1
	},
	loadingContainer: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: '5%'
	}
});

const _renderLayoutList = (
	userLayouts,
	userLayoutsAreLoading,
	userLayoutsHaveBeenLoaded,
	userLayoutsError,
	selectedLayout,
	setSelectedLayout,
	classes
) => {
	const { loadingContainer } = classes;

	let rendered = <></>;

	if (
		!userLayoutsAreLoading &&
		userLayoutsHaveBeenLoaded &&
		isDataEmpty(userLayouts)
	) {
		rendered = <div>No Layouts To Display</div>;
	} else if (!userLayoutsAreLoading && userLayoutsHaveBeenLoaded) {
		rendered = (
			<UserLayoutList
				selectedUserLayouts={userLayouts}
				setSelectedLayout={setSelectedLayout}
				selectedLayout={selectedLayout}
			/>
		);
	} else if (!userLayoutsAreLoading && userLayoutsError) {
		// TODO IMPLEMENT ERROR HANDLING
	} else {
		rendered = (
			<div className={loadingContainer}>
				<CircularProgress />
			</div>
		);
	}

	return rendered;
};

export const SelectLayout = (props) => {
	const {
		classes,
		t,
		selectedUser,
		setSelectedLayout,
		selectedLayout,
		userLayouts,
		userLayoutsAreLoading,
		userLayoutsHaveBeenLoaded,
		userLayoutsError,
		getSelectedUserLayouts
	} = props;
	const {
		container,
		instructionsRow,
		selectedUserContainer,
		removeIcon,
		box
	} = classes;

	React.useEffect(() => {
		if (
			!userLayoutsHaveBeenLoaded &&
			!userLayoutsAreLoading &&
			!userLayoutsError
		) {
			getSelectedUserLayouts({
				userId: selectedUser.id
			});
		}
	}, [
		selectedUser,
		userLayoutsHaveBeenLoaded,
		userLayoutsAreLoading,
		userLayoutsError
	]);

	const onClear = () => {
		const { onClearUser, clearUserLayouts } = props;
		onClearUser(undefined);
		clearUserLayouts();
	};

	return (
		<div className={container}>
			<div className={instructionsRow}>
				{`${t(
					'components.modals.createLayout.instructions.cloneLayout.selectLayout'
				)}`}
				<span className={selectedUserContainer}>
					<Typography>{`${selectedUser.firstName} ${selectedUser.lastName}`}</Typography>
					<BackspaceIcon onClick={onClear} className={removeIcon} />
				</span>
			</div>
			<Box className={box}>
				{_renderLayoutList(
					userLayouts,
					userLayoutsAreLoading,
					userLayoutsHaveBeenLoaded,
					userLayoutsError,
					selectedLayout,
					setSelectedLayout,
					classes
				)}
			</Box>
		</div>
	);
};

SelectLayout.propTypes = {
	selectedUser: PropTypes.object,
	onClearUser: PropTypes.func,
	userLayouts: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	setSelectedLayout: PropTypes.func,
	selectedLayout: PropTypes.object,
	userLayoutsAreLoading: PropTypes.bool,
	userLayoutsHaveBeenLoaded: PropTypes.bool,
	userLayoutsError: PropTypes.object,
	clearUserLayouts: PropTypes.func,
	classes: PropTypes.object,
	t: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		userLayouts: state.cloneLayoutReducer.getIn(['userLayouts', 'data']),
		userLayoutsAreLoading: state.cloneLayoutReducer.getIn([
			'userLayouts',
			'isLoading'
		]),
		userLayoutsHaveBeenLoaded: state.cloneLayoutReducer.getIn([
			'userLayouts',
			'hasBeenLoaded'
		]),
		userLayoutsError: state.cloneLayoutReducer.getIn(['userLayouts', 'error'])
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getSelectedUserLayouts: (payload) => {
			dispatch(cloneLayoutActions.getUserLayouts(payload));
		},
		clearUserLayouts: (payload) => {
			dispatch(cloneLayoutActions.clearUserLayouts(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(withTranslation()(SelectLayout)));
