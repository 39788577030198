import React, { Fragment, useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import './styles.css';
import { withStyles } from '@mui/styles';
import { withSize } from 'react-sizeme';
import { PivotViewComponent } from '@syncfusion/ej2-react-pivotview';

import Typography from '@mui/material/Typography';

const styles = (theme) => ({});

const dataSourceOrchestration = (dataSource) => {
	return {
		enableSorting: false,
		columns: [{ name: 'WeekEnd' }, { name: 'WeekIndex' }],
		valueSortSettings: { headerDelimiter: ' - ' },
		values: [
			{ name: 'WeeklyIndex', caption: 'Weekly Index' },
			{ name: 'Billing', caption: 'Billing In $' },
			{ name: 'Fraud', caption: 'Fraud In $' },
			{ name: 'FraudPercent', caption: 'Fraud %' },
			{ name: 'Index', caption: 'Index' }
		],
		dataSource: dataSource,
		rows: [{ name: 'Merchant' }],
		formatSettings: [
			// { name: '', format: 'C0' },
			{ name: 'Billing', format: 'C0' },
			{ name: 'Fraud', format: 'C0' },
			{ name: 'FraudPercent', format: '0.##%' },
			{ name: 'Index', format: '0.00' }
		],
		expandAll: false,
		filters: [],
		calculatedFieldSettings: [
			{ name: 'FraudPercent', formula: '"Sum(Fraud)"/"Sum(Billing)"' }
		],
		showGrandTotals: false
	};
};

// const exampleIndexHeader =
// 	'<th class="e-headercell e-firstcell e-columnsheader e-valuesheader e-movablefirst e-focus" role="columnheader" tabindex="0" aria-colindex="1" cell="[object Object]" aria-sort="none" aria-rowspan="1" aria-colspan="1" fieldname="Billing" index="1"><div e-mappinguid="grid-column5" class="e-headercelldiv"><div><div class="e-headertext e-cellvalue">Billing In $</div></div></div><div class="e-sortfilterdiv e-icons"></div><div class="e-rhandler e-rcursor" style="height: 30px;"></div></th>';

const PivotTableNoCalc = (props) => {
	const { classes, data, size } = props;
	const {} = classes;

	const colors = {
		backgroundColor: '#f5f5f5',
		borderColor: '#e0e0e0'
	};

	const getDistinct = (prop) => {
		return data
			.map((item) => item[prop])
			.filter((value, index, self) => self.indexOf(value) === index);
	};

	const _renderFirstLevelColumns = () => {
		const getFirstLevelColumns = getDistinct('WeekEnd');

		const style = {
			minWidth: '110px',
			border: '1px solid lightgray',
			backgroundColor: '#f2f2f2'
		};

		const headerStyle = {
			fontWeight: 600,
			fontSize: '13px',
			color: 'rgba(0, 0, 0, 0.8)'
		};

		return (
			<Fragment>
				<td rowSpan="2">
					<th
						// colSpan="1"
						scope="colgroup"
						style={{
							fontWeight: 500,
							fontSize: '13px',
							// lineHeight: '1.5',
							paddingLeft: '10px',
							paddingTop: '40px',
							color: 'rgba (0,0,0,0.87)'
						}}
					>
						Merchant
					</th>
				</td>
				<th colSpan="1" scope="colgroup"></th>
				{getFirstLevelColumns.map((column) => (
					<th colSpan="4" key={column} scope="colgroup" style={style}>
						<Typography variant="subtitle1" style={headerStyle}>
							{column}
						</Typography>
					</th>
				))}
			</Fragment>
		);
	};

	const _renderSecondLevelColumns = () => {
		const getFirstLevelColumnsCount = getDistinct('WeekEnd').length;

		const style = {
			minWidth: '110px',
			border: '1px solid lightgray',
			backgroundColor: '#f2f2f2'
		};

		const headerStyle = {
			fontWeight: 600,
			fontSize: '13px',
			color: 'rgba(0, 0, 0, 0.8)'
		};

		const createHeaders = () => {
			const headers = [];
			let i = 0;
			while (i < getFirstLevelColumnsCount) {
				headers.push(
					<Fragment key={i}>
						<th style={style} scope="col">
							<Typography variant="subtitle1" style={headerStyle}>
								Billings in $
							</Typography>
						</th>
						<th style={style} scope="col">
							<Typography variant="subtitle1" style={headerStyle}>
								Fraud in $
							</Typography>
						</th>
						<th style={style} scope="col">
							<Typography variant="subtitle1" style={headerStyle}>
								Fraud %
							</Typography>
						</th>
						<th style={style} scope="col">
							<Typography variant="subtitle1" style={headerStyle}>
								Index
							</Typography>
						</th>
					</Fragment>
				);
				i++;
			}
			return headers;
		};

		return (
			<Fragment>
				<th style={style} scope="col">
					<Typography variant="subtitle1" style={headerStyle}>
						WOW Index
					</Typography>
				</th>
				{createHeaders()}
				<th style={style} scope="col">
					<Typography variant="subtitle1" style={headerStyle}>
						Seven Week Index
					</Typography>
				</th>
			</Fragment>
		);
	};

	const getWeeklyIndexByRowName = (rowName) => {
		const weeklyIndexByRowName = {
			'Monthly Mow': '0.96x',
			'Coffee Club': '0.75x',
			'Baby Box': '0.82x',
			'Vino of the Month': '1.04x',
			'Paradise Parking': '0.82x',
			'Flowers By Friday': '2.08x',
			'Cigar Central': '1.13x',
			'Squeaky Clean Carwash': '0.85x',
			Total: '1.00x'
		};

		return weeklyIndexByRowName[rowName];
	};

	const getSevenWeekIndexByRowName = (rowName) => {
		const weekIndexByRowName = {
			'Monthly Mow': '0.91',
			'Coffee Club': '1.22',
			'Baby Box': '1.33',
			'Vino of the Month': '0.75',
			'Paradise Parking': '0.70',
			'Flowers By Friday': '1.91',
			'Cigar Central': '0.52',
			'Squeaky Clean Carwash': '0.63',
			Total: '1.00'
		};

		return weekIndexByRowName[rowName];
	};

	const _renderRowData = () => {
		const getDistinctRowNames = getDistinct('Merchant');

		const getRowDataByRowName = (rowName) => {
			const dataByRowName = data
				.filter((dataSet) => dataSet.Merchant === rowName)
				.map((dataSet) => {
					return {
						Billing: dataSet.Billing,
						Fraud: dataSet.Fraud,
						FraudPercent: dataSet.FraudPercent,
						Index: dataSet.Index
					};
				});
			return dataByRowName;
		};

		const formatNumber = (num) => {
			return `$${num.toLocaleString('en-US')}`;
		};

		const formatPercent = (num) => {
			return `${(parseFloat(num) * 100).toFixed(2)}%`;
		};

		const cellStyle = {
			textAlign: 'end',
			border: `1px solid ${colors.borderColor}`,
			paddingRight: '4px',
			backgroundColor: 'white'
		};

		const headerStyle = {
			fontWeight: 500,
			fontSize: '13px',
			color: 'rgba(0, 0, 0, 0.8)',
			nothing: 'two'
		};

		const getCellAltColorStyle = (rowName) => {
			const altRows = [
				'Coffee Club',
				'Vino of the Month',
				'Flowers By Friday',
				'Squeaky Clean Carwash'
			];

			if (altRows.includes(rowName)) {
				const cellStyleCopy = Object.assign({}, cellStyle, {
					backgroundColor: '#ebebeb'
				});

				if (rowName === 'Flowers By Friday') {
					cellStyleCopy.color = 'red';
				}

				return cellStyleCopy;
			} else {
				return cellStyle;
			}
		};

		return (
			<Fragment>
				{getDistinctRowNames.map((rowName) =>
					rowName === 'Total' ? (
						<tr key={rowName}>
							<th
								style={{
									display: 'flex',
									width: '300px',
									backgroundColor: '#f2f2f2',
									borderLeft: `1px solid ${colors.borderColor}`,
									borderBottom: `1px solid ${colors.borderColor}`,
									borderTop: `1px solid ${colors.borderColor}`,
									justifyContent: 'end',
									paddingRight: '10px',
									height: '30px',
									alignItems: 'bottom'
								}}
								scope="row"
							>
								<Typography variant="subtitle1" style={headerStyle}>
									{rowName}
								</Typography>
							</th>
							<td style={getCellAltColorStyle(rowName)}>
								<Typography variant="subtitle1" style={headerStyle}>
									{getWeeklyIndexByRowName(rowName)}
								</Typography>
							</td>
							{getRowDataByRowName(rowName).map((rowData) => (
								<Fragment
									key={`${rowName}-${Math.floor(Math.random() * 9000)}`}
								>
									<td style={getCellAltColorStyle(rowName)}>
										<Typography variant="subtitle1" style={headerStyle}>
											{formatNumber(rowData.Billing)}
										</Typography>
									</td>
									<td style={getCellAltColorStyle(rowName)}>
										<Typography variant="subtitle1" style={headerStyle}>
											{formatNumber(rowData.Fraud)}
										</Typography>
									</td>
									<td style={getCellAltColorStyle(rowName)}>
										<Typography variant="subtitle1" style={headerStyle}>
											{formatPercent(rowData.FraudPercent)}
										</Typography>
									</td>
									<td style={getCellAltColorStyle(rowName)}>
										<Typography variant="subtitle1" style={headerStyle}>
											{rowData.Index}
										</Typography>
									</td>
								</Fragment>
							))}
							<td style={getCellAltColorStyle(rowName)}>
								<Typography variant="subtitle1" style={headerStyle}>
									{getSevenWeekIndexByRowName(rowName)}
								</Typography>
							</td>
						</tr>
					) : (
						<tr key={rowName}>
							<th
								style={{
									display: 'flex',
									width: '300px',
									backgroundColor: '#f2f2f2',
									borderTop: `1px solid ${colors.borderColor}`,
									borderLeft: `1px solid ${colors.borderColor}`,
									fontWeight: 'lighter',
									height: '30px',
									alignItems: 'bottom',
									paddingLeft: '10px'
								}}
								scope="row"
							>
								<Typography variant="subtitle1" style={headerStyle}>
									{rowName}
								</Typography>
							</th>
							<td style={getCellAltColorStyle(rowName)}>
								<Typography
									variant="subtitle1"
									style={
										rowName === 'Flowers By Friday'
											? {
													...headerStyle,
													color: 'red',
													fontWeight: 'bold'
											  }
											: headerStyle
									}
								>
									{getWeeklyIndexByRowName(rowName)}
								</Typography>
							</td>
							{getRowDataByRowName(rowName).map((rowData, index) => (
								<Fragment
									key={`${rowName}-${Math.floor(Math.random() * 9000)}`}
								>
									<td style={getCellAltColorStyle(rowName)}>
										<Typography variant="subtitle1" style={headerStyle}>
											{formatNumber(rowData.Billing)}
										</Typography>
									</td>
									<td style={getCellAltColorStyle(rowName)}>
										<Typography variant="subtitle1" style={headerStyle}>
											{formatNumber(rowData.Fraud)}
										</Typography>
									</td>
									<td style={getCellAltColorStyle(rowName)}>
										<Typography variant="subtitle1" style={headerStyle}>
											{formatPercent(rowData.FraudPercent)}
										</Typography>
									</td>
									<td style={getCellAltColorStyle(rowName)}>
										<Typography
											variant="subtitle1"
											style={
												rowName === 'Flowers By Friday' &&
												(index === 0 || index === 1)
													? {
															...headerStyle,
															color: 'red',
															fontWeight: 'bold'
													  }
													: headerStyle
											}
										>
											{rowData.Index}
										</Typography>
									</td>
								</Fragment>
							))}
							<td style={getCellAltColorStyle(rowName)}>
								<Typography variant="subtitle1" style={headerStyle}>
									{getSevenWeekIndexByRowName(rowName)}
								</Typography>
							</td>
						</tr>
					)
				)}
			</Fragment>
		);
	};

	return (
		<Fragment>
			<div
				style={{
					display: 'flex',
					flexFlow: 'column',
					overflowX: 'scroll',
					maxWidth: '85vw',
					fontFamily:
						'"Roboto","Segoe UI","GeezaPro","DejaVu Serif","sans-serif","-apple-system","BlinkMacSystemFont"'
				}}
			>
				<table
					style={{
						clear: 'both',
						borderCollapse: 'collapse',
						borderSpacing: 0,
						width: '100%'
					}}
				>
					{/* <colgroup span="2"></colgroup> */}
					{/* <colgroup span="2"></colgroup> */}
					<tbody>
						<tr>{_renderFirstLevelColumns()}</tr>
						<tr>{_renderSecondLevelColumns()}</tr>
						{_renderRowData()}
					</tbody>
				</table>
			</div>
		</Fragment>
	);
};

PivotTableNoCalc.propTypes = {
	classes: PropTypes.object,
	data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	size: PropTypes.object
};

export default withStyles(styles)(
	withSize({ monitorHeight: true })(PivotTableNoCalc)
);
