import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import TransactionListRow from './TransactionListRow';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const styles = (theme) => ({
	container: {
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		padding: '10px',
		margin: '10px',
		borderRadius: '10px',
		border: `1px solid ${theme.palette.representment.sectionContainer.border}`,
		backgroundColor: theme.palette.representment.sectionContainer.background
	},
	sectionTitle: {
		backgroundColor:
			theme.palette.representment.sectionContainer.sectionTitleBackground,
		borderTopLeftRadius: '5px',
		borderTopRightRadius: '5px',
		width: 'fit-content',
		fontSize: '1rem',
		color: theme.palette.representment.sectionContainer.sectionText,
		margin: '-22px 0 0 2px',
		padding: '0 5px 0 5px'
	}
});

const _cellColumnDefinition = [
	'components.Representment.TransactionDetails.settledOn',
	'components.Representment.TransactionDetails.settledAmount',
	'components.Representment.TransactionDetails.maskedCardNumber',
	'components.Representment.TransactionDetails.authorizationCode',
	'components.Representment.TransactionDetails.acquirerReferenceNumber'
];

export const TransactionList = (props) => {
	const { classes, t, data } = props;
	const { container, sectionTitle } = classes;

	return (
		<div className={container}>
			<div className={sectionTitle}>
				{t('components.Representment.TransactionsList.label')}
			</div>
			<TableContainer>
				<Table sx={{ minWidth: 650 }} size="small">
					<TableHead>
						<TableRow>
							{_cellColumnDefinition.map((label, index) => {
								return <TableCell key={index}>{t(label)}</TableCell>;
							})}
						</TableRow>
					</TableHead>
					<TableBody>
						{data.length === 0 ? (
							<TableRow
								key={'no-data'}
								sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
							>
								<TableCell>
									{t('components.Representment.TransactionsList.noData')}
								</TableCell>
							</TableRow>
						) : (
							data.map((row, index) => {
								return <TransactionListRow key={index} row={row} />;
							})
						)}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	);
};

TransactionList.propTypes = {
	data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(TransactionList));
