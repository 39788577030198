import React from 'react';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';

const _determineOnClickFunction = (isMarkedForDeletion, isDefaultLayout, onMarkForDeletion, onRestore) => {
	let onClickFunction = () => {};

	if (!isDefaultLayout && isMarkedForDeletion) {
		onClickFunction = onRestore;
	} else if (!isDefaultLayout && !isMarkedForDeletion) {
		onClickFunction = onMarkForDeletion;
	}

	return onClickFunction;
};

export default function (isMarkedForDeletion, isDefaultLayout, onMarkForDeletion, onRestore) {
	return {
		toolTipText: isMarkedForDeletion ? 'components.modals.ManageDashboard.restore' : 'components.modals.ManageDashboard.delete',
		icon: isMarkedForDeletion ? <RestoreFromTrashIcon /> : <DeleteOutlineIcon/>,
		onClickFunction: _determineOnClickFunction(isMarkedForDeletion, isDefaultLayout, onMarkForDeletion, onRestore)
	};
};
