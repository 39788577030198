import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { successMessage, failureMessage } from '../ToastNotifications/actions';

import * as actions from './actions.js';
import * as actionTypes from './actionTypes.js';

export const getAlertsEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.GET_ALERTS),
		mergeMap(async (action) => {
			return {
				unreadMessages: 5
			};
		}),
		switchMap((res) => [
			actions.getAlertsCompleted(res),
			successMessage('Returned Alerts')
		]),
		catchError((error, source) =>
			merge(
				of(actions.getAlertsFailed(), failureMessage(error.message)),
				source
			)
		)
	);
