import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { tilesActions } from '../../../../../../containers/Tiles';
import shortUuid from 'short-uuid';
import deepCopy from '../../../../../../utilities/deepCopy';
import AvailableTilesList from './AvailableTilesList';
import SelectedTilesList from './SelectedTilesList';

import Divider from '@mui/material/Divider';

const styles = (theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		padding: '5px 10px 5px 10px'
	},
	instructionsRow: {
		display: 'flex',
		flexDirection: 'row',
		color: theme.palette.createLayoutModal.instructionsText,
		fontSize: '1rem',
		padding: '10px'
	},
	boxesContainer: {
		display: 'flex',
		flex: 1,
		flexDirection: 'row',
		maxHeight: '300px'
	},
	box: {
		display: 'flex',
		flexDirection: 'column',
		alignSelf: 'stretch',
		height: '100%',
		flex: 1
	},
	listHeader: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		color: theme.palette.createLayoutModal.instructionsText,
		fontSize: '1rem'
	},
	list: {
		paddingTop: '5px',
		paddingRight: '3px',
		overflow: 'auto'
	},
	listItemText: {
		display: 'flex',
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'flex-start'
	},
	deleteIcon: {
		color: theme.palette.createLayoutModal.removeIcon,
		'&:hover': {
			color: `${theme.palette.createLayoutModal.removeIconHover} !important`
		}
	},
	divider: {
		marginLeft: '20px',
		marginRight: '5px'
	}
});

// TODO - FIGURE OUT METHOD FOR STATIC DEFINED TILES
const _staticAvailableTiles = [
	{
		label: 'Chargeback KPIs',
		type: 'static',
		tileName: 'ChargebackKPIsTile'
	}
];

export const AddTiles = (props) => {
	const {
		classes,
		t,
		createdLayoutConfiguration,
		updateCreatedLayoutConfiguration,
		setActiveStepCompleted,
		availableTiles,
		availableTilesHaveBeenLoaded,
		availableTilesAreLoading,
		getAvailableTiles
	} = props;
	const { container, instructionsRow, boxesContainer, divider } = classes;

	React.useEffect(() => {
		if (createdLayoutConfiguration.tiles.length > 0) {
			setActiveStepCompleted(true);
		} else {
			setActiveStepCompleted(false);
		}
	}, [createdLayoutConfiguration, setActiveStepCompleted]);

	React.useEffect(() => {
		if (!availableTilesHaveBeenLoaded && !availableTilesAreLoading) {
			getAvailableTiles();
		}
	}, [
		availableTilesHaveBeenLoaded,
		availableTilesAreLoading,
		getAvailableTiles
	]);

	const onTileSelect = (tile) => {
		const generatedUuid = shortUuid.uuid();

		const updatedCreatedLayoutConfiguration = deepCopy(
			createdLayoutConfiguration
		);

		if (!tile.reportId) {
			updatedCreatedLayoutConfiguration.tiles.push({
				label: tile.label,
				tileName: tile.tileName,
				userIntefaceDefinition: tile.userIntefaceDefinition,
				universalUniqueIdentifier: generatedUuid
			});
		} else {
			updatedCreatedLayoutConfiguration.tiles.push({
				label: tile.label,
				reportId: tile.reportId,
				userIntefaceDefinition: 'dashboardWidget',
				universalUniqueIdentifier: generatedUuid
			});
		}

		updateCreatedLayoutConfiguration(updatedCreatedLayoutConfiguration);
	};

	const onTileRemove = (universalUniqueIdentifier) => {
		const updatedCreatedLayoutConfiguration = deepCopy(
			createdLayoutConfiguration
		);

		updatedCreatedLayoutConfiguration.tiles = updatedCreatedLayoutConfiguration.tiles.filter(
			(item) => item.universalUniqueIdentifier !== universalUniqueIdentifier
		);

		updateCreatedLayoutConfiguration(updatedCreatedLayoutConfiguration);
	};

	return (
		<div className={container}>
			<div className={instructionsRow}>
				{t('components.modals.createLayout.instructions.addTiles')}
			</div>
			<div className={boxesContainer}>
				<AvailableTilesList
					availableTiles={availableTiles}
					availableTilesHaveBeenLoaded={availableTilesHaveBeenLoaded}
					availableTilesAreLoading={availableTilesAreLoading}
					onTileSelect={onTileSelect}
				/>
				<Divider orientation="vertical" className={divider} />
				<SelectedTilesList
					selectedTiles={createdLayoutConfiguration.tiles}
					onTileRemove={onTileRemove}
				/>
			</div>
		</div>
	);
};

AddTiles.propTypes = {
	createdLayoutConfiguration: PropTypes.object,
	updateCreatedLayoutConfiguration: PropTypes.func,
	setActiveStepCompleted: PropTypes.func,
	availableTiles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	availableTilesHaveBeenLoaded: PropTypes.bool,
	availableTilesAreLoading: PropTypes.bool,
	getAvailableTiles: PropTypes.func,
	classes: PropTypes.object,
	t: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		availableTiles: state.tilesReducer.getIn(['availableTiles', 'data']),
		availableTilesHaveBeenLoaded: state.tilesReducer.getIn([
			'availableTiles',
			'hasBeenLoaded'
		]),
		availableTilesAreLoading: state.tilesReducer.getIn([
			'availableTiles',
			'isLoading'
		])
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getAvailableTiles: (payload) => {
			dispatch(tilesActions.getAvailableTiles(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(withTranslation()(AddTiles)));
