import React from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { sanitizeLabels } from '../../../../helpers/sanitizeLabels';
import moment from 'moment';

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

const styles = (theme) => ({
	genericInput: {
		marginTop: '5px',
		fontSize: '.8rem',
		minWidth: '150px',
		height: '55px'
	}
});

const DateInput = (props) => {
	const { classes, label, onUpdate, option, value } = props;
	const { genericInput } = classes;

	const handleSelectionUpdate = (value) => {
		const newDate = moment(value).format('YYYY-MM-DD');
		onUpdate(label, newDate);
	};

	return (
		<FormControl>
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<DatePicker
					label={sanitizeLabels(label)}
					value={value}
					onChange={(newValue) => {
						handleSelectionUpdate(newValue);
					}}
					renderInput={(params) => (
						<TextField {...params} size="small" className={genericInput} />
					)}
				/>
			</LocalizationProvider>
		</FormControl>
	);
};

DateInput.propTypes = {
	classes: PropTypes.object,
	label: PropTypes.string,
	onUpdate: PropTypes.func,
	option: PropTypes.object,
	value: PropTypes.string
};

export default withStyles(styles)(DateInput);
