import React from 'react';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';

const CounterSmallView = (props) => {
	// const { data } = props;

	// const prepareCounter = () => {
	// 	const getCounterLabels = Object.keys(data.results);
	// };

	return (
		<Container maxWidth={false}>
			<p>LABEL</p>
			<p>Count</p>
		</Container>
	);
};

CounterSmallView.propTypes = {
	data: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

export default CounterSmallView;
