import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@mui/styles';

import { modalActions } from '../../../containers/Modal';

import HeaderRow from '../shared/HeaderRow';
import LayoutList from './components/LayoutList';
import ActionRow from './components/ActionRow';

import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';

const styles = (theme) => ({
	modal: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		flex: 1
	},
	paper: {
		display: 'flex',
		height: '50vh',
		[theme.breakpoints.up('smallLaptop')]: {
			width: '50vw'
		},
		[theme.breakpoints.down('smallLaptop')]: {
			width: '80vw'
		},
		flexFlow: 'column',
		padding: '1rem'
	},
	layoutText: {
		paddingTop: '.5rem',
		fontWeight: '600',
		color: theme.palette.secondary.main
	}
});

export const ManageDashboardModal = (props) => {
	const { classes, isManageDashboardModalOpen, closeModal } = props;
	const { modal, paper } = classes;

	const onCloseModal = () => {
		const { closeModal } = props;

		closeModal();
	};

	const onClickCreateLayout = () => {
		const { closeModal, toggleCreateLayoutModal } = props;

		closeModal();
		toggleCreateLayoutModal();
	};

	return (
		<Modal
			open={isManageDashboardModalOpen}
			onClose={closeModal}
			className={modal}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Paper className={paper} elevation={1}>
				<HeaderRow
					translationPath={'components.modals.ManageDashboard.manageDashboard'}
					onClickCancel={onCloseModal}
				/>
				<ActionRow onClickCreateLayout={onClickCreateLayout} />
				<LayoutList />
			</Paper>
		</Modal>
	);
};

ManageDashboardModal.propTypes = {
	layouts: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	isManageDashboardModalOpen: PropTypes.bool,
	closeModal: PropTypes.func,
	toggleCreateLayoutModal: PropTypes.func,
	classes: PropTypes.object,
	t: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		isManageDashboardModalOpen: state.modalReducer.get(
			'isManageDashboardModalOpen'
		)
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		closeModal: () => {
			dispatch(modalActions.toggleManageDashboardModal());
		},
		toggleCreateLayoutModal: () => {
			dispatch(modalActions.toggleCreateLayoutModal());
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(withTranslation()(ManageDashboardModal)));
