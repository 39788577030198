import { fromJS } from 'immutable';
import * as actionTypes from './actionTypes';

export const initialState = fromJS({
	type: '',
	message: '',
	messageTime: ''
});

export const toastNotificationReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SUCCESS_MESSAGE:
			return state
				.set('message', action.payload)
				.set('messageTime', new Date())
				.set('type', 'success');

		case actionTypes.FAILURE_MESSAGE:
			return state
				.set('message', action.payload)
				.set('messageTime', new Date())
				.set('type', 'failure');

		case actionTypes.CLEAR_MESSAGE:
			return state.set('message', '').set('messageTime', '').set('type', '');

		default:
			return state;
	}
};
