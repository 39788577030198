export default [
	{
		id: 101,
		groupId: 1,
		category: 'Disputes',
		active: true,
		rule: 'Disputes accelerating at 2x the 30-day rolling average',
		alertLevel: 'severe'
	},
	{
		id: 102,
		groupId: 1,
		category: 'Refunds',
		active: true,
		rule:
			'Refund rate on new product SKU 573165 at 2x company average over last 14 days',
		alertLevel: 'warning'
	},
	{
		id: 103,
		groupId: 1,
		category: 'Fraud',
		active: true,
		rule: 'Monthly fraud % at issuing banks increased 3x over last 4 weeks',
		alertLevel: 'severe'
	},
	{
		id: 104,
		groupId: 1,
		category: 'Product',
		active: true,
		rule:
			'Received first transactions on 61253428 new product SKUs in the last 7 days',
		alertLevel: 'info'
	},
	{
		id: 105,
		groupId: 1,
		category: 'Disputes',
		active: true,
		rule:
			'Affiliate {{AFFILIATE}} dispute % has increased by 20% on first installment over the last 14 days',
		alertLevel: 'warning'
	},
	{
		id: 106,
		groupId: 1,
		category: 'List Source',
		active: true,
		rule: 'List source C23519 submitting transactions after 30 days of zero',
		alertLevel: 'info'
	},
	{
		id: 107,
		groupId: 1,
		category: 'Disputes',
		active: true,
		rule:
			'Visa disputes up 30% over the last 3 months with chargeback to RDR ratio shifting by 50%',
		alertLevel: 'severe'
	},
	{
		id: 108,
		groupId: 1,
		category: 'Authorizations',
		active: true,
		rule:
			'First attempt authorization rates on issuing banks down 15% over the last 21 days along with a 20% increase in fraud dollars 30 days prior',
		alertLevel: 'severe'
	},

	{
		id: 1,
		groupId: 1,
		category: 'Account Updater',
		active: true,
		rule:
			'Visa account updater chargebacks are 20 basis points over non updated accounts',
		alertLevel: 'severe'
	},
	{
		id: 2,
		groupId: 1,
		category: 'Account Updater',
		active: true,
		rule:
			'Visa account updater chargebacks on recurring payments 1-6 increased 30 basis points over prior month',
		alertLevel: 'severe'
	},
	{
		id: 3,
		groupId: 1,
		category: 'Mitigation',
		active: false,
		rule: 'Has not received any mitigation units in 3 days',
		alertLevel: 'warning'
	},
	{
		id: 4,
		groupId: 1,
		category: 'Mitigation',
		active: false,
		rule: 'Has not received any mitigation units in 6 days',
		alertLevel: 'severe'
	},
	{
		id: 5,
		groupId: 1,
		category: 'Mitigation',
		active: false,
		rule:
			'Mitigation Leakage has exceeded 20% one time in the past three weeks',
		alertLevel: 'severe'
	},
	{
		id: 6,
		groupId: 1,
		category: 'Mitigation',
		active: true,
		rule:
			'Visa Mitigation Leakage has exceeded 20% at least once in the past three months',
		alertLevel: 'severe'
	},
	{
		id: 7,
		groupId: 1,
		category: 'Mitigation',
		active: true,
		rule:
			'Mastercard Mitigation Leakage has exceeded 20% at least once in the past three months',
		alertLevel: 'severe'
	},
	{
		id: 8,
		groupId: 1,
		category: 'Card Distribution',
		active: true,
		rule: 'Has marginal bin sales greater than 20% of overall sales',
		alertLevel: 'severe'
	},
	{
		id: 9,
		groupId: 1,
		category: 'Card Distribution',
		active: false,
		rule: 'Has marginal bin sales greater than 15% of overall sales',
		alertLevel: 'warning'
	},
	{
		id: 10,
		groupId: 1,
		category: 'CS',
		active: true,
		rule:
			'Had over 60% of contacts to cs resulting in a dispute over the past 30 days',
		alertLevel: 'severe'
	},
	{
		id: 11,
		groupId: 1,
		category: 'CS',
		active: true,
		rule: 'Had zero CS contacts received yesterday',
		alertLevel: 'warning'
	},
	{
		id: 12,
		groupId: 1,
		category: 'CS',
		active: true,
		rule: 'Had zero CS contacts received over the past two days',
		alertLevel: 'severe'
	},
	{
		id: 13,
		groupId: 1,
		category: 'CS',
		active: true,
		rule: 'Had CS contacts drop 35% compared to last week',
		alertLevel: 'warning'
	},
	{
		id: 14,
		groupId: 1,
		category: 'CS',
		active: true,
		rule: 'Had CS contacts drop 50% compared to last week',
		alertLevel: 'severe'
	},
	{
		id: 15,
		groupId: 1,
		category: 'Disputes - CBs + Mitigation',
		active: true,
		rule: 'Exceeded excessive dispute threshold of 2.0%',
		alertLevel: 'severe'
	},
	{
		id: 16,
		groupId: 1,
		category: 'Disputes - CBs + Mitigation',
		active: true,
		rule:
			'7 day average indicates excessive dispute threshold will exceed 2.0%',
		alertLevel: 'severe'
	},
	{
		id: 17,
		groupId: 1,
		category: 'Disputes - CBs + Mitigation',
		active: true,
		rule:
			'7 day average indicates excessive dispute threshold will exceed 1.5%',
		alertLevel: 'warning'
	},
	{
		id: 18,
		groupId: 1,
		category: 'Disputes - CBs + Mitigation',
		active: false,
		rule:
			'Dispute Percentage Estimated over Threshold of 1.25% for Current Month',
		alertLevel: 'warning'
	},
	{
		id: 19,
		groupId: 1,
		category: 'Disputes - CBs + Mitigation',
		active: false,
		rule: 'Dispute Unit Estimated over Threshold of 150 for Current Month',
		alertLevel: 'warning'
	},
	{
		id: 20,
		groupId: 1,
		category: 'Disputes - CBs + Mitigation',
		active: false,
		rule:
			'Dispute Percentage Estimated over Threshold of 1.7% for Current Month',
		alertLevel: 'severe'
	},
	{
		id: 21,
		groupId: 1,
		category: 'Disputes - CBs + Mitigation',
		active: false,
		rule: 'Dispute Unit Estimated over Threshold of 180 for Current Month',
		alertLevel: 'severe'
	},
	{
		id: 22,
		groupId: 1,
		category: 'Disputes - CBs + Mitigation',
		active: true,
		rule: 'Dispute Unit Threshold of 200 has been exceeded for Current Month',
		alertLevel: 'severe'
	},
	{
		id: 23,
		groupId: 1,
		category: 'Disputes - CBs + Mitigation',
		active: true,
		rule:
			'Dispute Percentage Threshold of 2.0% has been exceeded for Current Month',
		alertLevel: 'severe'
	},
	{
		id: 24,
		groupId: 1,
		category: 'Disputes - CBs + Mitigation',
		active: true,
		rule:
			'Dispute Unit & Percentage Thresholds have been exceeded for Current Month',
		alertLevel: 'severe'
	},
	{
		id: 25,
		groupId: 1,
		category: 'Refunds',
		active: true,
		rule: 'Returns Exceeded $20,000 for prior day',
		alertLevel: 'warning'
	},
	{
		id: 26,
		groupId: 1,
		category: 'Refunds',
		active: true,
		rule: 'Returns Exceeded $40,000 for prior day',
		alertLevel: 'severe'
	},
	{
		id: 27,
		groupId: 1,
		category: 'Refunds',
		active: true,
		rule: 'Return Percentage for last 14 days has exceeded 8.0%',
		alertLevel: 'warning'
	},
	{
		id: 28,
		groupId: 1,
		category: 'Refunds',
		active: true,
		rule: 'Returns Percentage for last 14 days has exceeded 10.0%',
		alertLevel: 'severe'
	},
	{
		id: 29,
		groupId: 1,
		category: 'Refunds',
		active: true,
		rule:
			'Returns Percentage has increased by over 20.0% over the last 30 days',
		alertLevel: 'warning'
	},
	{
		id: 30,
		groupId: 1,
		category: 'Refunds',
		active: true,
		rule:
			'Returns Percentage has increased by over 30.0% over the last 30 days',
		alertLevel: 'severe'
	},
	{
		id: 31,
		groupId: 1,
		category: 'Fraud',
		active: true,
		rule:
			'Had greater than 2x increase in fraud index score compared to prior week',
		alertLevel: 'warning'
	},
	{
		id: 32,
		groupId: 1,
		category: 'Fraud',
		active: true,
		rule: 'Fraud Percentage Estimated Over Threshold of 0.6% for Current Month',
		alertLevel: 'warning'
	},
	{
		id: 33,
		groupId: 1,
		category: 'Fraud',
		active: true,
		rule: 'Fraud Dollars Estimated Over Threshold of $40,000 for Current Month',
		alertLevel: 'warning'
	},
	{
		id: 34,
		groupId: 1,
		category: 'Fraud',
		active: false,
		rule: 'Fraud Percentage Estimated Threshold of 0.8% for Current Month',
		alertLevel: 'severe'
	},
	{
		id: 35,
		groupId: 1,
		category: 'Fraud',
		active: false,
		rule: 'Fraud Dollars Estimated over Threshold of $55,000 for Current Month',
		alertLevel: 'severe'
	},
	{
		id: 36,
		groupId: 1,
		category: 'Fraud',
		active: true,
		rule:
			'Fraud Dollars Threshold of $75,000 has been exceeded for Current Month',
		alertLevel: 'severe'
	},
	{
		id: 37,
		groupId: 1,
		category: 'Fraud',
		active: true,
		rule:
			'Fraud Percentage Threshold of 1.0% has been exceeded for Current Month',
		alertLevel: 'severe'
	},
	{
		id: 38,
		groupId: 1,
		category: 'Fraud',
		active: true,
		rule:
			'Fraud Dollars & Percentage Thresholds have been exceeded for Current Month',
		alertLevel: 'severe'
	},
	{
		id: 39,
		groupId: 1,
		category: 'Chargeback',
		active: true,
		rule:
			'Chargeback Percentage Estimated over Threshold of 0.4% for Current Month',
		alertLevel: 'warning'
	},
	{
		id: 40,
		groupId: 1,
		category: 'Chargeback',
		active: true,
		rule: 'Chargeback Unit Estimated over Threshold of 75 for Current Month',
		alertLevel: 'warning'
	},
	{
		id: 41,
		groupId: 1,
		category: 'Chargeback',
		active: false,
		rule:
			'Chargeback Percentage Estimated Over Threshold of 0.7% for Current Month',
		alertLevel: 'severe'
	},
	{
		id: 42,
		groupId: 1,
		category: 'Chargeback',
		active: false,
		rule: 'Chargeback Unit Estimated over Threshold of 90 for Current Month',
		alertLevel: 'severe'
	},
	{
		id: 43,
		groupId: 1,
		category: 'Chargeback',
		active: true,
		rule:
			'Chargeback Unit Threshold of 100 has been exceeded for Current Month',
		alertLevel: 'severe'
	},
	{
		id: 44,
		groupId: 1,
		category: 'Chargeback',
		active: true,
		rule:
			'Chargeback Percentage Threshold of 0.9% has been exceeded for Current Month',
		alertLevel: 'severe'
	},
	{
		id: 45,
		groupId: 1,
		category: 'Chargeback',
		active: true,
		rule:
			'Chargeback Unit & Percentage Thresholds have been exceeded for Current Month',
		alertLevel: 'severe'
	},
	{
		id: 46,
		groupId: 1,
		category: 'Representments',
		active: true,
		rule: 'Win to loss ratio has decreased 1.5x based on prior month ratios',
		alertLevel: 'severe'
	},
	{
		id: 47,
		groupId: 1,
		category: 'Representments',
		active: true,
		rule: 'Expired Chargeback Cases',
		alertLevel: 'severe'
	},
	{
		id: 48,
		groupId: 1,
		category: 'Representments',
		active: true,
		rule: 'Chargeback Cases Expiring Today',
		alertLevel: 'severe'
	},
	{
		id: 49,
		groupId: 1,
		category: 'Representments',
		active: false,
		rule: 'Has more than 10 Chargebacks Cases Set to Expire Today',
		alertLevel: 'severe'
	},
	{
		id: 50,
		groupId: 1,
		category: 'Representments',
		active: false,
		rule: 'Has more than 20 Chargeback Cases Expiring in Two Days',
		alertLevel: 'severe'
	},
	{
		id: 51,
		groupId: 1,
		category: 'Representments',
		active: false,
		rule: 'Has cases Expiring in Three Days',
		alertLevel: 'warning'
	},
	{
		id: 52,
		groupId: 1,
		category: 'Representments',
		active: false,
		rule: 'Has cases Expiring in Four Days',
		alertLevel: 'warning'
	},
	{
		id: 53,
		groupId: 1,
		category: 'Authorizations',
		active: true,
		rule: 'Authorizations dropped below 40.0% Yesterday',
		alertLevel: 'severe'
	},
	{
		id: 54,
		groupId: 1,
		category: 'Authorizations',
		active: false,
		rule: 'Authorizations dropped below 50.0% Yesterday',
		alertLevel: 'warning'
	},
	{
		id: 55,
		groupId: 1,
		category: 'Authorizations',
		active: true,
		rule: 'Authorization rates are down 20% from the prior week',
		alertLevel: 'severe'
	},
	{
		id: 56,
		groupId: 1,
		category: 'Authorizations',
		active: true,
		rule:
			'Authorization rates decreased by a compounded rate of 30.0% over the last 6 months',
		alertLevel: 'severe'
	}
];
