import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { recoveryHistoryActions } from '../../../containers/RecoveryHistory';
import generateDateRange from '../../../helpers/shared/generateDateRange';

import StatusBreakdown from './StatusBreakdown';
import Container from '@mui/material/Container';

const styles = (theme) => ({
	stack: {
		display: 'flex',
		flexDirection: 'column',
		margin: '10px 0px',
		padding: '0px'
	},
	grouped: {
		display: 'flex',
		flex: 1,
		flexDirection: 'row',
		padding: 0,
		justifyContent: 'space-between',
		flexWrap: 'wrap',
		height: '250px'
	}
});

const BreakDownByCard = (props) => {
	const {
		classes,
		dateRange,
		data,
		getHistoryByCard,
		isLoading,
		hasBeenLoaded
	} = props;
	const { stack, grouped } = classes;

	React.useEffect(() => {
		const formattedDateRange = generateDateRange(dateRange);

		getHistoryByCard(formattedDateRange);
	}, [dateRange, getHistoryByCard]);

	return (
		<Container maxWidth={false} className={stack}>
			{hasBeenLoaded && (
				<div className={grouped}>
					<StatusBreakdown
						title={'visa'}
						data={data.visa}
						isLoading={isLoading}
					/>
					<StatusBreakdown
						title={'mastercard'}
						data={data.mastercard}
						isLoading={isLoading}
					/>
					<StatusBreakdown
						title={'discover'}
						data={data.discover}
						isLoading={isLoading}
					/>
					<StatusBreakdown
						title={'amex'}
						data={data.amex}
						isLoading={isLoading}
					/>
				</div>
			)}
		</Container>
	);
};

BreakDownByCard.propTypes = {
	dateRange: PropTypes.string,
	data: PropTypes.object,
	isLoading: PropTypes.bool,
	hasBeenLoaded: PropTypes.bool,
	getHistoryByCard: PropTypes.func,
	classes: PropTypes.object
};

const mapStateToProps = (state) => {
	return {
		data: state.recoveryHistoryReducer.getIn(['historyByCard', 'data']),
		isLoading: state.recoveryHistoryReducer.getIn([
			'historyByCard',
			'isLoading'
		]),
		hasBeenLoaded: state.recoveryHistoryReducer.getIn([
			'historyByCard',
			'hasBeenLoaded'
		]),
		error: state.recoveryHistoryReducer.getIn(['historyByCard', 'error'])
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getHistoryByCard: (payload) => {
			dispatch(recoveryHistoryActions.getRecoveryHistoryByCard(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(withTranslation()(BreakDownByCard)));
