import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import getDeleteIconConfiguration from './helpers/getDeleteIconConfiguration';

import Tooltip from '@mui/material/Tooltip';

const styles = (theme) => ({
	listDeleteIcon: {
		paddingRight: '10px',
		color: theme.palette.icons.default,
		'&:hover': {
			color: theme.palette.error.main
		}
	},
	listRestoreIcon: {
		paddingRight: '10px',
		color: theme.palette.icons.default,
		'&:hover': {
			color: theme.palette.icons.selected
		}
	},
	disabled: {
		color: theme.palette.icons.disabled,
		paddingRight: '10px',
		cursor: 'default'
	}
});

export const EditIconWrapper = (props) => {
	const { classes, t, uuid, isMarkedForDeletion, isDefaultLayout, onMarkForDeletion, onRestore } = props;
	const {
		listDeleteIcon,
		listRestoreIcon,
		disabled
	} = classes;

	const iconConfiguration = getDeleteIconConfiguration(isMarkedForDeletion, isDefaultLayout, onMarkForDeletion, onRestore);

	return (
		<Tooltip placement='right' title={t(iconConfiguration.toolTipText)}>
			<span className={clsx(
				!isDefaultLayout && !isMarkedForDeletion && listDeleteIcon,
				!isDefaultLayout && isMarkedForDeletion && listRestoreIcon,
				isDefaultLayout && disabled
			)} onClick={() => { iconConfiguration.onClickFunction(uuid); }}>
				{iconConfiguration.icon}
			</span>
		</Tooltip>
	);
};

EditIconWrapper.propTypes = {
	uuid: PropTypes.string,
	isMarkedForDeletion: PropTypes.bool,
	isDefaultLayout: PropTypes.bool,
	onMarkForDeletion: PropTypes.func,
	onRestore: PropTypes.func,
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(EditIconWrapper));
