import ChargebackKpiTile from './tiles/ChargebackKpiTile';
import DashboardWidgetTile from './tiles/DashboardWidgetTile';
import TileSkeleton from './tiles/TileSkeleton';

import BreakdownBySeverity from './demoTiles/BreakdownBySeverity';
import FlagChartMetric from './demoTiles/FlagChartMetric';
import LineChartMetric from './demoTiles/LineChartMetric';
import NotificationsByDay from './demoTiles/NotificationsByDay';
import QuickView from './demoTiles/QuickView';
import RecentNotifications from './demoTiles/RecentNotifications';
import StaticMetricList from './demoTiles/StaticMetricList';

export default {
	breakdownBySeverity: BreakdownBySeverity,
	chargebackKpi: ChargebackKpiTile,
	dashboardWidget: DashboardWidgetTile,
	flagChartMetric: FlagChartMetric,
	lineChartMetric: LineChartMetric,
	notificationByDay: NotificationsByDay,
	quickView: QuickView,
	recentNotifications: RecentNotifications,
	staticMetricList: StaticMetricList,
	tileSkeleton: TileSkeleton
};
