import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';

// HACK UNTIL LINE COLORS ARE ESTABLISHED
// const _lineColors = ['#ca881c', '#97cde6', '#94540c', '#55344c '];
const _lineColors = ['#94540c', '#ca881c', '#97cde6', '#55344c '];

import {
	CartesianGrid,
	Line,
	LineChart,
	Tooltip,
	YAxis,
	XAxis,
	Legend
} from 'recharts';

const styles = (theme) => ({});

const LineGraph = (props) => {
	const { classes, t, height, width, reportData } = props;
	const {} = classes;

	return (
		<LineChart width={width} height={height} data={reportData.results}>
			<CartesianGrid strokeDasharray="3 3" />
			<XAxis dataKey="date" />
			<YAxis />
			<Tooltip />
			{reportData.lineKeys &&
				reportData.lineKeys.map((lineKey, index) => {
					return (
						<Line
							key={lineKey}
							type="monotone"
							dataKey={lineKey}
							stroke={_lineColors[index]}
						/>
					);
				})}
		</LineChart>
	);
};

// TODO FORMAT SO ITS NOT CUTTING THINGS OF
// label={t('components.Widget.WidgetViewer.LineGraph.yAxis')}

LineGraph.propTypes = {
	reportData: PropTypes.object,
	classes: PropTypes.object,
	size: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(LineGraph));
