import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { DropzoneArea } from 'material-ui-dropzone';
import clsx from 'clsx';

import fileUploadHandler from '../../../utilities/fileUploadHandler';
import HeaderRow from '../shared/HeaderRow';

import { userActions } from '../../../containers/User';
import { modalActions } from '../../../containers/Modal';

import Container from '@mui/material/Container';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircularProgress from '@mui/material/CircularProgress';

const styles = (theme) => ({
	modal: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	paper: {
		display: 'flex',
		flexFlow: 'column',
		padding: '10px'
	},
	paperRow: {
		display: 'flex',
		flexflow: 'row'
	},
	mainContentArea: {
		padding: '10px'
	},
	currentAvatarContainer: {
		display: 'flex',
		flex: '1',
		flexFlow: 'column',
		justifyContent: 'center',
		alignItems: 'center'
	},
	uploadContainer: {
		display: 'flex',
		margin: '5px',
		padding: 0,
		flex: '1'
	},
	square200: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '200px',
		width: '200px'
	},
	dropzoneContainer: {
		padding: '10px',
		height: '200px',
		width: '200px'
	},
	previewContainer: {
		display: 'none'
	},
	statusIcon: {
		height: '100px',
		width: '100px',
		color: theme.palette.primary.main
	},
	buttonContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginTop: '20px',
		marginBottom: '10px',
		marginRight: '10px'
	},
	inputButton: {
		borderRadius: '4px',
		width: '100px',
		border: 'none',
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.secondary.alt,
		padding: '5px',
		fontSize: '16px',
		fontWeight: '300',
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
			cursor: 'pointer'
		}
	}
});

export const EditAvatarModal = (props) => {
	const {
		classes,
		t,
		user,
		userAvatar,
		isUpdatingAvatar,
		avatarHasBeenUpdated,
		isUpdateAvatarModalOpen,
		closeModal
	} = props;
	const {
		modal,
		paper,
		paperRow,
		mainContentArea,
		dropzoneContainer,
		uploadContainer,
		square200,
		statusIcon,
		previewContainer,
		currentAvatarContainer,
		buttonContainer,
		inputButton
	} = classes;

	const [uploadedAvatar, setUploadedAvatar] = React.useState(undefined);
	const [isUploadingAvatar, setIsUploadingAvatar] = React.useState(false);

	const onUpdateAvatar = () => {
		const { updateAvatar, user } = props;

		updateAvatar({
			userId: user.id,
			username: user.username,
			userData: {
				avatar: uploadedAvatar
			}
		});
	};

	const onUploadFinished = (data) => {
		setUploadedAvatar(data);
		setIsUploadingAvatar(false);
	};

	const onUpload = async (file) => {
		setIsUploadingAvatar(true);
		await fileUploadHandler(file, onUploadFinished);
	};

	const onCloseModal = () => {
		const { closeModal } = props;

		setUploadedAvatar(undefined);
		closeModal();
	};

	return (
		<Modal
			open={isUpdateAvatarModalOpen}
			onClose={closeModal}
			className={modal}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Paper className={paper} elevation={1}>
				<HeaderRow
					translationPath={'components.modals.EditAvatar.updateAvatar'}
					onClickCancel={onCloseModal}
				/>
				<div className={clsx(paperRow, mainContentArea)}>
					<Container className={currentAvatarContainer}>
						<Avatar
							alt={`${user.firstName} ${user.lastName}`}
							src={
								uploadedAvatar || `https://dev.assets.slyce360.com${userAvatar}`
							}
							variant="rounded"
							sx={{ width: 200, height: 200 }}
						/>
					</Container>
					<Container className={uploadContainer}>
						{avatarHasBeenUpdated ? (
							<div className={square200}>
								<CheckCircleIcon className={statusIcon} />
							</div>
						) : (
							<div>
								{!avatarHasBeenUpdated && isUpdatingAvatar ? (
									<div className={square200}>
										<CircularProgress className={statusIcon} />
									</div>
								) : (
									<DropzoneArea
										id="#avatar-upload-dropzone"
										clearOnUnmount={true}
										dropzoneText={'Upload New Avatar'}
										initialFiles={[]}
										filesLimit={1}
										maxFileSize={10000000}
										onChange={(file) => onUpload(file[0])}
										dropzoneClass={dropzoneContainer}
										previewGridClasses={{
											container: previewContainer,
											item: previewContainer
										}}
										disabled={isUploadingAvatar || uploadedAvatar}
									/>
								)}
							</div>
						)}
					</Container>
				</div>
				<div className={`${paperRow}, ${buttonContainer}`}>
					<Button
						variant="contained"
						size="small"
						className={inputButton}
						type="submit"
						onClick={avatarHasBeenUpdated ? onCloseModal : onUpdateAvatar}
						disabled={!avatarHasBeenUpdated && !uploadedAvatar}
					>
						{avatarHasBeenUpdated
							? t('generic.buttons.close')
							: t('generic.buttons.confirm')}
					</Button>
				</div>
			</Paper>
		</Modal>
	);
};

EditAvatarModal.propTypes = {
	user: PropTypes.object,
	userAvatar: PropTypes.string,
	isUpdatingAvatar: PropTypes.bool,
	avatarHasBeenUpdated: PropTypes.bool,
	updateAvatar: PropTypes.func,
	isUpdateAvatarModalOpen: PropTypes.bool,
	closeModal: PropTypes.func,
	classes: PropTypes.object,
	t: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		user: state.userReducer.get('user'),
		userAvatar: state.userReducer.get('userAvatar'),
		isUpdatingAvatar: state.userReducer.get('isUpdatingAvatar'),
		avatarHasBeenUpdated: state.userReducer.get('avatarHasBeenUpdated'),
		isUpdateAvatarModalOpen: state.modalReducer.get('isUpdateAvatarModalOpen')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateAvatar: (payload) => {
			dispatch(userActions.updateUserAvatar(payload));
		},
		closeModal: (payload) => {
			dispatch(modalActions.toggleUpdateAvatarModal());
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(withTranslation()(EditAvatarModal)));
