import amexCard from '../../assets/cardBrandImages/amex.svg';
import amexLogo from '../../assets/cardBrandImages/amex_noCard.svg';
import discoverCard from '../../assets/cardBrandImages/discover.svg';
import discoverLogo from '../../assets/cardBrandImages/discover_noCard.svg';
import mastercardCard from '../../assets/cardBrandImages/mastercard.svg';
import mastercardLogo from '../../assets/cardBrandImages/mastercard_noCard.svg';
import visaCard from '../../assets/cardBrandImages/visa.svg';
import visaLogo from '../../assets/cardBrandImages/visa_noCard.svg';

import constants from '../constants';

const _cardImages = [
	{
		keys: ['A', 'Amex', 'amex', 'uo_amex'],
		label: constants.CARD_TYPES.uo_amex,
		lowercase: 'amex',
		logo: amexLogo,
		fullCard: amexCard
	},
	{
		keys: ['D', 'Discover', 'discover', 'uo_discover'],
		label: constants.CARD_TYPES.uo_discover,
		lowercase: 'discover',
		logo: discoverLogo,
		fullCard: discoverCard
	},
	{
		keys: ['M', 'Mastercard', 'mastercard', 'uo_mastercard'],
		label: constants.CARD_TYPES.uo_mastercard,
		lowercase: 'mastercard',
		logo: mastercardLogo,
		fullCard: mastercardCard
	},
	{
		keys: ['V', 'Visa', 'visa', 'uo_visa'],
		label: constants.CARD_TYPES.uo_visa,
		lowercase: 'visa',
		logo: visaLogo,
		fullCard: visaCard
	}
];

const _cardNameList = ['visa', 'mastercard', 'discover', 'amex'];

export const getCardList = () => {
	return _cardNameList;
};

export const getLogoByBrandName = (cardBrand) => {
	const cardAssets = _cardImages.find((card) => {
		return card.keys.includes(cardBrand);
	});

	if (cardAssets) {
		return cardAssets.logo;
	}
};

export const getFullCardByBrandName = (cardBrand) => {
	const cardAssets = _cardImages.find((card) => {
		return card.keys.includes(cardBrand);
	});

	if (cardAssets) {
		return cardAssets.fullCard;
	}
};

export const getLabel = (cardType) => {
	const cardAssets = _cardImages.find((card) => {
		return card.keys.includes(cardType);
	});

	if (cardAssets) {
		return cardAssets.label;
	}
};

export const getLowercase = (cardType) => {
	const cardAssets = _cardImages.find((card) => {
		return card.keys.includes(cardType);
	});

	if (cardAssets) {
		return cardAssets.lowercase;
	}
};
