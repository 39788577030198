import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import { handleZero } from '../../../../../helpers/dailyChargebackReport/handleZero';
import { generate } from 'short-uuid';
import clsx from 'clsx';

import TableCell from '@mui/material/TableCell';

const styles = (theme) => ({
	subHeader: {
		borderLeft: '1px solid black'
	}
});

const ChargebacksRow = (props) => {
	const { classes, cardData, t } = props;
	const { subHeader } = classes;

	const { monthToDate, daily, weekToDate } = cardData;
	return (
		<Fragment>
			<TableCell colSpan={2} className={subHeader}>
				{t('pages.reports.chargebacks.daily-chargeback-report.received')}
			</TableCell>
			{[daily, weekToDate, monthToDate].map((entry) => {
				const tableCells = [];
				for (const [key, value] of Object.entries(entry.chargebacks)) {
					tableCells.push(
						<TableCell
							align="center"
							key={generate()}
							className={clsx(key === 'actual' && subHeader)}
						>
							{key === 'deltaPercent' ? `${value}%` : handleZero(value)}
						</TableCell>
					);
				}
				return tableCells;
			})}
		</Fragment>
	);
};

ChargebacksRow.propTypes = {
	classes: PropTypes.object,
	cardData: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(ChargebacksRow));
