export default function (list, universalUniqueIdentifier) {
	let listItem = {};

	if (universalUniqueIdentifier && list) {
		const foundItem = list.get(universalUniqueIdentifier);

		listItem = foundItem;
	}

	return listItem;
}
