export const getColumnNames = (t) => {
	return [
		{
			title: t('pages.reports.chargebacks.summary.columnnames.mid'),
			field: 'mid',
			headerStyle: {
				fontWeight: 600,
				fontSize: '12px',
				minWidth: '10%'
			}
		},
		{
			title: t('pages.reports.chargebacks.summary.columnnames.yesterday'),
			field: 'actualsByDay',
			headerStyle: {
				fontWeight: 600,
				fontSize: '12px',
				maxWidth: 'calc((100% - 0px) / 12)'
			}
		},
		{
			title: t('pages.reports.chargebacks.summary.columnnames.mtd'),
			field: 'actualsByMonth',
			headerStyle: {
				fontWeight: 600,
				fontSize: '12px',
				maxWidth: 'calc((100% - 0px) / 12)'
			}
		},
		{
			title: t('pages.reports.chargebacks.summary.columnnames.forecast'),
			field: 'forecastChargebacks',
			headerStyle: {
				fontWeight: 600,
				fontSize: '12px',
				maxWidth: 'calc((100% - 0px) / 10)'
			}
		},
		{
			title: t(
				'pages.reports.chargebacks.summary.columnnames.chargebackmargin'
			),
			field: 'chargebackMargin',
			headerStyle: {
				fontWeight: 600,
				fontSize: '12px',
				maxWidth: 'calc((100% - 0px) / 10)'
			}
		},
		{
			title: t(
				'pages.reports.chargebacks.summary.columnnames.chargebackpercent'
			),
			field: 'chargebackPctByMonth',
			headerStyle: {
				fontWeight: 600,
				fontSize: '12px',
				maxWidth: 'calc((100% - 0px) / 10)'
			}
		},
		{
			title: '',
			field: 'actions',
			headerStyle: { minWidth: '11vw' }
		}
	];
};
