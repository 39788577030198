import React from 'react';
import PropTypes from 'prop-types';
import { Sector } from 'recharts';

const renderActiveShape = (props) => {
	const {
		cx,
		cy,
		innerRadius,
		outerRadius,
		startAngle,
		endAngle,
		payload
	} = props;

	return (
		<g>
			<Sector
				cx={cx}
				cy={cy}
				innerRadius={innerRadius}
				outerRadius={outerRadius}
				startAngle={startAngle}
				endAngle={endAngle}
				fill={payload.fillColor}
			/>
			<Sector
				cx={cx}
				cy={cy}
				startAngle={startAngle}
				endAngle={endAngle}
				innerRadius={outerRadius + 6}
				outerRadius={outerRadius + 10}
				fill={payload.fillColor}
			/>
		</g>
	);
};

renderActiveShape.propTypes = {
	cx: PropTypes.number,
	cy: PropTypes.number,
	innerRadius: PropTypes.number,
	outerRadius: PropTypes.number,
	startAngle: PropTypes.number,
	endAngle: PropTypes.number,
	payload: PropTypes.object
};

export default renderActiveShape;
