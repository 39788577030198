import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import {
	MonthlySummaryByMid,
	MonthlySummaryByMidOptions
} from './MonthlySummaryByMid';
import Reporter from '../../Reporter';
import { TwelveMonthLook, TwelveMonthLookOptions } from './TwelveMonthLook';

import Container from '@mui/material/Container';

const styles = (theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		width: '80vw'
	}
});

const DailyChargebackReportView = (props) => {
	const { classes, reportViewId } = props;
	const { root } = classes;

	return (
		<Container maxWidth={false} className={root}>
			<Reporter
				reportViewId={`${reportViewId}-1`}
				OverrideFilterComponent={MonthlySummaryByMidOptions}
				OverrideReportComponent={MonthlySummaryByMid}
			/>
			<Reporter
				reportViewId={`${reportViewId}-2`}
				OverrideFilterComponent={TwelveMonthLookOptions}
				OverrideReportComponent={TwelveMonthLook}
			/>
		</Container>
	);
};

DailyChargebackReportView.propTypes = {
	reportViewId: PropTypes.string,
	classes: PropTypes.object
};

export default withStyles(styles)(DailyChargebackReportView);
