import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import AppsIcon from '@mui/icons-material/Apps';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import CircularProgress from '@mui/material/CircularProgress';

const styles = (theme) => ({
	box: {
		display: 'flex',
		flexDirection: 'column',
		alignSelf: 'stretch',
		height: '100%',
		flex: 1
	},
	listHeader: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		color: theme.palette.createLayoutModal.instructionsText,
		fontSize: '1rem'
	},
	list: {
		paddingTop: '5px',
		paddingRight: '3px',
		overflow: 'auto'
	},
	listItemText: {
		display: 'flex',
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'flex-start'
	},
	circularProgressContainer: {
		display: 'flex',
		marginTop: '10px',
		flexDirection: 'row',
		justifyContent: 'center'
	}
});

export const AvailableTilesList = (props) => {
	const {
		availableTiles,
		availableTilesHaveBeenLoaded,
		availableTilesAreLoading,
		onTileSelect,
		classes,
		t
	} = props;
	const {
		box,
		listHeader,
		list,
		listItemText,
		circularProgressContainer
	} = classes;

	return (
		<Box className={box}>
			<span className={listHeader}>Available Tiles</span>
			{!availableTilesHaveBeenLoaded || availableTilesAreLoading ? (
				<div className={circularProgressContainer}>
					<CircularProgress />
				</div>
			) : (
				<List className={list}>
					{availableTiles.map((tile) => {
						return (
							<ListItem key={tile.reportId || tile.tileName} disablePadding>
								<ListItemButton
									onClick={() => {
										onTileSelect(tile);
									}}
								>
									<ListItemIcon>
										{tile.type === 'static' ? (
											<AppsIcon />
										) : (
											<AppRegistrationIcon />
										)}
									</ListItemIcon>
									<ListItemText className={listItemText}>
										{tile.label}
									</ListItemText>
								</ListItemButton>
							</ListItem>
						);
					})}
				</List>
			)}
		</Box>
	);
};

AvailableTilesList.propTypes = {
	availableTiles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	availableTilesHaveBeenLoaded: PropTypes.bool,
	availableTilesAreLoading: PropTypes.bool,
	onTileSelect: PropTypes.func,
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(AvailableTilesList));
