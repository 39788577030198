export default function async(file, callback) {
	if (file) {
		return new Promise((resolve) => {
			const reader = new FileReader();
			reader.onload = function (evt) {
				callback(evt.target.result);
				resolve();
			};

			reader.readAsDataURL(file);
		});
	}
}
