import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';

import Divider from '@mui/material/Divider';

import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import DangerousIcon from '@mui/icons-material/Dangerous';
import InfoIcon from '@mui/icons-material/Info';

const _icons = {
	severe: <DangerousIcon style={{ fontSize: '2rem', color: '#f5989d' }} />,
	warning: <WarningAmberIcon style={{ fontSize: '2rem', color: '#c6b55a' }} />,
	info: <InfoIcon style={{ fontSize: '2rem', color: '#71a6ec' }} />
};

const styles = (theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'row',
		alignSelf: 'stretch'
	},
	cursor: {
		cursor: 'pointer'
	},
	column: {
		display: 'flex',
		flexDirection: 'column'
	},
	iconContainer: {
		margin: '5px'
	},
	mainContainer: {
		paddingLeft: '3px',
		flex: 1
	},
	row: {
		display: 'flex',
		flex: 1,
		flexDirection: 'row',
		marginTop: '2px',
		justifyContent: 'space-between',
		'& span': {
			marginRight: '10px'
		}
	},
	flex: {
		flex: 1,
		paddingLeft: '5px',
		flexDirection: 'row',
		justifyContent: 'center'
	},
	detailContainer: {
		display: 'flex',
		flexDirection: 'row',
		paddingTop: '3px',
		fontSize: '.8rem'
	},
	rightAlign: {
		justifyContent: 'right'
	},
	assignedToText: {
		color: theme.palette.primary.main
	},
	divider: {
		width: '95%',
		marginTop: '2px'
	}
});

const _allowedClickableNotifications = [121, 123];

const RowItem = (props) => {
	const { classes, itemData, lastIndex } = props;
	const {
		container,
		column,
		row,
		iconContainer,
		mainContainer,
		detailContainer,
		rightAlign,
		flex,
		divider,
		cursor
	} = classes;

	const history = useHistory();

	const onRowClick = (notificationId) => {
		if (
			notificationId &&
			_allowedClickableNotifications.includes(notificationId)
		) {
			history.push(`/notifications/${notificationId}`);
		}
	};

	return (
		<div
			className={clsx(
				container,
				_allowedClickableNotifications.includes(itemData.notificationId) &&
					cursor
			)}
			onClick={() => {
				onRowClick(itemData.notificationId);
			}}
		>
			<div className={clsx(column, iconContainer)}>
				{_icons[itemData.level]}
			</div>
			<div className={clsx(column, mainContainer)}>
				<div className={row}>{itemData.description}</div>
				<div className={row}>
					{itemData.type && (
						<div className={detailContainer}>{`Type: ${itemData.type}`}</div>
					)}
					{/* <div className={clsx(flex, detailContainer)}>
						{itemData.assignedTo
							? `Assigned To: ${itemData.assignedTo}`
							: 'Unassigned'}
					</div> */}
					{/* {!itemData.assignedTo && <div className={flex}></div>} */}
					<div className={clsx(detailContainer, rightAlign)}>
						<span>
							{itemData.assignedTo
								? `Assigned To: ${itemData.assignedTo}`
								: 'Unassigned'}
						</span>
						{itemData.date}
					</div>
				</div>
				{!lastIndex && <Divider className={divider} />}
			</div>
		</div>
	);
};

RowItem.propTypes = {
	itemData: PropTypes.object,
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(RowItem));
