import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import ViewAll from './ViewAll';
import { systemActions } from '../../../containers/System';
import { notificationActions } from '../../../containers/Notifications';

import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import Typography from '@mui/material/Typography';

const styles = (theme) => ({
	card: {
		margin: '2px 5px 2px 5px',
		padding: '5px 10px 5px 10px'
	},
	cardContent: {
		display: 'flex',
		justifyContent: 'space-between'
	}
});

const DrawerNotifications = (props) => {
	const {
		classes,
		data,
		getNotificationDetailsById,
		hasBeenLoaded,
		isLoading,
		t,
		toggleRightDrawer
	} = props;
	const { card, cardContent } = classes;

	const history = useHistory();

	const handleSelect = (id) => {
		if ([121, 123].includes(id)) {
			const filteredNotification = data.filter(
				(notification) => notification.id === id
			);
			history.push(`/notifications/${filteredNotification[0].id}`);
			toggleRightDrawer();
		}
	};

	const topLevelTypeOptionColors = (type) => {
		switch (type) {
			case 'Severe':
				return { color: '#e00429' };
			case 'Info':
				return { color: '#74B72E' };
			case 'Warning':
				return { color: '#dbc202' };
			default:
				break;
		}
	};

	return (
		<div>
			<ViewAll />
			{hasBeenLoaded && !isLoading && data.length ? (
				data.map((entry) => {
					return (
						<Card className={card} key={entry.id}>
							<CardActionArea onClick={() => handleSelect(entry.id)}>
								<div className={cardContent}>
									<Typography
										style={topLevelTypeOptionColors(entry.type)}
										variant="caption"
									>
										{entry.type}
									</Typography>
									<Typography variant="caption">{entry.createdOn}</Typography>
								</div>
								<div className={cardContent} style={{ marginBottom: '10px' }}>
									<Typography>{entry.label}</Typography>
								</div>
							</CardActionArea>
						</Card>
					);
				})
			) : (
				<Typography variant="body1">
					{t('pages.notifications.piechart.noNotifications')}
				</Typography>
			)}
		</div>
	);
};

DrawerNotifications.propTypes = {
	classes: PropTypes.object,
	data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	getNotificationDetailsById: PropTypes.func,
	hasBeenLoaded: PropTypes.bool,
	isLoading: PropTypes.bool,
	t: PropTypes.func,
	toggleRightDrawer: PropTypes.func
};

const mapDispatchToProps = (dispatch) => {
	return {
		getNotificationDetailsById: (id) => {
			dispatch(notificationActions.getNotificationDetailsById(id));
		},
		toggleRightDrawer: () => {
			dispatch(systemActions.toggleRightDrawer());
		}
	};
};

export default connect(
	null,
	mapDispatchToProps
)(withStyles(styles)(withTranslation()(DrawerNotifications)));
