export const ADD_NOTIFICATION = 'notifications/ADD_NOTIFICATION';
export const ADD_NOTIFICATION_COMPLETED =
	'notifications/ADD_NOTIFICATION_COMPLETED';
export const ADD_NOTIFICATION_FAILED = 'notifications/ADD_NOTIFICATION_FAILED';

export const ADD_NOTIFICATION_DETAIL = 'notifications/ADD_NOTIFICATION_DETAIL';
export const ADD_NOTIFICATION_DETAIL_COMPLETED =
	'notifications/ADD_NOTIFICATION_DETAIL_COMPLETED';
export const ADD_NOTIFICATION_DETAIL_FAILED =
	'notifications/ADD_NOTIFICATION_DETAIL_FAILED';

export const GET_NOTIFICATIONS = 'notifications/GET_NOTIFICATIONS';
export const GET_NOTIFICATIONS_COMPLETED =
	'notifications/GET_NOTIFICATIONS_COMPLETED';
export const GET_NOTIFICATIONS_FAILED =
	'notifications/GET_NOTIFICATIONS_FAILED';

export const GET_NOTIFICATION_BY_ID = 'notifications/GET_NOTIFICATION_BY_ID';
export const GET_NOTIFICATION_BY_ID_COMPLETED =
	'notifications/GET_NOTIFICATION_BY_ID_COMPLETED';
export const GET_NOTIFICATION_BY_ID_FAILED =
	'notifications/GET_NOTIFICATION_BY_ID_FAILED';

export const GET_NOTIFICATION_DETAILS_BY_ID =
	'notifications/GET_NOTIFICATION_DETAILS_BY_ID';
export const GET_NOTIFICATION_DETAILS_BY_ID_COMPLETED =
	'notifications/GET_NOTIFICATION_DETAILS_BY_ID_COMPLETED';
export const GET_NOTIFICATION_DETAILS_BY_ID_FAILED =
	'notifications/GET_NOTIFICATION_DETAILS_BY_ID_FAILED';

export const GET_ARCHIVED_NOTIFICATIONS =
	'notifications/GET_ARCHIVED_NOTIFICATIONS';
export const GET_ARCHIVED_NOTIFICATIONS_COMPLETED =
	'notifications/GET_ARCHIVED_NOTIFICATIONS_COMPLETED';
export const GET_ARCHIVED_NOTIFICATIONS_FAILED =
	'notifications/GET_ARCHIVED_NOTIFICATIONS_FAILED';

export const GET_ARCHIVED_NOTIFICATION_DETAILS_BY_ID =
	'notifications/GET_ARCHIVED_NOTIFICATION_DETAILS_BY_ID';
export const GET_ARCHIVED_NOTIFICATION_DETAILS_BY_ID_COMPLETED =
	'notifications/GET_ARCHIVED_NOTIFICATION_DETAILS_BY_ID_COMPLETED';
export const GET_ARCHIVED_NOTIFICATION_DETAILS_BY_ID_FAILED =
	'notifications/GET_ARCHIVED_NOTIFICATION_DETAILS_BY_ID_FAILED';

export const UPDATE_NOTIFICATION = 'notifications/UPDATE_NOTIFICATION';
export const UPDATE_NOTIFICATION_COMPLETED =
	'notifications/UPDATE_NOTIFICATION_COMPLETED';
export const UPDATE_NOTIFICATION_FAILED =
	'notifications/UPDATE_NOTIFICATION_FAILED';

export const UPDATE_NOTIFICATION_DETAIL =
	'notifications/UPDATE_NOTIFICATION_DETAIL';
export const UPDATE_NOTIFICATION_DETAIL_COMPLETED =
	'notifications/UPDATE_NOTIFICATION_DETAIL_COMPLETED';
export const UPDATE_NOTIFICATION_DETAIL_FAILED =
	'notifications/UPDATE_NOTIFICATION_DETAIL_FAILED';

export const DELETE_NOTIFICATION = 'notifications/DELETE_NOTIFICATION';
export const DELETE_NOTIFICATION_COMPLETED =
	'notifications/DELETE_NOTIFICATION_COMPLETED';
export const DELETE_NOTIFICATION_FAILED =
	'notifications/DELETE_NOTIFICATION_FAILED';

export const DELETE_NOTIFICATION_DETAIL =
	'notifications/DELETE_NOTIFICATION_DETAIL';
export const DELETE_NOTIFICATION_DETAIL_COMPLETED =
	'notifications/DELETE_NOTIFICATION_DETAIL_COMPLETED';
export const DELETE_NOTIFICATION_DETAIL_FAILED =
	'notifications/DELETE_NOTIFICATION_DETAIL_FAILED';
