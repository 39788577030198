import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import CancelIcon from '@mui/icons-material/Cancel';

const styles = (theme) => ({
	headerContainer: {
		display: 'flex',
		flexFlow: 'row'
	},
	titleContainer: {
		display: 'flex',
		flex: '1',
		flexFlow: 'row no-wrap',
		justifyContent: 'flex-start'
	},
	titleText: {
		color: theme.palette.primary.main,
		fontSize: '2rem',
		paddingRight: '20px'
	},
	topCloseContainer: {
		display: 'flex',
		flex: '1',
		justifyContent: 'flex-end'
	},
	closeIcon: {
		color: theme.palette.secondary.main,
		cursor: 'pointer'
	}
});

export const HeaderRow = (props) => {
	const { classes, t, translationPath, onClickCancel } = props;
	const {
		headerContainer,
		topCloseContainer,
		closeIcon,
		titleText
	} = classes;

	return (
		<div className={headerContainer}>
			<span className={titleText}>
				{t(translationPath)}
			</span>
			<div className={topCloseContainer}>
				<CancelIcon className={closeIcon} onClick={onClickCancel} />
			</div>
		</div>
	);
};

HeaderRow.propTypes = {
	translationPath: PropTypes.string,
	onClickCancel: PropTypes.func,
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(HeaderRow));
