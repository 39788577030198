import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@mui/styles';
import { Link, useLocation } from 'react-router-dom';
import { withSize } from 'react-sizeme';
import clsx from 'clsx';

import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

import PieChartIcon from '@mui/icons-material/PieChart';
import InventoryIcon from '@mui/icons-material/Inventory';
import TimelineIcon from '@mui/icons-material/Timeline';

const styles = (theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'row',
		flex: 1,
		marginLeft: '10px',
		justifyContent: 'flex-start',
		alignItems: 'center',
		padding: '10px'
	},
	linkStyle: {
		textDecoration: 'none'
	},
	navigationIcon: {
		color: theme.palette.navigation.color,
		'&hover': theme.palette.navigation.active
	},
	navigationItemContainer: {
		marginLeft: '20px',
		textDecoration: 'none'
	},
	navigationItemCompressed: {
		marginLeft: '5px'
	},
	navigationText: {
		color: `${theme.palette.navigation.color}`,
		textDecoration: 'none',
		fontWeight: 600
	},
	activeNavigationText: {
		color: `${theme.palette.navigation.active} !important`,
		textDecoration: 'none'
	}
});

const Navigation = (props) => {
	const { classes, t, size } = props;
	const {
		container,
		navigationIcon,
		activeNavigationText,
		linkStyle,
		navigationText,
		navigationItemContainer,
		navigationItemCompressed
	} = classes;

	const useCompressed = size.width < 500;

	const location = useLocation();
	const activeLocation = location.pathname;

	return (
		<div className={container}>
			<Link to={'/reports'} className={linkStyle}>
				<Button
					className={clsx(
						useCompressed ? navigationItemCompressed : navigationItemContainer
					)}
				>
					{useCompressed ? (
						<Tooltip title={t('components.Layout.Navbar.Navigation.reports')}>
							<PieChartIcon className={navigationIcon} />
						</Tooltip>
					) : (
						<span
							className={clsx(
								navigationText,
								activeLocation === '/reports' && activeNavigationText
							)}
						>
							{t('components.Layout.Navbar.Navigation.reports')}
						</span>
					)}
				</Button>
			</Link>
			<Link to={'/current-inventory'} className={linkStyle}>
				<Button
					className={clsx(
						useCompressed ? navigationItemCompressed : navigationItemContainer
					)}
				>
					{useCompressed ? (
						<Tooltip
							// title={t('components.Layout.Navbar.Navigation.currentInventory')}
							title="Representment Inventory"
						>
							<InventoryIcon className={navigationIcon} />
						</Tooltip>
					) : (
						<span
							className={clsx(
								navigationText,
								activeLocation === '/current-inventory' && activeNavigationText
							)}
						>
							{/* {t('components.Layout.Navbar.Navigation.currentInventory')} */}
							Representment Inventory
						</span>
					)}
				</Button>
			</Link>
			<Link to={'/recovery-history'} className={linkStyle}>
				<Button
					className={clsx(
						useCompressed ? navigationItemCompressed : navigationItemContainer
					)}
				>
					{useCompressed ? (
						<Tooltip
							// title={t('components.Layout.Navbar.Navigation.recoveryHistory')}
							title="Chargeback Recoveries"
						>
							<TimelineIcon className={navigationIcon} />
						</Tooltip>
					) : (
						<span
							className={clsx(
								navigationText,
								activeLocation === '/recovery-history' && activeNavigationText
							)}
						>
							{/* {t('components.Layout.Navbar.Navigation.recoveryHistory')} */}
							Chargeback Recoveries
						</span>
					)}
				</Button>
			</Link>
		</div>
	);
};

Navigation.propTypes = {
	classes: PropTypes.object,
	size: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(withSize()(Navigation)));
