import * as actionTypes from './actionTypes';

export const authUser = (username, password) => ({
	type: actionTypes.AUTH_USER,
	payload: {
		username,
		password
	}
});
export const authUserCompleted = (payload) => ({
	type: actionTypes.AUTH_USER_COMPLETED,
	payload
});

export const authUserFailed = (payload) => ({
	type: actionTypes.AUTH_USER_FAILED,
	payload
});

export const authUserSession = (payload) => ({
	type: actionTypes.AUTH_USER_SESSION,
	payload
});
export const authUserSessionCompleted = (payload) => ({
	type: actionTypes.AUTH_USER_SESSION_COMPLETED,
	payload
});

export const authUserSessionFailed = (payload) => ({
	type: actionTypes.AUTH_USER_SESSION_FAILED,
	payload
});

export const deAuthUser = (payload) => ({
	type: actionTypes.DE_AUTH_USER,
	payload
});

export const completeNewPassword = (payload) => ({
	type: actionTypes.COMPLETE_NEW_PASSWORD,
	payload
});

export const completeNewPasswordCompleted = (payload) => ({
	type: actionTypes.COMPLETE_NEW_PASSWORD_COMPLETED,
	payload
});

export const completeNewPasswordFailed = (payload) => ({
	type: actionTypes.COMPLETE_NEW_PASSWORD_FAILED,
	payload
});
