import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';

import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const styles = (theme) => ({
	container: {
		display: 'flex',
		flexSrhink: 1,
		flexFlow: 'column',
		padding: '10px',
		marginTop: '5%',
		maxWidth: '400px'
	},
	paper: {
		background: theme.palette.common.white
	},
	errorIcon: {
		color: theme.palette.error.light,
		fontSize: '2rem'
	},
	errorText: {
		color: theme.palette.error.light
	}
});

export const Overview = (props) => {
	const { classes, t } = props;
	const { container, paper, errorIcon, errorText } = classes;

	return (
		<Container className={container}>
			<Paper className={paper}>
				<Stack
					direction="column"
					spacing={2}
					pt={2}
					pb={2}
					alignItems="center"
					justifyContent="center"
				>
					<ErrorOutlineIcon className={errorIcon} />
					<span className={errorText}>
						{t('components.Representment.RepresentmentWrapper.notFound')}
					</span>
				</Stack>
			</Paper>
		</Container>
	);
};

Overview.propTypes = {
	data: PropTypes.object,
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(Overview));
