import React, { Fragment } from 'react';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { capitalCase } from 'change-case';
import { sanitizeKeys } from '../../../../../helpers/sanitizeKeys';

import TableCell from '@mui/material/TableCell';

const styles = (theme) => ({
	leftHeader: {
		borderRight: '1px solid black',
		fontWeight: '600'
	},
	subHeader: {
		borderRight: '1px solid black'
	}
});

const SubHeaderRow = (props) => {
	const { cardData, classes, t } = props;
	const { leftHeader, subHeader } = classes;

	const { monthToDate, daily, weekToDate } = cardData;
	// TODO: map over these so there's less duplicate code
	return (
		<Fragment>
			<TableCell align="left" colSpan={2} className={leftHeader}>
				{capitalCase(sanitizeKeys(cardData.cardType))}
			</TableCell>
			{monthToDate && (
				<Fragment>
					<TableCell align="center">
						{t(
							'pages.reports.chargebacks.daily-chargeback-report.subheaders.actual'
						)}
					</TableCell>
					<TableCell align="center">
						{t(
							'pages.reports.chargebacks.daily-chargeback-report.subheaders.forecast'
						)}
					</TableCell>
					<TableCell align="center">
						{t(
							'pages.reports.chargebacks.daily-chargeback-report.subheaders.delta'
						)}
					</TableCell>
					<TableCell align="center" className={subHeader}>
						{t(
							'pages.reports.chargebacks.daily-chargeback-report.subheaders.percent'
						)}
					</TableCell>
				</Fragment>
			)}
			{daily && (
				<Fragment>
					<TableCell align="center">
						{t(
							'pages.reports.chargebacks.daily-chargeback-report.subheaders.actual'
						)}
					</TableCell>
					<TableCell align="center">
						{t(
							'pages.reports.chargebacks.daily-chargeback-report.subheaders.forecast'
						)}
					</TableCell>
					<TableCell align="center">
						{t(
							'pages.reports.chargebacks.daily-chargeback-report.subheaders.delta'
						)}
					</TableCell>
					<TableCell align="center" className={subHeader}>
						{t(
							'pages.reports.chargebacks.daily-chargeback-report.subheaders.percent'
						)}
					</TableCell>
				</Fragment>
			)}
			{weekToDate && (
				<Fragment>
					<TableCell align="center">
						{t(
							'pages.reports.chargebacks.daily-chargeback-report.subheaders.actual'
						)}
					</TableCell>
					<TableCell align="center">
						{t(
							'pages.reports.chargebacks.daily-chargeback-report.subheaders.forecast'
						)}
					</TableCell>
					<TableCell align="center">
						{t(
							'pages.reports.chargebacks.daily-chargeback-report.subheaders.delta'
						)}
					</TableCell>
					<TableCell align="center">
						{t(
							'pages.reports.chargebacks.daily-chargeback-report.subheaders.percent'
						)}
					</TableCell>
				</Fragment>
			)}
		</Fragment>
	);
};

SubHeaderRow.propTypes = {
	cardData: PropTypes.object,
	classes: PropTypes.object,
	t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(SubHeaderRow));
