import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import Typography from '@mui/material/Typography';

const styles = (theme) => ({
	accordian: {
		width: '25vw',
		margin: '2px 5px 2px 5px',
		padding: '5px 10px 5px 10px'
	},
	card: {
		width: '100%'
	},
	cardContent: {
		display: 'flex',
		justifyContent: 'space-between'
	},
	labelStyle: {
		marginBottom: '10px'
	}
});

const AccordionNotificationDisplay = (props) => {
	const {
		classes,
		expanded,
		handleChange,
		filteredNotificationsByType,
		handleSelect,
		label,
		t,
		displayStyle,
		type
	} = props;
	const { accordian, card, cardContent, labelStyle } = classes;

	return (
		<Accordion
			className={accordian}
			expanded={expanded === type}
			onChange={() => {
				handleChange(type, expanded);
			}}
		>
			<AccordionSummary>{`${label} (${
				filteredNotificationsByType && filteredNotificationsByType.length
			})`}</AccordionSummary>
			<AccordionDetails>
				{filteredNotificationsByType &&
					filteredNotificationsByType.map((notification) => {
						return (
							<Card className={card} key={notification.id}>
								<CardActionArea
									onClick={() => handleSelect(notification.id, notification)}
								>
									<div className={cardContent}>
										<Typography style={displayStyle} variant="caption">
											{notification.type}
										</Typography>
										<Typography variant="caption">
											{notification.createdOn}
										</Typography>
									</div>
									<div className={clsx(cardContent, labelStyle)}>
										<Typography>{notification.label}</Typography>
									</div>
									<div className={cardContent}>
										<div>
											<Typography variant="caption">
												{t('pages.notifications.general.lastEntry')}
											</Typography>
											<Typography variant="subtitle2">
												{/* TODO: need to manipulate the lambda to update parent notification
								when notification detail is added so that we can fetch the message up to the most
								recent notification detail displayed on the card */}
												{notification.lastEntry
													? notification.lastEntry.description
													: t('pages.notifications.general.noEntries')}
											</Typography>
										</div>
									</div>
								</CardActionArea>
							</Card>
						);
					})}
			</AccordionDetails>
		</Accordion>
	);
};

AccordionNotificationDisplay.propTypes = {
	classes: PropTypes.object,
	expanded: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
	filteredNotificationsByType: PropTypes.array,
	handleChange: PropTypes.func,
	handleSelect: PropTypes.func,
	label: PropTypes.string,
	t: PropTypes.func,
	topLevelTypeOptionColors: PropTypes.func,
	type: PropTypes.string
};

export default withStyles(styles)(
	withTranslation()(AccordionNotificationDisplay)
);
