import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import dataViews from './dataViews';
import NotDisplayable from './dataViews/NotDisplayable';

const styles = (theme) => ({});

const _selectDataViewComponent = (view) => {
	switch (view) {
		case 'line':
			return dataViews.lineGraph;
		case 'bar':
			return dataViews.barChart;
		case 'table':
			return dataViews.tableView;
		default:
			return undefined;
	}
};

export const DataViewer = (props) => {
	const {
		classes,
		reportData,
		tileId,
		dataViewType,
		height,
		width,
		viewProps
	} = props;
	const {} = classes;

	const DataViewComponent = _selectDataViewComponent(dataViewType);

	return (
		<Fragment>
			<div style={{ height }}>
				{DataViewComponent && reportData ? (
					<DataViewComponent
						reportData={reportData}
						height={height}
						width={width}
						{...viewProps}
					/>
				) : (
					<NotDisplayable
						height={height * 0.9}
						width={width * 0.9}
						dataViewType={dataViewType}
					/>
				)}
			</div>
		</Fragment>
	);
};

DataViewer.propTypes = {
	reportData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	tileId: PropTypes.string,
	dataViewType: PropTypes.string,
	height: PropTypes.number,
	width: PropTypes.number,
	viewProps: PropTypes.object,
	classes: PropTypes.object
};

export default withStyles(styles)(DataViewer);
