import * as ReportViewsEpics from './epic';
import * as reportViewsActions from './actions';
import * as reportViewsActionTypes from './actionTypes';
import { reportViewsReducer } from './reducer';

const reportViewsEpics = Object.values({ ...ReportViewsEpics });

export {
	reportViewsActions,
	reportViewsActionTypes,
	reportViewsEpics,
	reportViewsReducer
};
