import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';

import Container from '@mui/material/Container';
import Loader from '../../components/Loader';
import { userActions } from '../../containers/User';
import { multiFactorAuthConstants } from '../../containers/MultiFactorAuth';

const styles = (theme) => ({
	pageContainer: {
		display: 'flex',
		flex: 1,
		flexGrow: 1,
		flexDirection: 'row',
		justifyContent: 'center',
		width: '100%',
		alignItems: 'center'
	}
});

const _handleChallengeRedirect = (history, challengeName, state) => {
	if (challengeName) {
		if (challengeName === 'NEW_PASSWORD_REQUIRED') {
			history.push('/new-password-required');
		} else if (challengeName === multiFactorAuthConstants.softwareMfa) {
			history.push('/multi-factor-auth', state.from);
		} else {
			history.push('/login');
		}
	}
};

const _handleNonChallengeRedirect = (state, history) => {
	if (
		state &&
		state.from &&
		state.from !== '/post-login' &&
		state.from !== '/new-password-required'
	) {
		history.push(state.from);
	} else {
		history.push('/dashboard');
	}
};

const PostLogin = (props) => {
	const {
		classes,
		authUser,
		isAuthed,
		isAuthingUser,
		challengeName,
		logInError,
		userHasBeenLoaded,
		isLoadingUser,
		getUser
	} = props;
	const { pageContainer } = classes;

	const { state } = useLocation();
	const history = useHistory();

	React.useEffect(() => {
		if (!isAuthingUser || !isLoadingUser) {
			if (isAuthed && userHasBeenLoaded) {
				_handleNonChallengeRedirect(state, history);
			} else if (isAuthed && !userHasBeenLoaded && !challengeName) {
				getUser(state.username);
			} else if (isAuthed && challengeName) {
				_handleChallengeRedirect(history, challengeName, state);
			}
		}
	}, [
		state,
		history,
		challengeName,
		authUser,
		isAuthed,
		state,
		userHasBeenLoaded,
		getUser
	]);

	React.useEffect(() => {
		if (logInError) {
			history.push('/login');
		}
	}, [logInError]);

	return (
		<Container className={pageContainer} maxWidth={false}>
			<Loader />
		</Container>
	);
};

PostLogin.propTypes = {
	authUser: PropTypes.object,
	isAuthed: PropTypes.bool,
	isAuthingUser: PropTypes.bool,
	challengeName: PropTypes.string,
	logInError: PropTypes.bool,
	userHasBeenLoaded: PropTypes.bool,
	isLoadingUser: PropTypes.bool,
	getUser: PropTypes.func,
	classes: PropTypes.object
};

const mapStateToProps = (state) => {
	return {
		authUser: state.authReducer.get('user'),
		isAuthed: state.authReducer.get('isAuthed'),
		isAuthingUser: state.authReducer.get('isAuthingUser'),
		challengeName: state.authReducer.get('challengeName'),
		userHasBeenLoaded: state.userReducer.get('userHasBeenLoaded'),
		isLoadingUser: state.userReducer.get('isLoadingUser'),
		logInError: state.authReducer.get('logInError')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getUser: (payload) => {
			dispatch(userActions.getUser(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(PostLogin));
