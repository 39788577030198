import React, { useState } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { sanitizeLabels } from '../../../../helpers/sanitizeLabels';
import handleComplexFilters from '../../../../helpers/widget/handleComplexFilters';

import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const styles = (theme) => ({
	genericInput: {
		marginTop: '5px',
		fontSize: '.8rem',
		minWidth: '200px',
		height: '55px'
	}
});

const DynamicInput = (props) => {
	const { classes, label, onUpdate, option, value } = props;
	const { genericInput } = classes;

	const [inputValue, setInputValue] = useState(
		value.label || value || option.values[0]
	);

	const handleSelectionUpdate = (value) => {
		setInputValue(value);
	};

	const handleSelectionSubmit = () => {
		onUpdate(label, inputValue);
	};

	return (
		<FormControl>
			<Autocomplete
				key={label}
				onInputChange={(e, value) => {
					handleSelectionUpdate(handleComplexFilters(value));
				}}
				options={option.values.map((value) => handleComplexFilters(value))}
				noOptionsText={null}
				className={genericInput}
				value={inputValue}
				selectOnFocus={true}
				onBlur={handleSelectionSubmit}
				renderInput={(params) => (
					<TextField
						{...params}
						label={sanitizeLabels(label)}
						margin="none"
						variant="outlined"
					/>
				)}
			/>
		</FormControl>
	);
};

DynamicInput.propTypes = {
	classes: PropTypes.object,
	label: PropTypes.string,
	onUpdate: PropTypes.func,
	option: PropTypes.object,
	value: PropTypes.string
};

export default withStyles(styles)(DynamicInput);
