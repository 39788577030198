import { getLabel } from '../../shared/cardBrandHelper';

export default function (data) {
	return data.map((cardType) => {
		return {
			key: getLabel(cardType.card_type),
			value: cardType.pct
		};
	});
}
