import React, { useEffect } from 'react';
import { withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { alertsActions } from '../../../../../containers/Alerts';
import { systemActions } from '../../../../../containers/System';
import { useLocation } from 'react-router-dom';

import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import NotificationsIcon from '@mui/icons-material/Notifications';

const styles = (theme) => ({
	actionButton: {
		display: 'flex',
		borderRadius: '10px',
		margin: '10px',
		backgroundColor: theme.palette.navigationActionButton.background
	},
	iconDefault: {
		color: theme.palette.secondary.main,
		padding: '5px',
		fontSize: '22px'
	},
	iconSelected: {
		color: `${theme.palette.icons.selected} !important`
	}
});

const Notifications = (props) => {
	const {
		classes,
		t,
		alerts,
		closeRightDrawer,
		getAlerts,
		hasBeenLoaded,
		isLoading,
		openRightDrawer,
		rightDrawerIsOpen
	} = props;
	const { actionButton, iconDefault, iconSelected } = classes;

	useEffect(() => {
		if (!hasBeenLoaded && !isLoading) {
			getAlerts();
		}
	}, [hasBeenLoaded, isLoading]);

	const location = useLocation();

	const handleRightDrawer = () => {
		rightDrawerIsOpen ? closeRightDrawer() : openRightDrawer();
	};

	return (
		<Tooltip title={t('components.Layout.Navbar.actionButtons.notifications')}>
			<div className={actionButton}>
				<Badge
					// badgeContent={(alerts && alerts.unreadMessages) || 0}
					color="primary"
				>
					<IconButton
						aria-label="component screenshot"
						className={clsx(iconDefault, rightDrawerIsOpen && iconSelected)}
						component="span"
						onClick={handleRightDrawer}
						disabled={location.pathname.includes('notifications')}
					>
						<NotificationsIcon />
					</IconButton>
				</Badge>
			</div>
		</Tooltip>
	);
};

Notifications.propTypes = {
	alerts: PropTypes.object,
	classes: PropTypes.object,
	closeRightDrawer: PropTypes.func,
	error: PropTypes.string,
	getAlerts: PropTypes.func,
	hasBeenLoaded: PropTypes.bool,
	isLoading: PropTypes.bool,
	openRightDrawer: PropTypes.func,
	rightDrawerIsOpen: PropTypes.bool,
	t: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		alerts: state.alertsReducer.get('alerts'),
		error: state.alertsReducer.get('error'),
		hasBeenLoaded: state.alertsReducer.get('hasBeenLoaded'),
		isLoading: state.alertsReducer.get('isLoading'),
		rightDrawerIsOpen: state.systemReducer.get('rightDrawerIsOpen')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getAlerts: () => {
			dispatch(alertsActions.getAlerts());
		},
		closeRightDrawer: () => {
			dispatch(systemActions.toggleRightDrawer());
		},
		openRightDrawer: () => {
			dispatch(systemActions.toggleRightDrawer());
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(withTranslation()(Notifications)));
